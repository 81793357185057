import ReportItemDeli from "./ReportItemDeli";

const ReportListDeli = (props) =>
{
  let orders = null;

  if (props.items !== undefined && props.items !== null && props.items.length > 0)
  {
    orders = props.items.map(item => {
      return (<ReportItemDeli key={`${item.orderType}-${item.idx}`} order = {item} handleDelivery={props.handleDelivery} handleDeliveryMnuOrder={props.handleDeliveryMnuOrder} popt={props.popt}
        handleDeliMode={props.handleDeliMode}
        />);
    });
  }

  return (
    <div className = "tablecont">
      <table className="emptable">
        <thead>
          <tr key={-1}>
            <th className="reptableheader">
              <label className="fontnw">거래처명</label>
            </th>

            <th className="reptableheader">
              <label className="fontnw">상품명</label>
            </th>

            <th className="reptableheader">
              <label className="fontnw">배송 설정</label>
            </th>

            <th className="reptableheaderdeli" />
          </tr>
        </thead>
        <tbody>
        {orders}
        </tbody>
      </table>
    </div>
  );
}

export default ReportListDeli;