import { mobileOn } from "../../URL/AppUrl";

const EditManagedSalesAddItem = (props) =>
{
  if (props.selected)
  {
    return (
      <div className="edmsitem flexcenterrow bgcolor3 btncur" onClick={(e) => {
        props.handleSelected(props.item.sidx);
        e.preventDefault();
        e.stopPropagation();
      }}>
        <label className= {mobileOn ? "mfontnwb btncur" : "fontnwb btncur"}>{props.item.uname}</label>
      </div>
    );
  }
  else
  {
    return (
      <div className="edmsitem flexcenterrow bgcolor5 btncur"onClick={(e) => {
        props.handleSelected(props.item.sidx);
        e.preventDefault();
        e.stopPropagation();
      }}>
        <label className={mobileOn ? "mfontnb btncur" : "fontnb btncur"}>{props.item.uname}</label>
      </div>
    );
  }
  
};

export default EditManagedSalesAddItem;