
import React from 'react';
import './Main.css';
import '../../App.css';

import {GetImgUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { observer } from 'mobx-react';
import Loading from '../Loading/Loading';

//설정
class MainSet extends React.Component {
  
  state = {
    loading : false,
    orderTime : 9,
    orderTime2 : 0,
    chatStart : 9,
    chatEnd : 18,
  };

  setLoading = (loading) =>
  {
    this.setState((prevState) => ({...prevState, loading : loading}));
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("MainSet.componentDidMount");


    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);
    }
    else
      this.loadTimeInfo();

  }

  componentWillUnmount = () =>
  {
    if (LOG_ENABLE)
      console.log("MainSet.componentWillUnmount");
  }

  onLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      this.props.ProcessLogout();
      return;
    }

    this.loadTimeInfo();
  }

  loadTimeInfo = () =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    LoginStoreInstance.loadTimeInfo(this.onLoadTimeInfo);
  }

  onLoadTimeInfo = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        if (LoginStoreInstance.isTimeInfoReady())
        {
          this.setState((prevState) => ({...prevState, orderTime : LoginStoreInstance.ordertm, orderTime2 : LoginStoreInstance.ordertm2, chatStart : LoginStoreInstance.chatStart, chatEnd : LoginStoreInstance.chatEnd}));
        }
        break;
      default:
        alert('시간 값 읽기중 오류가 발생했습니다.');
        this.props.handleTimeError();
        break;
    }
  }

  changeChatStartTime = (v) =>
  {
    this.setState((prevState) => ({...prevState, chatStart : parseInt(v)}));
  }

  changeChatEndTime = (v) =>
  {
    this.setState((prevState) => ({...prevState, chatEnd : parseInt(v)}));
  }

  changeOrderTime = (v) =>
  {
    this.setState((prevState) => ({...prevState, orderTime : parseInt(v)}));
  }

  changeOrderTime2 = (v) =>
  {
    this.setState((prevState) => ({...prevState, orderTime2 : parseInt(v)}));
  }

  //채팅 가능 시간 변경하기
  handleChangeChatTime = () =>
  {
    if (LOG_ENABLE)
      console.log("handleChangeChatTime");

    if (this.state.loading)
    {
      return;
    }

    if (!LoginStoreInstance.isTimeInfoReady())
    {
      alert('시간을 변경할 수 없습니다. 나중에 다시 시도해 주세요.');
      return;
    }

    if (this.state.chatStart === LoginStoreInstance.chatStart && this.state.chatEnd === LoginStoreInstance.chatEnd)
    {
      alert('기존 시간과 동일합니다.');
      return;
    }

    if (this.state.chatStart > this.state.chatEnd)
    {
      alert('채팅 이용 시간이 올바르지 않습니다. 시작 시간이 끝 시간보다 클수 없습니다.');
      return;
    }

    if (!window.confirm('채팅 이용 시간을 변경할까요?'))
      return;

    this.setLoading(true);
    LoginStoreInstance.setTimeInfo("1", this.state.chatStart, this.state.chatEnd, this.onSetChatTimeResult);
  }

  onSetChatTimeResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        alert('채팅 이용 시간이 변경되었습니다.');
        break;
      case 1: 
        alert('잘못된 요청입니다.');
        break;
      case 2: 
        alert('권한이 없습니다.');
        break;
      default:
        alert('시간 변경중 오류가 발생했습니다. 잠시 후 이용해 주세요.');
        break;
    }
  }

  //주문 마감시간 변경하기
  handleChangeOrderTime = () =>
  {
    if (LOG_ENABLE)
      console.log("handleChangeOrderTime");

    if (this.state.loading)
    {
      return;
    }

    if (!LoginStoreInstance.isTimeInfoReady())
    {
      alert('시간을 변경할 수 없습니다. 나중에 다시 시도해 주세요.');
      return;
    }

    if (this.state.orderTime === LoginStoreInstance.ordertm && this.state.orderTime2 === LoginStoreInstance.ordertm2)
    {
      alert('기존 시간과 동일합니다.');
      return;
    }

    if (!window.confirm('주문 마감 시간을 변경할까요?'))
      return;

    this.setLoading(true);
    LoginStoreInstance.setTimeInfo("2", this.state.orderTime, this.state.orderTime2, this.onSetOrderTimeResult);
  }

  onSetOrderTimeResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        alert('주문 마감 시간이 변경되었습니다.');
        break;
      case 1: 
        alert('잘못된 요청입니다.');
        break;
      case 2: 
        alert('권한이 없습니다.');
        break;
      default:
        alert('시간 변경중 오류가 발생했습니다. 잠시 후 이용해 주세요.');
        break;
    }
  }

  render()
  {
    if (mobileOn)
    {
      return (
        <div className="flexcentercol mainsetpopmb">
          {
            this.state.loading ? (
              <Loading />
            ) : null
          }
  
          <div className="cntfullh marginbottom15 flexcenterrow">
            <div className="dcell1"></div>
  
            <div className="dcell8 flexcenterrow">
              <label className="fontnb">{LoginStoreInstance.profile.cname}</label>    
            </div>
  
            <div className="dcell1">
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className="btncur" onClick={() => this.props.handleCloseSetting()}/>
            </div>
  
          </div>
          
  
          <label className="mainsetitem fontn marginbottom5">채팅 이용 시간</label>
  
          <div className="flexcenterrowstart mainsetitem marginbottom15">
            <select className="mainsettmselmb fontn" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.changeChatStartTime(e.target.value);
              }}
  
              value={this.state.chatStart}
            >
              <option value="0">00시</option>
              <option value="1">01시</option>
              <option value="2">02시</option>
              <option value="3">03시</option>
              <option value="4">04시</option>
              <option value="5">05시</option>
              <option value="6">06시</option>
              <option value="7">07시</option>
              <option value="8">08시</option>
              <option value="9">09시</option>
              <option value="10">10시</option>
              <option value="11">11시</option>
              <option value="12">12시</option>
              <option value="13">13시</option>
              <option value="14">14시</option>
              <option value="15">15시</option>
              <option value="16">16시</option>
              <option value="17">17시</option>
              <option value="18">18시</option>
              <option value="19">19시</option>
              <option value="20">20시</option>
              <option value="21">21시</option>
              <option value="22">22시</option>
              <option value="23">23시</option>
            </select>
            
            <label className="fontn">&nbsp;&nbsp;~&nbsp;&nbsp;</label>
  
            <select className="mainsettmselmb fontn" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.changeChatEndTime(e.target.value);
              }}
  
              value={this.state.chatEnd}
            >
              <option value="0">00시</option>
              <option value="1">01시</option>
              <option value="2">02시</option>
              <option value="3">03시</option>
              <option value="4">04시</option>
              <option value="5">05시</option>
              <option value="6">06시</option>
              <option value="7">07시</option>
              <option value="8">08시</option>
              <option value="9">09시</option>
              <option value="10">10시</option>
              <option value="11">11시</option>
              <option value="12">12시</option>
              <option value="13">13시</option>
              <option value="14">14시</option>
              <option value="15">15시</option>
              <option value="16">16시</option>
              <option value="17">17시</option>
              <option value="18">18시</option>
              <option value="19">19시</option>
              <option value="20">20시</option>
              <option value="21">21시</option>
              <option value="22">22시</option>
              <option value="23">23시</option>
            </select>
  
            <button className="mainsetbtn btncur fontnwb flexcenterrow" onClick={() => this.handleChangeChatTime()}>저장</button>
          </div>
  
          <label className="mainsetitem fontn marginbottom5">주문 마감시간</label>
  
          <div className="flexcenterrowstart mainsetitem">
            <select className="mainsettmselmb fontn marginright5" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.changeOrderTime(e.target.value);
              }}
  
              value={this.state.orderTime}
            >
              <option value="0">00시</option>
              <option value="1">01시</option>
              <option value="2">02시</option>
              <option value="3">03시</option>
              <option value="4">04시</option>
              <option value="5">05시</option>
              <option value="6">06시</option>
              <option value="7">07시</option>
              <option value="8">08시</option>
              <option value="9">09시</option>
              <option value="10">10시</option>
              <option value="11">11시</option>
              <option value="12">12시</option>
              <option value="13">13시</option>
              <option value="14">14시</option>
              <option value="15">15시</option>
              <option value="16">16시</option>
              <option value="17">17시</option>
              <option value="18">18시</option>
              <option value="19">19시</option>
              <option value="20">20시</option>
              <option value="21">21시</option>
              <option value="22">22시</option>
              <option value="23">23시</option>
            </select>
  
            <select className="mainsettmselmb fontn" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.changeOrderTime2(e.target.value);
              }}
  
              value={this.state.orderTime2}
            >
              <option value="0">00분</option>
              <option value="1">01분</option>
              <option value="2">02분</option>
              <option value="3">03분</option>
              <option value="4">04분</option>
              <option value="5">05분</option>
              <option value="6">06분</option>
              <option value="7">07분</option>
              <option value="8">08분</option>
              <option value="9">09분</option>
              <option value="10">10분</option>
              <option value="11">11분</option>
              <option value="12">12분</option>
              <option value="13">13분</option>
              <option value="14">14분</option>
              <option value="15">15분</option>
              <option value="16">16분</option>
              <option value="17">17분</option>
              <option value="18">18분</option>
              <option value="19">19분</option>
              <option value="20">20분</option>
              <option value="21">21분</option>
              <option value="22">22분</option>
              <option value="23">23분</option>
              <option value="24">24분</option>
              <option value="25">25분</option>
              <option value="26">26분</option>
              <option value="27">27분</option>
              <option value="28">28분</option>
              <option value="29">29분</option>
              <option value="30">30분</option>
              <option value="31">31분</option>
              <option value="32">32분</option>
              <option value="33">33분</option>
              <option value="34">34분</option>
              <option value="35">35분</option>
              <option value="36">36분</option>
              <option value="37">37분</option>
              <option value="38">38분</option>
              <option value="39">39분</option>
              <option value="40">40분</option>
              <option value="41">41분</option>
              <option value="42">42분</option>
              <option value="43">43분</option>
              <option value="44">44분</option>
              <option value="45">45분</option>
              <option value="46">46분</option>
              <option value="47">47분</option>
              <option value="48">48분</option>
              <option value="49">49분</option>
              <option value="50">50분</option>
              <option value="51">51분</option>
              <option value="52">52분</option>
              <option value="53">53분</option>
              <option value="54">54분</option>
              <option value="55">55분</option>
              <option value="56">56분</option>
              <option value="57">57분</option>
              <option value="58">58분</option>
              <option value="59">59분</option>
            </select>
  
            <button className="mainsetbtn btncur fontnwb flexcenterrow" onClick={() => this.handleChangeOrderTime()}>저장</button>
  
          </div>
  
        </div>
      );
    }
    else
    {
      return (
        <div className="flexcentercol mainsetpop">
          {
            this.state.loading ? (
              <Loading />
            ) : null
          }
  
          <div className="cntfullh marginbottom15 flexcenterrow">
            <div className="dcell1"></div>
  
            <div className="dcell8 flexcenterrow">
              <label className="fontnb">{LoginStoreInstance.profile.cname}</label>    
            </div>
  
            <div className="dcell1">
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className="btncur" onClick={() => this.props.handleCloseSetting()}/>
            </div>
  
          </div>
          
  
          <label className="mainsetitem fontn marginbottom5">채팅 이용 시간</label>
  
          <div className="flexcenterrowstart mainsetitem marginbottom15">
            <select className="mainsettmsel fontn" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.changeChatStartTime(e.target.value);
              }}
  
              value={this.state.chatStart}
            >
              <option value="0">00시</option>
              <option value="1">01시</option>
              <option value="2">02시</option>
              <option value="3">03시</option>
              <option value="4">04시</option>
              <option value="5">05시</option>
              <option value="6">06시</option>
              <option value="7">07시</option>
              <option value="8">08시</option>
              <option value="9">09시</option>
              <option value="10">10시</option>
              <option value="11">11시</option>
              <option value="12">12시</option>
              <option value="13">13시</option>
              <option value="14">14시</option>
              <option value="15">15시</option>
              <option value="16">16시</option>
              <option value="17">17시</option>
              <option value="18">18시</option>
              <option value="19">19시</option>
              <option value="20">20시</option>
              <option value="21">21시</option>
              <option value="22">22시</option>
              <option value="23">23시</option>
            </select>
            
            <label className="fontn">&nbsp;&nbsp;~&nbsp;&nbsp;</label>
  
            <select className="mainsettmsel fontn" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.changeChatEndTime(e.target.value);
              }}
  
              value={this.state.chatEnd}
            >
              <option value="0">00시</option>
              <option value="1">01시</option>
              <option value="2">02시</option>
              <option value="3">03시</option>
              <option value="4">04시</option>
              <option value="5">05시</option>
              <option value="6">06시</option>
              <option value="7">07시</option>
              <option value="8">08시</option>
              <option value="9">09시</option>
              <option value="10">10시</option>
              <option value="11">11시</option>
              <option value="12">12시</option>
              <option value="13">13시</option>
              <option value="14">14시</option>
              <option value="15">15시</option>
              <option value="16">16시</option>
              <option value="17">17시</option>
              <option value="18">18시</option>
              <option value="19">19시</option>
              <option value="20">20시</option>
              <option value="21">21시</option>
              <option value="22">22시</option>
              <option value="23">23시</option>
            </select>
  
            <button className="mainsetbtn btncur fontnwb flexcenterrow" onClick={() => this.handleChangeChatTime()}>저장</button>
          </div>
  
          <label className="mainsetitem fontn marginbottom5">주문 마감시간</label>
  
          <div className="flexcenterrowstart mainsetitem">
            <select className="mainsettmsel fontn marginright5" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.changeOrderTime(e.target.value);
              }}
  
              value={this.state.orderTime}
            >
              <option value="0">00시</option>
              <option value="1">01시</option>
              <option value="2">02시</option>
              <option value="3">03시</option>
              <option value="4">04시</option>
              <option value="5">05시</option>
              <option value="6">06시</option>
              <option value="7">07시</option>
              <option value="8">08시</option>
              <option value="9">09시</option>
              <option value="10">10시</option>
              <option value="11">11시</option>
              <option value="12">12시</option>
              <option value="13">13시</option>
              <option value="14">14시</option>
              <option value="15">15시</option>
              <option value="16">16시</option>
              <option value="17">17시</option>
              <option value="18">18시</option>
              <option value="19">19시</option>
              <option value="20">20시</option>
              <option value="21">21시</option>
              <option value="22">22시</option>
              <option value="23">23시</option>
            </select>
  
            <select className="mainsettmsel fontn" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.changeOrderTime2(e.target.value);
              }}
  
              value={this.state.orderTime2}
            >
              <option value="0">00분</option>
              <option value="1">01분</option>
              <option value="2">02분</option>
              <option value="3">03분</option>
              <option value="4">04분</option>
              <option value="5">05분</option>
              <option value="6">06분</option>
              <option value="7">07분</option>
              <option value="8">08분</option>
              <option value="9">09분</option>
              <option value="10">10분</option>
              <option value="11">11분</option>
              <option value="12">12분</option>
              <option value="13">13분</option>
              <option value="14">14분</option>
              <option value="15">15분</option>
              <option value="16">16분</option>
              <option value="17">17분</option>
              <option value="18">18분</option>
              <option value="19">19분</option>
              <option value="20">20분</option>
              <option value="21">21분</option>
              <option value="22">22분</option>
              <option value="23">23분</option>
              <option value="24">24분</option>
              <option value="25">25분</option>
              <option value="26">26분</option>
              <option value="27">27분</option>
              <option value="28">28분</option>
              <option value="29">29분</option>
              <option value="30">30분</option>
              <option value="31">31분</option>
              <option value="32">32분</option>
              <option value="33">33분</option>
              <option value="34">34분</option>
              <option value="35">35분</option>
              <option value="36">36분</option>
              <option value="37">37분</option>
              <option value="38">38분</option>
              <option value="39">39분</option>
              <option value="40">40분</option>
              <option value="41">41분</option>
              <option value="42">42분</option>
              <option value="43">43분</option>
              <option value="44">44분</option>
              <option value="45">45분</option>
              <option value="46">46분</option>
              <option value="47">47분</option>
              <option value="48">48분</option>
              <option value="49">49분</option>
              <option value="50">50분</option>
              <option value="51">51분</option>
              <option value="52">52분</option>
              <option value="53">53분</option>
              <option value="54">54분</option>
              <option value="55">55분</option>
              <option value="56">56분</option>
              <option value="57">57분</option>
              <option value="58">58분</option>
              <option value="59">59분</option>
            </select>
  
            <button className="mainsetbtn btncur fontnwb flexcenterrow" onClick={() => this.handleChangeOrderTime()}>저장</button>
  
          </div>
  
        </div>
      );
    }
  }
}

export default observer(MainSet);