import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
} from 'mobx';
import React from 'react';
import {LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { LoginStoreInstance } from './LoginStore';
import { FromBase64, IsValidS, ToBase64 } from '../Util/Util';

//추천인
class RecommStore {
  //observable변수
  recommList = null;
  
  //비 observable
  totalCount = 0; //전체 직원의 수
  
  clearAll = () =>
  {
    this.recommList = null;

    //비 observable
    this.totalCount = 0; //전체 직원의 수

    this.loadCallback = null;
    this.addCallback = null;
  }
  
  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      recommList : observable,

      clearAll : action,
      clearList : action,

      parseLoadResult : action,
      parseAddRecommResult : action,
    });
  }
  
  //고유번호로 영업담당자 찾기
  findRecommByIdx = (recIdx) =>
  {
    if (this.recommList === null)
      return null;

    for (let i = 0;i < this.recommList.length; ++i)
    {
      if (this.recommList[i].idx === recIdx)
        return this.recommList[i];
    }

    return null;
  }

  clearList = () =>
  {
    this.recommList = null; //직원 목록
  }
  //목록 읽기-------------------------------------------------------------------------------------------------------------------------------------
  loadCallback = null;

  makeLoadParam (offset) {
    const params = new URLSearchParams();

    params.append("pos", offset.toString());

    return params;
  }

  loadList = (offset, callback) =>
  {
    this.loadCallback = callback;

    axios({
      method:"POST",
      url: "rc/get.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadParam(offset)
    }).then((res)=>{
        this.parseLoadResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseLoadResult(null);
    });
  }

  parseLoadResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseLoadResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadCallback != null)
        this.loadCallback(-2, false);

      return;
    }
    
    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }
    
    if (result.ret === 0)
    {
      if (result.ft === 1)
      {
        this.totalCount = result.tc;
      }
      
      if (IsValidS(result.list))
      {
        this.recommList = [];

        for (let i = 0;i < result.list.length; ++i)
        {
          //상위추천인인경우 통과
          if (result.list[i].rt === 0)
            continue;

          this.recommList.push({...result.list[i], rc : FromBase64(result.list[i].rc), uname : FromBase64(result.list[i].uname)});
        }
      }
      else
        this.recommList = null;
    }
    else
      this.recommList = null;

    if (this.loadCallback != null)
        this.loadCallback(result.ret, result.ft === 1);
  }
  //추천인 추가-------------------------------------------------------------------------------------------------------------------------------------
  addCallback = null;

  makeAddParam (rc, uname, phone, sn, bc, an) {
    const params = new URLSearchParams();

    params.append("rc", ToBase64(rc));
    params.append("uname", ToBase64(uname));
    params.append("ph", phone);
    params.append("sn", sn);
    params.append("bc", bc);
    params.append("an", an);
    params.append("rt", "1"); //주도에서는 무조건 상위 추천인 타입으로추가됨

    return params;
  }

  addRecommender = (rc, uname, phone, sn, bc, an, callback) =>
  {
    this.addCallback = callback;

    axios({
      method:"POST",
      url: "rc/add.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeAddParam(rc, uname, phone, sn, bc, an)
    }).then((res)=>{
        this.parseAddRecommResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseAddRecommResult(null);
    });
  }

  parseAddRecommResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseAddRecommResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.addCallback != null)
        this.addCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      ++this.totalCount;
    }

    if (this.addCallback != null)
      this.addCallback(result.ret);
  }
}
//-------------------------------------------------------------------------------------------------------------------------------------------------
const RecommStoreInstance = new RecommStore();
const RecommStoreContext = React.createContext(RecommStoreInstance);

const UseRecommStore = () => React.useContext(RecommStoreContext);

export {UseRecommStore, RecommStoreInstance};
