import OrderItemvo from "./OrderItemvo";

const OrderItemListvo = (props) =>
{
  let items = null;

  if (props.itemList !== undefined && props.itemList !== null && props.itemList.length > 0)
  {
    items = props.itemList.map(item => {
      return (<OrderItemvo key={item.idx} item={item} orderIdx = {props.order.idx} handleEditItem = {props.handleEditItem} handleDeleteItem = {props.handleDeleteItem}/>);
    });
  }

  return (
    <div className = "tablecont">
      <table className="emptable">
        <thead>
          <tr>
            <th className="emptableheader">
              <label className="fontnw">상품번호</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">상품명</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">용량</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">주문갯수</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">수정</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">삭제</label>
            </th>
          </tr>
        </thead>
        <tbody>
        {items}
        </tbody>
      </table>
    </div>
  );
};

export default OrderItemListvo;