import React from 'react';
import { GetFormattedCNum, GetFormattedPhone2 } from '../../Util/Util';

function GetCompanyType(ct)
{
  if (ct === 0)
    return "유흥";
  else
    return "일반";
}

const RetAccInfo = (props) =>{
  if (!props.appInstall)
  {
    return (
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontnr">앱미설치</label>
        </div>
      </td>
    );
  }

  return (
    <td className="emptablecell">
      <div className="emptablecellin flexcenterrow">
        <button className="retaccbtn flexcenterrow btncur fontnwb" onClick={props.handleShowAccount}>계정보기</button>
      </div>
    </td>
  );
}

const RetInfo = (props) => {
  return (
    <tr className="emptabledata">
      <td className="emptablecell">
        <input type="radio" onChange={(e) => props.onCheckChanged(props.index)} className="chk" checked={props.selectIdx === props.index}/>
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn btncur" onClick={() => props.handleEditDirect(props.index)}>{props.cname}</label>
        </div>
        
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn btncur" onClick={() => props.handleEditDirect(props.index)}>{props.addr}</label>  
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn btncur" onClick={() => props.handleEditDirect(props.index)}>{props.oname}</label>  
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn btncur" onClick={() => props.handleEditDirect(props.index)}>{GetFormattedPhone2(props.cphone)}</label>  
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{GetFormattedCNum(props.cnum)}</label>  
        </div>
        
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{`${props.sname}(${props.sid})`}</label>  
        </div>
      </td>

      {/*
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{`${props.pname}(${props.pid})`}</label>  
        </div>
      </td>
       */}

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{GetCompanyType(props.ct)}</label>  
        </div>
      </td>

      {
        props.ulevel < 2 ? (
          <RetAccInfo  appInstall = {props.appinst} handleShowAccount = {() => props.handleShowAccount(props.retIdx)}/>
        ) : null
      }
    </tr>
  );
}

export default RetInfo;