import { useState } from "react";
import { GetImgUrl, mobileOn } from "../../URL/AppUrl"

//채팅창 우측 대화 검색창
const ChatSerTalk = (props) =>
{
  const[search, setSearch] = useState('');

  if (mobileOn)
  {
    return (
      <div className="chatsertalkmb flexcentercol">
        <div className="chatsertalktopmb flexcenterrow">
  
          <div className="chatsertalktopleft flexcenterrow">
            <div className="chatsertalktopleftinpmb flexcenterrow">
              <div className="chatsertalktopleftinpleft  flexcenterrow">
                <img src={GetImgUrl("searchpd.png")} alt="검색"/>
              </div>
              <div className="chatsertalktopleftinpright flexcenterrow">
                <input type="text" onChange={(e) => setSearch(e.target.value)} value = {search} className="talkserinpmb mfontnb" placeholder="대화 내용 검색"
                  onKeyPress = {(e) => {
                    if (e.key === "Enter")
                    {
                      props.handleSearchTalk(search);
                    }
                  }}
                />
              </div>
            </div>
          </div>
  
          <div className="chatsertalktopcenter flexcenterrow">
            <label className="mfontnb">{props.companyName}</label>
          </div>
  
          {
            (props.searched)? (
              <div className="chatsertalktopright flexcenterrow">
                <label className="mfontnb">
                    {
                      props.searchCount > 0 ? (props.searchIndex + 1) + "/" + props.searchCount : "검색내용없음"
                    }
                </label>
                
                {
                  (props.searchCount > 0) ?
                    (
                    <button className="chattkserbtnbgmb mfontnb" onClick={() => props.handleSearchMove(true)}>
                      △
                    </button>
                    ):null
                }
                
                {
                  (props.searchCount > 0) ?
                  (
                    <button className="chattkserbtnbgmb mfontnb" onClick={() => props.handleSearchMove(false)}>
                      ▽
                    </button>
                  ):null
                }
  
                <button className="chattkserclosebtnbgmb mfontnb" onClick={() => props.handleSearchClose()}>
                  X
                </button>
                
  
              </div>
            ) : (
              <div className="chatsertalktopright flexcenterrow">
                <button className="chatdonebtnbgmb mfontnwb" onClick={() => props.handleStopTalk()}>
                  대화 종료하기
                </button>
              </div>
            )
          }
        </div>
        
        <div className="chathlinemb"></div>

        <div className="chatsertalkbottommb flexcenterrow">
          <label className="mfontn chatdescmb">대화 내역은 일주일간 조회 가능하며 해당 기간이 경과되면 자동으로 삭제됩니다.</label>
        </div>
      </div>
    );
  }
  else
  {
    return (
      <div className="chatsertalk flexcentercol">
        <div className="chatsertalktop flexcenterrow">
  
          <div className="chatsertalktopleft flexcenterrow">
            <div className="chatsertalktopleftinp flexcenterrow">
              <div className="chatsertalktopleftinpleft  flexcenterrow">
                <img src={GetImgUrl("searchpd.png")} alt="검색"/>
              </div>
              <div className="chatsertalktopleftinpright flexcenterrow">
                <input type="text" onChange={(e) => setSearch(e.target.value)} value = {search} className="talkserinp fontlargeb" placeholder="대화 내용 검색"
                  onKeyPress = {(e) => {
                    if (e.key === "Enter")
                    {
                      props.handleSearchTalk(search);
                    }
                  }}
                />
              </div>
            </div>
          </div>
  
          <div className="chatsertalktopcenter flexcenterrow">
            <label className="fontlargeb">{props.companyName}</label>
          </div>
  
          {
            (props.searched)? (
              <div className="chatsertalktopright flexcenterrow">
                <label className="fontnb">
                    {
                      props.searchCount > 0 ? (props.searchIndex + 1) + "/" + props.searchCount : "검색내용없음"
                    }
                </label>
                
                {
                  (props.searchCount > 0) ?
                    (
                    <button className="chattkserbtnbg fontnb" onClick={() => props.handleSearchMove(true)}>
                      △
                    </button>
                    ):null
                }
                
                {
                  (props.searchCount > 0) ?
                  (
                    <button className="chattkserbtnbg fontnb" onClick={() => props.handleSearchMove(false)}>
                      ▽
                    </button>
                  ):null
                }
  
                <button className="chattkserclosebtnbg fontnb" onClick={() => props.handleSearchClose()}>
                  X
                </button>
                
  
              </div>
            ) : (
              <div className="chatsertalktopright flexcenterrow">
                <button className="chatdonebtnbg fontnwb" onClick={() => props.handleStopTalk()}>
                  대화 종료하기
                </button>
              </div>
            )
          }
          
        </div>
  
        <div className="chatsertalkbottom flexcenterrow">
          <label className="fontmid">대화 내역은 일주일간 조회 가능하며 해당 기간이 경과되면 자동으로 삭제됩니다.</label>
        </div>
      </div>
    );
  }  
}

export default ChatSerTalk;