import {
  action,
  makeObservable,
  observable,
} from 'mobx';
import React from 'react';

import { GetApiUrl2, LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV, ToBase64 } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

//추천인관련
class RecmStore {
  //observable
  recmList = null; //추천인목록
  userList = null; //가입자 목록
  recmAllList = null; //특정 도매상 소속의 추천인 목록을 모두 읽은것
  //----------------------------------------------------------------------------------------------------------------------------------------
  //비observable
  totalRecmCount = 0; //추천인수
  totalUserCount = 0; //가입자수

  allListReady = false; //모든 추천인 목록 조회가 완료되었나?
  allListRefIdx = 0;
  //모든 데이터를 초기화 시킴
  clearAll = () => {
    this.recmList = null; //추천인목록
    this.userList = null; //가입자 목록
    this.recmAllList = null; //특정 도매상 소속의 추천인 목록을 모두 읽은것

    this.totalRecmCount = 0; //추천인수
    this.totalUserCount = 0; //가입자수
    this.allListReady = false; //모든 추천인 목록 조회가 완료되었나?
    this.allListRefIdx = 0;
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      recmList : observable,
      userList : observable,
      recmAllList : observable,
      
      clearAll : action,
      
      parseRCListResult : action,
      parseRCListAllResult : action,
      parseRCUserListResult : action,
      parseTransResult : action,
      toggleUserCheck : action,
    });
  }

  toggleUserCheck = (idx) =>
  {
    const userInfo = this.findUserByIdx(idx);

    if (userInfo !== null)
    {
      userInfo.selected = !userInfo.selected;

      this.userList = [...this.userList];
    }
  }

  //고유번호로 추천인 찾기
  findRCByIdx = (idx) =>
  {
    if (!IsValidS(this.recmList))
      return null;

    for (let i = 0;i < this.recmList.length; ++i)
    {
      if (this.recmList[i].idx === idx)
        return this.recmList[i];
    }

    return null;
  }

  //고유번호로 가입자 찾기
  findUserByIdx = (idx) =>
  {
    if (!IsValidS(this.userList))
      return null;

    for (let i = 0;i < this.userList.length; ++i)
    {
      if (this.userList[i].idx === idx)
        return this.userList[i];
    }

    return null;
  }

  findRecmByIdxFromAllList = (idx) =>
  {
    if (!IsValidS(this.recmAllList))
      return null;

    for (let i = 0;i < this.recmAllList.length; ++i)
    {
      if (this.recmAllList[i].idx === idx)
        return this.recmAllList[i];
    }

    return null;
  }

  //추천인목록------------------------------------------------------------------------------------------------------------------------
  //추천인 목록을 읽음
  loadRCList = (pos, callback) =>
  {
    const params = new URLSearchParams();

    params.append("rt", "2");
    params.append("pos", pos.toString());
    params.append("cidx", LoginStoreInstance.compIdx.toString());

    if (LOG_ENABLE)
      console.log(`loadRCList : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl2("rc/list/"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseRCListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseRCListResult(null, callback);
    });
  }

  parseRCListResult = (result, callback) =>
  {
    if (LOG_ENABLE)
      console.log("parseRCListResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (callback)
        callback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (result.rt === 2) //영업사원 또는 일반 추천인 목록읽기
      {
        this.recmList = result.list;

        if (IsValidS(this.recmList))
          this.recmList.forEach(item => this.decodeRecommender(item));

        if (result.ft === 1)
        {
          this.totalRecmCount = result.tc;
        }
      }
    }

    if (callback)
      callback(result.ret, result.ft === 1);
  }

  decodeRecommender = (element) =>
  {
    if (IsValidS(element.cname))
      element.cname = FromBase64(element.cname);

    if (IsValidS(element.nm))
      element.nm = FromBase64(element.nm);

    if (IsValidS(element.bun))
      element.bun = FromBase64(element.bun);
  }

  //모든 추천인 읽기------------------------------------------------------------------------------------------------------------------------
  //추천인 목록을 읽음
  loadRCListAll = (callback) =>
  {
    const params = new URLSearchParams();

    params.append("rt", "3");
    params.append("pos", this.allListRefIdx.toString());
    params.append("cidx", LoginStoreInstance.compIdx.toString());

    if (LOG_ENABLE)
      console.log(`loadRCListAll : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl2("rc/list/"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseRCListAllResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseRCListAllResult(null, callback);
    });
  }

  parseRCListAllResult = (result, callback) =>
  {
    if (LOG_ENABLE)
      console.log("parseRCListAllResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (callback)
        callback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    let readCount = 0;

    if (result.ret === 0)
    {
      if (result.rt === 3)
      {
        if (IsValidS(result.list))
        {
          result.list.forEach(item => {
            this.decodeRecommender(item);
            if (this.allListRefIdx < item.idx)
              this.allListRefIdx = item.idx;
          });

          readCount = result.list.length;

          if (IsValidS(this.recmAllList))
          {
            this.recmAllList = [...this.recmAllList, ...result.list];
          }
          else
          {
            this.recmAllList = result.list;
          }
        }
      }
    }

    if (callback)
      callback(result.ret, readCount);
  }
  //추천인 가입자 목록조회------------------------------------------------------------------------------------------------------------------------
  //가입자 목록조회
  //rt : 목록조회(0), 개수만조회(1)
  loadUserList = (rt, ridx, pos, callback) =>
  {
    const params = new URLSearchParams();

    params.append("rt", rt.toString());
    params.append("ridx", ridx.toString());

    if (IsValidV(pos))
      params.append("pos", pos.toString());

    axios({
      method:"POST",
      url: GetApiUrl2("rc/ulist/"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseRCUserListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseRCUserListResult(null, callback);
    });
  }

  parseRCUserListResult = (result, callback) =>
  {
    if (LOG_ENABLE)
      console.log("parseRCUserListResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (callback)
        callback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (result.rt === 0) //목록을 조회한경우
      {
        this.userList = [];

        if (IsValidS(result.list))
        {
          result.list.forEach(item => {
            this.decodeRCUser(item);
            this.userList.push({
              ...item,
              selected: false
            });
          });
        }
  
        if (result.ft === 1)
        {
          this.totalUserCount = result.tc; //총가입자수
        }

        if (callback)
          callback(result.ret, result.ft === 1);

        return;
      }
      else if (result.rt === 1) //개수만 조회한경우
      {
        if (callback)
          callback(result.ret, result.tc);

        return;
      }
    }

    if (callback)
      callback(result.ret, result.ft === 1);
  }

  decodeRCUser = (element) =>
  {
    if (IsValidS(element.cname))
      element.cname = FromBase64(element.cname);

    if (IsValidS(element.uname))
      element.uname = FromBase64(element.uname);

    if (IsValidS(element.addr))
      element.addr = FromBase64(element.addr);

    if (IsValidS(element.wname))
      element.wname = FromBase64(element.wname);
  }
  
  //추천인 가입자 이전------------------------------------------------------------------------------------------------------------------------
  //rt : 일괄(0), 개별(1)
  //sidx : 원래 추천인
  //tidx : 신규 추천인
  //list : 이전할 가입자 고유번호 목록 배열
  //원래 추천인에 있던 가입자 일부 또는 전부를 신규 추천인 소속으로 이전시킨다.
  transUser = (rt, sidx, tidx, list, callback) =>
  {
    const params = new URLSearchParams();

    params.append("rt", rt.toString());
    params.append("sidx", sidx.toString());
    params.append("tidx", tidx.toString());

    if (IsValidS(list))
    {
      let temp = "";

      for (let i = 0;i < list.length; ++i)
      {
        if (i === 0)
          temp = list[i].toString();
        else
          temp += `,${list[i]}`;
      }

      params.append("list", temp);
    }

    

    axios({
      method:"POST",
      url: GetApiUrl2("rc/trans/"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseTransResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseTransResult(null, callback);
    });
  }

  parseTransResult = (result, callback) =>
  {
    if (LOG_ENABLE)
      console.log("parseTransResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (callback)
        callback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (result.rt === 0) //일괄 이전 완료
      {
        this.userList = null;
        this.totalUserCount = 0;
      }
      else if (result.rt === 1) //개별 이전 완료, 개별이전 완료시에는 목록을 다시 조회하기
      {
      }
    }

    if (callback)
      callback(result.ret);
  }

  findUserInList = (idx, list) =>
  {
    if (!IsValidS(list))
      return false;

    for (let i = 0;i < list.length; ++i)
    {
      if (list[i] === idx)
        return true;
    }

    return false;
  }
}

const RecmStoreInstance = new RecmStore();
const RecmStoreContext = React.createContext(RecmStoreInstance);

const UseRecmStore = () => React.useContext(RecmStoreContext);

export {UseRecmStore, RecmStoreInstance};
