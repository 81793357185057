import { observer } from "mobx-react";
import React from "react";
import { NoticeStoreInstance } from "../../Stores/NoticeStore";
import { LOG_ENABLE } from "../../URL/AppUrl";
import PageList from "../PageList";
import NoticeItemmb from "./NoticeItemmb";

class NoticeListmb extends React.Component
{
  componentDidMount = () =>
  {
    if (LOG_ENABLE)
      console.log("NoticeList.componentDidMount");
  }

  componentWillUnmount = () =>
  {
    if (LOG_ENABLE)
      console.log("NoticeList.componentWillUnmount");
  }
  
  render()
  {
    const noticeList = this.props.tabIndex === 0 ? NoticeStoreInstance.gnotices : NoticeStoreInstance.pnotices;

    let notices = null;

    if (noticeList !== undefined && noticeList !== null && noticeList.length > 0)
    {
      notices = noticeList.map(item => {
        return (<NoticeItemmb key={item.idx} notice = {item} handleSelect={this.props.handleSelect} companyName={this.props.companyName} />);
      });
    }
  
    return (
      <div className="noticebodymb flexcentercolstart">
        {notices}
        <div className="flexcenterrow ntcmargintt"/>
        {
          this.props.pageCount > 1 && !this.props.searchMode ? <PageList handlePage={this.props.handlePage} 
            first={this.props.startPage !== 1} prev={this.props.startPage !== 1} 
            next={this.props.endPage < this.props.pageCount} last={this.props.endPage < this.props.pageCount} 
            currentPage={this.props.currentPage} 
            startPage={this.props.startPage} endPage={this.props.endPage}/> : 
          null
        }
      </div>
    );
  }
}

export default observer(NoticeListmb);