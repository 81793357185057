
//검색된 업체
const ChatSearchItemmb = (props) =>
{
  return (
    <div className="chatcompitemmb2 flexcentercol">
      <div className="chatseritemtop flexcenterrowstart">
        <label className="mfontnb">{props.item.cname}</label>
      </div>

      <div className="chatseritemcenter flexcenterrowstart">
        <label className="chatseritemaddrfont mfontn">{props.item.addr}</label>
      </div>

      <div className="chatseritembottom flexcenterrow">
        <button className="bgcolor1 chatseritembtnmb mfontnwb" onClick={() => props.handleStartTalk(props.item.aidx)}>
          대화 시작
        </button>
      </div>

      <div className="chathlinemb"></div>
    </div>
  );
}

export default ChatSearchItemmb;