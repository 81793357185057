import { useState } from "react";
import { GetImgUrl } from "../../URL/AppUrl"

//채팅창 좌측 업체 검색창
const ChatSerComp = (props) =>
{
  const[search, setSearch] = useState('');

  return (
    <div className="chatsercomp flexcentercolstart">
      <div className="chatsercomptop flexcenterrow">
        <label className="fontlargeb">채팅&nbsp;&nbsp;&nbsp;</label>
        <div className="chatcompserarea flexcenterrow">
    
          <div className="chatcompserarealeft flexcenterrow">
            <img src={GetImgUrl("searchpd.png")} alt="검색"/>
          </div>

          <div className="chatcompserarearight flexcenterrowend">
            <input type="text" onChange={(e) => setSearch(e.target.value)} value = {search} className="chatserinp fontlargeb" placeholder="거래처 검색(입력후엔터)" 
              onKeyPress = {(e) => {
                if (e.key === "Enter")
                {
                  props.handleSearchCompany(search);
                }
            }}/>
          </div>

        </div>
      </div>

      {
        props.listMode === 0 ? (
        <div className="chatsercompbottom flexcenterrow">
          <div className="chatlefttopline flexcenterrow"/>
          <label className="fontlargeb">&nbsp;&nbsp;대화 목록&nbsp;&nbsp;</label>
          <div className="chatlefttopline flexcenterrow"/>
        </div>
        ):
        (
        <div className="chatsercompbottom flexcenterrow">
          <div className="charserttleft">

          </div>

          <div className="charserttcenter flexcenterrow">
            <label className="fontlargeb">검색 목록</label>  
          </div>

          <div className="charserttright flexcenterrowend">
            <img src={GetImgUrl("chatsx.png")} alt="닫기" className="charserttrightimg" onClick={() => props.handleCloseSearch()}/>
          </div>
        </div>
        )
      }
      

    </div>
  );
}

export default ChatSerComp;