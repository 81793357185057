import { GetImgUrl } from "../../URL/AppUrl";
import MnuOrderItemsedb from "./MnuOrderItemsedb";

function GetOrderLabel(order)
{
  /*if (order.st === 0)
    return `주문번호 ${order.idx} 이 주문은 아직 처리되지 않았습니다.`;*/

  if (order.st === 11)
    return `주문번호 ${order.idx} (배송완료)`;

  return `주문번호 ${order.idx} 상품 등록 완료`;
}

const MnuOneOrder = (props) =>
{
  return(
    <div className="flexcentercol mnuoditem margintop20">
      <div className="mnuoditeminfo flexcenterrow">
        <div className="mnuoditeminfoleft">
          <img src={GetImgUrl("mnuod5.png")} alt="주문"/>
          <label className="mnuitemtitleen">{GetOrderLabel(props.order)}</label>
        </div>
        <div className="mnuoditeminforight flexcenterrowend">
          {
            props.order.st !== 11 ? (
              <button className="bgcolor3 mnuoddnpdbtn fontnwb flexcenterrow btncur" onClick={() => props.handlePdAdd(props.order.idx)}>상품 추가 하기</button>
            ) : null
          }
        </div>
      </div>

      <table className="mnuoditemtable">
        <thead>
          <tr>
            <th className="emptableheader">
              <label className="fontnw">업체명</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">대표자명</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">휴대폰</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">주소</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">영업 담당자</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="emptabledata">
            <td className="ordercellcname">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{props.order.cname}</label>
              </div>
            </td>
            
            <td className="ordercelluname">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{props.order.oname}</label>
              </div>
            </td>

            <td className="ordercellphone">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{props.order.phone}</label>
              </div>
            </td>

            <td className="emptablecell">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{props.order.addr}</label>
              </div>
            </td>

            <td className="ordercellsname">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{props.order.sname}</label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/*
        props.order.st === 0 ? (
          <MnuOrderItemsedb order={props.order} handleEdit={props.handleEditDone} handleDelete={props.handleDelete} orderIdx={props.order.idx}/>
        ) : (
          <MnuOrderItems order={props.order} />
        )
        */}

      <MnuOrderItemsedb order={props.order} handleEdit={props.handleEditDone} handleDelete={props.handleDelete} orderIdx={props.order.idx}/>

      <div className="mnuodbtmbtnarea flexcenterrowend">
        {/*
          props.order.st === 0 ? (
            <button className="bgcolor3 mnuoddnbtn fontnwb flexcenterrow btncur" onClick={() => props.handleOrderDone(props.order.idx)}>주문 처리 완료</button>    
          ) : null
        */}

        {
          props.order.st !== 11 ? (
            <button className="bgcolor2 mnuoddnbtn fontnwb flexcenterrow btncur marginleft20" onClick={() => props.handleOrderDelete(props.order.idx)}>주문 취소</button>    
          ) : null
        }
      </div>

      <div className="mnuodline margintop20"></div>
    </div>
  );
}

export default MnuOneOrder;