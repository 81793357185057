import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import {LOG_ENABLE, mobileOn} from '../URL/AppUrl';
import axios from 'axios';
import { delCookie, getCookie, setCookie } from '../Util/CookieManager';
import { FromBase64, IsValidS, IsValidV } from '../Util/Util';
import CryptoJS from 'crypto-js';

//로그인 및 계정
class LoginStore {
  //observable변수
  init = false; //초기화 되었나?
  userId = ""; //사용자 계정
  userPW = ""; //비번
  acSave = true; //계정정보 저장하기
  autoLogin = true; //자동로그인
  loggedIn = false; //서버에로그인되었나?
  login = false; //로그인시도중인가?
  profile = null; //사용자의 프로필 정보
  ordertm = 9; //주문마감시간 시
  ordertm2 = 0; //주문 마감시간 분

  chatStart = 9;
  chatEnd = 18;

  //비 observable
  sid = null; //세션id(유저id와 같음)
  token = null;
  userIdx = -1; //사용자 고유번호
  compIdx = -1; //사용자의 소속회사 고유번호
  userType = -1; //로그인 유저의 타입
  errorCode = 0; //로그인에러코드
  sessionRefreshing = false;
  loginResultCallback = null;
  sessionError = false; //세션 오류가 생기는경우 true로 설정됨
  enableAutoLogin = true; //자동로그인을 활성화 여부
  appEnabled = false;

  needPushToken = false; //푸시알림 토큰 설정필요한가?
  pushTokenIdx = -1; //푸시 알림 토큰 설정 고유번호

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      init: observable,
      userId: observable,
      userPW: observable,
      acSave: observable,
      autoLogin: observable,
      loggedIn: observable,
      login: observable,
      profile: observable,
      ordertm: observable,
      ordertm2: observable,
      chatStart: observable,
      chatEnd: observable,

      setUserId: action,
      setUserPW: action,
      toggleSaveAccount : action,
      toggleAutoLogin : action,
      setSaveAccount : action,
      setAutoLogin : action,
      setLoggedIn: action,
      saveAccount: action,
      loadAccount: action,
      clearAccountInfo: action,
      restoreAccountFromApp : action,

      setOrderDoneTime : action,
      setOrderDoneTime2 : action,
      setChatTime : action,

      tryLogin: action,
      logOut: action,
      setLoginResult: action,

      parseProfile: action,
      
      getUserId : computed,
      getUserPw : computed,
      getUserLevel : computed,
      getCompanyName : computed,
    });
  }

  restoreAccountFromApp = (data) =>
  {
    if (!IsValidV(data))
    {
      this.clearAccountInfo();
      return;
    }

    if (data.saveAccount)
      this.acSave = data.saveAccount;


    if (this.acSave)
    {
      if (data.uid)
        this.userId = data.uid;
      
      if (data.upw)
        this.userPW = data.upw;
    }

    if (data.autoLogin)
      this.autoLogin = data.autoLogin;
  }

  //모든 계정 정보가 유효한가
  isAccountValid = () => {
    if (this.userId != null && this.userId.length > 0 && this.userPW != null && this.userPW.length > 0)
      return true;

      return false;
  };

  setUserId = (id) => {
    this.userId = id;
  };

  setUserPW = (pw) => {
    this.userPW = pw;
  };

  toggleSaveAccount = () => {
    this.acSave = !this.acSave;
  }

  setSaveAccount = (sa) =>
  {
    this.acSave = sa;
  }

  toggleAutoLogin = () => {
    this.autoLogin = !this.autoLogin;
  }

  setAutoLogin = (al) => {
    this.autoLogin = al;
  }

  setLoggedIn = (isLoggedIn) => {
    this.loggedIn = isLoggedIn;
  };

  setOrderDoneTime = (time) =>{
    this.ordertm = time;
  }

  setOrderDoneTime2 = (time) =>{
    this.ordertm2 = time;
  }

  setChatTime = (stime, etime) =>
  {
    this.chatStart = stime;
    this.chatEnd = etime;
  }

  //현재의 계정정보를 저장소에 저장함
  saveAccount = () => {
    let accountInfo = {};
    
    if (this.acSave)
    {
      if (IsValidS(this.userId))
        accountInfo.id = this.userId;

      if (mobileOn && IsValidS(this.userPW))
        accountInfo.pw = this.userPW;
    }

    //계정저장
    accountInfo.asv = this.acSave;
    accountInfo.al = this.autoLogin;

    if (LOG_ENABLE)
      console.log("save account info : " + JSON.stringify(accountInfo));

    if (Object.keys(accountInfo).length > 0)
    {
      setCookie("uai2", CryptoJS.AES.encrypt(JSON.stringify(accountInfo), "fjecdsoQfwfdeGh*&46$lzp2dr&^1$#H").toString());
    }    
  };

  //스토어에 저장된 계정정보를 읽어옴
  loadAccount = () => {
    this.init = true;

    let temp = getCookie("uai2");

    if (IsValidS(temp))
    {
      if (LOG_ENABLE)
        console.log("user account found");

      const bytes = CryptoJS.AES.decrypt(temp, 'fjecdsoQfwfdeGh*&46$lzp2dr&^1$#H');

      if (IsValidV(bytes))
      {
        let t = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (IsValidV(t.asv))
        {
          this.setSaveAccount(t.asv);

          if (t.asv)
          {
            if (IsValidS(t.id))
              this.setUserId(t.id);
          }
        }

        if (mobileOn)
        {
          if (IsValidV(t.al))
          {
            this.setAutoLogin(t.al);
          }

          if (IsValidS(t.pw))
            this.setUserPW(t.pw);
        }
      }
      else
        this.clearAccountInfo();
    }
    else
    {
      this.clearAccountInfo();

      if (LOG_ENABLE)
      console.log("user account not found");
    }
  };

  //계정정보 클리어
  clearAccountInfo = () => {
    this.userId = "";
    this.userPW = "";
    this.acSave = true;
    this.autoLogin = true;
  };

  get getLoginData () {
    const params = new URLSearchParams();

    if (IsValidS(this.userId))
      params.append("id", this.userId);
    
    if (IsValidS(this.userPW))
      params.append("pw", this.userPW);

    params.append("lt", "0");

    return params;
  }

  //로그인 시도하기
  tryLogin = (loginResultCallback) => {
    this.loginResultCallback = loginResultCallback;

    this.login = true;
    this.errorCode = 0;

    if (LOG_ENABLE)
      console.log("try login : ");

    axios({
      method:"POST",
      url: "login/login.do",
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      // `responseType`은 서버에서 응답할 데이터 타입을 설정합니다.
      // [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseType: 'text', // 기본 값

      // `responseEncoding`은 응답 디코딩에 사용할 인코딩을 나타냅니다.
      // [주의!] 클라이언트 사이드 요청 또는 `responseType`이 'stream'인 경우는 무시합니다.
      responseEncoding: 'utf8', // 기본 값
      data: this.getLoginData
    }).then((res)=>{
        this.setLoginResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.setLoginResult(null);
    });
  }

  //서버로 부터 수신된 로그인 결과를 바탕으로 로그인 데이터 설정
  setLoginResult = (result) => 
  {
    this.login = false;

    if (result == null || result === undefined || result.ret === undefined)
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      this.errorCode = -1;

      if (this.loginResultCallback != null)
      {
        this.loginResultCallback(this.errorCode);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    this.errorCode = result.ret;

    switch(result.ret)
    {
      case 0:
        {
          if (LOG_ENABLE)
            console.log("login succeeded");

          this.loggedIn = true;

          this.validateProfile(result.profile);

          this.profile = result.profile; //프로필 정보를 저장한다.
          
          if (IsValidV(this.profile) && IsValidV(this.profile.idx))
          {
            this.userIdx = this.profile.idx;
            this.compIdx = this.profile.cidx;
            this.userType = this.profile.utype;
            this.ordertm = this.profile.ordertm;
            this.ordertm2 = this.profile.ordertm2;

            if (LOG_ENABLE)
              console.log("user idx : " + this.userIdx);
          }

          this.sid = this.userId;
          this.token = result.token;

          this.needPushToken = result.npt !== 0;
          this.pushTokenIdx = result.pidx;

          if (LOG_ENABLE)
              console.log("user token : " + this.token);

          this.saveToken();

          //계정저장 설정되어 있으면 계정정보도 저장함
          if (this.acSave)
          {
            this.saveAccount();
          }

          if (this.loginResultCallback != null)
          {
            this.loginResultCallback(0);
          }

          return;
        }
      default:
        if (this.loginResultCallback != null)
        {
          this.loginResultCallback(this.errorCode);
        }
        break;
    }
  }
  
  logOut = () => {
    
    //observable변수
    this.loggedIn = false; //서버에로그인되었나?
    this.login = false; //로그인시도중인가?
    this.profile = null; //사용자의 프로필 정보
    this.sessionError = false;

    //비 observable
    this.errorCode = 0; //로그인에러코드
    this.token = null;
    this.sid = null;
    this.timeInfoIdx = -1;

    this.removeToken();
  }

  validateProfile(profileData) {
    
    if (profileData == null)
      return;

    if (IsValidS(profileData.cname))
      profileData.cname = FromBase64(profileData.cname);

    if (IsValidS(profileData.oname))
      profileData.oname = FromBase64(profileData.oname);

    if (IsValidS(profileData.uname))
      profileData.uname = FromBase64(profileData.uname);
  }

  //----------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------
  makeSessionParam = () => {
    const params = new URLSearchParams();
    params.append("rt", "1"); //요청타입
    params.append("sid", this.sid); //요청타입
    params.append("tk", this.token); //요청타입

    return params;
  }

  //세션 리프레시
  refreshSession = () => {
    //이미 주문이 진행중인경우
    if (this.sessionRefreshing || !this.loggedIn || !IsValidS(this.token))
      return;

    this.sessionRefreshing = true;

    axios({
      method:"POST",
      url: "login/refs.do",
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSessionParam()
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("session refresh : " + JSON.stringify(res.data));

      this.sessionRefreshing = false;

      if (res.data.ret !== 0)
      {
        this.profile = null;
        this.loggedIn = false;
        this.sessionError = true;
      }
    }).catch(error=>{
      console.log(error);
      
      this.sessionRefreshing = false;
    });
  }
  //---------------------------------------------------------------------------------------------------
  profileCallback = null;

  //프로필 정보 다시 읽기
  loadProfile = (callback) => {
    this.profileCallback = callback;

    //토큰 정보가 없으면 로컬에서 읽기
    if (!IsValidS(this.token))
    {
      this.loadToken();

      if (!IsValidS(this.token))
      {
        if (callback !== null)
        {
          callback(-2);
          return;
        }
      }
    }

    axios({
      method:"POST",
      url: "login/prf.do",
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("loadProfile : " + JSON.stringify(res.data));

      this.parseProfile(res.data);

    }).catch(error=>{
      console.log(error);
      
      this.parseProfile(null);
    });
  }

  parseProfile = (result) =>
  {
    if (result === null || result === undefined)
    {
      if (this.profileCallback !== null)
        this.profileCallback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
      {
        this.sessionError = true;
      }

      if (this.profileCallback !== null)
        this.profileCallback(result.ret);

      return;
    }

    this.loggedIn = true;
    this.profile = result.profile;

    if (result.ret === 0 && IsValidV(this.profile))
    {
      this.validateProfile(this.profile);
      this.userIdx = this.profile.idx;
      this.compIdx = this.profile.cidx;
      this.userType = this.profile.utype;
      this.ordertm = this.profile.ordertm;
      this.ordertm2 = this.profile.ordertm2;
      this.needPushToken = result.npt !== 0;
      this.pushTokenIdx = result.pidx;
    }
    else
    {
      this.profile = null;

      this.needPushToken = false;
      this.pushTokenIdx = -1;
    }

    if (!IsValidS(this.userId))
    {
      this.loadAccount();
    }

    if (this.profileCallback !== null)
      this.profileCallback(0);
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------
  //사용자 계정 얻기
  get getUserId() {
    return this.userId;
  }

  get getUserPw() {
    return this.userPW;
  }

  //영업담당자인가?
  get isSalesAccount()
  {
    return this.getUserLevel() === 2;
  }

  //사용자 권한 레벨 얻기
  get getUserLevel()
  {
    if (!IsValidV(this.profile))
      return 1000;

    return this.profile.plevel;
  }

  get getUserName()
  {
    if (!IsValidV(this.profile))
      return "";

    return this.profile.uname;
  }

  get getCompanyName()
  {
    if (!IsValidV(this.profile))
      return "";

    return this.profile.cname;
  }

  get isProfileReady()
  {
    return IsValidV(this.profile);
  }

  //마감시간전인지 확인하기
  checkOrderTimeLimit = (hour, minute) =>
  {
    if (this.ordertm > hour)
      return true;

    if (hour > this.ordertm)
      return false;

    return this.ordertm2 > minute;
  }

  getOrderDoneTime = () =>
  {
    if (this.ordertm2 > 0)
      return `${this.ordertm}시 ${this.ordertm2}분`;

    return `${this.ordertm}시`;
  }

  removeToken = () =>
  {
    delCookie("tki");
  }

  saveToken = () =>
  {
    let temp = {
      sid : this.sid,
      token : this.token
    };

    setCookie("tki", CryptoJS.AES.encrypt(JSON.stringify(temp), "ajecdsopqwfdeuh*&56$lzpidr&^1$#n").toString());

    if (LOG_ENABLE)
      console.log("save token");
  }

  loadToken = () =>
  {
    this.token = null;
    this.sid = null;

    let v = getCookie("tki");

    if (!IsValidS(v))
    {
      if (LOG_ENABLE)
        console.log("session info invalid");

      return;
    }

    try
    {
      const bytes = CryptoJS.AES.decrypt(v, 'ajecdsopqwfdeuh*&56$lzpidr&^1$#n');

      let t = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      this.sid = t.sid;
      this.token = t.token;

      if (LOG_ENABLE)
      {
        console.log("token found : id = " + this.sid + "/ tk = " + this.token);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }
  }
  //---------------------------------------------------------------------------------------------------
  timeLoadCallback = null;
  timeInfoIdx = -1;
  
  isTimeInfoReady = () =>
  {
    return this.timeInfoIdx !== -1;
  }

  makeLoadTimeParam = () => {
    const params = new URLSearchParams();
    params.append("rt", "0"); //요청타입

    return params;
  }

  //각종 마감시간 정보 읽기
  loadTimeInfo = (callback) => {
    this.timeLoadCallback = callback;

    axios({
      method:"POST",
      url: "login/ti.do",
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadTimeParam()
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("loadTimeInfo : " + JSON.stringify(res.data));

      this.parseLoadTimeResult(res.data);

    }).catch(error=>{
      console.log(error);
      
      this.parseLoadTimeResult(null);
    });
  }

  parseLoadTimeResult = (result) =>
  {
    if (result === null || result === undefined)
    {
      if (this.timeLoadCallback !== null)
        this.timeLoadCallback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
      {
        this.sessionError = true;
      }

      if (this.timeLoadCallback !== null)
        this.timeLoadCallback(result.ret);

      return;
    }

    if (result.ret === 0)
    {
      if (IsValidV(result.tm))
      {
        this.timeInfoIdx = result.tm.idx;
        this.chatStart = result.tm.cst;
        this.chatEnd = result.tm.cet;
        this.ordertm = result.tm.tm;
        this.ordertm2 = result.tm.tm2;
      }
    }

    if (this.timeLoadCallback !== null)
      this.timeLoadCallback(0);
  }
  //---------------------------------------------------------------------------------------------------
  timeSetCallback = null;

  makeTimeSetParam = (requestType, startTime, endTime) => {
    const params = new URLSearchParams();
    params.append("rt", requestType); //요청타입
    params.append("idx", this.timeInfoIdx.toString()); //요청타입

    if (IsValidV(startTime))
      params.append("st", startTime.toString()); //시간1

    if (IsValidV(endTime))
      params.append("et", endTime.toString()); //시간2

    return params;
  }

  //각종 마감시간 정보 읽기
  setTimeInfo = (requestType, startTime, endTime, callback) => {
    this.timeSetCallback = callback;

    axios({
      method:"POST",
      url: "login/ti.do",
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeTimeSetParam(requestType, startTime, endTime)
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("loadTimeInfo : " + JSON.stringify(res.data));

      this.parseSetTimeResult(res.data);

    }).catch(error=>{
      console.log(error);
      
      this.parseSetTimeResult(null);
    });
  }

  parseSetTimeResult = (result) =>
  {
    if (result === null || result === undefined)
    {
      if (this.timeSetCallback !== null)
        this.timeSetCallback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
      {
        this.sessionError = true;
      }

      if (this.timeSetCallback !== null)
        this.timeSetCallback(result.ret);

      return;
    }

    if (result.ret === 0)
    {
      if (result.rt === "1")
      {
        this.chatStart = parseInt(result.st);
        this.chatEnd = parseInt(result.et);
      }
      else if (result.rt === "2")
      {
        this.ordertm = parseInt(result.st);
        this.ordertm2 = parseInt(result.et);
      }
    }

    if (this.timeSetCallback !== null)
      this.timeSetCallback(0);
  }
  //axios config--------------------------------------------------------------------------
  setHeaderToken = async (config) =>
  {
    if (IsValidS(this.token) && IsValidS(this.sid))
    {
      if (LOG_ENABLE)
        console.log(`set default header : ${config.url}`);

      if (IsValidS(config.url) && (config.url.indexOf(".do") >= 0 || config.url.indexOf("https://test.bravo6.kr:13003") >= 0 || 
        config.url.indexOf("https://api2.bravo6.kr") >= 0))
      {
        config.headers["token"] = this.token;
        config.headers["sid"] = this.sid;
      }      
    }
    
    return config;
  }
}

const LoginStoreInstance = new LoginStore();
const LoginStoreContext = React.createContext(LoginStoreInstance);

const UseLoginStore = () => React.useContext(LoginStoreContext);

export {UseLoginStore, LoginStoreInstance};
