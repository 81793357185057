/*eslint arrow-body-style: ["error", "as-needed"]*/
/*eslint-env es6*/

import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import {LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV,  } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

//주문 관련
class MOrderStore {
  //observable변수
  orderList = null;
  recentItems = null; //수기주문의 최근주문 아이템목록
  //비 observable
  totalOrderCount = 0;
  salesOrderDone = false; //영업담당의 주문마감여부, 조회타입이 영업담당일경우에만 유효함

  clearAll = () =>
  {
    this.orderList = null;
    this.recentItems = null; //수기주문의 최근주문 아이템목록
    this.totalOrderCount = 0;
    this.salesOrderDone = false;

    this.recentItemCallback = null;
    this.loadCallback = null;
    this.addCallback = null;
    this.editCallback = null;
    this.delCallback = null;
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      orderList : observable,
      recentItems : observable,

      clearAll : action,

      clearOrderList : action,
      clearRecentList: action,

      parseRecentResult: action,
      parseLoadResult: action,
      parseAddResult: action,
      parseEditResult: action,
      parseDelResult: action,

      getOrderList: computed,
      getRecentItems: computed,
    });
  }
  
  //주문 목록 클리어
  clearOrderList = () =>
  {
    this.orderList = null; //목록
  }

  clearRecentList = () =>
  {
    this.recentItems = null; //목록
  }

  //주문목록
  get getOrderList()
  {
    return this.orderList;
  }

  //최근주문상품
  get getRecentItems()
  {
    return this.recentItems;
  }

  findOrderByIdx = (idx) =>
  {
    if (this.orderList == null || this.orderList.length < 1)
      return null;

    for (let i = 0; i < this.orderList.length; ++i)
    {
      if (this.orderList[i].idx === idx)
        return this.orderList[i];
    }

    return null;
  }
  //최근상품목록 읽기-------------------------------------------------------------------------------------------------------------------------------------
  recentItemCallback = null;

  makeRecentParam (ridx) {
    const params = new URLSearchParams();

    params.append("ridx", ridx.toString());
    
    return params;
  }

  //주문목록읽기
  loadRecentItems = (ridx, callback) =>
  {
    this.recentItemCallback = callback;

    axios({
      method:"POST",
      url: ("mo/ritem.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeRecentParam(ridx)
    }).then((res)=>{
        this.parseRecentResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseRecentResult(null);
    });
  }

  parseRecentResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseRecentResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.recentItemCallback != null)
        this.recentItemCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (IsValidS(result.items))
      {
        this.recentItems = [];

        for (let i = 0;i < result.items.length; ++i)
        {
          if (!this.findItemFromRecentList(result.items[i].iidx))
          {
            this.decodeOrderItem(result.items[i]);

            this.recentItems.push(result.items[i]);
          }
        }
      }
      else
      {
        this.recentItems = null;
      }
    }
    else
      this.recentItems = null;

    if (this.recentItemCallback != null)
        this.recentItemCallback(result.ret);
  }

  findItemFromRecentList = (itemIdx) =>
  {
    if (!IsValidS(this.recentItems))
      return false;

    for (let i = 0;i < this.recentItems.length; ++i)
    {
      if (this.recentItems[i].iidx === itemIdx)
        return true;
    }

    return false;
  }
  //목록 읽기-------------------------------------------------------------------------------------------------------------------------------------
  loadCallback = null;

  makeLoadParam (rt, sidx, pos, rtime) {
    const params = new URLSearchParams();

    params.append("rt", rt.toString());
    params.append("sidx", sidx.toString());
    params.append("pos", pos.toString());
    params.append("rtime", rtime);
    return params;
  }

  //주문목록읽기
  //rt : 전체(0), 영업담당(1)
  //sidx: 대상영업담당자
  //pos : 읽을 위치
  //rtime : 일자
  loadOrder = (rt, sidx, pos, rtime, callback) =>
  {
    this.loadCallback = callback;

    if (LOG_ENABLE)
    {
      console.log(`rt = ${rt}, sidx = ${sidx}, pos = ${pos}, rtime = ${rtime}`);
    }

    axios({
      method:"POST",
      url: ("mo/list.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadParam(rt, sidx, pos, rtime)
    }).then((res)=>{
        this.parseLoadResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseLoadResult(null);
    });
  }

  parseLoadResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseLoadResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadCallback != null)
        this.loadCallback(-2, false);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    let firstRead = false;

    if (result.ret === 0)
    {
      this.orderList = result.orders;

      if (IsValidS(this.orderList))
      {
        this.orderList.forEach(element => {
          this.decodeOrder(element);
        });
      }
      else
        this.orderList = null;

      if (result.ft === 1)
      {
        this.totalOrderCount = result.tc;
        this.salesOrderDone = result.odone === 1;

        firstRead = true;
      }
    }
    else
      this.orderList = null;

    if (this.loadCallback != null)
        this.loadCallback(result.ret, firstRead);
  }

  decodeOrder = (element) =>
  {
    if (IsValidS(element.cname))
      element.cname = FromBase64(element.cname);

    if (IsValidS(element.oname))
      element.oname = FromBase64(element.oname);

    if (IsValidS(element.addr))
      element.addr = FromBase64(element.addr);

    if (IsValidS(element.sname))
      element.sname = FromBase64(element.sname);

    if (IsValidS(element.items))
    {
      element.items.forEach(item => {
        this.decodeOrderItem(item);
      });
    }
  }

  decodeOrderItem = (element) =>
  {
    if (IsValidS(element.iname))
      element.iname = FromBase64(element.iname);

    /*if (IsValidS(element.alias))
      element.alias = FromBase64(element.alias);*/
  }
  //주문등록-------------------------------------------------------------------------------------------------------------------------------------
  addCallback = null;

  makeAddParam (rtime, sidx, ridx, items) {
    const params = new URLSearchParams();

    params.append("rtime", rtime);
    params.append("sidx", sidx.toString());
    params.append("ridx", ridx.toString());
    params.append("items", items);
    return params;
  }

  //주문등록
  addOrder = (rtime, sidx, ridx, items, callback) =>
  {
    this.addCallback = callback;

    axios({
      method:"POST",
      url: ("mo/od.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeAddParam(rtime, sidx, ridx, items)
    }).then((res)=>{
        this.parseAddResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseAddResult(null);
    });
  }

  parseAddResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseAddResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.addCallback != null)
        this.addCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (IsValidV(result.info))
      {
        this.decodeOrder(result.info);

        ++this.totalOrderCount;

        if (!IsValidS(this.orderList))
        {
          this.orderList = [result.info];
          
        }
        else
        {
          let prevOrder = this.findOrderByIdx(result.info.idx);

          if (prevOrder !== null)
          {
            let temp = [];

            for (let i = 0;i < this.orderList.length; ++i)
            {
              if (this.orderList[i].idx !== result.info.idx)
                temp.push(this.orderList[i]);
              else
                temp.push(result.info);
            }

            this.orderList = temp;
          }
          else
          {
            let temp = this.orderList;

            this.orderList = [result.info, ...temp];
          }
        }
      }
    }

    if (this.addCallback != null)
        this.addCallback(result.ret);
  }
  //상품수정-------------------------------------------------------------------------------------------------------------------------------------
  editCallback = null;

  makeEditParam (requestType, idx, items) {
    const params = new URLSearchParams();

    params.append("rt", requestType.toString());
    params.append("idx", idx.toString());

    if (IsValidS(items))
      params.append("items", items);

    return params;
  }

  //주문수정, requestType : 0 : 주문수정, 1 : 주문을 처리완료상태로 만듬
  editOrder = (requestType, idx, items, callback) =>
  {
    this.editCallback = callback;

    axios({
      method:"POST",
      url: ("mo/edit.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeEditParam(requestType, idx, items)
    }).then((res)=>{
        this.parseEditResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseEditResult(null);
    });
  }

  parseEditResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseEditResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.editCallback != null)
        this.editCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (result.rt === 0)
      {
        if (IsValidV(result.info))
        {
          this.decodeOrder(result.info);
  
          let oldItem = this.findOrderByIdx(result.info.idx);
  
          if (oldItem !== null)
          {
            let temp = [];
  
            for (let i = 0;i < this.orderList.length; ++i)
            {
              if (this.orderList[i].idx !== result.info.idx)
                temp.push(this.orderList[i]);
              else
                temp.push(result.info);
            }
  
            this.orderList = temp;
          }
        }
      }
      else if (result.rt === 1) //주문을 처리완료 상태로 만든경우
      {
        if (IsValidS(this.orderList))
        {
          let temp = [];

          for (let i = 0;i < this.orderList.length; ++i)
          {
            if (this.orderList[i].idx !== result.idx)
            {
              temp.push(this.orderList[i]);
            }
            else
            {
              temp.push({...this.orderList[i], st: 1});
            }
          }

          this.orderList = temp;
        }
      }
      else if (result.rt === 2) //주문을 취소함
      {
        if (IsValidS(this.orderList))
        {
          let temp = [];

          for (let i = 0;i < this.orderList.length; ++i)
          {
            if (this.orderList[i].idx !== result.idx)
            {
              temp.push(this.orderList[i]);
            }
          }

          this.orderList = temp;
        }
      }
    }

    if (this.editCallback != null)
        this.editCallback(result.ret);
  }
  //삭제-------------------------------------------------------------------------------------------------------------------------------------
  delCallback = null;

  makeDelParam (orderIdx, itemIdx) {
    const params = new URLSearchParams();

    params.append("oidx", orderIdx.toString());
    params.append("idx", itemIdx.toString());
    return params;
  }

  //orderIdx : 주문번호, itemIdx : 주문 아이템 번호
  delProduct = (orderIdx, itemIdx, callback) =>
  {
    this.delCallback = callback;

    axios({
      method:"POST",
      url: ("mo/del.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeDelParam(orderIdx, itemIdx)
    }).then((res)=>{
        this.parseDelResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseDelResult(null);
    });
  }

  parseDelResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseDelResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.delCallback != null)
        this.delCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      const order = this.findOrderByIdx(parseInt(result.oidx));

      if (order !== null)
      {
        if (order.items === undefined || order.items.length < 1)
        {
        }
        else
        {
          let newList = [];

          const iidx = parseInt(result.idx);

          for (let i = 0;i < order.items.length; ++i)
          {
            if (order.items[i].idx !== iidx)
            {
              newList.push(order.items[i]);
            }
          }

          order.items = newList;
        }

        this.orderList = this.orderList.slice();
      }
    }

    if (this.delCallback != null)
        this.delCallback(result.ret);
  }
  //미완료주문개수-------------------------------------------------------------------------------------------------------------------------------------
  uncompletedOrderCallback = null;

  makeUCOCParam (sidx, stime, etime) {
    const params = new URLSearchParams();

    params.append("sidx", sidx.toString());
    params.append("stime", stime);
    params.append("etime", etime);

    return params;
  }

  //특정 일자 범위에 완료되지 않은 수기주문의 개수를 구함
  getUnCompletedOrderCount = (sidx, stime, etime, callback) =>
  {
    this.uncompletedOrderCallback = callback;

    axios({
      method:"POST",
      url: ("mo/ucoc.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeUCOCParam(sidx, stime, etime)
    }).then((res)=>{
        this.parseUCOCResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseUCOCResult(null);
    });
  }

  parseUCOCResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseUCOCResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.uncompletedOrderCallback != null)
        this.uncompletedOrderCallback(-2, 0);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (this.uncompletedOrderCallback != null)
      this.uncompletedOrderCallback(result.ret, result.cnt);
  }
}


//-------------------------------------------------------------------------------------------------------------------------------------------------
const MOrderStoreInstance = new MOrderStore();
const MOrderStoreContext = React.createContext(MOrderStoreInstance);

const UseMOrderStore = () => React.useContext(MOrderStoreContext);

export {UseMOrderStore, MOrderStoreInstance};
