import React from "react";
import { ODS_ACCEPT, ODS_REORDER, ODS_UNDELI, ODS_PROCESSED } from "../../Stores/OrderStore";
import { GetImgUrl } from "../../URL/AppUrl"
import { GetDataTime, IsValidS } from "../../Util/Util";
import OrderItemListvomb from "./OrderItemListvomb";

class OrderVoicemb extends React.Component
{
  componentDidMount()
  {
    
  }

  componentWillUnmount()
  {
  }

  getOrderTitle = (order) =>
  {
    if (order.st === ODS_UNDELI)
      return `음성주문(${GetDataTime(order.rdate)})`;
    else if (order.st === ODS_PROCESSED)
      return '주문처리완료';

    
    return `음성주문`;
  }

  getCompanyName = (order) =>
  {
    if (order.ct === 0)
      return `${order.cname}(유흥)`;
    return `${order.cname}(일반)`;
  }

  getOrderStatusMsg = (order) =>
  {
    switch(order.st)
    {
      case ODS_ACCEPT:
        return "이주문은 아직 처리되지 않았습니다. 음성 내용을 청취 후 상품을 등록해주세요.";

      case ODS_REORDER:
        return "재주문 요청됨";

      default:
        return "상품등록완료";
    }
  }

  render()
  {
    return (
      <div className = "ordertablecontmb">
  
        <div className="empsertitle flexcenterrowstart">
  
          <div className="orderadditemcontlmb flexcenterrowstart">
            <img src={GetImgUrl("mbodplay.png")} alt="음성주문" />
            <label className={(this.props.order.st === ODS_UNDELI || this.props.order.st === ODS_REORDER) ? "orderverrfontmb" : "mfontnb"}>&nbsp;{this.getOrderTitle(this.props.order)}</label>
          </div>
        
          {
            this.props.order.st === ODS_UNDELI ? (
              <div className="orderadditemcontrmb flexcenterrowend">
                <button className="bgcolor3 orderundelibtnmb mfontnwb" onClick={() => this.props.handleUndeliOrder(this.props.order.idx, 0)}>
                  당일주문처리
                </button>

                <button className="bgcolor4 orderadditembtnmb mfontnwb" onClick={() => this.props.handleUndeliOrder(this.props.order.idx, 1)}>
                  익일주문처리
                </button>
              </div>
            ):null
            
          }
        </div>

        {
          this.props.order.st !== ODS_UNDELI ?
            (
              <label className={(this.props.order.st === ODS_ACCEPT || this.props.order.st === ODS_REORDER) ? "orderverrfonts" : "ordervnfonts"}>&nbsp;{this.getOrderStatusMsg(this.props.order)}</label>
            ):null
        }

        <table className="emptablemb">
          <thead>
            <tr>
              <th className="emptableheadermb">
                <label className="mfontnw">업체명</label>
              </th>
  
              <th className="emptableheadermb">
                <label className="mfontnw">상세정보 확인</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="emptabledata">
              <td className="ordercellcnamevo">
                <div className="emptablecellin flexcenterrow">
                  <label className="mfontn">{this.getCompanyName(this.props.order)}</label>
                </div>
              </td>
              
              <td className="ordercellunamevo">
                <div className="emptablecellin flexcenterrow">
                  <button className= {this.props.order.st === 0 ? "bgcolor3 orderdetbtnmb mfontnwb btncur" : "bgcolor4 orderdetbtnmb mfontnwb btncur"} onClick={() => this.props.handleDetail(this.props.order)}>상세정보</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
  
        { IsValidS(this.props.order.items) ? <OrderItemListvomb itemList = {this.props.order.items}  order = {this.props.order} handleEditVoItem={this.props.handleEditVoItem}/> : null}
  
        {/*<div className="orderline"/>*/}
      </div>
    );
  }
}

export default OrderVoicemb;