import axios from "axios";
import { LOG_ENABLE } from "../URL/AppUrl";

function NumberFormatString(num)
{
  if (num < 10)
    return "0" + num;

  return num.toString();
}

function DownloadFile(url, fileName, logPrinter = null)
{
  try
  {
    axios({
      url: url,
      method: 'GET',
      withCredentials: false,
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    })
    .catch(error=>{

      if (logPrinter)
        logPrinter(error);
      else
        console.log(error);
        
      alert(fileName + " 파일을 다운로드중 오류가 발생했습니다.");
    });
  }
  catch(e)
  {
    alert(e.toString());
  }
}

function UploadFile(userIdx, uploadType, file, callback)
{
  const formData = new FormData();

  formData.append("file", file);
  formData.append("uidx", userIdx.toString());
  formData.append("rt", uploadType.toString());

  axios({
    method:"POST",
    url: "upload/up.do",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
    data: formData
  })
  .then((res)=>{

    /*if (LOG_ENABLE)
    {
      console.log("upload done");
      console.log(JSON.stringify(res.data));
    }*/

    //결과값은 결과 코드와, 성공일경우 업로드된 서버사운드파일이름이다.
    if (!IsValidV(res.data) || res.data.ret === undefined || res.data.ret !== 0)
    {
      if (LOG_ENABLE)
        console.log("invalid result");

      callback(false, null, null);
      return;
    }
    else //업로드에 성공했다.
    {
      if (IsValidS(res.data.files))
      {
        for (let i = 0;i < res.data.files.length; ++i)
        {
          if (LOG_ENABLE)
            console.log(JSON.stringify(res.data.files[i]));
        }

        callback(true, res.data.files[0].oldName, res.data.files[0].newName);
      }
      else
        callback(false, null, null);
    }
  })
  .catch(error=>{

    if (LOG_ENABLE)
      console.log(error);

    callback(false, null, null);
  });
}

function ToBase64(v)
{
  if (v === undefined || v=== null || v.length < 1)
    return v;

  return Buffer.from(v, "utf8").toString("base64");
}

function FromBase64(v)
{
  if (v === undefined || v=== null || v.length < 1)
    return v;

  return Buffer.from(v, "base64").toString("utf-8");
}

function IsValidS(v)
{
  return v !== undefined && v !== null && v.length > 0;
}

function IsValidV(v)
{
  return v !== undefined && v !== null;
}

//사업자 번호를 000-00-00000 형식으로 얻음
function GetFormattedCNum(cnum)
{
  if (!IsValidS(cnum))
    return "";

  if (cnum.length !== 10)
    return cnum;

  return cnum.substring(0, 3) + "-" + cnum.substring(3, 5) + "-" + cnum.substring(5, 10);
}

//사업자 번호를 000-00-00000 형식으로 얻음
function GetFormattedCNum2(cnum)
{
  if (!IsValidS(cnum))
    return "";

  let temp = cnum;

  if (temp.indexOf('-') >= 0)
  {
    temp = temp.replace(/-/gi, '');
  }

  if (isNaN(temp))
  {
    return cnum;
  }
  
  if (temp.length < 3)
  {  
    return temp;
  }

  let result = temp.substring(0, 3) + '-';

  if (temp.length >= 5)
  {
    result += temp.substring(3, 5) + '-';
  }
  else
  {
    return result + temp.substring(3, temp.length);
  }

  return result + temp.substring(5, temp.length);
}

//전화 번호를 000-0000-0000 형식으로
//051-234-1111
function GetFormattedPhone(phone)
{
  if (!IsValidS(phone))
    return "";

  if (phone.length === 8)
  {
    return phone.substring(0, 4) + "-" + phone.substring(4, 8);
  }
  if (phone.length === 9)
  {
    return phone.substring(0, 2) + "-" + phone.substring(2, 5) + "-" + phone.substring(5, 9);
  }
  if (phone.length === 10)
  {
    if (phone.indexOf("02") === 0)
      return phone.substring(0, 2) + "-" + phone.substring(2, 6) + "-" + phone.substring(6, 10);

    return phone.substring(0, 3) + "-" + phone.substring(3, 6) + "-" + phone.substring(6, 10);
  }
  else if (phone.length === 11)
  {
    return phone.substring(0, 3) + "-" + phone.substring(3, 7) + "-" + phone.substring(7, 11);
  }
  else if (phone.length === 12)
  {
    return phone.substring(0, 4) + "-" + phone.substring(4, 8) + "-" + phone.substring(8, 12);
  }

  return phone;
}

function GetFormattedPhone2(phone)
{
  if (!IsValidS(phone))
    return "";

  let temp = phone;

  if (temp.indexOf("-") >= 0)
  {
    temp = temp.replace(/-/gi, "");
  }

  if (temp.length < 3)
  {
    if (temp.indexOf("02") === 0)
    {
      return temp.replace("02", "02-");
    }

    return phone;
  }

  if (temp.length >= 3)
  {
    if (temp.length >= 4)
    {
      if (temp.indexOf("0505") === 0)
      {
        let temp2 = temp.substring(0, 4);
        temp2 += "-";
  
        let lg = temp.length - 4;
  
        if (temp.length > 4)
        {
          if (lg < 4)
          {
            temp2 += temp.substring(4, temp.length);

            return temp2;
          }
          else if (lg <= 7)
          {
            temp2 += temp.substring(4, 7);
            temp2 += "-";
            temp2 += temp.substring(7, temp.length);
  
            return temp2;
          }
          
          temp2 += temp.substring(4, 8);
          temp2 += "-";
          temp2 += temp.substring(8, temp.length);
          return temp2;
        }
  
        if (temp.length === 4)
          return temp2;
  
        temp2 += temp.substring(4, temp.length);
  
        return temp2;
      }
      else if (temp.indexOf("1566") === 0 || temp.indexOf("1588") === 0 || temp.indexOf("1600") === 0 || temp.indexOf("1670") === 0 ||
      temp.indexOf("1577") === 0 || temp.indexOf("1899") === 0 || temp.indexOf("1522") === 0 || temp.indexOf("1544") === 0 || temp.indexOf("1644") === 0 ||
      temp.indexOf("1661") === 0 || temp.indexOf("1599") === 0 || temp.indexOf("1688") === 0 ||  temp.indexOf("1666") === 0 ||  temp.indexOf("1855") === 0 || 
      temp.indexOf("1811") === 0 || temp.indexOf("1877") === 0)
      {
        let temp2 = temp.substring(0, 4);
        temp2 += "-";
  
        if (temp.length === 4)
          return temp2;
  
        temp2 += temp.substring(4, temp.length);
  
        return temp2;
      }
    }

    if (temp.indexOf("010") === 0 || temp.indexOf("011") === 0 || temp.indexOf("012") === 0 || temp.indexOf("016") === 0 || temp.indexOf("017") === 0 || 
      temp.indexOf("018") === 0 || temp.indexOf("019") === 0 || temp.indexOf("020") === 0 || temp.indexOf("030") === 0 || 
      temp.indexOf("040") === 0 || temp.indexOf("050") === 0 || temp.indexOf("060") === 0 || temp.indexOf("070") === 0 || 
      temp.indexOf("080") === 0 || temp.indexOf("090") === 0 || temp.indexOf("051") === 0 || temp.indexOf("053") === 0 || temp.indexOf("032") === 0 ||
      temp.indexOf("062") === 0 || temp.indexOf("042") === 0 || temp.indexOf("052") === 0 || temp.indexOf("044") === 0 || temp.indexOf("031") === 0 ||
      temp.indexOf("033") === 0 || temp.indexOf("043") === 0 || temp.indexOf("041") === 0 || temp.indexOf("063") === 0 || temp.indexOf("061") === 0 ||
      temp.indexOf("054") === 0 || temp.indexOf("055") === 0 || temp.indexOf("064") === 0)
    {
      let temp2 = temp.substring(0, 3);

      temp2 += "-";

      let lg = temp.length - 3;

      if (temp.length > 4)
      {
        if (lg < 4)
        {
          temp2 += temp.substring(3, temp.length);

          return temp2;
        }
        else if (lg <= 7)
        {
          temp2 += temp.substring(3, 6);
          temp2 += "-";
          temp2 += temp.substring(6, temp.length);

          return temp2;
        }
        
        temp2 += temp.substring(3, 7);
        temp2 += "-";
        temp2 += temp.substring(7, temp.length);
        return temp2;
      }

      if (temp.length === 3)
        return temp2;

      temp2 += temp.substring(3, temp.length);

      return temp2;
    }
    else if (temp.indexOf("02") === 0)
    {
      let temp2 = temp.substring(0, 2);

      temp2 += "-";

      let lg = temp.length - 2;

      if (temp.length > 4)
      {
        if (lg < 4)
        {
          temp2 += temp.substring(2, temp.length);

          return temp2;
        }
        else if (lg <= 7)
        {
          temp2 += temp.substring(2, 5);
          temp2 += "-";
          temp2 += temp.substring(5, temp.length);

          return temp2;
        }
        
        temp2 += temp.substring(2, 6);
        temp2 += "-";
        temp2 += temp.substring(6, temp.length);
        return temp2;
      }

      if (temp.length === 2)
        return temp2;

      temp2 += temp.substring(2, temp.length);

      return temp2;
    }
  }
  
  return phone;
}


function GetChatTime(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  temp = temp[1].split(":");

  if (temp.length < 3)
    return dateInfo;

  if (parseInt(temp[0]) < 12)
  {
    return `오전 ${temp[0]}:${temp[1]}`;
  }
  else
  {
    return `오후 ${temp[0]}:${temp[1]}`;
  }
}

function GetDataTime(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  temp = temp[1].split(":");

  if (temp.length < 3)
    return dateInfo;

  return `${temp[0]}:${temp[1]}:${temp[2]}`;
}

function GetDataDate(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  return temp[0];
}

function isImageFile(name)
{
  if (!IsValidS(name))
    return false;

  try
  {
    const t = name.toUpperCase();

    if (t.indexOf(".PNG") >= 0 || t.indexOf(".JPG") >= 0 || t.indexOf(".JPEG") >= 0 || t.indexOf(".BMP") >= 0 || t.indexOf(".GIF") >= 0 || t.indexOf(".TIF") >= 0)
      return true;

    return false;
  }
  catch(e)
  {
    return false;
  }
}

function GetConvertedSerial(sn)
{
  let temp = sn;

  if (!IsValidS(temp))
  {
    return "";
  }

  if (temp.length !== 13)
  {
    return temp;
  }

  let temp2 = "";

  for (let i = 0;i < temp.length; ++i)
  {
    if (i === 6)
    {
      temp2 += "-";
    }
    
    if (i < 6)
    {
      temp2 += temp.charAt(i);
    }
    else
    {
      temp2 += "*";
    }
  }

  return temp2;
}

function GetBankName(bankCode)
{
  let temp=["국민은행", "기업은행", "농협", "신한은행", "산업은행", "우리은행", "한국씨티은행", "하나은행", "SC제일은행", "경남은행", "광주은행", 
    "대구은행", "도이치은행", "부산은행", "저축은행", "새마을금고", "수협은행", "신협중앙회", "우체국", "전북은행", "제주은행", "케이뱅크", "카카오뱅크", "토스뱅크"];

  if (bankCode < 1 || bankCode > 24)
    return "알수없음";

  return temp[bankCode -1];
}

function GetComProductTypeName(itemTypeCode)
{
  switch(itemTypeCode)
  {
    case 1:
      return "소주";
    case 2:
      return "맥주";
    case 3:
      return "수입맥주";
    case 4:
      return "생맥주";
    case 5:
      return "양주";
    case 6:
      return "와인";
    case 7:
      return "사케";
    case 8:
      return "기타";
    default:
      return "알수없음";
  }
}

function GetProductUnitName(unitCode)
{
  switch(unitCode)
  {
    case 0:
      return "박스";
    case 1:
      return "병";
    case 2:
      return "박스/병";
    case 3:
      return "박스/개";
    case 4:
      return "개";
    default:
      return "";
  }
}

function GetProductUsesName(usesCode)
{
  switch(usesCode)
  {
    case 0:
      return "유흥";
    case 1:
      return "일반";
    case 2:
      return "공통";
    default:
      return "";
  }
}

function GetProductStatus(statusCode)
{
  switch(statusCode)
  {
    case 0:
      return '판매중';
    case 1:
      return '판매중지됨';
    default:
      return '';
  }
}

//공통상품의 판매 분류를 특정회사의 판매 분류 정보로 변환한다.
function ConvertItemCategoryToUnit(companyIdx, itemCategory)
{
  if (companyIdx === 37) //태광상사, 공통상품의 판매 분류와 태광상사의 판매 분류를 변환해줘야함
  {
    switch(itemCategory)
    {
      case 1://소주
        return 1;
      case 2://맥주
        return 2;
      case 3://수입맥주
        return 3;
      case 4://생맥주
        return 4;
      case 5://양주
        return 5;
      case 6://와인
        return 10;
      case 7://사케
        return 12;
      case 100://기타
      default:
        return 15;
    }
  }
  else if (companyIdx === 186) //동행주류, 공통상품의 판매 분류와 동행주류의 판매 분류를 변환해줘야함
  {
    switch(itemCategory)
    {
      case 1://소주
        return 1;
      case 2://맥주
        return 2;
      case 3://수입맥주
        return 3;
      case 4://생맥주
        return 4;
      case 5://양주
        return 6;
      case 6://와인
        return 5;
      case 7://사케
        return 7;
      case 100://기타
      default:
        return 11;
    }
  }
  else //기타 업체는 공통상품의 판매 분류와 일치함
  {
    return itemCategory;
  }
}

function OrderTimeToString(orderTime)
{
  let v = orderTime.toString();

  if (v.length !== 8)
    return v;

  return `${v.substring(0, 4)}-${v.substring(4, 6)}-${v.substring(6, 8)}`;
}

export {NumberFormatString, DownloadFile, UploadFile, ToBase64, FromBase64, IsValidS, IsValidV, GetFormattedCNum, GetFormattedCNum2, GetFormattedPhone, GetFormattedPhone2, GetChatTime,
  GetDataTime,GetDataDate, isImageFile, GetConvertedSerial, GetBankName, GetComProductTypeName, GetProductUnitName, GetProductUsesName, GetProductStatus, ConvertItemCategoryToUnit,
  OrderTimeToString};