import { IsValidS } from "../../Util/Util";

function GetItemVolume(item)
{
  if (IsValidS(item.ivol))
  {
    if (item.ivol.indexOf('g') >= 0)
      return item.ivol;

    return `${item.ivol}ML`;
  }

  return "";
}

function GetItemBottleCount(item)
{
  if (item.unit === 3 || item.unit === 4)
  {
    return `${item.ecnt}개`;
  }

  return `${item.ecnt}병`;
}

//음성 주문용 아이템
const OrderItemvomb = (props) =>
{
  return (
    <tr className="emptabledata">
      <td className="ordercellitemcodevomb">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.item.icode}</label>
        </div>
      </td>
      
      <td className="ordercellitemnamevomb">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.item.iname}</label>
        </div>
      </td>

      <td className="ordercellitemvolmb">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{GetItemVolume(props.item)}</label>
        </div>
      </td>

      <td className="ordercellitemcountvomb">
        <div className="emptablecellin flexcentercol">
          <label className="fontn">{props.item.bcnt > 0 ? `${props.item.bcnt}박스` : ''}</label>
          <label className="fontn">{props.item.ecnt > 0 ? GetItemBottleCount(props.item) : ''}</label>
        </div>
      </td>

      <td className="ordercellitemeditmb">
        <div className="emptablecellin flexcenterrow">

          <button className="bgcolor1 orderitemvbtn fontnwb" 
            onClick={() => {
              if (props.handleEditVoItem)
                props.handleEditVoItem(props.orderIdx, props.item);
            }}>
            수정
          </button>

        </div>
      </td>
    </tr>
  );
};

export default OrderItemvomb;