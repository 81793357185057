import { GetImgUrl } from "../../URL/AppUrl";
import { GetDataDate } from "../../Util/Util";

function IsAddFileValid(noticeInfo)
{
  return noticeInfo.afile !== undefined && noticeInfo.afile !== null && noticeInfo.afile !== "n";
}

function IsAddImgValid(noticeInfo)
{
  return noticeInfo.aimg !== undefined && noticeInfo.aimg !== null && noticeInfo.aimg !== "n";
}

function GetNoticeDate(noticeInfo)
{
  return GetDataDate(noticeInfo.rdate);
}

const NoticeItem = (props) =>
{
  return (
    <div className = "noticeitem flexcenterrow">

      {/**공지번호 */}
      <div className = "noticeitemnum flexcenterrow" >
        <label className="fontn">{props.notice.idx}</label>
      </div>

      {/**공지타입 */}
      <div className = "noticeitemtype flexcenterrow" >
      {
        props.notice.ntype === 0? (
          <div className = "noticeitemtypebg flexcenterrow" >
            <img src={GetImgUrl("ntcspk.png")} alt="전체공지" />
            <label className="fontn">전체 공지사항</label>
          </div>):
          (
            <div className = "noticeitemtypebg flexcenterrow" >
              <label className="fontn">{props.companyName}</label>
            </div>
          )
      }
      </div>
      
      {/**공지제목 */}
      <div className = "noticeitemtitle flexcenterrowstart" >
        <label className={props.notice.ntype === 0 ? "btncur fontlargeb" : "btncur fontmidb"} onClick={() => props.handleSelect(props.notice.idx)}>{props.notice.title}</label>
      </div>

      {/**공지파일 */}
      <div className = "noticeitemfile flexcenterrowend" >
        {IsAddFileValid(props.notice) ? (<img src={GetImgUrl("ntcffile.png")} alt="첨부이미지" className="ntcitemimg"/>) : null}
        {IsAddImgValid(props.notice) ? (<img src={GetImgUrl("ntcfimg.png")} alt="첨부파일" className="ntcitemimg"/>) : null}
      </div>

      {/**공지일자 */}
      <div className = "noticeitemdate flexcenterrowend" >
        <label className="fontn">{GetNoticeDate(props.notice)}</label>
      </div>
    </div>
  );
}

export default NoticeItem;