import React, { useState } from 'react';
import { GetImgUrl } from '../../URL/AppUrl';
import "./StaffAdd.css";

const StaffInp = (props) =>
{
  return (
    <div className="staffaddinpcont flexcenterrow">

      <div className="staffaddinpleft flexcenterrowstart">
        <label className="fontlargeb">{props.title}</label>
      </div>
      
      <div className="staffaddinpright flexcenterrowend">
        <input type={props.pw ? "password" : "text"} onChange={(e) => props.handleEdit(e.target.value)} value = {props.editValue} 
          className="staffinp fontn" disabled={props.disabled}
          maxLength={props.maxlen}
        />
      </div>

    </div>
  );
}

const StaffAdd = (props) => {
  const[id, setId] = useState(props.id);
  const[pw, setPw] = useState(props.pw);
  const[name, setName] = useState(props.name);
  const[phone, setPhone] = useState(props.phone);
  const[email, setEmail] = useState(props.email);
  const[plevel, setPlevel] = useState(props.plevel);

  return (
    <div className="staffaddcont flexcentercol">
      <div className="staffaddcontent flexcentercolstart">
        <div className="staffclose flexcenterrowend">
          <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={props.handleClose} className="btncur"/>
        </div>

        <div className="stafftitle flexcenterrow">
          <img src={GetImgUrl("uicon.png")} alt="직원 정보" onClick={props.handleClose}/>
          <label className="fontlargeb">{props.title}</label>
        </div>

        <StaffInp pw={false} title="ID" handleEdit = {setId} editValue={id} disabled={props.staffIdx !== -1} maxlen="20"/>
        <StaffInp pw={true} title="비밀번호" handleEdit = {setPw} editValue={pw} maxlen="20"/>
        <StaffInp pw={false} title="이름" handleEdit = {setName} editValue={name} maxlen="20"/>
        <StaffInp pw={false} title="휴대폰 번호" handleEdit = {setPhone} editValue={phone} maxlen="15"/>
        <StaffInp pw={false} title="메일 주소" handleEdit = {setEmail} editValue={email}  maxlen="30"/>

        <div className="staffaddinpcont flexcenterrow">

          <div className="staffaddinpleft flexcenterrowstart">
            <label className="fontlargeb">권한</label>
          </div>
          
          <div className="staffaddinprightl flexcenterrowstart">
            <select className="staffaddsel fontn" 
              onChange={(e) => {
                  console.log(e.target.value);
                  setPlevel(e.target.value);
              }}

              value={plevel}

              disabled={(props.plevel === 2 || props.utype === 1) && props.staffIdx !== -1}
            >
              <option value="-1">권한 레벨을 선택하세요.</option>
              <option value="0">Admin</option>
              <option value="1">일반관리자</option>
              <option value="2">영업담당자</option>
              <option value="3">관리담당자</option>
            </select>
          </div>
        </div>

        <div className="flexcenterrow staffaddbtnarea">
          {
            props.staffIdx > 0 && props.plevel === 3 ? (
              <button className="bgcolor3 staffaddbtn fontnwb" onClick={() => props.editManagedSales(props.staffIdx)}>
                관리하는 영업담당자 편집
              </button>    
            ) : null
          }
          <button className="bgcolor3 staffaddbtn fontnwb" onClick={() => props.addStaff(props.staffIdx, id, pw, name, phone, email, plevel)}>
            {props.btntitle}
          </button>
        </div>
      </div>
    </div>
  );
}

export default StaffAdd;