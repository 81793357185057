import { IsValidS } from "../../Util/Util";
import MnuOrderItemInfoedbmb from "./MnuOrderItemInfoedbmb";

//편집 버튼이 있는
const MnuOrderItemsedb = (props) =>
{
  let items = null;

  if (IsValidS(props.order.items))
  {
    items = props.order.items.map(item => {
      return (
        <MnuOrderItemInfoedbmb key={item.idx} order={props.order} item = {item} handleEditItemMobile = {props.handleEditItemMobile} orderIdx={props.orderIdx}/>
      );
    });
  }

  return(
    <table className="mnuoditemtable">
      <thead>
        <tr>
          <th className="emptableheader">
            <label className="mfontnw">상품번호</label>
          </th>

          <th className="emptableheader">
            <label className="mfontnw">상품명</label>
          </th>

          <th className="emptableheader">
            <label className="mfontnw">용량</label>
          </th>

          <th className="emptableheader">
            <label className="mfontnw">주문갯수</label>
          </th>

          <th className="emptableheader">
            <label className="mfontnw">수정/삭제</label>
          </th>
        </tr>
      </thead>
      <tbody>
        {items}
      </tbody>
    </table>
  );
}

export default MnuOrderItemsedb;