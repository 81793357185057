import React, { useState } from 'react';
import { GetImgUrl, LOG_ENABLE } from '../../URL/AppUrl';

import "../../App.css";
import "../Emp/StaffAdd.css";
import "./RetAdd.css";
import { GetFormattedCNum2, GetFormattedPhone2, IsValidS } from '../../Util/Util';

const RetInpmb = (props) =>
{
  return (
    <div className="flexcenterrow retaddinpcontmb">
      <label className="mfontnb">{props.title}</label>
      <input type="text" 
        onChange={(e) => props.handleEdit(e.target.value)}
        value = {props.editValue}
        className= {props.lg ? "retaddinplmb mfontn" : "retaddinpsmb mfontn"} 
        maxLength={props.maxlen}
      />
    </div>
  );
}

const RetAddmb = (props) => {
  const[ctype, setCtype] = useState(props.ctype);
  const[btype, setBtype] = useState(props.btype);
  const[cname, setCname] = useState(props.cname);
  const[oname, setOname] = useState(props.oname);
  const[cphone, setCphone] = useState(props.cphone ? GetFormattedPhone2(props.cphone) : props.cphone);
  const[cnum, setCnum] = useState(props.cnum ? GetFormattedCNum2(props.cnum) : props.cnum);
  const[sid, setSid] = useState(props.sid);
  //const[pid, setPid] = useState(props.pid);
  const[addr, setAddr] = useState(props.addr);
  //const[email, setEmail] = useState(props.email);
  const[ct, setCT] = useState(props.ct);
  const[phoneLength, setPhoneLength] = useState(props.cphone ? GetFormattedPhone2(props.cphone).length : 0);
  const[cnumLength, setCnumLength] = useState(props.cnum ? GetFormattedCNum2(props.cnum).length : 0);

  let salesList = null;

  if (IsValidS(props.sales))
  {
    salesList = props.sales.map(item => {
      return (
        <option key = {item.idx} value={item.cid}>{item.uname}</option>
      );
    });
  }

  return (
    <div className="retaddcontmb flexcenterrow">
      <div className="retaddwinmb flexcentercolstart">

        <div className="stafftitlemb flexcenterrow">
          <div className="dcell1"></div>
          <div className="dcell8 flexcenterrow">
            <img src={GetImgUrl("mbrshop.png")} alt="거래처 정보" onClick={props.handleClose}/>
            <label className="mfontnb">{props.title}</label>  
          </div>

          <div className="dcell1">
            <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={props.handleClose} className="btncur"/>  
          </div>
          
        </div>

        <RetInpmb title="상호" handleEdit = {setCname} editValue={cname} lg={true} maxlen="40"/>
        <RetInpmb title="대표자명" handleEdit = {setOname} editValue={oname} maxlen="20"/>
        <RetInpmb title="대표 전화번호" 
          handleEdit = {v => {
            if (v.length > phoneLength)
            {
              setPhoneLength(v.length);
              setCphone(GetFormattedPhone2(v))
            }
            else
            {
              setPhoneLength(v.length);
              setCphone(v)
            }
          }} 
          editValue={cphone}
          maxlen="15"
        />
        <RetInpmb title="사업자 번호" 
          handleEdit = {v =>{
            if (v && v.length > cnumLength)
            {
              setCnumLength(v.length);
              setCnum(GetFormattedCNum2(v));
            }
            else
            {
              setCnumLength(v.length);
              setCnum(v);
            }
          }}
          editValue={cnum}
          maxlen="40"
        />

        <div className="flexcenterrow retaddinpcontmb">
          <label className="mfontnb">영업 담당자</label>

          <select className="retaddinpsmb mfontn  btncur"
            value={sid}
            onChange={(e) => {
              if (LOG_ENABLE)
                console.log(e.target.value);

                setSid(e.target.value);
            }}
          >
            {salesList}
          </select>  
        </div>

        {/*
        <div className="flexcenterrow retaddinpcontmb">
          <label className="mfontnb">관리 담당자</label>

          <select className="retaddinpsmb mfontn  btncur"
            value={pid}
            onChange={(e) => {
              if (LOG_ENABLE)
                console.log(e.target.value);

                setPid(e.target.value);
            }}
          >
            {salesList}
          </select>  
        </div> */}

        <RetInpmb title="업종" handleEdit = {setCtype} editValue={ctype}  maxlen="15"/>
        <RetInpmb title="업태" handleEdit = {setBtype} editValue={btype}  maxlen="15"/>
        <RetInpmb title="주소" handleEdit = {setAddr} editValue={addr} lg={true}  maxlen="150"/>

        <div className="flexcenterrow retaddinpcontmb">
          <label className="mfontnb">업체구분</label>

          <select className="retaddinpsmb mfontn  btncur"
            value={ct}
            onChange={(e) => {
              if (LOG_ENABLE)
                console.log(e.target.value);

              setCT(parseInt(e.target.value));
            }}
          >
            <option value="0">유흥업체</option>
            <option value="1">일반업체</option>
          </select>  
        </div>

        <button className="bgcolor3 retaddpopbtnmb mfontnwb btncur" onClick={() => props.addRet(props.retIdx, ctype,btype,cname,oname,cphone,cnum,sid,/*pid*/sid,addr,/*email*/'n',ct)}>
          {props.btntitle}
        </button>

      </div>
    </div>
  );
}

export default RetAddmb;