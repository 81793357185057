import { GetImgUrl } from "../../URL/AppUrl";
import "./Loading.css";

const Loading = () =>
{
  return (
    <div className="ldbg">
      <img src={GetImgUrl("loading.svg")} alt="로딩중" className="ldimg"/>
    </div>
  );
}

export default Loading;