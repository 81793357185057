import { Fragment } from "react";
import { GetImgUrl } from "../../URL/AppUrl";
import { GetDataDate } from "../../Util/Util";

function IsAddFileValid(noticeInfo)
{
  return noticeInfo.afile !== undefined && noticeInfo.afile !== null && noticeInfo.afile !== "n";
}

function IsAddImgValid(noticeInfo)
{
  return noticeInfo.aimg !== undefined && noticeInfo.aimg !== null && noticeInfo.aimg !== "n";
}

function GetNoticeDate(noticeInfo)
{
  return GetDataDate(noticeInfo.rdate);
}

const NoticeItemmb = (props) =>
{
  return (
    <div className="noticeitemcontmb flexcentercol btncur" onClick={() => props.handleSelect(props.notice.idx)}>
      <div className = "noticeitemmb flexcenterrow">

        {/**공지번호 */}
        <div className = "noticeitemnummb flexcenterrow" >
          <label className="mfontnb">{props.notice.idx}</label>
        </div>

        {/**공지타입 */}
        <div className = "noticeitemtypemb flexcentercol" >
        {
          props.notice.ntype === 0? (
            <Fragment>
              <div className="cnthalfv flexcenterrowstart">
                <label className="btncur mfontnb">{props.notice.title}</label>
              </div>
              <div className="cnthalfv flexcenterrowstart">
                <img src={GetImgUrl("ntcspk.png")} alt="전체공지" />
                <label className="mfontn">전체 공지사항</label>
              </div>
            </Fragment>
            ):
            (
              <Fragment>
              <div className="cnthalfv flexcenterrowstart">
                <label className="btncur mfontn">{props.notice.title}</label>
              </div>
              <div className="cnthalfv flexcenterrowstart">
                <label className="mfontn">{props.companyName}</label>
              </div>
            </Fragment>
            )
        }
        </div>

        <div className="noticeaddfareamb">
          <div className="cnthalfv flexcenterrowend">
            {IsAddImgValid(props.notice) ? (<img src={GetImgUrl("mbntcimage.png")} alt="첨부파일" className="ntcitemimgmb"/>) : null}
            {IsAddFileValid(props.notice) ? (<img src={GetImgUrl("mbntcclip.png")} alt="첨부이미지" className="ntcitemimgmb"/>) : null}
          </div>
          <div className="cnthalfv flexcenterrowend">
            <label className="mfontn">{GetNoticeDate(props.notice)}</label>
          </div>
        </div>
      </div>

      <div className="ntcitemhlinemb"></div>
    </div>
    
  );
}

export default NoticeItemmb;