import { useState, Fragment } from "react";

const ItemCountInfo = (props) =>
{
  if (props.edit)
  {
    return (
      <Fragment>
        {
          props.item.unit === 0 || props.item.unit === 2 || props.item.unit === 3 ? 
          (
            <div className="flexcenterrow cntfullh">
              <input type="number" onChange={(e) => props.setBoxCount(parseInt(e.target.value))} value = {props.boxCount} className="orderitemvoinp" placeholder="개수입력"/>
              <label className="fontn">박스</label>
            </div>
          ) : null
        }
        
        {
          props.item.unit === 1 || props.item.unit === 2 || props.item.unit === 4 ? 
          (
            <div className="flexcenterrow cntfullh">
              <input type="number" onChange={(e) => props.setBottleCount(parseInt(e.target.value))} value = {props.bottleCount} className="orderitemvoinp" placeholder="개수입력"/>
              <label className="fontn">&nbsp;&nbsp;&nbsp;병</label>
            </div>
          ) : null
        }
      </Fragment>
    );
    
  }
  else
  {
    return (
      <Fragment>
        {
          props.item.bcnt > 0 ? 
          (
            <label className="fontn">{`${props.item.bcnt}박스`}</label>
          ) : null
        }
        
        {
          props.item.ecnt > 0 ? 
          (
            <label className="fontn">{`${props.item.ecnt}병`}</label>
          ) : null
        }
      </Fragment>
    );
  }
}

const MnuOrderItemInfoedb = (props) =>
{
  const [edit, setEdit] = useState(false);
  const [boxCount, setBoxCount] = useState(props.item.bcnt);
  const [bottleCount, setBottleCount] = useState(props.item.ecnt);

  return(
    <tr className="emptabledata">
      <td className="mnuordercell">
        <div className="mnuordercellitem flexcenterrow">
          <label className="fontn">{props.item.icode}</label>
        </div>
      </td>
      
      <td className="mnuordercell">
        <div className="mnuordercellitem flexcenterrow">
          <label className="fontn">{props.item.iname}</label>
        </div>
      </td>

      <td className="mnuordercell">
        <div className="mnuordercellitem flexcenterrow">
          <label className="fontn">{`${props.item.ivol}ml`}</label>
        </div>
      </td>

      <td className="mnuordercell">
        <div className="mnuordercellitem flexcentercol">
          <ItemCountInfo item={props.item} edit={edit} boxCount={boxCount} bottleCount={bottleCount} setBoxCount={setBoxCount} setBottleCount={setBottleCount}/>
        </div>
      </td>

      <td className="mnuordercell">
        <div className="mnuordercellitem flexcentercol">
          <button className="bgcolor1 mnuodeditbtn2 flexcenterrow btncur  fontnwb" 
            onClick={() => { 
              if (edit)
              {
                setEdit(false);
                //아이템 수정요청
                props.handleEdit(props.orderIdx, props.item, boxCount, bottleCount);
              }
              else
              {
                setEdit(true);
                //아이템 편집모드
                setBoxCount(props.item.bcnt);
                setBottleCount(props.item.ecnt);
              }
            }}
          >
            {edit ? "확인" : "수정"}
          </button>
        </div>
      </td>

      <td className="mnuordercell">
        <div className="mnuordercellitem flexcentercol">
          <button className="bgcolor2 mnuodeditbtn2 flexcenterrow btncur fontnwb" 
            onClick={() => { 
              if (edit)
              {
                setEdit(false);
              }
              else
              {
                //아이템 삭제 요청
                props.handleDelete(props.orderIdx, props.item);
              }
            }}
          >
            {edit ? "취소" : "삭제"}
          </button>
        </div>
      </td>

    </tr>
  );
}

export default MnuOrderItemInfoedb;