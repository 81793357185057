import {isMobile} from 'react-device-detect';

const APP_VER = "1.3.0";

const TEST_ENABLE = false;
const LOG_ENABLE = TEST_ENABLE;

//api호출 경로
const BASE_URL_TEST = "https://test.bravo6.kr/LSSer/";
const BASE_URL_LIVE = "https://judo.bravo6.kr/LSSer/";

//api2호출 경로
const API2_URL_TEST = "https://test.bravo6.kr:13003/";
const API2_URL_LIVE = "https://api2.bravo6.kr/";

//주도 웹사이트 이미지 경로
const IMG_URL_LIVE = "https://cdn.bravo6.kr/judo/";
const IMG_URL_TEST = "https://test.bravo6.kr/judo/";

//N-돌핀 이미지 이미지 경로
const APPIMG_URL_LIVE = "https://cdn.bravo6.kr/SulImg/";
const APPIMG_URL_TEST = "https://test.bravo6.kr/SulImg/";

//음성주문 업로드 경로
const SND_URL_LIVE = "https://cdn.bravo6.kr/SulSnd/";
const SND_URL_TEST = "https://test.bravo6.kr/SulSnd/";

//각종 첨부파일업로드 경로
const FILE_URL_LIVE = "https://cdn.bravo6.kr/afile/";
const FILE_URL_TEST = "https://test.bravo6.kr/afile/";


const CHAT_SERVER_URL_TEST = "https://test.bravo6.kr:11300";//"https://chat.bravo6.kr:11300";//;
const CHAT_SERVER_URL_LIVE = "https://chat.bravo6.kr:11300";

const OPCHAT_SERVER_URL_TEST = "https://test.bravo6.kr:11301";//"https://chat.bravo6.kr:11300";//;
const OPCHAT_SERVER_URL_LIVE = "https://chat.bravo6.kr:11301";

let mobileMode = false;//isMobile || true;
let mobileOn = isMobile || mobileMode;

function GetChatServerUrl()
{
  if (TEST_ENABLE)
    return CHAT_SERVER_URL_TEST;
  else
    return CHAT_SERVER_URL_LIVE; 
}

function GetOPChatServerUrl()
{
  if (TEST_ENABLE)
    return OPCHAT_SERVER_URL_TEST;
  else
    return OPCHAT_SERVER_URL_LIVE; 
}

function GetApiBaseUrl()
{
  if (TEST_ENABLE)
    return BASE_URL_TEST;
  else
    return BASE_URL_LIVE;
}

//api호출 경로를 얻음
function GetApiUrl(apiName)
{
  if (TEST_ENABLE)
    return BASE_URL_TEST + apiName;
  else
    return BASE_URL_LIVE + apiName;
}

//api2 서버 경로 얻기
function GetApiUrl2(apiName)
{
  if (TEST_ENABLE)
    return API2_URL_TEST + apiName;
  else
    return API2_URL_LIVE + apiName;
}

//이미지 리소스 파일의 경로를 얻음
function GetImgUrl(imageName)
{
  if (TEST_ENABLE)
    return IMG_URL_TEST + imageName;
  else
    return IMG_URL_LIVE + imageName;
}

//이미지 리소스 파일의 경로를 얻음
function GetAppImgUrl(imageName)
{
  if (TEST_ENABLE)
    return APPIMG_URL_TEST + imageName;
  else
    return APPIMG_URL_LIVE + imageName;
}

//파일리소스 파일의 경로를 얻음
function GetFileUrl(imageName)
{
  if (TEST_ENABLE)
    return FILE_URL_TEST + imageName;
  else
    return FILE_URL_LIVE + imageName;
}

//사운드 리소스 파일의 경로를 얻음
function GetSoundUrl(soundName)
{
  if (TEST_ENABLE)
    return SND_URL_TEST + soundName;
  else
    return SND_URL_LIVE + soundName;
}

export {GetApiBaseUrl, GetApiUrl, GetApiUrl2, GetImgUrl, GetAppImgUrl, GetSoundUrl, TEST_ENABLE, LOG_ENABLE, mobileMode, mobileOn, GetFileUrl, GetChatServerUrl, GetOPChatServerUrl, APP_VER};