import { IsValidS } from '../../Util/Util';
import './Stats.css';

function GetStatsDate(item)
{
  let t = item.rtime.toString();

  if (!IsValidS(t) || t.length !== 8)
    return item.rtime;

  return `${t.substring(0,4)}-${t.substring(4,6)}-${t.substring(6, 8)}`;
}

const StatsItemmb = (props) => {
  return (
    <tr className="statstbitem">

      <td className="statstablecell">
        <div className="statstablecellin flexcenterrow">
          <label className="mfontn btncur">{GetStatsDate(props.item)}</label>  
        </div>
      </td>

      <td className="statstablecell">
        <div className="statstablecellin flexcenterrow">
          <label className="mfontn btncur">{props.item.nru}</label>  
        </div>
      </td>

      <td className="statstablecell">
        <div className="statstablecellin flexcenterrow">
          <label className="mfontn btncur">{`${props.item.aoc}/${props.item.voc}/${props.item.moc}/${props.item.mnoc}`}</label>  
        </div>
      </td>

      <td className="statstablecell">
        <div className="statstablecellin flexcenterrow">
          <label className="mfontn btncur">{props.item.ouc}</label>  
        </div>
      </td>

      <td className="statstablecell">
        <div className="statstablecellin flexcenterrow">
          <label className="mfontn btncur">{(props.item.aoc + props.item.voc + props.item.moc + props.item.mnoc).toString()}</label>  
        </div>
      </td>
      
    </tr>
  );
}

export default StatsItemmb;