import { ODS_ACCEPT } from "../../Stores/OrderStore";
import { GetImgUrl } from "../../URL/AppUrl";
import "../Product/Product.css";


const OrderMiscDetmb = (props) =>
{
  return(
    <div className="pddetcontmb flexcenterrow">
      <div className="ompdetpopmb flexcentercolstart">
        <div className="pddettitle flexcenterrow">
          <label className="mfontnb">주문 상세정보 확인</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">업체명</label>
          <label className="mfontn">{props.order.cname}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">대표자명</label>
          <label className="mfontn">{props.order.uname}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">휴대폰</label>
          <label className="mfontn">{props.order.phone}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">주소</label>
          <label className="mfontn">{props.order.addr}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">영업담당자</label>
          <label className="mfontn">{props.order.sname}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">녹음재생</label>

          <div className="flexcenterrow">
            <img src={GetImgUrl(props.playing ? "mbstop1.png" : "mbplay1.png")} className="btncur" onClick={() => props.playVoice(props.order)} alt="재생"/>
            <label className="mfontn">{props.playTime}</label>
          </div>
          
        </div>

        <div className="flexcenterrow modetbtnareamb">
          {
            props.order.st === ODS_ACCEPT ? (
              <button className="bgcolor2 pddetbtnmb mfontnwb btncur" onClick={() => props.handleReorder(props.order.idx)}>
                재주문 요청
              </button>
            ):
            null
          }

          {
            props.order.st === ODS_ACCEPT ? (
              <button className="bgcolor3 pddetbtnmb mfontnwb btncur" onClick={() => props.addItemCompleted(props.order.idx)}>
                주문 처리 완료
              </button>
            ):
            null
          }

          <button className="bgcolor4 pddetbtnmb mfontnwb btncur" onClick={() => props.handleCloseDetail()}>확인</button>
        </div>
        
      </div>
    </div>
  );
}

export default OrderMiscDetmb;