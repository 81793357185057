import React from 'react';
import { GetFormattedCNum, GetFormattedPhone } from '../../Util/Util';

const NoAppUserInfo = (props) => {
  return (
    <tr className="emptabledata">
      
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.cname}</label>
        </div>
        
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.addr}</label>  
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.oname}</label>  
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.cphone === "n" ? ' ' : GetFormattedPhone(props.cphone)}</label>  
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{GetFormattedCNum(props.cnum)}</label>  
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{GetFormattedCNum(props.sname)}</label>  
        </div>
      </td>
    </tr>
  );
}

export default NoAppUserInfo;