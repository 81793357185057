import React from 'react';

const NoAppUserInfomb = (props) => {
  return (
    <tr className="emptabledata">
      
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.cname}</label>
        </div>
        
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <button className="mfontnwb statsdetbtnmb btncur" onClick={() => props.handleDetail(props.item)}>상세정보</button>
        </div>
      </td>
    </tr>
  );
}

export default NoAppUserInfomb;