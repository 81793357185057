import { observer } from "mobx-react";
import { useState } from "react";
import { LoginStoreInstance } from "../../Stores/LoginStore";
import { GetImgUrl } from "../../URL/AppUrl";

function CheckNoticeWritePermission(tabIndex, searchMode)
{
  if (searchMode)
    return false;

  if (LoginStoreInstance.userType === 0)
    return true;

  if (tabIndex === 0 || LoginStoreInstance.getUserLevel > 1)
    return false;

  return true;
}

const TabSearch = (props) =>
{
  const [search, setSearch] = useState('');

  return (
    <div className="ntctabarearight flexcenterrowend">
      <img src={GetImgUrl("searchpd.png")} alt="검색"/>
      <label className="fontnb">&nbsp;&nbsp;검색하기&nbsp;&nbsp;</label>

      <input type="text" onChange={(e) => setSearch(e.target.value)} value = {search} className="ntcsearchinp fontn" 
        onKeyPress={(e) => {
          if (e.key === "Enter")
          {
            props.handleSearch(search);
          }
        }}
      />

      <button className="bgcolor4 ntcsearchbtn fontnwb btncur" onClick={() => props.handleSearch(search)}>
        검색
      </button>

      {
        props.searchMode ? 
          (
            <button className="bgcolor3 ntcsearchbtn fontnwb btncur" onClick={props.handleList}>
              목록
            </button>
          ) : 
          null
      }

      {
        CheckNoticeWritePermission(props.tabIndex, false) ? 
          (
            <button className="bgcolor1 ntcsearchbtn fontnwb btncur" onClick={props.handleWrite}>
              글쓰기
            </button>
          ) : 
          null
      }
      
    </div>
  );
}

const TabEditMode = () =>
{
  return (
    <div className="ntctabarearight flexcenterrow">
      <div className="ntcedittitlearea flexcentercolend">
        <label className="ntcedittitle fontlargeb">공지사항 수정</label>
        <div className="ntcedittitleline" />
      </div>
      
    </div>
  );
}

const NoticeTab = (props) =>
{
  return (
    <div className="ntctabarea flexcenterrow">

      {/*공지 탭왼쪽 */}
      <div className="ntctabarealeft flexcenterrowstart">
        <div className="ntctabmenu flexcentercolend" onClick = {() => props.handleTab(0)}>
          <label className= {props.tabIndex === 0 ? "btncur fontlarge" : "ntctabfont"}>전체</label>
          <div className={props.tabIndex === 0 ? "ntctabmenulinesel":"ntctabmenuline"}/>
        </div>

        <div className="ntctabmenu flexcentercolend" onClick = {() => props.handleTab(1)}>
          <label className={props.tabIndex === 1 ? "btncur fontlarge":"ntctabfont"}>개별</label>
          <div className={props.tabIndex === 1 ? "ntctabmenulinesel" : "ntctabmenuline"}/>
        </div>
      </div>

      {/*공지 탭 오른쪽 */}
      {
        props.editMode !== 2 ? 
          <TabSearch handleSearch = {props.handleSearch} handleWrite = {props.handleWrite} handleList = {props.handleList}
            tabIndex = {props.tabIndex} searchMode = {props.searchMode}/> : 
          <TabEditMode/>
      }

    </div>
  );
}

export default observer(NoticeTab);