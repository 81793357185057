import React from 'react';
import './Staff.css';
import '../../App.css';
import {GetImgUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
//import { Link } from "react-router-dom";
import MainMenu from '../Menu/MainMenu';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { StaffStoreInstance } from '../../Stores/StaffStore';
import EmpInfo from './EmpInfo';
import PageList from '../PageList';
import StaffAdd from './StaffAdd';
import { observer } from 'mobx-react';
import Loading from '../Loading/Loading';
import { ProcessLogout } from '../../Stores/StoreManager';
import EmpInfomb from './EmpInfomb';
import MenuMb from '../Menu/MenuMb';
import StaffAddmb from './StaffAddmb';
import { IsValidS, IsValidV } from '../../Util/Util';
import StaffInfomb from './StaffInfomb';
import EditManagedSales from './EditManagedSales';
import RecmList from './RecmList';

class Staff extends React.Component {
  state = 
  {
    loading : false,
    staffIdx : -1,//편집중인 직원의 고유번호, 만일 신규생성이면 -1
    editStaff : false,
    showDetail : false, //상세 정보 보기창
    newStaff : false, //신규 직원 추가시true
    listMode : 0, //전체, 어드민, 영업담당자, 일반관리자
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 1, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 1, //전체 페이지 개수
    searchMode : 0, //검색 모드
    searchResult : false, //리스트의 내용이 검색에 의한 결과인가?
    appOn : false,
    showEditManagedSales: false, //관리담당자의 관리하는 영업담당자 목록 편집

    showRecommList: false, //추천인 목록창
  };

  intervalId = -1;
  visiblePageNum = 10; //몇개의 페이지 번호를 보일지
  defailInfo = null; //상세 정보 보기 사용자 정보
  decommenderInfo = null;
  managerIdx = -1; //편집중인 관리 담당자 고유번호

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("Staff.componentDidMount");

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 2}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    //먼저 프로필 정보가 유효한지 확인하고 유효하지 않으면, 프로필 정보를 다시 읽는다.
    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.OnLoadProfile);
      return;
    }

    this.loadCountInfo();

    this.intervalId = setInterval(this.update, 1000);

    if (mobileOn)
      this.visiblePageNum = 3;
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : "backhandler"}));

    if (!this.state.editStaff && !this.state.showDetail && !this.state.newStaff && !this.state.showRecommAdd)
      this.props.history.push("/main");
    else if (this.state.showDetail)
    {
      this.handleCloseDetail();
    }
    else if (this.state.showRecommAdd)
    {
      this.handleCloseAddRecomm();
    }
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }
  
  setLoading = (enabled) =>
  {
    this.setState((prevState) => ({...prevState, loading : enabled}));
  }

  calcPageInfo = (listMode, changeListMode) =>
  {
    /*
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 10, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 10, //전체 페이지 개수
    */

    let currentCount = 0;

    switch(listMode)
    {
      case 0://전체
        currentCount = StaffStoreInstance.totalCount;
        break;
      case 1://어드민
        currentCount = StaffStoreInstance.adminCount;
        break;
      case 2://영업담당
        currentCount = StaffStoreInstance.salesCount;
        break;
      case 3://관리담당
        currentCount = StaffStoreInstance.salesManager;
        break;
      case 4://일반관리자
        currentCount = StaffStoreInstance.managerCount;
        break;
      default:
        currentCount = 0;
        break;
    }

    let pageCount = Math.floor(currentCount / 20);

    if ((currentCount % 20) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.visiblePageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    if (changeListMode)
      this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount , listMode : listMode}));
    else
      this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  OnLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    this.loadCountInfo();
  }

  //계정 타입별 개수 정보를 읽는다.
  loadCountInfo = () =>
  {
    StaffStoreInstance.loadCount(this.onLoadCountRsult);
  }
  
  onLoadCountRsult = (success) =>
  {
    if (success)
    {
      this.calcPageInfo(this.state.listMode, false);
      this.loadAccountList(this.state.listMode, 1);
    }
    else
    {
      alert("계정 목록 조회에 실패 했습니다.");
      this.setLoading(false);
    }
  }

  loadAccountList = (listMode, currentPage) =>
  {
    if (!this.state.Loading)
      this.setLoading(true);

    let staffType = -1;

    switch(listMode)
    {
      case 1://어드민
        staffType = 0;
        break;
      case 2://영업담당
        staffType = 2;
        break;
      case 3://관리담당
        staffType = 3;
        break;
      case 4://일반관리자
        staffType = 1;
        break;
      default:
        if (LOG_ENABLE)
          console.log("unknown list");
        break;
    }

    StaffStoreInstance.loadStaff(staffType, (currentPage - 1) * 20, this.onLoadStaffResult);
  }

  onLoadStaffResult = (success) =>
  {
    this.setLoading(false);

    if (!success)
    {
      alert("직원 목록 조회에 실패 했습니다.");
    }
  }

  editUserInfo = null;

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case -1:
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        this.props.history.push("/order"); 
        break;
      case 4: //판매일보
        this.props.history.push("/report"); 
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }
  
  setSearchMode = (mode) =>
  {
    this.setState({...this.state, searchMode : mode});
  }

  //검색어 바뀜
  onSearchChanged = (e) => {
    
    StaffStoreInstance.setSearchWord(e.target.value);
  }

  //직원 검색하기
  handleSearch = () =>
  {
    if (LOG_ENABLE)
      console.log("handleSearch");

    if (!StaffStoreInstance.isSearchWordValid())
    {
      if (this.state.searchMode === 0)
      {
        alert("검색 할 직원 이름을 입력하세요.");
        return;
      }
      else
      {
        alert("검색 할 직원 전화번호를 입력하세요.");
        return;
      }
    }

    this.setLoading(true);

    StaffStoreInstance.searchStaff(this.state.searchMode, this.onSearchResult);
  }

  onSearchResult = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);

      alert("직원 검색에 실패 했습니다.");
      return;
    }

    this.setState((prevState) => ({...prevState, loading : false, searchResult : true}));
  }

  handleChangeUser = () =>
  {
    if (LOG_ENABLE)
      console.log("handleChangeUser");

    if (this.state.staffIdx === -1)
    {
      alert("수정 할 직원을 선택하세요.");
      return;
    }

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert("직원 수정 권한이 없습니다.");
      return;
    }

    this.editUserInfo = StaffStoreInstance.findUserByIdx(this.state.staffIdx);

    if (this.editUserInfo === null)
    {
      alert("수정 할 직원을 선택하세요.");
      return;
    }

    this.setState({...this.state, editStaff : true, newStaff : false, showRecommList: false});
  }

  handleEditDirect = (staffIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleEditDirect");

    if (LoginStoreInstance.getUserLevel > 1)
    {
      //alert("직원 수정 권한이 없습니다.");
      return;
    }

    this.editUserInfo = StaffStoreInstance.findUserByIdx(staffIdx);

    if (this.editUserInfo === null)
    {
      alert("수정 할 직원을 선택하세요.");
      return;
    }

    this.setState({...this.state, staffIdx : staffIdx, editStaff : true, newStaff : false, showRecommList: false});
  }

  handleDeleteUser = () =>
  {
    if (LOG_ENABLE)
      console.log("handleDeleteUser");

    if (this.state.staffIdx === -1)
    {
      alert("삭제할 직원을 선택하세요.");
      return;
    }

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert("직원 삭제 권한이 없습니다.");
      return;
    }

    this.editUserInfo = StaffStoreInstance.findUserByIdx(this.state.staffIdx);

    if (LOG_ENABLE)
      console.log("del info = " + JSON.stringify(this.editUserInfo));

    if (this.editUserInfo === null)
    {
      alert("삭제할 직원을 선택하세요.");
      return;
    }

    if (this.editUserInfo.plevel === 0)
    {
      alert("어드민 계정은 삭제할 수 없습니다.");
      return;
    }

    /*if (this.editUserInfo.plevel === 2)
    {
      alert("영업 담당자 계정은 삭제할 수 없습니다.");
      return;
    }*/

    if (this.editUserInfo.utype === 1)
    {
      alert("메인 계정은 삭제할 수 없습니다.");
      return;
    }

    if (!window.confirm(this.editUserInfo.uname + "(" + this.editUserInfo.cid + ") 계정을 삭제할까요?"))
    {
      return;
    }

    StaffStoreInstance.delStaff(this.state.staffIdx, this.editUserInfo.plevel, this.onDeleteResult);
  }

  onDeleteResult = (resultCode) =>
  {
    switch(resultCode)
    {
      case 0:
        alert("삭제되었습니다.");
        this.calcPageInfo(this.state.listMode, false);
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("자신의 계정을 삭제 할 수 없습니다.");
        break;
      case 4:
        alert("계정 삭제 권한이 없습니다.");
        break;
      case 5:
        alert("거래처가 있는 영업 담당자는 삭제할 수 없습니다.");
        break;
      case 6:
        alert("N-돌핀 앱 이용자중 해당 영업담당자를 거래처로 지정한 계정이 있습니다. 개발사에 문의해주세요.");
        break;
      case 7:
        alert("최근 3일간 주문이 있는 영업담당자는 삭제할 수 없습니다. 3일 경과 후 삭제해 주세요.");
        break;
      default:
        alert("계정 삭제중 오류가 발생했습니다.");
        break;
    }
  }
  //직원 등록 버튼
  handleAdd = () =>
  {
    if (LOG_ENABLE)
      console.log("handleAdd");

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert("직원 등록 권한이 없습니다.");
      return;
    }

    this.editUserInfo = {idx : -1, uname : '', phone:'', email:'', plevel: 2};

    this.setState({...this.state, editStaff : true, newStaff : true, staffIdx : -1, showRecommList: false});
  }

  //직원 편집창 닫기
  handleCloseStaff = () => 
  {
    this.setState({...this.state, editStaff : false, newStaff : false});
  }

  /*직원 정보 등록/편집창에서 직원 정보 등록/수정 요청이 옴
  idx : 직원 고유번호, 신규 생성인경우 -1
  id : 계정, 등록정보 변경일경우 바꿀 수 없음
  pw : 비밀 번호
  name : 이름
  phone : 전화번호
  email : 이메일주소
  plevel : 권한 레벨

  */
  addStaff = (idx, id, pw, name, phone, email, plevel) =>
  {
    if (id === undefined || id === null || id.length < 1)
    {
      alert("직원 ID를 올바르게 입력해 주세요.");
      return;
    }

    if (id.length < 3)
    {
      alert("직원 ID를 3자 이상입력해주세요.");
      return;
    }

    if (pw === undefined || pw === null || pw.length < 1)
    {
      alert("직원 비밀번호를 올바르게 입력해 주세요.");
      return;
    }

    if (pw.length < 4)
    {
      alert("비밀번호를 4자이상 입력해주세요.");
      return;
    }

    if (name === undefined || name === null || name.length < 1)
    {
      alert("직원 이름을 올바르게 입력해 주세요.");
      return;
    }

    if (phone === undefined || phone === null || phone.length < 1)
    {
      alert("직원 전화 번호를 올바르게 입력해 주세요.");
      return;
    }

    if (email === undefined || email === null || email.length < 1)
    {
      alert("직원 이메일 주소를 올바르게 입력해 주세요.");
      return;
    }

    if (plevel === undefined || plevel === null || plevel < 0 || plevel > 3)
    {
      alert("직원 권한을 올바르게 선택해 주세요.");
      return;
    }

    if (idx === -1)
    {
      if (!window.confirm("신규 직원을 등록 할까요?"))
        return;

      this.setLoading(true);
      StaffStoreInstance.addStaff(id, pw, name, phone, email, plevel, this.onAddStaffResult);
    }
    else
    {
      if (!window.confirm("직원 정보를 수정 할까요?"))
        return;

      this.setLoading(true);

      if (phone.indexOf("-") >= 0)
        phone = phone.replace(/-/gi, "");
        
      StaffStoreInstance.editStaff(idx, pw, name, phone, email, plevel, this.onEditStaffResult);
    }
  }

  userLevelToListMode = (userLevel) =>
  {
    switch(userLevel)
    {
      case 0:
        return 1;
      case 1:
        return 4;
      case 2:
        return 2;
      case 3:
        return 3;
      default:
        return 0;
    }
  }

  onAddStaffResult = (resultCode, newUserLevel) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.setState({...this.state, editStaff : false});

        if ((this.state.listMode === 0 || this.state.listMode === this.userLevelToListMode(newUserLevel)))
        {
          this.calcPageInfo(this.state.listMode, false);
          
          if (this.state.currentPage === this.state.pageCount)
          {
            this.loadAccountList(this.state.listMode, this.state.currentPage);
          }
        }

        alert("추가 되었습니다.");
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("직원 등록 권한이 없습니다.");
        break;
      case 3:
        alert("회사 정보가 존재하지 않습니다.");
        break;
      case 5:
        alert("사용 할 수 없는 ID입니다.");
        break;
      case 4:
      default:
        alert("계정 등록중 오류가 발생했습니다.");
        break;
    }
  }

  onEditStaffResult = (resultCode, oldUserLevel, newUserLevel) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.setState({...this.state, editStaff : false});
        alert("수정 되었습니다.");
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("직원 수정 권한이 없습니다.");
        break;
      case 3:
      default:
        alert("직원 정보 수정중 오류가 발생했습니다.");
        break;
    }
  }
  //전체 선택 체크 박스
  onCheckChanged = () =>
  {
    if (LOG_ENABLE)
      console.log("onCheckChanged");
  }

  //직원 목록 리스트의 체크 박스
  onChildCheckChanged = (index) =>
  {
    if (LOG_ENABLE)
      console.log("onChildCheckChanged : " + index);

    if (index === this.state.staffIdx)
      this.setState({...this.state, staffIdx : -1});
    else
      this.setState({...this.state, staffIdx : index});
  }

  //전체, 어드민, 영업담당자, 일반 관리자 선택시 리스트 항목의 내용을 조회해서 다시 출력해야함
  handleCategory = (index) =>
  {
    if (this.state.listMode === index)
      return;

    this.setState((prevState) => ({...prevState, searchResult : false}));

    this.calcPageInfo(index, true);

    this.loadAccountList(index, 1);

    if (LOG_ENABLE)
      console.log("handleCategory : " + index);
  }

  //하단 페이지 변경 버튼 눌릴경우
  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4
  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = this.visiblePageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadAccountList(this.state.listMode, 1)
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - this.visiblePageNum;
      let endPage = this.state.endPage - this.visiblePageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadAccountList(this.state.listMode, startPage)
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.visiblePageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadAccountList(this.state.listMode, startPage)
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.visiblePageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadAccountList(this.state.listMode, endPage)
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadAccountList(this.state.listMode, pageNum)
    }
  }

  //모바일용, 상세정보 보기 버튼눌림
  handleDetail = (staffIdx) =>
  {
    if (LOG_ENABLE)
    {
      console.log(`handleDetail : ${staffIdx}`);
    }

    this.defailInfo = StaffStoreInstance.findUserByIdx(staffIdx);
    
    if (!IsValidV(this.defailInfo))
    {
      return;
    }

    this.setState((prevState) => ({...prevState, showDetail : true}));
  }

  handleCloseDetail = () =>
  {
    this.setState((prevState) => ({...prevState, showDetail : false}));
  }

  handleLogout = () =>
  {
    ProcessLogout();
    this.props.history.push("/");
  }

  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.handleSearch();
    }
  }

  //추천인 목록 버튼 눌림
  handleRecommenderList = () =>
  {
    if (LOG_ENABLE)
      console.log("handleRecommenderList");

    this.setState((prevState) => ({...prevState, showRecommList: true}));
  }
  
  handleCloseRecommList = () =>
  {
    this.setState((prevState) => ({...prevState, showRecommList: false}));
  }

  //관리하는 영업담당자 편집
  editManagedSales = (managerIdx) =>
  {
    this.managerIdx = managerIdx;
    this.setState((prevState) => ({...prevState, showEditManagedSales: true}));
  }

  handleCloseManagedSales = () =>
  {
    this.setState((prevState) => ({...prevState, showEditManagedSales: false}));
  }

  render() {
    const empList = StaffStoreInstance.getEmpList;

    let emp = null;

    if (mobileOn)
    {
      if (empList !== null && empList.length > 0)
      {
        emp = empList.map(item => {
          return (<EmpInfomb key={item.idx} index={item.idx} onCheckChanged = {this.onChildCheckChanged} num={item.idx} name={item.uname} phone={item.phone} 
            email={item.email} level={item.plevel} selectIdx = {this.state.staffIdx} utype={item.utype} cid={item.cid}
            handleDetail={this.handleDetail}
            />);
        });
      }
  
      return (
        
        <div className="appcontmb flexcentercolstart">
  
          {
            this.state.loading ? <Loading />:null
          }
  
          {
            this.state.showDetail ?
              (<StaffInfomb staffInfo = {this.defailInfo} handleCloseDetail = {this.handleCloseDetail}/>) : null
          }

          {
            this.state.editStaff ? 
              (<StaffAddmb 
                staffIdx = {this.state.staffIdx}
                newStaff = {this.state.newStaff}
                handleClose={this.handleCloseStaff} 
                btntitle={this.state.newStaff?"직원 등록" : "직원 정보 수정"}
                title={this.state.newStaff?"직원 등록" : "직원 정보"}
                addStaff= {this.addStaff}
                editManagedSales = {this.editManagedSales}
                id={this.editUserInfo.cid}
                pw={this.editUserInfo.cpw}
                name={this.editUserInfo.uname}
                phone={this.editUserInfo.phone}
                email={this.editUserInfo.email}
                plevel={this.editUserInfo.plevel}
                utype={this.editUserInfo.utype}
              />) : null
          }
          
          {
            this.state.showEditManagedSales ? (
              <EditManagedSales handleClose={this.handleCloseManagedSales} managerIdx={this.managerIdx}/>
            ) : null
          }
          
          <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout = {this.handleLogout}/>

          <div className="maincontainermb">
            
            <div className="empserconmb">
              <div className="flexcenterrow empsertitle2">
                <div className='flexcenterrow'>
                  <img src={GetImgUrl("mbuser.png")} alt="직원관리" />
                  <label className="mfontnb">&nbsp;직원관리</label>
                </div>

                {/*
                  LoginStoreInstance.getUserLevel < 2 ? (
                    <button className='emprecommbtnmb mfontnwb btncur' onClick={this.handleRecommenderList}>추천인 코드 생성</button>
                  ) : null
                  */}
              </div>
  
              <div className="empsercontentmb flexcenterrow">
  
                <select className="empselectmb mfontn"
                  onChange={(e) => {
                    if (LOG_ENABLE)
                      console.log(e.target.value);
  
                    this.setSearchMode(parseInt(e.target.value));
                  }}
                >
                  <option value="0">이름</option>
                  <option value="1">전화번호</option>
                </select>
  
                <input type="text" onChange={this.onSearchChanged} 
                  value = {StaffStoreInstance.searchWord}
                  className="empinpmb mfontn" placeholder={this.state.searchMode === 0 ? "직원이름(성포함)" : "전화번호(-제외)"}
                  onKeyPress={this.handleKeyPress}
                />
                <button className="empserbtnmb mfontnb" onClick={this.handleSearch}>검색</button>
              </div>
            </div>
  
            <div className="empsercon">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("mbpapers.png")} alt="직원현황" />
                <label className="mfontnb">&nbsp;직원현황</label>
              </div>
  
              <div className="empnummb flexcentercol">

                <div className="cnthalfv flexcenterrow">
                  <div className="cnthalfh flexcenterrowstart">
                    <label className="mfontnwb">&nbsp;&nbsp;&nbsp;</label>
                    <img src={GetImgUrl("mbuser2.png")} alt="Admin" className="empimgmb"/>
                    <label className="mfontnwb">Admin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <div className="empcntmb flexcenterrow">
                      <label className="fontnb">{StaffStoreInstance.adminCount + "명"}</label>
                    </div>
                  </div>

                  <div className="cnthalfh flexcenterrowend">
                    <img src={GetImgUrl("mbuser2.png")} alt="영업담당자" className="empimgmb"/>
                    <label className="mfontnwb">영업담당자</label>
                    <div className="empcntmb flexcenterrow">
                      <label className="fontnb">{StaffStoreInstance.salesCount + "명"}</label>
                    </div>
                    <label className="mfontnwb">&nbsp;&nbsp;&nbsp;</label>
                  </div>
                </div>

                <div className="cnthalfv flexcenterrow">
                  <div className="cnthalfh flexcenterrowstart">
                    <label className="mfontnwb">&nbsp;&nbsp;&nbsp;</label>
                    <img src={GetImgUrl("mbuser2.png")} alt="일반관리자" className="empimgmb"/>
                    <label className="mfontnwb">일반관리자</label>
                    <div className="empcntmb flexcenterrow">
                      <label className="fontnb">{StaffStoreInstance.managerCount + "명"}</label>
                    </div>
                  </div>

                  <div className="cnthalfh flexcenterrowend">
                    {
                      LoginStoreInstance.getUserLevel <= 1 ? (
                        <button className="empaddbtnmb mfontnb" onClick={this.handleAdd}>
                          직원등록
                        </button>
                      ) : null
                    }
                    
                    <label className="mfontnwb">&nbsp;&nbsp;&nbsp;</label>
                  </div>
                </div>
  
              </div>
            </div>
  
            <div className="empinfo flexcentercol">
              
              <div className="empsertitlemb flexcenterrowstart">

                <div className="empinfoleftmb flexcenterrowstart">
                  <img src={GetImgUrl("mbpapers.png")} alt="직원정보" />
                  <label className="mfontnb">&nbsp;직원정보</label>
                </div>

                <div className="empinforightmb">
                  <label className={this.state.listMode === 0 ? "empcatselmb" : "btncur mfontnb"} onClick={() => this.handleCategory(0)}>전체</label>
                  <div className="empvline" />
                  <label className={this.state.listMode === 1 ? "empcatselmb" : "btncur mfontnb"} onClick={() => this.handleCategory(1)}>admin</label>
                  <div className="empvline" />
                  <label className={this.state.listMode === 2 ? "empcatselmb" : "btncur mfontnb"} onClick={() => this.handleCategory(2)}>영업담당자</label>
                  <div className="empvline" />
                  <label className={this.state.listMode === 4 ? "empcatselmb" : "btncur mfontnb"} onClick={() => this.handleCategory(4)}>일반관리자</label>
                </div>
              </div>
              
              <div className="empline"/>
  
              {
                LoginStoreInstance.getUserLevel <= 1 ? (
                  <div className="empinfo flexcenterrowstart empmarginb10">
                    <input type="radio" onChange={this.onCheckChanged} checked = {false} className="chk"/>
                    <label className="btncur mfontnb">&nbsp;&nbsp;선택한 정보를&nbsp;&nbsp;</label>

                    <button className="bgcolor1 empsmallbtnmb mfontnwb" onClick = {this.handleChangeUser}>
                      수정
                    </button>

                    <label className="btncur mfontnb">&nbsp;&nbsp;또는&nbsp;&nbsp;</label>

                    <button className="bgcolor2 empsmallbtnmb mfontnwb" onClick = {this.handleDeleteUser}>
                      삭제
                    </button>
                  </div>
                ) : null
              }
              
            </div>
  
            <div className = "tablecont flexcentercol">
              <table className="emptablemb">
                <thead>
                  <tr>
                    <th className="emptableheadermb">
                      {/*<input type="checkbox" onChange={this.onCheckChanged} checked = {false} className="chk"/>*/}
                    </th>
  
                    <th className="emptableheadermb">
                      <label className="mfontnw">번호</label>
                    </th>
  
                    <th className="emptableheadermb">
                      <label className="mfontnw">이름</label>
                    </th>
  
                    <th className="emptableheadermb">
                      <label className="mfontnw">상세정보확인</label>
                    </th>
                    </tr>
                </thead>
                <tbody>
                {emp}
                </tbody>
              </table>
            </div>
            {
              this.state.pageCount > 1 && !this.state.searchResult ? <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
    else
    {
      if (empList !== null && empList.length > 0)
      {
        emp = empList.map(item => {
          return (<EmpInfo key={item.idx} index={item.idx}
            onCheckChanged = {this.onChildCheckChanged} 
            handleEditDirect = {this.handleEditDirect} 
            num={item.idx} name={item.uname} phone={item.phone} cid={item.cid}
            email={item.email} level={item.plevel} selectIdx = {this.state.staffIdx} utype={item.utype}/>);
        });
      }
  
      return (
        
        <div className="appcontainer">
  
          {
            this.state.loading ? <Loading />:null
          }
  
          {
            this.state.editStaff ? 
              (<StaffAdd 
                staffIdx = {this.state.staffIdx}
                newStaff = {this.state.newStaff}
                handleClose={this.handleCloseStaff} 
                btntitle={this.state.newStaff?"직원 등록" : "직원 정보 수정"}
                title={this.state.newStaff?"직원 등록" : "직원 정보"}
                addStaff= {this.addStaff}
                editManagedSales = {this.editManagedSales}
                id={this.editUserInfo.cid}
                pw={this.editUserInfo.cpw}
                name={this.editUserInfo.uname}
                phone={this.editUserInfo.phone}
                email={this.editUserInfo.email}
                plevel={this.editUserInfo.plevel}
                utype={this.editUserInfo.utype}
              />) : null
          }
          
          {
            this.state.showEditManagedSales ? (
              <EditManagedSales handleClose={this.handleCloseManagedSales} managerIdx={this.managerIdx}/>
            ) : null
          }

          {
            this.state.showRecommList ? (
              <RecmList handleCloseRecommList={this.handleCloseRecommList}/>
            ) : null
          }
          
          <div className="maincontainer">
            <div className="empmargin"/>
            <MainMenu index={1} handleMenuClick = {this.handleMenuClick}/>
            
            <div className="empsercon">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("uicon.png")} alt="직원관리" />
                <label className="fontlargeb">&nbsp;직원관리</label>
              </div>
  
              <div className="flexcenterrowstart empsercontent2">
                <div className='flexcenterrow'>
                  <img src={GetImgUrl("search.png")} alt="직원검색" className="empimg"/>
                  <label className="fontnwb">직원검색</label>
    
                  <select className="empselect fontn"
                    onChange={(e) => {
                      if (LOG_ENABLE)
                        console.log(e.target.value);
    
                      this.setSearchMode(parseInt(e.target.value));
                    }}
                  >
                    <option value="0">이름</option>
                    <option value="1">전화번호</option>
                  </select>
    
                  <input type="text" onChange={this.onSearchChanged}
                    value = {StaffStoreInstance.searchWord}
                    className="empinp fontn" placeholder={this.state.searchMode === 0 ? "직원이름(성포함)" : "전화번호(-제외)"}
                    onKeyPress={this.handleKeyPress}
                  />
                  <button className="empserbtn fontnb" onClick={this.handleSearch}>검색</button>
                </div>
                
                {
                  LoginStoreInstance.getUserLevel < 2 ? (
                    <button className='emprecommbtn fontnb btncur' onClick={this.handleRecommenderList}>추천인 코드 목록</button>
                  ) : null
                }
              </div>
              
            </div>
  
            <div className="empsercon">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("papers.png")} alt="직원현황" />
                <label className="fontlargeb">&nbsp;직원현황</label>
              </div>
  
              <div className="empsercontent flexcenterrowstart">
                <img src={GetImgUrl("userw.png")} alt="Admin" className="empimg"/>
                <label className="fontnwb">Admin</label>
                <div className="empcnt flexcenterrow">
                  <label className="fontnb">{StaffStoreInstance.adminCount + "명"}</label>
                </div>
  
                <img src={GetImgUrl("userw.png")} alt="일반관리자" className="empimg"/>
                <label className="fontnwb">일반관리자</label>
                <div className="empcnt flexcenterrow">
                  <label className="fontnb">{StaffStoreInstance.managerCount + "명"}</label>
                </div>
  
                <img src={GetImgUrl("userw.png")} alt="영업담당자" className="empimg"/>
                <label className="fontnwb">영업담당자</label>
                <div className="empcnt flexcenterrow">
                  <label className="fontnb">{StaffStoreInstance.salesCount + "명"}</label>
                </div>
  
                <img src={GetImgUrl("userw.png")} alt="관리담당자" className="empimg"/>
                <label className="fontnwb">관리담당자</label>
                <div className="empcnt flexcenterrow">
                  <label className="fontnb">{StaffStoreInstance.salesManager + "명"}</label>
                </div>
  
                {
                  LoginStoreInstance.getUserLevel <= 1 ? (
                    <button className="empaddbtn fontnb" onClick={this.handleAdd}>
                      직원등록
                    </button>
                  ) : null
                }
                
              </div>
            </div>
  
            <div className="empsercon">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("papers.png")} alt="직원정보" />
                <label className="fontlargeb">&nbsp;직원정보</label>
              </div>
              
              <div className="empline"/>
  
              {
                LoginStoreInstance.getUserLevel <= 1 ? (
                  <div className="empinfo flexcenterrow">
                    <div className="empinfoleft flexcenterrowstart">
                      <input type="radio" onChange={this.onCheckChanged} checked = {false} className="chk"/>
                      <label className="btncur fontnb">&nbsp;&nbsp;선택한 정보를&nbsp;&nbsp;</label>
      
                      <button className="bgcolor1 empsmallbtn fontnwb" onClick = {this.handleChangeUser}>
                        수정
                      </button>
      
                      <label className="btncur fontnb">&nbsp;&nbsp;또는&nbsp;&nbsp;</label>
      
                      <button className="bgcolor2 empsmallbtn fontnwb" onClick = {this.handleDeleteUser}>
                        삭제
                      </button>
                    </div>
      
                    <div className="empinforight">
                      <label className={this.state.listMode === 0 ? "empcatsel" : "btncur fontnb"} onClick={() => this.handleCategory(0)}>전체</label>
                      <div className="empvline" />
                      <label className={this.state.listMode === 1 ? "empcatsel" : "btncur fontnb"} onClick={() => this.handleCategory(1)}>admin</label>
                      <div className="empvline" />
                      <label className={this.state.listMode === 2 ? "empcatsel" : "btncur fontnb"} onClick={() => this.handleCategory(2)}>영업담당자</label>
                      <div className="empvline" />
                      <label className={this.state.listMode === 3 ? "empcatsel" : "btncur fontnb"} onClick={() => this.handleCategory(3)}>관리담당자</label>
                      <div className="empvline" />
                      <label className={this.state.listMode === 4 ? "empcatsel" : "btncur fontnb"} onClick={() => this.handleCategory(4)}>일반관리자</label>
                    </div>
                  </div>
                ) : null
              }
              
            </div>
  
            <div className = "tablecont">
              <table className="emptable">
                <thead>
                  <tr>
                    <th className="emptableheader">
                      {/*<input type="checkbox" onChange={this.onCheckChanged} checked = {false} className="chk"/>*/}
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">번호</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">이름</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">휴대폰</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">메일주소</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">권한</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {emp}
                </tbody>
              </table>
            </div>
            {
              this.state.pageCount > 1 && !this.state.searchResult ? <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
  }
}

export default observer(Staff);