import { IsValidS } from '../../Util/Util';
import './Stats.css';

function GetStatsDate(item)
{
  let t = item.rtime.toString();

  if (!IsValidS(t) || t.length !== 8)
    return item.rtime;

  return `${t.substring(0,4)}-${t.substring(4,6)}-${t.substring(6, 8)}`;
}

const StatsItem = (props) => {

  if (props.userType === 0) //운영자인경우
  {
    return (
      <tr className="statstbitem">
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{GetStatsDate(props.item)}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.nru}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.nu - props.item.nru}</label>  
          </div>
        </td>

        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.aoc}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.voc}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.moc}</label>  
          </div>
        </td>

        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.mnoc}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.ouc}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{(props.item.aoc + props.item.voc + props.item.moc).toString()}</label>  
          </div>
        </td>
        
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.auc}</label>  
          </div>
        </td>
      </tr>
    );
  }
  else
  {
    return (
      <tr className="statstbitem">
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{GetStatsDate(props.item)}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.nru}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.aoc}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.voc}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.moc}</label>  
          </div>
        </td>

        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.mnoc}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{props.item.ouc}</label>  
          </div>
        </td>
  
        <td className="statstablecell">
          <div className="statstablecellin flexcenterrow">
            <label className="fontn">{(props.item.aoc + props.item.voc + props.item.moc).toString()}</label>  
          </div>
        </td>
        
      </tr>
    );
  }
}

export default StatsItem;