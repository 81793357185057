import { LOG_ENABLE, mobileOn } from "../../URL/AppUrl";
import { IsValidS } from "../../Util/Util";

const MnuOrderAddSalesSel = (props) =>
{
  let sel = null;

  if (IsValidS(props.salesList))
  {
    sel = props.salesList.map(item => {
      return (<option key={item.idx} value={item.idx.toString()}>{item.uname}</option>);
    });
  }
  if (mobileOn)
  {
    return (
    
      <div className="mnuodsaleslistmb flexcentercolstart">
        <label className="mfontnb">주문을 담당할 영업 담당자를 선택하세요.</label>
  
        <select className="mnuodsalesselinpmb mfontn"
          onChange={(e) => {
            if (LOG_ENABLE)
              console.log(e.target.value);
  
              props.handleSalesSelect(parseInt(e.target.value));
          }}
          value={props.selectedSalesIdx.toString()}
        >
          {sel}
        </select>
      </div>
    );
  }
  else
  {
    return (
      <div className="mnuodsaleslist flexcentercolstart">
        <label className="fontnb">주문을 담당할 영업 담당자를 선택하세요.</label>
  
        <select className="mnuodsalesselinp fontn"
          onChange={(e) => {
            if (LOG_ENABLE)
              console.log(e.target.value);
  
              props.handleSalesSelect(parseInt(e.target.value));
          }}
          value={props.selectedSalesIdx.toString()}
        >
          {sel}
        </select>
      </div>
    );
  }
}

export default MnuOrderAddSalesSel;