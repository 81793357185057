import React from 'react';

import {
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import { FromBase64, IsValidS, IsValidV, NumberFormatString, ToBase64 } from '../Util/Util';

import {GetChatServerUrl, LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { LoginStoreInstance } from './LoginStore';
import socketIOClient from "socket.io-client";

//채팅 관련
class ChatStore {
  //observable변수
  newChat = null; //새로운 대화 목록
  searchList = null; //검색된 업체의 목록
  talkList = null; //수신된 대화 목록
  newChatCount = 0; //신규 채팅이 도착한 업체의 개수

  //비 observable
  socket = null;
  connected = false;
  refIdx = -1; //
  chatListLoading = false; //채팅 목록 로딩중인가?
  currentTalkUserIdx = -1; //현재 대화중인 상대의 고유번호
  totalCompanyCount = 0;
  totalTalkCount = 0;
  tempIdx = -1;
  newChatCountIdx = -1; //신규 채팅 개수 정보를 저장하고 있는 테이블의 고유번호

  clearAll = ()=>
  {
    this.newChat = null; //새로운 대화 목록
    this.searchList = null; //검색된 업체의 목록
    this.talkList = null; //수신된 대화 목록
    this.newChatCount = 0; //신규 채팅이 도착한 업체의 개수

    this.loadChatCountCallback = null;
    this.searchChatCallback = null;

    this.connected = false;
    this.refIdx = -1;
    this.chatListLoading = false;

    this.chatListCallback = null;
    this.chatLoading = false;
    this.currentTalkUserIdx = -1;
    this.totalCompanyCount = 0;
    this.totalTalkCount = 0;
    this.newChatCallback = null;
    this.newChatUserCallback = null;
    this.tempIdx = -1;
    this.newChatCountIdx = -1; //신규 채팅 개수 정보를 저장하고 있는 테이블의 고유번호

    if (this.socket !== null)
    {
      try
      {
        this.socket.disconnect();
      }
      catch(e)
      {
        console.log(e.toString());
      }

      this.socket = null;
    }
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      newChat : observable,
      searchList : observable,
      talkList : observable,
      newChatCount : observable,

      clearAll : action,

      connect : action,

      loadNewChatCount : action,
      parseChatCountResult : action,

      clearNewChatCount : action,

      sendUserInfo : action,
      onNewChat : action,
      onNewChatUserLoaded : action,
      clearNewChatMark : action,

      onLoadNewChatListResult : action,

      clearChatList : action,
      loadLastChatList : action,
      onLoadChatListResult : action,

      searchCompany : action,
      parseSearchResult : action,

      getTotalCompanycount : computed,
      getTotalTalkCount : computed,
    });
  }
  
  get getTotalCompanycount()
  {
    if (IsValidS(this.newChat))
      return this.newChat.length;

    return 0;
  }

  get getTotalTalkCount()
  {
    if (IsValidS(this.talkList))
      return this.talkList.length;

    return 0;
  }

  findNewChatByIdx = (itemIdx) =>
  {
    if (this.newChat === null)
      return null;

    for (let i = 0;i < this.newChat.length; ++i)
    {
      if (this.newChat[i].idx === itemIdx)
        return this.newChat[i];
    }

    return null;
  }

  findNewChatByUserIdx = (userIdx) =>
  {
    if (this.newChat === null)
      return null;

    for (let i = 0;i < this.newChat.length; ++i)
    {
      if (this.newChat[i].uidx === userIdx)
        return this.newChat[i];
    }

    return null;
  }

  findTalkByIdx = (talkIdx) =>
  {
    if (this.talkList === null)
      return null;

    for (let i = 0;i < this.talkList.length; ++i)
    {
      if (this.talkList[i].idx === talkIdx)
        return this.talkList[i];
    }

    return null;
  }

  searchTalk = (searchWord) =>
  {
    if (!IsValidS(this.talkList))
      return null;

    let result = null;

    this.talkList.forEach((item) => {
      if (item.ctype === 0 || item.ctype === 1) //일반 채팅일경우
      {
        if (IsValidS(item.chat) && item.chat.indexOf(searchWord) >= 0)
        {
          if (result === null)
            result = [];

          result.push(item);
        }
      }

      if (item.ctype === 3 || item.ctype === 4) //첨부 파일일경우
      {
        if (IsValidS(item.fname) && item.fname.indexOf(searchWord) >= 0)
        {
          if (result === null)
            result = [];

          result.push(item);
        }
      }
    });

    return result;
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------------------------------------------------------------
  connect = () =>
  {
    this.connected = false;

    this.socket = socketIOClient(GetChatServerUrl(), {/*autoConnect: false,*/ transports: ['websocket', 'AJAX long-polling', 'polling']});

    let instance = this;

    this.socket.on("connect", () => {
      if (LOG_ENABLE)
        console.log("connected!!");

      instance.sendUserInfo(); //사용자 정보를 설정하고
    });
    
    this.socket.on("msg", (data) => {
      instance.onMessageReceived(data, instance);
    });

    this.socket.on("disconnect", (reason) => {

      if (LOG_ENABLE)
        console.log("disconnected : " + reason);

      instance.connected = false;
    });
  }

  onMessageReceived = (data, instance) =>
  {
    if (IsValidS(data))
    {
      try
      {
        let json = JSON.parse(data);

        if (!IsValidV(json) || !IsValidV(json.id))
        {
          if (LOG_ENABLE)
            console.log("invalid json");
          return;
        }

        switch(json.id)
        {
          case 0: //사용자 정보의 설정이 완료되었다.
            if (LOG_ENABLE)
              console.log("set chat info done");

            instance.loadLastNewChatList();
            break;
          case 1: //새로운 채팅메시지 수신
            if (LOG_ENABLE)
              console.log("new chatting");

            instance.onNewChat(json);
            break;
          case 3:
            instance.onLoadChatListResult(json);
            break;
          case 5: //신규 채팅 목록 수신됨
            instance.onLoadNewChatListResult(json);
            break;
          case 6://유저의 신규채팅 정보 조회의 결과
            instance.onNewChatUserLoaded(json);
            break;
          default:
            break;
        }
      }
      catch(e)
      {
        console.log(e.toString());
      }
    }
  }

  sendMessage = (msg) =>
  {
    try{
      this.socket.emit('msg', msg);
    }
    catch(e)
    {
      console.log(e.toString());
    }
  }
  //채팅서버 접속후 사용자 정보 설정--------------------------------------------------------------------------------------------------------
  sendUserInfo = () =>
  {
    this.connected = true;
    this.refIdx = -1;
    this.newChat = null;

    if (LOG_ENABLE)
      console.log(`sendUserInfo : ${LoginStoreInstance.userIdx}, ${LoginStoreInstance.compIdx}, 
        ${LoginStoreInstance.getCompanyName}`);

    this.sendMessage(JSON.stringify({id : 4, uidx : LoginStoreInstance.userIdx, widx : LoginStoreInstance.compIdx, 
        uname : ToBase64(LoginStoreInstance.getCompanyName)}));
  }
  //파일전송-------------------------------------------------------------------------------------------------------------------------
  //oriName은 이미 base64인코딩됨
  sendFile = (fileName, oriName, targetUserIdx) =>
  {
    this.sendMessage(JSON.stringify({id : 2, chat : ToBase64(fileName), fname : oriName, 
        ct : 4, uidx : targetUserIdx}));
  }
  //이미지전송-------------------------------------------------------------------------------------------------------------------------
  sendImage = (fileName, oriName, targetUserIdx) =>
  {
    this.sendMessage(JSON.stringify({id : 2, chat : ToBase64(fileName), fname : oriName, 
        ct : 3, uidx : targetUserIdx}));
  }
  //대화종료 전송-------------------------------------------------------------------------------------------------------------------------
  sendTalkStop = (targetUserIdx) =>
  {
    this.sendMessage(JSON.stringify({id : 2, chat : ToBase64("채팅이 종료되었습니다."), fname : "n", 
      ct : 2, uidx : targetUserIdx}));
  }
  //해당 유저의 신규 대화정보조회-------------------------------------------------------------------------------------------------------------------------
  newChatUserCallback = null;

  requestNewChat = (userIdx) =>
  {
    this.sendMessage(JSON.stringify({id : 6, uidx : userIdx}));
  }

  onNewChatUserLoaded = (result) =>
  {
    if (LOG_ENABLE)
      console.log("onNewChatUserLoaded");

    if (IsValidV(result.newchat))
    {
      let newList = [];
      
      newList.push(result.newchat);

      if (IsValidS(this.newChat))
      {
        for (let i = 0;i < this.newChat.length; ++i)
        {
          if (this.newChat[i].uidx !== result.newchat.uidx)
          {
            newList.push(this.newChat[i]);
          }
        }
      }

      this.newChat = newList;

      if (this.newChatUserCallback !== null)
      {
        this.newChatUserCallback(result.newchat.uidx);
      }
    }
  }
  //채팅메시지 발송-------------------------------------------------------------------------------------------------------------------------
  newChatCallback = null;

  sendChat = (chatType, chatMsg, fname, targetUserIdx) =>
  {
    if (IsValidS(chatMsg))
      this.sendMessage(JSON.stringify({id : 2, chat : ToBase64(chatMsg), fname : fname!== "n"  ? ToBase64(fname) : fname, 
        ct : chatType, uidx : targetUserIdx}));
  }

  //신규 채팅 메시지가 수신됨, 도매상의 경우 접속중에 채팅메시지 수신될떄의 처리가 필요함
  onNewChat = (result) =>
  {
    if (LOG_ENABLE)
      console.log("onNewChat : " + JSON.stringify(result));

    if (IsValidV(result.chat))
    {
      this.decodeChatItem(result.chat);

      let currentUser = false;

      if (this.currentTalkUserIdx === result.chat.uidx)
      {//현재 대화상대유저가 보낸 채팅이면
        if (LOG_ENABLE)
          console.log("onNewChat : current user");

        if (!IsValidS(this.talkList))
          this.talkList = [result.chat];
        else
        {
          if (this.checkTalkDate(this.talkList[this.talkList.length - 1], result.chat))
            this.talkList = [...this.talkList, result.chat];
          else
            this.talkList = [...this.talkList, {idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(result.chat.year, 
              result.chat.month, result.chat.day)}, result.chat];
        }

        if (this.newChatCallback !== null)
          this.newChatCallback(true, result.chat);

        currentUser = true;
      }
      else
      {
        //현재 대화 상대가 아닌 다른 유저로부터 채팅이 수신되었다.
        if (LOG_ENABLE)
          console.log("onNewChat : another user");

        if (this.newChatCallback !== null)
          this.newChatCallback(false, result.chat);

        if (result.chat.uidx === LoginStoreInstance.userIdx)
          currentUser = true;
      }

      this.refreshCompanyList((result.chat.ctype === 0 || result.chat.ctype === 1) ? result.chat.chat : result.chat.fname, result.chat.uidx, currentUser, this.isWholeSalesChat(result.chat));
    }
  }

  //운영자가 보낸채팅인가?
  isWholeSalesChat = (chat) =>
  {
    switch(chat.ctype)
    {
      case 0:
      case 2:
      case 3:
      case 4:
      case 5:
        return true;
      default:
        return false;
    }
  }

  checkTalkDate = (talk1, talk2) =>
  {
    if (talk1.year !== talk2.year || talk1.month !== talk2.month || talk1.day !== talk2.day)
      return false;

    return true;
  }

  //새로운 채팅이 수신될때, 업체 목록에서 해당 채팅의 목록을 최상단으로 올리기
  refreshCompanyList = (chat, userIdx, isCurrentUser, wholeSalesChat) =>
  {
    let company = this.findNewChatByUserIdx(userIdx);

    //기존에 목록에 없던 유저면 서버에 해당 유저를 조회한다.
    if (company === null)
    {
      if (LOG_ENABLE)
        console.log(`request newchat info : ${userIdx}`);
        
      this.requestNewChat(userIdx);
    }
    else //기존에 목록에 있다면, 해당 목록을 상단에 올린다.
    {
      company.chat = chat;

      if (isCurrentUser)
        company.rd = 1;
      else if (!wholeSalesChat)
        company.rd = 0;

      let newList = [];
      newList.push(company);

      for (let i = 0;i < this.newChat.length; ++i)
      {
        if (this.newChat[i].uidx !== userIdx)
        {
          newList.push(this.newChat[i]);
        }
      }

      this.newChat = newList;
    }
  }

  decodeChatItem = (item) =>{
    if (IsValidS(item.chat))
      item.chat = FromBase64(item.chat);

    if (IsValidS(item.fname) && item.fname !== "n")
      item.fname = FromBase64(item.fname);

    if (IsValidS(item.cname))
      item.cname = FromBase64(item.cname);

    if (IsValidS(item.rdate))
    {
      let temp = item.rdate.split(" ");

      if (temp.length < 2)
      {
        return;
      }

      let date = temp[0].split("-");

      if (date.length !== 3)
        return;

      item.year = parseInt(date[0]);
      item.month = parseInt(date[1]);
      item.day = parseInt(date[2]);

      temp = temp[1].split(":");

      if (temp < 3)
      {
        return;
      }

      item.hour = temp[0];
      item.min = temp[1];
      //item.sec = temp[2];

      if (parseInt(temp[0]) < 12)
      {
        item.time = "오전 " + temp[0] + ":" + temp[1];
      }
      else
      {
        item.time = "오후 " + temp[0] + ":" + temp[1];
      }

      if (LOG_ENABLE)
        console.log(`${item.year}-${item.month}-${item.day} ${item.hour}:${item.min}`);
    }

   // if (IsValidS(item.rdate))
     // item.rdate = item.rdate.replace("T", " ").replace("Z", "");
  }
  //대화업체 목록읽기-------------------------------------------------------------------------------------------------------------------------
  newChatListCallback = null;

  loadLastNewChatList = () =>
  {
    if (this.chatListLoading)
    {
      if (LOG_ENABLE)
        console.log("loadLastNewChatList : already loading");

      return;
    }

    this.chatListLoading = true;
    //this.chatList = null;

    this.sendMessage(JSON.stringify({id : 5, ridx: this.refIdx}));
  }

  onLoadNewChatListResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("onLoadNewChatListResult : " + JSON.stringify(result));

    this.chatListLoading = false;

    if (!IsValidV(result) || !IsValidS(result.list))
    {
      if (LOG_ENABLE)
        console.log("invalid result");

      if (this.newChatListCallback !== null)
        this.newChatListCallback(0);

      return;
    }

    if (this.refIdx === -1 && IsValidV(result.cnt))
    {
      this.totalCompanyCount = result.cnt;

      if (LOG_ENABLE)
        console.log(`total company count : ${result.cnt}`);
    }

    result.list.forEach((item) => {
      this.decodeNewChatItem(item);

      if (this.refIdx === -1 || this.refIdx > item.idx)
        this.refIdx = item.idx;
    });

    if (!IsValidS(this.newChat))
      this.newChat = result.list;
    else
      this.newChat = [...this.newChat, ...result.list];

    if (this.newChatListCallback !== null)
      this.newChatListCallback(result.list.length);
  }

  decodeNewChatItem = (item) =>
  {
    if (IsValidS(item.cname))
      item.cname = FromBase64(item.cname);

    if (IsValidS(item.chat))
      item.chat = FromBase64(item.chat);
  }

  clearNewChatMark = (idx) =>
  {
    if (LOG_ENABLE)
      console.log(`clearNewChatMark : ${idx}`);

    if (!IsValidS(this.newChat))
      return;

    let updatedChat = null;

    for (let i = 0;i < this.newChat.length; ++i)
    {
      if (this.newChat[i].idx === idx)
      {
        updatedChat = this.newChat[i];
        updatedChat.rd = 1;
        break;
      }
    }

    if (updatedChat === null)
      return;

    let newList = [];

    for (let i = 0;i < this.newChat.length; ++i)
    {
      if (this.newChat[i].idx !== idx)
      {
        newList.push(this.newChat[i]);
      }
      else
        newList.push(updatedChat);
    }

    this.newChat = newList;
  }
  //업체와의 대화 목록읽기-------------------------------------------------------------------------------------------------------------------------
  chatListCallback = null;
  chatLoading = false;
  chatRefIdx = -1;
  currentTalkCount = 0;

  //새로운 대화 목록을 읽기전에 먼저 클리어해줘야한다.
  clearChatList = () =>
  {
    this.talkList = null;
    this.chatRefIdx = -1;
    this.currentTalkUserIdx = -1;
    this.totalTalkCount = 0;
    this.tempIdx = -1;
    this.currentTalkCount = 0;
  }

  //특정유저와의 대화 목록을 읽어들인다.
  loadLastChatList = (userIdx) =>
  {
    if (LOG_ENABLE)
        console.log(`loadLastChatList : ${userIdx}`);

    if (this.chatLoading)
    {
      return;
    }

    this.currentTalkUserIdx = userIdx;
    this.chatLoading = true;
    //this.chatList = null;

    this.sendMessage(JSON.stringify({id : 3, uidx : userIdx, refidx: this.chatRefIdx}));
  }

  //특정 유저와의 대화 목록이 수신됨
  onLoadChatListResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("onLoadChatListResult : " + JSON.stringify(result));

    this.chatLoading = false;

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("invalid result");

      if (this.chatListCallback !== null)
        this.chatListCallback(0);

      return;
    }

    if (IsValidS(result.list) && this.currentTalkUserIdx !== result.list[0].uidx) //현재 대화 상대의 채팅이 아니면 무시한다.
    {
      if (LOG_ENABLE)
        console.log("user mismatch");
    }
    else
    {
      //더이상 읽을 채팅이 없는경우, 채팅의 상단에 날짜 정보를 추가해야한다.
      if (!IsValidS(result.list))
      {
        //기존에 채팅 목록이 있다면 목록 상단에 날짜 정보를 추가해준다.
        if (IsValidS(this.chatList))
        {
          if (this.chatList[0].ctype !== 5)
          {
            let finalList = [{idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(this.chatList[0].year, this.chatList[0].month, 
              this.chatList[0].day)}, ...this.chatList];

            this.chatList = finalList;
          }
        }

        return;
      }

      if (this.chatRefIdx === -1 && IsValidV(result.cnt))
      {
        this.totalTalkCount = result.cnt;

        if (LOG_ENABLE)
          console.log(`total talk count : ${result.cnt}`);
      }

      this.currentTalkCount += result.list.length;

      result.list.forEach((item) => {
        this.decodeChatItem(item);
  
        if (this.chatRefIdx === -1 || this.chatRefIdx > item.idx)
          this.chatRefIdx = item.idx;
      });

      result.list.sort(function (a, b){
        if (a.idx > b.idx)
          return 1;
        
        if (a.idx === b.idx)
          return 0;
  
        return -1;
      });

      let list = this.addDateInfo(result.list, 30);

      if (!IsValidS(this.talkList))
        this.talkList = list;
      else
        this.talkList = [...list, ...this.talkList];

      if (this.chatListCallback !== null)
        this.chatListCallback(result.list.length);
    }
    
  }

  addDateInfo = (chatList, needCount) =>
  {
    try
    {
      let anotherDayFound1 = false;
      let anotherDayFound2 = false;

      if (!IsValidS(chatList))
        return chatList;
  
      let year = chatList[0].year;
      let month = chatList[0].month;
      let day = chatList[0].day;
  
      for (let i = 1;i < chatList.length; ++i)
      {
        if (chatList[i].year !== year || chatList[i].month !== month || chatList[i].day !== day)
        {
          anotherDayFound1 = true;
          break;
        }
      }

      if (IsValidS(this.chatList))
      {
        if (this.chatList[0].year !== year || this.chatList[0].month !== month || this.chatList[0].day !== day)
        {
          anotherDayFound2 = true;
        }
      }

      if (!anotherDayFound1 && !anotherDayFound2)
      {
        //더이상 읽을 채팅이 없다는 의미다. 상단에 날짜 정보를 붙여줘야한다.
        if (chatList.length < needCount)
        {
          return [{idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(chatList[0].year, chatList[0].month, 
            chatList[0].day)}, ...chatList];
        }

        return chatList;
      }

      let newList = [];

      //더이상 읽을 채팅이 없다는 의미다. 상단에 날짜 정보를 붙여줘야한다.
      if (chatList.length < needCount)
      {
        newList.push({idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(chatList[0].year, chatList[0].month, 
          chatList[0].day)});
      }

      newList.push(chatList[0]);

      for (let i = 1;i < chatList.length; ++i)
      {
        if (chatList[i].year !== year || chatList[i].month !== month || chatList[i].day !== day)
        {
          year = chatList[i].year;
          month = chatList[i].month;
          day = chatList[i].day;

          newList.push({idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(year, month, day) });
        }

        newList.push(chatList[i]);
      }

      if (anotherDayFound2)
      {
        newList.push({idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(this.chatList[0].year, this.chatList[0].month, this.chatList[0].day) });
      }

      return newList;
    }
    catch(e)
    {
      return chatList;
    }
  }

  getDateInfo = (year, month, day) =>
  {
    let date = new Date(year, month - 1, day, 0, 0, 0, 0);
    let dayOfWeek = null;

    switch(date.getDay())
    {
      case 0:
        dayOfWeek = " 일요일";
        break;
      case 1:
        dayOfWeek = " 월요일";
        break;
      case 2:
        dayOfWeek = " 화요일";
        break;
      case 3:
        dayOfWeek = " 수요일";
        break;
      case 4:
        dayOfWeek = " 목요일";
        break;
      case 5:
        dayOfWeek = " 금요일";
        break;
      case 6:
      default:
        dayOfWeek = " 토요일";
        break;
    }

    return year.toString() + "/" + NumberFormatString(month) +"/" + NumberFormatString(day) + dayOfWeek;
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------------------------------------------------------------
  //신규 채팅 개수 -------------------------------------------------------------------------------------------------------------------------------------
  loadChatCountCallback = null;

  makeChatCountParam () {
    const params = new URLSearchParams();

    params.append("rt", "0");
    return params;
  }

  loadNewChatCount = (callback) =>
  {
    this.loadChatCountCallback = callback;
    this.newChatCount = 0;

    axios({
      method:"POST",
      url: "chat/cc.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeChatCountParam()
    }).then((res)=>{
        this.parseChatCountResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseChatCountResult(null);
    });
  }

  parseChatCountResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseChatCountResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadChatCountCallback != null)
        this.loadChatCountCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      this.newChatCount = result.cnt;
      this.newChatCountIdx = result.idx;
    }

    if (this.loadChatCountCallback != null)
      this.loadChatCountCallback(result.ret);
  }
  //신규 채팅 개수 클리어 요청-------------------------------------------------------------------------------------------------------------------------------------
  makeNewChatCountClearParam () {
    const params = new URLSearchParams();

    params.append("rt", "2");
    params.append("idx", this.newChatCountIdx.toString());
    return params;
  }

  //신규 채팅 개수 정보를 클리어 요청함
  clearNewChatCount = () =>
  {
    if (this.newChatCount < 1)
      return;
      
    this.newChatCount = 0;

    if (this.newChatCountIdx < 1)
    {
      if (LOG_ENABLE)
        console.log("invalid new chat count idx");

      return;
    }

    axios({
      method:"POST",
      url: "chat/cc.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeNewChatCountClearParam()
    }).then((res)=>{
        this.parseClearNewChatCountResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseClearNewChatCountResult(null);
    });
  }

  parseClearNewChatCountResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseClearNewChatCountResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }
  }
  //업체 검색 -------------------------------------------------------------------------------------------------------------------------------------
  searchChatCallback = null;

  makeSearchParam (sw) {
    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("sw", ToBase64(sw));
    return params;
  }

  searchCompany = (searchWord, callback) =>
  {
    this.searchChatCallback = callback;
    this.searchList = null;

    axios({
      method:"POST",
      url: "chat/ser.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSearchParam(searchWord)
    }).then((res)=>{
        this.parseSearchResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseSearchResult(null);
    });
  }

  parseSearchResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseSearchResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.searchChatCallback != null)
        this.searchChatCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0 && IsValidS(result.list))
    {
      this.validateCompany(result.list);
      this.searchList = result.list;
    }
    else
      this.searchList = null;

    if (this.searchChatCallback != null)
      this.searchChatCallback(result.ret);
  }

  validateCompany = (list) =>{
    if (!IsValidS(list))
      return;

    list.forEach((item) => {
      if (IsValidS(item.cname))
        item.cname = FromBase64(item.cname);

      if (IsValidS(item.addr))
        item.addr = FromBase64(item.addr);
    });
  }
}
//-------------------------------------------------------------------------------------------------------------------------------------------------
const ChatStoreInstance = new ChatStore();
const ChatStoreContext = React.createContext(ChatStoreInstance);

const UseChatStore = () => React.useContext(ChatStoreContext);

export {UseChatStore, ChatStoreInstance};
