import React from 'react';
import { observer } from 'mobx-react';
import { OPChatStoreInstance } from '../../Stores/OPChatStore';
import { GetImgUrl, GetFileUrl, LOG_ENABLE, mobileOn } from '../../URL/AppUrl';
import { IsValidS, IsValidV, ToBase64, UploadFile,  DownloadFile} from '../../Util/Util';
import '../../ChatApp/ChatApp.css';
import './OPChat.css';
import ChatAppDate from '../../ChatApp/ChatAppDate';
import ChatAppEnd from '../../ChatApp/ChatAppEnd';
import ChatAppFile from '../../ChatApp/ChatAppFile';
import ChatAppImg from '../../ChatApp/ChatAppImg';
import ChatAppItem from '../../ChatApp/ChatAppItem';
import ChatAppItemUser from '../../ChatApp/ChatAppItemUser';
import { LoginStoreInstance } from '../../Stores/LoginStore';

class OPChat extends React.Component {
  state = 
  {
    chatMsg : '',
    windowHeight : 0,
  };

  needInit = false;
  intervalId = -1;
  chatContainerRef = null;
  moreChat = false;
  moreOn = false;
  scrollHeight = 0;
  lastScrollHeight = 0;

  fileSendRef = null;
  //chatList = null;
  uploadInfo = null;

  setContainerRef = (ref) =>{
    this.chatContainerRef = ref;
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log(`OPChat.componentDidMount`);

    document.addEventListener("message", this.handleMessageSend);
    window.addEventListener("message", this.handleMessageSend);

    window.addEventListener("resize", this.handleSizeChanging);

    this.intervalId = setInterval(this.update, 100);

    OPChatStoreInstance.clearAll();
    OPChatStoreInstance.newChatListCallback = this.onNewChatList;
    OPChatStoreInstance.newChatArrivedCallback = this.onNewChat;
    OPChatStoreInstance.connect(LoginStoreInstance.compIdx, LoginStoreInstance.userIdx, `${LoginStoreInstance.getCompanyName}(${LoginStoreInstance.getUserName})`, this.sendLogMsg);

    this.setState((prevState) => ({...prevState, windowHeight : window.innerHeight}));
  }

  componentWillUnmount()
  {
    if (LOG_ENABLE)
      console.log(`OPChat.componentWillUnmount`);

    OPChatStoreInstance.clearAll();

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    document.removeEventListener("message", this.handleMessageSend);
    window.removeEventListener("message", this.handleMessageSend);

    window.removeEventListener("resize", this.handleSizeChanging);
  }

  handleSizeChanging = (event) =>
  {
    if (LOG_ENABLE)
      this.sendLogMsg("handleSizeChanging");

    this.setState((prevState) => ({...prevState, windowHeight : window.innerHeight}));
  }

  update = () =>
  {
    if (IsValidV(this.chatContainerRef) &&IsValidV(this.chatContainerRef.scrollHeight) && IsValidV(window.innerHeight))
    {
      if (!this.needInit || (!this.moreOn && this.chatContainerRef.scrollHeight !== this.lastScrollHeight))
      {
        this.lastScrollHeight = this.chatContainerRef.scrollHeight;

        if (LOG_ENABLE)
          this.sendLogMsg('update scroll');

        this.needInit = true;

        if (!mobileOn)
          this.chatContainerRef.scrollTo(0, this.chatContainerRef.scrollHeight - 574);
        else
          this.chatContainerRef.scrollTo(0, this.chatContainerRef.scrollHeight - (this.state.windowHeight - 40 - 86));

        /*if (this.intervalId !== -1)
        {
          clearInterval(this.intervalId);
          this.intervalId = -1;
        } */ 
      }
    }
  }

  //새로운 채팅 목록이 수신될때마다 호출된다. count : 수신된 채팅의 개수
  onNewChatList = (count) =>
  {
    if (LOG_ENABLE)
      console.log(`onNewChatList : ${count}`);

    if (count > 0)
    {
      if (OPChatStoreInstance.getTotalChatCount < 50 && count >= 30)
      {
        if (!OPChatStoreInstance.chatListLoading && OPChatStoreInstance.totalChatCount !== 0 && 
          OPChatStoreInstance.totalChatCount > OPChatStoreInstance.getTotalChatCount)
          OPChatStoreInstance.loadLastChatList();
      }
    }
  }

  //신규 채팅 한개가 수신될때
  onNewChat = () =>
  {
    this.moreOn = false;
  }

  //리액트로 부터 수신된 메시지
  handleMessageSend = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleMessageSend : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (IsValidV(msg))
      {
        switch(msg.id)
        {
          case 0: //채팅 메시지 전송
            if (OPChatStoreInstance.connected)
            {
              OPChatStoreInstance.sendChat(msg.data);
            }
            else
            {
              alert("채팅 서버와 연결되어 있지 않습니다.");
            }
            break;
          case 3: //유저의 세션 정보 요청에 대한 응답
            break;
          case 10: //파일선택요청
            this.SelectFile();
            break;
          default:
            break;
        }
      }
    }
    catch(e)
    {
      this.postMessage(e.toString());
    }
  }

  onSendMsg = () =>
  {
    if (!IsValidS(this.state.chatMsg))
      return;

    let msg = this.state.chatMsg;

    this.setState((prevState) => ({...prevState, chatMsg : ''}))

    if (OPChatStoreInstance.connected)
    {
      OPChatStoreInstance.sendChat(ToBase64(msg));
    }
    else
    {
      alert("채팅 서버와 연결되어 있지 않습니다.");
    }
  }

  sendLogMsg = (logMsg) =>
  {
    console.log(logMsg);
    this.postMessage(JSON.stringify({id : 100, msg : logMsg}));
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        // 모바일이라면 모바일의 카메라 권한을 물어보는 액션을 전달합니다.
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  SelectFile = () =>
  {
    if (this.props.appOn)
    {
      alert('현재 앱에서는 이기능이 지원되지 않습니다. 모바일 웹 또는 PC를 이용해주세요.');

      return;
    }

    if (IsValidV(this.fileSendRef))
    {
      this.sendLogMsg("file selector found");

      /*if (IsValidV(this.chatContainerRef))
      {
        this.sendLogMsg("focus to container");

        this.chatContainerRef.focus();
      }*/

      this.fileSendRef.focus();
      this.fileSendRef.click();
    }
  }

  handleDownload = (isImage, fileName, oriName) =>
  {
    if (LOG_ENABLE)
      this.sendLogMsg(`handleDownload : ${fileName}, ${oriName}`);

    DownloadFile(GetFileUrl(fileName), oriName);

    //this.postMessage(JSON.stringify({id : isImage ? 2 : 1, fn : fileName, on : oriName}));
  }

  handleScroll = (e) =>{

    //if (LOG_ENABLE)
      //this.sendLogMsg(`top = ${e.target.scrollTop}, client height=${e.target.clientHeight}, scroll height : ${e.target.scrollHeight}`);

    if (e.target.scrollTop === 0){

      if (OPChatStoreInstance.totalChatCount !== 0 && OPChatStoreInstance.totalChatCount > OPChatStoreInstance.currentTotal &&
        !OPChatStoreInstance.chatListLoading)
      {
        if (LOG_ENABLE)
          this.sendLogMsg("on scroll TOP!!!");

        this.moreChat = true;
        this.moreOn = true;
        this.scrollHeight = e.target.scrollHeight;

        OPChatStoreInstance.loadLastChatList();
      }
    }
  }

  handleUploadFile = (file) =>
  {
    if (LOG_ENABLE)
      this.sendLogMsg("sendFile : " + file.name);

      /*
    if (!OPChatStoreInstance.connected)
    {
      alert("현재 채팅서버와 접속되어 있지 않습니다. 잠시 후 이용해 주세요.");
      return;
    }*/

    if (IsValidV(file) && IsValidS(file.name))
    {
      let upperName = file.name.toUpperCase();

      if (IsValidS(upperName))
      {
        if (upperName.indexOf('.JSP') >= 0 || upperName.indexOf('.PH') >= 0 || upperName.indexOf('.PHP') >= 0 || upperName.indexOf('.HTM') >= 0 || 
          upperName.indexOf('.ASP') >= 0 || upperName.indexOf('.VB') >= 0 || upperName.indexOf('.CSS') >= 0 || upperName.indexOf('.JS') >= 0 ||
          upperName.indexOf('.JAVA') >= 0 || upperName.indexOf('.EXE') >= 0 || upperName.indexOf('.COM') >= 0 || upperName.indexOf('.ADE') >= 0 || 
          upperName.indexOf('.APP') >= 0 || upperName.indexOf('.BAT') >= 0 || upperName.indexOf('.CAB') >= 0 || 
          upperName.indexOf('.CMD') >= 0 || upperName.indexOf('.SH') >= 0 || upperName.indexOf('.CHM') >= 0 || upperName.indexOf('.CPL') >= 0 || 
          upperName.indexOf('.DLL') >= 0 || upperName.indexOf('.DMG') >= 0 || upperName.indexOf('.EX') >= 0 || upperName.indexOf('.HTA') >= 0 || 
          upperName.indexOf('.JAR') >= 0 || upperName.indexOf('.ISP') >= 0 || upperName.indexOf('.INS') >= 0 || upperName.indexOf('.LIB') >= 0 || 
          upperName.indexOf('.LNK') >= 0 || upperName.indexOf('.MDE') >= 0 || upperName.indexOf('.MSC') >= 0 || upperName.indexOf('.MSI') >= 0 || 
          upperName.indexOf('.MSP') >= 0 || upperName.indexOf('.MST') >= 0 || upperName.indexOf('.NSH') >= 0 || upperName.indexOf('.PIF') >= 0 || 
          upperName.indexOf('.SCR') >= 0 || upperName.indexOf('.SCT') >= 0 || upperName.indexOf('.SHB') >= 0 || upperName.indexOf('.SYS') >= 0 || 
          upperName.indexOf('.VXD') >= 0 || upperName.indexOf('.WSC') >= 0 || upperName.indexOf('.WSF') >= 0 || upperName.indexOf('.WSH') >= 0 ||
          upperName.indexOf('.XML') >= 0 || upperName.indexOf('.CLA') >= 0 || upperName.indexOf('.WAR') >= 0 || upperName.indexOf('.HTACCESS') >= 0 || 
          upperName.indexOf('.JSON') >= 0 || upperName.indexOf('.TS') >= 0 || upperName.indexOf('.VXD') >= 0 || upperName.indexOf('.PY') >= 0)
        {
          if (LOG_ENABLE)
            console.log(upperName);
            
          alert("해당 형식의 파일은 업로드 할 수 없습니다.");
          return;
        }
      }
      
      this.uploadInfo = {fn : file.name, fl : file, isFile : true};

      UploadFile(LoginStoreInstance.userIdx, 1, file, this.onUploadDone)
    }
  }

  onUploadDone = (success, oldName, newName) =>
  {
    if (!success)
    {
      alert("파일 전송에 실패 했습니다.");
      return;
    }

    if (!OPChatStoreInstance.connected)
    {
      alert("현재 채팅서버와 접속되어 있지 않습니다. 잠시 후 이용해 주세요.");
      return;
    }
    
    OPChatStoreInstance.sendFile(newName, oldName);
  }

  componentDidUpdate(){

    if (LOG_ENABLE)
      console.log("componentDidUpdate");

    if (IsValidV(this.chatContainerRef) && IsValidV(this.chatContainerRef.scrollHeight) && IsValidV(window.innerHeight))
    {
      if (this.moreChat)
      {
        if (LOG_ENABLE)
          this.sendLogMsg('update scroll2');

        this.moreChat = false;
        this.chatContainerRef.scrollTo(0, this.chatContainerRef.scrollHeight - this.scrollHeight);
      }
      else
      {
        //if (LOG_ENABLE)
          //this.sendLogMsg('update scroll3');

        if (mobileOn)
          this.chatContainerRef.scrollTo(0, this.chatContainerRef.scrollHeight - (this.state.windowHeight - 40 - 86));
        else
          this.chatContainerRef.scrollTo(0, this.chatContainerRef.scrollHeight - 574);
      }
    }
  }

  render() {
    const chatList = OPChatStoreInstance.chatList;

    let list = null;

    if (IsValidS(chatList))
    {
      list = chatList.map(item => {
        switch (item.ctype)
        {
          case 0: //운영자 채팅
            return (<ChatAppItem key={item.idx} item={item}/>);
          case 1 : //유저 채팅
            return (<ChatAppItemUser key={item.idx} item={item}/>);
          case 2: //채팅 종료마크
            return (<ChatAppEnd key={item.idx} />);
          case 3: //첨부 이미지
          case 6:
            return (<ChatAppImg key={item.idx} item={item} handleDownload = {this.handleDownload}/>);
          case 4: //첨부파일
          case 7:
            return (<ChatAppFile key={item.idx} item={item} handleDownload = {this.handleDownload}/>);
          case 5: //날짜정보
            return (<ChatAppDate key = {item.idx} item={item}/>);
          default:
            return null;
        }
      });
    }

    if (mobileOn)
    {
      return (
        <div className="opchatcontainermb" /*style={{height : window.innerHeight}}*/>
          <div className="opchattitlearea flexcenterrow">
            <div className="dcell1"></div>
            <div className="dcell8">
              <label className="fontnwb flexcenterrow">업무 지원</label>
            </div>
            <div className="dcell1 flexcenterrow">
              <img src={GetImgUrl("opchatcl.png")} alt="닫기" onClick={() => this.props.handleCloseChat()} className="btncur"/>
            </div>
          </div>
  
          <div className="opchatappcont"
            style={{height : this.state.windowHeight - 40 - 86}}
            onScroll={this.handleScroll} 
            ref = {(ref) => this.setContainerRef(ref)}
          >
            {list}
          </div>
          
          
          <input type="file"
            id="fileSelect2"
            name="fileSelect2"
            accept="image/*,video/*, .xls, .ppt, .pptx, xlsx, .doc, .docx, .hwp, .zip, .7z, .rar, .alz, .egg, .tar, .lzh"
            className="chatappuploader"
            ref = {(ref) => this.fileSendRef = ref} 
  
            onChange={(e) => {
  
              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              {
                if (LOG_ENABLE)
                  this.sendLogMsg("no selected file");
                return;
              }
                
              if (LOG_ENABLE)
                this.sendLogMsg(e.target.files[0]);
  
                this.handleUploadFile(e.target.files[0]);
                //props.sendFile(e.target.files[0].name, e.target.files[0]);
            }}
          />
  
          <div className="opchatappinparea flexcentercol">
            <div className="chatappinputline"></div>
  
            <div className="chatappinparea2 flexcenterrow">
              <div className="chatappinpleft btncur flexcenterrow" onClick={this.SelectFile}>
                <img src={GetImgUrl("camicon.png")} alt="첨부파일" className="btncur"/>
              </div>
  
              <div className="chatappinpright flexcenterrow">
                <div className="chatappinpedit flexcenterrow">
                  <div className="chatappinpeditleft flexcenterrow">
                    <textarea className="chatappinput" wrap="off" value={this.state.chatMsg} 
                      onChange={e=>this.setState((prevState) => ({...prevState, chatMsg : e.target.value}))}
                      maxLength="190"
                      placeholder="메세지 내용을 입력해주세요."
                      rows = {1}
                      onKeyPress = {(e) => {
                        if (e.key === "Enter")
                        {
                          e.preventDefault();
                          this.onSendMsg();
                        }
                      }}
                    />
                  </div>
                  <div className="chatappinpeditright flexcenterrow" onClick={()=>this.onSendMsg()}>
                    <img src={GetImgUrl("sendicon.png")} alt="발송" className="btncur"/>
                  </div>              
                </div>
              </div>
            </div>
            
          </div>
        </div>
      );
    }
    else
    {
      return (
        <div className="opchatcontainer" /*style={{height : window.innerHeight}}*/>
          <div className="opchattitlearea flexcenterrow">
            <div className="dcell1"></div>
            <div className="dcell8">
              <label className="fontnwb flexcenterrow">업무 지원</label>
            </div>
            <div className="dcell1">
              <img src={GetImgUrl("opchatcl.png")} alt="닫기" onClick={() => this.props.handleCloseChat()} className="btncur"/>
            </div>
          </div>
  
          <div className="opchatappcont"
            //style={{height : this.state.windowHeight - 86}}
            onScroll={this.handleScroll} 
            ref = {(ref) => this.setContainerRef(ref)}
          >
            {list}
          </div>
          
          
          <input type="file"
            id="fileSelect2"
            name="fileSelect2"
            accept="image/*,video/*, .xls, .ppt, .pptx, xlsx, .doc, .docx, .hwp, .zip, .7z, .rar, .alz, .egg, .tar, .lzh"
            className="chatappuploader"
            ref = {(ref) => this.fileSendRef = ref} 
  
            onChange={(e) => {
  
              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              {
                if (LOG_ENABLE)
                  this.sendLogMsg("no selected file");
                return;
              }
                
              if (LOG_ENABLE)
                this.sendLogMsg(e.target.files[0]);
  
                this.handleUploadFile(e.target.files[0]);
                //props.sendFile(e.target.files[0].name, e.target.files[0]);
            }}
          />
  
          <div className="opchatappinparea flexcentercol">
            <div className="chatappinputline"></div>
  
            <div className="chatappinparea2 flexcenterrow">
              <div className="chatappinpleft btncur flexcenterrow" onClick={this.SelectFile}>
                <img src={GetImgUrl("camicon.png")} alt="첨부파일" className="btncur"/>
              </div>
  
              <div className="chatappinpright flexcenterrow">
                <div className="chatappinpedit flexcenterrow">
                  <div className="chatappinpeditleft flexcenterrow">
                    <textarea className="chatappinput" wrap="off" value={this.state.chatMsg} 
                      onChange={e=>this.setState((prevState) => ({...prevState, chatMsg : e.target.value}))}
                      maxLength="190"
                      placeholder="메세지 내용을 입력해주세요."
                      rows = {1}
                      onKeyPress = {(e) => {
                        if (e.key === "Enter")
                        {
                          e.preventDefault();
                          this.onSendMsg();
                        }
                      }}
                    />
                  </div>
                  <div className="chatappinpeditright flexcenterrow" onClick={()=>this.onSendMsg()}>
                    <img src={GetImgUrl("sendicon.png")} alt="발송" className="btncur"/>
                  </div>              
                </div>
              </div>
            </div>
            
          </div>
        </div>
      );
    }
  }
}

export default observer(OPChat);