import React from 'react';

//모바일용
const EmpInfomb = (props) => {
  return (
    <tr className="emptabledata">
      <td className="emptablecell">
        <div className="emptablecellinmb flexcenterrow">
          <input type="radio" onChange={(e) => props.onCheckChanged(props.index)} className="chk" checked={props.selectIdx === props.index}/>
        </div>
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellinmb flexcenterrow">
          <label className="mfontn">{props.num}</label>
        </div>
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellinmb flexcenterrow">
          <label className="mfontn">{props.name}<br/>{`(${props.cid})`}</label>
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellinmb flexcenterrow">
          <button className="empdetmbbtn btncur mfontnwb" onClick={() => props.handleDetail(props.num)}>상세정보</button>
        </div>
      </td>
    </tr>
  );
}

export default EmpInfomb;