import React from 'react';
import './Menu.css';

import {GetImgUrl} from '../../URL/AppUrl';
import { observer } from 'mobx-react';
import { UseChatStore } from '../../Stores/ChatStore';
import { UseLoginStore } from '../../Stores/LoginStore';
//import { Link } from "react-router-dom";

/*
micon.png
uicon.png
cmpicon.png
papers.png
new.png
truck.png
notice.png
chat.png
*/
const MainMenu = observer((props) => {
  const chatStore = UseChatStore();
  const loginStore = UseLoginStore();

  return (
    <div className="menucontainer">
      <div className="flexcenterrow menu">
        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(0)} >
          <img src={GetImgUrl(props.index === 0?"micon.png":"home_btn.png")} alt="메인화면"/>
          <label className= {props.index === 0 ? "menulinktextsel" : "menulinktext"}>메인 화면</label>
        </div>
        
        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(1)} >
          <img src={GetImgUrl(props.index === 1 ? "usersel.png":"uicon.png")} alt="직원관리"/>
          <label className={props.index === 1 ? "menulinktextsel" : "menulinktext"}>직원 관리</label>
        </div>

        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(2)} >
          <img src={GetImgUrl(props.index === 2 ? "shopsel.png":"shop.png")} alt="거래처관리"/>
          <label className={props.index === 2 ? "menulinktextsel" : "menulinktext"}>거래처 관리</label>
        </div>

        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(3)} >
          <img src={GetImgUrl(props.index === 3 ? "orderen.png":"order.png")} alt="주문관리"/>
          <label className={props.index === 3 ? "menulinktextsel" : "menulinktext"}>주문 관리</label>
        </div>

        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(4)} >
          <img src={GetImgUrl(props.index === 4 ? "paperssel.png":"papers.png")} alt="판매일보"/>
          <label className={props.index === 4 ? "menulinktextsel" : "menulinktext"}>판매 일보</label>
        </div>

        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(6)} >
          <img src={GetImgUrl(props.index === 6 ? "etcssel.png":"etcs.png")} alt="기타 주문"/>
          <label className={props.index === 6 ? "menulinktextsel" : "menulinktext"}>기타 주문</label>
        </div>

        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(12)} >
          <img src={GetImgUrl(props.index === 12 ? "mnuodmen.png":"mnuodm.png")} alt="수기 주문"/>
          <label className={props.index === 12 ? "menulinktextsel" : "menulinktext"}>수기주문등록</label>
        </div>

        {
          loginStore.getUserLevel <= 1 ? (
            <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(11)} >
              <img src={GetImgUrl(props.index === 11 ? "graphen.png":"graph.png")} alt="앱 이용자 통계"/>
              <label className={props.index === 11 ? "menulinktextsel" : "menulinktext"}>앱 이용자 통계</label>
            </div>
          ) : null
        }

        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(5)} >
          <img src={GetImgUrl(props.index === 5 ? "newsel.png" : "new.png")} alt="신규상품등록"/>
          <label className={props.index === 5 ? "menulinktextsel" : "menulinktext"}>신규 상품 등록</label>
        </div>

        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(7)} >
          <img src={GetImgUrl(props.index === 7 ? "noticesel.png" : "notice.png")} alt="공지사항"/>
          <label className={props.index === 7 ? "menulinktextsel" : "menulinktext"}>공지사항</label>
        </div>

        <div className="menuitem flexcenterrow" onClick={() => props.handleMenuClick(8)}>
          <img src={GetImgUrl(props.index === 8 ? "chatsel.png" : "chat.png")} alt="채팅"/>
          {
            chatStore.newChatCount > 0 ? (<div className="menunewitem"/>) : null
          }
          
          <label className={props.index === 8 ? "menulinktextsel" : "menulinktext"}>채팅</label>
        </div>
      </div>
      <div className="menuline"></div>
    </div>
  );
});

export default MainMenu;