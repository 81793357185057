const INNER_FONT_SIZE = 8;

//font: 13pt Georgia, "Times New Roman", Times, serif;
const PrintStyle=`
<style type="text/css" media="print">
@page{
  //size: A4 portrait;
  //size: A4 landscape;
  size: auto;
  
  margin-left: 7mm;
  margin-right: 7mm;
  margin-top: 7mm;
  margin-bottom: 7mm;
}

*{
  -webkit-print-color-adjust: exact !important;
	print-color-adjust: exact !important;
  color-adjust: exact !important;  /*Firefox*/
}
</style>

<style type="text/css" >

body
{
  overflow : auto;
  display : flex;
	flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  
  line-height: 1.3;
  background: #fff !important;
  color: #000;
  border:0; margin:0; padding:0;
}

table { page-break-inside:auto; }

tr    {
  page-break-inside:auto;
  page-break-after:auto;
  -webkit-region-break-inside: avoid;
}
thead { display:table-header-group; }
tbody { display:table-header-group; }
tfoot { display:table-footer-group; }

::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

/*전체 컨테이너*/
.container
{
	width : 100%;
  height : auto;
  overflow : auto;
	display : flex;
	flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*타이틀 영역*/
.titlear
{
  width : 100%;
  height: 99pt;

  display : flex;
	flex-direction: row;
  justify-content: center;
  align-items: center;
}

/*타이틀 왼쪽 영역*/
.titleleft
{
  width: 75%;
  height : 99pt;
  display : flex;
	flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

/*타이틀 왼쪽 위 영역*/
.titlelefttop
{
  width: 100%;
  height: 50%; 
  display : flex;
	flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15pt;
}

.titlelefttopline
{
  width: 242pt;
  height: 1.5pt; 
  background-color: {0};
}

/*타이틀 왼쪽 아래영역*/
.titleleftbottom
{
  width: 100%;
  height: 50%; 
  display : flex;
	flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/*타이틀 오른쪽 결제 영역*/
.titleright
{
  width: 25%;
  height: 99pt;
  display : flex;
	flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

}

/*타이틀 오른쪽 결제 영역 테이블*/
.titlerighttable
{
  width: 100%;
  //height: 90pt;
  border-collapse: collapse;
  border : 1pt solid {0};
}

.titlerighttabletop
{
  width: 100%;
  height : 25pt;
  background-color: black;
  display: block;
}

/*타이틀 오른쪽 결제 영역 테이블 타이틀*/
.titlerighttabletitle
{
  background-color: {0};
  height: 27pt;
  min-height: 27pt;
  max-height: 27pt;
  border: 1pt solid {0};
}

/*타이틀 오른쪽 결제 영역 테이블 타이틀 계*/
.titlerighttabletitlecl
{
  width: 33%;
  background-color: white;
  border: 1pt solid {0};
}

/*타이틀 오른쪽 결제 영역 테이블 타이틀 상무*/
.titlerighttabletitlecc
{
  width: 33%;
  border: 1pt solid {0};
}

/*타이틀 오른쪽 결제 영역 테이블 타이틀 사장*/
.titlerighttabletitlecr
{
  width: 34%;
  border: 1pt solid {0};
}

.titlerighttableht
{
  /*height: 60pt;
  min-height: 60pt;
  max-height: 60pt;*/

  height: 45pt;
  min-height: 45pt;
  max-height: 45pt;
}

/*콘텐트 영역*/
.content
{
	width : 100%;
  overflow : auto;
  display : flex;
	flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.newpage
{
  page-break-before: always; 
}

/*콘텐트 테이블*/
.contenttable
{
  width: 100%;
  border-collapse: collapse;
  border : 1pt solid {0};
  margin-top: 1pt;
}

.contenttable2
{
  width: 100%;
  border-collapse: collapse;
  border : none;
  margin-top: 1pt;
}

/*테이블 타이틀 거래처명*/
.contenttabletitle0
{
  width : 5%;
	min-width : 5%;
	max-width : 5%;
  background-color: {0};
  height: 27pt;
  border: 1pt solid {0};
}

/*테이블 타이틀 거래처명*/
.contenttabletitlea
{
  width : 13%;
	min-width : 13%;
	max-width : 13%;
  background-color: {0};
  height: 27pt;
  border: 1pt solid {0};
}

/*테이블 타이틀 상품명*/
.contenttabletitleb
{
  width : 46%;
	min-width : 46%;
	max-width : 46%;
  background-color: {0};
  height: 27pt;
  border: 1pt solid {0};
}

/*테이블 타이틀 상품대*/
.contenttabletitlec
{
  width : 10%;
	min-width : 10%;
	max-width : 10%;
  background-color: {0};
  height: 27pt;
  border: 1pt solid {0};
}

/*테이블 타이틀 공병대*/
.contenttabletitled
{
  width : 10%;
	min-width : 10%;
	max-width : 10%;
  background-color: {0};
  height: 27pt;
  border: 1pt solid {0};
}

/*테이블 타이틀 카드*/
.contenttabletitlee
{
  width : 8%;
	min-width : 8%;
	max-width : 8%;
  background-color: {0};
  height: 27pt;
  border: 1pt solid {0};
}

/*테이블 타이틀 현금*/
.contenttabletitlef
{
  width : 8%;
	min-width : 8%;
	max-width : 8%;
  background-color: {0};
  height: 27pt;
  border: 1pt solid {0};
}

/*테이블 내용 거래처명*/
.ctable0
{
	width : 5%;
	max-width : 5%;
	min-width : 5%;
	border: 1pt solid {0};
}

/*테이블 내용 거래처명*/
.ctablea
{
	width : 13%;
	max-width : 13%;
	min-width : 13%;
	border: 1pt solid {0};
}

/*테이블 내용 상품명*/
.ctableb
{
	width : 46%;
  max-width : 36%;
	min-width : 36%;
  height: auto;
	border: 1pt solid {0};
}

.ctableb2
{
	width : 87%;
  max-width : 36%;
	min-width : 36%;
  height: auto;
	border: 1pt solid {0};
}

/*테이블 내용 상품대*/
.ctablec
{
	width : 10%;
  max-width : 10%;
	min-width : 10%;
  height: auto;
	border: 1pt solid {0};
}

/*테이블 내용 공병대*/
.ctabled
{
	width : 10%;
  max-width : 10%;
	min-width : 10%;
  height: auto;
	border: 1pt solid {0};
}

/*테이블 내용 카드*/
.ctablee
{
	width : 8%;
  max-width : 8%;
	min-width : 8%;
  height: auto;
	border: 1pt solid {0};
}

/*테이블 내용 현금*/
.ctablef
{
	width : 8%;
  max-width : 8%;
	min-width : 8%;
  height: auto;
	border: 1pt solid {0};
}

/*테이블 내용 컨텐츠*/
.tcontent
{
	min-height : 27pt;
	display : flex;
	align-items : flex-start;
	justify-content : flex-start;
}

/*테이블 내용 컨텐츠*/
.tcontent2
{
	min-height : 27pt;
	display : flex;
	align-items : center;
	justify-content : center;
}

/*테이블 내용 상품명*/
.ctablebtt
{
	width : 13%;
  height: auto;
	max-width : 13%;
	min-width : 13%;
	border: 1pt solid {0};
  background-color : {0};
}

/*합계영역 합계*/
.ctotal
{
	width : 100%;
  height : 100%;
	background-color : {0};
  min-height : 27pt;
  display : flex;
	flex-direction : row;
	justify-content : center;
	align-items : center;
  border : none;
}

.footer
{
	width : 100%;
	//min-height : 27pt;
  height: auto;
  page-break-inside:avoid;
	page-break-after: auto;
  page-break-before: auto;
  display : flex;
  justify-content: center;
  align-items: flex-start;
  //margin-top : 1pt;
}

.footerleft
{
	width : 64%;
  min-width : 54%;
  max-width : 54%;
	min-height : 27pt;
  border-style : solid solid;
	border-top-width: 1pt;
	border-left-width: 1pt;
	border-right-width: 0px;
	border-bottom-width: 1pt;
	border-color: white;

  page-break-inside:avoid;
}

.footercenter
{
	width : 10%;
  min-width : 10%;
  max-width : 10%;
	min-height : 27pt;
  border: 1px solid {0};
  background-color : {0};
  text-align : center;
  page-break-inside:avoid;
}

.footercenter2
{
	width : 10%;
  min-width : 10%;
  max-width : 10%;
	min-height : 27pt;
  border: none;
  background-color : white;
  text-align : center;
  page-break-inside:avoid;
}

.footerright
{
	width : 36%;
  min-width : 36%;
  max-width : 36%;
	min-height : 27pt;
  border: 1pt solid {0};
  page-break-inside:avoid;
}

.footerright2
{
	width : 36%;
  min-width : 36%;
  max-width : 36%;
	min-height : 27pt;
  border: none;
  page-break-inside:avoid;
}

/*타이틀 제목폰트*/
.titlefont
{
  font-size: 30pt;
  font-weight: bold;
  color: {1};
}

/*타이틀 날짜 폰트*/
.titledatefont
{
  font-size: 15pt;
  font-weight: bold;
  color: {1};
}

.tabletextfont
{
  font-size: 10pt;
  font-weight: normal;
  color: {1};
  page-break-inside:avoid;
  -webkit-region-break-inside: avoid;
}

.titletablefont
{
  font-size: 10pt;
  font-weight: normal;
  color: white;
}

.infofont
{
  font-size: 10pt;
  font-weight: normal;
  color: white;
}

.innertextfont
{
  font-size: {2}pt;
  font-weight: normal;
  color: {1};
  page-break-inside:avoid;
  -webkit-region-break-inside: avoid;
}

.totalinfo
{
  width: 100%;
  height: auto;
  display : flex;
  justify-content : center;
	align-items : flex-start;
  page-break-inside:avoid;
	page-break-after: auto;
  page-break-before: auto;
  //margin-top: 1pt; 
}

.ttfont2
{
  font-size: 14pt;
  font-weight: bold;
  color: black;
}
</style>
`;

const PrintInfo = `
  <div class="container">

    <div class="titlear">
      <div class="titleleft">
        <div class="titlelefttop">
          <label class="titlefont">일 일 판 매 일 보</label>
          <div class="titlelefttopline"></div>
        </div>
        
        <div class="titleleftbottom">
          <img src='https://cdn.bravo6.kr/judo/printday.png' alt='날짜'/>
          <label class="titledatefont">{0}</label>
        </div>
      </div>

      <div class="titleright">
        <!--<div class="titlerighttabletop">
        </div>-->
        <table class="titlerighttable">
          <thead>
            <tr>
            <th class="titlerighttabletitle">
              <label class="titletablefont">팀장</label>
            </th>

            <th class="titlerighttabletitle">
              <label class="titletablefont">전무</label>
            </th>
            
            <th class="titlerighttabletitle">
              <label class="titletablefont">사장</label>
            </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="titlerighttabletitlecl"><div class="titlerighttableht"/></td>
              <td class="titlerighttabletitlecc"><div class="titlerighttableht"/></td>
              <td class="titlerighttabletitlecr"><div class="titlerighttableht"/></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="content">
      <table class="contenttable">
        <thead>
          <tr>

          <th class="contenttabletitle0">
            <label class="titletablefont">이동순서</label>
          </th>

          <th class="contenttabletitlea">
            <label class="titletablefont">거래처명</label>
          </th>

          <th class="contenttabletitleb">
            <label class="titletablefont">상품명</label>
          </th>
          
          <th class="contenttabletitlec">
            <label class="titletablefont">상품대</label>
          </th>
          
          <th class="contenttabletitled">
            <label class="titletablefont">공병대</label>
          </th>
          
          <th class="contenttabletitlee">
            <label class="titletablefont">카드</label>
          </th>
          
          <th class="contenttabletitlef">
            <label class="titletablefont">현금</label>
          </th>
          </tr>
        </thead>
        <tbody>
          {1}
        </tbody>
      </table>
      {3}

      <div class="content">
        <table class="contenttable">
          <tbody>
            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div class="totalinfo">
        <table class="contenttable">
          <tbody>
            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablebtt">
                <div class="ctotal"><label class="infofont">합계</label></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"><label class="innertextfont">{2}<br></label></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>

      <div class="footer">
        <table class="contenttable">
          <tbody>
            <tr>
              <td class="footerleft">
                <div class="tcontent2">
                  <label class="ttfont2">{4}</label>
                </div>
              </td>
              <td class="footercenter">
                <div class="tcontent2">
                  <label class="infofont">공병대금</label>
                </div>
              </td>
              <td class="footerright">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="footerleft">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="footerright">
                <div class="tcontent"></div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      
    </div>
  </div>
`;

const PrintInfoNoTotal = `
  <div class="container">

    <div class="titlear">
      <div class="titleleft">
        <div class="titlelefttop">
          <label class="titlefont">일 일 판 매 일 보</label>
          <div class="titlelefttopline"></div>
        </div>
        
        <div class="titleleftbottom">
          <img src='https://cdn.bravo6.kr/judo/printday.png' alt='날짜'/>
          <label class="titledatefont">{0}</label>
        </div>
      </div>

      <div class="titleright">
        <!--<div class="titlerighttabletop">
        </div>-->
        <table class="titlerighttable">
          <thead>
            <tr>
            <th class="titlerighttabletitle">
              <label class="titletablefont">팀장</label>
            </th>

            <th class="titlerighttabletitle">
              <label class="titletablefont">전무</label>
            </th>
            
            <th class="titlerighttabletitle">
              <label class="titletablefont">사장</label>
            </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="titlerighttabletitlecl"><div class="titlerighttableht"/></td>
              <td class="titlerighttabletitlecc"><div class="titlerighttableht"/></td>
              <td class="titlerighttabletitlecr"><div class="titlerighttableht"/></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="content">
      <table class="contenttable">
        <thead>
          <tr>

          <th class="contenttabletitle0">
            <label class="titletablefont">이동순서</label>
          </th>

          <th class="contenttabletitlea">
            <label class="titletablefont">거래처명</label>
          </th>

          <th class="contenttabletitleb">
            <label class="titletablefont">상품명</label>
          </th>
          
          <th class="contenttabletitlec">
            <label class="titletablefont">상품대</label>
          </th>
          
          <th class="contenttabletitled">
            <label class="titletablefont">공병대</label>
          </th>
          
          <th class="contenttabletitlee">
            <label class="titletablefont">카드</label>
          </th>
          
          <th class="contenttabletitlef">
            <label class="titletablefont">현금</label>
          </th>
          </tr>
        </thead>
        <tbody>
          {1}
        </tbody>
      </table>
      {3}

      <div class="content">
        <table class="contenttable">
          <tbody>
            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="ctable0">
                <div class="tcontent"></div>
              </td>
              <td class="ctablea">
                <div class="tcontent"></div>
              </td>
              <td class="ctableb">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="ctabled">
                <div class="tcontent"></div>
              </td>
              <td class="ctablee">
                <div class="tcontent"></div>
              </td>
              <td class="ctablef">
                <div class="tcontent"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="footer">
        <table class="contenttable">
          <tbody>
            <tr>
              <td class="footerleft">
                <div class="tcontent2">
                  <label class="ttfont2">{2}</label>
                </div>
              </td>
              <td class="footercenter">
                <div class="tcontent2">
                  <label class="infofont">공병대금</label>
                </div>
              </td>
              <td class="footerright">
                <div class="tcontent"></div>
              </td>
            </tr>

            <tr>
              <td class="footerleft">
                <div class="tcontent"></div>
              </td>
              <td class="ctablec">
                <div class="tcontent"></div>
              </td>
              <td class="footerright">
                <div class="tcontent"></div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      
    </div>
  </div>
`;

const PrintInfoTotalOnly = `
  <div class="container">

    <div class="titlear">
      <div class="titleleft">
        <div class="titlelefttop">
          <label class="titlefont">일 일 판 매 일 보</label>
          <div class="titlelefttopline"></div>
        </div>
        
        <div class="titleleftbottom">
          <img src='https://cdn.bravo6.kr/judo/printday.png' alt='날짜'/>
          <label class="titledatefont">{0}</label>
        </div>
      </div>

      <div class="titleright">
        
      </div>
    </div>

    <div class="content">
      <div class="totalinfo">
        <table class="contenttable">
          <tbody>
            <tr>
              <td class="ctablebtt">
                <div class="ctotal"><label class="infofont">합계</label></div>
              </td>
              <td class="ctableb2">
                <div class="tcontent"><label class="innertextfont">{1}<br></label></div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>

      <div class="footer">
        <table class="contenttable2">
          <tbody>
            <tr>
              <td class="footerleft">
                <div class="tcontent2">
                  <label class="ttfont2">{2}</label>
                </div>
              </td>
              <td class="footercenter2">
                <div class="tcontent"></div>
              </td>
              <td class="footerright2">
                <div class="tcontent"></div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      
    </div>
  </div>
`;

const PrintTableRow = `
<tr {2}>
  <td class="ctable0">
    <div class="tcontent"></div>
  </td>
  <td class="ctablea">
    <div class="tcontent"><label class="innertextfont">{0}</label></div>
  </td>
  <td class="ctableb">
    <div class="tcontent"><label class="innertextfont">{1}</label></div>
  </td>
  <td class="ctablec">
    <div class="tcontent"></div>
  </td>
  <td class="ctabled">
    <div class="tcontent"></div>
  </td>
  <td class="ctablee">
    <div class="tcontent"></div>
  </td>
  <td class="ctablef">
    <div class="tcontent"></div>
  </td>
</tr>
`;

const PrintTable = `
<table class="contenttable">
        <tbody>
        <tr {2}>
        <td class="ctable0">
          <div class="tcontent"></div>
        </td>
        <td class="ctablea">
          <div class="tcontent"><label class="innertextfont">{0}</label></div>
        </td>
        <td class="ctableb">
          <div class="tcontent"><label class="innertextfont">{1}</label></div>
        </td>
        <td class="ctablec">
          <div class="tcontent"></div>
        </td>
        <td class="ctabled">
          <div class="tcontent"></div>
        </td>
        <td class="ctablee">
          <div class="tcontent"></div>
        </td>
        <td class="ctablef">
          <div class="tcontent"></div>
        </td>
      </tr>
        </tbody>
      </table>


`;

/*
const PrintTable = `
<table class="contenttable">
        <tbody>
        <tr {2}>
        <td class="ctablea">
          <div class="tcontent"><label class="tabletextfont">{0}</label></div>
        </td>
        <td class="ctableb">
          <div class="tcontent">{1}</div>
        </td>
        <td class="ctablec">
          <div class="tcontent"></div>
        </td>
        <td class="ctabled">
          <div class="tcontent"></div>
        </td>
        <td class="ctablee">
          <div class="tcontent"></div>
        </td>
        <td class="ctablef">
          <div class="tcontent"></div>
        </td>
      </tr>
        </tbody>
      </table>


`;*/
export {PrintStyle, PrintInfo, PrintInfoNoTotal, PrintInfoTotalOnly, PrintTableRow, PrintTable, INNER_FONT_SIZE};