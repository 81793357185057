import OrderAddItem from "./OrderAddItem";

//검색 아이템 목록창
const OrderSearchList = (props) =>
{
  let items = null;

  if (props.itemList !== undefined && props.itemList !== null && props.itemList.length > 0)
  {
    items = props.itemList.map(item => {
      return (<OrderAddItem key={item.idx} item = {item} itemMode = {1} addItem = {props.addItem}/>);
    });
  }

  return (
    <div className="oslcont flexcentercolstart"  onClick={(e) => e.stopPropagation()}>
      <div className="osllist flexcentercolstart" onClick={(e) => e.stopPropagation()}>
        {items}
      </div>
    </div>
  );
}

export default OrderSearchList;