import { GetImgUrl, GetFileUrl } from "../../URL/AppUrl";
import { GetChatTime, IsValidS, isImageFile } from "../../Util/Util";
import "./Chat.css";

function GetCurrentChatTime(chat)
{
  if (IsValidS(chat.time))
    return chat.time;

  return GetChatTime(chat.rdate); 
}

const getChattingLabels = (chatMsg, clsName) =>
{
  if (!IsValidS(chatMsg))
    return null;

  if (chatMsg.indexOf("<br>") >= 0)
  {
    const splits = chatMsg.split("<br>");

    if (IsValidS(splits))
    {
      let itemKey = 1;
      return splits.map(item => {
        return (<label key={itemKey++} className={clsName}>{item}</label>);
      });
    }
  }

  return (<label className={clsName}>{chatMsg}</label>);;
}

//채팅 내용
const ChatTalkItemmb = (props) =>
{
  let chatLabel = null;

  switch (props.item.ctype)
  {
    case 0: //운영자
    case 1: //유저
      //chatLabel=(<label className="chattalkfont mfontn">{props.item.chat}</label>);
      chatLabel=getChattingLabels(props.item.chat, "chattalkfont mfontn");
      break;
    case 3: //운영자첨부이미지
    case 4: //운영자첨부파일
    case 6: //유저첨부이미지
    case 7: //유저첨부파일
      chatLabel=(<label className="chataddfilefontmb btncur">{props.item.fname}</label>);
      break;
    default:
      break;
  }
  
  if (props.item.ctype === 0) //운영자 채팅
  {
    return (
      <div className="chattalkitemmy" id={"ti" + props.item.idx}>
        <label className="chatfonttime mfontn">{GetCurrentChatTime(props.item)}</label>
        <div className={props.searchedIdx === props.item.idx ? "chattalkitemballoonmysel flexcenterrow" : "chattalkitemballoonmy flexcenterrow"}>
          {chatLabel}
        </div>
      </div>
    );
  }
  else if (props.item.ctype === 1) //내채팅
  {
    return (
      <div className="chattalkitem" id={"ti" + props.item.idx}>
        <div className= {props.searchedIdx === props.item.idx ? "chattalkitemballoonsel flexcenterrow" : "chattalkitemballoon flexcenterrow"} >
          {chatLabel}
        </div>
        <label className="chatfonttime mfontn">{GetCurrentChatTime(props.item)}</label>
      </div>
    );
  }
  else if (props.item.ctype === 3) //운영자 첨부이미지
  {
    return (
      <div className="chattalkitemmy" id={"ti" + props.item.idx}>
        <img src={GetImgUrl("mbcai.png")} alt="첨부이미지"/>
        <label className="chatfonttime mfontn">이미지 첨부</label>
        <label className="chatfonttime mfontn">{GetCurrentChatTime(props.item)}</label>
        <div className={props.searchedIdx === props.item.idx ? "chattalkitemballoonmyselafmb flexcentercol btncur" : "chattalkitemballoonmyafmb flexcentercol btncur"}
          onClick={() => {props.handleDownload(true, props.item.chat, props.item.fname)}}
        >
          {isImageFile(props.item.chat) ? (<img src={GetFileUrl(props.item.chat)} alt="첨부파일" className="chatafimgsz"/>):null}
          {chatLabel}
        </div>
      </div>
    );
  }
  else if (props.item.ctype === 4) //운영자 첨부파일
  {
    return (
      <div className="chattalkitemmy" id={"ti" + props.item.idx}>
        <img src={GetImgUrl("mbcaf.png")} alt="첨부파일"/>
        <label className="chatfonttime mfontn">파일 첨부</label>
        <label className="chatfonttime mfontn">{GetCurrentChatTime(props.item)}</label>

        <div className={props.searchedIdx === props.item.idx ? "chattalkitemballoonmyselafmb flexcenterrow btncur" : "chattalkitemballoonmyafmb flexcenterrow btncur"}
          onClick={() => {props.handleDownload(false, props.item.chat, props.item.fname)}}
        >
          {chatLabel}
        </div>
      </div>
    );
  }
  else if (props.item.ctype === 6) //유저 첨부이미지
  {
    return (
      <div className="chattalkitem" id={"ti" + props.item.idx}>
        <div className="chattalkitemballoonafmb flexcentercol btncur"
          onClick={() => {props.handleDownload(true, props.item.chat, props.item.fname)}}
        >
          {isImageFile(props.item.chat) ? (<img src={GetFileUrl(props.item.chat)} alt="첨부파일" className="chatafimgsz"/>):null}
          {chatLabel}
        </div>
        <label className="chatfonttime mfontn">{GetCurrentChatTime(props.item)}</label>
        <img src={GetImgUrl("mbcai.png")} alt="첨부이미지"/>
        <label className="chatfonttime mfontn">이미지 첨부</label>
      </div>
    );
  }
  else if (props.item.ctype === 7) //유저 첨부파일
  {
    return (
      <div className="chattalkitem" id={"ti" + props.item.idx}>
        <div className="chattalkitemballoonafmb flexcenterrow btncur"
          onClick={() => {props.handleDownload(false, props.item.chat, props.item.fname)}}
        >
          {chatLabel}
        </div>
        <label className="chatfonttime mfontn">{GetCurrentChatTime(props.item)}</label>
        <img src={GetImgUrl("mbcaf.png")} alt="첨부파일"/>
        <label className="chatfonttime mfontn">파일 첨부</label>
      </div>
    );
  }
  else if (props.item.ctype === 5) //날짜표식
  {
    return (
      <div className="chattalkitemendmark flexcenterrow">
        <label className="mfontnb">{props.item.date}</label>
      </div>
    );
  }
  else if (props.item.ctype === 2) //대화 종료
  {
    return (
      <div className="chattalkitemendmark flexcenterrow">
        <div className="chattalkitemendmarkline"/>
        <label className="mfontn">상대방과의 대화가 종료되었습니다.</label>
        <div className="chattalkitemendmarkline"/>
      </div>
    );
  }

  return null;
}

export default ChatTalkItemmb;