import { LOG_ENABLE } from "../../URL/AppUrl";
import { IsValidS } from "../../Util/Util";
import ReportItemmb from "./ReportItemmb";

const ReportListmb = (props) =>
{
  let orders = null;

  if (LOG_ENABLE)
    console.log("ReportList : " + JSON.stringify(props.items));

  if (IsValidS(props.items))
  {
    orders = props.items.map(item => {
      return (<ReportItemmb key={item.idx} order = {item} delis={props.delis} handleDelivery={props.handleDelivery} 
        handleDeliveryMnuOrder={props.handleDeliveryMnuOrder} popt={props.popt}
        handleDeliMode={props.handleDeliMode}
        />);
    });
  }

  return (
    <div className = "rptlistcontmb">
      {orders}
    </div>
  );
}

export default ReportListmb;