import { GetBankName, IsValidS, GetFormattedPhone2 } from "../../Util/Util";

import './Staff.css';
import '../../App.css';
const getPersonalNumber = (item) =>
{
  if (IsValidS(item.pn) && item.pn !== "n")
  {
    return `${item.pn.substring(0, 6)}-*******`;
  }

  return "-";
}

const RecmListItem = (props) =>
{
  return (
    <tr className="recomrow">

      {/*이름*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{props.item.nm}</label>  
        </div>
      </td>

      {/*휴대폰*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{GetFormattedPhone2(props.item.hp)}</label>  
        </div>
      </td>

      {/*주민번호*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{getPersonalNumber(props.item.pn)}</label>  
        </div>
      </td>

      {/*은행*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{props.item.bk < 1 ? "-" : GetBankName(props.item.bk)}</label>  
        </div>
      </td>

      {/*계좌번호*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className= "fontn">{props.item.bn !== "n" ? props.item.bn : "-"}</label>
        </div>
      </td>

      {/*추천인코드*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{props.item.cc}</label>  
        </div>
      </td>

      {/*추천인 목록관리*/}
      <td className="recomdcell">
        <div className="flexcenterrow recomlitem spaceeven">
          <button className="recmbtn1 fontnwb bgcolor1 btncur" onClick={() => props.handleEdit(0, props.item)}>일괄 변경</button>
          <button className="recmbtn1 fontnwb bgcolor9 btncur" onClick={() => props.handleEdit(1, props.item)}>개별 변경</button>
        </div>
      </td>
    </tr>
  );
}

export default RecmListItem;