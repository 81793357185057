import { IsValidS } from "../../Util/Util";

function GetItemInfo(item, printOption)
{
  if (IsValidS(item.alias) && item.alias !== "n")
  {
    if (printOption === 0 || printOption === 1) //단축명 있거나 또는 항상 상품코드 및 용량 표시안함
    {
      if (item.bcnt > 0)
      {
        if (item.ecnt > 0)
          return `◆${item.alias} ${item.bcnt}박스, ${item.ecnt}병`;
        else
          return `◆${item.alias} ${item.bcnt}박스`;
      }
      else if (item.ecnt > 0)
      {
        return `◆${item.alias} ${item.ecnt}병`;
      }
    }
    else if (printOption === 3) //단축명 있거나 또는 항상 상품코드 및 용량 표시안함
    {
      if (item.bcnt > 0)
      {
        if (item.ecnt > 0)
          return `◆${item.alias} ${item.ivol}ml ${item.bcnt}박스, ${item.ecnt}병`;
        else
          return `◆${item.alias} ${item.ivol}ml ${item.bcnt}박스`;
      }
      else if (item.ecnt > 0)
      {
        return `◆${item.alias} ${item.ivol}ml ${item.ecnt}병`;
      }
    }
    else
    {
      if (item.bcnt > 0)
      {
        if (item.ecnt > 0)
          return `◆${item.alias} ${item.icode} ${item.ivol}ml ${item.bcnt}박스, ${item.ecnt}병`;
        else
          return `◆${item.alias} ${item.icode} ${item.ivol}ml ${item.bcnt}박스`;
      }
      else if (item.ecnt > 0)
      {
        return `◆${item.alias} ${item.icode} ${item.ivol}ml ${item.ecnt}병`;
      }
    }
    
    return "";
  }

  if (printOption === 1)
  {
    if (item.bcnt > 0)
    {
      if (item.ecnt > 0)
        return `◆${item.iname} ${item.bcnt}박스, ${item.ecnt}병`;
      else
        return `◆${item.iname} ${item.bcnt}박스`;
    }
    else if (item.ecnt > 0)
    {
      return `◆${item.iname} ${item.ecnt}병`;
    }
  }
  else if (printOption === 3)
  {
    if (item.bcnt > 0)
    {
      if (item.ecnt > 0)
        return `◆${item.iname} ${item.ivol}ml ${item.bcnt}박스, ${item.ecnt}병`;
      else
        return `◆${item.iname} ${item.ivol}ml ${item.bcnt}박스`;
    }
    else if (item.ecnt > 0)
    {
      return `◆${item.iname} ${item.ivol}ml ${item.ecnt}병`;
    }
  }
  else
  {
    if (item.bcnt > 0)
    {
      if (item.ecnt > 0)
        return `◆${item.iname} ${item.icode} ${item.ivol}ml ${item.bcnt}박스, ${item.ecnt}병`;
      else
        return `◆${item.iname} ${item.icode} ${item.ivol}ml ${item.bcnt}박스`;
    }
    else if (item.ecnt > 0)
    {
      return `◆${item.iname} ${item.icode} ${item.ivol}ml ${item.ecnt}병`;
    }
  }
  
  return "";
}

function GetItemInfoForPrint(item, printOption)
{
  if (IsValidS(item.alias) && item.alias !== "n")
  {
    if (printOption === 0 || printOption === 1)
    {
      if (item.bcnt > 0)
      {
        if (item.ecnt > 0)
          return `◆${item.alias} ${item.bcnt}박스,${item.ecnt}병`;  
        else
          return `◆${item.alias} ${item.bcnt}박스`;  
      }
      else if (item.ecnt > 0)
      {
        return `◆${item.alias} ${item.ecnt}병`;  
      }
    }
    else if (printOption === 3)
    {
      if (item.bcnt > 0)
      {
        if (item.ecnt > 0)
          return `◆${item.alias} ${item.ivol}ml ${item.bcnt}박스,${item.ecnt}병`;  
        else
          return `◆${item.alias} ${item.ivol}ml ${item.bcnt}박스`;  
      }
      else if (item.ecnt > 0)
      {
        return `◆${item.alias} ${item.ivol}ml ${item.ecnt}병`;  
      }
    }
    else
    {
      if (item.bcnt > 0)
      {
        if (item.ecnt > 0)
          return `◆${item.alias} ${item.icode} ${item.ivol}ml ${item.bcnt}박스,${item.ecnt}병`;  
        else
          return `◆${item.alias} ${item.icode} ${item.ivol}ml ${item.bcnt}박스`;  
      }
      else if (item.ecnt > 0)
      {
        return `◆${item.alias} ${item.icode} ${item.ivol}ml ${item.ecnt}병`;  
      }
    }
    
    return "";
  }

  if (printOption === 1)
  {
    if (item.bcnt > 0)
    {
      if (item.ecnt > 0)
        return `◆${item.iname} ${item.bcnt}박스,${item.ecnt}병`;  
      else
        return `◆${item.iname} ${item.bcnt}박스`;  
    }
    else if (item.ecnt > 0)
    {
      return `◆${item.iname} ${item.ecnt}병`;  
    }
  }
  else if (printOption === 3)
  {
    if (item.bcnt > 0)
    {
      if (item.ecnt > 0)
        return `◆${item.iname} ${item.ivol}ml ${item.bcnt}박스,${item.ecnt}병`;  
      else
        return `◆${item.iname} ${item.ivol}ml ${item.bcnt}박스`;  
    }
    else if (item.ecnt > 0)
    {
      return `◆${item.iname} ${item.ivol}ml ${item.ecnt}병`;  
    }
  }
  else
  {
    if (item.bcnt > 0)
    {
      if (item.ecnt > 0)
        return `◆${item.iname} ${item.icode} ${item.ivol}ml ${item.bcnt}박스,${item.ecnt}병`;  
      else
        return `◆${item.iname} ${item.icode} ${item.ivol}ml ${item.bcnt}박스`;  
    }
    else if (item.ecnt > 0)
    {
      return `◆${item.iname} ${item.icode} ${item.ivol}ml ${item.ecnt}병`;  
    }
  }  
  
  return "";
}

//판매일보에 출력할 아이템 목록 구하기
function GetItemList(order, printOption)
{
  /*if (LOG_ENABLE)
    console.log("order item : " + JSON.stringify(order));*/

  if (!IsValidS(order.items))
    return ["상품이 비어 있습니다."];

  let items = "";
  let textLength = 0;

  for (let i = 0;i < order.items.length; ++i)
  {
    let itemInfo = GetItemInfo(order.items[i], printOption);

    textLength += itemInfo.length;

    if (textLength > 78)
    {
      textLength = itemInfo.length;

      items += "\n" + itemInfo;
    }
    else
    {
      items += itemInfo;
    }

    if (i !== (order.items.length - 1))
    {
      items +=" ";
    }
  }

  return items.split("\n");
}

//판매일보에 출력할 아이템 목록 구하기
function GetItemListmb(order, printOption)
{
  /*if (LOG_ENABLE)
    console.log("order item : " + JSON.stringify(order));*/

  if (!IsValidS(order.items))
    return ["상품이 비어 있습니다."];

  let items = "";

  for (let i = 0;i < order.items.length; ++i)
  {
    let itemInfo = GetItemInfo(order.items[i], printOption);

    items += itemInfo;

    if (i !== (order.items.length - 1))
    {
      items +="\n";
    }
  }

  return items.split("\n");
}

//판매일보 프린트양식용 아이템 목록
function ItemListToTag(itemList, printOption)
{
  if (!IsValidS(itemList))
    return "상품이 비어 있습니다.";

  let items = "";
  //let items = '<label class="tabletextfont">';
  
  let textLength = 0;

  for (let i = 0;i < itemList.length; ++i)
  {
    let itemInfo = GetItemInfoForPrint(itemList[i], printOption);

    textLength += itemInfo.length;

    if (textLength > 78)
    {
      textLength = itemInfo.length;

      //items += '</label><br><label class="tabletextfont">' + itemInfo;
      items += '<br>' + itemInfo;
    }
    else
    {
      items += itemInfo;
    }

    if (i !== (itemList.length - 1))
    {
      items +=" ";
    }
  }

  //items += '</label>';

  return items;
}

//판매일보 프린트양식용 아이템 목록
function ItemListToTagSpecial(itemList, printOption, itemKind, title, firstInfo)
{
  if (!IsValidS(itemList))
    return null;

  let items = firstInfo ? `${title}<br/>` : `<br/><br/>${title}<br/>`;
  //let items = '<label class="tabletextfont">';
  
  let textLength = 0;
  let itemCount = 0;
  for (let i = 0;i < itemList.length; ++i)
  {
    if (itemKind === -1)
    {
      let cont = false;

      switch(itemList[i].iid)
      {
        case 1: //소주
        case 2: //맥주
        case 3: //수입맥주
        case 4: //생맥주
          cont = true;
          break;
        default:
          break;
      }

      if (cont)
        continue;
    }
    else if (itemList[i].iid !== itemKind)
      continue;

    ++itemCount;

    let itemInfo = GetItemInfoForPrint(itemList[i], printOption);

    textLength += itemInfo.length;

    if (textLength > 78)
    {
      textLength = itemInfo.length;

      //items += '</label><br><label class="tabletextfont">' + itemInfo;
      items += '<br>' + itemInfo;
    }
    else
    {
      items += itemInfo;
    }

    if (i !== (itemList.length - 1))
    {
      items +=" ";
    }
  }

  if (itemCount < 1)
    return null;
  //items += '</label>';

  return items;
}

export {GetItemList, GetItemListmb, ItemListToTag, ItemListToTagSpecial};