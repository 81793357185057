import {  useState } from "react";
import { IsValidS } from "../../Util/Util";

function GetItemVolume(item)
{
  if (IsValidS(item.ivol))
  {
    if (item.ivol.indexOf('g') >= 0)
      return item.ivol;

    return `${item.ivol}ML`;
  }

  return "";
}

function GetItemBottleCount(item)
{
  if (item.unit === 3 || item.unit === 4)
  {
    return `${item.ecnt}개`;
  }

  return `${item.ecnt}병`;
}

//음성 주문용 아이템
const OrderItemvo = (props) =>
{
  const [editMode, setEditMode] = useState(false);
  const [boxCount, setBoxCount] = useState(props.item.bcnt);
  const [bottleCount, setBottleCount] = useState(props.item.ecnt);

  return (
    <tr className="emptabledata">
      <td className="ordercellitemcode">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.item.icode}</label>
        </div>
      </td>
      
      <td className="ordercellitemnamevo">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.item.iname}</label>
        </div>
      </td>

      <td className="ordercellitemvol">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{GetItemVolume(props.item)}</label>
        </div>
      </td>

      <td className="ordercellitemcount">
        <div className="emptablecellin flexcentercol">
          {editMode && (props.item.unit === 0 || props.item.unit === 2 || props.item.unit === 3) ? 
            (
              <div className="flexcenterrowstart">
                <input type="number" onChange={(e) => setBoxCount(parseInt(e.target.value))} value = {boxCount} className="orderitemvoinp" placeholder="박스개수입력" autoFocus/>
                {/*<label className="fontn">박스</label>  */}
              </div>
            ):
            (
              <label className="fontn">{props.item.bcnt > 0 ? `${props.item.bcnt}박스` : ''}</label>
            )
          }

          {editMode && (props.item.unit === 1 || props.item.unit === 2 || props.item.unit === 3 || props.item.unit === 4) ? 
            (
              <div className="flexcenterrowstart">
                <input type="number" onChange={(e) => setBottleCount(parseInt(e.target.value))} value = {bottleCount} className="orderitemvoinp" placeholder="수정개수입력"/>
                {/*<label className="fontn">병</label>  */}
              </div>
            ):
            (
              <label className="fontn">{props.item.ecnt > 0 ? GetItemBottleCount(props.item) : ''}</label>
            )
          }

        </div>
      </td>

      <td className="ordercellitemedit">
        <div className="emptablecellin flexcenterrow">

          {
            editMode ? (
              <button className="bgcolor1 orderitemvbtn fontnwb" onClick={() => {
                setEditMode(false);
                props.handleEditItem(props.orderIdx, props.item.idx, boxCount, bottleCount);
              }}>
                완료
              </button>
            ):
            (
              <button className="bgcolor1 orderitemvbtn fontnwb" 
                onClick={() => {
                  setEditMode(true);
                  setBoxCount(props.item.bcnt);
                  setBottleCount(props.item.ecnt);
                }}>
                수정
              </button>
            )
          }
        </div>
      </td>

      <td className="ordercellitemedit">
        <div className="emptablecellin flexcenterrow">
        {
          editMode ? (
            <button className="bgcolor2 orderitemvbtn fontnwb" onClick={() => setEditMode(false)}>
              취소
            </button>
          ):
          (
            <button className="bgcolor2 orderitemvbtn fontnwb" onClick={() => props.handleDeleteItem(props.orderIdx, props.item.idx)}>
              삭제
            </button>
          )
        } 
        </div>
      </td>
    </tr>
  );
};

export default OrderItemvo;