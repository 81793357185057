import React, { Fragment } from 'react';
import './Term.css';

class TermApp extends React.Component
{
  termInfo = `이용약관
  제 1 조 (목적) 이 이용약관은 주식회사 브라보식스(이하 ““N-돌핀 앱””)에서 제공하는 인터넷 서비스 (이하 '서비스')의 가입조건 및 이용에 관한 제반 사항과 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.
  
  제 2 조 (약관의 효력 및​ 변경) ① 이 약관은 “N-돌핀 앱”에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이 발생합니다. ② “N-돌핀 앱”은 필요하다고 인정되는 경우에 이 약관을 개정할 수 있으며, 개정된 내용은 제1항과 같은 방법으로 공지한 때로부터 그 효력을 발생합니다.
  
  제 3 조 (약관외 준칙) ① 이 약관은 “N-돌핀 앱”이 제공하는 서비스에 관한 이용안내와 함께 적용됩니다.② 이 약관에 명시되지 아니한 사항은 관계법령의 규정이 적용됩니다.
  
  제 4 조 (용어의 정의) ① "이용자"라 함은 “N-돌핀 앱”에 접속하여 이 약관에 따라 “N-돌핀 앱”가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.② "회원"이라 함은 서비스를 이용하기 위하여 “N-돌핀 앱”에 개인정보를 제공하여 아이디(ID)와 비밀번호를 부여 받은 자를 말합니다.③ "회원 아이디(ID)"라 함은 회원의 식별 및 서비스 이용을 위하여 자신이 선정한 문자 및 숫자의 조합을 말합니다. ④ "비밀번호(패스워드)"라 함은 회원이 자신의 비밀보호를 위하여 선정한 문자, 숫자 및 특수문자의 조합을 말합니다.
  
  제 5 조 (회원가입) 서비스를 이용하고자 하는 고객은 “N-돌핀 앱”에서 정한 회원가입양식에 개인정보를 기재하여 가입을 하여야 합니다.
  
  제 6 조 (회원 ID 부여 및 정보의 변경) ① “N-돌핀 앱”은 이용고객에 대하여 약관에 정하는 바에 따라 자신이 선정한 회원 ID를 부여합니다.② 회원 ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경 하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다. ③ 회원은 이용신청시 기재한 회원정보가 변경되었을 경우 온라인으로 직접 수정해야 하며, 관리자에게 수정을 요구할 수 있습니다.
  
  제 7 조 (계약 해지) 회원이 이용계약을 해지하고자 하는 때에는 주식회사 브라보식스의 홈페이지 [마이페이지]->[회원정보관리]->[회원탈퇴] 메뉴를 이용해 직접 해지해야 합니다. 
  
  제 8 조 (서비스 이용) ① 서비스 이용은 “N-돌핀 앱”의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 제공을 원칙으로 합니다. ② 단, “N-돌핀 앱”은 시스템 정기점검, 증설 및 교체를 위해 “N-돌핀 앱”가 정한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시중단은 “N-돌핀 앱” 홈페이지를 통해 사전에 공지합니다. ③ 회원은 “N-돌핀 앱”로부터 검색한 자료의 지적재산권에 관한 사항과 데이터베이스 제작자가 요구하는 사항을 준수하여야 합니다.
  
  제 9 조 (서비스 제공의 제한 및 중단) ① “N-돌핀 앱”은 다음 각 호에 해당하는 경우 서비스 제공의 일부 또는 전부를 제한하거나 중지할 수 있습니다. 1. 서비스용 설비의 보수, 정기점검 또는 공사로 인한 부득이한 경우 2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우 3. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우 4. 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 경우 5. 제11조 회원의 의무를 현저히 위반했을 경우 ② “N-돌핀 앱”은 제1항의 규정에 의하여 서비스 제공의 일부 또는 전부를 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 지체없이 공지사항이나 이메일 등을 통하여 회원에게 알립니다.
  
  제 10 조 (주식회사 브라보식스의 의무) ① “N-돌핀 앱”은 이용고객이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록 하여야 합니다.② “N-돌핀 앱”은 개인정보 보호를 위해 보안시스템을 구축하며 개인정보 보호정책을 공시하고 준수합니다.③ “N-돌핀 앱”은 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 회원에게 그 사유와 처리일정을 통보하여야 합니다.
  
  제 11 조 (회원의 의무) ① 이용자는 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.② “N-돌핀 앱”가 관계법령 및 개인정보 보호정책에 의거하여 그 책임을 지는 경우를 제외하고 회원에게 부여된 ID의 비밀번호 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.③ 회원은 “N-돌핀 앱” 및 제 3자의 지적 재산권을 침해해서는 안 됩니다.
  제 12 조 (손해배상) ”N-돌핀 앱”은 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 “N-돌핀 앱”가 고의 또는 과실로 인한 손해발생을 제외하고는 이에 대하여 책임을 부담하지 아니합니다. 
  
  제 13 조 (관할 법원) 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 민사 소송법상의 관할 법원에 제기합니다. [부 칙] 1. (시행일) 이 약관은 2021년 10월 10일부터 시행합니다.
  
  `;
  render()
  {
    const term = this.termInfo.split('\n').map(element => {
      return <Fragment>{element}<br/></Fragment>
    });

    return (
      <div className="termcont flexcentercolstart">
        <div className="termbody flexcentercolstart">
          <label className="termlabel">
            {term}
          </label>
        </div>
      </div>
    );
  }
}

export default TermApp;