import { GetChatTime, IsValidS } from "../../Util/Util";

/*function GetMsgOnly(item)
{
  if (!IsValidS(item.chat))
    return "";

  if (item.chat.length < 13)
    return item.chat;

  return item.chat.substring(0,13) + "...";
}

function GetAttachedFile(item)
{
  if (!IsValidS(item.fname))
    return "";

  if (item.fname.length < 13)
    return item.fname;

  return item.fname.substring(0,13) + "...";
}*/

function GetChatMsg(item)
{
  /*switch (item.ctype)
  {
    case 0:
    case 1:
      return GetMsgOnly(item);
    case 3: //운영자첨부이미지
    case 4: //운영자첨부파일
    case 6: //유저첨부이미지
    case 7: //유저첨부파일
      return GetAttachedFile(item);
  }

  return "";*/
  if (!IsValidS(item.chat))
    return "";

  if (item.chat === "n")
    return "";
    
  if (item.chat.length < 13)
    return item.chat;

  return item.chat.substring(0,13) + "...";
}

//채팅창 업체
const ChatCompItem = (props) =>
{
  if (props.selectedIdx === props.item.idx)
  {
    return (
      <div className="chatcompitemsel flexcentercol btncur" onClick={(e)=>props.handleCompanyClick(props.item.idx, props.item.uidx)}>
        <div className="chatcompitemtop">
          <label className="fontlargewb btncur">{props.item.cname}</label>
        </div>

        <div className="chatcompitembottom flexcenterrow">
          <div className="chatcompitembottomleft flexcenterrowstart">
            <label className="fontmidw btncur">{GetChatMsg(props.item)}</label>
          </div>

          <div className="chatcompitembottomright flexcenterrowend">
            <label className="fontmidw btncur">{GetChatTime(props.item.rdate)}</label>
          </div>
        </div>

      </div>
    );
  }
  else
  {
    return (
      <div className="chatcompitem flexcentercol btncur" onClick={(e)=>props.handleCompanyClick(props.item.idx, props.item.uidx)}>
        <div className="chatcompitemtop">
          <label className="fontlargeb btncur">{props.item.cname}</label>

          {
            props.item.rd === 0 ? (<div className="newchatmark"></div>) : null
          }
          
        </div>

        <div className="chatcompitembottom flexcenterrow">
          <div className="chatcompitembottomleft flexcenterrowstart">
            <label className="fontmid btncur">{GetChatMsg(props.item)}</label>
          </div>

          <div className="chatcompitembottomright flexcenterrowend">
            <label className="fontmid btncur">{GetChatTime(props.item.rdate)}</label>
          </div>
        </div>

      </div>
    );
  }  
}

export default ChatCompItem;