import { mobileOn } from "../../URL/AppUrl";
import ChatCompItem from "./ChatCompItem";
import ChatCompItemmb from "./ChatCompItemmb";
import ChatSearchItem from "./ChatSearchItem";
import ChatSearchItemmb from "./ChatSearchItemmb";

//채팅창 좌측 업체 목록
const ChatCompany = (props) =>
{
  let companyList = null;

  if (mobileOn)
  {
    if (props.listMode === 0)//일반 업체 목록
    {
      if (props.list !== undefined && props.list !== null && props.list.length > 0)
      {
        companyList = props.list.map(item => {
          return (<ChatCompItemmb key={item.idx} item={item} selectedIdx = {props.selectedIdx} handleCompanyClick = {props.handleCompanyClick}/>);
        });
      }
    }
    else //업체 검색 목록
    {
      if (props.list !== undefined && props.list !== null && props.list.length > 0)
      {
        companyList = props.list.map(item => {
          return (<ChatSearchItemmb key={item.aidx} item={item} handleStartTalk = {props.handleStartTalk}/>);
        });
      }
    }
    
    if (companyList === null)
    {
      return (
        <div className="chatcomplistmb">
          <div className="chatcmopemp flexcenterrow">
            <label className="mfontnb">{props.listMode === 0?"아직 추가된 대화목록이 없습니다.":"검색된 업체가 없습니다."}</label>
          </div>
        </div>
      ); 
    }
  
    return (
      <div className="chatcomplistmb" onScroll={props.handleScroll}>
        {companyList}
      </div>
    );
  }
  else
  {
    if (props.listMode === 0)//일반 업체 목록
    {
      if (props.list !== undefined && props.list !== null && props.list.length > 0)
      {
        companyList = props.list.map(item => {
          return (<ChatCompItem key={item.idx} item={item} selectedIdx = {props.selectedIdx} handleCompanyClick = {props.handleCompanyClick}/>);
        });
      }
    }
    else //업체 검색 목록
    {
      if (props.list !== undefined && props.list !== null && props.list.length > 0)
      {
        companyList = props.list.map(item => {
          return (<ChatSearchItem key={item.aidx} item={item} handleStartTalk = {props.handleStartTalk}/>);
        });
      }
    }
    
    if (companyList === null)
    {
      return (
        <div className="chatcomplist">
          <div className="chatcmopemp flexcenterrow">
            <label className="fontlargeb">{props.listMode === 0?"아직 추가된 대화목록이 없습니다.":"검색된 업체가 없습니다."}</label>
          </div>
        </div>
      ); 
    }
  
    return (
      <div className="chatcomplist" onScroll={props.handleScroll}>
        {companyList}
      </div>
    );
  }  
}

export default ChatCompany;