import { useState } from "react";
import { GetImgUrl } from "../../URL/AppUrl";
import "../Product/Product.css";

//음성주문 아이템 수정
const OrderVoiceItemEdit = (props) =>
{
  const [boxCount, setBoxCount] = useState(props.item.bcnt);
  const [bottleCount, setBottleCount] = useState(props.item.ecnt);

  return(
    <div className="pddetcontmb flexcenterrow">
      <div className="odvoedititemmb flexcentercolstart">
        <div className="pddettitle flexcenterrow">

          <div className="dcell1">
          </div>

          <div className="dcell8 flexcenterrow">
            <label className="mfontnb">주문 정보 수정</label>  
          </div>

          <div className="dcell1">
            <img src={GetImgUrl("mbvox.png")} className="btncur" alt="닫기" onClick={() => props.handleCloseDetail()}/>
          </div>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">상품번호</label>
          <label className="mfontn">{props.item.icode}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">상품명</label>
          <label className="mfontn">{props.item.iname}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">용량</label>
          <label className="mfontn">{props.item.ivol}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">주문개수</label>

          <div className="flexcentercol odvoitemcntcnt">
            {
              (props.item.unit === 0 || props.item.unit === 2 || props.item.unit === 3) ? 
              (<div className="flexcenterrowstart odvoitemcntcnt2">
                <input type="number" className="mfontn odvoitemcntmb" value={boxCount.toString()}
                  onChange={(e) => {
                    setBoxCount(parseInt(e.target.value))
                  }}
                />
                <label className="mfontn">박스</label>
              </div>) : null
            }
            
            {
              (props.item.unit === 1 || props.item.unit === 2 || props.item.unit === 3 || props.item.unit === 4) ? 
              (<div className="flexcenterrowstart odvoitemcntcnt2">
                <input type="number" className="mfontn odvoitemcntmb" value={bottleCount.toString()}
                  onChange={(e) => {
                    setBottleCount(parseInt(e.target.value))
                  }}
                />
                <label className="mfontn">{props.item.unit === 3 || props.item.unit === 4 ? "개" : "병"}</label>
              </div>) : null
            }
          </div>
        </div>

        <div className="flexcenterrow modetbtnareamb">
          <button className="bgcolor2 pddetbtnmb mfontnwb btncur" onClick={() => props.handleDeleteItem(props.orderIdx, props.item.idx)}>해당 상품 삭제</button>
          <button className="bgcolor1 pddetbtnmb mfontnwb btncur" onClick={() => props.handleEditItem(props.orderIdx, props.item.idx, boxCount, bottleCount)}>주문 정보 수정</button>

          
        </div>
        
      </div>
    </div>
  );
}

export default OrderVoiceItemEdit;