import React from 'react';
import './Staff.css';
import '../../App.css';
import { IsValidS } from '../../Util/Util';
import { LOG_ENABLE, mobileOn, GetImgUrl } from '../../URL/AppUrl';
import { observer } from 'mobx-react';
import PageList from '../PageList';
import { RecmStoreInstance } from '../../Stores/RecmStore';
import RecmListItem from './RecmListItem';
import RecmTrans from './RecmTrans';
import Loading from '../Loading/Loading';
import RecmTransAll from './RecmTransAll';

//신규 추천인 시스템 추천인 목록
//특정 주류 도매상 소속의 추천인 목록을 조회함
class RecmList extends React.Component {
  state = {
    loading: false,
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 1, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 1, //전체 페이지 개수

    showUserList: false, //가입자 목록창
    showTransList: false, //일괄변경창
  };

  visiblePageNum = 10;
  editItem = null;
  transCount = 0;

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowUserList = (v) =>
  {
    this.setState((prevState) => ({...prevState, showUserList : v}));
  }

  setShowTransList = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTransList : v}));
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("RecmList.componentDidMount");
    
    if (mobileOn)
      this.visiblePageNum = 3;

    RecmStoreInstance.clearAll();

    this.loadAllList();
  }

  componentWillUnmount = () =>
  {
  }

  calcPageInfo = () =>
  {
    let currentCount = RecmStoreInstance.totalRecmCount;

    let pageCount = Math.floor(currentCount / 20);

    if ((currentCount % 20) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.visiblePageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  //먼저 모든 소속 추천인들의 간략한 정보를 읽는다.
  loadAllList = () =>
  {
    this.setLoading(true);
    RecmStoreInstance.loadRCListAll(this.onLoadAllList);
  }

  onLoadAllList = (resultCode, readCount) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert(`추천인 목록 읽기 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.`);
      return;
    }

    //더이상 읽을 추천인이 없다면
    if (readCount < 1)
    {
      this.loadList(1);
      return;
    }

    //다음 추천인을 읽는다.
    RecmStoreInstance.loadRCListAll(this.onLoadAllList);
  }

  loadList = (pageNum) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    RecmStoreInstance.loadRCList((pageNum - 1) * 20, this.onLoadRecommList);
  }

  onLoadRecommList = (resultCode, isFirst) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('추천인 목록 조회중 오류가 발생했습니다.');
      return;
    }

    if (isFirst)
    {
      this.calcPageInfo();
    }
  }

  //하단 페이지 변경 버튼 눌릴경우
  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4
  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = this.visiblePageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadList(1)
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - this.visiblePageNum;
      let endPage = this.state.endPage - this.visiblePageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadList(startPage)
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.visiblePageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadList(startPage)
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.visiblePageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadList(endPage)
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadList(pageNum)
    }
  }

  //추천인 편집
  handleEditRecm = (mode, item) =>
  {
    if (mode === 0) //일괄변경
    {
      if (this.state.loading)
        return;

      this.editItem = item;

      //해당 추천인의 가입자수를 구한다.
      this.setLoading(true);
      RecmStoreInstance.loadUserList(1, item.idx, null, this.onCountResult);
    }
    else if (mode === 1) //개별 변경
    {
      this.editItem = item;
      this.setShowUserList(true);
    }
  }

  onCountResult = (resultCode, count) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`추천인 정보 읽기 중 오류가 발생헀습니다.\n잠시 후 이용해 주세요.`);
      return;
    }

    this.transCount = count;
    this.setShowTransList(true);
  }

  //개별 변경창 닫기
  handleCloseUserList = () =>
  {
    this.setShowUserList(false);
  }

  //일괄 변경창 닫기
  handleCloseTransList = () =>
  {
    this.setShowTransList(false);
  }

  render()
  {
    let recomList = null;

    const temp = RecmStoreInstance.recmList;

    if (IsValidS(temp))
    {
      recomList = temp.map(item => {
        return (<RecmListItem key={item.idx} item={item} handleEdit={this.handleEditRecm}/>);
      });
    }

    return (
      <div className='recomlistpopbg flexcenterrow'>
        {
          this.state.showUserList ? (
            <RecmTrans handleCloseUserList={this.handleCloseUserList} item={this.editItem}/>
          ) : null
        }

        {
          this.state.showTransList ? (
            <RecmTransAll item={this.editItem} transCount={this.transCount} handleClose={this.handleCloseTransList}/>
          ) : null
        }

        {
          this.state.loading ? (<Loading/>) : null
        }

        <div className='recomlistpop flexcentercol'>
        
          <div className='cntfullh flexcenterrow marginbottom10'>
            <div className='dcell1'></div>
            <div className='dcell8 flexcenterrow'>
              <label className='fontlargeb'>추천인 코드 목록</label>
            </div>
            <div className='dcell1 flexcenterrow'>
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur' onClick={() => this.props.handleCloseRecommList()}/>
            </div>
          </div>

          <div className = "recommtablecont">
            <table className='recommtable'>
              <thead>
                <tr>
                  <th className="recommcell">
                    <label className="fontnw">이름</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">휴대폰</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">주민번호</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">은행</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">계좌번호</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">추천인코드</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">추천인 목록 관리</label>
                  </th>

                </tr>
              </thead>
              <tbody>
                {recomList}
              </tbody>
            </table>
          </div>

          {
            this.state.pageCount > 1 ? <PageList handlePage={this.handlePage} 
              first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
              next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
              currentPage={this.state.currentPage} 
              startPage={this.state.startPage} endPage={this.state.endPage}/> : 
            null
          }
        </div>
      </div>
    );
  }
}

export default observer(RecmList);