import { useState } from "react";
import { ProductStoreInstance } from "../../Stores/ProductStore";
import { GetAppImgUrl, GetImgUrl, LOG_ENABLE } from "../../URL/AppUrl";
import { IsValidS, IsValidV } from "../../Util/Util";
import "./ProductInput.css";

const PDEditBtn = (props) =>
{
  return (
    <div className="pdeditbtncontmb flexcenterrow">
      <button className="bgcolor4 fontnwb pdinpeditbtnlmb" onClick={() => props.handleEdit(false)}>
        수정 취소하기
      </button>

      <button className="bgcolor1 fontnwb pdinpeditbtnrmb" onClick={() => props.handleEdit(true)}>
        수정하기
      </button>
    </div>
  );
};

const PDAddBtn = (props) =>
{
  return (
    <div className="flexcenterrow pdeditbtncontmb">
      <button className="bgcolor3 fontnwb pdinpeditbtnmb" onClick={() => props.handleAdd()}>
        등록하기
      </button>
    </div>
  );
};

//제품 추가 또는 수정시 입력을 받는창
const ProductInputmb = (props) =>
{
  const [iid, setIid] = useState(IsValidV(props.iid) ? props.iid : 1);
  const [icode, setIcode] = useState(props.icode);
  const [iname, setIname] = useState(props.iname);
  const [alias, setAlias] = useState(props.alias);
  const [ivol, setIvol] = useState(props.ivol);
  const [iunit, setIunit] = useState(props.iunit); //0 : 박스, 1 : 병, 2 : 박스/병
  const [iuses, setIuses] = useState(IsValidV(props.iuses) ? props.iuses : "0"); //0 : 유흥, 1 : 일반, 2 : 공용
  const [ifreq, setIfreq] = useState(props.ifreq);
  const [icnt, setIcnt] = useState(props.icnt);
  const [price,setPrice] = useState(props.price);
  const [imgFile, setImgFile] = useState(null);

  const categoryList = ProductStoreInstance.productCategory;

  let category = null;

  if (categoryList != null && categoryList.length > 0)
  {
    category = categoryList.map(item => {
      return (<option key={item.idx} value={item.iid}>{item.cname}</option>);
    });
  }

  return (
    <div className="pdinputcontmb flexcentercolstart">

      {/* 입력창 영역 */}
      <div className="pdinputwinmb flexcentercol">
        
        {/* 이미지영역 */}
        <div className="pdinputimgcontmb flexcenterrow">
          <img src={IsValidS(props.img) ? GetAppImgUrl(props.img) : GetImgUrl("itemimage.png")} className="pdinputimg" id="previmg" alt="상품이미지"/>
        </div>

        <label className="mfontn margintopten">120 X 120 크기의 PNG 또는 JPG포맷의 이미지 파일을 선택하세요.</label>

        <div className="bgcolor3 pdinputimgselectmb flexcenterrow">
          <label htmlFor="fileInput" className="pdinputimgselfontmb">사진등록</label>
          <input type="file" id="fileInput" className="pdinputfilestyle" accept="image/png, image/jpeg"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              if (LOG_ENABLE)
                console.log(e.target.files[0]);

              setImgFile(e.target.files[0]);

              const preview = document.getElementById("previmg");
              
              const reader = new FileReader();
              reader.onloadend = (evt) => {
                preview.src = reader.result;
              };

              reader.readAsDataURL(e.target.files[0]);
          }}/>
        </div>

        <div className="pdinphlinemb"></div>

        {/* 판매분류 */}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">판매분류</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <select className="pdinputselectmb mfontnb" value={iid.toString()}
              onChange={(e) => {
                if (LOG_ENABLE)
                  console.log(e.target.value);

                  setIid(parseInt(e.target.value));
              }}
            >
              {category}
            </select>
          </div>
        </div>

        <div className="pdinphlinemb"></div>
        {/* 품목코드*/}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">품목코드</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <input type="text" onChange={(e) => setIcode(e.target.value)} value = {icode} className="pdinputeditinpmb mfontn" placeholder="품목코드를 입력하세요."  maxLength="15"/>
          </div>
        </div>

        <div className="pdinphlinemb"></div>
        {/* 품목명*/}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">품목명</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <input type="text" onChange={(e) => setIname(e.target.value)} value = {iname} className="pdinputeditinpmb mfontn"  placeholder="품목명을 입력하세요."  maxLength="40"/>
          </div>
        </div>

        <div className="pdinphlinemb"></div>
        {/*단축명*/}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">단축명</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <input type="text" onChange={(e) => setAlias(e.target.value)} value = {alias} className="pdinputeditinpmb mfontn"  placeholder="단축명은 일일판매일보에 사용됩니다."  maxLength="40"/>
          </div>
        </div>

        <div className="pdinphlinemb"></div>
        {/* 규격*/}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">{"규격(용량)"}</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <input type="text" onChange={(e) => setIvol(e.target.value)} value = {ivol} className="pdinputeditinpmb mfontn"  placeholder="용량을 입력하세요."  maxLength="7"/>
          </div>
        </div>

        <div className="pdinphlinemb"></div>
        {/* 단위*/}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">단위</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <select className="pdinputselectmb mfontnb" value={iunit.toString()}
              onChange={(e) => {
                if (LOG_ENABLE)
                  console.log(e.target.value);

                  setIunit(parseInt(e.target.value));
              }}
            >
              <option value="0">박스</option>
              <option value="1">병</option>
              <option value="2">박스/병</option>
              <option value="3">박스/개</option>
              <option value="4">개</option>
            </select>
          </div>
        </div>

        <div className="pdinphlinemb"></div>
        {/*용도*/}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">용도</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <select className="pdinputselectmb mfontnb" value={iuses.toString()}
              onChange={(e) => {
                if (LOG_ENABLE)
                  console.log(e.target.value);

                  setIuses(parseInt(e.target.value));
              }}
            >
              <option value="0">유흥</option>
              <option value="1">일반</option>
              <option value="2">공용</option>
            </select>
          </div>
        </div>

        <div className="pdinphlinemb"></div>
        {/*도수*/}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">도수</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <input type="text" onChange={(e) => setIfreq(e.target.value)} value = {ifreq} className="pdinputeditinpmb mfontn"  placeholder="도수를 입력하세요."  maxLength="7"/>
          </div>
        </div>

        <div className="pdinphlinemb"></div>
        {/*입수*/}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">입수</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <input type="number" onChange={(e) => setIcnt(e.target.value)} value = {icnt} className="pdinputeditinpmb mfontn"  placeholder="입수를 입력하세요."/>
          </div>
        </div>

        <div className="pdinphlinemb"></div>
        {/*판매가*/}
        <div className="pdinputwinleftrowmb flexcenterrow">
          <div className="pdinputwinleftrowleftmb flexcenterrow">
            <label className="fontnb">판매가</label>
          </div>
          <div className="pdinputwinleftrowrightmb flexcenterrowend">
            <input type="number" onChange={(e) => setPrice(e.target.value)} value = {price} className="pdinputeditinpmb mfontn"  placeholder="상품 판매가를 입력하세요."/>
          </div>
        </div>
      </div>

      <div className="pdinphlinemb"></div>
      {/* 버튼영역 */}
      {
        props.editMode ? (<PDEditBtn handleEdit = {(editted) => props.handleEdit(editted, props.itemIdx, iid, icode, iname, alias, ivol, iunit, iuses, ifreq, icnt, props.img, price, imgFile)}/>) : 
          (<PDAddBtn handleAdd = {() => props.handleAdd(iid, icode, iname, alias, ivol, iunit, iuses, ifreq, icnt, price, imgFile)}/>)
      }
    </div>
  );
}

export default ProductInputmb;
