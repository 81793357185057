import { useState } from "react";
import { GetImgUrl, LOG_ENABLE, mobileOn } from "../../URL/AppUrl";
import { NumberFormatString, OrderTimeToString } from "../../Util/Util";
import DatePick from "../DatePick/DatePick";

function GetDateYear(dateInfo)
{
  let v = dateInfo.toString();

  return parseInt(v.substring(0, 4));
}

function GetDateMonth(dateInfo)
{
  let v = dateInfo.toString();

  return parseInt(v.substring(4, 6));
}

function GetDateDay(dateInfo)
{
  let v = dateInfo.toString();

  return parseInt(v.substring(6, 8));
}

const OrderTransfer = (props) =>
{
  const [showDateInfo, showDate] = useState(false); //이동할 일자
  const [dateInfo, setDateInfo] = useState(props.rtime ? props.rtime : 0); //이동할 일자
  const [deliType, setDeliType] = useState(0); //기본배송인지(1), 추가배송인지(2)

  if (mobileOn)
  {
    return (
      <div className="pddetcontmb flexcenterrow">
  
        {
          showDateInfo ? (
            <DatePick cyear={GetDateYear(dateInfo)} cmonth={GetDateMonth(dateInfo)} cday={GetDateDay(dateInfo)} handleDate={(selected, selectedDate) => {
              showDate(false);
  
              if (LOG_ENABLE)
              {
                console.log(`selected : ${selected}, date = ${selectedDate.getFullYear()}-${selectedDate.getMonth()+1}-${selectedDate.getDate()}`);
              }
  
              if (selected)
              {
                setDateInfo(parseInt(`${selectedDate.getFullYear()}${NumberFormatString(selectedDate.getMonth()+1)}${NumberFormatString(selectedDate.getDate())}`));
              }
            }}/>
          ) : null
        }
  
        <div className="odtranspopmb flexcentercol">
          
          <div className="odtranstt flexcenterrow marginbottom15">
            <div className="dcell1"></div>
            <div className="dcell8 flexcenterrow">
              <label className="mfontnb">주문 일자 변경</label>
            </div>
            <div className="dcell1 flexcenterrowend">
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur marginright10' onClick={() => {
                
                props.handleOrderTransfer(false, null, null, null);
              }}/>
            </div>
          </div>
          
          <div className="odtranstt flexcentercol marginbottom15">
            <label className="fonts2">주문을 특정일자의 기본 또는 추가배송으로 이동합니다.</label>
            <label className="fonts2">기본 또는 추가배송 어디로 이동할지는</label>
            <label className="fonts2">당일 배송상황에 따라 선택해주세요.</label>
            <label className="fonts2">배송하고자 하는 일자의 하루전 일자로 이동시켜야 합니다.</label>
            <label className="fonts2">예) 배송일자가 3월 8일인경우 주문을 3월 7일로 이동</label>
          </div>
  
          <div className="flexcenterrow odtransitem marginbottom15">
            <label className="mfontn">일자 선택</label>
  
            <div className="odtransdate flexcenterrow btncur" onClick={() => {
              showDate(true);
            }}>
              <label className="mfontn btncur">{OrderTimeToString(dateInfo)}</label>
            </div>
          </div>
  
          <div className="odtransline marginbottom15"></div>
  
          <div className="flexcenterrow odtransitem marginbottom15">
            <label className="mfontn">배송타입</label>
  
            <select className="odtranssel mfontn btncur" 
              onChange={(e) => {
                  console.log(e.target.value);
                  setDeliType(parseInt(e.target.value));
              }}
  
              value={deliType}
            >
              <option value="0">배송 타입을 선택하세요.</option>
              <option value="1">기본배송</option>
              <option value="2">추가배송</option>
            </select>
          </div>
  
          <div className="odtransline marginbottom30"></div>
  
          <button className="odtransbtn mfontnwb btncur bgcolor3" onClick={() => {
            if (deliType === 0)
            {
              alert('배송 타입을 선택하세요.');
              return;
            }
  
            if (dateInfo === props.rtime)
            {
              alert('이동하고자 하는 일자가 주문 접수일자와 동일합니다.');
              return;
            }
  
            if (!window.confirm(`${OrderTimeToString(props.rtime)} 일자 주문을 ${OrderTimeToString(dateInfo)} 일자 ${deliType === 1 ? "기본배송" : "추가배송"}으로 이동할까요?`))
            {
              return;
            }
  
            props.handleOrderTransfer(true, props.orderIdx, dateInfo, deliType);
          }}>
            주문이동
          </button>
        </div>
      </div>
    );
  }
  else
  {
    return (
      <div className="pddetcontmb flexcenterrow">
  
        {
          showDateInfo ? (
            <DatePick cyear={GetDateYear(dateInfo)} cmonth={GetDateMonth(dateInfo)} cday={GetDateDay(dateInfo)} handleDate={(selected, selectedDate) => {
              showDate(false);
  
              if (LOG_ENABLE)
              {
                console.log(`selected : ${selected}, date = ${selectedDate.getFullYear()}-${selectedDate.getMonth()+1}-${selectedDate.getDate()}`);
              }
  
              if (selected)
              {
                setDateInfo(parseInt(`${selectedDate.getFullYear()}${NumberFormatString(selectedDate.getMonth()+1)}${NumberFormatString(selectedDate.getDate())}`));
              }
            }}/>
          ) : null
        }
  
        <div className="odtranspop flexcentercol">
          
          <div className="odtranstt flexcenterrow marginbottom15">
            <div className="dcell1"></div>
            <div className="dcell8 flexcenterrow">
              <label className="fontnb">주문 일자 변경</label>
            </div>
            <div className="dcell1 flexcenterrowend">
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur marginright10' onClick={() => {
                
                props.handleOrderTransfer(false, null, null, null);
              }}/>
            </div>
          </div>
          
          <div className="odtranstt flexcentercol marginbottom15">
            <label className="fonts2">주문을 특정일자의 기본 또는 추가배송으로 이동합니다.</label>
            <label className="fonts2">기본 또는 추가배송 어디로 이동할지는 당일 배송상황에 따라 선택해주세요.</label>
            <label className="fonts2">배송하고자 하는 일자의 하루전 일자로 이동시켜야 합니다.</label>
            <label className="fonts2">예) 배송일자가 3월 8일인경우 주문을 3월 7일로 이동</label>
          </div>
  
          <div className="flexcenterrow odtransitem marginbottom15">
            <label className="fontn">일자 선택</label>
  
            <div className="odtransdate flexcenterrow btncur" onClick={() => {
              showDate(true);
            }}>
              <label className="fontn btncur">{OrderTimeToString(dateInfo)}</label>
            </div>
          </div>
  
          <div className="odtransline marginbottom15"></div>
  
          <div className="flexcenterrow odtransitem marginbottom15">
            <label className="fontn">배송타입</label>
  
            <select className="odtranssel mfontn btncur" 
              onChange={(e) => {
                  console.log(e.target.value);
                  setDeliType(parseInt(e.target.value));
              }}
  
              value={deliType}
            >
              <option value="0">배송 타입을 선택하세요.</option>
              <option value="1">기본배송</option>
              <option value="2">추가배송</option>
            </select>
          </div>
  
          <div className="odtransline marginbottom30"></div>
  
          <button className="odtransbtn fontnwb btncur bgcolor3" onClick={() => {
            if (deliType === 0)
            {
              alert('배송 타입을 선택하세요.');
              return;
            }
  
            if (dateInfo === props.rtime)
            {
              alert('이동하고자 하는 일자가 주문 접수일자와 동일합니다.');
              return;
            }
  
            if (!window.confirm(`${OrderTimeToString(props.rtime)} 일자 주문을 ${OrderTimeToString(dateInfo)} 일자 ${deliType === 1 ? "기본배송" : "추가배송"}으로 이동할까요?`))
            {
              return;
            }
  
            props.handleOrderTransfer(true, props.orderIdx, dateInfo, deliType);
          }}>
            주문이동
          </button>
        </div>
      </div>
    );    
  }
}

export default OrderTransfer;