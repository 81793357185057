import './MnuOrder.css';

function GetBtnColor(props, item)
{
  if (props.selectedIdx === -1)
    return "mnuodselreton mfontnwb btncur bgcolor1";
  else if (props.selectedIdx === item.idx)
    return "mnuodselreton mfontnwb btncur bgcolor3"

  return "mnuodselreton mfontnwb btncur bgcolor4"
}

const MnuOrderCompItem = (props) =>
{
  return (
    <div className="flexcenterrow mnuodcompitem">
      <div className="flexcentercol mnuodcompitemleft">
        <label className="fontnb">{props.item.cname}</label>
        <label className="fontn">{props.item.addr}</label>
      </div>

      <div className="mnuodcompitemright flexcenterrow">
        <button className={GetBtnColor(props, props.item)} onClick={() => props.handleSelect(props.item)}>선택</button>
      </div>
    </div>
  );
}

export default MnuOrderCompItem;