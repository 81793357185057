import { observer } from "mobx-react";
import { useState } from "react";
import { LoginStoreInstance } from "../../Stores/LoginStore";

function CheckNoticeWritePermission(tabIndex, searchMode)
{
  if (searchMode)
    return false;

  if (LoginStoreInstance.userType === 0)
    return true;

  if (tabIndex === 0 || LoginStoreInstance.getUserLevel > 1)
    return false;

  return true;
}

const TabSearchmb = (props) =>
{
  const [search, setSearch] = useState('');

  return (
    <div className="ntctabarearightmb flexcenterrowstart">

      <input type="text" onChange={(e) => setSearch(e.target.value)} value = {search} className="ntcsearchinpmb fontn"
        onKeyPress={(e) => {
          if (e.key === "Enter")
          {
            props.handleSearch(search);
          }
        }}
      />

      <button className="ntcsearchbtnmb mfontnb btncur" onClick={() => props.handleSearch(search)}>
        검색
      </button>

      {
        props.searchMode ? 
          (
            <button className="ntcsearchbtnmb mfontnb btncur" onClick={props.handleList}>
              목록
            </button>
          ) : 
          null
      }

      {
        CheckNoticeWritePermission(props.tabIndex, props.searchMode) ? 
          (
            <button className="ntcwritebtnmb mfontnb btncur" onClick={props.handleWrite}>
              글쓰기
            </button>
          ) : 
          null
      }
    </div>
  );
}

const NoticeTabmb = (props) =>
{
  return (
    <div className="ntctabareamb flexcentercol">

      {/*검색영역*/}
      <TabSearchmb handleSearch = {props.handleSearch} handleWrite = {props.handleWrite} handleList = {props.handleList}
        tabIndex = {props.tabIndex} searchMode = {props.searchMode}/>

      {/*탭 영역 */}
      <div className="ntctabarealeftmb flexcenterrow">
        <div className="ntctabmenumb flexcentercolend" onClick = {() => props.handleTab(0)}>
          <label className= {props.tabIndex === 0 ? "btncur ntctabselfontmb" : "ntctabfontmb"}>전체</label>
          <div className={props.tabIndex === 0 ? "ntctabmenulinesel":"ntctabmenuline"}/>
        </div>

        <div className="ntctabvlinemb"></div>

        <div className="ntctabmenumb flexcentercolend" onClick = {() => props.handleTab(1)}>
          <label className={props.tabIndex === 1 ? "btncur ntctabselfontmb":"ntctabfontmb"}>개별</label>
          <div className={props.tabIndex === 1 ? "ntctabmenulinesel" : "ntctabmenuline"}/>
        </div>
      </div>

      <div className="ntcitemhlinemb"></div>
    </div>
  );
}

export default observer(NoticeTabmb);