import React from 'react';

import {GetImgUrl, GetSoundUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
import MainMenu from '../Menu/MainMenu';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { OrderStoreInstance } from '../../Stores/OrderStore';
import PageList from '../PageList';
import { observer } from 'mobx-react';


import '../../App.css';
import '../Emp/Staff.css';
import '../Order/Order.css';
import './OrderMisc.css';
import { NumberFormatString } from '../../Util/Util';
import DatePick from '../DatePick/DatePick';
import { ProcessLogout } from '../../Stores/StoreManager';
import Loading from '../Loading/Loading';
import OrderMiscVoice from './OrderMiscVoice';
import OrderMiscVoicemb from './OrderMiscVoicemb';
import MenuMb from '../Menu/MenuMb';
import OrderMiscDetmb from './OrderMiscDetmb';

class OrderMisc extends React.Component {

  state = 
  {
    loading : false,
    dateSelect : false, //날짜 선택중인가?
    selectedDate : '',

    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 1, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 1, //전체 페이지 개수
    showDetail : false,
    voicePlaying : false,
    voiceTime : '',
    appOn : false,
  };

  //선택된 날짜
  selectedYear = 0;
  selectedMonth = 0;
  selectedDay = 0;

  //오늘 처리해야 하는 주문의 날짜
  todayYear = 0;
  todayMonth = 0;
  todayDay = 0;

  intervalId = -1;

  maxPageNum = 10;
  detailInfo = null;

  audioData = null;
  audioPlaying = false;
  audioLength = 0; //재생중인 오디오의 길이
  audioTime = null;

  getDayInfo = () =>
  {
    return this.selectedYear.toString() + NumberFormatString(this.selectedMonth) + NumberFormatString(this.selectedDay);
  }

  componentDidMount() {

    if (mobileOn)
      this.maxPageNum = 3;

    let yesterDay = this.getYesterday();

    this.setState((prevState) => ({...prevState, selectedDate : yesterDay}));

    if (LOG_ENABLE)
      console.log("Staff.componentDidMount");

    this.intervalId = setInterval(this.update, 1000);

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 6}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    //프로필없으면 읽기
    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.OnLoadProfile);
      return;
    }

    this.loadCount();
  }

  componentWillUnmount = () =>
  {
    this.stopAudio();

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : "backhandler"}));

    if (!this.state.dateSelect && !this.state.showDetail)
      this.props.history.push("/main");
    else if (this.state.dateSelect)
    {
      this.handleDate(false, '');
    }
    else if (this.state.showDetail)
    {
      this.handleCloseDetail();
    }
  }

  stopAudio = () =>
  {
    if (this.audioData != null)
    {
      this.audioData.pause();
      this.audioData = null;
      this.audioPlaying = false;
    }
  }

  updateAudioTime = () =>
  {
    if (this.audioTime === null)
      return;

    const currentTime = ((new Date()) - this.audioTime) / 1000;

    let passedTime = this.audioLength - currentTime;
    
    if (passedTime < 0)
    {
      this.setState((prevState) => ({...prevState, voiceTime : ''}));
      return;
    }

    let min = Math.floor(passedTime / 60);

    if (min > 0)
      passedTime -= min * 60;

    passedTime = Math.floor(passedTime);

    this.setState((prevState) => ({...prevState, voiceTime : `${NumberFormatString(min)}:${NumberFormatString(passedTime)}`}));
  }

  playAudio = (soundFile) =>
  {
    if (this.audioData !== null)
    {
      this.stopAudio();

      return;
    }

    this.audioData = new Audio(GetSoundUrl(soundFile));
    this.audioData.load();
    
    this.audioData.addEventListener('loadeddata', () => {
      //this.setState((prevState) => ({...prevState, time : this.getAudioTime()}));

      this.audioData.currentTime = 0;
      this.audioData.play();

      this.audioLength = this.audioData.duration; //재생중인 오디오의 길이
      this.audioTime = new Date();

      this.updateAudioTime();
    });

    this.audioData.addEventListener('playing', () => {

      if (LOG_ENABLE)
        console.log("audio playing");

      this.audioPlaying = true;
      this.setState((prevState) => ({...prevState, voicePlaying : true}));
    });

    this.audioData.addEventListener('pause', () => {

      if (LOG_ENABLE)
        console.log("audio paused");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, voicePlaying : false,voiceTime:''}));
      this.stopAudio();
    });

    this.audioData.addEventListener('ended', () => {

      if (LOG_ENABLE)
        console.log("audio ended");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, voicePlaying : false,voiceTime:''}));

      this.stopAudio();
    });

    this.audioData.addEventListener('error', () => {
      if (LOG_ENABLE)
        console.log("audio error");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, voicePlaying : false,voiceTime:''}));

      this.stopAudio();
    });
  }

  setLoading = (enabled) =>
  {
    this.setState((prevState) => ({...prevState, loading : enabled}));
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }

  calcPageInfo = () =>
  {
    let currentCount = OrderStoreInstance.morder;

    let pageCount = Math.floor(currentCount / 30);

    if ((currentCount % 30) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.maxPageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  OnLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    this.loadCount();
  }

  //전체 주문의 개수 정보를 읽는다.
  loadCount = () =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    if (LOG_ENABLE)
      console.log("day info : " + this.getDayInfo());

    OrderStoreInstance.loadOrderCount(this.getDayInfo(), 0, 1,  this.onLoadCountResult);
  }

  onLoadCountResult = (resultCode) =>
  {
    switch (resultCode)
    {
      case 0:
        if (LOG_ENABLE)
            console.log("onLoadCountResult");

        this.calcPageInfo();
        this.loadOrderList(this.state.currentPage);
        break;
      default:
        this.setLoading(false);
        alert("주문 정보 조회중 오류가 발생했습니다.");
        break;
    }
  }

  loadOrderList = (pageNum) =>
  {
    OrderStoreInstance.clearOrderList();

    if (OrderStoreInstance.morder < 1)
    {
      if (LOG_ENABLE)
        console.log("no order");

      if (this.state.loading)
      {
        this.setLoading(false);
      }
      return;
    }

    if (!this.state.loading)
      this.setLoading(true);

    OrderStoreInstance.loadOrder(0, 2, (pageNum - 1) * 30, this.getDayInfo(), this.onLoadOrderResult);
  }

  onLoadOrderResult = (resultCode) =>
  {
    if (this.state.loading)
      this.setLoading(false);

    if(resultCode !== 0)
    {
      alert("주문 정보 조회중 오류가 발생했습니다.");
      return;
    }
  }

  //이전날짜인가?
  isPrevDay = () =>
  {
    if (this.selectedYear < this.todayYear)
      return true;

    if (this.selectedYear > this.todayYear)
      return false;

    if (this.selectedMonth < this.todayMonth)
      return true;

    if (this.selectedMonth > this.todayMonth)
      return false;

    if (this.selectedDay < this.todayDay)
      return true;

    if (this.selectedDay > this.todayDay)
      return false;

    return false;
  }

  //이후 날짜인가?
  isNextDay = () =>
  {
    if (this.selectedYear > this.todayYear)
      return true;

    if (this.selectedYear < this.todayYear)
      return false;

    if (this.selectedMonth > this.todayMonth)
      return true;

    if (this.selectedMonth < this.todayMonth)
      return false;

    if (this.selectedDay > this.todayDay)
      return true;

    if (this.selectedDay < this.todayDay)
      return false;

    return false;
  }

  getYesterday = () =>
  {
    let date = new Date();

    date.setDate(date.getDate() - 1);

    this.selectedYear = date.getFullYear();
    this.selectedMonth = date.getMonth() + 1;
    this.selectedDay = date.getDate();

    //처리 가능한 주문의 날짜를 기록해둠
    this.todayYear = this.selectedYear;
    this.todayMonth = this.selectedMonth;
    this.todayDay = this.selectedDay;

    return (date.getFullYear() + "-" + NumberFormatString(date.getMonth() + 1) + "-" + NumberFormatString(date.getDate()));
  }

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");  
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        this.props.history.push("/order"); 
        break;
      case 4: //판매일보
        this.props.history.push("/report"); 
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }

  //하단 페이지 변경 버튼 눌릴경우
  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4
  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = this.maxPageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadOrderList(1);
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(startPage);
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(startPage);
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(endPage);
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadOrderList(pageNum);
    }
  }

  //날짜 영역을 선택하는경우
  handleDateSelect = () =>
  {
    if (LOG_ENABLE)
      console.log("handleDateSelect");

    this.setState((prevState) => ({...prevState, dateSelect : true}));
  }

  //새로운 날짜가 선택됨
  handleDate = (valid, selectedDate) =>
  {
    if (LOG_ENABLE)
      console.log("handleDate : " + selectedDate);

    //날짜 선택없이 닫은경우
    if (!valid)
    {
      this.setState((prevState) => ({...prevState, dateSelect : false}));
      return;
    }

    let newYear = selectedDate.getFullYear();
    let newMonth = selectedDate.getMonth() + 1;
    let newDay = selectedDate.getDate();

    let today = new Date(this.todayYear, this.todayMonth - 1, this.todayDay, 0, 0, 0);
    let newDate = new Date(newYear, newMonth - 1, newDay, 0, 0, 0);

    let dateDiff = (newDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);

    if (dateDiff > 1)
    {
      this.setState((prevState) => ({...prevState, dateSelect : false}));

      alert("해당 일자는 조회할 수 없습니다.");
      return;
    }

    this.selectedYear = newYear;
    this.selectedMonth = newMonth;
    this.selectedDay = newDay;

    let newDateInfo = selectedDate.getFullYear() + "-" + NumberFormatString(selectedDate.getMonth() + 1) + "-" + NumberFormatString(selectedDate.getDate());

    let nextDay = false;

    this.setState((prevState) => ({...prevState, dateSelect : false,selectedDate : newDateInfo}));

    if (nextDay)
      alert("이날짜의 주문은 아직 접수중 이므로, 추가 주문이 있을 수 있습니다.");

    OrderStoreInstance.clearOrderList();
    this.loadCount();
  }

  handleReorder = (orderIdx) =>
  {
    if (LOG_ENABLE)
      console.log(`handleReorder : ${orderIdx}`);

    this.editOrderInfo = OrderStoreInstance.findOrderByIdx(orderIdx);

    if (this.editOrderInfo == null)
    {
      alert("주문을 찾을 수 없습니다.");
      return;
    }

    if (!window.confirm('재주문 요청할까요? 주문은 재주문 요청상태가 되고 주문자에게 알림이 갑니다.'))
    {
      return;
    }

    this.setState((prevState) => ({...prevState, loading : true, showDetail : 0}));

    OrderStoreInstance.requestVOrderDone(orderIdx, 6, undefined, this.onReorderResult);
  }

  onReorderResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("재주문 요청 권한이 없습니다.");
        break;
      case 3:
        alert("해당 주문을 찾을 수 없습니다.");
        break;
      case 5:
        alert("해당 주문은 이용자에 의해 취소되었습니다.");
        break;
      default:
        alert("재주문 요청에 실패 했습니다.");
        break;
    }
  }

  //상품추가 완료버튼 눌릴경우, 영업담당자 본인이 아니면 사용못하게 하기
  handleAddItemCompleted = (orderIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleAddItemCompleted : " + orderIdx);

    this.editOrderInfo = OrderStoreInstance.findOrderByIdx(orderIdx);

    if (this.editOrderInfo == null)
    {
      alert("주문을 찾을 수 없습니다.");
      return;
    }

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert("주문 처리 권한이 없습니다.");
      return;
    }

    //어제 부터 오늘 오전6시까지의 주문만 수정 할 수 있다.
    /*if (this.isPrevDay() || this.isNextDay())
    {
      const msg = this.isPrevDay() ? "이주문은 이미 지난 주문입니다. 그래도 진행할까요?" : "이 주문은 내일 처리할 주문입니다. 그래도 진행할까요?";

      if (!window.confirm(msg))
      {
        return;
      }
    }*/

    if (!window.confirm('이주문을 처리 완료 할까요? 완료시 해당 주문은 "주문 처리 완료" 상태가 됩니다.'))
    {
      return;
    }

    this.setLoading(true);
    OrderStoreInstance.requestVOrderDone(orderIdx, 1, undefined, this.onVOrderDoneResult);
  }
  
  onVOrderDoneResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("주문 완료 권한이 없습니다.");
        break;
      case 3:
        alert("해당 주문을 찾을 수 없습니다.");
        break;
      case 5:
        alert("해당 주문은 이용자에 의해 취소되었습니다.");
        break;
      default:
        alert("주문 완료 처리에 실패 했습니다.");
        break;
    }
  }

  handleLogout = () => {
    ProcessLogout();
    this.props.history.push("/");
  }

  handleDetail = (orderInfo) => {
    if (LOG_ENABLE)
      console.log("handleDetail");

    this.detailInfo = orderInfo;

    this.setState((prevState) => ({...prevState, showDetail : true, voicePlaying : false}));
  }

  handleCloseDetail = () => {
    this.setState((prevState) => ({...prevState, showDetail : false, voicePlaying : false}));

    //재생중인 사운드는 멈추기
    this.stopAudio();
  }

  handlePlayVoice = (orderInfo) => {
    if (this.audioPlaying)
    {
      this.stopAudio();
      return;
    }

    this.playAudio(orderInfo.sfile);
  }

  render() {
    const orderList = OrderStoreInstance.getOrderList;

    let orders = null;

    if (orderList !== null && orderList.length > 0)
    {
      if (mobileOn)
      {
        orders = orderList.map(item => {
          return (<OrderMiscVoicemb key={item.idx} order = {item} addItemCompleted = {this.handleAddItemCompleted}
            handleDetail = {this.handleDetail}/>);
        });
      }
      else
      {
        orders = orderList.map(item => {
          return (<OrderMiscVoice key={item.idx} order = {item} addItemCompleted = {this.handleAddItemCompleted} handleReorder={this.handleReorder}/>);
        });
      }
    }

    let subMsg = "";

    if (this.isPrevDay())
      subMsg = "지난 일자의 주문입니다.";
    else if (this.isNextDay())
      subMsg = "아직 주문이 진행중입니다";
    else
      subMsg = "오늘 처리 할 주문입니다.";

    if (mobileOn) //모바일 버전
    {
      return (
        <div className="appcontainer">
          {this.state.dateSelect ? <DatePick handleDate = {this.handleDate} cyear={this.selectedYear} cmonth={this.selectedMonth} cday={this.selectedDay}/> : null}
          
          {
            this.state.loading ? <Loading />:null
          }
  
          {
            this.state.showDetail ? <OrderMiscDetmb order = {this.detailInfo} handleCloseDetail = {this.handleCloseDetail} 
              playTime = {this.state.voiceTime}
              playVoice = {this.handlePlayVoice} playing = {this.state.voicePlaying}
              addItemCompleted = {this.handleAddItemCompleted}
              handleReorder={this.handleReorder}
              /> : null
          }

          <div className="maincontainermb">
            <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout = {this.handleLogout}/>            
            
            <div className="omsercontmb">
              <div className="ordersertitle flexcenterrowstart">
                
                <div className="orderserl flexcenterrowstart">
                  <img src={GetImgUrl("mbmcetc.png")} alt="기타주문" />
                  <label className="mfontnb">&nbsp;기타주문</label>
                </div>
  
                <div className="orderserr flexcenterrowend">
                </div>
                
              </div>
  
              <div className="empsercontentmb flexcenterrowstart">
                <label className="mfontnwb">&nbsp;날짜별 주문 내역 조회</label>
  
                <div className="orderdatemb flexcenterrow" onClick={this.handleDateSelect}>
                  <label className="btncur mfontnb">{this.state.selectedDate}</label>
                </div>
  
                <button className="orderdateselmb mfontnb" onClick={this.handleDateSelect}>
                  조회
                </button>
              </div>
            </div>
  
            <div className="omsercontmb">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("mbmcpapers.png")} alt="주문정보 조회" />
                <label className="mfontnb">&nbsp;주문정보 조회</label>
              </div>
              
              <div className="empline"/>
  
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("mbmcday.png")} alt="조회 일자" />
                <label className="mfontnb">&nbsp;{this.state.selectedDate}</label>
                <label className="rptsubmsgmb">&nbsp;{subMsg}</label>
              </div>
  
              <div className="empline"/>
  
            </div>
  
            {orders != null && orders.length > 0 ? orders : (<label className="mfontn">해당 일자의 주문이 없습니다.</label>)}
  
            {
              this.state.pageCount > 1 ? 
                <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
    else //pc버전
    {
      return (
        <div className="appcontainer">
          {this.state.dateSelect ? <DatePick handleDate = {this.handleDate} cyear={this.selectedYear} cmonth={this.selectedMonth} cday={this.selectedDay}/> : null}
          
          {
            this.state.loading ? <Loading />:null
          }
  
          <div className="maincontainer">
            <div className="empmargin"/>
            <MainMenu index={6} handleMenuClick = {this.handleMenuClick}/>
            
            <div className="empsercon">
              <div className="ordersertitle flexcenterrowstart">
                
                <div className="orderserl flexcenterrowstart">
                  <img src={GetImgUrl("papers.png")} alt="기타주문" />
                  <label className="fontlargeb">&nbsp;기타주문</label>
                </div>
  
                <div className="orderserr flexcenterrowend">
                </div>
                
              </div>
  
              <div className="empsercontent flexcenterrowstart">
                <img src={GetImgUrl("search.png")} alt="날짜별주문관리" className="empimg"/>
                <label className="fontnwb">&nbsp;날짜별 주문 내역 조회</label>
  
                <div className="orderdate flexcenterrow" onClick={this.handleDateSelect}>
                  <label className="btncur fontnb">{this.state.selectedDate}</label>
                </div>
  
                <button className="orderdatesel fontnb" onClick={this.handleDateSelect}>
                  조회
                </button>
              </div>
            </div>
  
            <div className="empsercon">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("papersl.png")} alt="주문정보 조회" />
                <label className="fontlargeb">&nbsp;주문정보 조회</label>
              </div>
              
              <div className="empline"/>
  
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("day.png")} alt="조회 일자" />
                <label className="fontlargeb">&nbsp;{this.state.selectedDate}</label>
                <label className="rptsubmsg">&nbsp;{subMsg}</label>
              </div>
  
              <div className="empline"/>
  
            </div>
  
            {orders != null && orders.length > 0 ? orders : "해당 일자의 주문이 없습니다."}
  
            {
              this.state.pageCount > 1 ? 
                <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
  }
}

export default observer(OrderMisc);