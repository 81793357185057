import React from 'react';

import {GetImgUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
import MainMenu from '../Menu/MainMenu';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { OrderStoreInstance } from '../../Stores/OrderStore';
import { observer } from 'mobx-react';
import { PrintInfo, PrintInfoNoTotal, PrintInfoTotalOnly, PrintStyle, PrintTable } from './PrintInfo';

import '../../App.css';
import '../Emp/Staff.css';
import '../Order/Order.css';
import "./Report.css";
import { IsValidS, IsValidV, NumberFormatString } from '../../Util/Util';
import DatePick from '../DatePick/DatePick';
import ReportList from './ReportList';
import ReportListDeli from './ReportListDeli';
import { ItemListToTag, ItemListToTagSpecial } from './ReportUtil';
import ReportSalesSel from './ReportSalesSel';
import { StaffStoreInstance } from '../../Stores/StaffStore';
import { ProcessLogout } from '../../Stores/StoreManager';
import Loading from '../Loading/Loading';
import MenuMb from '../Menu/MenuMb';
import ReportSalesSelmb from './ReportSalesSelmb';
import ReportListmb from './ReportListmb';
import ReportFontOption from './ReportFontOption';
import ReportFontOptionmb from './ReportFontOptionmb';

class Report extends React.Component {

  state = 
  {
    loading : false,
    dateSelect : false, //날짜 선택중인가?
    dateSelectMode : 0,
    startDate : '',
    selectedDate : '',
    dateRange : '',
    deliveryMode: 0, //0 : 일반 배송, 1 : 추가배송
    printOption : 0, //0 : 단축명있는경우 상품코드 및 용량 표시안함, 1 : 상품코드, 용량 항상표시, 2 : 상품코드 및 용량 표시안함
    appOn : false,
    printFontOption : false,
  };

  reportMode = 0; //리포트 모드, 0 : 영업담당자 또는 관리담당자 본인, 1 : 기타 계정
  selectedSalesIdx = -1; //선택된 영업담당자 계정의 고유번호
  selectedSalesName = null; //선택된 영업담당자 계정의 이름
  

  dateString = null;

  startDateSelected = false; //시작 날짜가 수동으로 선택되었나?
  //선택된 날짜1
  selectedYear1 = 0;
  selectedMonth1 = 0;
  selectedDay1 = 0;

  //선택된 날짜2
  selectedYear = 0;
  selectedMonth = 0;
  selectedDay = 0;

  //오늘 처리해야 하는 주문의 날짜
  todayYear = 0;
  todayMonth = 0;
  todayDay = 0;

  isTodayOrder = true; //오늘자 판매일보 보고있나?

  intervalId = -1;
  firstIdx = -1; //판매일보의 첫번째 주문의 고유번호
  lastIdx = -1; //판매 일보 조회용 고유번호, 마지막으로 조회된 판매일보중 고유번호가 가장큰것
  firstMnuOrderIdx = -1;
  lastMnuOrderIdx = -1;
  totalOrderCount = 0; //판매일보 조회시 수신된 총 주문의 개수
  maxManualIdx = -1;

  deliMode = 0;
  totalInfoOnly = false;

  getDayInfo1 = () =>
  {
    return this.selectedYear1 + NumberFormatString(this.selectedMonth1) + NumberFormatString(this.selectedDay1);
  }

  getDayInfo2 = () =>
  {
    return this.selectedYear + NumberFormatString(this.selectedMonth) + NumberFormatString(this.selectedDay);
  }

  getDayInfoString1 = () =>
  {
    return `${this.selectedYear1}-${NumberFormatString(this.selectedMonth1)}-${NumberFormatString(this.selectedDay1)}`;
  }

  getDayInfoString2 = () =>
  {
    return `${this.selectedYear}-${NumberFormatString(this.selectedMonth)}-${NumberFormatString(this.selectedDay)}`;
  }

  componentDidMount() {

    if (LOG_ENABLE)
      console.log("Report.componentDidMount");

    OrderStoreInstance.loadFontSize();

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 5}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    if (!String.format)
    {
      String.format = function() {
        // The string containing the format items (e.g. "{0}")
        // will and always has to be the first argument.
        var theString = arguments[0];
        
        // start with the second argument (i = 1)
        for (var i = 1; i < arguments.length; i++) {
            // "gm" = RegEx options for Global search (more than one instance)
            // and for Multiline search
            var regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
            theString = theString.replace(regEx, arguments[i]);
        }
        
        return theString;
      }
    }

    this.lastIdx = -1;
    this.firstMnuOrderIdx = -1;
    this.lastMnuOrderIdx = -1;
    OrderStoreInstance.clearReportInfo();
    OrderStoreInstance.clearDailyOrder();

    this.makeTodayInfo();

    this.isTodayOrder = true;

    this.intervalId = setInterval(this.update, 1000);

    //먼저 프로필 정보를 읽어야 한다.
    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);

      return;
    }

    //영업담당자 목록이 없는경우
    if (!IsValidS(StaffStoreInstance.salesManagerList))
    {
      this.setLoading(true);
      StaffStoreInstance.loadSalesManager(this.onLoadSales);

      return;
    }

    //관리 담당자인경우
    if (LoginStoreInstance.getUserLevel === 3)
    {
      //관리 담당자 목록이 없는경우
      if (!IsValidS(StaffStoreInstance.managedSalesList))
      {
        this.setLoading(true);

        StaffStoreInstance.loadManagedSalesList(LoginStoreInstance.userIdx, this.onManagedSalesLoaded);
        return;
      }
    }

    this.initReportMode();
    this.loadReport(this.state.deliveryMode);
  }
  
  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }
  }

  initReportMode = () =>
  {
    switch(LoginStoreInstance.getUserLevel)
    {
      case 2: //영업담당인경우
        this.reportMode = 0;
        break;
      default: //기타 계정인경우
        this.reportMode = 1;
        break;
    }
	
    if (this.reportMode === 0)
    {
      if (LOG_ENABLE)
        console.log("sales account");

      this.selectedSalesIdx = LoginStoreInstance.userIdx;
      this.selectedSalesName = LoginStoreInstance.getUserName;

      this.setState((prevState) => ({...prevState, startDate : this.getDayInfoString1(), selectedDate : this.getDayInfoString2(), dateRange : this.dateString}));
    }
    else
    {
      if (LoginStoreInstance.getUserLevel <= 1) //어드민 및 일반관리자
      {
        //일단 계정이 영업담당자가 아니라 가정하고 필요 데이터 설정
        const sales = StaffStoreInstance.getFirstSalesManager();

        if (sales !== null)
        {
          this.selectedSalesIdx = sales.idx;
          this.selectedSalesName = sales.uname;
        }

        this.setState((prevState) => ({...prevState, startDate : this.getDayInfoString1(), selectedDate : this.getDayInfoString2(), 
          dateRange : `${this.dateString}(${this.selectedSalesName})`}));
      }
      else //관리 담당자
      {
        const managedSales = StaffStoreInstance.getFirstManagedSalesInfo();

        if (managedSales !== null)
        {
          this.selectedSalesIdx = managedSales.sidx;
          this.selectedSalesName = managedSales.uname;
        }

        this.setState((prevState) => ({...prevState, startDate : this.getDayInfoString1(), selectedDate : this.getDayInfoString2(), 
          dateRange : `${this.dateString}(${this.selectedSalesName})`}));
      }
    }
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : "backhandler"}));

    if (!this.state.dateSelect)
      this.props.history.push("/main");
    else if (this.state.dateSelect)
    {
      this.handleDate(false, '');
    }
  }

  onLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    //영업담당자 목록이 비어 있으면
    if (!IsValidS(StaffStoreInstance.salesManagerList))
    {
      this.setLoading(true);
      StaffStoreInstance.loadSalesManager(this.onLoadSales);
      return;
    }

    if (LoginStoreInstance.getUserLevel === 3)
    {
      if (!IsValidS(StaffStoreInstance.managedSalesList))
      {
        this.setLoading(true);
        StaffStoreInstance.loadManagedSalesList(LoginStoreInstance.userIdx, this.onManagedSalesLoaded);
        return;
      }
    }

    this.initReportMode();

    this.firstIdx = -1;
    this.lastIdx = -1;
    this.firstMnuOrderIdx = -1;
    this.lastMnuOrderIdx = -1;
    this.totalOrderCount = 0;
    OrderStoreInstance.clearReportInfo();
    OrderStoreInstance.clearDailyOrder();

    this.loadReport(this.state.deliveryMode);
  }

  onLoadSales = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      if (!window.confirm("영업담당자 조회중 오류가 발생 했습니다.다시 시도할까요?"))
      {
        this.setLoading(false);
        return;
      }

      StaffStoreInstance.loadSalesManager(this.onLoadSales);
      return;
    }

    if (LoginStoreInstance.getUserLevel === 3)
    {
      if (!IsValidS(StaffStoreInstance.managedSalesList))
      {
        this.setLoading(true);
        StaffStoreInstance.loadManagedSalesList(LoginStoreInstance.userIdx, this.onManagedSalesLoaded);
        return;
      }
    }
    
    this.initReportMode();
    
    this.firstIdx = -1;
    this.lastIdx = -1;
    this.firstMnuOrderIdx = -1;
    this.lastMnuOrderIdx = -1;
    this.totalOrderCount = 0;
    OrderStoreInstance.clearReportInfo();
    OrderStoreInstance.clearDailyOrder();

    this.loadReport(this.state.deliveryMode);
  }

  onManagedSalesLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      if (!window.confirm('관리하는 영업담당자 목록을 조회 중 오류가 발생했습니다.\n다시 시도할까요?'))
      {
        return;
      }

      this.setLoading(true);
      StaffStoreInstance.loadManagedSalesList(LoginStoreInstance.userIdx, this.onManagedSalesLoaded);

      return;
    }

    this.initReportMode();
    
    this.firstIdx = -1;
    this.lastIdx = -1;
    this.firstMnuOrderIdx = -1;
    this.lastMnuOrderIdx = -1;
    this.totalOrderCount = 0;
    OrderStoreInstance.clearReportInfo();
    OrderStoreInstance.clearDailyOrder();

    this.loadReport(this.state.deliveryMode);
  }

  loadReport = (deliveryMode) =>
  {
    if (this.selectedSalesIdx < 1)
    {
      return;
    }

    if (!this.state.loading)
      this.setLoading(true);

    OrderStoreInstance.loadDailyReport(this.lastIdx, this.selectedSalesIdx, this.getDayInfo1(), this.getDayInfo2(), deliveryMode, this.onReportLoaded);
  }

  onReportLoaded = (resultCode, loadCount, firstOrderIdx, lastQueryIdx, totalOrderCount) =>
  {
    if (resultCode !== 0)
    {
      if (!window.confirm("판매일보 조회중 오류가 발생했습니다.다시 시도할까요?"))
      {
        this.setLoading(false);
        return;
      }

      this.firstIdx = -1;
      this.lastIdx = -1;
      this.firstMnuOrderIdx = -1;
      this.lastMnuOrderIdx = -1;
      this.totalOrderCount = 0;
      OrderStoreInstance.clearReportInfo();
      OrderStoreInstance.clearDailyOrder();

      this.loadReport(this.state.deliveryMode);

      return;
    }

    this.firstIdx = firstOrderIdx;
    this.lastIdx = lastQueryIdx;

    //더이상 읽을 데이터가 없다면 리포트 조회가 완료 된거다.
    if (loadCount === 0)
    {
      this.totalOrderCount = totalOrderCount; //총 주문 개수를 기록해둔다.
      //this.setLoading(false);

      if (this.state.deliveryMode === 0) //기본배송조회인경우
      {
        this.maxManualIdx = 0;
        //수기 주문을 읽기 시작한다.
        this.loadManualOrders();
      }
      else
      {
        this.setLoading(false);
      }
      return;
    }

    //아직 읽을 데이터가 있으니 다음 데이터를 읽기 시도한다.
    this.loadReport(this.state.deliveryMode);
  }
  //---------------------------------------------------------------------
  loadManualOrders = () =>
  {
    OrderStoreInstance.loadManualOrders(this.maxManualIdx, this.selectedSalesIdx, this.getDayInfo1(), this.getDayInfo2(), this.onManualReportLoadDone);
  }

  onManualReportLoadDone = (resultCode, loadCount, firstIdx, maxIdx) =>
  {
    if (resultCode !== 0)
    {
      if (!window.confirm("판매일보 조회중 오류가 발생했습니다.다시 시도할까요?"))
      {
        this.setLoading(false);
        return;
      }

      this.firstIdx = -1;
      this.lastIdx = -1;
      this.firstMnuOrderIdx = -1;
      this.lastMnuOrderIdx = -1;
      this.totalOrderCount = 0;
      OrderStoreInstance.clearReportInfo();
      OrderStoreInstance.clearDailyOrder();

      this.loadReport(this.state.deliveryMode);

      return;
    }

    //더이상 읽을 주문이 없음
    if (loadCount === 0)
    {
      this.setLoading(false);
      return;
    }

    //다음 주문 읽기 시작
    if (firstIdx !== -1)
    {
      if (this.firstIdx === -1 || this.firstIdx > firstIdx)
        this.firstIdx = firstIdx;
    }
    
    this.lastMnuOrderIdx = maxIdx;
    this.maxManualIdx = maxIdx;
    this.loadManualOrders();
  }
  //---------------------------------------------------------------------
  setLoading = (enable) =>
  {
    this.setState((prevState) => ({...prevState, loading : enable}));
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }

  //이전날짜인가?
  isPrevDay = () =>
  {
    if (this.selectedYear < this.todayYear)
      return true;

    if (this.selectedYear > this.todayYear)
      return false;

    if (this.selectedMonth < this.todayMonth)
      return true;

    if (this.selectedMonth > this.todayMonth)
      return false;

    if (this.selectedDay < this.todayDay)
      return true;

    if (this.selectedDay > this.todayDay)
      return false;

    return false;
  }

  //이후 날짜인가?
  isNextDay = () =>
  {
    if (this.selectedYear > this.todayYear)
      return true;

    if (this.selectedYear < this.todayYear)
      return false;

    if (this.selectedMonth > this.todayMonth)
      return true;

    if (this.selectedMonth < this.todayMonth)
      return false;

    if (this.selectedDay > this.todayDay)
      return true;

    if (this.selectedDay < this.todayDay)
      return false;

    return false;
  }

  //오늘 배송할 데이터인가?
  isToDay = () =>
  {
    if (this.selectedYear !== this.todayYear)
      return false;

    if (this.selectedMonth !== this.todayMonth)
      return false;

    if (this.selectedDay !== this.todayDay)
      return false;

    return true;
  }

  //배송 마감 시간이 지났는지 확인하기
  checkOrderDoneTime = () =>
  {
    let date = new Date();

    return !LoginStoreInstance.checkOrderTimeLimit(date.getHours(), date.getMinutes());
  }

  makeTodayInfo = () =>
  {
    let date = new Date();

    date.setDate(date.getDate() - 1);

    this.startDateSelected = false;
    this.selectedYear1 = date.getFullYear();
    this.selectedMonth1 = date.getMonth() + 1;
    this.selectedDay1 = date.getDate();

    this.selectedYear = this.selectedYear1;
    this.selectedMonth = this.selectedMonth1;
    this.selectedDay = this.selectedDay1;

    //처리 가능한 주문의 날짜를 기록해둠
    this.todayYear = this.selectedYear;
    this.todayMonth = this.selectedMonth;
    this.todayDay = this.selectedDay;

    this.dateString = `${date.getFullYear()}-${NumberFormatString(date.getMonth() + 1)}-${NumberFormatString(date.getDate())}`;
  }

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");  
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        this.props.history.push("/order");
        break;
      case 4: //판매일보
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }

  handleStartDateSelect = () =>
  {
    if (LOG_ENABLE)
      console.log("handleStartDateSelect");

    this.setState((prevState) => ({...prevState, dateSelect : true, dateSelectMode : 1}));
  }

  //날짜 영역을 선택하는경우
  handleDateSelect = () =>
  {
    if (LOG_ENABLE)
      console.log("handleDateSelect");

    this.setState((prevState) => ({...prevState, dateSelect : true, dateSelectMode : 0}));
  }

  //새로운 날짜가 선택됨
  handleDate = (valid, selectedDate) =>
  {
    if (LOG_ENABLE)
      console.log("handleDate : " + selectedDate);

    //날짜 선택없이 닫은경우
    if (!valid)
    {
      this.setState((prevState) => ({...prevState, dateSelect : false}));
      return;
    }

    let newYear = selectedDate.getFullYear();
    let newMonth = selectedDate.getMonth() + 1;
    let newDay = selectedDate.getDate();

    if (this.state.dateSelectMode === 1) //시작 날짜 선택인경우
    {
      const tempDate1 = parseInt(newYear + NumberFormatString(newMonth) + NumberFormatString(newDay));
      const tempDate2 = parseInt(this.selectedYear + NumberFormatString(this.selectedMonth) + NumberFormatString(this.selectedDay));

      //시작 날짜가 끝날짜보다 뒤인경우
      if (tempDate1 > tempDate2)
      {
        this.setState((prevState) => ({...prevState, dateSelect : false}));
  
        alert("해당 일자는 조회할 수 없습니다.");
        return;
      }

      this.startDateSelected = true; //시작날짜가 수동으로 선택되었다고 표시
      this.selectedYear1 = newYear;
      this.selectedMonth1 = newMonth;
      this.selectedDay1 = newDay;
    }
    else //끝날짜 선택인경우
    {
      let today = new Date(this.todayYear, this.todayMonth - 1, this.todayDay, 0, 0, 0);
      let newDate = new Date(newYear, newMonth - 1, newDay, 0, 0, 0);
  
      let dateDiff = (newDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
  
      if (dateDiff > 1)
      {
        this.setState((prevState) => ({...prevState, dateSelect : false}));
  
        alert("해당 일자는 조회할 수 없습니다.");
        return;
      }

      this.selectedYear = newYear;
      this.selectedMonth = newMonth;
      this.selectedDay = newDay;

      //따로 선택된 시작 일자가 없으면
      if (!this.startDateSelected || (this.selectedYear < this.selectedYear1 || this.selectedMonth < this.selectedMonth1 || this.selectedDay < this.selectedDay1))
      {
        this.startDateSelected = false;
        this.selectedYear1 = newYear;
        this.selectedMonth1 = newMonth;
        this.selectedDay1 = newDay;
      }
    }
    //---------------------------------
    
    if (this.selectedYear === this.selectedYear1 && this.selectedMonth === this.selectedMonth1 && this.selectedDay === this.selectedDay1)
      this.dateString = selectedDate.getFullYear() + "-" + NumberFormatString(selectedDate.getMonth() + 1) + "-" + NumberFormatString(selectedDate.getDate());
    else
      this.dateString = `${this.selectedYear1}-${NumberFormatString(this.selectedMonth1)}-${NumberFormatString(this.selectedDay1)} ~ ${this.selectedYear}-${NumberFormatString(this.selectedMonth)}-${NumberFormatString(this.selectedDay)}`;

    if (this.reportMode === 0)
      this.setState((prevState) => ({...prevState, dateSelect : false, startDate : this.getDayInfoString1(), selectedDate : this.getDayInfoString2(), dateRange : this.dateString}));
    else
      this.setState((prevState) => ({...prevState, dateSelect : false,startDate : this.getDayInfoString1(), selectedDate : this.getDayInfoString2(), 
        dateRange : `${this.dateString}(${this.selectedSalesName})`}));

    this.isTodayOrder = !this.isPrevDay() && !this.isNextDay();

    this.firstIdx = -1;
    this.lastIdx = -1;
    this.totalOrderCount = 0;
    OrderStoreInstance.clearReportInfo();
    OrderStoreInstance.clearDailyOrder();

    this.loadReport(this.state.deliveryMode);
  }

  getDateForReport = () =>
  {
    let tomorrow = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay, 0, 0, 0);

    tomorrow.setDate(tomorrow.getDate() + 1);

    let result = `${tomorrow.getFullYear()}년 ${NumberFormatString(tomorrow.getMonth() + 1)}월 ${NumberFormatString(tomorrow.getDate())}일 담당자 : `;


    if (this.reportMode === 0)
      result += LoginStoreInstance.getUserName;
    else
      result += this.selectedSalesName;

    if (this.deliMode === 0)
    {
      if (this.state.deliveryMode === 0)
        result += ", 기본배송";
      else
        result += ", 추가배송";
    }
    else
    {
      if (this.state.deliveryMode === 0)
        result += ", 기본배송";
      else
        result += ", 추가배송";

      switch(this.deliMode)
      {
        case 1:
          result += "1";
          break;
        case 2:
          result += "2";
          break;
        case 3:
          result += "3";
          break;
        default:
          break;
      }
    }
    return result;
  }

  //인쇄 페이지 생성하기
  generatePrintPage = () =>
  {
    const orderList = OrderStoreInstance.getDailyOrder;

    if (orderList == null || orderList.length < 1)
    {
      return "비어있음";
    }

    let tableItem = "";
    let totalItem = "";
    
    if (this.deliMode === 0)
    {
      if (!this.totalInfoOnly)
      {
        //업체마다 테이블을 추가하는 방식
        for (let i = 0;i < orderList.length; ++i)
        {
          let curItems = ItemListToTag(orderList[i].items, this.state.printOption);
          tableItem += String.format(PrintTable, orderList[i].orderType === 0 ? orderList[i].cname : `${orderList[i].cname}(수기)`, curItems, "");
        }
      }

      if (IsValidV(OrderStoreInstance.dailyReport) && IsValidS(OrderStoreInstance.dailyReport.total))
      {
        let firstInfo = true;
        //소주 합계
        let currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.total, this.state.printOption, 1, "-------소주-------", true); //소주
        

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }

        currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.total, this.state.printOption, 2, "-------맥주-------", firstInfo); //맥주

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }

        currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.total, this.state.printOption, 3, "-------수입맥주-------", firstInfo); //수입맥주

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }

        currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.total, this.state.printOption, 4, "-------생맥주-------", firstInfo); //생맥주

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }

        currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.total, this.state.printOption, -1, "-------기타-------", firstInfo); //기타

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }
      }

      if (this.totalInfoOnly)
      {
        return String.format(PrintInfoTotalOnly, 
          this.getDateForReport(), totalItem, `박스 총합: ${OrderStoreInstance.totalBoxOrderCount}박스, 병 총합 : ${OrderStoreInstance.totalBottleOrderCount}병`);
      }
      else
      {
        if (OrderStoreInstance.totalInfocheck)
        {
          return String.format(PrintInfo, 
            this.getDateForReport(), '', totalItem, tableItem, `박스 총합: ${OrderStoreInstance.totalBoxOrderCount}박스, 병 총합 : ${OrderStoreInstance.totalBottleOrderCount}병`);
        }
        else
        {
          return String.format(PrintInfoNoTotal, 
            this.getDateForReport(), '', `박스 총합: ${OrderStoreInstance.totalBoxOrderCount}박스, 병 총합 : ${OrderStoreInstance.totalBottleOrderCount}병`, tableItem);
        }
      }
    }
    else //특정회차의 배송만 출력
    {
      if (LOG_ENABLE)
      {
        console.log(`generate deli mode list`);
      }

      if (!this.totalInfoOnly)
      {
        for (let i = 0;i < orderList.length; ++i)
        {
          if (orderList[i].printType === this.deliMode)
          {
            let curItems = ItemListToTag(orderList[i].items, this.state.printOption);
            tableItem += String.format(PrintTable, orderList[i].orderType === 0 ? orderList[i].cname : `${orderList[i].cname}(수기)`, curItems, "");
          }
        }
      }
      
      if (IsValidV(OrderStoreInstance.dailyReport))
      {
        //토탈 정보를 산출하고
        OrderStoreInstance.generateDeliTotalInfo(this.deliMode);

        let firstInfo = true;
        //소주 합계
        let currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.deliTotal, this.state.printOption, 1, "-------소주-------", true); //소주
        

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }

        currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.deliTotal, this.state.printOption, 2, "-------맥주-------", firstInfo); //맥주

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }

        currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.deliTotal, this.state.printOption, 3, "-------수입맥주-------", firstInfo); //수입맥주

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }

        currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.deliTotal, this.state.printOption, 4, "-------생맥주-------", firstInfo); //생맥주

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }

        currentTotal = ItemListToTagSpecial(OrderStoreInstance.dailyReport.deliTotal, this.state.printOption, -1, "-------기타-------", firstInfo); //기타

        if (currentTotal !== null)
        {
          totalItem += currentTotal;
          firstInfo = false;
        }
      }

      if (this.totalInfoOnly)
      {
        return String.format(PrintInfoTotalOnly, 
          this.getDateForReport(), totalItem, `박스 총합: ${OrderStoreInstance.totalBoxOrderCountDeli}박스, 병 총합 : ${OrderStoreInstance.totalBottleOrderCountDeli}병`);
      }
      else
      {
        if (OrderStoreInstance.totalInfocheck)
        {
          return String.format(PrintInfo, 
            this.getDateForReport(), '', totalItem, tableItem, `박스 총합: ${OrderStoreInstance.totalBoxOrderCountDeli}박스, 병 총합 : ${OrderStoreInstance.totalBottleOrderCountDeli}병`);
        }
        else
        {
          return String.format(PrintInfoNoTotal, 
            this.getDateForReport(), '', `박스 총합: ${OrderStoreInstance.totalBoxOrderCountDeli}박스, 병 총합 : ${OrderStoreInstance.totalBottleOrderCountDeli}병`, tableItem);
        }
      }
    }
  }

  generatePrintStyle = () =>
  {
    if (LOG_ENABLE)
      console.log(`print font size : ${OrderStoreInstance.printFontSize}`);
    //return String.format(PrintStyle, '#797979', '#797979');;
    return String.format(PrintStyle, '#797979', 'black', OrderStoreInstance.printFontSize);
  }
  
  //판매일보출력
  handleReportPrint = (totalOnly) =>
  {
    if (LOG_ENABLE)
      console.log("handleReportPrint");

    this.deliMode = 0;
    this.totalInfoOnly = totalOnly;

    this.startPrint();
  }

  handlePrintDeli = (deliMode) =>
  {
    this.deliMode = deliMode;
    this.totalInfoOnly = false;

    const orderList = OrderStoreInstance.getDailyOrder;

    if (orderList == null || orderList.length < 1)
    {
      alert(`출력할 내용이 없습니다.`);
      return;
    }

    let found = false;

    //먼저 해당회차의 주문의 개수를 파악하기
    for (let i = 0;i < orderList.length; ++i)
    {
      if (orderList[i].printType === this.deliMode)
      {
        found = true;
        break;
      }
    }

    if (!found)
    {
      switch(deliMode)
      {
        case 1:
          alert('배송1에 출력할 주문이 없습니다.\n배송1로 출력할 업체를 선택해 주세요.');
          break;
        case 2:
          alert('배송2에 출력할 주문이 없습니다.\n배송2로 출력할 업체를 선택해 주세요.');
          break;
        case 3:
          alert('배송3에 출력할 주문이 없습니다.\n배송3로 출력할 업체를 선택해 주세요.');
          break;
        default:
          break;
      }
      return;
    }

    this.startPrint();
  }

  handlePrintDeliTotal = (deliMode) =>
  {
    this.deliMode = deliMode;
    this.totalInfoOnly = true;
    
    const orderList = OrderStoreInstance.getDailyOrder;

    if (orderList == null || orderList.length < 1)
    {
      alert(`출력할 내용이 없습니다.`);
      return;
    }

    let found = false;

    //먼저 해당회차의 주문의 개수를 파악하기
    for (let i = 0;i < orderList.length; ++i)
    {
      if (orderList[i].printType === this.deliMode)
      {
        found = true;
        break;
      }
    }

    if (!found)
    {
      switch(deliMode)
      {
        case 1:
          alert('배송1에 출력할 주문이 없습니다.\n배송1로 출력할 업체를 선택해 주세요.');
          break;
        case 2:
          alert('배송2에 출력할 주문이 없습니다.\n배송2로 출력할 업체를 선택해 주세요.');
          break;
        case 3:
          alert('배송3에 출력할 주문이 없습니다.\n배송3로 출력할 업체를 선택해 주세요.');
          break;
        default:
          break;
      }
      return;
    }

    this.startPrint();
  }

  startPrint = () =>
  {
    const orderList = OrderStoreInstance.getDailyOrder;

    if (orderList == null || orderList.length < 1)
    {
      alert("출력할 주문이 없습니다.");
      return;
    }

    if (OrderStoreInstance.orderInCompletedCount > 0)
    {
      alert(`${OrderStoreInstance.orderInCompletedCount}건의 처리되지 않은 음성 또는 미배송 주문이 있습니다. 먼저 모든 주문들을 처리해 주세요.`);
      return;
    }

    if (this.isToDay() && !this.checkOrderDoneTime() && !OrderStoreInstance.orderDone)
    {
      alert(`아직 주문이 마감되지 않았습니다. 판매일보를 출력하려면 주문 관리 페이지에서 먼저 주문을 마감해주세요.`);
      return;
    }

    if (this.isNextDay())
    {
      if (!window.confirm('조회된 주문은 내일 배송할 내역입니다. 주문 마감전까지 추가 주문이 더 있을 수 있으며, 주문 수정 또는 취소 될 수 있습니다. 그래도 판매일보를 출력할까요?'))
        return;
    }

    this.setLoading(true);
    //주문 개수를 조회해서 일치하는지 확인
    OrderStoreInstance.requestDailyOrderCount(this.selectedSalesIdx, this.getDayInfo1(), this.getDayInfo2(), this.state.deliveryMode, this.onOrderCountLoaded);
  }

  onOrderCountLoaded = (resultCode, currentTotalCount, modifiedCount) =>
  {
    if (LOG_ENABLE)
      console.log(`onOrderCountLoaded : ${resultCode}, ${currentTotalCount}, ${modifiedCount}`);

    if (resultCode !== 0){
      alert("주문 개수 조회중 오류가 발생했습니다.");

      this.setLoading(false);
      return;
    }

    if (currentTotalCount !== this.totalOrderCount || modifiedCount > 0)
    {
      alert("판매일보 조회후 주문에 변경사항이 접수 되었습니다. 주문 내용을 확인 후 다시 조회해 주세요.");

      this.setLoading(false);
      return;
    }

    //수기 주문 완료는 확인하지 않음
    this.setLoading(false);
    this.printReport();

    //미완료 수기주문의 개수구하기
    //MOrderStoreInstance.getUnCompletedOrderCount(this.selectedSalesIdx, this.getDayInfo1(), this.getDayInfo2(), this.onMOrderUCOCDone);
  }

  /*
  onMOrderUCOCDone = (resultCode, count) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('미완료 수기주문 개수 조회 중 오류가 발생했습니다.');
      return;
    }

    if (count !== 0)
    {
      alert('완료되지 않은 수기주문이 있습니다. 판매일보를 출력하고자 하는 기간에 수기주문들을 모두 완료처리해주세요.');
      return;
    }

    this.printReport();
  }*/

  printReport = () =>
  {
    const setting = "width=1133, height=900";
    const objWin = window.open('', 'print', setting);
    objWin.document.open();
    objWin.document.write('<html><head><title>일일판매일보</title>');
    objWin.document.write(this.generatePrintStyle());
    //objWin.document.write('<link rel="stylesheet" type="text/css" href="https://test.wizingame.com/judo/print.css"/>');
    objWin.document.write('</head><body onload="window.print()">');
    objWin.document.write(this.generatePrintPage());
    objWin.document.write('</body></html>');
    objWin.focus(); 
    objWin.document.close();
    
    //objWin.print();
    //objWin.close();
  }

  //배송완료 처리
  handleDelivery = (retailerIdx) => 
  {
    if (LOG_ENABLE)
      console.log("handleDelivery : " + retailerIdx);

    this.setLoading(true);
    OrderStoreInstance.requestDeliveryDone(retailerIdx, this.getDayInfo1(), this.getDayInfo2(), this.state.deliveryMode, this.onDeliveryDoneResult);
  }

  //수기 주문에 대한 배송 완료처리
  handleDeliveryMnuOrder = (retailerIdx) => 
  {
    if (LOG_ENABLE)
      console.log("handleDeliveryMnuOrder : " + retailerIdx);

    this.setLoading(true);
    OrderStoreInstance.requestDeliveryDone(retailerIdx, this.getDayInfo1(), this.getDayInfo2(), 2, this.onDeliveryDoneResult);
  }

  onDeliveryDoneResult = (resultCode) =>
  {
    if (LOG_ENABLE)
      console.log("onDeliveryDoneResult : " + resultCode);

    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      default:
        alert("배송 완료 처리가 실패 했습니다.");
        break;
    }
  }

  //오늘자 주문 배송 시작하기 버튼눌림
  handleDeliveryStart = () =>
  {
    if (LOG_ENABLE)
      console.log("handleDeliveryStart : ");

    if (OrderStoreInstance.orderInCompletedCount > 0)
    {
      alert(OrderStoreInstance.orderInCompletedCount + "건의 처리되지 않은 음성 또는 미배송 주문이 있습니다. 배송을 시작하기전, 먼저 모든 주문들을 처리해 주세요.");
      return;
    }

    if (this.isToDay() && !this.checkOrderDoneTime() && !OrderStoreInstance.orderDone)
    {
      alert(`아직 주문이 마감되지 않았습니다. 배송 시작전 주문 관리 페이지에서 주문을 마감해주세요.`);
      return;
    }

    if (this.isNextDay())
    {
      alert('조회된 주문은 내일 배송할 내역입니다.');
      return;
    }

    if (!window.confirm("주문 배송을 시작 할까요?"))
      return;

    OrderStoreInstance.startDelivery(this.selectedSalesIdx, this.getDayInfo1(), this.getDayInfo2(), this.firstIdx, this.lastIdx, this.totalOrderCount, 
      this.state.deliveryMode, this.state.deliveryMode === 0 ? OrderStoreInstance.manualOrderCount : 0, this.onStartDVResult);

      /*
    this.setLoading(true);

    MOrderStoreInstance.getUnCompletedOrderCount(this.selectedSalesIdx, this.getDayInfo1(), this.getDayInfo2(), this.onUCMOCCountForDeli);*/
  }

  //배송시작전 미완료 수기주문의 개수구하기
  /*onUCMOCCountForDeli = (resultCode, count) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert('미완료 수기 주문의 개수를 구하는 중 오류가 발생했습니다. 잠시 후 이용해 주세요.');
      return;
    }

    if (count !== 0)
    {
      this.setLoading(false);
      alert(`${count}건의 처리완료되지 않은 수기 주문이 있습니다. 해당 수기주문들을 모두 처리완료 해 주세요.`);
      return;
    }

    OrderStoreInstance.startDelivery(this.selectedSalesIdx, this.getDayInfo1(), this.getDayInfo2(), this.firstIdx, this.lastIdx, this.totalOrderCount, 
      this.state.deliveryMode, this.state.deliveryMode === 0 ? OrderStoreInstance.manualOrderCount : 0, this.onStartDVResult);
  }*/

  onStartDVResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("배송 시작 권한이 없습니다.");
        break;
      case 4:
        alert("추가 주문이 있습니다. 추가 주문을 확인하고 판매일보를 다시 조회해 주세요.");
        break;
      case 5:
        alert("이용자가 주문을 수정했습니다. 판매일보를 다시 조회해 주세요.");
        break;
      default:
        alert("배송 시작 설정이 실패 했습니다.");
        break;
    }
  }

  //영업담당자 바뀜
  handleSalesSelection = (salesIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleSalesSelection : " + salesIdx);

    const sales = StaffStoreInstance.findSalesFromSalesManagerByIdx(salesIdx);

    if (sales === null)
    {
      if (LOG_ENABLE)
      {
        console.log(`sales not found : ${salesIdx}`);
      }
      return;
    }

    this.selectedSalesIdx = sales.idx;
    this.selectedSalesName = sales.uname;

    this.setState((prevState) => ({...prevState, dateRange : `${this.dateString}(${this.selectedSalesName})`}));

    OrderStoreInstance.clearDailyOrder();
    OrderStoreInstance.clearReportInfo();
    this.lastIdx = -1;
    this.firstIdx = -1;
    this.totalOrderCount = 0;
    
    this.loadReport(this.state.deliveryMode);
  }

  handleDeliveriMode = (mode) =>
  {
    if (LOG_ENABLE)
      console.log(`handleDeliveriMode : ${mode}`);

    if (this.state.loading)
    {
      return;
    }

    this.setState((prevState) => ({...prevState, deliveryMode : mode}));

    this.firstIdx = -1;
    this.lastIdx = -1;
    this.totalOrderCount = 0;
    OrderStoreInstance.clearReportInfo();
    OrderStoreInstance.clearDailyOrder();

    this.loadReport(mode);
  }

  handleLogout = () => {
    ProcessLogout();
    this.props.history.push("/");
  }
  
  handlePrintOption = (opt) =>{
    this.setState((prevState) => ({...prevState, printOption : opt}));
  }

  handleOption = () =>
  {
    this.setState((prevState) => ({...prevState, printFontOption : true}));
  }

  handleCloseOption = () =>
  {
    this.setState((prevState) => ({...prevState, printFontOption : false}));
  }

  handleDeliMode = (deliMode, order) =>
  {
    if (LOG_ENABLE)
      console.log(`handleDeliMode : ${deliMode}`);

    OrderStoreInstance.setDailyOrderDeliNumber(order, deliMode);
  }

  render() {
    const orderList = OrderStoreInstance.getDailyOrder; //일일주문 목록 전체

    let list = null;

    if (orderList !== null)
    {
      if (mobileOn)
      {
        list = (<ReportListmb items = {orderList} handleDelivery={this.handleDelivery} 
          handleDeliveryMnuOrder={this.handleDeliveryMnuOrder}
          handleDeliMode={this.handleDeliMode}
          delis={OrderStoreInstance.deliveryStarted} popt={this.state.printOption}/>);
      }
      else
      {
        if (OrderStoreInstance.deliveryStarted)
          list = (<ReportListDeli items = {orderList} handleDelivery={this.handleDelivery} handleDeliveryMnuOrder={this.handleDeliveryMnuOrder} popt={this.state.printOption} handleDeliMode={this.handleDeliMode}/>);
        else
          list = (<ReportList items = {orderList} popt={this.state.printOption} handleDeliMode={this.handleDeliMode}/>);
      }
    }

    let subMsg = '';

    if (mobileOn)
    {
      if (this.isPrevDay())
      subMsg = "(지난일자)";
    else if (this.isNextDay())
      subMsg = "(내일배송)";
    else
    {
      if (this.checkOrderDoneTime() || OrderStoreInstance.orderDone)
      {
        subMsg = "(오늘배송. 주문마감됨)";
      }
      else
        subMsg = `(오늘배송. 주문마감전)`;
    }

      return (
        <div className="appcontainer">
          
          {this.state.dateSelect ? <DatePick handleDate = {this.handleDate} cyear={this.state.dateSelectMode === 0 ? this.selectedYear : this.selectedYear1}
            cmonth={this.state.dateSelectMode === 0 ? this.selectedMonth : this.selectedMonth1}
            cday={this.state.dateSelectMode === 0 ? this.selectedDay : this.selectedDay1}/> : null}
  
          {
            this.state.loading ? <Loading />:null
          }
  
          {
            this.state.printFontOption ? (<ReportFontOptionmb handleClose = {this.handleCloseOption}/>) : null
          }

          <div className="maincontainermb">
            <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout = {this.handleLogout}/>
            
            <div className="rptdateareamb">
              <div className="rptdatetitlemb flexcenterrowstart">
                <img src={GetImgUrl("mbrpts.png")} alt="판매일보" />
                <label className="mfontnb">&nbsp;{`판매일보(마감시간 ${LoginStoreInstance.getOrderDoneTime()})`}</label>
              </div>
  
              <div className="flexcentercol rptdatecontmb">
                <label className="mfontnwb">&nbsp;날짜별 판매 일보 조회</label>
  
                <div className="cntfullh flexcenterrow rptdatearea2mb">
                  <div className="rptdateselarea flexcenterrow" onClick={this.handleStartDateSelect}>
                    <label className="btncur fontnb">{this.state.startDate}</label>
                  </div>
                  
                  <label className="fontnwb">~</label>
    
                  <div className="rptdateselarea flexcenterrow" onClick={this.handleDateSelect}>
                    <label className="btncur fontnb">{this.state.selectedDate}</label>
                  </div>
                </div>
              </div>
            </div>

            {
              this.reportMode !== 0 ? (
                <ReportSalesSelmb handleSalesSelection={this.handleSalesSelection} 
                  sales={LoginStoreInstance.getUserLevel === 3 ?  StaffStoreInstance.managedSalesList : StaffStoreInstance.salesManagerList}
                />
              )
              : null
            }

            <div className="rptdelitype flexcentercol">
              <div className="rptdelitypesel flexcenterrowstart">
                <input type="radio" onChange= {(e) => this.handleDeliveriMode(0)} checked = {this.state.deliveryMode === 0} className="pdcheck"/>
                <label className="mfontlargeb">기본배송 조회&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <input type="radio" onChange= {(e) => this.handleDeliveriMode(1)} checked = {this.state.deliveryMode === 1} className="pdcheck"/>
                <label className="mfontlargeb">추가배송 조회&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
              </div>
              
              <div className="empline"/>
            </div>
  
            <div className="rptdelitype flexcentercol">
              <div className="rptdelitypesel flexcentercol">
                <div className="flexcenterrowstart cntfullh marginbottom5">
                  <input type="radio" onChange= {(e) => this.handlePrintOption(0)} checked = {this.state.printOption === 0} className="pdcheck"/>
                  <label className="mfontnb">단축명있는 상품은 상품코드,용량 표시안함</label>
                </div>

                <div className="flexcenterrowstart cntfullh marginbottom5">
                  <input type="radio" onChange= {(e) => this.handlePrintOption(1)} checked = {this.state.printOption === 1} className="pdcheck"/>
                  <label className="mfontnb">상품코드,용량 항상 표시안함</label>  
                </div>

                <div className="flexcenterrowstart cntfullh marginbottom5">
                  <input type="radio" onChange= {(e) => this.handlePrintOption(2)} checked = {this.state.printOption === 2} className="pdcheck"/>
                  <label className="mfontnb">상품코드, 용량 항상 표시</label>  
                </div>

                <div className="flexcenterrowstart cntfullh marginbottom5">
                  <input type="radio" onChange= {(e) => this.handlePrintOption(3)} checked = {this.state.printOption === 3} className="pdcheck"/>
                  <label className="mfontnb">상품명,용량,개수만 표시</label>  
                </div>

                <div className='flexcenterrowstart cntfullh margintop10'>
                  
                </div>
                
                <div className='flexevenrow cntfullh margintop10'>
                  <button className="bgcolor4 rptprintfontsizemb mfontnwb" onClick={this.handleOption}>
                    출력 설정
                  </button>

                  <button className="bgcolor1 rptprintfontsizemb mfontnwb" onClick={() => this.handleReportPrint(true)}>
                    합계만 출력
                  </button>  

                  <button className="bgcolor1 rptprintbtnmb2 mfontnwb" onClick={() => this.handleReportPrint(false)}>
                    전체 출력
                  </button>  
                </div>

              </div>
              
              <div className="empline"/>
            </div>

            <div className="empsercon">
              
              <div className="empsertitle flexcenterrowstart">
                <div className="orderserl flexcenterrowstart">
                  <img src={GetImgUrl("mbrptpp.png")} alt="판매일보 조회" />
                  <label className="mfontnb">&nbsp;{this.state.deliveryMode === 0 ? "판매일보 기본배송 조회" : "판매일보 추가배송 조회"}</label>
                  {/*<label className="rptsubmsgmb">{subMsg}</label>*/}
                </div>
  
                <div className="orderserr flexcenterrowend">
                  
                </div>
              </div>

              <div className="empline"/>
  
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("mbrptday.png")} alt="조회 일자" />
                <label className="mfontnb">&nbsp;{this.state.dateRange}</label>
              </div>
            </div>
  
            {
              list ? list : (
                <label className="mfontlargeb">주문 정보가 없습니다.</label>
              )
            }
  
            <div className='cntfullh flexcenterrow marginbottom20 margintop20'>
              <button className="repdeliselbtnmb mfontnwb bgcolor3 btncur marginright15" onClick={() => this.handlePrintDeli(1)}>배송1 출력</button>
              <button className="repdeliselbtnmb mfontnwb bgcolor6 btncur marginright15" onClick={() => this.handlePrintDeli(2)}>배송2 출력</button>
              <button className="repdeliselbtnmb mfontnwb bgcolor7 btncur" onClick={() => this.handlePrintDeli(3)}>배송3 출력</button>
            </div>

            <div className='cntfullh flexcenterrow marginbottom20'>
              <button className="repdeliselbtnmb mfontnwb bgcolor31 btncur marginright15" onClick={() => this.handlePrintDeliTotal(1)}>배송1 합계</button>
              <button className="repdeliselbtnmb mfontnwb bgcolor61 btncur marginright15" onClick={() => this.handlePrintDeliTotal(2)}>배송2 합계</button>
              <button className="repdeliselbtnmb mfontnwb bgcolor71 btncur" onClick={() => this.handlePrintDeliTotal(3)}>배송3 합계</button>
            </div>

            <div className="repbottom flexcentercol">
              {
                (!OrderStoreInstance.deliveryStarted && !this.state.loading && orderList) ? 
              (<button className="bgcolor3 repdelisbtnmb fontnwb" onClick={this.handleDeliveryStart}>
                배송시작
              </button>) : null
              }
            </div>
          </div>
        </div>
      );
    }
    else
    {
      if (this.isPrevDay())
        subMsg = " - (지난 일자 주문입니다.)";
      else if (this.isNextDay())
        subMsg = " - (내일 배송할 주문입니다.)";
      else
      {
        if (this.checkOrderDoneTime() || OrderStoreInstance.orderDone)
        {
          subMsg = " - (오늘 배송할 주문입니다. 주문 마감됨)";
        }
        else
          subMsg = ` - (오늘 배송할 주문입니다. 주문 마감전)`;
      }

      return (
        <div className="appcontainer">
          
          {
            this.state.printFontOption ? (<ReportFontOption handleClose = {this.handleCloseOption}/>) : null
          }

          {this.state.dateSelect ? <DatePick handleDate = {this.handleDate} cyear={this.state.dateSelectMode === 0 ? this.selectedYear : this.selectedYear1}
            cmonth={this.state.dateSelectMode === 0 ? this.selectedMonth : this.selectedMonth1}
            cday={this.state.dateSelectMode === 0 ? this.selectedDay : this.selectedDay1}/> : null}
  
          {
            this.state.loading ? <Loading />:null
          }
  
          <div className="maincontainer">
            <div className="empmargin"/>
            <MainMenu index={4} handleMenuClick = {this.handleMenuClick}/>
            
            <div className="empsercon">
              <div className="ordersertitle flexcenterrowstart">
                
                <div className="orderserl flexcenterrowstart">
                  <img src={GetImgUrl("papers.png")} alt="판매일보" />
                  <label className="fontlargeb">&nbsp;{`판매일보(마감시간 ${LoginStoreInstance.getOrderDoneTime()})`}</label>
                </div>
  
                {/*<label className="devfont">&nbsp;&nbsp;영업담당자 외의 계정으로 접속했다고 가정, 영업담당자 본인의 경우 아래의 담당자 선택창이 없고 배송 관련 버튼 이용가능합니다.</label>*/}
  
                <div className="orderserr flexcenterrowend"/>
                
              </div>
  
              <div className="empsercontent flexcenterrowstart">
                <img src={GetImgUrl("search.png")} alt="날짜별판매일보" className="empimg"/>
                <label className="fontnwb">&nbsp;날짜별 판매 일보 조회</label>
  
                <div className="rptdateselarea flexcenterrow" onClick={this.handleStartDateSelect}>
                  <label className="btncur fontnb">{this.state.startDate}</label>
                </div>
                
                <label className="fontnwb">~</label>
  
                <div className="rptdateselarea flexcenterrow" onClick={this.handleDateSelect}>
                  <label className="btncur fontnb">{this.state.selectedDate}</label>
                </div>
  
                {/*
                  <button className="orderdatesel fontnb" onClick={this.handleDateSelect}>
                  조회
                </button>
                */}
  
                {
                  this.reportMode !== 0 ? (
                    <ReportSalesSel handleSalesSelection={this.handleSalesSelection} 
                      sales={LoginStoreInstance.getUserLevel === 3 ?  StaffStoreInstance.managedSalesList : StaffStoreInstance.salesManagerList}
                    />
                  ) : null
                }
              </div>
            </div>
  
            <div className="rptdelitype flexcentercol">
              <div className="rptdelitypesel flexcenterrowstart marginbottom5">
                <input type="radio" onChange= {(e) => this.handleDeliveriMode(0)} checked = {this.state.deliveryMode === 0} className="pdcheck"/>
                <label className="fontlargeb">기본배송 조회&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <input type="radio" onChange= {(e) => this.handleDeliveriMode(1)} checked = {this.state.deliveryMode === 1} className="pdcheck"/>
                <label className="fontlargeb">추가배송 조회&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
              </div>
              
              <div className="empline"/>
            </div>
  
            <div className="rptdelitype flexcentercol">
              <div className="rptdelitypesel flexcenterrowstart marginbottom5">
                <input type="radio" onChange= {(e) => this.handlePrintOption(0)} checked = {this.state.printOption === 0} className="pdcheck"/>
                <label className="mfontlargeb">단축명있는 상품은 상품코드,용량 표시안함&nbsp;&nbsp;&nbsp;&nbsp;</label>
                
                <input type="radio" onChange= {(e) => this.handlePrintOption(1)} checked = {this.state.printOption === 1} className="pdcheck"/>
                <label className="mfontlargeb">상품코드,용량 항상 표시안함&nbsp;&nbsp;&nbsp;&nbsp;</label>

                <input type="radio" onChange= {(e) => this.handlePrintOption(2)} checked = {this.state.printOption === 2} className="pdcheck"/>
                <label className="mfontlargeb">상품코드, 용량 항상 표시&nbsp;&nbsp;&nbsp;&nbsp;</label>

                <input type="radio" onChange= {(e) => this.handlePrintOption(3)} checked = {this.state.printOption === 3} className="pdcheck"/>
                <label className="mfontlargeb">상품명,용량, 개수만 표시&nbsp;&nbsp;&nbsp;&nbsp;</label>
              </div>
              
              <div className="empline"/>
            </div>

            <div className="empsercon">
              
              <div className="empsertitle flexcenterrowstart">
                <div className="orderserl flexcenterrowstart">
                  <img src={GetImgUrl("papersl.png")} alt="판매일보 조회" />
                  <label className="fontlargeb">&nbsp;{this.state.deliveryMode === 0 ? "판매일보 기본배송 조회" : "판매일보 추가배송 조회"}</label>
                  <label className="rptsubmsg">{subMsg}</label>
                </div>
  
                <div className="orderserr flexcenterrowend">
                  
                  <button className="bgcolor4 orderrepbtn fontnwb" onClick={this.handleOption}>
                    출력설정
                  </button>  
                  
                  <button className="bgcolor1 orderrepbtn fontnwb" onClick={() => this.handleReportPrint(true)}>
                    합계만 출력
                  </button>  

                  <button className="bgcolor1 orderrepbtn fontnwb" onClick={() => this.handleReportPrint(false)}>
                    전체 출력
                  </button>  
                </div>
              </div>
              
              <div className="empline"/>
  
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("day.png")} alt="조회 일자" />
                <label className="fontlargeb">&nbsp;{this.state.dateRange}</label>
              </div>
            </div>
  
            {
              list ? list : (
                <label className="fontlargeb">주문 정보가 없습니다.</label>
              )
            }
  
            <div className='cntfullh flexcenterrowend marginbottom20'>
              <button className="repdeliselbtn3 fontnwb bgcolor3 btncur marginright15" onClick={() => this.handlePrintDeli(1)}>배송1 출력</button>
              <button className="repdeliselbtn3 fontnwb bgcolor6 btncur marginright15" onClick={() => this.handlePrintDeli(2)}>배송2 출력</button>
              <button className="repdeliselbtn3 fontnwb bgcolor7 btncur" onClick={() => this.handlePrintDeli(3)}>배송3 출력</button>
            </div>

            <div className='cntfullh flexcenterrowend marginbottom20'>
              <button className="repdeliselbtn3 fontnwb bgcolor31 btncur marginright15" onClick={() => this.handlePrintDeliTotal(1)}>배송1 합계 출력</button>
              <button className="repdeliselbtn3 fontnwb bgcolor61 btncur marginright15" onClick={() => this.handlePrintDeliTotal(2)}>배송2 합계 출력</button>
              <button className="repdeliselbtn3 fontnwb bgcolor71 btncur" onClick={() => this.handlePrintDeliTotal(3)}>배송3 합계 출력</button>
            </div>

            <div className="repbottom flexcentercol">
              {
                (!OrderStoreInstance.deliveryStarted && !this.state.loading && orderList) ? 
              (<button className="bgcolor3 repdelisbtn fontnwb" onClick={this.handleDeliveryStart}>
                배송시작
              </button>) : null
              }
            </div>
          </div>
        </div>
      );
    }
  }  
}

export default observer(Report);