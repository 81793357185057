import { observer } from 'mobx-react';
import React from 'react';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { RetailerStoreInstance } from '../../Stores/RetailerStore';
import { ProcessLogout } from '../../Stores/StoreManager';
import { GetImgUrl, LOG_ENABLE, mobileOn } from '../../URL/AppUrl';
import PageList from '../PageList';
import NoAppUserDetail from './NoAppUserDetail';
import NoAppUserInfo from './NoAppUserInfo';
import NoAppUserInfomb from './NoAppUserInfomb';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { IsValidS } from '../../Util/Util';

class NoAppUserList extends React.Component
{
  state = {
    loading: false,
    searchList : false,
    retIdx : -1,
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 0, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 0, //전체 페이지 개수
    showDetail : false,
  };

  searchMode = 0;
  detailInfo = null;

  setLoading = (loading) =>
  {
    this.setState((prevState) => ({...prevState, loading : loading}));

    if (this.props.setLoading)
      this.props.setLoading(loading);
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("RetBanList.componentDidMount");

    this.intervalId = setInterval(this.update, 1000);

    RetailerStoreInstance.clearNoAppList();

    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);
      return;
    }

    this.loadRetList(1);
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }

  calcPageInfo = () =>
  {
    let currentCount = RetailerStoreInstance.totalNoAppCount;

    let pageCount = Math.floor(currentCount / 10);

    if ((currentCount % 10) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = 10;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  onLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    this.loadRetList(1);
  }

  loadRetList = (page) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    RetailerStoreInstance.loadRetailer((page - 1) * 10, 2, null, this.onLoadListResult);
  }

  onLoadListResult = (resultCode, totalCount, isFirst) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert("목록 읽기 실패 입니다.");
      return;
    }

    if (isFirst)
    {
      this.calcPageInfo();
    }
  }

  //하단 페이지 변경 버튼 눌릴경우
  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4
  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (this.state.loading)
      return;

    if (pageNum === -1)
    {
      let endPage = 10;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadRetList(1);
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - 10;
      let endPage = this.state.endPage - 10;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadRetList(startPage);
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + 10;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadRetList(startPage);
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - 10;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadRetList(endPage);
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadRetList(pageNum);
    }
  }

  //검색어 바뀜
  onSearchChanged = (e) => {
    
    RetailerStoreInstance.setSearchWord(e.target.value);
  }

  handleListMode = () =>
  {
    this.setState((prevState) => ({...prevState, searchList : false}));

    this.loadRetList(this.state.currentPage);
  }

  //업체 검색하기
  handleSearch = () =>
  {
    if (LOG_ENABLE)
      console.log("handleSearch");

    if (this.state.loading)
      return;

    if (!RetailerStoreInstance.isSearchWordValid())
    {
      if (this.searchMode === 0)
      {
        alert("검색 할 업체 이름을 입력하세요.");
        return;
      }
      else if (this.searchMode === 1)
      {
        alert("검색 할 업체 대표자명을 입력하세요.");
        return;
      }
      else if (this.searchMode === 2)
      {
        alert("검색 할 업체 사업자번호를 입력하세요.");
        return;
      }
      else
      {
        alert("검색 할 업체 전화번호를 입력하세요.");
        return;
      }
    }

    this.setState((prevState) => ({...prevState, searchList : true}));
    this.setLoading(true);
    
    RetailerStoreInstance.searchRetailer(this.searchMode, 2, LoginStoreInstance.getUserId, this.onSearchResult);
  }

  onSearchResult = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert("거래처 검색에 실패 했습니다.");
    }
  }

  handleDetail = (info) =>
  {
    this.detailInfo = info;
    
    this.setState((prevState) => ({...prevState, showDetail : true}));
  }

  handleCloseDetail = () =>
  {
    this.setState((prevState) => ({...prevState, showDetail : false}));
  }

  s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  handleExportExcel = () =>
  {
    if (LOG_ENABLE)
      console.log('handleExportExcel');

    if (this.props.appOn)
    {
      alert('주도 앱에서는 미설치 목록 출력이 제공되지 않습니다. PC또는 모바일 브라우저를 이용해 주세요.');
      return;
    }

    if (this.state.loading)
    {
      return;
    }

    if (RetailerStoreInstance.noAppListTotalDone)
      this.exportListToExcel();
    else
    {
      this.setLoading(true);
      RetailerStoreInstance.loadRetailer(RetailerStoreInstance.noAppListRefIdx, 3, null, this.onNoAppListTotalLoadDone);
    }
  }

  onNoAppListTotalLoadDone = (resultCode, readCount, notUsed) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert('미설치 업체 목록 조회중 오류가 발생했습니다. 잠시 후 다시 이용해 주세요.');
      return;
    }

    //목록을 모두 읽었다.
    if (readCount < 1)
    {
      this.setLoading(false);
      this.exportListToExcel();
      return;
    }

    //다음 목록 읽기를 시작한다.
    RetailerStoreInstance.loadRetailer(RetailerStoreInstance.noAppListRefIdx, 3, null, this.onNoAppListTotalLoadDone);
  }

  makeSheet = (workSheet, valueList) =>
  {
    workSheet.columns = [
      { header: '상호', key: 'cname'},
      { header: '대표자명', key: 'oname'},
      { header: '사업자번호', key: 'cnum'},
      { header: '전화번호', key: 'phone'},
      { header: '주소', key: 'addr'},
      { header: '영업담당자', key: 'sname'},
    ];

    let border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };

    let row = workSheet.getRow(1);
    let fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'ff797979'},
    };

    row.font = {bold : true, color : {argb: 'ffffffff'}};
    row.alignment = {vertical: 'center', horizontal: 'center'};
    //row.fill = fill;

    row.getCell('cname').border = border;
    row.getCell('oname').border = border;
    row.getCell('cnum').border = border;
    row.getCell('phone').border = border;
    row.getCell('sname').border = border;
    row.getCell('addr').border = border;

    row.getCell('cname').fill = fill;
    row.getCell('oname').fill = fill;
    row.getCell('cnum').fill = fill;
    row.getCell('phone').fill = fill;
    row.getCell('sname').fill = fill;
    row.getCell('addr').fill = fill;

    if (valueList.length > 0)
    {
      fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'fff9f9f9'},
      };

      for (let i = 0;i < valueList.length; ++i)
      {
        row = workSheet.getRow(i + 2);
        row.values = valueList[i];
        row.alignment = {vertical: 'center', horizontal: 'center'};
        row.getCell('cname').border = border;
        row.getCell('oname').border = border;
        row.getCell('cnum').border = border;
        row.getCell('phone').border = border;
        row.getCell('sname').border = border;
        row.getCell('addr').border = border;


        if ((i % 2) !== 0)
        {
          row.getCell('cname').fill = fill;
          row.getCell('oname').fill = fill;
          row.getCell('cnum').fill = fill;
          row.getCell('phone').fill = fill;
          row.getCell('sname').fill = fill;
          row.getCell('addr').fill = fill;
        }
      }
    }

    this.autoWidthSheet(workSheet, 15);
  }

  exportListToExcel = () =>
  {
    try
    {
      let temp = [];

      const workBook = new ExcelJS.Workbook();
      workBook.creator = 'Bravo6';
      workBook.created = new Date();
      workBook.modified = new Date();

      let salesList = [];

      RetailerStoreInstance.noAppListToArray(temp, salesList, null);

      let sheet = workBook.addWorksheet('전체목록', {properties:{tabColor:{argb:'FFFC0000'}, outlineLevelCol:1}});

      this.makeSheet(sheet, temp);

      if (IsValidS(salesList))
      {
        for (let i = 0;i < salesList.length; ++i)
        {
          temp = [];
          RetailerStoreInstance.noAppListToArray(temp, salesList, salesList[i]);

          sheet = workBook.addWorksheet(salesList[i], {properties:{outlineLevelCol:1}});

          this.makeSheet(sheet, temp);
        }
      }

      workBook.xlsx.writeBuffer().then(function (buffer){
        saveAs(new Blob([buffer], {type: "application/octet-stream"}), "N-돌핀미설치업체목록.xlsx");
      });
    }
    catch(e)
    {
      console.log(e.toString());
      alert('엑셀 파일 출력중 오류가 발생했습니다.');
    }
  }

  autoWidthSheet = (worksheet, minimalWidth = 10) => {
    worksheet.columns.forEach((column) => {
        let maxColumnLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            maxColumnLength = Math.max(
                maxColumnLength,
                minimalWidth,
                cell.value ? cell.value.toString().length : 0
            );
        });
        column.width = maxColumnLength + 2;
    });
  }

  render()
  {
    const retList = RetailerStoreInstance.getNoAppList;

    let ret = null;

    if (mobileOn)
    {
      if (retList !== null && retList.length > 0)
      {
        ret = retList.map(item => {
          return (<NoAppUserInfomb key={item.idx} index={item.idx} cname={item.cname} cphone={item.cphone} 
            oname={item.oname} cnum={item.cnum} sid={item.sid} pid={item.pid} sname={item.sname} pname={item.pname} addr={item.addr} selectIdx={this.state.retIdx}
            item = {item}
            handleDetail = {this.handleDetail}
            />);
        });
      }
  
      return (
        <div className="retbanpopcont flexcenterrow">
          {
            this.state.showDetail ? (
              <NoAppUserDetail handleClose = {this.handleCloseDetail} info = {this.detailInfo}/>
            ) : null
          }

          <div className="statsnoapppopmb flexcentercolstart">
  
            <div className="retbantitle flexcenterrow">
              <div className="dcell1"></div>
              
              <div className="dcell8 flexcenterrow">
                <label className="mfontlargeb">미설치 업체 리스트</label>
              </div>
              
              <div className="dcell1 flexcenterrow">
                <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={this.props.handleClose} className="btncur retbantopclosebtn"/>
              </div>
              
            </div>
  
            <div className="statssearchmb flexcenterrow">

              <select className="statssearchselmb mfontn btncur"
                onChange={(e) => {
                  if (LOG_ENABLE)
                    console.log(e.target.value);

                  this.searchMode = parseInt(e.target.value);
                }}
              >
                <option value="0">상호</option>
                <option value="1">대표자명</option>
                <option value="2">사업자번호</option>
                <option value="3">전화번호</option>
              </select>

              <input type="text" onChange={this.onSearchChanged} value = {RetailerStoreInstance.searchWord} className="statssearchinpmb mfontn" />

              <button className="statslistbtnmb mfontnwb  btncur" onClick={this.handleSearch}>
                검색
              </button>
              
              {
                this.state.searchList ? (
                  <button className="mfontnwb statslistbtnmb" onClick = {this.handleListMode}>
                    목록보기
                  </button>
                ):
                null
              }

            </div>

            {
              !this.props.appOn ? (
                <button className="statsexcelbtnmb fontnwb  btncur" onClick={this.handleExportExcel}>
                  엑셀출력
                </button>
              ) : null
            }

            <div className = "statstablecontmb">
              <table className="retbantable">
                <thead>
                  <tr>
                    <th className="emptableheader">
                      <label className="fontnw">업체명</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">상세정보</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {ret}
                </tbody>
              </table>
            </div>
  
            {
              ret === null ? (<label className="fontnb retbannolist">N-돌핀 앱 미설치 업체가 없습니다.</label>) : null
            }
            {
              this.state.pageCount > 1 && !this.state.searchList ? <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
    else
    {
      if (retList !== null && retList.length > 0)
      {
        ret = retList.map(item => {
          return (<NoAppUserInfo key={item.idx} index={item.idx} cname={item.cname} cphone={item.cphone} 
            oname={item.oname} cnum={item.cnum} sid={item.sid} pid={item.pid} sname={item.sname} pname={item.pname} addr={item.addr} selectIdx={this.state.retIdx}/>);
        });
      }
  
      return (
        <div className="retbanpopcont flexcenterrow">
          <div className="retbanpop flexcentercolstart">
            <div className="retbantopclose flexcenterrowend">
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={this.props.handleClose} className="btncur retbantopclosebtn"/>
            </div>
  
            <div className="retbantitle flexcenterrow">
              <label className="fontlargeb">미설치 업체 리스트</label>
            </div>
  
            <div className="retbansearch flexcenterrow">
              <div className="retbansearchleft flexcenterrowstart">
                <button className="statsexcelbtn fontnwb  btncur" onClick={this.handleExportExcel}>
                  엑셀출력
                </button>

                {
                  this.state.searchList ? (
                    <button className="fontnwb retlmbtn" onClick = {this.handleListMode}>
                      목록보기
                    </button>
                  ):
                  null
                }
  
              </div>
  
              <div className="retbansearchright flexcenterrowend">
                <select className="retbansearchsel fontn  btncur"
                  onChange={(e) => {
                    if (LOG_ENABLE)
                      console.log(e.target.value);
  
                    this.searchMode = parseInt(e.target.value);
                  }}
                >
                  <option value="0">상호</option>
                  <option value="1">대표자명</option>
                  <option value="2">사업자번호</option>
                  <option value="3">전화번호</option>
                </select>
  
                <input type="text" onChange={this.onSearchChanged} value = {RetailerStoreInstance.searchWord} className="retbansearchinp fontn" />
  
                <button className="retbansearchbtn fontnwb  btncur" onClick={this.handleSearch}>
                  검색
                </button>
              </div>  
            </div>
  
            <div className = "retbantablecont">
              <table className="retbantable">
                <thead>
                  <tr>
                    <th className="emptableheader">
                      <label className="fontnw">업체명</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">주소</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">대표자명</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">연락처</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">사업자번호</label>
                    </th>

                    <th className="emptableheader">
                      <label className="fontnw">담당자</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {ret}
                </tbody>
              </table>
            </div>
  
            {
              ret === null ? (<label className="fontnb retbannolist">N-돌핀 앱 미설치 업체가 없습니다.</label>) : null
            }
            {
              this.state.pageCount > 1 && !this.state.searchList ? <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
    
  }
}

export default observer(NoAppUserList);