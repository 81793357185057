import { GetImgUrl } from '../../URL/AppUrl';
import { GetFormattedCNum } from '../../Util/Util';

const NoAppUserDetail = (props) =>
{
  return (
    <div className="retbanpopcont flexcenterrow">
      <div className="statsnoappdetpopmb flexcentercolstart">

        <div className="retbantitle flexcenterrow">
          <div className="dcell1"></div>
          
          <div className="dcell8 flexcenterrow">
            <label className="mfontlargeb">상세 정보</label>
          </div>
          
          <div className="dcell1 flexcenterrow">
            <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={props.handleClose} className="btncur retbantopclosebtn"/>
          </div>
        </div>

        <div className="flexcenterrow statsdetinfomb">
          <label className="mfontn marginleft10">상호</label>
          <label className="mfontn marginright10">{props.info.cname}</label>
        </div>

        <div className="flexcenterrow statsdetinfomb">
          <label className="mfontn marginleft10">주소</label>
          <label className="mfontn marginright10">{props.info.addr}</label>
        </div>

        <div className="flexcenterrow statsdetinfomb">
          <label className="mfontn marginleft10">대표자명</label>
          <label className="mfontn marginright10">{props.info.oname}</label>
        </div>

        <div className="flexcenterrow statsdetinfomb">
          <label className="mfontn marginleft10">연락처</label>
          <label className="mfontn marginright10">{props.info.cphone === 'n' ? ' ' : props.info.cphone}</label>
        </div>

        <div className="flexcenterrow statsdetinfomb">
          <label className="mfontn marginleft10">사업자번호</label>
          <label className="mfontn marginright10">{GetFormattedCNum(props.info.cnum)}</label>
        </div>
        
        <div className="flexcenterrow statsdetinfomb">
          <label className="mfontn marginleft10">영업담당자</label>
          <label className="mfontn marginright10">{props.info.sname}</label>
        </div>

      </div>
    </div>
  );
}

export default NoAppUserDetail;