import { Fragment } from "react";
import { ODS_DELIE, ODS_REORDER, ODS_UNDELI } from "../../Stores/OrderStore";
import { GetImgUrl } from "../../URL/AppUrl";
import { GetDataTime, IsValidS } from "../../Util/Util";
import OrderItemListvo from "./OrderItemListvo";

function GetOrderTitle(order)
{
  if (order.st === ODS_UNDELI)
    return `APP주문(${GetDataTime(order.rdate)})`;
  
  return `APP주문`;
}

function GetCompanyName(order)
{
  if (order.ct === 0)
    return `${order.cname}(유흥)`;
  return `${order.cname}(일반)`;
}

const OrderApp = (props) =>
{
  return (
    <div className = "ordertablecont">
      <div className="empsertitle flexcenterrowstart">
        
        <div className="orderadditemcontl flexcenterrowstart">
          <img src={GetImgUrl("phonel.png")} alt="앱주문" />
          <label className={props.order.st === ODS_UNDELI ? "odundelititlefont" : "fontlargeb"}>&nbsp;{GetOrderTitle(props.order)}</label>
          <label className={props.order.st === ODS_UNDELI ? "rptsubmsg" : "rptsubmsgn"}>&nbsp;{`주문번호 ${props.order.idx}`}</label>
        </div>

        
        <div className="orderadditemcontr flexcenterrowend">
          {
            props.order.st === ODS_UNDELI ?
            (
              <Fragment>
                <button className="bgcolor3 orderundelibtn fontnwb" onClick={() => props.handleUndeliOrder(props.order.idx, 0)}>
                  당일 주문 처리
                </button>

                <button className="bgcolor4 orderadditembtn fontnwb" onClick={() => props.handleUndeliOrder(props.order.idx, 1)}>
                  익일 주문처리
                </button>
              </Fragment>
            ):(
              <Fragment>
                {
                  props.order.st !== ODS_DELIE && props.order.st !== ODS_REORDER ? (
                    <button className="bgcolor3 orderadditembtn fontnwb" onClick={() => props.handleMoveOrder(props.order.idx)}>
                      주문일자변경
                    </button>
                  ) : null
                }

                <button className="bgcolor3 orderadditembtn fontnwb" onClick={() => props.addItem(props.order.idx)}>
                  상품 추가하기
                </button>
              </Fragment>
            )
          }
        </div>
      </div>

      <table className="emptable">
        <thead>
          <tr>
            <th className="emptableheader">
              <label className="fontnw">업체명</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">대표자명</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">휴대폰</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">주소</label>
            </th>

            <th className="emptableheader">
              <label className="fontnw">영업 담당자</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="emptabledata">
            <td className="ordercellcname">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{GetCompanyName(props.order)}</label>
              </div>
            </td>
            
            <td className="ordercelluname">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{props.order.uname}</label>
              </div>
            </td>

            <td className="ordercellphone">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{props.order.phone}</label>
              </div>
            </td>

            <td className="emptablecell">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{props.order.addr}</label>
              </div>
            </td>

            <td className="ordercellsname">
              <div className="emptablecellin flexcenterrow">
                <label className="fontn">{props.order.sname}</label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {
        IsValidS(props.order.items) ? (
          <OrderItemListvo itemList = {props.order.items} order = {props.order}
            handleEditItem={props.handleEditItem} 
            handleDeleteItem={props.handleDeleteItem}
          />
        ) : null
      }
      

      {/*<div className="orderline"/>*/}
    </div>
  );
};

export default OrderApp;