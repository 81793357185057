import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import {LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { LoginStoreInstance } from './LoginStore';
import { FromBase64, IsValidS, IsValidV, ToBase64 } from '../Util/Util';

//직원 관련
class StaffStore {
  //observable변수
  searchWord = '';
  adminCount = 0; //어드민 계정 개수
  managerCount = 0; //일반 관리자 개수
  salesCount = 0; //영업담당자 개수
  salesManager = 0; //관리 담당자 개수

  empList = null; //조회된 직원 목록
  salesList = null; //직원중 영업담당자들의 목록

  salesManagerList = null; //직원중 영업담당자와 관리 담당자의 목록

  managedSalesList = null; //특정 관리담당자가 관리하는 영업담당자의 목록
  
  //비 observable
  totalCount = 0; //전체 직원의 수
  
  clearAll = () =>
  {
    this.searchWord = '';
    this.adminCount = 0; //어드민 계정 개수
    this.managerCount = 0; //일반 관리자 개수
    this.salesCount = 0; //영업담당자 개수
    this.salesManager = 0; //관리 담당자 개수
  
    this.empList = null; //조회된 직원 목록
    this.salesList = null; //직원중 영업담당자들의 목록
    this.salesManagerList = null;

    this.managedSalesList = null; //특정 관리담당자가 관리하는 영업담당자의 목록

    //비 observable
    this.totalCount = 0; //전체 직원의 수

    this.searchCallback = null;
    this.countCallback = null;
    this.loadCallback = null;
    this.addCallback = null;
    this.delCallback = null;
    this.editCallback = null;
    this.editStaffInfo = null;
    this.salesCallback = null;
    this.salesManagerCallback = null;

    this.managedSalesCallback = null;
    this.managedSalesEditCallback = null;
    this.lastManagerIdx = -1; //마지막 요청의 관리담당자 고유번호
    this.lastMSEditInfo = null; //마지막 요청의 편집정보
  }
  
  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      searchWord : observable,
      adminCount : observable,
      managerCount : observable,
      salesCount : observable,
      salesManager : observable,
      empList : observable,
      salesList : observable,
      salesManagerList : observable,
      managedSalesList: observable,

      clearAll : action,
      setSearchWord: action,
      clearEmpList : action,
      clearSalesList : action,
      searchStaff : action,
      parseSearchResult : action,

      parseCountResult : action,
      loadStaff : action,
      parseLoadResult : action,

      addStaff : action,
      parseAddResult : action,

      delStaff : action,
      parseDelResult : action,

      editStaff : action,
      parseEditResult : action,

      parseSalesResult : action,
      parseSalesManagerResult : action,
      getEmpList : computed,

      parseManagedSalesListResult : action,
      parseManagedSalesEditResult : action,
    });
  }
  
  //고유번호로 영업담당자 찾기
  findSalesByIdx = (staffIdx) =>
  {
    if (this.salesList === null)
      return null;

    for (let i = 0;i < this.salesList.length; ++i)
    {
      if (this.salesList[i].idx === staffIdx)
        return this.salesList[i];
    }

    return null;
  }

  getFirstSales = () =>
  {
    if (this.salesList === null || this.salesList.length < 1)
      return null;

    return this.salesList[0];
  }

  isSalesListValid = () =>
  {
    return IsValidS(this.salesList);
  }

  //조회된 직원 목록의 정보만 삭제한다.
  clearEmpList = () =>
  {
    this.empList = null; //직원 목록
  }

  clearSalesList = () =>
  {
    this.salesList = null;
  }

  //검색어 설정
  setSearchWord = (v) =>
  {
    this.searchWord = v;
  }

  //검색어가 올바른가?
  isSearchWordValid = () =>
  {
    return this.searchWord !== undefined && this.searchWord !== null && this.searchWord.length > 0;
  }

  //조회된 직원 목록
  get getEmpList()
  {
    return this.empList;
  }

  findUserByIdx = (userIdx) =>
  {
    if (this.empList == null || this.empList.length < 1)
      return null;

    for (let i = 0; i < this.empList.length; ++i)
    {
      if (this.empList[i].idx === userIdx)
        return this.empList[i];
    }

    return null;
  }
  //직원 검색하기-------------------------------------------------------------------------------------------------------------------------------------
  searchCallback = null;

  makeSearchParam (searchType, searchWord) {
    const params = new URLSearchParams();

    params.append("st", searchType.toString());
    params.append("sw", (ToBase64(searchWord)));

    return params;
  }

  searchStaff = (searchType, searchCallback) =>
  {
    this.searchCallback = searchCallback;

    if (LOG_ENABLE)
      console.log("seatchMode : " + searchType + ", sw : " + this.searchWord);

    axios({
      method:"POST",
      url: "staff/ser.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSearchParam(searchType, this.searchWord)
    }).then((res)=>{
        this.parseSearchResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseSearchResult(null);
    });
  }

  parseSearchResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseSearchResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.searchCallback != null)
        this.searchCallback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.searchCallback != null)
        this.searchCallback(result.ret);

      return;
    }

    this.empList = result.list;

    if (IsValidS(this.empList))
    {
      for (let i = 0;i < this.empList.length; ++i)
      {
        this.empList[i].uname = FromBase64(this.empList[i].uname);
      }
    }
    else
      this.empList = null;

    if (this.searchCallback != null)
      this.searchCallback(0);
  }
  //직원 계정별 개수 조회-------------------------------------------------------------------------------------------------------------------------------------
  countCallback = null;

  loadCount = (callback) =>
  {
    this.countCallback = callback;

    axios({
      method:"POST",
      url: "staff/cnt.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{
        this.parseCountResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseCountResult(null);
    });
  }

  parseCountResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseCountResult : " + JSON.stringify(result));

    if (!IsValidV(result))
    {
      if (this.countCallback != null)
        this.countCallback(false);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.countCallback != null)
        this.countCallback(false);

      return;
    }

    this.adminCount = (result.acnt); //어드민 계정 개수
    this.managerCount = (result.mcnt); //일반 관리자 개수
    this.salesCount = (result.scnt); //영업담당자 개수
    this.salesManager = (result.smcnt); //관리 담당자 개수
    this.totalCount = this.adminCount + this.managerCount + this.salesCount + this.salesManager;

    if (this.countCallback != null)
      this.countCallback(true);
  }
  //직원 목록 읽기-------------------------------------------------------------------------------------------------------------------------------------
  loadCallback = null;

  makeLoadParam (staffType, offset) {
    const params = new URLSearchParams();

    params.append("lt", staffType.toString());
    params.append("pos", offset.toString());

    return params;
  }

  loadStaff = (staffType, offset, callback) =>
  {
    this.loadCallback = callback;

    if (LOG_ENABLE)
      console.log("type = " + staffType + ", pos = " + offset);

    axios({
      method:"POST",
      url: "staff/get.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadParam(staffType, offset)
    }).then((res)=>{
        this.parseLoadResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseLoadResult(null);
    });
  }

  parseLoadResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseLoadResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadCallback != null)
        this.loadCallback(false);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.loadCallback != null)
        this.loadCallback(false);

      return;
    }

    this.empList = result.list;

    if (IsValidS(this.empList))
    {
      for (let i = 0;i < this.empList.length; ++i)
      {
        this.empList[i].uname = FromBase64(this.empList[i].uname);
      }
    }
    else
      this.empList = null;

    if (this.loadCallback != null)
        this.loadCallback(true);
  }
  //직원 추가-------------------------------------------------------------------------------------------------------------------------------------
  addCallback = null;
  lastAddLevel = 0;

  makeAddParam (id, pw, name, phone, email, at) {
    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("id", id);
    params.append("pw", pw);
    params.append("name", (ToBase64(name)));
    params.append("phone", phone);
    params.append("email", email);
    params.append("at", at.toString());

    return params;
  }

  addStaff = (id, pw, name, phone, email, at, callback) =>
  {
    this.addCallback = callback;
    this.lastAddLevel = at;

    axios({
      method:"POST",
      url: "staff/add.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeAddParam(id, pw, name, phone, email, at)
    }).then((res)=>{
        this.parseAddResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseAddResult(null);
    });
  }

  parseAddResult = (result) =>
  {
    if (LOG_ENABLE)
    console.log("parseAddResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.addCallback != null)
        this.addCallback(-2, this.lastAddLevel);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      switch(this.lastAddLevel)
      {
        case 0:
          ++this.adminCount;
          break;
        case 1:
          ++this.managerCount;
          break;
        case 2:
          ++this.salesCount;
          break;
        case 3:
          ++this.salesManager;
          break;
        default:
          break;
      }

      ++this.totalCount;
    }

    if (this.addCallback != null)
        this.addCallback(result.ret, this.lastAddLevel);
  }
  //직원 삭제-------------------------------------------------------------------------------------------------------------------------------------
  delCallback = null;
  lastDelLevel = -1;
  lastDelIdx = -1;

  makeDelParam (idx) {
    const params = new URLSearchParams();

    params.append("idx", idx.toString());
    return params;
  }

  delStaff = (idx,lastDelLevel, callback) =>
  {
    this.delCallback = callback;
    this.lastDelLevel = lastDelLevel;
    this.lastDelIdx = idx;

    axios({
      method:"POST",
      url: "staff/del.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeDelParam(idx)
    }).then((res)=>{
        this.parseDelResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseDelResult(null);
    });
  }

  parseDelResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseDelResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.delCallback != null)
        this.delCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      switch(this.lastDelLevel)
      {
        case 0:
          --this.adminCount;
          break;
        case 1:
          --this.managerCount;
          break;
        case 2:
          --this.salesCount;
          break;
        case 3:
          --this.salesManager;
          break;
        default:
          break;
      }

      --this.totalCount;

      let info = this.findUserByIdx(this.lastDelIdx);

      if (info !== null && IsValidS(this.empList))
      {
        let newList = [];

        for (let i = 0;i < this.empList.length; ++i)
        {
          if (this.empList[i].idx !== this.lastDelIdx)
          {
            newList.push(this.empList[i]);
          }
        }

        this.empList = newList;
      }
    }

    if (this.delCallback != null)
        this.delCallback(result.ret);
  }
  //직원 수정-------------------------------------------------------------------------------------------------------------------------------------
  editCallback = null;
  editStaffInfo = null;
  editOldLevel = 0;
  editNewLevel = 0;

  makeEditParam (idx, uid, pw, name, phone, email, at) {
    const params = new URLSearchParams();

    params.append("idx", idx);
    params.append("pw", pw);
    params.append("name", (ToBase64(name)));
    params.append("phone", phone);
    params.append("email", email);
    params.append("at", at.toString());
    params.append("uid", uid);
    
    return params;
  }

  editStaff = (idx, pw, name, phone, email, at, callback) =>
  {
    this.editCallback = callback;

    let staff = this.findUserByIdx(idx);

    if (staff !== null)
    {
      this.editOldLevel = staff.plevel;
      this.editNewLevel = at;

      this.editStaffInfo = {
        idx: idx,
        cid : staff.cid,
        cpw: pw,
        utype: staff.utype,
        uname: name,
        phone: phone,
        email: email,
        plevel: at,
      };
    }
    else
    {
      this.editStaffInfo = null;

      this.editOldLevel = -1;
      this.editNewLevel = -1;
    }
    

    axios({
      method:"POST",
      url: "staff/edit.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeEditParam(idx, IsValidV(staff) ? staff.cid : "n",  pw, name, phone, email, at)
    }).then((res)=>{
        this.parseEditResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseEditResult(null);
    });
  }

  parseEditResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseEditResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.editCallback != null)
        this.editCallback(-2, this.editOldLevel, this.editNewLevel);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (this.editStaffInfo !== null)
      {
        const staff = this.findUserByIdx(this.editStaffInfo.idx);

        if (staff !== null)
        {
          staff.cpw = this.editStaffInfo.cpw;
          staff.uname = this.editStaffInfo.uname;
          staff.phone = this.editStaffInfo.phone;
          staff.email = this.editStaffInfo.email;
          staff.plevel = this.editStaffInfo.plevel;
          
          this.empList = [...this.empList];
        }
      }

      switch(this.editOldLevel)
      {
        case 0:
          --this.adminCount;
          break;
        case 1:
          --this.managerCount;
          break;
        case 2:
          --this.salesCount;
          break;
        case 3:
          --this.salesManager;
          break;
        default:
          break;
      }

      switch(this.editNewLevel)
      {
        case 0:
          ++this.adminCount;
          break;
        case 1:
          ++this.managerCount;
          break;
        case 2:
          ++this.salesCount;
          break;
        case 3:
          ++this.salesManager;
          break;
        default:
          break;
      }
    }
    
    if (this.editCallback != null)
        this.editCallback(result.ret, this.editOldLevel, this.editNewLevel);
  }
  //영업담당자 목록 조회-------------------------------------------------------------------------------------------------------------------------------------
  salesCallback = null;

  makeSalesListParam (requestType) {
    const params = new URLSearchParams();

    params.append("rt", requestType.toString()); //0 : 영업담당만, 1 : 영업담당 + 관리담당

    return params;
  }

  loadSales = (callback) =>
  {
    this.salesCallback = callback;

    axios({
      method:"POST",
      url: "staff/sales.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSalesListParam(0)
    }).then((res)=>{
        this.parseSalesResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseSalesResult(null);
    });
  }

  parseSalesResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseSalesResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.salesCallback != null)
        this.salesCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          if (element.uname !== undefined && element.uname !== null && element.uname.length > 0)
            element.uname = FromBase64(element.uname);
        });

        this.salesList = result.list;
      }
      else
        this.salesList = null;
      
    }
    else
        this.salesList = null;
    
    if (this.salesCallback != null)
        this.salesCallback(result.ret);
  }
  //영업 + 관리 담당자 목록 조회-------------------------------------------------------------------------------------------------------------------------------------
  salesManagerCallback = null;

  //원래 영업 + 관리 담당자 조회지만, 영업담당자만 조회하는걸로 바뀜
  loadSalesManager = (callback) =>
  {
    this.salesManagerCallback = callback;

    axios({
      method:"POST",
      url: "staff/sales.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSalesListParam(1) //관리담당의 개념이 바뀌어 그냥 기존과 동일하게 영업담당자만 조회하는것으로 변경
    }).then((res)=>{
        this.parseSalesManagerResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseSalesManagerResult(null);
    });
  }

  parseSalesManagerResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseSalesManagerResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.salesManagerCallback != null)
        this.salesManagerCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          if (IsValidS(element.uname))
            element.uname = FromBase64(element.uname);
        });

        this.salesManagerList = result.list;
      }
      else
        this.salesManagerList = null;
      
    }
    else
        this.salesManagerList = null;
    
    if (this.salesManagerCallback != null)
        this.salesManagerCallback(result.ret);
  }

  findSalesNameFromSalesManager = (userId) =>
  {
    if (!IsValidS(this.salesManagerList))
      return null;

    for (let i = 0;i < this.salesManagerList.length; ++i)
    {
      if (this.salesManagerList[i].cid === userId)
      {
        return this.salesManagerList[i].uname;
      }
    }

    return null;
  }

  findSalesNameFromSalesManagerByIdx = (userIdx) =>
  {
    if (!IsValidS(this.salesManagerList))
      return null;

    for (let i = 0;i < this.salesManagerList.length; ++i)
    {
      if (this.salesManagerList[i].idx === userIdx)
      {
        return this.salesManagerList[i].uname;
      }
    }

    return null;
  }

  findSalesFromSalesManagerByIdx = (userIdx) =>
  {
    if (!IsValidS(this.salesManagerList))
      return null;

    for (let i = 0;i < this.salesManagerList.length; ++i)
    {
      if (this.salesManagerList[i].idx === userIdx)
      {
        return this.salesManagerList[i];
      }
    }

    return null;
  }

  getFirstSalesManagerId = () =>
  {
    if (!IsValidS(this.salesManagerList))
      return '';

    return this.salesManagerList[0].cid;
  }

  getSalesManagerCount = () =>
  {
    if (!IsValidS(this.salesManagerList))
      return 0;

    return this.salesManagerList.length;
  }

  getFirstSalesManager = () =>
  {
    if (!IsValidS(this.salesManagerList))
      return null;

    return this.salesManagerList[0];
  }
  //도매상 등록-------------------------------------------------------------------------------------------------------------------------------------
  addWholeSalesCallback = null;

  makeAddWSParam (cname, oname, phone, cnum, cid, cpw) {
    const params = new URLSearchParams();

    params.append("rt", "2");
    params.append("cname", (ToBase64(cname)));
    params.append("oname", (ToBase64(oname)));
    params.append("phone", phone);
    params.append("cnum", cnum);
    params.append("cid", cid);
    params.append("cpw", cpw);

    return params;
  }

  addWholeSales = (cname, oname, phone, cnum, cid, cpw, callback) =>
  {
    this.addWholeSalesCallback = callback;

    axios({
      method:"POST",
      url: "staff/add.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeAddWSParam(cname, oname, phone, cnum, cid, cpw)
    }).then((res)=>{
        this.parseAddWSResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseAddWSResult(null);
    });
  }

  parseAddWSResult = (result) =>
  {
    if (LOG_ENABLE)
    console.log("parseAddWSResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.addWholeSalesCallback != null)
        this.addWholeSalesCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (this.addWholeSalesCallback != null)
        this.addWholeSalesCallback(result.ret);
  }
  //관리하는 영업담당자 목록 조회-------------------------------------------------------------------------------------------------------------------------------------
  managedSalesCallback = null;

  makeManagedSalesListParam (managerIdx) {
    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("uidx", managerIdx.toString());

    return params;
  }

  findManagedSalesByIdx = (salesIdx) =>
  {
    if (!IsValidS(this.managedSalesList))
    {
      return null;
    }

    for (let i = 0;i < this.managedSalesList.length; ++i)
    {
      if (this.managedSalesList[i].sidx === salesIdx)
      {
        return this.managedSalesList[i];
      }
    }

    return null;
  }

  getFirstManagedSalesInfo = () =>
  {
    if (!IsValidS(this.managedSalesList))
    {
      return null;
    }

    return this.managedSalesList[0];
  }

  loadManagedSalesList = (managerIdx, callback) =>
  {
    this.managedSalesCallback = callback;

    axios({
      method:"POST",
      url: "staff/msales.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeManagedSalesListParam(managerIdx)
    }).then((res)=>{
        this.parseManagedSalesListResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseManagedSalesListResult(null);
    });
  }

  parseManagedSalesListResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseManagedSalesListResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.managedSalesCallback != null)
        this.managedSalesCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        this.managedSalesList = [];

        for (let i = 0;i < result.list.length; ++i)
        {
          this.managedSalesList.push({...result.list[i], uname: null, cid: null});
        }
      }
      else
        this.managedSalesList = null;
      
      this.validateManagedSalesName();
    }
    else
        this.managedSalesList = null;
    
    if (this.managedSalesCallback != null)
        this.managedSalesCallback(result.ret);
  }

  validateManagedSalesName = () =>
  {
    if (!IsValidS(this.managedSalesList))
    {
      return;
    }

    for (let i = 0;i < this.managedSalesList.length; ++i)
    {
      if (!IsValidS(this.managedSalesList[i].uname))
      {
        let salesInfo = this.findSalesFromSalesManagerByIdx(this.managedSalesList[i].sidx);

        if (IsValidV(salesInfo))
        {
          this.managedSalesList[i].uname = salesInfo.uname;
          this.managedSalesList[i].cid = salesInfo.cid;
        }
        else
        {
          this.managedSalesList[i].uname = '알수없음';
        }
      }
    }
  }
  //관리하는 영업담당자 목록 편집-------------------------------------------------------------------------------------------------------------------------------------
  managedSalesEditCallback = null;
  lastManagerIdx = -1; //마지막 요청의 관리담당자 고유번호
  lastMSEditInfo = null; //마지막 요청의 편집정보

  makeEditManagedSalesListParam (managerIdx, editInfo) {
    const params = new URLSearchParams();

    params.append("rt", "1");
    params.append("uidx", managerIdx.toString());
    params.append("ei", ToBase64(editInfo));

    return params;
  }

  makeManagedSalesListEditInfo = () =>
  {
    if (!IsValidS(this.lastMSEditInfo))
    {
      return 'n';
    }

    let editInfo = '';

    for (let i = 0;i < this.lastMSEditInfo.length; ++i)
    {
      if (i === (this.lastMSEditInfo.length - 1))
        editInfo += `${this.lastMSEditInfo[i].sidx},${this.lastMSEditInfo[i].edit}`;
      else
        editInfo += `${this.lastMSEditInfo[i].sidx},${this.lastMSEditInfo[i].edit},`;
    }

    return editInfo;
  }

  //해당 영업담당자가 마지막 편집 목록에 포함되어 있는지 확인한다.
  findManagedSalesFromEditInfo = (salesIdx) =>
  {
    if (!IsValidS(this.lastMSEditInfo))
    {
      return null;
    }

    for (let i = 0;i < this.lastMSEditInfo.length; ++i)
    {
      if (this.lastMSEditInfo[i].sidx === salesIdx)
      {
        return this.lastMSEditInfo[i];
      }
    }

    return null;
  }

  //관리하는 영업담당자 목록에 추가하거나 제거함
  //managerIdx : 관리 담당자 고유번호
  //editInfo : 추가하거나 삭제할 영업담당자의 목록
  editManagedSalesList = (managerIdx, editInfo, callback) =>
  {
    this.managedSalesEditCallback = callback;
    this.lastManagerIdx = managerIdx;
    this.lastMSEditInfo = editInfo;

    let editData = this.makeManagedSalesListEditInfo();

    axios({
      method:"POST",
      url: "staff/msales.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeEditManagedSalesListParam(managerIdx, editData)
    }).then((res)=>{
        this.parseManagedSalesEditResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseManagedSalesEditResult(null);
    });
  }

  parseManagedSalesEditResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseManagedSalesEditResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.managedSalesEditCallback != null)
        this.managedSalesEditCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      //편집에 성공했고, 마지막으로 요청했던 정보와 일치하는 경우
      if (this.lastManagerIdx === result.uidx)
      {
        if (IsValidS(this.managedSalesList))
        {
          let newList = null;

          //기존 목록에 있는것 중 삭제되지 않은것들을 추가
          for (let i = 0;i < this.managedSalesList.length; ++i)
          {
            let editInfo = this.findManagedSalesFromEditInfo(this.managedSalesList[i].sidx);

            if (editInfo === null || editInfo.edit === 1)
            {
              if (newList === null)
              {
                newList = [];
              }

              newList.push(this.managedSalesList[i]);
            }
          }

          //편집목록중 새로 추가된 것만 목록에 추가
          for (let i = 0;i < this.lastMSEditInfo.length; ++i)
          {
            if (this.lastMSEditInfo[i].edit === 1)
            {
              //기존 목록에 없던 담당자면
              if (this.findManagedSalesByIdx(this.lastMSEditInfo[i].sidx) === null)
              {
                newList.push({...this.lastMSEditInfo[i], uname: null});
              }
            }
          }

          this.managedSalesList = newList;
        }
        else
        {
          let newList = [];

          for (let i = 0;i < this.lastMSEditInfo.length; ++i)
          {
            if (this.lastMSEditInfo[i].edit === 1)
            {
              newList.push({...this.lastMSEditInfo[i], uname: null});
            }
          }

          if (IsValidS(newList))
          {
            this.managedSalesList = newList;
          }
        }

        this.validateManagedSalesName();
      }
    }
    
    if (this.managedSalesEditCallback != null)
        this.managedSalesEditCallback(result.ret);
  }
}
//-------------------------------------------------------------------------------------------------------------------------------------------------
const StaffStoreInstance = new StaffStore();
const StaffStoreContext = React.createContext(StaffStoreInstance);

const UseStaffStore = () => React.useContext(StaffStoreContext);

export {UseStaffStore, StaffStoreInstance};
