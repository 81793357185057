import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import {GetApiUrl, LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS,  IsValidV,  ToBase64 } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

//상품 관련
class ProductStore {
  //observable변수
  searchWord = '';
  productCategory = null; //서버에서 조회된 아이템 판매 분류
  productList = null; //서버에서 조회된 아이템 목록
  recentList = null; //최근 주문 목록
  searchList = null; //검색된 아이템 목록
  compdList = null; //검색된 공통 상품의 목록

  //비 observable
  totalCount = 0;
  lastRecentIdx = -1; //마지막 으로 조회된 최근 목록 아이템 소매상의 고유번호
  totalList = null; //엑셀출력을 위해 조회된 전체 상품의 정보
  totalListDone = false;
  totalListIdx = -1;
  totalLoading = false;

  clearAll = () =>
  {
    this.searchWord = '';
    this.productCategory = null; //서버에서 조회된 아이템 판매 분류
    this.productList = null; //서버에서 조회된 아이템 목록
    this.recentList = null; //최근 주문 목록
    this.searchList = null; //검색된 아이템 목록
    this.compdList = null; //검색된 공통 상품의 목록

    //비 observable
    this.totalCount = 0;
    this.lastRecentIdx = -1; //마지막 으로 조회된 최근 목록 아이템 소매상의 고유번호

    this.recentCallback = null;
    this.searchCallback = null;
    this.searchCompdCallback = null;
    this.loadCallback = null;
    this.lastLoadPos = -1;
    this.addCallback = null;
    this.editCallback = null;
    this.lastEditIdx = -1;
    this.editInfo = null;
    this.editStatusCallback = null;
    this.categoryCallback = null;

    this.totalList = null; //엑셀출력을 위해 조회된 전체 상품의 정보
    this.totalListDone = false;
    this.totalListIdx = -1;
    this.totalLoading = false;

    this.addComPdCallback = null;
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      searchWord : observable,
      productList : observable,
      recentList : observable,
      productCategory : observable,
      searchList : observable,
      compdList : observable,

      setSearchWord: action,
      clearProductList : action,
      searchProduct : action,
      parseSearchResult : action,

      clearRecentList : action,
      loadRecentProduct : action,
      parseRecentResult : action,

      loadProduct : action,
      parseLoadResult : action,

      addProduct : action,
      parseAddResult : action,

      editProduct : action,
      parseEditResult : action,

      getProductList : computed,

      loadCategory : action,
      parseCategoryResult : action,

      parseCompdList : action,
      clearAll : action,
    });
  }
  
  //
  clearProductList = () =>
  {
    this.productList = null; //목록
  }

  clearRecentList = () =>
  {
    this.recentList = null; //목록
  }

  clearTotalList = () =>
  {
    this.totalList = null; //엑셀출력을 위해 조회된 전체 상품의 정보
    this.totalListDone = false;
    this.totalListIdx = -1;
    this.totalLoading = false;
  }

  clearCompdList = () =>
  {
    this.compdList = null;
  }

  totalListToExcelValue = (tempList) =>
  {
    if (!IsValidS(this.totalList))
    {
      return;
    }

    for (let i = 0;i < this.totalList.length; ++i)
    {
      let temp = [];

      temp.push(LoginStoreInstance.compIdx);
      temp.push(this.getCategoryNameByCode(this.totalList[i].iid));
      temp.push(this.totalList[i].icode);
      temp.push(this.totalList[i].iname);
      temp.push(this.totalList[i].alias);
      temp.push(this.totalList[i].ivol);
      temp.push(this.unitCodeToString(this.totalList[i].unit));
      temp.push(this.usesCodeToString(this.totalList[i].uses));
      temp.push(this.totalList[i].freq);
      temp.push(this.totalList[i].cnt);
      temp.push(this.totalList[i].price);
      temp.push(this.totalList[i].img);
      temp.push(this.totalList[i].idx);

      tempList.push(temp);
    }
  }

  unitCodeToString = (unitCode) =>
  {
    switch(unitCode)
    {
      case 0:
        return "박스";
      case 1:
        return "병";
      case 2:
        return "박스/병";
      case 3:
        return "박스/개";
      case 4:
        return "개";
      default:
        return "기타";
    }
  }

  usesCodeToString = (usesCode) =>
  {
    switch(usesCode)
    {
      case 0:
        return "유흥";
      case 1:
        return "일반";
      case 2:
        return "공용";
      default:
        return "기타";
    }
  }

  //검색어 설정
  setSearchWord = (v) =>
  {
    this.searchWord = v;
  }

  //검색어가 올바른가?
  isSearchWordValid = () =>
  {
    return IsValidS(this.searchWord);
  }

  //
  get getProductList()
  {
    return this.productList;
  }

  //고유번호로 아이템 찾기
  findProductByIdx = (idx) =>
  {
    if (this.productList === null || this.productList.length < 1)
      return null;

    for (let i = 0; i < this.productList.length; ++i)
    {
      if (this.productList[i].idx === idx)
        return this.productList[i];
    }

    return null;
  }

  findProductByIdxFromSearchList = (idx) =>
  {
    if (!IsValidS(this.searchList))
      return null;

    for (let i = 0; i < this.searchList.length; ++i)
    {
      if (this.searchList[i].idx === idx)
        return this.searchList[i];
    }

    return null;
  }
  //최근 주문목록-------------------------------------------------------------------------------------------------------------------------------------
  recentCallback = null;

  makeRecentParam (retailerIdx) {
    const params = new URLSearchParams();

    params.append("ridx", retailerIdx.toString());
    params.append("rt", "0");
    params.append("widx", LoginStoreInstance.compIdx.toString());
    return params;
  }

  loadRecentProduct = (retailerIdx, callback) =>
  {
    this.recentCallback = callback;
    this.recentList = null;

    axios({
      method:"POST",
      url: GetApiUrl("order/items.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeRecentParam(retailerIdx)
    }).then((res)=>{
        this.parseRecentResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseRecentResult(null);
    });
  }

  parseRecentResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseRecentResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.recentCallback != null)
        this.recentCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      this.recentList = null;

      if (IsValidS(result.list))
      {
        this.recentList = [];

        result.list.forEach(element => {

          if (!this.findItemInList(this.recentList, element))
          {
            this.decodeRecentItem(element);
            this.recentList.push(element);
          }
        });
      }
    }
    else
      this.recentList = null;

    if (this.recentCallback != null)
        this.recentCallback(result.ret);
  }

  findItemInList = (itemList, item) =>
  {
    if (!IsValidS(itemList))
      return false;

    for (let i = 0;i < itemList.length; ++i)
    {
      if (itemList[i].iidx === item.iidx)
        return true;
    }

    return false;
  }

  decodeRecentItem = (item) =>
  {
    if (IsValidS(item.iname))
      item.iname = FromBase64(item.iname);

    if (IsValidS(item.alias))
      item.alias = FromBase64(item.alias);
  }
  //검색하기-------------------------------------------------------------------------------------------------------------------------------------
  searchCallback = null;

  makeSearchParam (companyType) {
    const params = new URLSearchParams();

    params.append("sn", this.searchWord);
    params.append("rt", "0");
    params.append("ct", companyType.toString());

    if (LOG_ENABLE)
      console.log(params.toString());
      
    return params;
  }

  //companyType : 소매상 업체 구분, 0 : 유흥, 1 : 일반, -1 : 구분없이 제품검색
  searchProduct = (companyType, callback) =>
  {
    this.searchCallback = callback;
    this.searchList = null;

    axios({
      method:"POST",
      url: ("pd/ser.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSearchParam(companyType)
    }).then((res)=>{
        this.parseSearchResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseSearchResult(null);
    });
  }

  parseSearchResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseSearchResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.searchCallback != null)
        this.searchCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      this.searchList = result.list;

      if (IsValidS(this.searchList))
      {
        this.searchList.forEach(element => {
          this.decodeProduct(element);
        });
      }
    }

    if (this.searchCallback != null)
        this.searchCallback(result.ret);
  }

  decodeProduct = (element) =>
  {
    if (IsValidS(element.iname))
      element.iname = FromBase64(element.iname);

    if (IsValidS(element.alias))
      element.alias = FromBase64(element.alias);

    if (IsValidS(element.dt))
      element.dt = FromBase64(element.dt);
  }

  isSearchProductValid = () =>
  {
    return IsValidS(this.searchList);
  }
  //공통상품 검색------------------------------------------------------------------------------------------------------------------------------------------
  //검색하기-------------------------------------------------------------------------------------------------------------------------------------
  searchCompdCallback = null;

  makeSearchCommonPdParam () {
    const params = new URLSearchParams();

    params.append("sw", ToBase64(this.searchWord));
    params.append("rt", "1");

    if (LOG_ENABLE)
      console.log(params.toString());
      
    return params;
  }

  //공통상품 검색
  searchCommonProduct = (callback) =>
  {
    this.searchCompdCallback = callback;

    axios({
      method:"POST",
      url: ("cpd/ser.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSearchCommonPdParam()
    }).then((res)=>{
        this.parseCompdList(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseCompdList(null);
    });
  }

  parseCompdList = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseCompdList : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.searchCompdCallback != null)
        this.searchCompdCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      this.compdList = result.list;

      if (IsValidS(this.compdList))
      {
        this.compdList.forEach(element => {
          this.decodeCommonProduct(element);
        });
      }
    }

    if (this.searchCompdCallback != null)
        this.searchCompdCallback(result.ret);
  }

  decodeCommonProduct = (element) =>
  {
    if (IsValidS(element.mk))
      element.mk = FromBase64(element.mk);

    if (IsValidS(element.iname))
      element.iname = FromBase64(element.iname);

    if (IsValidS(element.img))
      element.img = FromBase64(element.img);

    if (IsValidS(element.dt))
      element.dt = FromBase64(element.dt);

    if (IsValidS(element.cc))
      element.cc = FromBase64(element.cc);

    if (IsValidS(element.ptn))
      element.ptn = FromBase64(element.ptn);
  }
  //품목코드로 검색하기-------------------------------------------------------------------------------------------------------------------------------------
  makeItemCodeSearchParam () {
    const params = new URLSearchParams();

    params.append("icode", this.searchWord);
    return params;
  }

  searchProductByItemCode = (callback) =>
  {
    this.searchCallback = callback;
    this.searchList = null;

    axios({
      method:"POST",
      url: ("pd/ser2.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeItemCodeSearchParam()
    }).then((res)=>{
        this.parseSearchResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseSearchResult(null);
    });
  }
  //목록 읽기-------------------------------------------------------------------------------------------------------------------------------------
  loadCallback = null;
  lastLoadPos = -1;

  makeLoadParam (requestType, iid, pos) {
    const params = new URLSearchParams();

    params.append("iid", iid.toString());
    params.append("pos", pos.toString());
    params.append("rt", requestType.toString());
    return params;
  }

  loadProduct = (requestType, iid, offset, callback) =>
  {
    this.loadCallback = callback;
    this.lastLoadPos = offset;

    if (requestType === 1)
      this.totalLoading = true;

    if (LOG_ENABLE)
      console.log(`iid = ${iid}, pos = ${offset}`);
    axios({
      method:"POST",
      url: ("pd/list.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadParam (requestType, iid, offset)
    }).then((res)=>{
        this.parseLoadResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseLoadResult(null);
    });
  }

  parseLoadResult = (result) =>
  {
    this.totalLoading = false;

    if (LOG_ENABLE)
      console.log("parseLoadResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadCallback != null)
        this.loadCallback(-2, false);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (result.rt === 0)
      {
        this.productList = result.list;

        if (IsValidS(this.productList))
        {
          this.productList.forEach(element => {
            this.decodeProduct(element);
          });
        }
  
        if (result.ft === 1)
        {
          this.totalCount = result.tc;
        }
      }
      else //엑셀출력을 위한 전체 상품 조회중이면
      {
        let readCount = 0;

        if (IsValidS(result.list))
        {
          readCount = result.list.length;

          result.list.forEach(element => {
            this.decodeProduct(element);

            if (this.totalListIdx < element.idx)
              this.totalListIdx = element.idx;
          });

          if (!IsValidS(this.totalList))
            this.totalList = result.list;
          else
            this.totalList = this.totalList.concat(result.list);
        }
        else
          this.totalListDone = true;

        if (this.loadCallback != null)
          this.loadCallback(result.ret, readCount > 0);

        return;
      }
    }

    if (this.loadCallback != null)
      this.loadCallback(result.ret, result.ft === 1);
  }
  //추가-------------------------------------------------------------------------------------------------------------------------------------
  addCallback = null;

  makeAddParam (iid, icode,iname,alias,ivol,unit,uses,freq,cnt,img,price,comIdx,mk,dt) {
    const params = new URLSearchParams();

    params.append("iid", iid.toString());
    params.append("icode", icode);
    params.append("iname", (ToBase64(iname)));
    params.append("alias", (ToBase64(alias)));
    params.append("ivol", ivol);
    params.append("unit", unit.toString());
    params.append("uses", uses.toString());
    params.append("freq", freq);
    params.append("cnt", cnt);
    params.append("img", img);
    params.append("price", price);
    params.append("comidx", comIdx);
    params.append("mk", ToBase64(mk));
    params.append("dt", ToBase64(dt));
    return params;
  }

  addProduct = (iid, icode,iname,alias,ivol,unit,uses,freq,cnt,img,price,comIdx,mk,dt, callback) =>
  {
    this.addCallback = callback;

    axios({
      method:"POST",
      url: ("pd/add.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeAddParam (iid, icode,iname,alias,ivol,unit,uses,freq,cnt,img, price,comIdx,mk,dt)
    }).then((res)=>{
        this.parseAddResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseAddResult(null);
    });
  }

  parseAddResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseAddResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.addCallback != null)
        this.addCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      ++this.totalCount; //상품 전체 개수를 증가 시킨다.

      if (this.lastLoadPos === 0 && IsValidV(result.item))
      {
        this.decodeProduct(result.item);

        let newList = null;

        if (IsValidS(this.productList))
          newList = [result.item, ...this.productList];
        else
          newList = [result.item];

        this.productList = newList;
      }
    }

    if (this.addCallback != null)
        this.addCallback(result.ret);
  }
  //공통상품추가-------------------------------------------------------------------------------------------------------------------------------------
  addComPdCallback = null;

  makeAddComPdParam (mk,iname,ivol,freq,cnt,img,price,itc,iunit,iuses,dt,cc,ptn) {
    const params = new URLSearchParams();

    params.append("mk", ToBase64(mk));
    params.append("iname", ToBase64(iname));
    params.append("ivol", ivol);
    params.append("freq", freq);
    params.append("cnt", cnt);
    params.append("img", ToBase64(img));
    params.append("price", price);
    params.append("itc", itc);
    params.append("iunit", iunit);
    params.append("iuses", iuses);
    params.append("dt", ToBase64(dt));
    params.append("cc", ToBase64(cc));
    params.append("ptn", ToBase64(ptn));
    params.append("rt", "1");

    if (LOG_ENABLE)
      console.log(`makeAddComPdParam : mk=${mk},iname=${iname},ivol=${ivol},freq=${freq},cnt=${cnt},img=${img},price=${price},itc=${itc},iunit=${iunit},iuses=${iuses},dt=${dt},cc=${cc},ptn=${ptn}/${params.toString()}`);

    return params;
  }

  addComProduct = (mk,iname,ivol,freq,cnt,img,price,itc,iunit,iuses,dt,cc,ptn, callback) =>
  {
    this.addComPdCallback = callback;

    axios({
      method:"POST",
      url: ("cpd/add.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeAddComPdParam (mk,iname,ivol,freq,cnt,img,price,itc,iunit,iuses,dt,cc,ptn)
    }).then((res)=>{
        this.parseAddComPdResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseAddComPdResult(null);
    });
  }

  parseAddComPdResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseAddComPdResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.addComPdCallback != null)
        this.addComPdCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (this.addComPdCallback != null)
        this.addComPdCallback(result.ret);
  }
  //수정-------------------------------------------------------------------------------------------------------------------------------------
  editCallback = null;
  lastEditIdx = -1;
  fromSearchList = false;
  editInfo = null;

  makeEditParam (idx, iid, icode,iname,alias,ivol,unit,uses,freq,cnt,img,price) {
    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("idx", idx.toString());
    params.append("iid", iid.toString());
    params.append("icode", icode);
    params.append("iname", (ToBase64(iname)));
    params.append("alias", (ToBase64(alias)));
    params.append("ivol", ivol);
    params.append("unit", unit.toString());
    params.append("uses", uses.toString());
    params.append("freq", freq);
    params.append("cnt", cnt);
    params.append("img", img);
    params.append("price", price);
    return params;
  }

  editProduct = (fromSearchList, idx, iid, icode,iname,alias,ivol,unit,uses,freq,cnt,img,price, callback) =>
  {
    this.editCallback = callback;
    this.lastEditIdx = idx;
    this.fromSearchList = fromSearchList;

    let item = null;

    if (fromSearchList)
      item = this.findProductByIdxFromSearchList(idx);
    else
      item = this.findProductByIdx(idx);

    if (item !== null)
    {
      this.editInfo = {
        ...item,
        iid : iid,
        icode: icode,
        iname: iname,
        alias : alias,
        ivol : ivol,
        unit : unit,
        uses : uses,
        freq: freq,
        cnt : cnt,
        img : img,
        price: price,
      };
    }
    else
      this.editInfo = null;

    axios({
      method:"POST",
      url: ("pd/edit.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeEditParam (idx, iid, icode,iname,alias,ivol,unit,uses,freq,cnt,img,price)
    }).then((res)=>{
        this.parseEditResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseEditResult(null);
    });
  }

  parseEditResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseEditResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.editCallback != null)
        this.editCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      let item = null;
      
      if (this.fromSearchList)
      {
        item = this.findProductByIdxFromSearchList(this.lastEditIdx);

        if (item !== null && this.editInfo !== null)
        {
          let newList = [];

          for (let i = 0;i < this.searchList.length; ++i)
          {
            if (this.searchList[i].idx !== this.lastEditIdx)
            {
              newList.push(this.searchList[i]);
            }
            else
              newList.push(this.editInfo)
          }

          this.searchList = newList;
        }
      }
      else
      {
        item = this.findProductByIdx(this.lastEditIdx);

        if (item !== null && this.editInfo !== null)
        {
          let newList = [];

          for (let i = 0;i < this.productList.length; ++i)
          {
            if (this.productList[i].idx !== this.lastEditIdx)
            {
              newList.push(this.productList[i]);
            }
            else
              newList.push(this.editInfo)
          }

          this.productList = newList;
        }
      }
    }

    if (this.editCallback != null)
        this.editCallback(result.ret);
  }
  //판매상태수정-------------------------------------------------------------------------------------------------------------------------------------
  editStatusCallback = null;

  makeEditStatusParam (idx, pt) {
    const params = new URLSearchParams();

    params.append("rt", "1");
    params.append("idx", idx.toString());
    params.append("pt", pt.toString());
    return params;
  }

  //상품의 판매 상태를 수정함
  editProductStatus = (fromSearchList, idx, pt, callback) =>
  {
    this.editStatusCallback = callback;
    this.lastEditIdx = idx;
    this.fromSearchList = fromSearchList;

    let item = null;

    if (fromSearchList)
      item = this.findProductByIdxFromSearchList(idx);
    else
      item = this.findProductByIdx(idx);

    if (item !== null)
    {
      this.editInfo = {
        ...item,
        pt: pt,
      };
    }
    else
      this.editInfo = null;

    axios({
      method:"POST",
      url: ("pd/edit.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeEditStatusParam (idx, pt)
    }).then((res)=>{
        this.parseEditStatusResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseEditStatusResult(null);
    });
  }

  parseEditStatusResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseEditStatusResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.editStatusCallback != null)
        this.editStatusCallback(-2, 0);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      let item = null;
      
      if (this.fromSearchList)
      {
        item = this.findProductByIdxFromSearchList(this.lastEditIdx);

        if (item !== null && this.editInfo !== null)
        {
          let newList = [];

          for (let i = 0;i < this.searchList.length; ++i)
          {
            if (this.searchList[i].idx !== this.lastEditIdx)
            {
              newList.push(this.searchList[i]);
            }
            else
              newList.push(this.editInfo)
          }

          this.searchList = newList;
        }
      }
      else
      {
        item = this.findProductByIdx(this.lastEditIdx);

        if (item !== null && this.editInfo !== null)
        {
          let newList = [];

          for (let i = 0;i < this.productList.length; ++i)
          {
            if (this.productList[i].idx !== this.lastEditIdx)
            {
              newList.push(this.productList[i]);
            }
            else
              newList.push(this.editInfo)
          }

          this.productList = newList;
        }
      }

      if (this.editStatusCallback != null)
        this.editStatusCallback(result.ret, parseInt(result.pt));

      return;
    }

    if (this.editStatusCallback != null)
        this.editStatusCallback(result.ret, 0);
  }
  //판매 카테고리-------------------------------------------------------------------------------------------------------------------------------------
  getCategoryNameByCode = (categoryCode) =>
  {
    if (this.productCategory === null)
      return "알수없음";

    for (let i = 0;i < this.productCategory.length; ++i)
    {
      if (this.productCategory[i].iid === categoryCode)
        return this.productCategory[i].cname;
    }

    return "알수없음";
  }

  categoryCallback = null;

  makeCategoryParam () {
    const params = new URLSearchParams();

    params.append("rt", "0");
    return params;
  }

  loadCategory = (callback) =>
  {
    this.categoryCallback = callback;
    this.productCategory = null;

    axios({
      method:"POST",
      url: ("pd/pt.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeCategoryParam()
    }).then((res)=>{
        this.parseCategoryResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseCategoryResult(null);
    });
  }
  
  parseCategoryResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseCategoryResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.categoryCallback != null)
        this.categoryCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      this.productCategory = result.list;

      if (this.productCategory !== undefined && this.productCategory !== null)
      {
        this.productCategory.forEach(element =>{
          this.decodeCategory(element);
        });
      }
    }

    if (this.categoryCallback != null)
        this.categoryCallback(result.ret);
  }

  decodeCategory = (element) =>
  {
    if (element.cname !== undefined && element.cname !== null && element.cname.length > 0)
      element.cname = FromBase64(element.cname);
  }
}

//-------------------------------------------------------------------------------------------------------------------------------------------------
const ProductStoreInstance = new ProductStore();
const ProductStoreContext = React.createContext(ProductStoreInstance);

const UseProductStore = () => React.useContext(ProductStoreContext);

export {UseProductStore, ProductStoreInstance};
