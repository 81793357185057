import { GetChatTime, IsValidS } from "../../Util/Util";

function GetChatMsg(item)
{
  if (!IsValidS(item.chat))
    return "";

  if (item.chat === "n")
    return "";
    
  if (item.chat.length < 13)
    return item.chat;

  return item.chat.substring(0,13) + "...";
}

//채팅창 업체
const ChatCompItemmb = (props) =>
{
  if (props.selectedIdx === props.item.idx)
  {
    return (
      <div className="chatcompitemselmb flexcentercol btncur" onClick={(e)=>props.handleCompanyClick(props.item.idx, props.item.uidx)}>
        <div className="chatcompitemtop">
          <label className="mfontnwb btncur">{props.item.cname}</label>
          {
            props.item.rd === 0 ? (<div className="newchatmarkmb"></div>) : null
          }
        </div>

        <div className="chatcompitembottom flexcenterrow">
          <div className="chatcompitembottomleft flexcenterrowstart">
            <label className="mfontnw btncur">{GetChatMsg(props.item)}</label>
          </div>

          <div className="chatcompitembottomright flexcenterrowend">
            <label className="mfontnw btncur">{GetChatTime(props.item.rdate)}</label>
          </div>
        </div>
      </div>
    );
  }
  else
  {
    return (
      <div className="chatcompitemmb flexcentercol btncur" onClick={(e)=>props.handleCompanyClick(props.item.idx, props.item.uidx)}>
        <div className="chatcompitemtop">
          <label className="mfontnb btncur">{props.item.cname}</label>
          {
            props.item.rd === 0 ? (<div className="newchatmarkmb"></div>) : null
          }
        </div>

        <div className="chatcompitembottom flexcenterrow">
          <div className="chatcompitembottomleft flexcenterrowstart">
            <label className="mfontn btncur">{GetChatMsg(props.item)}</label>
          </div>

          <div className="chatcompitembottomright flexcenterrowend">
            <label className="mfontn btncur">{GetChatTime(props.item.rdate)}</label>
          </div>
        </div>

        <div className="chathlinemb"></div>
        
      </div>
    );
  }  
}

export default ChatCompItemmb;