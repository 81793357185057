import { LOG_ENABLE } from "../../URL/AppUrl";
import { IsValidS } from "../../Util/Util";
import ReportItem from "./ReportItem";

const ReportList = (props) =>
{
  let orders = null;

  if (LOG_ENABLE)
    console.log("ReportList : " + JSON.stringify(props.items));

  if (IsValidS(props.items))
  {
    orders = props.items.map(item => {
      return (<ReportItem key={`${item.orderType}-${item.idx}`} order = {item} popt={props.popt} handleDeliMode={props.handleDeliMode}/>);
    });
  }

  return (
    <div className = "tablecont">
      <table className="emptable">
        <thead>
          <tr key={-1}>
            <th className="reptableheader">
              <label className="fontnw">거래처명</label>
            </th>

            <th className="reptableheader">
              <label className="fontnw">상품명</label>
            </th>

            <th className="reptableheader">
              <label className="fontnw">배송설정</label>
            </th>

          </tr>
        </thead>
        <tbody>
        {orders}
        </tbody>
      </table>
    </div>
  );
}

export default ReportList;