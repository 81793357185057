import React from 'react';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { NoticeStoreInstance } from '../../Stores/NoticeStore';
import { GetFileUrl, LOG_ENABLE, mobileOn } from '../../URL/AppUrl';
import NoticeEdit from './NoticeEdit';
import NoticeList from './NoticeList';
import NoticeTab from './NoticeTab';
import NoticeView from './NoticeView';
import "./Notice.css";
import MainMenu from '../Menu/MainMenu';
import NoticeNew from './NoticeNew';
import { ProcessLogout } from '../../Stores/StoreManager';
import Loading from '../Loading/Loading';
import { DownloadFile, FromBase64, IsValidS, IsValidV, UploadFile } from '../../Util/Util';
import MenuMb from '../Menu/MenuMb';
import NoticeTabmb from './NoticeTabmb';
import NoticeListmb from './NoticeListmb';
import NoticeViewmb from './NoticeViewmb';
import NoticeEditmb from './NoticeEditmb';

class Notice extends React.Component
{
  state = {
    loading : false,
    tabIndex : 0, //현재 선택된 탭, 0 : 전체, 1 : 개별
    selectedIdx : -1, //현재 선택된 공지
    editMode : 0, //0 : 편집중이 아님, 1 : 새공지 작성중, 2 : 공지 수정중, 3 : 공지 보는중
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 1, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 1, //전체 페이지 개수
    searchMode : false, //검색목록 보는중
    editInfo : null, //편집할 공지의 정보
    appOn : false, //앱에서 실행되었나?
  };

  intervalId = -1;
  updateInfo = null; //공지를 작성하거나 수정할때 임시로 내용을 보관함
  maxPageNum = 10;

  componentDidMount = () =>
  {
    if (LOG_ENABLE)
      console.log("Notice.componentDidMount");

    if (mobileOn)
      this.maxPageNum = 3;

    this.intervalId = setInterval(this.update, 1000);

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 8}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.OnLoadProfile);
      return;
    }

    this.loadNotice(1, this.state.tabIndex);
  }

  componentWillUnmount = () =>
  {
    if (LOG_ENABLE)
      console.log("componentWillUnmount");

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : "backhandler"}));

    if (!this.state.editMode)
      this.props.history.push("/main");
  }

  calcPageInfo = (tabMode) =>
  {
    let currentCount = 0;

    switch(tabMode)
    {
      case 0://전체
        currentCount = NoticeStoreInstance.totalGCount;
        break;
      case 1://개별
        currentCount = NoticeStoreInstance.totalPCount;
        break;
      default:
        break;
    }

    let pageCount = Math.floor(currentCount / 10);

    if ((currentCount % 10) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.maxPageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  OnLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    this.loadNotice(1, this.state.tabIndex);
  }

  loadNotice = (pageNum, tabMode) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    NoticeStoreInstance.loadNotice(tabMode, (pageNum - 1) * 10, this.onLoadNotices);
  }

  onLoadNotices = (resultCode, isFirst) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        return;
      default:
        alert("공지 조회중 오류가 발생했습니다.");
        return;
    }

    if (isFirst)
      this.calcPageInfo(this.state.tabIndex);
  }

  setLoading = (enabled) =>
  {
    this.setState((prevState) => ({...prevState, loading : enabled}));
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case -1:
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");  
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        this.props.history.push("/order"); 
        break;
      case 4: //판매일보
        this.props.history.push("/report"); 
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }

  //탭 눌림
  handleTab = (tabIndex)=>
  {
    if (LOG_ENABLE)
      console.log("handleTab : " + tabIndex);

    this.setState((prevState) => ({...prevState, tabIndex : tabIndex, editMode : 0, searchMode : false}));

    this.loadNotice(1, tabIndex);
  }

  //검색 버튼 눌림
  handleSearch = (searchWord)=>
  {
    if (LOG_ENABLE)
      console.log("handleSearch");

    if (searchWord === undefined || searchWord === null || searchWord.length < 1)
    {
      alert("검색어를 입력하세요.");
      return;
    }

    if (LOG_ENABLE)
      console.log(`search word = ${searchWord}`);

    this.setState((prevState) => ({...prevState, loading : true, searchMode : true}));

    NoticeStoreInstance.searchNotice(this.state.tabIndex, searchWord, this.onSearchResult);
  }

  onSearchResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      default:
        alert("공지 검색중 오류가 발생했습니다.");
        break;
    }
  }

  //글쓰기 버튼 눌림
  handleWrite = ()=>
  {
    if (LOG_ENABLE)
      console.log("handleWrite");

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert('공지 작성 권한이 없습니다.');
      return;
    }

    this.setState((prevState) => ({...prevState, editMode : 1, searchMode : false, editInfo : null}));
  }

  //글쓰기 창에서 등록 또는 닫기 눌림
  handleWriteResult = (ok, uname, title, desc, afilenew, aimgnew)=>
  {
    if (LOG_ENABLE)
      console.log("handleWrite");

    if (!ok)
    {
      this.setState((prevState) => ({...prevState, editMode : 0, searchMode : false}));
      return;
    }

    if (!IsValidS(uname))
    {
      alert("작성자 이름을 입력해 주세요.");
      return;
    }

    if (!IsValidS(title))
    {
      alert("공지 제목을 입력해 주세요.");
      return;
    }

    if (!IsValidS(desc))
    {
      alert("공지 내용을 입력해 주세요.");
      return;
    }

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert('공지 작성 권한이 없습니다.');
      return;
    }

    this.updateInfo = {
      mode : 0,
      uname : uname,
      title : title,
      desc : desc,
      afile : afilenew,
      aimg : aimgnew,
      afileName : null,
      afileNameNew : null,
      aimgName : null,
      aimgNameNew : null,
    };

    if (!window.confirm("공지를 저장할까요?"))
      return;

    this.setLoading(true);

    //첨부 파일이 있다면
    if (IsValidV(afilenew))
    {
      UploadFile(LoginStoreInstance.userIdx, 1, afilenew, this.onUploadFileDone);
      return;
    }

    if (IsValidV(aimgnew))
    {
      UploadFile(LoginStoreInstance.userIdx, 1, aimgnew, this.onUploadImgDone);
      return;
    }

    NoticeStoreInstance.addNotice(this.state.currentPage, this.state.tabIndex, title, uname, desc, null, null, null, null, this.onAddNoticeResult);
  }

  onUploadFileDone = (success, oldFile, newFIle) =>
  {
    if (LOG_ENABLE)
      console.log(`onUploadFileDone => success = ${success}, oldfile = ${oldFile}, newfile = ${newFIle}`);

    if (!success)
    {
      this.setLoading(false);
      alert("첨부 파일 업로드중 오류가 발생했습니다.");
      return;
    }

    this.updateInfo.afileName = FromBase64(oldFile);
    this.updateInfo.afileNameNew = newFIle;

    if (IsValidV(this.updateInfo.aimg))
    {
      UploadFile(LoginStoreInstance.userIdx, 1, this.updateInfo.aimg, this.onUploadImgDone);
      return;
    }

    if (this.updateInfo.mode === 0)
    {
      NoticeStoreInstance.addNotice(this.state.currentPage, this.state.tabIndex, 
        this.updateInfo.title, this.updateInfo.uname, this.updateInfo.desc, this.updateInfo.afileNameNew, this.updateInfo.afileName, 
        this.updateInfo.aimgNameNew, this.updateInfo.aimgName, this.onAddNoticeResult);
    }
    else
    {
      NoticeStoreInstance.editNotice(this.updateInfo.idx, this.state.tabIndex, this.updateInfo.title, 
        this.updateInfo.uname, this.updateInfo.desc, this.updateInfo.afileNameNew, this.updateInfo.afileName, 
        this.updateInfo.aimgNameNew, this.updateInfo.aimgName, this.onEditResult);
    }
  }

  onUploadImgDone = (success, oldFile, newFIle) =>
  {
    if (LOG_ENABLE)
      console.log(`onUploadImgDone => success = ${success}, oldfile = ${oldFile}, newfile = ${newFIle}`);

    if (!success)
    {
      this.setLoading(false);
      alert("첨부 이미지 업로드중 오류가 발생했습니다.");
      return;
    }

    this.updateInfo.aimgName = FromBase64(oldFile);
    this.updateInfo.aimgNameNew = newFIle;

    if (this.updateInfo.mode === 0)
    {
      NoticeStoreInstance.addNotice(this.state.currentPage, this.state.tabIndex, 
        this.updateInfo.title, this.updateInfo.uname, this.updateInfo.desc, this.updateInfo.afileNameNew, this.updateInfo.afileName, 
        this.updateInfo.aimgNameNew, this.updateInfo.aimgName, this.onAddNoticeResult);
    }
    else
    {
      NoticeStoreInstance.editNotice(this.updateInfo.idx, this.state.tabIndex, this.updateInfo.title, 
        this.updateInfo.uname, this.updateInfo.desc, this.updateInfo.afileNameNew, this.updateInfo.afileName, 
        this.updateInfo.aimgNameNew, this.updateInfo.aimgName, this.onEditResult);
    }
  }

  onAddNoticeResult = (resultCode) =>
  {
    this.setState((prevState) => ({...prevState, editMode : 0, searchMode : false, loading : false}));

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("공지 작성 권한이 없습니다.");
        break;
      default:
        alert("공지 작성중 오류가 발생했습니다.");
        break;
    }
  }

  //이전글
  handlePrev = (noticeIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handlePrev : " + noticeIdx);

    //공지중에서 번호가 작은것 하나 읽기
    const notice = NoticeStoreInstance.findPrevNotice(noticeIdx, this.state.tabIndex === 0);

    if (notice !== null)
    {
      if (LOG_ENABLE)
        console.log("prev notice found");

      this.setState((prevState) => ({...prevState, editMode : 3, selectedIdx : notice.idx, editInfo : notice}));
    }
    else
    {
      if (this.state.tabIndex === 0) //전체 공지 모드
      {
        //더이상 읽을 공지가 없다
        if (noticeIdx <= NoticeStoreInstance.gStartIdx)
        {
          alert("첫번째 공지입니다.");
          return;
        }
      }
      else //개별 공지 모드
      {
        //더이상 읽을 공지가 없다
        if (noticeIdx <= NoticeStoreInstance.pStartIdx)
        {
          alert("첫번째 공지입니다.");
          return;
        }
      }

      //서버에서 이전 공지 한개를 읽는다.
      NoticeStoreInstance.loadNoticeOne(noticeIdx, this.state.tabIndex, 0, this.onLoadOneResult);
      this.setLoading(true);
    }
  }

  //공지 한개 읽기의 결과
  onLoadOneResult = (resultCode, notice) =>
  {
    this.setLoading(false);

    if (resultCode !== 0 || !IsValidV(notice))
    {
      alert("더이상 공지가 없습니다.");
      return;
    }

    this.setState((prevState) => ({...prevState, editMode : 3, selectedIdx : notice.idx, editInfo : notice}));
  }

  //다음글
  handleNext = (noticeIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleNext : " + noticeIdx);

    //다음공지 한개읽기
    const notice = NoticeStoreInstance.findNextNotice(noticeIdx, this.state.tabIndex === 0);

    if (notice !== null)
    {
      if (LOG_ENABLE)
        console.log("next notice found");

      this.setState((prevState) => ({...prevState, editMode : 3, selectedIdx : notice.idx, editInfo : notice}));
    }
    else
    {
      if (this.state.tabIndex === 0) //전체 공지 모드
      {
        //더이상 읽을 공지가 없다
        if (noticeIdx >= NoticeStoreInstance.gEndIdx)
        {
          alert("마지막 공지입니다.");
          return;
        }
      }
      else //개별 공지 모드
      {
        //더이상 읽을 공지가 없다
        if (noticeIdx <= NoticeStoreInstance.pEndIdx)
        {
          alert("마지막 공지입니다.");
          return;
        }
      }

      //서버에서 이전 공지 한개를 읽는다.
      NoticeStoreInstance.loadNoticeOne(noticeIdx, this.state.tabIndex, 1, this.onLoadOneResult);
      this.setLoading(true);
    }
  }

  //공지 보기창에서 수정 버튼 눌림
  handleEdit = (noticeIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleEdit : " + noticeIdx);

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert('공지 수정 권한이 없습니다.');
      return;
    }

    let editInfo = this.state.tabIndex === 0 ? NoticeStoreInstance.findGNoticeByIdx(noticeIdx) : NoticeStoreInstance.findPNoticeByIdx(noticeIdx);

    if (editInfo === null)
    {

      if (this.state.editInfo === null)
      {
        alert("수정할 수 없습니다.");
        return;
      }

      editInfo = this.state.editInfo;
    }

    this.setState((prevState) => ({...prevState, editMode : 2, selectedIdx : noticeIdx, editInfo : editInfo}));
  }

  //공지 수정 결과
  handleEditResult = (ok, noticeIdx, uname, title, desc, afilenew, aimgnew, afile, aimg, afileori, aimgori) =>
  {
    if (LOG_ENABLE)
      console.log(`handleEdit : ${noticeIdx}, ${afile}, ${aimg}, ${afileori}, ${aimgori}`);

    if (!ok)
    {
      this.setState((prevState) => ({...prevState, editMode : 0}));
      return;
    }

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert('공지 수정 권한이 없습니다.');
      return;
    }
    
    this.updateInfo = {
      mode : 1,
      idx : noticeIdx,
      uname : uname,
      title : title,
      desc : desc,
      afile : afilenew,
      aimg : aimgnew,
      afileName : afileori,
      afileNameNew : afile,
      aimgName : aimgori,
      aimgNameNew : aimg,
    };

    if (!window.confirm("수정 사항을 저장할까요?"))
      return;

    this.setLoading(true);

    //첨부 파일이 있다면
    if (IsValidV(afilenew))
    {
      UploadFile(LoginStoreInstance.userIdx, 1, afilenew, this.onUploadFileDone);
      return;
    }

    if (IsValidV(aimgnew))
    {
      UploadFile(LoginStoreInstance.userIdx, 1, aimgnew, this.onUploadImgDone);
      return;
    }

    NoticeStoreInstance.editNotice(noticeIdx, this.state.tabIndex, title, uname, desc, afile, afileori, aimg, aimgori, this.onEditResult);
  }

  onEditResult = (resultCode) =>
  {
    switch(resultCode)
    {
      case 0:
        this.setState((prevState) => ({...prevState, editMode : 0, searchMode : false, loading : false, editInfo : null}));
        return;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("공지 수정 권한이 없습니다.");
        break;
      default:
        alert("공지 수정중 오류가 발생했습니다.");
        break;
    }

    this.setState((prevState) => ({...prevState, editMode : 0, searchMode : false, loading : false}));
  }

  //공지 목록에서 특정 공지 보기 선택됨
  handleSelect = (noticeIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleSelect : " + noticeIdx);

    let editInfo = this.state.tabIndex === 0 ? NoticeStoreInstance.findGNoticeByIdx(noticeIdx) : NoticeStoreInstance.findPNoticeByIdx(noticeIdx);

    if (editInfo === null)
    {
      alert("공지를 찾을 수 없습니다.");
      return;
    }

    if (LOG_ENABLE)
      console.log(`===>${JSON.stringify(editInfo)}`);

    this.setState((prevState) => ({...prevState, editMode : 3, selectedIdx : noticeIdx, editInfo : editInfo}));
  }

  //공지 보기창에서 목록 버튼이 눌림
  handleList = () =>
  {
    if (LOG_ENABLE)
      console.log("handleList");

    if (this.state.editMode !== 0 || this.state.searchMode)
      this.setState((prevState) => ({...prevState, editMode : 0, searchMode : false}));

    this.loadNotice(this.state.currentPage, this.state.tabIndex);
  }

  handlePage = (pageIndex) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageIndex);

    if (pageIndex === -1)
    {
      let endPage = this.maxPageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadNotice(1, this.state.tabIndex);
    }
    else if (pageIndex === -2)
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadNotice(startPage, this.state.tabIndex);
    }
    else if (pageIndex === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadNotice(startPage, this.state.tabIndex);
    }
    else if (pageIndex === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadNotice(endPage, this.state.tabIndex);
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageIndex}));

      this.loadNotice(pageIndex, this.state.tabIndex);
    }
  }

  handleLogout = () => {
    ProcessLogout();
    this.props.history.push("/");
  }
  
  handleDownloadFile = (fileName, oriName) =>
  {
    if (this.state.appOn)
      this.postMessage(JSON.stringify({action: "download", fn : fileName, on : oriName}));
    else
      DownloadFile(GetFileUrl(fileName), oriName);
  }

  render = () =>
  {
    if (mobileOn) //공제 모바일
    {
      return (
        <div className="appcontmb flexcentercolstart">
  
          {
            this.state.loading ? <Loading />:null
          }
  
          <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout = {this.handleLogout}/>

          <div className="noticetitlecontmb flexcentercolstart">
            <div className="noticetitlemb flexcentercolstart">
              <label className="mfontlargeb">공지사항</label>
  
              {//공지작성모드
                (this.state.editMode !== 1) ?
                (
                  <NoticeTabmb handleTab = {this.handleTab} handleSearch = {this.handleSearch} handleWrite={this.handleWrite} 
                    tabIndex = {this.state.tabIndex}
                    editMode={this.state.editMode}
                    searchMode = {this.state.searchMode}
                    handleList = {this.handleList}
                  />
                )
                :(
                  null
                )
              }
  
            </div>
          </div>
  
          <div className="noticebodycontmb flexcentercolstart">
            {//일반 리스트 모드인경우
              (this.state.editMode === 0) ? 
                <NoticeListmb tabIndex = {this.state.tabIndex} handleSelect={this.handleSelect} companyName = {LoginStoreInstance.getUserName}
                  startPage={this.state.startPage} endPage={this.state.endPage}
                  currentPage={this.state.currentPage} pageCount={this.state.pageCount}
                  handlePage = {this.handlePage}
                  searchMode = {this.state.searchMode}
                /> : null
            }
  
            {//글쓰기 또는 수정
              (this.state.editMode === 1) || (this.state.editMode === 2)? 
                <NoticeEditmb editMode={this.state.editMode} tabIndex = {this.state.tabIndex} handleWriteResult={this.handleWriteResult}
                  handleEditResult = {this.handleEditResult} editInfo = {this.state.editInfo} noticeIdx={this.state.selectedIdx}
                /> : null
            }
  
            {//글보기
              (this.state.editMode === 3) ? 
              <NoticeViewmb tabIndex = {this.state.tabIndex} 
                handleEdit = {this.handleEdit} editInfo = {this.state.editInfo} noticeIdx={this.state.selectedIdx}
                handleList = {this.handleList} handlePrev={this.handlePrev} handleNext={this.handleNext}
                appOn = {this.state.appOn}
                handleDownloadFile = {this.handleDownloadFile}
              /> : null
            }
          </div>
        </div>
      );
    }
    else //공지 pc버전
    {
      return (
        <div className="appcontainer flexcentercolstart">
  
          {
            this.state.loading ? <Loading />:null
          }
  
          <div className="noticetitlecont flexcentercolstart">
            <div className="noticemenu">
              <MainMenu index={7} handleMenuClick = {this.handleMenuClick}/>
            </div>
            
  
            <div className="noticetitle flexcentercolstart">
              <label className="ntctitlefont">공지사항</label>
  
              {
                (this.state.editMode === 1) ?
                (
                  <NoticeNew />
                )
                :(
                  <NoticeTab handleTab = {this.handleTab} handleSearch = {this.handleSearch} handleWrite={this.handleWrite} 
                    tabIndex = {this.state.tabIndex}
                    editMode={this.state.editMode}
                    searchMode = {this.state.searchMode}
                    handleList = {this.handleList}
                  />
                )
              }
  
            </div>
          </div>
  
          <div className="noticebodycont flexcentercolstart">
            {//일반 리스트 모드인경우
              (this.state.editMode === 0) ? 
                <NoticeList tabIndex = {this.state.tabIndex} handleSelect={this.handleSelect} companyName = {LoginStoreInstance.getUserName}
                  startPage={this.state.startPage} endPage={this.state.endPage}
                  currentPage={this.state.currentPage} pageCount={this.state.pageCount}
                  handlePage = {this.handlePage}
                  searchMode = {this.state.searchMode}
                /> : null
            }
  
            {//글쓰기 또는 수정
              (this.state.editMode === 1) || (this.state.editMode === 2)? 
                <NoticeEdit editMode={this.state.editMode} tabIndex = {this.state.tabIndex} handleWriteResult={this.handleWriteResult}
                  handleEditResult = {this.handleEditResult} editInfo = {this.state.editInfo} noticeIdx={this.state.selectedIdx}
                /> : null
            }
  
            {//글보기
              (this.state.editMode === 3) ? 
              <NoticeView tabIndex = {this.state.tabIndex} 
                handleEdit = {this.handleEdit} editInfo = {this.state.editInfo} noticeIdx={this.state.selectedIdx}
                handleList = {this.handleList} handlePrev={this.handlePrev} handleNext={this.handleNext}
              /> : null
            }
          </div>
        </div>
      );
    }
  }
}

export default Notice;