import React from 'react';
import './Login.css';
import {GetImgUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
//import { Link } from "react-router-dom";
import { observer } from 'mobx-react';
import { LoginStoreInstance } from '../../Stores/LoginStore';

class Login extends React.Component {

  state = {
    errorMsg : "",
    appOn : false,
  };
 
  componentDidMount() {

    if (LOG_ENABLE)
      console.log("Login.componentDidMount : " + LoginStoreInstance.userId + "/" + LoginStoreInstance.userPW);

    if (!LoginStoreInstance.init && !window.ReactNativeWebView)
    {
      LoginStoreInstance.loadAccount();
    }

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      this.postMessage(JSON.stringify({action : "webpos", pos : 0}));
      this.postMessage(JSON.stringify({action : "loginon"}));

      LoginStoreInstance.appEnabled = true;
    }
    else
      LoginStoreInstance.appEnabled = false;

    //이미 로그인되어 있다면
    if (LoginStoreInstance.loggedIn)
    {
      if (LOG_ENABLE)
        console.log("already logged in");
        
      this.props.history.push("/main");
    }
  }

  componentWillUnmount()
  {
    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "account") //계정정보인경우
        {
          LoginStoreInstance.restoreAccountFromApp(msg);

          if (LoginStoreInstance.isAccountValid() && LoginStoreInstance.autoLogin && LoginStoreInstance.enableAutoLogin)
          {
            if (LOG_ENABLE)
              console.log("auto login");

            this.onLoginClick(null);
          }
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  onIDChanged = (e) => {

    if (LOG_ENABLE)
      console.log("onIDChanged : " + e.target.value);
      
    LoginStoreInstance.setUserId(e.target.value);
  }

  onPWChanged = (e) => {
    if (LOG_ENABLE)
      console.log("onPWChanged : " + e.target.value);

    LoginStoreInstance.setUserPW(e.target.value);
  }

  onCheckChanged = (e) => {

    LoginStoreInstance.toggleSaveAccount();

    if (LOG_ENABLE)
      console.log("onCheckChanged : " + e.target.value + "/store value : " + LoginStoreInstance.acSave);
  }

  onCheckALChanged = (e) => {
    LoginStoreInstance.toggleAutoLogin();
  }

  onLoginClick = (e) => {
    //alert("로그인버튼");

    if (!LoginStoreInstance.isAccountValid())
    {
      alert("계정 정보를 올바르게 입력해주세요.");
      return;
    }

    //이미 로그인 시도 중인경우
    if (LoginStoreInstance.login)
    {
      return;
    }

    this.setState((prevState) => ({...prevState, errorMsg : ''}));

    LoginStoreInstance.tryLogin(this.handleLoginResult);

    //this.handleLoginResult(0);
    //this.props.history.push("/main");
  }

  saveAccountInfoToApp = () =>
  {
    if (window.ReactNativeWebView) {
      let temp = {
        action : "saveaccount",
        saveAccount: LoginStoreInstance.acSave, 
        autoLogin : LoginStoreInstance.autoLogin,
        userId : LoginStoreInstance.userId,
        userPW : LoginStoreInstance.userPW,
      };

      this.postMessage(JSON.stringify(temp));
    }
  }

  handleLoginResult = (errorCode) => {
    
    if (LOG_ENABLE)
      console.log("handleLoginResult : " + errorCode);

    switch(errorCode)
    {
      case 0:
        if (LoginStoreInstance.acSave)
        {
          if (!window.ReactNativeWebView)
            LoginStoreInstance.saveAccount();
        }

        this.saveAccountInfoToApp();

        this.props.history.push("/main");
        break;
      case 1:
        break;
      case 4:
        this.setState((prevState) => ({...prevState, errorMsg : '프로필 읽기 오류입니다. 잠시 후 다시 이용해 주세요.'}));
        break;
      case 5:
        this.setState((prevState) => ({...prevState, errorMsg : '이 계정은 차단되어 더이상 이용할 수 없습니다.'}));
        break;
      case 6:
        this.setState((prevState) => ({...prevState, errorMsg : '이용할 수 없는 계정입니다.'}));
        break;
      case 2: //존재하지 않는 계정
      case 3: //비번오류
      default:
        this.setState((prevState) => ({...prevState, errorMsg : '계정 정보를 올바르게 입력해 주세요.'}));
        break;
    }
  }

  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.onLoginClick(e);
    }
  }

  render() {
    if (LOG_ENABLE)
      console.log("is mobile : " + mobileOn);

    if (mobileOn)
    {
      return (
        <div className="logincontainer flexcenterrow">
          <div className="loginmb flexcentercol">
  
            <div className="loginimgmb flexcenterrow">
              <img src={GetImgUrl("bimb.png")} alt="주도" />
            </div>
            
            <input type="text" onChange={this.onIDChanged} value = {LoginStoreInstance.userId} className="logininpmb fontinpmb" 
              placeholder="아이디를 입력해주세요." autoFocus/>
            <input type="password" onChange={this.onPWChanged} value = {LoginStoreInstance.userPW} className="logininpmb fontinpmb"
              placeholder="비밀번호를 입력해주세요." onKeyPress={this.handleKeyPress}/>
  
            {
              this.state.errorMsg != null && this.state.errorMsg.length > 0 ? 
              (<div className="loginchkcontmb flexcenterrowstart">
                <label className="errortextmb">{this.state.errorMsg}</label>
              </div>) : null
            }
  
            <div className="loginchkcontmb flexcenterrowstart">

              <div className="flexrowcenter">
                <input type="checkbox" onChange={this.onCheckChanged} checked = {LoginStoreInstance.acSave} className="chk"/>
                <label className="fontlgnchk">&nbsp;아이디 저장</label>
              </div>
              
              {
                this.state.appOn ? (
                  <div className="flexrowcenter">
                    <input type="checkbox" onChange={this.onCheckALChanged} checked = {LoginStoreInstance.autoLogin} className="chk"/>
                    <label className="fontlgnchk">&nbsp;자동로그인</label>
                  </div>
                ):null
              }
              

            </div>
  
            <button className="loginbtnmb fontnwb" onClick={this.onLoginClick}>
              로그인
            </button>
            
  
            <div className="bottomcontainer flexcenterrow">
              {/*
              <div className="btmc flexcenterrow">
                <Link to="/fidpw" className="linktext">ID/비밀번호 찾기</Link>
              </div>
              
              <div className="horiline"></div>
              <div className="btmr flexcenterrowstart">
                <Link to="/join" className="linktext">회원 가입</Link>
              </div>
              */}
            </div>
  
          </div>
        </div>
      );
    }
    else
    {
      return (
        <div className="logincontainer flexcenterrow">
          <div className="login flexcentercol">
  
            <div className="loginimg flexcenterrow">
              <img src={GetImgUrl("title2.png")} alt="N-돌핀" />
            </div>
            
            <input type="text" onChange={this.onIDChanged} value = {LoginStoreInstance.userId} className="logininp" 
              placeholder="아이디를 입력해주세요." autoFocus/>
            <input type="password" onChange={this.onPWChanged} value = {LoginStoreInstance.userPW} className="logininp" 
              placeholder="비밀번호를 입력해주세요." onKeyPress={this.handleKeyPress}/>
  
            {
              this.state.errorMsg != null && this.state.errorMsg.length > 0 ? 
              (<div className="loginchkcont flexcenterrowstart">
                <label className="errortext">{this.state.errorMsg}</label>
              </div>) : null
            }
  
            <div className="loginchkcont flexcenterrowstart">
              <input type="checkbox" onChange={this.onCheckChanged} checked = {LoginStoreInstance.acSave} className="chk"/> 아이디 저장
            </div>
  
            <button className="loginbtn fontlargewb" onClick={this.onLoginClick}>
              로그인
            </button>
            
  
            <div className="bottomcontainer flexcenterrow">
              {/*
              <div className="btmc flexcenterrow">
                <Link to="/fidpw" className="linktext">ID/비밀번호 찾기</Link>
              </div>
              
              <div className="horiline"></div>
              <div className="btmr flexcenterrowstart">
                <Link to="/join" className="linktext">회원 가입</Link>
              </div>
              */}
  
              <label className="bdesc">크롬,오페라,엣지,파이어폭스,웨일 브라우저와 같은 최신 브라우저를 이용해 주세요. 인터넷익스플로러와 같은 구버전 브라우저는 지원되지 않습니다.</label>
            </div>
  
          </div>
        </div>
      );
    }
  }
}

export default observer(Login);