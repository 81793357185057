import { mobileOn } from '../../URL/AppUrl';

//채팅창 우측 대화 검색창
const ChatEmptyTop = (props) =>
{
  if (mobileOn)
  {
    return (
      <div className="chatsertalkmb2 flexcentercol">
        <div className="chatsertalktopmb flexcenterrow">
          <label className="mfontlargeb">CHAT</label>
        </div>
        <div className="chathlinemb"></div>
      </div>
    );
  }
  else
  {
    return (
      <div className="chatsertalk flexcentercol">
        <div className="chatsertalktop flexcenterrow">
          <label className="fontlargeb">CHAT</label>
        </div>
      </div>
    );
  }
  
}

export default ChatEmptyTop;