import React from 'react';
import { observer } from "mobx-react";
import {  LOG_ENABLE, mobileOn, GetImgUrl } from "../../URL/AppUrl";
import "../../App.css";
import "./Product.css";
import { ProductStoreInstance } from "../../Stores/ProductStore";
import { IsValidS, IsValidV, UploadFile } from '../../Util/Util';
import Loading from '../Loading/Loading';
import { LoginStoreInstance } from '../../Stores/LoginStore';

//공통상품 등록
class ProductAddCom extends React.Component {

  state = {
    loading : false,

    maker : '',
    iname: '',
    ivol: '',
    freq: '',
    count: '',
    price: '',
    itc: 1,
    iuses: 0,
    iunit: 0,
    dt: 'n',
    cc: '',
    ptn: '',
    imgFile: null,
  }

  setInfo = (index, v) =>
  {
    switch(index)
    {
      case 0:
        this.setState((prevState) => ({...prevState, maker : v}));
        break;
      case 1:
        this.setState((prevState) => ({...prevState, iname : v}));
        break;
      case 2:
        this.setState((prevState) => ({...prevState, ivol : v}));
        break;
      case 3:
        this.setState((prevState) => ({...prevState, freq : v}));
        break;
      case 4:
        this.setState((prevState) => ({...prevState, count : v}));
        break;
      case 5:
        this.setState((prevState) => ({...prevState, price : v}));
        break;
      case 6:
        this.setState((prevState) => ({...prevState, itc : v}));
        break;
      case 7:
        this.setState((prevState) => ({...prevState, iuses : v}));
        break;
      case 8:
        this.setState((prevState) => ({...prevState, iunit : v}));
        break;
      case 9:
        this.setState((prevState) => ({...prevState, dt : v}));
        break;
      case 10:
        this.setState((prevState) => ({...prevState, cc : v}));
        break;
      case 11:
        this.setState((prevState) => ({...prevState, ptn : v}));
        break;
      case 12:
        this.setState((prevState) => ({...prevState, imgFile : v}));
        break;
      default:
        break;
    }
  }

  componentDidMount() {

    if (LOG_ENABLE)
      console.log("ProductAddNew.componentDidMount");
  }

  componentWillUnmount()
  {
    if (LOG_ENABLE)
      console.log("ProductAddNew.componentWillUnmount");
    
    ProductStoreInstance.clearCompdList();
  }

  setLoading = (enable) =>
  {
    this.setState((prevState) => ({...prevState, loading : enable}));
  }

  //공통상품 추가 요청
  handleAddComPDItem = (maker, name, vol, freq, cnt, price, img, itc, iunit, iuses, dt, cc, ptn) =>
  {
    if (LOG_ENABLE)
      console.log(`handleAddComPDItem`);

    if (!IsValidS(maker))
    {
      alert('제조사를 입력하세요.');
      return;
    }

    if (!IsValidS(name))
    {
      alert('상품명을 입력하세요.');
      return;
    }

    if (isNaN(vol))
    {
      alert('용량을 입력하세요.');
      return;
    }

    if (isNaN(freq))
    {
      alert('도수를 입력하세요.');
      return;
    }

    if (isNaN(cnt))
    {
      alert('입수를 입력하세요.');
      return;
    }

    if (isNaN(price))
    {
      alert('가격을 입력하세요.');
      return;
    }

    if (isNaN(itc))
    {
      alert('판매 분류를 선택하세요.');
      return;
    }

    if (isNaN(iunit) || iunit < 0 || iunit > 4)
    {
      alert('취급 단위를 선택하세요.');
      return;
    }

    if (isNaN(iuses) || iuses < 0 || iuses > 2)
    {
      alert('용도를 선택하세요.');
      return;
    }

    //추가 할 상품의 정보를 저장해둠
    this.compdAddInfo = {
      mk: maker,
      iname: name, 
      ivol: vol,
      freq: freq,
      cnt: cnt,
      price: price,
      img: img,
      itc: itc,
      iunit: iunit,
      iuses: iuses,
      dt: !IsValidS(dt) ? 'n' : dt,
      cc: !IsValidS(cc) ? 'n' : cc,
      ptn: !IsValidS(ptn) ? 'n' : ptn,
    };

    if (!IsValidV(img))
    {
      alert('상품 이미지를 선택하세요.');
      return;
    }

    if (!window.confirm('상품을 공통 상품 DB에 추가할까요?'))
    {
      return;
    }

    this.setLoading(true);
    
    UploadFile(LoginStoreInstance.userIdx, 0, img, this.onUploadComPdImgResult);
  }

  onUploadComPdImgResult = (success, oldName, newName) =>
  {
    if (!success)
    {
      this.setLoading(false);
      alert("상품 이미지 업로드중 오류가 발생했습니다.");
      return;
    }

    if (LOG_ENABLE)
      console.log(`upload success : old = ${oldName}, new = ${newName}`);

    ProductStoreInstance.addComProduct(this.compdAddInfo.mk, this.compdAddInfo.iname,this.compdAddInfo.ivol,this.compdAddInfo.freq,
      this.compdAddInfo.cnt,newName,this.compdAddInfo.price,this.compdAddInfo.itc,this.compdAddInfo.iunit,this.compdAddInfo.iuses,this.compdAddInfo.dt,
      this.compdAddInfo.cc,this.compdAddInfo.ptn, 
      this.onAddComPdResult);
  }

  onAddComPdResult = (resultCode) =>
  {
    switch(resultCode)
    {
      case 0:
        this.setState((prevState) => ({...prevState, loading: false, showAddComPd : false}));
        alert('상품DB에 상품이 추가되었습니다.');
        break;
      case 1:
        alert('잘못된 요청입니다.');
        this.setLoading(false);
        return;
      case 100:
        alert('세션이 만료되었습니다. 다시 로그인해 주세요.');
        this.setLoading(false);
        return;
      case 2:
        this.setLoading(false);
        alert('상품DB에 상품 추가중 오류가 발생했습니다.');
        return;
      case 3:
        this.setLoading(false);
        alert('추가된 상품 읽기중 오류가 발생했습니다.');
        return;
      default:
        this.setLoading(false);
        alert('서버 오류가 발생했습니다. 잠시 후 이용해 주세요.');
        return;
    }
  }

  render()
  {
    if (mobileOn)
    {
      
    }
    else
    {
      return(
        <div className="pdcomaddpopbg2 flexcenterrow">
          {
            this.state.loading ? <Loading />:null
          }
          
          <div className="pdcompdaddpop flexcentercolstart">
            {/*타이틀*/}
            <div className='pdcomtt flexcenterrow marginbottom30'>
              <div className='dcell1'></div>
              <div className='dcell8 flexcenterrow'>
                <label className='fontlargeb'>공통상품DB 상품추가</label>
              </div>
              <div className='dcell1 flexcenterrow'>
                <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur' onClick={() => {
                  if (this.state.loading)
                    return;
                  
                  this.props.handleCloseAddCompd();
                }}/>
              </div>
            </div>


            <div className='pdcomhline margintop10 marginbottom10'></div>
            
            {/*하단 정보영역*/}
            <div className='pdcomcont flexcenterrow'>
              <div className='pdcomleft flexcentercolstart'>
                <div className='flexcentercol marginbottom30'>
                  <label className='fonts2'>공통상품DB에 상품을 추가합니다.</label>
                  <label className='fonts2'>공통상품DB에 있는 상품은 검색을 통해 판매 상품으로 등록 할 수 있습니다.</label>
                  <label className='fonts2'>상품 중복 등록을 방지하기 위해서 상품을 추가하기전, </label>
                  <label className='fonts2'>추가하고자 하는 상품이 이미 공통DB에 존재하는지 확인해주세요.</label>
                  <label className='fonts2'><br/>상품DB에 상품을 등록해도 도매상 판매상품으로 등록되지는 않습니다. </label>
                  <label className='fonts2'>공통상품 DB에 상품 추가 후 검색을 통해서 판매 상품으로 등록해 주세요.</label>
                  
                  <label className='fonts2'><br/>개발사에서는 도매상에서 직접 추가한 상품에 대한 이미지 수정 작업이 필요하니</label>
                  <label className='fonts2'>공통상품DB에 상품을 직접 등록 한 경우</label>
                  <label className='fonts2'>업무 지원 채팅을 통해서 추가한 상품에 대해 알려주세요.</label>
                  
                  <label className='fonts2'><br/>상품명은 될 수 있는한 상품의 정식 명칭을 기록해 주세요.</label>
                  
                </div>

                <div className="compdinputimgcont flexcenterrow">
                  <img src={GetImgUrl("itemimage.png")} className="compdinputimg" id="previmg2" alt="상품이미지"/>
                </div>

                <label className="fonts"><b>120 X 120</b> 크기의 PNG 또는<br/>JPG포맷의 이미지 파일을 선택하세요.</label>

                <div className="compdinputimgselect flexcenterrow">
                  <label htmlFor="fileInputx" className="compdinputimgselfont">사진등록</label>
                  <input type="file" id="fileInputx" className="compdinputfilestyle" accept="image/png, image/jpeg"
                    onChange={(e) => {

                      if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                        return;

                      if (LOG_ENABLE)
                        console.log(e.target.files[0]);

                      this.setInfo(12, e.target.files[0]);

                      const preview = document.getElementById("previmg2");
                      
                      const reader = new FileReader();
                      reader.onloadend = (evt) => {
                        preview.src = reader.result;
                      };

                      reader.readAsDataURL(e.target.files[0]);
                  }}/>
                </div>
              </div>

              <div className='pdcomright flexcentercolstart'>
                
              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">제조사</label>
                <input type="text" onChange={(e) => this.setInfo(0, e.target.value)} value = {this.state.maker} className="compdaddinp fontn" placeholder="제조사를 입력하세요." maxLength="50"/>
              </div>

              <div className="compdline"></div>

              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">원산지</label>
                <input type="text" onChange={(e) => this.setInfo(10, e.target.value)} value = {this.state.cc} className="compdaddinp fontn" placeholder="원산지를 입력하세요." maxLength="25"/>
              </div>

              <div className="compdline"></div>

              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">종류</label>
                <input type="text" onChange={(e) => this.setInfo(11, e.target.value)} value = {this.state.ptn} className="compdaddinp fontn" placeholder="ex)럼, 진, 보드카..." maxLength="25"/>
              </div>

              <div className="compdline"></div>

              {/*상품구분*/}
              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">판매분류</label>
                
                <select className="compditcsel fontn btncur" 
                  onChange={(e) => {
                      console.log(e.target.value);
                      this.setInfo(6, parseInt(e.target.value));
                  }}

                  value={this.state.itc.toString()}
                >
                  <option value="1">소주</option>
                  <option value="2">맥주</option>
                  <option value="3">수입맥주</option>
                  <option value="4">생맥주</option>
                  <option value="5">양주</option>
                  <option value="6">와인</option>
                  <option value="7">사케</option>
                  <option value="100">기타</option>
                </select>
              </div>

              <div className="compdline"></div>

              {/*상품명*/}
              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">상품명</label>
                <input type="text" onChange={(e) => this.setInfo(1, e.target.value)} value = {this.state.iname} className="compdaddinp fontn" placeholder="상품명을 입력하세요." maxLength="40"/>
              </div>

              <div className="compdline"></div>
              {/*용량*/}
              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">용량</label>
                <input type="text" onChange={(e) => this.setInfo(2, e.target.value)} value = {this.state.ivol} className="compdaddinp fontn" placeholder="용량을 입력하세요." maxLength="8"/>
              </div>

              <div className="compdline"></div>
              {/*도수*/}
              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">도수</label>
                <input type="text" onChange={(e) => this.setInfo(3, e.target.value)} value = {this.state.freq} className="compdaddinp fontn" placeholder="도수를 입력하세요." maxLength="8"/>
              </div>

              <div className="compdline"></div>
              {/*입수*/}
              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">입수</label>
                <input type="text" onChange={(e) => this.setInfo(4, e.target.value)} value = {this.state.count} className="compdaddinp fontn" placeholder="입수를 입력하세요." maxLength="5"/>
              </div>

              <div className="compdline"></div>
              {/*취급단위*/}
              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">취급단위</label>
                
                <select className="compditcsel fontn btncur" 
                  onChange={(e) => {
                      console.log(e.target.value);
                      this.setInfo(8, parseInt(e.target.value));
                  }}

                  value={this.state.iunit}
                >
                  <option value="0">박스</option>
                  <option value="1">병</option>
                  <option value="2">박스/병</option>
                  <option value="3">박스/개</option>
                  <option value="4">개</option>
                </select>

              </div>

              <div className="compdline"></div>
              
              {/*용도
              <div className="flexcenterrow compdaddrow">
                <label className="fontnb">용도</label>
                
                <select className="compditcsel fontn btncur" 
                  onChange={(e) => {
                      console.log(e.target.value);
                      this.setInfo(7, parseInt(e.target.value));
                  }}

                  value={this.state.iuses}
                >
                  <option value="0">유흥</option>
                  <option value="1">일반</option>
                  <option value="2">공통</option>
                </select>
              </div>
              <div className="compdline"></div>
              */}
              
                {/*가격*/}
                <div className="flexcenterrow compdaddrow">
                  <label className="fontnb">가격</label>
                  <input type="text" onChange={(e) => this.setInfo(5, e.target.value)} value = {this.state.price} className="compdaddinp fontn" placeholder="가격을 입력하세요." maxLength="10"/>
                </div>

                <button className="compdadditembtn fontnwb btncur" 
                  onClick={() => this.handleAddComPDItem(this.state.maker, this.state.iname, this.state.ivol, this.state.freq, this.state.count, this.state.price, 
                    this.state.imgFile, this.state.itc, this.state.iunit, this.state.iuses, this.state.dt, this.state.cc, this.state.ptn)}>상품 DB에 추가</button>

              </div>

            </div>

          </div>
        </div>
      );
    }    
  }
}

export default observer(ProductAddCom);