
import React from 'react';
import '../Main/Main.css';
import '../../App.css';

import {GetImgUrl, LOG_ENABLE} from '../../URL/AppUrl';
import { observer } from 'mobx-react';
import { OrderStoreInstance } from '../../Stores/OrderStore';

//도매상 등록
class ReportFontOption extends React.Component {
  
  state = {
    fontSize : 9,
    totalCheck: false,
  };

  setCheckBox = (v) => {
    this.setState((prevState) => ({...prevState, totalCheck : v}));
  }

  setFontSize = (v) =>{
    this.setState((prevState) => ({...prevState, fontSize : v}));
  }

  saveFontSize = () =>
  {
    OrderStoreInstance.printFontSize = this.state.fontSize;
    OrderStoreInstance.totalInfocheck = this.state.totalCheck;
    OrderStoreInstance.saveFontSize();

    this.props.handleClose();
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("ReportFontOption.componentDidMount");


    this.setFontSize(OrderStoreInstance.printFontSize);
    this.setCheckBox(OrderStoreInstance.totalInfocheck);
  }

  componentWillUnmount = () =>
  {
    if (LOG_ENABLE)
      console.log("ReportFontOption.componentWillUnmount");
  }

  render()
  {
    return (
      <div className="flexcentercol rptfontoptionpop">

        <div className="flexcentercol rptfontoptionpop2">
          <div className="cntfullh marginbottom15 flexcenterrow">
            <div className="dcell1"></div>

            <div className="dcell8 flexcenterrow">
              <label className="fontnb">판매일보 출력 설정</label>    
            </div>

            <div className="dcell1">
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className="btncur" onClick={() => this.props.handleClose()}/>
            </div>
          </div>


          <div className="cntfullh marginbottom15 flexcenterrowstart">
            <input type="radio" onChange={(e) => this.setFontSize(5)} checked={this.state.fontSize === 5}/>
            <label className="fontnb">5PT</label>
          </div>

          <div className="cntfullh marginbottom15 flexcenterrowstart">
            <input type="radio" onChange={(e) => this.setFontSize(6)} checked={this.state.fontSize === 6}/>
            <label className="fontnb">6PT</label>
          </div>

          <div className="cntfullh marginbottom15 flexcenterrowstart">
            <input type="radio" onChange={(e) => this.setFontSize(7)} checked={this.state.fontSize === 7}/>
            <label className="fontnb">7PT</label>
          </div>

          <div className="cntfullh marginbottom15 flexcenterrowstart">
            <input type="radio" onChange={(e) => this.setFontSize(8)} checked={this.state.fontSize === 8}/>
            <label className="fontnb">8PT</label>
          </div>

          <div className="cntfullh marginbottom15 flexcenterrowstart">
            <input type="radio" onChange={(e) => this.setFontSize(9)} checked={this.state.fontSize === 9}/>
            <label className="fontnb">9PT</label>
          </div>

          <div className="cntfullh marginbottom15 flexcenterrowstart">
            <input type="radio" onChange={(e) => this.setFontSize(10)} checked={this.state.fontSize === 10}/>
            <label className="fontnb">10PT</label>
          </div>

          <div className="cntfullh marginbottom15 flexcenterrowstart">
            <input type="radio" onChange={(e) => this.setFontSize(11)} checked={this.state.fontSize === 11}/>
            <label className="fontnb">11PT</label>
          </div>

          <div className="cntfullh marginbottom15 flexcenterrowstart">
            <input type="radio" onChange={(e) => this.setFontSize(12)} checked={this.state.fontSize === 12}/>
            <label className="fontnb">12PT</label>
          </div>
          
          <br/>

          <div className="cntfullh marginbottom15 flexcenterrowstart">
            <input type="checkbox" checked={this.state.totalCheck} onChange={(e) => this.setCheckBox(!this.state.totalCheck)}/>
            <label className="fontnb" >전체 출력에 합계 포함</label>
          </div>

          <br/>

          <button className="bgcolor3 btncur rptfontoptionbtn fontnwb" onClick={() => this.saveFontSize()}>저장하기</button>
        </div>
        
      </div>
    );
  }
}

export default observer(ReportFontOption);