import { Fragment } from "react";
import { LOG_ENABLE } from "../../URL/AppUrl";
import { GetItemList } from "./ReportUtil";

function GetRetailerName(order)
{
  if (order.orderType === 0)
    return order.cname;

  return `${order.cname}(수기주문)`;
}

function GetDeliMode(order)
{
  switch(order.printType)
  {
    case 1:
      return "배송1";
    case 2:
      return "배송2";
    case 3:
      return "배송3";
    default:
      return "";
  }
}

function GetDeliFontColor(order)
{
  switch(order.printType)
  {
    case 1:
      return "fontnl";
    case 2:
      return "fontng";
    case 3:
      return "fontno";
    default:
      return "fontn";
  }
}

//아직 배송전일경우의 아이템 출력
const ReportItem = (props) =>
{
  if (LOG_ENABLE)
    console.log("ReportItem : " + JSON.stringify(props.order));

  const items = GetItemList(props.order, props.popt);
  let displayItem = null;

  if (items !== null && items.length > 0)
  {
    let keyIndex = 1;

    displayItem = items.map(item => {
      return (
        <Fragment key={++keyIndex}>
          {item}<br/>
        </Fragment>
      );
    });
  }

  return (
    <tr className="emptabledata">
      <td className="repcellcname">
        <div className="emptablecellin flexcenterrow">
          <label className={props.order.orderType === 0 ? "fontn" : "fontn2"}>{GetRetailerName(props.order)}</label>
        </div>
      </td>
      
      <td className="repcellitem">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{displayItem}</label>
        </div>
      </td>

      <td className="repcellitem2">
        <div className="emptablecellin flexcenterrow">
          {
            props.order.printType === 0 ? (
              <div className="flexcenterrow repdeliselbtnara">
                <button className="repdeliselbtn fontnwb bgcolor8 btncur" onClick={() => props.handleDeliMode(1, props.order)}>배송1</button>
                <button className="repdeliselbtn fontnwb bgcolor6 btncur" onClick={() => props.handleDeliMode(2, props.order)}>배송2</button>
                <button className="repdeliselbtn fontnwb bgcolor7 btncur" onClick={() => props.handleDeliMode(3, props.order)}>배송3</button>
              </div>
            ) : null
          }
          {
            props.order.printType !== 0 ? (
              <div className="flexcenterrow repdeliselbtnara2">
                <label className={GetDeliFontColor(props.order)}>{GetDeliMode(props.order)}</label>
                <button className="repdeliselbtn2 fontnwb bgcolor1 btncur" onClick={() => props.handleDeliMode(0, props.order)}>배송 수정</button>
              </div>
            ) : null
          }
        </div>
      </td>
    </tr>
  );
}

export default ReportItem;