import React, { useState } from 'react';
import { GetImgUrl, LOG_ENABLE } from '../../URL/AppUrl';

import "../../App.css";
import "../Emp/StaffAdd.css";
import "./RetAdd.css";
import { GetFormattedCNum2, GetFormattedPhone2, IsValidS } from '../../Util/Util';

const RetInp = (props) =>
{
  return (
    <div className="staffaddinpcont flexcenterrow">

      <div className="staffaddinpleft flexcenterrowstart">
        <label className="fontlargeb">{props.title}</label>
      </div>
      
      <div className="staffaddinpright flexcenterrowend relativepos">
        <input type="text" onChange={(e) => props.handleEdit(e.target.value)} value = {props.editValue} className="staffinp fontn" maxLength={props.maxlen}/>
      </div>

    </div>
  );
}

const RetAdd = (props) => {
  const[ctype, setCtype] = useState(props.ctype);
  const[btype, setBtype] = useState(props.btype);
  const[cname, setCname] = useState(props.cname);
  const[oname, setOname] = useState(props.oname);
  const[cphone, setCphone] = useState(props.cphone ? GetFormattedPhone2(props.cphone) : props.cphone);
  const[cnum, setCnum] = useState(props.cnum ? GetFormattedCNum2(props.cnum) : props.cnum);
  const[sid, setSid] = useState(props.sid);
  //const[pid, setPid] = useState(props.pid);
  const[addr, setAddr] = useState(props.addr);
  //const[email, setEmail] = useState(props.email);
  const[ct, setCT] = useState(props.ct);
  const[phoneLength, setPhoneLength] = useState(props.cphone ? GetFormattedPhone2(props.cphone).length : 0);
  const[cnumLength, setCnumLength] = useState(props.cnum ? GetFormattedCNum2(props.cnum).length : 0);

  let salesList = null;

  if (IsValidS(props.sales))
  {
    //거래처 등록인경우
    /*if (props.retIdx === -1)
    {
      setSid(props.sales[0].cid);
      setPid(props.sales[0].cid);
    }*/

    salesList = props.sales.map(item => {
      return (
        <option key = {item.idx} value={item.cid}>{item.uname}</option>
      );
    });
  }

  return (
    <div className="staffaddcont flexcentercol">
      <div className="staffaddcontent flexcentercolstart">
        <div className="staffclose flexcenterrowend btncur">
          <img src={GetImgUrl("x_btn2.png")} alt="닫기" className="btncur" onClick={props.handleClose}/>
        </div>

        <div className="stafftitle flexcenterrow">
          <img src={GetImgUrl("uicon.png")} alt="거래처 정보" onClick={props.handleClose}/>
          <label className="fontlargeb">{props.title}</label>
        </div>

        <div className="retaddrow flexcenterrow">
          <div className="retaddrowleft flexcenterrow">
            <RetInp title="상호" handleEdit = {setCname} editValue={cname} maxlen="40"/>
          </div>
          <div className="retaddrowright flexcenterrow">
            <RetInp title="업종" handleEdit = {setCtype} editValue={ctype} maxlen="15"/>
          </div>
        </div>

        <div className="retaddrow flexcenterrow">
          <div className="retaddrowleft flexcenterrow">
            <RetInp title="대표자명" handleEdit = {setOname} editValue={oname} maxlen="20"/>
          </div>
          <div className="retaddrowright flexcenterrow">
            <RetInp title="업태" handleEdit = {setBtype} editValue={btype} maxlen="15"/>
          </div>
        </div>

        <div className="retaddrow flexcenterrow">
          <div className="retaddrowleft flexcenterrow">
            <RetInp title="대표 전화번호" 
              handleEdit = {v => {
                if (v && v.length > phoneLength)
                {
                  setPhoneLength(v.length);
                  setCphone(GetFormattedPhone2(v))
                }
                else
                {
                  setPhoneLength(v.length);
                  setCphone(v)
                }
              }}
              editValue={cphone}  maxlen="15"/>
          </div>
          <div className="retaddrowright flexcenterrow">
            <RetInp title="주소" handleEdit = {setAddr} editValue={addr}  maxlen="150"/>
          </div>
        </div>

        <div className="retaddrow flexcenterrow">
          <div className="retaddrowleft flexcenterrow">
            <RetInp title="사업자 번호" handleEdit = {v =>{
                if (v && v.length > cnumLength)
                {
                  setCnumLength(v.length);
                  setCnum(GetFormattedCNum2(v));
                }
                else
                {
                  setCnumLength(v.length);
                  setCnum(v);
                }
              }}
              editValue={cnum}
              maxlen="40"
            />
          </div>
          
          <div className="retaddrowright flexcenterrow">
            <div className="staffaddinpcont flexcenterrow">
              <div className="staffaddinpleft flexcenterrowstart">
                <label className="fontlargeb">업체구분</label>
              </div>

              <div className="staffaddinpright flexcenterrowend">
                <select className="retaddct fontn  btncur"
                  value={ct}
                  onChange={(e) => {
                    if (LOG_ENABLE)
                      console.log(e.target.value);

                    setCT(parseInt(e.target.value));
                  }}
                >
                  <option value="0">유흥업체</option>
                  <option value="1">일반업체</option>
                </select>  
              </div>
            </div>
          </div>
        </div>

        <div className="retaddrow flexcenterrow">
          <div className="retaddrowleft flexcenterrow">
            <div className="staffaddinpcont flexcenterrow">
              <div className="staffaddinpleft flexcenterrowstart">
                <label className="fontlargeb">영업 담당자</label>
              </div>

              <div className="staffaddinpright flexcenterrowend">
                <select className="retaddct fontn  btncur"
                  value={sid}
                  onChange={(e) => {
                    if (LOG_ENABLE)
                      console.log(e.target.value);

                    setSid(e.target.value);
                  }}
                >
                  {salesList}
                </select>  
              </div>
            </div>
          </div>

          <div className="retaddrowright flexcenterrow">
            {/*
            <div className="staffaddinpcont flexcenterrow">
              <div className="staffaddinpleft flexcenterrowstart">
                <label className="fontlargeb">관리 담당자</label>
              </div>

              <div className="staffaddinpright flexcenterrowend">
                <select className="retaddct fontn  btncur"
                  value={pid}
                  onChange={(e) => {
                    if (LOG_ENABLE)
                      console.log(e.target.value);

                      setPid(e.target.value);
                  }}
                >
                  {salesList}
                </select>  
              </div>
            </div>*/}
          </div>
        </div>

        <div className="retaddrowbtnarea  flexcentercol">
          <button className="bgcolor3 staffaddbtn fontnwb" onClick={() => props.addRet(props.retIdx, ctype,btype,cname,oname,cphone,cnum,sid,/*pid*/sid,addr,/*email*/'n',ct)}>
            {props.btntitle}
          </button>
        </div>
        

      </div>
    </div>
  );
}

export default RetAdd;