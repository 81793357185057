import React from 'react';
import './Staff.css';
import '../../App.css';
import { LOG_ENABLE } from '../../URL/AppUrl';
import { observer } from 'mobx-react';
import Loading from '../Loading/Loading';

//선택된 가입자 이전확인 팝업
class RecmTransConfirm extends React.Component {


  componentDidMount() {
    if (LOG_ENABLE)
      console.log("RecmTransConfirm.componentDidMount");
  }

  componentWillUnmount = () =>
  {
  }

  render()
  {
    return (
      <div className='recmtranspopbg flexcenterrow'>

        <div className='recmtranspop2 flexcentercolstart'>
        
          <div className='cntfullh flexcenterrow marginbottom10 margintop20'>
            <label className='fontlargeb'>{`"${this.props.item.nm}"님 가입자 개별 이전`}</label>
          </div>

          <div className='cntfullh flexcenterrow marginbottom10 margintop20'>
            <label className='fontn'>선택한 가입자</label>
            <label className='fontn3'>&nbsp;{`${this.props.transCount}`}</label>
            <label className='fontn'>{`개를`}&nbsp;</label>
            <label className='fontn3'>&nbsp;{`"${this.props.titem.cc}(${this.props.titem.nm})"`}</label>
            <label className='fontn'>&nbsp;추천인으로 이전합니다.</label>
          </div>

          <div className='cntfullh flexcenterrow marginbottom10 margintop10'>
            <label className='fontn'>확인을 누르면 이전이 완료됩니다.</label>
          </div>

          <div className='flexcenterrow recmtransallbtn spacebetween margintop20'>
            <button className='recmchgbtn2 fontnwb btncur bgcolor4' onClick={() => this.props.handleTransConfirm(false)}>취소</button>
            <button className='recmchgbtn2 fontnwb btncur bgcolor3' onClick={() => this.props.handleTransConfirm(true)}>변경완료</button>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(RecmTransConfirm);