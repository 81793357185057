import { useState, Fragment } from "react";
import { GetImgUrl, LOG_ENABLE } from "../../URL/AppUrl";
import { IsValidS, IsValidV } from "../../Util/Util";

const SelectFile = (props) =>
{
  return (
    <Fragment>
      <label htmlFor="fileInputx1" className="chatfileselfont fontlargeb">파일 보내기&nbsp;&nbsp;</label>
      <input type="file" id="fileInputx1" className="pdinputfilestyle"
        onChange={(e) => {
          if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              return;

          if (LOG_ENABLE)
            console.log(e.target.files[0]);

            props.sendFile(e.target.files[0].name, e.target.files[0]);
      }}/>
    </Fragment>
  );
}

const SelectImg = (props) =>
{
  return (
    <Fragment>
      <label htmlFor="fileInputx2" className="chatfileselfont fontlargeb">이미지 보내기</label>
      <input type="file" id="fileInputx2" className="pdinputfilestyle"  accept="image/png, image/jpeg"
        onChange={(e) => {

          if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              return;

          if (LOG_ENABLE)
            console.log(e.target.files[0]);

            props.sendImage(e.target.files[0].name, e.target.files[0]);
      }}/>
    </Fragment>
  );
}

const setMsgLimit = (msg, handler) =>
{
  if (msg === undefined || msg === null)
  {
    handler('');
    return;
  }

  if (msg.length < 190)
  {
    handler(msg);
  }

  handler(msg.substring(0, 190));
};

function GetMsgLength(msg)
{
  if (msg === undefined || msg === null)
    return 0;

  return msg.length;
}

//채팅 입력창
const ChatInput = (props) =>
{
  const [chatmsg, setChatMsg] = useState('');

  return (
    <div className="chatinputarea flexcentercol">
      <div className="chatinputareatop flexcenterrow">

        <div className="chatinputareatoparea flexcenterrow">
          <div className="chatinputareatopleft flexcentercolstart">
            {
              props.selectedIdx === -1 ?
                (
                  <textarea className="chatinputareachat fontlargeb" onChange={(e) => {}} value={''} 
                    placeholder="대화 목록에서 거래처를 선택 후 메세지를 입력해 주세요." disabled> 
                  </textarea>
                )
                :(
                  <textarea className="chatinputareachat fontlargeb" onChange={(e) => setMsgLimit(e.target.value, setChatMsg)} value={chatmsg} 
                    placeholder="메세지 내용을 입력해주세요."
                    onKeyPress = {(e) => {
                      if (e.key === "Enter")
                      {
                        const chat = chatmsg;
                        setChatMsg("");

                        if (props.selectedIdx !== -1)
                          props.handleSendTalk(chat);
                      }
                    }}>
                  </textarea>
                )
            }
          </div>

          <div className="chatinputareatopright flexcentercolend">
            <label className="msglengthfont">{GetMsgLength(chatmsg) + "/190"}</label>
            <button className={props.selectedIdx === -1 ? "chatsendbtnbgdis fontnwb" : "bgcolor1 chatsendbtnbg fontnwb"} 
              onClick={()=>{
                const chat = chatmsg;
                setChatMsg("");
                props.handleSendTalk(chat);
              }}
            >
              {props.selectedIdx === -1 ? "전송불가" : "전송"}
            </button>
          </div>
        </div>
        
      </div>

      <div className="chatinputareabottom flexcenterrowstart">

        {
        props.selectedIdx !== -1 ?
        (<Fragment>
          <img src={GetImgUrl("chatclip.png")} alt="첨부파일"/>
          <SelectFile sendFile={(fileName, file) =>{props.sendFile(fileName, file)}} />
          <img src={GetImgUrl("chatimg.png")} alt="첨부이미지"/>
          <SelectImg sendImage={(fileName, file) =>{props.sendImage(fileName, file)}} />
        </Fragment>) : null
        }
      </div>

    </div>
  );
}

export default ChatInput;