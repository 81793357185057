import React from 'react';

import {
  action,
  computed,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  
} from 'mobx';

import socketIOClient from "socket.io-client";
import { GetOPChatServerUrl, LOG_ENABLE } from '../URL/AppUrl';
import { FromBase64, IsValidS, IsValidV, NumberFormatString, ToBase64,  } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import axios from 'axios';

class OPChatStore {

  //observable
  chatList = null; //대화 목록
  newChatCount = 0;

  //비observable
  compIdx = 1; //유저의 소속회사 고유번호
  userIdx = -1; //유저의 고유번호
  userName = null; //유저이름
  wholeSalesIdx = 1; //담당 도매상 고유번호
  socket = null;
  connected = false;
  refIdx = -1;
  chatListLoading = false;
  totalChatCount = 0;
  currentTotal = 0; //현재까지 수신된 채팅의 개수

  clearAll = () =>
  {
    this.chatList = null; //대화 목록

    //비observable
    this.compIdx = -1; //유저의 소속회사 고유번호
    this.userIdx = -1; //유저의 고유번호
    this.userName = null; //유저이름
    this.wholeSalesIdx = 1; //담당 도매상 고유번호

    this.refIdx = -1;
    this.chatListLoading = false;
    this.newChatListCallback = null;
    this.totalChatCount = 0;
    this.tempIdx = -1;
    this.currentTotal = 0; //현재까지 수신된 채팅의 개수
    this.newChatArrivedCallback = null;
    this.newChatCount = 0;
    this.loadChatCountCallback = null;

    if (this.socket !== null)
    {
      try
      {
        this.socket.disconnect();
        this.socket = null;
      }
      catch(e)
      {
        console.log(e.toString());
      }
    }
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      chatList : observable,
      newChatCount : observable,

      clearAll : action,
      connect : action,
      onMessageReceived : action,
      loadLastChatList : action,

      onNewChat : action,
      onLoadChatListResult : action,

      getTotalChatCount : computed,

      loadNewChatCount : action,
      parseChatCountResult : action,
    });
  }

  get getTotalChatCount()
  {
    if (!IsValidS(this.chatList))
      return 0;

    return this.chatList.length;
  }

  connect = (compIdx, userIdx, userName, handleLog) =>
  {
    this.connected = false;
    this.compIdx = compIdx;
    this.userIdx = userIdx;
    //this.wholeSalesIdx = wholeSalesIdx;
    this.userName = ToBase64(userName); //base64인코딩되어 있음

    this.socket = socketIOClient(GetOPChatServerUrl(), {/*autoConnect: false,*/ transports: ['websocket', 'AJAX long-polling', 'polling']});

    let instance = this;

    //this.socket.connect();

    this.socket.on("connect", () => {
      if (LOG_ENABLE && handleLog)
        handleLog("connected!!");

      instance.connected = true;
      instance.refIdx = -1;
      instance.chatList = null;
      instance.sendUserInfo(); //사용자 정보를 설정하고
    });
    
    this.socket.on("msg", (data) => {
      instance.onMessageReceived(data, instance);
    });

    this.socket.on("disconnect", (reason) => {

      if (LOG_ENABLE)
        console.log("disconnected : " + reason);

      instance.connected = false;
    });

    
  }

  onMessageReceived = (data, instance) =>
  {
    if (LOG_ENABLE)
      console.log(`achat recv msg : ${data}`);

    if (IsValidS(data))
    {
      try
      {
        let json = JSON.parse(data);

        if (!IsValidV(json) || !IsValidV(json.id))
        {
          if (LOG_ENABLE)
            console.log("invalid json");
          return;
        }

        switch(json.id)
        {
          case 0: //사용자 정보의 설정이 완료되었다.
            instance.loadLastChatList();
            break;
          case 1: //새로운 채팅메시지 수신
            instance.onNewChat(json);
            break;
          case 3: //채팅목록 조회결과가 수신됨
            instance.onLoadChatListResult(json);
            break;
          default:
            break;
        }
      }
      catch(e)
      {
        console.log(e.toString());
      }
    }
  }

  sendMessage = (msg) =>
  {
    try{
      this.socket.emit('msg', msg);
    }
    catch(e)
    {
      console.log(e.toString());
    }
  }

  //채팅서버 접속후 사용자 정보 설정--------------------------------------------------------------------------------------------------------
  sendUserInfo = () =>
  {
    this.sendMessage(JSON.stringify({id : 0, uidx : this.userIdx, cidx : this.compIdx, widx : this.wholeSalesIdx, uname : this.userName}));
  }
  //파일전송메시지발송----------------------------------------------------------------------------------------------------------------------
  sendFile = (newName, oldName) =>
  {
    this.sendMessage(JSON.stringify({id : 1, ct : 6, chat : oldName, fname: newName}));
  }
  //채팅메시지 발송-------------------------------------------------------------------------------------------------------------------------
  newChatArrivedCallback = null;

  sendChat = (chatMsg) =>
  {
    if (IsValidS(chatMsg))
      this.sendMessage(JSON.stringify({id : 1, ct : 1, chat : chatMsg, fname:'n'}));
  }

  //신규 채팅 메시지가 수신됨
  onNewChat = (result) =>
  {
    if (LOG_ENABLE)
      console.log("onNewChat : " + JSON.stringify(result));

    if (IsValidV(result.chat))
    {
      this.decodeChatItem(result.chat);

      if (!IsValidS(this.chatList))
        this.chatList = [result.chat];
      else
      {
        if (this.checkTalkDate(this.chatList[this.chatList.length - 1], result.chat))
          this.chatList = [...this.chatList, result.chat];
        else
          this.chatList = [...this.chatList, {idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(result.chat.year, 
            result.chat.month, result.chat.day)}, result.chat];
      }

      if (this.newChatArrivedCallback)
      {
        this.newChatArrivedCallback();
      }
    }
  }

  checkTalkDate = (talk1, talk2) =>
  {
    if (talk1.year !== talk2.year || talk1.month !== talk2.month || talk1.day !== talk2.day)
      return false;

    return true;
  }
  //-------------------------------------------------------------------------------------------------------------------------
  newChatListCallback = null;
  tempIdx = -1;

  loadLastChatList = () =>
  {
    if (this.chatListLoading)
    {
      if (LOG_ENABLE)
        console.log("loadLastChatList : already loading");

      return;
    }

    this.chatListLoading = true;
    //this.chatList = null;

    this.sendMessage(JSON.stringify({id : 3, uidx : this.userIdx, refidx: this.refIdx}));
  }

  onLoadChatListResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("onLoadChatListResult : " + JSON.stringify(result));

    this.chatListLoading = false;

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("invalid result");

        if (this.newChatListCallback !== null)
          this.newChatListCallback(0);
      return;
    }

    //더이상 읽을 채팅이 없는경우
    if (!IsValidS(result.list))
    {
      //기존에 채팅 목록이 있다면 목록 상단에 날짜 정보를 추가해준다.
      if (IsValidS(this.chatList))
      {
        if (this.chatList[0].ctype !== 5)
        {
          let finalList = [{idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(this.chatList[0].year, this.chatList[0].month, 
            this.chatList[0].day)}, ...this.chatList];

          this.chatList = finalList;
        }
      }

      return;
    }

    this.currentTotal += result.list.length;

    if (this.refIdx === -1 && IsValidV(result.cnt))
    {
      this.totalChatCount = result.cnt;
    }
    
    result.list.forEach((item) => {
      this.decodeChatItem(item);

      if (this.refIdx === -1 || this.refIdx > item.idx)
        this.refIdx = item.idx;
    });

    result.list.sort(function (a, b){
      if (a.idx > b.idx)
        return 1;
      
      if (a.idx === b.idx)
        return 0;

      return -1;
    });

    let list = this.addDateInfo(result.list, 30);

    if (!IsValidS(this.chatList))
      this.chatList = list;
    else
      this.chatList = [...list, ...this.chatList];

    if (this.newChatListCallback !== null)
      this.newChatListCallback(result.list.length);
  }

  decodeChatItem = (item) =>{
    if (IsValidS(item.chat))
      item.chat = FromBase64(item.chat);

    if (IsValidS(item.fname) && item.fname !== "n")
      item.fname = FromBase64(item.fname);

    if (IsValidS(item.rdate))
    {
      let temp = item.rdate.split(" ");

      if (temp.length < 2)
      {
        return;
      }

      let date = temp[0].split("-");

      if (date.length !== 3)
        return;

      item.year = parseInt(date[0]);
      item.month = parseInt(date[1]);
      item.day = parseInt(date[2]);

      temp = temp[1].split(":");

      if (temp < 3)
      {
        return;
      }

      item.hour = temp[0];
      item.min = temp[1];
      //item.sec = temp[2];

      if (parseInt(temp[0]) < 12)
      {
        item.time = "오전 " + temp[0] + ":" + temp[1];
      }
      else
      {
        item.time = "오후 " + temp[0] + ":" + temp[1];
      }

      if (LOG_ENABLE)
        console.log(`${item.year}-${item.month}-${item.day} ${item.hour}:${item.min}`);
    }
  }
  //-------------------------------------------------------------------------------------------------------------------------
  addDateInfo = (chatList, needCount) =>
  {
    try
    {
      let anotherDayFound1 = false;
      let anotherDayFound2 = false;

      if (!IsValidS(chatList))
        return chatList;
  
      let year = chatList[0].year;
      let month = chatList[0].month;
      let day = chatList[0].day;
  
      for (let i = 1;i < chatList.length; ++i)
      {
        if (chatList[i].year !== year || chatList[i].month !== month || chatList[i].day !== day)
        {
          anotherDayFound1 = true;
          break;
        }
      }

      if (IsValidS(this.chatList))
      {
        if (this.chatList[0].year !== year || this.chatList[0].month !== month || this.chatList[0].day !== day)
        {
          anotherDayFound2 = true;
        }
      }

      if (!anotherDayFound1 && !anotherDayFound2)
      {
        //더이상 읽을 채팅이 없다는 의미다. 상단에 날짜 정보를 붙여줘야한다.
        if (chatList.length < needCount)
        {
          return [{idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(chatList[0].year, chatList[0].month, 
            chatList[0].day)}, ...chatList];
        }

        return chatList;
      }

      let newList = [];

      //더이상 읽을 채팅이 없다는 의미다. 상단에 날짜 정보를 붙여줘야한다.
      if (chatList.length < needCount)
      {
        newList.push({idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(chatList[0].year, chatList[0].month, 
          chatList[0].day)});
      }

      newList.push(chatList[0]);

      for (let i = 1;i < chatList.length; ++i)
      {
        if (chatList[i].year !== year || chatList[i].month !== month || chatList[i].day !== day)
        {
          year = chatList[i].year;
          month = chatList[i].month;
          day = chatList[i].day;

          newList.push({idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(year, month, day) });
        }

        newList.push(chatList[i]);
      }

      if (anotherDayFound2)
      {
        newList.push({idx : --this.tempIdx, ctype : 5, date : this.getDateInfo(this.chatList[0].year, this.chatList[0].month, this.chatList[0].day) });
      }

      return newList;
    }
    catch(e)
    {
      return chatList;
    }
  }

  getDateInfo = (year, month, day) =>
  {
    let date = new Date(year, month - 1, day, 0, 0, 0, 0);
    let dayOfWeek = null;

    switch(date.getDay())
    {
      case 0:
        dayOfWeek = " 일요일";
        break;
      case 1:
        dayOfWeek = " 월요일";
        break;
      case 2:
        dayOfWeek = " 화요일";
        break;
      case 3:
        dayOfWeek = " 수요일";
        break;
      case 4:
        dayOfWeek = " 목요일";
        break;
      case 5:
        dayOfWeek = " 금요일";
        break;
      case 6:
      default:
        dayOfWeek = " 토요일";
        break;
    }

    return year.toString() + "/" + NumberFormatString(month) +"/" + NumberFormatString(day) + dayOfWeek;
  }

  //운영자로 부터 수신한 신규 채팅 개수 -------------------------------------------------------------------------------------------------------------------------------------
  loadChatCountCallback = null;

  makeChatCountParam () {
    const params = new URLSearchParams();

    params.append("rt", "4");
    params.append("uidx", LoginStoreInstance.userIdx.toString());
    return params;
  }

  loadNewChatCount = (callback) =>
  {
    this.loadChatCountCallback = callback;
    this.newChatCount = 0;

    axios({
      method:"POST",
      url: "chat/cc.do",
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeChatCountParam()
    }).then((res)=>{
        this.parseChatCountResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseChatCountResult(null);
    });
  }

  parseChatCountResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseChatCountResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadChatCountCallback != null)
        this.loadChatCountCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      this.newChatCount = result.cnt;
    }

    if (this.loadChatCountCallback != null)
      this.loadChatCountCallback(result.ret);
  }
}

//-------------------------------------------------------------------------------------------------------------------------------------------------
const OPChatStoreInstance = new OPChatStore();
const OPChatStoreContext = React.createContext(OPChatStoreInstance);

const UseOPChatStore = () => React.useContext(OPChatStoreContext);

export {UseOPChatStore, OPChatStoreInstance};
