import { useState } from "react";
import { GetAppImgUrl } from '../../URL/AppUrl';
import './MnuOrder.css';

function GetProductInfo(item)
{
  return `${item.icode}/${item.ivol}ml/${item.cnt}병`;
}

const MnuOrderItemInp = (props) =>
{
  const [count, setCount] = useState(0);

  return (
    <div className="flexcenterrow marginright10">
      <label className="fontnb">{props.unit}</label>
      <input type="number" onChange={(e) => setCount(parseInt(e.target.value))} value = {count < 1 ? '' : count} 
        className="oditeminfocnt" placeholder="갯수 입력"
      />

      <button className="bgcolor1 mnuoditeminfocart fontnw" onClick = {() => {

        if (count < 1)
        {
          alert("담을 상품 개수를 입력하세요.");
        }
        else
          props.addItem(count);
        }}>
        담기
      </button>
    </div>
  );
}

const MnuOrderAddItem = (props) =>
{
  if (props.mode === 0) //최근 상품 목록인경우
  {
    return (
      <div className="flexcenterrow mnuodpditem">
        <div className="flexcentercol mnuodpditemleft">
          <div className="flexceterrow mnuodimgbg">
            <img src={GetAppImgUrl(props.item.img)} alt="상품"  className="mnuorderimg"/>
          </div>
        </div>

        <div className="flexcentercol mnuodpditemcenter">
          <label className="fontnb">{props.item.iname}</label>
          <label className="fontn">{GetProductInfo(props.item)}</label>
        </div>
  
        <div className="flexcentercol mnuodpditemright">
          {
            props.item.unit === 0 || props.item.unit === 2 || props.item.unit === 3 ? (
              <MnuOrderItemInp addItem = {(count) => {props.handleAddItem(props.item.iname, props.item.iidx, count, 0)}} unit="박스&nbsp;"/>
            ) : null
          }
          {
            props.item.unit === 1 || props.item.unit === 2 || props.item.unit === 4 ? (
              <MnuOrderItemInp addItem = {(count) => {props.handleAddItem(props.item.iname, props.item.iidx, 0, count)}} unit="병&nbsp;"/>
            ) : null
          }
        </div>
      </div>
    );
  }
  else //검색된 상품 목록인경우
  {
    return (
      <div className="flexcenterrow mnuodpditem">
        <div className="flexcentercol mnuodpditemleft">
          <div className="flexceterrow mnuodimgbg">
            <img src={GetAppImgUrl(props.item.img)} alt="상품" className="mnuorderimg"/>
          </div>
        </div>

        <div className="flexcentercol mnuodpditemcenter">
          <label className="fontnb">{props.item.iname}</label>
          <label className="fontn">{GetProductInfo(props.item)}</label>
        </div>
  
        <div className="flexcentercol mnuodpditemright">
          {
            props.item.unit === 0 || props.item.unit === 2 || props.item.unit === 3 ? (
              <MnuOrderItemInp addItem = {(count) => {props.handleAddItem(props.item.iname, props.item.idx, count, 0)}} unit="박스&nbsp;"/>
            ) : null
          }
          {
            props.item.unit === 1 || props.item.unit === 2 || props.item.unit === 4 ? (
              <MnuOrderItemInp addItem = {(count) => {props.handleAddItem(props.item.iname, props.item.idx, 0, count)}} unit="병&nbsp;"/>
            ) : null
          }
        </div>
      </div>
    );
  }
  
}

export default MnuOrderAddItem;