import React from 'react';
import './MenuIcon.css';

const MenuIcon = (props) => {
  return (
    <div className="iconcontainer flexcentercolstart" onClick={() => props.handleClick(props.index)}>
      <div className="iconround flexcentercol">
        <img src={props.img}  alt={props.title} className="iconimgm"/>
      </div>
      <label className="icontitle fontlargeb">{props.title}</label>
      <label className="btncur fontmid">{props.desc}</label>
    </div>
    
  );
}

export default MenuIcon;