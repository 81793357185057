import React from 'react';

const ChatAppEnd = (props) =>
{
  return (
    <div className="achatitemend">

      <div className="achatitemendline"></div>
      <label className="achatitemendfont">상담원과의 채팅이 종료되었습니다.</label>
    </div>
  );
}

export default ChatAppEnd;