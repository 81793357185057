import OrderItemvomb from "./OrderItemvomb";

const OrderItemListvomb = (props) =>
{
  let items = null;

  if (props.itemList !== undefined && props.itemList !== null && props.itemList.length > 0)
  {
    items = props.itemList.map(item => {
      return (<OrderItemvomb key={item.idx} item={item} orderIdx = {props.order.idx} handleEditVoItem = {props.handleEditVoItem}/>);
    });
  }

  return (
    <div className = "tablecontmb">
      <table className="emptablemb">
        <thead>
          <tr>
            <th className="emptableheadermb">
              <label className="mfontnw">상품번호</label>
            </th>

            <th className="emptableheadermb">
              <label className="mfontnw">상품명</label>
            </th>

            <th className="emptableheadermb">
              <label className="mfontnw">용량</label>
            </th>

            <th className="emptableheadermb">
              <label className="mfontnw">주문갯수</label>
            </th>

            <th className="emptableheadermb">
              <label className="mfontnw">수정/삭제</label>
            </th>
          </tr>
        </thead>
        <tbody>
        {items}
        </tbody>
      </table>
    </div>
  );
};

export default OrderItemListvomb;