import React from "react";
import { ODS_ACCEPT, ODS_REORDER } from "../../Stores/OrderStore";
import { GetImgUrl, GetSoundUrl, LOG_ENABLE } from "../../URL/AppUrl"

class OrderMiscVoice extends React.Component
{
  state = {
    playing : false,
  };

  audioData = null;

  componentDidMount()
  {
    
  }

  componentWillUnmount()
  {
    if (this.audioData != null)
    {
      this.audioData.pause();
      this.audioData = null;
    }
  }

  initSound = () =>
  {
    this.audioData = new Audio(GetSoundUrl(this.props.order.sfile));
    this.audioData.load();
    
    this.audioData.addEventListener('loadeddata', () => {
      //this.setState((prevState) => ({...prevState, time : this.getAudioTime()}));
    });

    this.audioData.addEventListener('playing', () => {

      if (LOG_ENABLE)
        console.log("audio playing");

      this.audioPlaying = true;
      this.setState((prevState) => ({...prevState, playing : true}));
    });

    this.audioData.addEventListener('pause', () => {

      if (LOG_ENABLE)
        console.log("audio paused");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, playing : false}));
    });

    this.audioData.addEventListener('ended', () => {

      if (LOG_ENABLE)
        console.log("audio ended");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, playing : false}));
    });

    this.audioData.addEventListener('error', () => {
      if (LOG_ENABLE)
        console.log("audio error");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, playing : false}));
    });
  }

  playVoice = () =>
  {
    if (this.audioData == null || !this.state.playing)
    {
      if (this.audioData == null)
        this.initSound();
        
      this.audioData.currentTime = 0;
      this.audioData.play();
    }
    else
      this.audioData.pause();
  }

  getOrderStatus = (order) =>
  {
    switch(order.st)
    {
      case ODS_ACCEPT:
        return "아직 처리되지 않은 주문입니다. 주문을 청취 후 주문 처리 완료를 눌러주세요.";

      case ODS_REORDER:
        return "재주문 요청되었습니다.";

      default:
        return "주문 처리 완료";
    }
  }

  render()
  {
    return (
      <div className = "ordertablecont">
  
        <div className="empsertitle flexcenterrowstart">
  
          <div className="orderadditemcontl flexcenterrowstart">
            <label className={(this.props.order.st === ODS_ACCEPT || this.props.order.st === ODS_REORDER) ? "orderverrfontsm" : "ordervnfontsm"}>&nbsp;{this.getOrderStatus(this.props.order)}</label>
          </div>
        
          <div className="orderadditemcontr flexcenterrowend">

            {
              this.props.order.st === ODS_ACCEPT ? (
                <button className="bgcolor2 orderadditembtn fontnwb" onClick={() => this.props.handleReorder(this.props.order.idx)}>
                  재주문 요청
                </button>
              ):
              null
            }

            {
              this.props.order.st === ODS_ACCEPT ? (
                <button className="bgcolor3 orderadditembtn fontnwb" onClick={() => this.props.addItemCompleted(this.props.order.idx)}>
                  주문 처리 완료
                </button>
              ):
              null
            }
          </div>
        </div>

        <table className="emptable">
          <thead>
            <tr>
              <th className="emptableheader">
                <label className="fontnw">업체명</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">대표자명</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">휴대폰</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">주소</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">영업 담당자</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">녹음 재생</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="emptabledata">
              <td className="ordercellcnamevo">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.props.order.cname}</label>
                </div>
              </td>
              
              <td className="ordercellunamevo">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.props.order.uname}</label>
                </div>
              </td>
  
              <td className="ordercellphonevo">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.props.order.phone}</label>
                </div>
              </td>
  
              <td className="emptablecell">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.props.order.addr}</label>
                </div>
              </td>
  
              <td className="ordercellsnamevo">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.props.order.sname}</label>
                </div>
              </td>
  
              <td className="ordercellvoice">
                <div className="emptablecellin flexcenterrow">
                  <img src={GetImgUrl(this.state.playing ? "stop.png" : "play.png")} className="btncur" onClick={this.playVoice} alt="재생"/>
                </div>
              </td>
  
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default OrderMiscVoice;