//import { forwardRef } from "react";
import OrderAddItem from "./OrderAddItem";

//const OrderAddItemList = forwardRef((props, ref) =>
const OrderAddItemList = (props) =>
{
  const itemList = props.items;

  let items = null;

  if (itemList != null && itemList.length > 0)
  {
    items = itemList.map(item => {
      return (<OrderAddItem key={item.idx} item = {item} itemMode = {props.itemMode} addItem = {props.addItem}/>);
    });
  }

  /*
  return (
    <div className="oditemlist flexcentercolstart" ref={ref}>
      {items}
    </div>
  );*/
  return (
    <div className="oditemlist flexcentercolstart">
      {items}
    </div>
  );
}
//});

export default OrderAddItemList;