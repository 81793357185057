import React from 'react';
import './MenuIcon.css';

//모바일용 메인 메뉴 아이콘
const MenuIconmb = (props) => {
  return (
    <div className="flexcentercol iconcontmb" onClick={() => props.handleClick(props.index)}>
      <img src={props.img}  alt={props.title} className="btncur"/>
      <div className="ttrside flexcenterrow">
        <label className="btncur mfontlargeb">{props.title}</label>
        {
          props.newItem ? (<div className="menunewitemmb"/>) : null
        }
      </div>
      <label className="btncur mfontlarge">{props.desc}</label>
    </div>
    
  );
}

export default MenuIconmb;