import { observer } from 'mobx-react';
import React from 'react';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { RetailerStoreInstance } from '../../Stores/RetailerStore';
import { ProcessLogout } from '../../Stores/StoreManager';
import { GetImgUrl, LOG_ENABLE } from '../../URL/AppUrl';
import PageList from '../PageList';
import RetInfomb from './RetInfomb';

class RetBanListmb extends React.Component
{
  state = {
    loading: false,
    searchList : false,
    retIdx : -1,
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 0, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 0, //전체 페이지 개수
  };

  searchMode = 0;

  setLoading = (loading) =>
  {
    this.setState((prevState) => ({...prevState, loading : loading}));

    if (this.props.setLoading)
      this.props.setLoading(loading);
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("RetBanList.componentDidMount");

    this.intervalId = setInterval(this.update, 1000);

    RetailerStoreInstance.clearBanRetList();

    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);
      return;
    }

    this.loadRetList(1);
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }

  calcPageInfo = () =>
  {
    let currentCount = RetailerStoreInstance.totalBanCount;

    let pageCount = Math.floor(currentCount / 30);

    if ((currentCount % 30) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = 3;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  onLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    this.loadRetList(1);
  }

  loadRetList = (page) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    RetailerStoreInstance.loadRetailer((page - 1) * 30, 1, null, this.onLoadListResult);
  }

  onLoadListResult = (resultCode, totalCount, isFirst) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert("목록 읽기 실패 입니다.");
      return;
    }

    if (isFirst)
    {
      this.calcPageInfo();
    }
  }

  //업체 목록 리스트의 체크 박스
  onChildCheckChanged = (index) =>
  {
    if (LOG_ENABLE)
      console.log("onChildCheckChanged : " + index);

    //체크된 직원 정보외의 정보는 체크 해제하고
    //체크된 직원의 정보를 저장함

    if (index === this.state.retIdx)
      this.setState((prevState) => ({...prevState, retIdx : -1}));
    else
      this.setState((prevState) => ({...prevState, retIdx : index}));
  }

  //하단 페이지 변경 버튼 눌릴경우
  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4
  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = 3;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadRetList(1);
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - 3;
      let endPage = this.state.endPage - 3;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadRetList(startPage);
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + 3;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadRetList(startPage);
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - 3;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadRetList(endPage);
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadRetList(pageNum);
    }
  }

  //검색어 바뀜
  onSearchChanged = (e) => {
    
    RetailerStoreInstance.setSearchWord(e.target.value);
  }

  handleListMode = () =>
  {
    this.setState((prevState) => ({...prevState, searchList : false}));

    this.loadRetList(this.state.currentPage);
  }

  //업체 검색하기
  handleSearch = () =>
  {
    if (LOG_ENABLE)
      console.log("handleSearch");

    if (!RetailerStoreInstance.isSearchWordValid())
    {
      if (this.searchMode === 0)
      {
        alert("검색 할 업체 이름을 입력하세요.");
        return;
      }
      else if (this.searchMode === 1)
      {
        alert("검색 할 업체 대표자명을 입력하세요.");
        return;
      }
      else if (this.searchMode === 2)
      {
        alert("검색 할 업체 사업자번호를 입력하세요.");
        return;
      }
      else
      {
        alert("검색 할 업체 전화번호를 입력하세요.");
        return;
      }
    }

    this.setState((prevState) => ({...prevState, searchList : true}));
    this.setLoading(true);
    
    RetailerStoreInstance.searchRetailer(this.searchMode, 1, LoginStoreInstance.getUserId, this.onSearchResult);
  }

  onSearchResult = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert("거래처 검색에 실패 했습니다.");
    }
  }

  //거래처 차단해제 버튼 눌림
  handleBanRet = () =>
  {
    if (LOG_ENABLE)
      console.log("handleBanRet");

    if (this.state.retIdx === -1)
    {
      alert("차단 해제할 업체를 선택하세요.");
      return;
    }

    this.editInfo = RetailerStoreInstance.findRetailerBanByIdx(this.state.retIdx);

    if (this.editInfo === null)
    {
      alert("차단 해제할 업체를 선택하세요.");
      return;
    }

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert("거래처 차단 해제 권한이 없습니다.");
      return;
    }
    
    if (!window.confirm(this.editInfo.cname + "(" + this.editInfo.cnum + ") 업체를 차단 해제 할까요?"))
    {
      return;
    }

    this.setLoading(true);
    RetailerStoreInstance.banRetailer(this.state.retIdx, 1, this.onBanResult);
  }

  //업체 차단 해제결과
  onBanResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0: //정상
        alert('거래처의 주문 차단이 해제되었습니다.');
        break;
      case 1: //잘못된 요청
        alert('잘못된 요청입니다.');
        break;
      case 2: //권한 없음
        alert('업체를 차단해제할 권한이 없습니다.');
        break;
      case 4:
        alert('해당 업체를 찾을 수 없습니다.');
        break;
      case 5:
        alert('해당 업체는 현재 N-돌핀 앱에 가입되어 있지 않습니다.');
        break;
      case 6:
        alert('해당 업체는 현재 거래 상대가 아닙니다.');
        break;
      case 3: //차단 실패
      default:
        alert('업체 차단해제에 실패 했습니다.');
        break;
    }
  }

  onCheckChanged = (v) =>
  {

  }

  render()
  {
    const retList = RetailerStoreInstance.getRetBanList;

    let ret = null;

    if (retList !== null && retList.length > 0)
    {
      const detailHandler = this.props.handleDetail;
      ret = retList.map(item => {
        return (<RetInfomb key={item.idx} index={item.idx} onCheckChanged = {this.onChildCheckChanged} cname={item.cname} cphone={item.cphone} 
          oname={item.oname} cnum={item.cnum} sid={item.sid} pid={item.pid} sname={item.sname} pname={item.pname} addr={item.addr} selectIdx={this.state.retIdx} 
          handleDetail = {detailHandler} 
          detType={1}/>);
      });
    }

    return (
      <div className="retbanpopcont flexcenterrow">
        <div className="retbanpopmb flexcentercolstart">

          <div className="retbantitlemb flexcenterrow">
            <div className="dcell1"></div>
            <div className="dcell8 flexcenterrow">
              <label className="mfontnb">거래처 차단 관리</label>
            </div>
            <div className="dcell1 flexcenterrow">
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={this.props.handleClose} className="btncur retbantopclosebtn"/>
            </div>
            
          </div>

          <div className="flexcenterrow retbansearchmb">
            <select className="retbansearchselmb mfontn  btncur"
              onChange={(e) => {
                if (LOG_ENABLE)
                  console.log(e.target.value);

                this.searchMode = parseInt(e.target.value);
              }}
            >
              <option value="0">상호</option>
              <option value="1">대표자명</option>
              <option value="2">사업자번호</option>
              <option value="3">전화번호</option>
            </select>

            <input type="text" onChange={this.onSearchChanged} value = {RetailerStoreInstance.searchWord} className="retbansearchinpmb mfontn" />

            <button className="retbansearchbtnmb mfontnwb  btncur" onClick={this.handleSearch}>
              검색
            </button>
          </div>

          <div className="retbaneditmb flexcenterrowstart">
            <input type="checkbox" onChange={this.onCheckChanged} checked = {false} className="chk"/>
            <label className="btncur mfontnb">&nbsp;&nbsp;선택한 정보를&nbsp;&nbsp;</label>

            <button className="bgcolor2 retbandbtnmb mfontnwb btncur" onClick = {this.handleBanRet}>
              차단해제
            </button>

            {
              this.state.searchList ? (
                <button className="mfontnwb retlmbtnmb" onClick = {this.handleListMode}>
                  목록보기
                </button>
              ):
              null
            }
          </div>

          <div className = "retbantablecontmb">
            <table className="retbantable">
              <thead>
                <tr>
                  <th className="emptableheader">
                    {/*<input type="checkbox" onChange={this.onCheckChanged} checked = {false} className="chk"/>*/}
                  </th>

                  <th className="emptableheader">
                    <label className="mfontnw">업체명</label>
                  </th>

                  <th className="emptableheader">
                    <label className="mfontnw">상세정보 확인</label>
                  </th>
                </tr>
              </thead>
              <tbody>
              {ret}
              </tbody>
            </table>
          </div>

          {
            ret === null ? (<label className="mfontnb retbannolist">차단된 업체가 없습니다.</label>) : null
          }
              
          {
            this.state.pageCount > 1 ? <PageList handlePage={this.handlePage} 
              first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
              next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
              currentPage={this.state.currentPage} 
              startPage={this.state.startPage} endPage={this.state.endPage}/> : 
            null
          }
        </div>
      </div>
    );
  }
}

export default observer(RetBanListmb);