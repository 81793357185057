import { useState } from "react";
import { GetAppImgUrl,  } from "../../URL/AppUrl";
import { IsValidS,  } from "../../Util/Util";

function GetItemCount(item)
{
  if (item.unit === 3 || item.unit === 4)
    return `${item.cnt}개`;

  return `${item.cnt}입`;
}

function GetItemInfo(item)
{
  if (IsValidS(item.ivol))
  {
    if (item.ivol.indexOf('g') >= 0)
      return `${item.icode} / ${item.ivol} / ${GetItemCount(item)}`;

    return `${item.icode} / ${item.ivol}ml / ${GetItemCount(item)}`;
  }
  else
  {
    return `${item.icode} / ${GetItemCount(item)}`;
  }
}

function GetGoodsImage(item)
{
  return item.img;
  //return "testitem.png";
}

function GetCountTypeName(countType)
{
  if (countType === 0)
    return "박스";
  else if (countType === 1)
    return "병";

  return "개";
}

const OrderAddInput = (props) =>
{
  const [count, setCount] = useState(0);

  if (props.onetype)
  {
    return (
      <div className="oditemcnt2mb flexcenterrow">
        <label className="mfontnb">{GetCountTypeName(props.counttype)}&nbsp;&nbsp;</label>
  
        <input type="number" onChange={(e) => setCount(parseInt(e.target.value))} value = {count < 1 ? '' : count} 
          className="oditeminfocntmb" placeholder="갯수입력"
        />
  
        <button className="bgcolor1 oditeminfocartmb mfontnw" onClick = {() => {
  
          if (count < 1)
          {
            alert("담을 상품 개수를 입력하세요.");
          }
          else
            props.addItem(count);
        }}>
          담기
        </button>
      </div>
    );
  }
  else
  {
    return (
      <div className={props.counttype === 0 ? "oditemcnt2mb flexcenterrow" : "oditemcnt2mb flexcenterrow"}>
        <label className="mfontnb">{GetCountTypeName(props.counttype)}&nbsp;&nbsp;</label>
  
        <input type="number" onChange={(e) => setCount(parseInt(e.target.value))} value = {count < 1 ? '' : count} 
          className="oditeminfocntmb" placeholder="갯수입력"
        />
  
        <button className="bgcolor1 oditeminfocartmb mfontnw" onClick = {() => {
  
          if (count < 1)
          {
            alert("담을 상품 개수를 입력하세요.");
          }
          else
            props.addItem(count);
        }}>
          담기
        </button>
      </div>
    );
  }  
}

const OrderAddItemmb = (props) =>
{
  return (
    <div className="oditemmb flexcentercol">

      <div className="flexcenterrow oditemmb2">
        {/*아이콘 영역 */}
        <div className="oditemiconmb flexcenterrow">
          <div className="oditemiconbgmb flexcenterrow">
            <img src={GetAppImgUrl(GetGoodsImage(props.item))} alt="상품이미지" className="oditemiconszmb"/>
          </div>
        </div>

        {/*정보영역 */}
        <div className="oditeminfomb">
          <label className="mfontn">{props.item.iname}</label>
          <label className="mfontn">{GetItemInfo(props.item)}</label>
        </div>
      </div>
      

      {/*개수영역 */}
      <div className={(props.item.unit === 0 || props.item.unit === 1) ? "flexcenterrow oditemcntmb3" : "flexcenterrow oditemcntmb"}>
        {
          (props.item.unit === 0 || props.item.unit === 2 || props.item.unit === 3) ?(
            <OrderAddInput 
              onetype={props.item.unit === 0 || props.item.unit === 1 || props.item.unit === 4}
              counttype={0}
              addItem = {(count) => {
                if (count < 1)
                {
                  alert("담을 박스 개수를 입력하세요.");
                }
                else
                  props.addItem(props.itemMode, props.item, count, 0);
              }}
            />
          ):null
        }

        {
          (props.item.unit === 1 || props.item.unit === 2 || props.item.unit === 3 || props.item.unit === 4) ?(
            <OrderAddInput 
              onetype={props.item.unit === 0 || props.item.unit === 1 || props.item.unit === 4}
              counttype={props.item.unit === 3 || props.item.unit === 4 ? 2 : 1}
              addItem = {(count) => {
                if (count < 1)
                {
                  alert("담을 병 개수를 입력하세요.");
                }
                else
                  props.addItem(props.itemMode, props.item, 0, count);
              }}
            />
          ):null
        }
      </div>
    </div>
  );
}

export default OrderAddItemmb;