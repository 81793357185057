import { GetAppImgUrl, mobileOn } from "../../URL/AppUrl";
import { GetProductUnitName, GetProductUsesName, IsValidS } from "../../Util/Util";
import './Product.css';

function GetNameInfo(item)
{
  return item.iname;
}

function GetMaker(item)
{
  if (IsValidS(item.mk) && item.mk !== 'n')
  {
    return item.mk;
  }

  return '';
}

function GetItemInfo(item)
{
  return `${item.ivol}ml/${item.freq}도/${item.cnt}병`
}

function GetItemInfo2(item)
{
  return `${GetProductUnitName(item.iunit)}/${GetProductUsesName(item.iuses)}`;
}

const ProductAddNewItem = (props) =>
{
  if (mobileOn)
  {
    return (
      <div className="flexcenterrow pdcomitem">
        <div className="flexcentercol pdcomitemstart">
          <img src={GetAppImgUrl(props.item.img)} alt="상품이미지" className="pdcomitemimg"/>
        </div>
        
  
        <div className="flexcentercol pdcomitemcenter">
          <label className="mfontnb">{GetNameInfo(props.item)}</label>
          <label className="mfontn">{GetMaker(props.item)}</label>
          <label className="mfontn">{GetItemInfo(props.item)}</label>
          <label className="mfontn">{GetItemInfo2(props.item)}</label>
        </div>
  
        <div className="flexcentercol pdcomitemstart">
          <button className="pdcomitembtnmb mfontnwb btncur bgcolor3" onClick={() => props.handleSelect(props.item)}>선택</button>
        </div>
        
      </div>
    );
  }
  else
  {
    return (
      <div className="flexcenterrow pdcomitem">
        <div className="flexcentercol pdcomitemstart">
          <img src={GetAppImgUrl(props.item.img)} alt="상품이미지" className="pdcomitemimg"/>
        </div>
        
  
        <div className="flexcentercol pdcomitemcenter">
          <label className="fontnb">{GetNameInfo(props.item)}</label>
          <label className="mfontn">{GetMaker(props.item)}</label>
          <label className="fontn">{GetItemInfo(props.item)}</label>
          <label className="fontn">{GetItemInfo2(props.item)}</label>
        </div>
  
        <div className="flexcentercol pdcomitemstart">
          <button className="pdcomitembtn fontnwb btncur bgcolor3" onClick={() => props.handleSelect(props.item)}>선택</button>
        </div>
        
      </div>
    );
  }  
}

export default ProductAddNewItem;