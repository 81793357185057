import { RetailerStoreInstance } from "../../Stores/RetailerStore";
import { IsValidS } from "../../Util/Util";
import MnuOrderCompItem from "./MnuOrderCompItem";
import MnuOrderCompItemmb from "./MnuOrderCompItemmb";

import './MnuOrder.css';
import { observer } from "mobx-react";
import { mobileOn } from "../../URL/AppUrl";

//거래처 검색 리스트
const MnuOrderAddComp = (props) =>
{
  let item = null;
  let list = RetailerStoreInstance.retList;

  if (mobileOn)
  {
    if (IsValidS(list))
    {
      item = list.map(item => {
        return (<MnuOrderCompItemmb key={item.idx} item={item} selectedIdx={props.selectedIdx} handleSelect={props.handleSelect}/>);
      });
    }
  
    return (
      <div className="mnuodcomplistmb flexcentercolstart">
        <label className="mfontlargeb marginbottom15">거래처 리스트</label>
        <div className="flexcentercolstart mnuodcomplistitemareamb">
          {item}
        </div>
      </div>
    );
  }
  else
  {
    if (IsValidS(list))
    {
      item = list.map(item => {
        return (<MnuOrderCompItem key={item.idx} item={item} selectedIdx={props.selectedIdx} handleSelect={props.handleSelect}/>);
      });
    }
  
    return (
      <div className="mnuodcomplist flexcentercolstart">
        <label className="fontlargeb marginbottom15">거래처 리스트</label>
        <div className="flexcentercolstart mnuodcomplistitemarea">
          {item}
        </div>
      </div>
    );
  }
}

export default observer(MnuOrderAddComp);