import OrderAddItemmb from "./OrderAddItemmb";

//검색 아이템 목록창
const OrderSearchListmb = (props) =>
{
  let items = null;

  if (props.itemList !== undefined && props.itemList !== null && props.itemList.length > 0)
  {
    items = props.itemList.map(item => {
      return (<OrderAddItemmb key={item.idx} item = {item} itemMode = {1} addItem = {props.addItem}/>);
    });
  }

  return (
    <div className="oditemlistmb flexcentercolstart"  onClick={(e) => e.stopPropagation()}>
      {items}
    </div>
  );
}

export default OrderSearchListmb;