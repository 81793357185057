import React from 'react';
import './Staff.css';
import '../../App.css';
import { IsValidS } from '../../Util/Util';
import { LOG_ENABLE, mobileOn, GetImgUrl } from '../../URL/AppUrl';
import { observer } from 'mobx-react';
import PageList from '../PageList';
import { RecmStoreInstance } from '../../Stores/RecmStore';
import Loading from '../Loading/Loading';
import RecmTransItem from './RecmTransItem';
import RecmTransConfirm from './RecmTransConfirm';

//특정 추천인의 가입자를 다른 추천인으로 이동시키는 UI
class RecmTrans extends React.Component {
  state = {
    loading: false,
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 1, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 1, //전체 페이지 개수
    selectedRecmIdx: -1, //선택된 추천인 고유번호
    showTransConfirm: false, //추천인 이전확인 팝업
  };

  visiblePageNum = 10;
  targetTransInfo = null; //이전할 대상 추천인 정보
  transList = null; //이전할 가입자 목록

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setSelectedRecmIdx = (v) =>
  {
    this.setState((prevState) => ({...prevState, selectedRecmIdx : v}));
  }

  setShowTransConfirm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTransConfirm : v}));
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("RecmTrans.componentDidMount");
    
    if (mobileOn)
      this.visiblePageNum = 3;

    this.loadList(1);
  }

  componentWillUnmount = () =>
  {
  }

  calcPageInfo = () =>
  {
    let currentCount = RecmStoreInstance.totalUserCount;

    let pageCount = Math.floor(currentCount / 12);

    if ((currentCount % 12) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.visiblePageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  loadList = (pageNum) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    RecmStoreInstance.loadUserList(0, this.props.item.idx, (pageNum - 1) * 12, this.onLoadList);
  }

  onLoadList = (resultCode, isFirst) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('가입자 목록 조회중 오류가 발생했습니다.');
      return;
    }

    if (isFirst)
    {
      this.calcPageInfo();
    }
  }

  //하단 페이지 변경 버튼 눌릴경우
  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4
  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = this.visiblePageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadList(1)
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - this.visiblePageNum;
      let endPage = this.state.endPage - this.visiblePageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadList(startPage)
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.visiblePageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadList(startPage)
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.visiblePageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadList(endPage)
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadList(pageNum)
    }
  }

  handleCheck = (item) =>
  {
    RecmStoreInstance.toggleUserCheck(item.idx);
  }

  //추천인 변경실행
  handleDoChange = () =>
  {
    if (this.state.loading)
      return;

    const list = RecmStoreInstance.userList;

    if (!IsValidS(list))
    {
      alert(`선택된 가입자가 없습니다.`);
      return;
    }

    this.transList = [];//이전할 가입자 목록

    list.forEach(item => {
      if (item.selected)
      {
        this.transList.push(item.idx);
      }
    });

    if (this.transList.length < 1)
    {
      alert(`선택된 가입자가 없습니다.`);
      return;
    }

    if (this.state.selectedRecmIdx === this.props.item.idx)
    {
      alert(`같은 추천인에게 이전할 수 없습니다.`);
      return;
    }

    this.targetTransInfo = RecmStoreInstance.findRecmByIdxFromAllList(this.state.selectedRecmIdx);

    if (this.targetTransInfo === null)
    {
      alert(`가입자를 이전할 추천인을 선택해 주세요.`);
      return;
    }

    this.setShowTransConfirm(true);
  }

  handleTransConfirm = (yes) =>
  {
    if (!yes)
    {
      this.setShowTransConfirm(false);
      return;
    }

    this.setLoading(true);
    RecmStoreInstance.transUser(1, this.props.item.idx, this.state.selectedRecmIdx, this.transList, this.onTransDone);
  }

  onTransDone = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`가입자 이전 처리중 오류가 발생했습니다.`);

      this.loadList(1);
      return;
    }

    alert(`선택하신 가입자의 이전이 완료되었습니다.`);
    this.setShowTransConfirm(false);
    this.loadList(this.state.currentPage);
  }

  getAllRecmListOption = () =>
  {
    const list = RecmStoreInstance.recmAllList;

    if (!IsValidS(list))
      return null;

    const optList = list.map(item => {
      return (<option key={item.idx} value={item.idx.toString()}>{`${item.cc}(${item.nm})`}</option>);
    });

    optList.unshift((<option key={-1} value="-1">추천인을 선택하세요.</option>));

    return optList;
  }

  render()
  {
    let recomList = null;

    const temp = RecmStoreInstance.userList;

    if (IsValidS(temp))
    {
      recomList = temp.map(item => {
        return (<RecmTransItem key={item.idx} item={item} handleCheck={this.handleCheck}/>);
      });
    }

    return (
      <div className='recmtranspopbg flexcenterrow'>
        {
          this.state.showTransConfirm ? (
            <RecmTransConfirm item={this.props.item} transCount={this.transList.length} titem={this.targetTransInfo}
              handleTransConfirm={this.handleTransConfirm}
            />
          ) : null
        }

        {
          this.state.loading ? (<Loading />) : null
        }

        <div className='recmtranspop flexcentercol'>
        
          <div className='cntfullh flexcenterrow marginbottom10'>
            <div className='dcell1'></div>
            <div className='dcell8 flexcenterrow'>
              <label className='fontlargeb'>{`"${this.props.item.nm}"님 가입자 개별 이전`}</label>
            </div>
            <div className='dcell1 flexcenterrow'>
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur' onClick={() => this.props.handleCloseUserList()}/>
            </div>
          </div>

          <div className='cntfullh flexcenterrowstart marginbottom10'>
            <input type='checkbox' className='recmchkbox marginleft50' />
            <label className='fontn'>&nbsp;선택한 가입자를&nbsp;</label>

            <select className="recmselect fontn" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.setSelectedRecmIdx(parseInt(e.target.value));
              }}

              value={this.state.selectedRecmIdx.toString()}
            >
              {this.getAllRecmListOption()}
            </select>

            <label className='fontn'>&nbsp;추천인으로 변경합니다.&nbsp;</label>

            <button className='recmchgbtn fontnwb btncur bgcolor3' onClick={this.handleDoChange}>변경하기</button>
          </div>

          <div className = "retranstablecont">
            <table className='recommtable'>
              <thead>
                <tr>
                  <th className="recommcell">
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">업체명</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">주소</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">대표자명</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">연락처</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">사업자번호</label>
                  </th>

                  <th className="recommcell">
                    <label className="fontnw">업체구분</label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {recomList}
              </tbody>
            </table>
          </div>

          {
            this.state.pageCount > 1 ? <PageList handlePage={this.handlePage} 
              first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
              next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
              currentPage={this.state.currentPage} 
              startPage={this.state.startPage} endPage={this.state.endPage}/> : 
            null
          }
        </div>
      </div>
    );
  }
}

export default observer(RecmTrans);