import React from 'react';
//import { GetImgUrl } from '../../URL/AppUrl';
import "./StaffAdd.css";

const StaffInfoCell = (props) =>
{
  return (
    <div className="staffaddinpcontmb flexcenterrow">

      <div className="staffaddinpleftmb flexcenterrowstart">
        <label className="mfontn">{props.title}</label>
      </div>
      
      <div className="staffaddinprightmb flexcenterrowend">
        <label className="mfontn">{props.value}</label>
      </div>

    </div>
  );
}

function GetStaffLevel(staffInfo)
{
  switch(staffInfo.plevel)
  {
    case 0:
      return "Admin";
    case 1:
      return "일반관리자";
    case 2:
      return "영업담당자";
    case 3:
      return "관리담당자";
    default:
      return "알수없음";
  }
}

//모바일용, 사용자 정보
const StaffInfomb = (props) => {

  return (
    <div className="staffaddcont flexcentercol">
      <div className="staffinfocontentmb2 flexcentercolstart">

        <div className="staffinfotitlemb flexcenterrow">
          <label className="mfontnb">직원 상세정보 확인</label>
        </div>

        <StaffInfoCell title="ID" value={props.staffInfo.cid}/>
        <StaffInfoCell title="이름" value={props.staffInfo.uname}/>
        <StaffInfoCell title="휴대폰" value={props.staffInfo.phone}/>
        <StaffInfoCell title="메일주소" value={props.staffInfo.email}/>
        <StaffInfoCell title="권한" value={GetStaffLevel(props.staffInfo)}/>

        <div className="staffaddbtnareamb flexcentercol">
          <button className="bgcolor4 staffaddbtnmb mfontnwb" onClick={() => props.handleCloseDetail()}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default StaffInfomb;