import { mobileOn } from '../../URL/AppUrl';

const ChatEmpty = (props) =>
{
  if (mobileOn)
  {
    return (
      <div className="chatemptymb flexcentercolstart">
        <label className="chatcompmsgfont2mb">대화 목록의 업체를 클릭하거나, 거래처 검색에서 검색 후 대화를 시작할 수 있습니다.<br/>대화 내역은 일주일간 조회 가능하며 해당 기간이 경과되면 자동으로 삭제됩니다.</label>
      </div>
    );
  }
  else
  {
    return (
      <div className="chatempty flexcentercolstart">
        <label className="chatcompmsgfont2">대화 목록의 업체를 클릭하거나, 거래처 검색에서 검색 후 대화를 시작할 수 있습니다.<br/>대화 내역은 일주일간 조회 가능하며 해당 기간이 경과되면 자동으로 삭제됩니다.</label>
      </div>
    );
  }
  
}

export default ChatEmpty;