import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import {LoginStoreInstance} from './Stores/LoginStore';
import { GetApiBaseUrl } from './URL/AppUrl';
import { CookiesProvider } from 'react-cookie';

//axios.defaults.withCredentials = true;
axios.defaults.baseURL = GetApiBaseUrl();

axios.interceptors.request.use(LoginStoreInstance.setHeaderToken, function (error) {
  // Do something with request error
  console.log(error);
  return Promise.reject(error);
});

const GlobalInterval = () => {
  //console.log("global interval");

  if (LoginStoreInstance.loggedIn)
  {
    //세션이 만료되지 않도록 세션 리프레시 api를 호출해줌
    LoginStoreInstance.refreshSession();
  }
}

//1분에 한번씩 반복되는 반복자 등록
setInterval(GlobalInterval, 1000 * 60);

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
