import { observer } from 'mobx-react';
import React from 'react';
import { AppChatStoreInstance } from '../Stores/AppChatStore';
import { GetImgUrl, LOG_ENABLE } from '../URL/AppUrl';
import { IsValidS, IsValidV, ToBase64, UploadFile,  } from '../Util/Util';
import './ChatApp.css';
import ChatAppDate from './ChatAppDate';
import ChatAppEnd from './ChatAppEnd';
import ChatAppFile from './ChatAppFile';
import ChatAppImg from './ChatAppImg';
import ChatAppItem from './ChatAppItem';
import ChatAppItemUser from './ChatAppItemUser';

class ChatApp extends React.Component {
  state = 
  {
    userIdx : -1,
    chatMsg : '',
    windowHeight : 0,
  };

  needInit = false;
  intervalId = -1;
  chatContainerRef = null;
  moreChat = false;
  moreOn = false;
  scrollHeight = 0;
  lastScrollHeight = 0;

  fileSendRef = null;
  //chatList = null;
  uploadInfo = null;

  setContainerRef = (ref) =>{
    this.chatContainerRef = ref;
  }

  componentDidMount() {
    document.addEventListener("message", this.handleMessageSend);
    window.addEventListener("message", this.handleMessageSend);

    window.addEventListener("resize", this.handleSizeChanging);

    //유저의 세션 정보를 요청한다.
    this.postMessage(JSON.stringify({id : 0}));

    this.intervalId = setInterval(this.update, 100);

    //this.setState((prevState) => ({...prevState, userIdx : 10}));
    //AppChatStoreInstance.connect(10, 10, 3, "Aaaa");

    AppChatStoreInstance.newChatListCallback = this.onNewChatList;
    AppChatStoreInstance.newChatArrivedCallback = this.onNewChat;

    this.setState((prevState) => ({...prevState, windowHeight : window.innerHeight}));
  }

  componentWillUnmount()
  {
    AppChatStoreInstance.clearAll();

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    document.removeEventListener("message", this.handleMessageSend);
    window.removeEventListener("message", this.handleMessageSend);

    window.removeEventListener("resize", this.handleSizeChanging);
  }

  handleSizeChanging = (event) =>
  {
    if (LOG_ENABLE)
      this.sendLogMsg("handleSizeChanging");

    this.setState((prevState) => ({...prevState, windowHeight : window.innerHeight}));
  }

  update = () =>
  {
    if (IsValidV(this.chatContainerRef) &&IsValidV(this.chatContainerRef.scrollHeight) && IsValidV(window.innerHeight))
    {
      if (!this.needInit || (!this.moreOn && this.chatContainerRef.scrollHeight !== this.lastScrollHeight))
      {
        this.lastScrollHeight = this.chatContainerRef.scrollHeight;

        if (LOG_ENABLE)
          this.sendLogMsg('update scroll');

        this.needInit = true;
        this.chatContainerRef.scrollTo(0, this.chatContainerRef.scrollHeight - (window.innerHeight - 86));

        /*if (this.intervalId !== -1)
        {
          clearInterval(this.intervalId);
          this.intervalId = -1;
        } */ 
      }
    }
  }

  //새로운 채팅 목록이 수신될때마다 호출된다. count : 수신된 채팅의 개수
  onNewChatList = (count) =>
  {
    if (LOG_ENABLE)
      console.log(`onNewChatList : ${count}`);

    if (count > 0)
    {
      if (AppChatStoreInstance.getTotalChatCount < 50 && count >= 30)
      {
        if (!AppChatStoreInstance.chatListLoading && AppChatStoreInstance.totalChatCount !== 0 && 
          AppChatStoreInstance.totalChatCount > AppChatStoreInstance.getTotalChatCount)
          AppChatStoreInstance.loadLastChatList();
      }
    }
  }

  //신규 채팅 한개가 수신될때
  onNewChat = () =>
  {
    this.moreOn = false;
  }

  //리액트로 부터 수신된 메시지
  handleMessageSend = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleMessageSend : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (IsValidV(msg))
      {
        switch(msg.id)
        {
          case 0: //채팅 메시지 전송
            if (AppChatStoreInstance.connected)
            {
              AppChatStoreInstance.sendChat(msg.data);
            }
            else
            {
              alert("채팅 서버와 연결되어 있지 않습니다.");
            }
            break;
          case 3: //유저의 세션 정보 요청에 대한 응답
            this.setState((prevState) => ({...prevState, userIdx : msg.uidx}));

            if (LOG_ENABLE)
              this.sendLogMsg("connect to server");

            AppChatStoreInstance.connect(msg.cidx, msg.uidx, msg.widx, msg.uname, this.sendLogMsg);
            break;
          case 10: //파일선택요청
            this.SelectFile();
            break;
          default:
            break;
        }
      }
    }
    catch(e)
    {
      this.postMessage(e.toString());
    }
  }

  onSendMsg = () =>
  {
    if (!IsValidS(this.state.chatMsg))
      return;

    let msg = this.state.chatMsg;

    this.setState((prevState) => ({...prevState, chatMsg : ''}))

    if (AppChatStoreInstance.connected)
    {
      AppChatStoreInstance.sendChat(ToBase64(msg));
    }
    else
    {
      alert("채팅 서버와 연결되어 있지 않습니다.");
    }
  }

  sendLogMsg = (logMsg) =>
  {
    console.log(logMsg);
    this.postMessage(JSON.stringify({id : 100, msg : logMsg}));
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        // 모바일이라면 모바일의 카메라 권한을 물어보는 액션을 전달합니다.
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  SelectFile = () =>
  {
    if (IsValidV(this.fileSendRef))
    {
      this.sendLogMsg("file selector found");

      /*if (IsValidV(this.chatContainerRef))
      {
        this.sendLogMsg("focus to container");

        this.chatContainerRef.focus();
      }*/

      this.fileSendRef.focus();
      this.fileSendRef.click();
    }
  }

  handleDownload = (isImage, fileName, oriName) =>
  {
    if (LOG_ENABLE)
      this.sendLogMsg(`handleDownload : ${fileName}, ${oriName}`);

    //DownloadFile(GetFileUrl(fileName), oriName);

    this.postMessage(JSON.stringify({id : isImage ? 2 : 1, fn : fileName, on : oriName}));
  }

  handleScroll = (e) =>{

    //if (LOG_ENABLE)
      //this.sendLogMsg(`top = ${e.target.scrollTop}, client height=${e.target.clientHeight}, scroll height : ${e.target.scrollHeight}`);

    if (e.target.scrollTop === 0){
      if (LOG_ENABLE)
        this.sendLogMsg("on scroll TOP!!!");

      if (AppChatStoreInstance.totalChatCount !== 0 && AppChatStoreInstance.totalChatCount > AppChatStoreInstance.currentTotal &&
        !AppChatStoreInstance.chatListLoading)
      {
        this.moreChat = true;
        this.moreOn = true;
        this.scrollHeight = e.target.scrollHeight;

        AppChatStoreInstance.loadLastChatList();
      }
    }
  }

  handleUploadFile = (file) =>
  {
    if (LOG_ENABLE)
      this.sendLogMsg("sendFile : " + file.name);

      /*
    if (!AppChatStoreInstance.connected)
    {
      alert("현재 채팅서버와 접속되어 있지 않습니다. 잠시 후 이용해 주세요.");
      return;
    }*/

    if (IsValidV(file) && IsValidS(file.name))
    {
      let upperName = file.name.toUpperCase();

      if (IsValidS(upperName))
      {
        if (upperName.indexOf('.JSP') >= 0 || upperName.indexOf('.PH') >= 0 || upperName.indexOf('.PHP') >= 0 || upperName.indexOf('.HTM') >= 0 || 
          upperName.indexOf('.ASP') >= 0 || upperName.indexOf('.VB') >= 0 || upperName.indexOf('.CSS') >= 0 || upperName.indexOf('.JS') >= 0 ||
          upperName.indexOf('.JAVA') >= 0 || upperName.indexOf('.EXE') >= 0 || upperName.indexOf('.COM') >= 0 || upperName.indexOf('.ADE') >= 0 || 
          upperName.indexOf('.APP') >= 0 || upperName.indexOf('.BAT') >= 0 || upperName.indexOf('.CAB') >= 0 || 
          upperName.indexOf('.CMD') >= 0 || upperName.indexOf('.SH') >= 0 || upperName.indexOf('.CHM') >= 0 || upperName.indexOf('.CPL') >= 0 || 
          upperName.indexOf('.DLL') >= 0 || upperName.indexOf('.DMG') >= 0 || upperName.indexOf('.EX') >= 0 || upperName.indexOf('.HTA') >= 0 || 
          upperName.indexOf('.JAR') >= 0 || upperName.indexOf('.ISP') >= 0 || upperName.indexOf('.INS') >= 0 || upperName.indexOf('.LIB') >= 0 || 
          upperName.indexOf('.LNK') >= 0 || upperName.indexOf('.MDE') >= 0 || upperName.indexOf('.MSC') >= 0 || upperName.indexOf('.MSI') >= 0 || 
          upperName.indexOf('.MSP') >= 0 || upperName.indexOf('.MST') >= 0 || upperName.indexOf('.NSH') >= 0 || upperName.indexOf('.PIF') >= 0 || 
          upperName.indexOf('.SCR') >= 0 || upperName.indexOf('.SCT') >= 0 || upperName.indexOf('.SHB') >= 0 || upperName.indexOf('.SYS') >= 0 || 
          upperName.indexOf('.VXD') >= 0 || upperName.indexOf('.WSC') >= 0 || upperName.indexOf('.WSF') >= 0 || upperName.indexOf('.WSH') >= 0 ||
          upperName.indexOf('.XML') >= 0 || upperName.indexOf('.CLA') >= 0 || upperName.indexOf('.WAR') >= 0 || upperName.indexOf('.HTACCESS') >= 0 || 
          upperName.indexOf('.JSON') >= 0 || upperName.indexOf('.TS') >= 0 || upperName.indexOf('.VXD') >= 0 || upperName.indexOf('.PY') >= 0)
        {
          if (LOG_ENABLE)
            console.log(upperName);
            
          alert("해당 형식의 파일은 업로드 할 수 없습니다.");
          return;
        }
      }
      
      this.uploadInfo = {fn : file.name, fl : file, isFile : true};

      UploadFile(this.state.userIdx, 1, file, this.onUploadDone)
    }
  }

  onUploadDone = (success, oldName, newName) =>
  {
    if (!success)
    {
      alert("파일 전송에 실패 했습니다.");
      return;
    }

    if (!AppChatStoreInstance.connected)
    {
      alert("현재 채팅서버와 접속되어 있지 않습니다. 잠시 후 이용해 주세요.");
      return;
    }
    
    AppChatStoreInstance.sendFile(newName, oldName);
  }

  componentDidUpdate(){

    if (LOG_ENABLE)
      console.log("componentDidUpdate");

    if (IsValidV(this.chatContainerRef) && IsValidV(this.chatContainerRef.scrollHeight) && IsValidV(window.innerHeight))
    {
      if (this.moreChat)
      {
        if (LOG_ENABLE)
          this.sendLogMsg('update scroll2');

        this.moreChat = false;
        this.chatContainerRef.scrollTo(0, this.chatContainerRef.scrollHeight - this.scrollHeight);
      }
      else
      {
        //if (LOG_ENABLE)
          //this.sendLogMsg('update scroll3');
        this.chatContainerRef.scrollTo(0, this.chatContainerRef.scrollHeight - (this.state.windowHeight-86));
      }
    }
  }

  render() {
    const chatList = AppChatStoreInstance.chatList;

    let list = null;

    if (this.state.userIdx !== -1 && IsValidS(chatList))
    {
      list = chatList.map(item => {
        switch (item.ctype)
        {
          case 0: //운영자 채팅
            return (<ChatAppItem key={item.idx} item={item}/>);
          case 1 : //유저 채팅
            return (<ChatAppItemUser key={item.idx} item={item}/>);
          case 2: //채팅 종료마크
            return (<ChatAppEnd key={item.idx} />);
          case 3: //첨부 이미지
          case 6:
            return (<ChatAppImg key={item.idx} item={item} handleDownload = {this.handleDownload}/>);
          case 4: //첨부파일
          case 7:
            return (<ChatAppFile key={item.idx} item={item} handleDownload = {this.handleDownload}/>);
          case 5: //날짜정보
            return (<ChatAppDate key = {item.idx} item={item}/>);
          default:
            return null;
        }
      });
    }

    if (this.state.userIdx === -1)
    {
      return (
        <div className="achatcontainer">
          잠시만 기다려 주세요.
        </div>      
      );
    }

    return (
      <div className="achatcontainer" /*style={{height : window.innerHeight}}*/>
        
        <div className="chatappcont"
          style={{height : this.state.windowHeight - 86}}
          onScroll={this.handleScroll} 
          ref = {(ref) => this.setContainerRef(ref)}
        >
          {list}
        </div>
        
        
        <input type="file"
          id="fileSelect2"
          name="fileSelect2"
          accept="image/*,video/*"
          className="chatappuploader"
          ref = {(ref) => this.fileSendRef = ref} 

          onChange={(e) => {

            if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
            {
              if (LOG_ENABLE)
                this.sendLogMsg("no selected file");
              return;
            }
              
            if (LOG_ENABLE)
              this.sendLogMsg(e.target.files[0]);

              this.handleUploadFile(e.target.files[0]);
              //props.sendFile(e.target.files[0].name, e.target.files[0]);
          }}
        />

        <div className="chatappinparea flexcentercol">
          <div className="chatappinputline"></div>

          <div className="chatappinparea2 flexcenterrow">
            <div className="chatappinpleft btncur flexcenterrow" onClick={this.SelectFile}>
              <img src={GetImgUrl("camicon2.png")} alt="첨부파일" className="btncur"/>
            </div>

            <div className="chatappinpright flexcenterrow">
              <div className="chatappinpedit flexcenterrow">
                <div className="chatappinpeditleft flexcenterrow">
                  <textarea className="chatappinput" wrap="off" value={this.state.chatMsg} 
                    onChange={e=>this.setState((prevState) => ({...prevState, chatMsg : e.target.value}))}
                    maxLength="190"
                    placeholder="메세지 내용을 입력해주세요."
                    rows = {1}
                    onKeyPress = {(e) => {
                      if (e.key === "Enter")
                      {
                        e.preventDefault();
                        this.onSendMsg();
                      }
                    }}
                  />
                </div>
                <div className="chatappinpeditright flexcenterrow" onClick={()=>this.onSendMsg()}>
                  <img src={GetImgUrl("sendicon2.png")} alt="발송" className="btncur"/>
                </div>              
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default observer(ChatApp);