import { observer } from "mobx-react";
import { mobileOn } from "../../URL/AppUrl";
import "../Emp/Staff.css";
import "./Product.css";
import ProductInputmb from "./ProductInputmb";
import ProductInput from "./ProductInput";

const ProductAdd = observer((props) =>
{
  if (mobileOn)
  {
    return (
      <div className="pdcontentmb flexcentercolstart">
        {/**타이틀 영역 */}
        <div className="pdtitlemb flexcenterrow">
          <label className="mfontlargeb">신규상품등록</label>
        </div>
  
        {/**검색 영역 */}
        <div className="pdsearchmb flexcenterrow">
            
          <div className="pdsearchtabmb flexcentercolend" onClick={() => props.handleTab(0)}>
            <label className= {(props.editMode === 0 || props.editMode === 2) ? "btncur mfontmb" : "btncur pdsearchfonttabmb"}>등록</label>
            <div className={(props.editMode === 0 || props.editMode === 2) ? "pdsearchtablinemb" : "pdsearchtablineselmb"}></div>
          </div>

          <div className="flexcentercolend pdvlinecontmb">
            <div className="ntctabvlinemb"></div>
          </div>
          

          <div className="pdsearchtabmb flexcentercolend"  onClick={() => props.handleTab(1)}>
            <label className={(props.editMode === 0 || props.editMode === 2) ? "btncur pdsearchfonttabmb" : "mfontmb btncur"}>조회</label>  
            <div className={(props.editMode === 0 || props.editMode === 2) ? "pdsearchtablineselmb" : "pdsearchtablinemb"}></div>
          </div>
        </div>
  
        <div className="pdhlinemb"></div>
  
        <ProductInputmb itemIdx = {-1} iid={1} icode={''} iname={''} alias={''} ivol={''} 
          iunit={0} iuses={0} ifreq={''} icnt={''} img={null} editMode = {false} handleAdd = {props.handleAdd}/>
      </div>
    );
  }
  else
  {
    return (
      <div className="pdcontent flexcentercolstart">
        {/**타이틀 영역 */}
        <div className="pdtitle flexcenterrow">
          <label className="pdtitlefont">신규상품등록</label>
        </div>
  
        {/**검색 영역 */}
        <div className="pdsearch flexcenterrow">
        
          {/**검색 영역 왼쪽*/}
          <div className="pdsearchleft flexcenterrowstart">
  
            <div className="pdsearchtab flexcentercolend" onClick={() => props.handleTab(0)}>
              <label className= {(props.editMode === 0 || props.editMode === 2) ? "pdsearchfonttab" : "btncur fontlargeb"}>등록</label>
              <div className={(props.editMode === 0 || props.editMode === 2) ? "pdsearchtabline" : "pdsearchtablinesel"}></div>
            </div>
  
            <div className="pdsearchtab flexcentercolend"  onClick={() => props.handleTab(1)}>
              <label className={(props.editMode === 0 || props.editMode === 2) ? "btncur fontlargeb" : "pdsearchfonttab"}>조회</label>  
              <div className={(props.editMode === 0 || props.editMode === 2) ? "pdsearchtablinesel" : "pdsearchtabline"}></div>
            </div>
          </div>
    
          {/**검색 영역 오른쪽*/}
          <div className="pdsearchright flexcenterrowend">
          </div>
        </div>
  
        <ProductInput itemIdx = {-1} iid={1} icode={''} iname={''} alias={''} ivol={''} 
          iunit={0} iuses={0} ifreq={''} icnt={''} img={null} editMode = {false} handleAdd = {props.handleAdd}/>
      </div>
    );
  }  
});

export default ProductAdd;