import React from 'react';

const ChatAppDate = (props) =>
{
  return (
    <div className="achatitemend">
      <label className="achatdatefont">{props.item.date}</label>
    </div>
  );
}

export default ChatAppDate;