import React from 'react';
import { GetChatTime, IsValidS } from '../Util/Util';

const getChattingLabels = (chatMsg, clsName) =>
{
  if (!IsValidS(chatMsg))
    return null;

  if (chatMsg.indexOf("<br>") >= 0)
  {
    const splits = chatMsg.split("<br>");

    if (IsValidS(splits))
    {
      let itemKey = 1;
      return splits.map(item => {
        return (<label key={itemKey++} className={clsName}>{item}</label>);
      });
    }
  }

  return (<label className={clsName}>{chatMsg}</label>);;
}

const ChatAppItemUser = (props) =>
{
  return (
    <div className="achatitemuser">
      <label className="achattime">{GetChatTime(props.item.rdate)}</label>

      <div className="achatbubbleuser flexcentercol">
        {/*<label className="achatfontuser">{props.item.chat}</label>*/}
        {getChattingLabels(props.item.chat, "achatfontuser")}
      </div>
      
    </div>
  );
}

export default ChatAppItemUser;