import { LoginStoreInstance } from "../../Stores/LoginStore";
import { NoticeStoreInstance } from "../../Stores/NoticeStore";
import { GetFileUrl, GetImgUrl } from "../../URL/AppUrl";
import { DownloadFile, IsValidS } from "../../Util/Util";

function GetAddFile(addFile)
{
  if (!IsValidS(addFile) || addFile === "n")
    return "";

  return addFile;
}

function GetAddImg(addImg)
{
  if (!IsValidS(addImg) || addImg === "n")
    return "";

  return addImg;
}

//이전 공지가 더있나?
function CheckPrevNotice(isGlobal, idx)
{
  if (isGlobal)
    return NoticeStoreInstance.gStartIdx < idx;
  else
    return NoticeStoreInstance.pStartIdx < idx;
}

//다음 공지가 더있나?
function CheckNextNotice(isGlobal, idx)
{
  if (isGlobal)
    return NoticeStoreInstance.gEndIdx > idx;
  else
    return NoticeStoreInstance.pEndIdx > idx;
}

const NoticeView = (props) =>
{
  return (
    <div className="noticeview flexcentercolstart">
      {/*제목 */}
      <div className="noticeviewtitle flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">제목</label>
        </div>
        <div className="noticevieweditright flexcenterrowstart">
          <label className="fontn">{props.editInfo.title}</label>
        </div>
      </div>

      {/*작성자 */}
      <div className="noticeviewname flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">작성자</label>
        </div>
        <div className="noticevieweditright flexcenterrowstart">
          <label className="fontn">{props.editInfo.uname}</label>
        </div>
      </div>

      {/*내용 */}
      <div className="noticeviewdesc flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">내용</label>
        </div>
        <div className="noticevieweditrightdesc">
          <label className="noticeitemfontntcv fontn">{props.editInfo.notice}</label>
        </div>
      </div>

      {/*첨부파일 */}
      <div className="noticeviewfile flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">첨부파일</label>
        </div>
        <div className="noticevieweditright flexcenterrowstart">
          <img src={GetImgUrl("ntcffile.png")} alt="첨부파일" className="ntcafimg"/>
          <label className="noticeeditfontaf" onClick={() => DownloadFile(GetFileUrl(props.editInfo.afile), props.editInfo.afileori)}>{GetAddFile(props.editInfo.afileori)}</label>
        </div>
      </div>

      {/*이미지 */}
      <div className="noticeviewimg flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">이미지</label>
        </div>
        <div className="noticevieweditright flexcenterrowstart">
          <img src={GetImgUrl("ntcfimg.png")} alt="첨부이미지" className="ntcafimg"/>
          <label className="noticeeditfontaf" onClick={() => DownloadFile(GetFileUrl(props.editInfo.aimg), props.editInfo.aimgori)}>{GetAddImg(props.editInfo.aimgori)}</label>
        </div>
      </div>

      {/*버튼영역*/}
      <div className="noticeviewbtnarea flexcenterrow">
        <div className="noticeviewbtnleft flexcenterrow"/>

        <div className="noticeviewbtncenter flexcenterrow">
          {
            CheckPrevNotice(props.tabIndex === 0, props.editInfo.idx) ?
            (<button className="noticeviewbtnprev fontnwb" onClick={() => props.handlePrev(props.editInfo.idx)}>
              {"< 이전글"}
            </button>) : null
          }
          
          {
            CheckNextNotice(props.tabIndex === 0, props.editInfo.idx) ?
            (
            <button className="noticeviewbtnnext fontnwb" onClick={() => props.handleNext(props.editInfo.idx)}>
              {"다음글 >"}
            </button>
            ):null
          }
          
        </div>

        <div className="noticeviewbtnright flexcenterrowend">
          {//전체 공지가 아니고, 내가 작성한 공지면 수정버튼
            ((props.tabIndex !== 0) && (props.editInfo.uidx === LoginStoreInstance.userIdx)) || LoginStoreInstance.userType === 0 ? (
              <button className="bgcolor1 noticeviewbtnedit fontnwb" onClick={() => props.handleEdit(props.editInfo.idx)}>
                수정
              </button>
            ) : null
          }
          
          <button className="noticeviewbtnlist fontnwb" onClick={props.handleList}>
            목록
          </button>
        </div>
      </div>
    </div>
  );
}

export default NoticeView;