import React from 'react';

import "../../App.css";
import { GetFormattedCNum, GetFormattedPhone2 } from '../../Util/Util';
import "../Emp/StaffAdd.css";
import "./RetAdd.css";

const RetDetail = (props) =>
{
  return (
    <div className="flexcenterrow retaddinpcontmb">
      <label className="mfontn">{props.title}</label>
      <label className="mfontn"> {props.editValue} </label>
    </div>
  );
}

function GetCompanyType(ct)
{
  if (ct === 0)
    return "유흥업체";

  return "일반업체";
}

function GetAppInstallInfo(appinst)
{
  if (appinst)
    return "설치함";

  return "미설치";
}

const RetDetmb = (props) => {

  return (
    <div className="retaddcontmb flexcenterrow">
      <div className="retinfowinmb flexcentercolstart">

        <div className="stafftitlemb flexcenterrow">
          <label className="mfontnb">{props.title}</label>  
        </div>

        <RetDetail title="상호" editValue={props.cname} lg={true}/>
        <RetDetail title="주소" editValue={props.addr} lg={true}/>
        <RetDetail title="대표자명" editValue={props.oname}/>
        <RetDetail title="연락처" editValue={GetFormattedPhone2(props.cphone)}/>
        <RetDetail title="사업자 번호" editValue={GetFormattedCNum(props.cnum)}/>
        <RetDetail title="영업 담당자 ID" editValue={props.sid}/>
        {/*<RetDetail title="관리 담당자 ID" editValue={props.pid}/>*/}
        <RetDetail title="업체구분" editValue={GetCompanyType(props.ct)}/>
        <RetDetail title="N-돌핀 앱" editValue={GetAppInstallInfo(props.appinst)}/>

        {
          props.ulevel < 2 && props.retIdx > 0 ? (
            <div className="flexcenterrow retaccbtnarea">
              <button className="bgcolor4 retaddpopbtnmb mfontnwb btncur" onClick={() => props.handleShowAccount(props.retIdx)}>
                계정보기
              </button>

              <button className="bgcolor4 retaddpopbtnmb mfontnwb btncur" onClick={() => props.handleCloseDetail()}>
                확인
              </button>
            </div>
          ) : (
            <button className="bgcolor4 retaddpopbtnmb mfontnwb btncur" onClick={() => props.handleCloseDetail()}>
              확인
            </button>
          )
        }

      </div>
    </div>
  );
}

export default RetDetmb;