
const RetAccountInfomb = (props) => {
  return (
    <div className="retaccinfopopbg flexcenterrow">
      <div className="retaccinfopopmb flexcentercolstart">
        <label className="mfontnb retacctitlefont">N-돌핀 앱 계정 정보</label>

        <div className="flexcenterrow retaccbtnarea">
          <label className="mfontnb retaccid">N-돌핀 앱 ID</label>
          <label className="mfontnb retaccid2">{props.accId}</label>
        </div>

        <div className="flexcenterrow retaccbtnarea">
          <label className="mfontn retaccid">비밀번호 초기화시 N-돌핀 앱 이용자의 비밀번호가 1111로 초기화 됩니다.앱 이용자가 비밀 번호 분실 후 초기화 요청을 한 경우에만 이용해 주세요.</label>
        </div>

        <div className="flexcenterrow retaccbtnarea">
          <button className="bgcolor2 retaccidbtn mfontnwb btncur" onClick={props.handleResetRetPassword}>비밀번호 초기화</button>
          <button className="bgcolor4 retaccidbtn mfontnwb btncur" onClick={props.handleCloseRetAccount}>닫기</button>
        </div>
      </div>
    </div>
  );
}

export default RetAccountInfomb;