//공지 사항 작성시의 탭 영역
const NoticeNew = (props) =>
{
  return (
    <div className="noticenewarea flexcentercolstart">
      <label className="fontlargeb">공지사항 작성</label>
      <div className="noticenewline" />
    </div>
  );
}

export default NoticeNew;