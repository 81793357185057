import { ODS_DELIE, ODS_REORDER } from "../../Stores/OrderStore";
import "../Product/Product.css";

//앱주문 상세
const OrderAppDet = (props) =>
{
  return(
    <div className="pddetcontmb flexcenterrow">
      <div className="odappdetpopmb2 flexcentercolstart">
        <div className="pddettitle flexcenterrow">
          <label className="mfontnb">APP주문 상세정보 확인</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">업체명</label>
          <label className="mfontn">{props.order.cname}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">대표자명</label>
          <label className="mfontn">{props.order.uname}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">휴대폰</label>
          <label className="mfontn">{props.order.phone}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">주소</label>
          <label className="mfontn">{props.order.addr}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">영업담당자</label>
          <label className="mfontn">{props.order.sname}</label>
        </div>

        <div className="flexcentercol modetbtnareamb">
          <button className="bgcolor1 odvcbtnokmb2 mfontnwb btncur" onClick={() => props.handleAddItem(props.order.idx)}>상품 추가하기</button>
          
          {
            props.order.st !== ODS_DELIE && props.order.st !== ODS_REORDER ? (
              <button className="bgcolor7 odvcbtnokmb mfontnwb btncur" onClick={() => props.handleMoveOrder(props.order.idx)}>주문 일자 변경</button>    
            ):null
          }

          <button className="bgcolor4 odvcbtnokmb3 mfontnwb btncur" onClick={() => props.handleCloseDetail()}>확인</button>
        </div>
        
      </div>
    </div>
  );
}

export default OrderAppDet;