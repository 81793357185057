import { GetImgUrl } from "../../URL/AppUrl";
import MnuOrderItemsedbmb from "./MnuOrderItemsedbmb";

function GetOrderLabel(order)
{
  /*if (order.st === 0)
    return `주문번호 ${order.idx} 이 주문은 아직 처리되지 않았습니다.`;*/

  if (order.st === 11)
    return `주문번호 ${order.idx} (배송완료)`;

  return `주문번호 ${order.idx} 상품 등록 완료`;
}

const MnuOneOrdermb = (props) =>
{
  return(
    <div className="flexcentercol mnuoditem margintop20">
      <div className="mnuoditeminfo flexcenterrowstart marginleft10  marginbottom10">
        <img src={GetImgUrl("mnuod5mb.png")} alt="주문"/>
        <label className="mnuitemtitleenmb">{GetOrderLabel(props.order)}</label>
      </div>

      <table className="mnuoditemtable">
        <thead>
          <tr>
            <th className="emptableheader">
              <label className="mfontnw">업체명</label>
            </th>

            <th className="emptableheader">
              <label className="mfontnw">상세정보 확인</label>
            </th>

            
          </tr>
        </thead>
        <tbody>
          <tr className="emptabledata">
            <td className="ordercellcname">
              <div className="emptablecellin flexcenterrow">
                <label className="mfontn">{props.order.cname}</label>
              </div>
            </td>
            
            <td className="ordercelluname">
              <div className="emptablecellin flexcenterrow">
                <button className="bgcolor3 mfontnwb mnuoddetbtnmb btncur" onClick={() => props.handleDetail(props.order)}>상세정보</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/*
        props.order.st == 0 ? (
          <MnuOrderItemsedbmb order={props.order} handleEditItemMobile = {props.handleEditItemMobile} orderIdx={props.order.idx}/>
        ) : (
          <MnuOrderItemsmb order={props.order} />
        )
        */}

      <MnuOrderItemsedbmb order={props.order} handleEditItemMobile = {props.handleEditItemMobile} orderIdx={props.order.idx}/>

    </div>
  );
}

export default MnuOneOrdermb;