import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import {LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { LoginStoreInstance } from './LoginStore';
import { FromBase64, GetFormattedCNum, IsValidS, ToBase64 } from '../Util/Util';
import { StaffStoreInstance } from './StaffStore';

//거래처 관련
class RetailerStore {
  //observable변수
  searchWord = '';
  retList = null; //직원 목록
  retBanList = null; //차단거래처 리스트
  retNoAppList = null; //앱 미설치 리스트

  //비 observable
  totalCount = 0; //전체 거래처의 수
  totalBanCount = 0; //전체 차단 거래처의 수
  totalNoAppCount = 0; //전체 미설치 업체수
  retNoAppListTotal = null; //전체 앱 미설치 목록
  noAppListRefIdx = -1;
  noAppListTotalDone = false;

  retListTotal = null; //전체 거래처 목록, 엑셀 출력을 위해 조회된 목록
  retListTotalDone = false;
  retListtotalIdx = -1;

  clearAll = () =>
  {
    this.searchWord = '';
    this.retList = null; //직원 목록
    this.retBanList = null;
    this.retNoAppList = null;

    //비 observable
    this.totalCount = 0; //전체 거래처의 수
    this.totalBanCount = 0; //전체 차단 거래처의 수
    this.totalNoAppCount = 0; //전체 미설치 업체수

    this.retNoAppListTotal = null; //전체 앱 미설치 목록
    this.noAppListRefIdx = -1;
    this.noAppListTotalDone = false;

    this.retListTotal = null; //전체 거래처 목록, 엑셀 출력을 위해 조회된 목록
    this.retListTotalDone = false;
    this.retListtotalIdx = -1;

    this.searchCallback = null;
    this.loadCallback = null;
    this.addCallback = null;
    this.delCallback = null;
    this.editCallback = null;
    this.editInfo = null; //수정된 정보
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      searchWord : observable, //검색어
      retList : observable, //소매상 목록
      retBanList : observable, //차단 소매상 목록
      retNoAppList : observable, //미설치 리스트

      clearAll : action,
      setSearchWord: action,
      clearRetList : action,
      clearBanRetList : action,
      clearNoAppList : action,

      searchRetailer : action,
      parseSearchResult : action,

      loadRetailer : action,
      parseLoadResult : action,

      addRetailer : action,
      parseAddResult : action,

      delRetailer : action,
      parseDelResult : action,

      editRetailer : action,
      parseEditResult : action,

      parseBanResult : action,
      getRetList : computed,
      getRetBanList : computed,
      getNoAppList : computed,
    });
  }
  
  findRetailerByIdx = (retIdx) =>
  {
    if (this.retList == null || this.retList.length < 1)
      return null;

    for (let i = 0;i < this.retList.length; ++i)
    {
      if (this.retList[i].idx === retIdx)
        return this.retList[i];
    }

    return null;
  }

  findRetailerBanByIdx = (retIdx) =>
  {
    if (this.retBanList == null || this.retBanList.length < 1)
      return null;

    for (let i = 0;i < this.retBanList.length; ++i)
    {
      if (this.retBanList[i].idx === retIdx)
        return this.retBanList[i];
    }

    return null;
  }

  //조회된 거래처 목록 클리어
  clearRetList = () =>
  {
    this.retList = null; 
    this.totalCount = 0;
  }

  //조회된 차단 거래처 목록 클리어
  clearBanRetList = () =>
  {
    this.retBanList = null;
    this.totalBanCount = 0;
  }

  //조회된 앱 미설치 업체 목록
  clearNoAppList = () =>
  {
    this.retNoAppList = null;
    this.totalNoAppCount = 0;
  }

  clearNoAppListTotal = () =>
  {
    this.retNoAppListTotal = null;
    this.noAppListRefIdx = -1;
    this.noAppListTotalDone = false;
  }

  clearRetListTotal = () =>
  {
    this.retListTotal = null; //전체 거래처 목록, 엑셀 출력을 위해 조회된 목록
    this.retListTotalDone = false;
    this.retListtotalIdx = -1;
  }

  //검색어 설정
  setSearchWord = (v) =>
  {
    this.searchWord = v;
  }

  //검색어가 올바른가?
  isSearchWordValid = () =>
  {
    return this.searchWord !== undefined && this.searchWord !== null && this.searchWord.length > 0;
  }

  get getRetList()
  {
    return this.retList;
  }

  get getRetBanList()
  {
    return this.retBanList;
  }

  get getNoAppList()
  {
    return this.retNoAppList;
  }

  isRetailerValid = () =>
  {
    return IsValidS(this.retList);
  }

  isRetailerBanValid = () =>
  {
    return IsValidS(this.retBanList);
  }

  isRetailerNoAppValid = () =>
  {
    return IsValidS(this.retNoAppList);
  }

  findSalesInList = (salesList, salesName) =>
  {
    if (salesList.length < 1)
      return false;

    for (let i = 0;i < salesList.length; ++i)
    {
      if (salesList[i] === salesName)
        return true;
    }

    return false;
  }

  noAppListToArray = (targetArray, salesList, targetSales) =>
  {
    if (!IsValidS(this.retNoAppListTotal))
      return;

    if (targetSales === null)
    {
      for (let i = 0;i < this.retNoAppListTotal.length; ++i)
      {
        if (!this.findSalesInList(salesList, this.retNoAppListTotal[i].sname))
          salesList.push(this.retNoAppListTotal[i].sname);

        let tempArray = [];
  
        tempArray.push(this.retNoAppListTotal[i].cname);
  
        if (this.retNoAppListTotal[i].oname === 'n')
          tempArray.push(' ');
        else
          tempArray.push(this.retNoAppListTotal[i].oname);
  
        tempArray.push(GetFormattedCNum(this.retNoAppListTotal[i].cnum));
  
        if (this.retNoAppListTotal[i].cphone === 'n')
          tempArray.push(' ');
        else
          tempArray.push(this.retNoAppListTotal[i].cphone);
  
        if (this.retNoAppListTotal[i].addr === 'n')
          tempArray.push(' ');
        else
          tempArray.push(this.retNoAppListTotal[i].addr);
  
        tempArray.push(this.retNoAppListTotal[i].sname);
  
        targetArray.push(tempArray);
      }
    }
    else
    {
      for (let i = 0;i < this.retNoAppListTotal.length; ++i)
      {
        if (this.retNoAppListTotal[i].sname !== targetSales)
          continue;

        let tempArray = [];
  
        tempArray.push(this.retNoAppListTotal[i].cname);
  
        if (this.retNoAppListTotal[i].oname === 'n')
          tempArray.push(' ');
        else
          tempArray.push(this.retNoAppListTotal[i].oname);
  
        tempArray.push(GetFormattedCNum(this.retNoAppListTotal[i].cnum));
  
        if (this.retNoAppListTotal[i].cphone === 'n')
          tempArray.push(' ');
        else
          tempArray.push(this.retNoAppListTotal[i].cphone);
  
        if (this.retNoAppListTotal[i].addr === 'n')
          tempArray.push(' ');
        else
          tempArray.push(this.retNoAppListTotal[i].addr);
  
        tempArray.push(this.retNoAppListTotal[i].sname);
  
        targetArray.push(tempArray);
      }
    }
  }

  findSalesInListTotal = (salesList, salesId) =>
  {
    if (!salesList || salesList.length < 1)
      return false;

    for (let i = 0;i < salesList.length; ++i)
    {
      if (salesList[i].id === salesId)
        return true;
    }

    return false;
  }

  totalListToExcelValue = (tempList, salesList, targetSales) =>
  {
    if (!IsValidS(this.retListTotal))
    {
      return;
    }

    if (!targetSales)
    {
      for (let i = 0;i < this.retListTotal.length; ++i)
      {

        if (!this.findSalesInListTotal(salesList, this.retListTotal[i].sid))
        {
          salesList.push({id : this.retListTotal[i].sid, name : this.retListTotal[i].sname});
        }

        let temp = [];

        temp.push(this.retListTotal[i].cname);
        temp.push(this.retListTotal[i].oname);
        temp.push(GetFormattedCNum(this.retListTotal[i].cnum));
        temp.push(this.retListTotal[i].ctype);
        temp.push(this.retListTotal[i].btype);
        temp.push(this.getCompanyTypeFromCode(this.retListTotal[i].ct));
        temp.push(this.retListTotal[i].cphone && this.retListTotal[i].cphone !== 'n' ? this.retListTotal[i].cphone : ' ');
        temp.push(this.retListTotal[i].addr);
        temp.push(' ');
        temp.push(this.retListTotal[i].sid);
        temp.push(this.retListTotal[i].sname);
        temp.push(this.retListTotal[i].st === 0 ? "정상" : "차단");
  
        tempList.push(temp);
      }
    }
    else
    {
      for (let i = 0;i < this.retListTotal.length; ++i)
      {

        if (this.retListTotal[i].sid !== targetSales)
        {
          continue;
        }

        let temp = [];

        temp.push(this.retListTotal[i].cname);
        temp.push(this.retListTotal[i].oname);
        temp.push(GetFormattedCNum(this.retListTotal[i].cnum));
        temp.push(this.retListTotal[i].ctype);
        temp.push(this.retListTotal[i].btype);
        temp.push(this.getCompanyTypeFromCode(this.retListTotal[i].ct));
        temp.push(this.retListTotal[i].cphone && this.retListTotal[i].cphone !== 'n' ? this.retListTotal[i].cphone : ' ');
        temp.push(this.retListTotal[i].addr);
        temp.push(' ');
        temp.push(this.retListTotal[i].sid);
        temp.push(this.retListTotal[i].sname);
        temp.push(this.retListTotal[i].st === 0 ? "정상" : "차단");
  
        tempList.push(temp);
      }
    }
  }

  getCompanyTypeFromCode = (companyType) =>
  {
    if (companyType === 0)
      return "유흥";
    
    return '일반';
  }
  //업체 검색하기-------------------------------------------------------------------------------------------------------------------------------------
  searchCallback = null;

  makeSearchParam (searchType, salesId, searchWord, requestType) {
    const params = new URLSearchParams();

    params.append("st", searchType.toString());
    params.append("sw", (ToBase64(searchWord)));
    params.append("rt", requestType.toString());
    params.append("sid", salesId);

    return params;
  }

  searchRetailer = (searchType, requestType, salesId, searchCallback) =>
  {
    this.searchCallback = searchCallback;

    axios({
      method:"POST",
      url: ("ret/ser.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSearchParam(searchType, salesId, this.searchWord, requestType)
    }).then((res)=>{
        this.parseSearchResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseSearchResult(null);
    });
  }

  parseSearchResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseSearchResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.searchCallback != null)
        this.searchCallback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.searchCallback != null)
        this.searchCallback(result.ret);

      return;
    }

    if (result.rt === 0) //거래처 목록
    {
      this.retList = result.list;

      if (IsValidS(this.retList))
      {
        this.retList.forEach(element => {
          this.decodeData(element);
        });
      }
      else
        this.retList = null;
    }
    else if (result.rt === 1) //차단 거래처 목록
    {
      this.retBanList = result.list;

      if (IsValidS(this.retBanList))
      {
        this.retBanList.forEach(element => {
          this.decodeData(element);
        });
      }
      else
        this.retBanList = null;
    }
    else if (result.rt === 2) //앱미설치 목록
    {
      this.retNoAppList = result.list;

      if (IsValidS(this.retNoAppList))
      {
        this.retNoAppList.forEach(element => {
          this.decodeData(element);
        });
      }
      else
        this.retNoAppList = null;
    }

    if (this.searchCallback != null)
      this.searchCallback(0);
  }

  decodeData = (element) =>
  {
    if (element.ctype !== undefined && element.ctype !== null && element.ctype.length > 0)
      element.ctype = FromBase64(element.ctype);

    if (element.btype !== undefined && element.btype !== null && element.btype.length > 0)
      element.btype = FromBase64(element.btype);

    if (element.cname !== undefined && element.cname !== null && element.cname.length > 0)
      element.cname = FromBase64(element.cname);

    if (element.oname !== undefined && element.oname !== null && element.oname.length > 0)
      element.oname = FromBase64(element.oname);

    if (element.addr !== undefined && element.addr !== null && element.addr.length > 0)
      element.addr = FromBase64(element.addr);

    if (element.sname !== undefined && element.sname !== null && element.sname.length > 0)
      element.sname = FromBase64(element.sname);

    if (element.pname !== undefined && element.pname !== null && element.pname.length > 0)
      element.pname = FromBase64(element.pname);
  }
  //업체 목록 읽기-------------------------------------------------------------------------------------------------------------------------------------
  loadCallback = null;

  makeLoadParam (pos, requestType, salesId) {

    if (LOG_ENABLE)
      console.log(`retlist : ${pos}, ${requestType}, ${salesId}`);
      
    const params = new URLSearchParams();

    params.append("pos", pos.toString());
    params.append("rt", requestType.toString());

    if (salesId)
      params.append("sid", salesId);

    return params;
  }

  loadRetailer = (pos, requestType, salesId, callback) =>
  {
    this.loadCallback = callback;

    axios({
      method:"POST",
      url: ("ret/get.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadParam(pos, requestType, salesId)
    }).then((res)=>{
        this.parseLoadResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseLoadResult(null);
    });
  }

  parseLoadResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseLoadResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadCallback != null)
        this.loadCallback(-2, -1, false);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.loadCallback != null)
        this.loadCallback(result.ret, -1, false);

      return;
    }

    let tc = -1;

    if (result.rt === 0 || result.rt === 5) //거래처 목록
    {
      this.retList = result.list;

      if (result.ft === 1)
      {
        tc = result.tc;
        this.totalCount = tc;
      }
  
      if (IsValidS(this.retList))
      {
        this.retList.forEach(element => {
          this.decodeData(element);
        });
      }
    }
    else if (result.rt === 1) //차단 거래처 목록
    {
      this.retBanList = result.list;

      if (result.ft === 1)
      {
        tc = result.tc;
        this.totalBanCount = tc;
      }
  
      if (IsValidS(this.retBanList))
      {
        this.retBanList.forEach(element => {
          this.decodeData(element);
        });
      }
    }
    else if (result.rt === 2) //앱 미설치 목록
    {
      this.retNoAppList = result.list;

      if (result.ft === 1)
      {
        tc = result.tc;
        this.totalNoAppCount = tc;
      }
  
      if (IsValidS(this.retNoAppList))
      {
        this.retNoAppList.forEach(element => {
          this.decodeData(element);
        });
      }
    }
    else if (result.rt === 3) //앱 미설치 목록(액셋출력용)
    {
      let readCount = 0;

      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeData(element);

          if (this.noAppListRefIdx < element.idx)
            this.noAppListRefIdx = element.idx;
        });

        if (IsValidS(this.retNoAppListTotal))
          this.retNoAppListTotal = this.retNoAppListTotal.concat(result.list);
        else
          this.retNoAppListTotal = result.list;

        readCount = result.list.length;
      }
      else
        this.noAppListTotalDone = true;

      if (this.loadCallback != null)
        this.loadCallback(0, readCount, false);

      return;
    }
    else if (result.rt === 4) //전체 거래처목록
    {
      let readCount = 0;

      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeData(element);

          if (this.retListtotalIdx < element.idx)
            this.retListtotalIdx = element.idx;
        });

        if (IsValidS(this.retListTotal))
          this.retListTotal = this.retListTotal.concat(result.list);
        else
          this.retListTotal = result.list;

        readCount = result.list.length;
      }
      else
        this.retListTotalDone = true;

      if (this.loadCallback != null)
        this.loadCallback(0, readCount, false);

      return;
    }

    if (this.loadCallback != null)
      this.loadCallback(0, tc, result.ft === 1);
  }
  //업체 추가-------------------------------------------------------------------------------------------------------------------------------------
  addCallback = null;

  makeAddParam (cname,oname,phone,cnum,sid,pid,ctype,btype,addr,email,companyType) {
    const params = new URLSearchParams();

    params.append("cname", (ToBase64(cname)));
    params.append("oname", (ToBase64(oname)));
    params.append("phone", phone);
    params.append("cnum", cnum);
    params.append("sid", sid);
    params.append("pid", pid);
    params.append("ctype", (ToBase64(ctype)));
    params.append("btype", (ToBase64(btype)));
    params.append("addr", (ToBase64(addr)));
    params.append("email", email);
    params.append("ct", companyType); //0 : 유흥, 1 : 일반

    return params;
  }

  addRetailer = (cname,oname,phone,cnum,sid,pid,ctype,btype,addr,email,companyType, callback) =>
  {
    this.addCallback = callback;

    axios({
      method:"POST",
      url: ("ret/add.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeAddParam(cname,oname,phone,cnum,sid,pid,ctype,btype,addr,email,companyType)
    }).then((res)=>{
        this.parseAddResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseAddResult(null);
    });
  }

  parseAddResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseAddResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.addCallback != null)
        this.addCallback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.addCallback != null)
        this.addCallback(result.ret);

      return;
    }

    ++this.totalCount;

    if (this.addCallback != null)
      this.addCallback(0);
  }
  //업체 삭제-------------------------------------------------------------------------------------------------------------------------------------
  delCallback = null;

  makeDelParam (idx) {
    const params = new URLSearchParams();

    params.append("idx", idx);

    return params;
  }

  delRetailer = (idx,callback) =>
  {
    this.delCallback = callback;

    if (LOG_ENABLE)
      console.log("del idx : " + idx);

    axios({
      method:"POST",
      url: ("ret/del.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeDelParam(idx)
    }).then((res)=>{
        this.parseDelResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseDelResult(null);
    });
  }

  parseDelResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseDelResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.delCallback != null)
        this.delCallback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.delCallback != null)
        this.delCallback(result.ret);

      return;
    }

    const idx = parseInt(result.idx);

    let retailerInfo = this.findRetailerByIdx(idx);

    if (retailerInfo !== null)
    {
      let newList = [];

      for (let i = 0;i < this.retList.length; ++i)
      {
        if (idx !== this.retList[i].idx)
          newList.push(this.retList[i]);
      }

      this.retList = newList;
    }

    --this.totalCount;

    if (this.delCallback != null)
      this.delCallback(0);
  }
  //업체 차단 또는 해제-------------------------------------------------------------------------------------------------------------------------------------
  banCallback = null;

  makeBanParam (idx, mode) {
    const params = new URLSearchParams();

    params.append("idx", idx);
    params.append("md", mode.toString());

    return params;
  }

  banRetailer = (idx,mode,callback) =>
  {
    this.banCallback = callback;

    if (LOG_ENABLE)
      console.log("ban idx : " + idx);

    axios({
      method:"POST",
      url: ("ret/ban.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeBanParam(idx,mode)
    }).then((res)=>{
        this.parseBanResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseBanResult(null);
    });
  }

  parseBanResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseBanResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.banCallback != null)
        this.banCallback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.banCallback != null)
        this.banCallback(result.ret);

      return;
    }

    const retIdx = parseInt(result.idx);

    if (result.md === "0")
    {
      const retailer = this.findRetailerByIdx(retIdx);

      if (retailer !== null)
      {
        this.retList = this.retList.filter(item => item.idx !== retIdx);
      }
    }
    else
    {
      const retailer = this.findRetailerBanByIdx(retIdx);

      if (retailer !== null)
      {
        this.retBanList = this.retBanList.filter(item => item.idx !== retIdx);
      }
    }    

    if (this.banCallback != null)
      this.banCallback(0);
  }
  //업체 수정-------------------------------------------------------------------------------------------------------------------------------------
  editCallback = null;
  editInfo = null; //수정된 정보

  makeEditParam (idx,cname,oname,phone,cnum,sid,pid,ctype,btype,addr,email,companyType) {
    const params = new URLSearchParams();

    params.append("idx", idx);
    params.append("cname", (ToBase64(cname)));
    params.append("oname", (ToBase64(oname)));
    params.append("phone", phone);
    params.append("cnum", cnum);
    params.append("sid", sid);
    params.append("pid", pid);
    params.append("ctype", (ToBase64(ctype)));
    params.append("btype", (ToBase64(btype)));
    params.append("addr", (ToBase64(addr)));
    params.append("email", email);
    params.append("ct", companyType);

    if (LOG_ENABLE)
      console.log(`makeEditParam : ${params.toString()}`);

    return params;
  }

  editRetailer = (idx,cname,oname,phone,cnum,sid,pid,ctype,btype,addr,email,companyType, callback) =>
  {
    this.editCallback = callback;

    const retailerInfo = this.findRetailerByIdx(idx);

    let salesName = StaffStoreInstance.findSalesNameFromSalesManager(sid);
    let partnerName = StaffStoreInstance.findSalesNameFromSalesManager(pid);

    if (!IsValidS(salesName))
      salesName = retailerInfo.sname;

    if (!IsValidS(partnerName))
      partnerName = retailerInfo.pname;

    //수정 정보를 보관해둔다.
    this.editInfo = {
      ...retailerInfo,
      idx : idx,
      cname : cname,
      oname: oname,
      cphone: phone,
      cnum : cnum,
      sid: sid,
      pid: pid,
      ctype: ctype,
      btype: btype,
      addr: addr,
      email: email,
      ct: companyType,
      sname: salesName,
      pname: partnerName,
    };

    axios({
      method:"POST",
      url: ("ret/edit.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeEditParam(idx, cname,oname,phone,cnum,sid,pid,ctype,btype,addr,email,companyType)
    }).then((res)=>{
        this.parseEditResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseEditResult(null);
    });
  }

  parseEditResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseEditResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.editCallback != null)
        this.editCallback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.editCallback != null)
        this.editCallback(result.ret);

      return;
    }

    const idx = this.editInfo.idx;

    let retailerInfo = this.findRetailerByIdx(idx);

    if (retailerInfo !== null)
    {
      let newList = [];

      for (let i = 0;i < this.retList.length; ++i)
      {
        if (idx !== this.retList[i].idx)
          newList.push(this.retList[i]);
        else
          newList.push(this.editInfo);
      }

      this.retList = newList;
    }

    if (this.editCallback != null)
      this.editCallback(0);
  }
  //거래처 계정 수정-------------------------------------------------------------------------------------------------------------------------------------
  editAccountCallback = null;

  makeEditAccountParam (idx, rt, nid, npw) {
    const params = new URLSearchParams();

    params.append("idx", idx);
    params.append("rt", rt.toString());

    if (IsValidS(nid))
      params.append("nid", (ToBase64(nid)));

    if (IsValidS(npw))
      params.append("npw", (ToBase64(npw)));

    return params;
  }

  editRetailerAccount = (idx,rt, nid, npw, callback) =>
  {
    this.editAccountCallback = callback;

    axios({
      method:"POST",
      url: ("ret/retacc.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeEditAccountParam(idx,rt, nid, npw)
    }).then((res)=>{
        this.parseEditAccountResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseEditAccountResult(null);
    });
  }

  parseEditAccountResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseEditAccountResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.editAccountCallback != null)
        this.editAccountCallback(-2, null);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
        LoginStoreInstance.sessionError = true;

      if (this.editAccountCallback != null)
        this.editAccountCallback(result.ret, null);
    }

    let id = null;

    if (result.rt === 0)
    {
      id = result.rid;
    }

    if (this.editAccountCallback != null)
      this.editAccountCallback(result.ret, id);
  }
}
//-------------------------------------------------------------------------------------------------------------------------------------------------
const RetailerStoreInstance = new RetailerStore();
const RetailerStoreContext = React.createContext(RetailerStoreInstance);

const UseRetailerStore = () => React.useContext(RetailerStoreContext);

export {UseRetailerStore, RetailerStoreInstance};
