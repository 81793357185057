import { Fragment, useState } from "react";
import { GetImgUrl, LOG_ENABLE } from "../../URL/AppUrl";
import { IsValidS, IsValidV } from "../../Util/Util";

/*
function GetAddFile(noticeInfo)
{
  if (noticeInfo.afileori === undefined || noticeInfo.afileori === null || noticeInfo.afileori.length < 1 || noticeInfo.afileori === "n")
    return "";

  return noticeInfo.afileori;
}

function GetAddImg(noticeInfo)
{
  if (noticeInfo.aimgori === undefined || noticeInfo.aimgori === null || noticeInfo.aimgori.length < 1 || noticeInfo.aimgori === "n")
    return "";

  return noticeInfo.aimgori;
}*/

//첨부 파일 첨부된 파일있는경우
const AddFileWithFile = (props) =>
{
  return (
    <Fragment>
      <label htmlFor="fileInput1" className="noticeeditfontaf">{props.afile}</label>
      <input type="file" id="fileInput1" className="pdinputfilestyle"
        onChange={(e) => {
          
          if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              return;

          if (LOG_ENABLE)
            console.log(e.target.files[0]);

            props.setFile(e.target.files[0].name);
            props.setFileNew(e.target.files[0]);
      }}/>

      <img src={GetImgUrl("mbntcdel.png")} alt="첨부파일" className="ntcafimg2 btncur" onClick={() => props.handleDeleteFile(1)}/>
    </Fragment>
  );
}

//첨부 파일 첨부된 파일없는경우
const AddFileEmpty = (props) =>
{
  return (
    <div className="bgcolor1 ntcaddfilebg flexcenterrow">
      <label htmlFor="fileInput1" className="btncur fontnw">파일 선택</label>
      <input type="file" id="fileInput1" className="pdinputfilestyle"
        onChange={(e) => {

          if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              return;

          if (LOG_ENABLE)
            console.log(e.target.files[0]);

            props.setFile(e.target.files[0].name);
            props.setFileNew(e.target.files[0]);
      }}/>
    </div>
  );
}

//첨부 이미지 첨부된 파일있는경우
const AddImgWithFile = (props) =>
{
  return (
    <Fragment>
      <label htmlFor="fileInput2" className="noticeeditfontaf">{props.aimg}</label>
      <input type="file" id="fileInput2" className="pdinputfilestyle" accept="image/png, image/jpeg"
        onChange={(e) => {

          if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              return;

          if (LOG_ENABLE)
            console.log(e.target.files[0]);

            props.setImg(e.target.files[0].name);
            props.setImgNew(e.target.files[0]);
      }}/>

      <img src={GetImgUrl("mbntcdel.png")} alt="첨부파일" className="ntcafimg2 btncur" onClick={() => props.handleDeleteFile(1)}/>
    </Fragment>
  );
}

//첨부 이미지 첨부된 파일없는경우
const AddImgEmpty = (props) =>
{
  return (
    <div className="bgcolor1 ntcaddfilebg flexcenterrow">
      <label htmlFor="fileInput2" className="btncur fontnw">파일 선택</label>
      <input type="file" id="fileInput2" className="pdinputfilestyle" accept="image/png, image/jpeg"
        onChange={(e) => {

          if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              return;
              
          if (LOG_ENABLE)
            console.log(e.target.files[0]);

            props.setImg(e.target.files[0].name);
            props.setImgNew(e.target.files[0]);
      }}/>
    </div>
  );
}

function IsValidFile(notice)
{
  if (!IsValidV(notice) || !IsValidS(notice.afileori) || notice.afileori === "n")
    return false;

  return true;
}

function IsValidFileImg(notice)
{
  if (!IsValidV(notice) || !IsValidS(notice.aimgori) || notice.aimgori === "n")
    return false;

  return true;
}

const NoticeEdit = (props) =>
{
  const [title, setTitle] = useState(IsValidV(props.editInfo)?props.editInfo.title:'');
  const [uname, setName] = useState(IsValidV(props.editInfo)?props.editInfo.uname:'');
  const [desc, setDesc] = useState(IsValidV(props.editInfo)?props.editInfo.notice:'');
  const [afile, setFile] = useState(IsValidFile(props.editInfo) ? props.editInfo.afileori : null);
  const [aimg, setImg] = useState(IsValidFileImg(props.editInfo) ? props.editInfo.aimgori : null);

  const[afilenew, setFileNew] = useState(null);
  const[aimgnew, setImgNew] = useState(null);

  const [fileDeleted, setFileDeleted] = useState(false);
  const [imgDeleted, setImgDeleted] = useState(false);

  const handleDeleteFile = (fileMode) =>
  {
    if (fileMode === 0)
    {
      setFile(null);
      setFileNew(null);
      setFileDeleted(true);
    }
    else
    {
      setImg(null);
      setImgNew(null);
      setImgDeleted(true);
    }
  };
  
  return (
    <div className="noticeview flexcentercolstart">
      {/*제목 */}
      <div className="noticeviewtitle flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">제목</label>
        </div>
        <div className="noticevieweditright flexcenterrowstart">
          <input type="text" onChange={(e) => setTitle(e.target.value)} value = {title} className="noticeeditinp fontn" placeholder="제목을 입력해주세요." maxLength="40"/>
        </div>
      </div>

      {/*작성자 */}
      <div className="noticeviewname flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">작성자</label>
        </div>
        <div className="noticevieweditright flexcenterrowstart">
          <input type="text" onChange={(e) => setName(e.target.value)} value = {uname} className="noticeeditinp fontn" placeholder="공지 작성자" maxLength="40"/>
        </div>
      </div>

      {/*내용 */}
      <div className="noticeviewdesc flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">내용</label>
        </div>
        <div className="noticevieweditrightdesc">
          <textarea onChange={(e) => setDesc(e.target.value)} value={desc} className="noticeeditinpdesc fontn"  placeholder="내용을 입력해주세요.">
            {desc}
          </textarea>
        </div>
      </div>

      {/*첨부파일 */}
      <div className="noticeviewfile flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">첨부파일</label>
        </div>
        <div className="noticevieweditright flexcenterrowstart">
          {
            (afile === undefined || afile === null || afile.length < 1) ? null : <img src={GetImgUrl("ntcffile.png")} alt="첨부파일" className="ntcafimg"/>
          }
          {
            (afile === undefined || afile === null || afile.length < 1) ? 
              (<AddFileEmpty  setFile={setFile} setFileNew = {(newFile) => { setFileNew(newFile); setFileDeleted(false);}}/>) : 
              (<AddFileWithFile afile={afile} setFile={setFile} setFileNew = {(newFile) => {setFileNew(newFile); setFileDeleted(false);}}
                handleDeleteFile = {handleDeleteFile}/>)
          }
          {
            (afile === undefined || afile === null || afile.length < 1) ? (<label className="fontn">선택된 파일이 없습니다.</label>) : null
          }
        </div>
      </div>

      {/*이미지 */}
      <div className="noticeviewimg flexcenterrow">
        <div className="noticevieweditleft flexcenterrow">
          <label className="fontn">이미지</label>
        </div>
        <div className="noticevieweditright flexcenterrowstart">
          {
            (aimg === undefined || aimg === null || aimg.length < 1) ? null : <img src={GetImgUrl("ntcfimg.png")} alt="첨부이미지" className="ntcafimg"/>
          }
          
          {
            (aimg === undefined || aimg === null || aimg.length < 1) ? 
              (<AddImgEmpty  setImg={setImg} setImgNew = {(newImg) => {setImgNew(newImg); setImgDeleted(false);}}/>) : 
              (<AddImgWithFile aimg={aimg} setImg={setImg} setImgNew = {(newImg) => {setImgNew(newImg); setImgDeleted(false);}}
                handleDeleteFile = {handleDeleteFile}/>)
          }
          {
            (aimg === undefined || aimg === null || aimg.length < 1) ? (<label className="fontn">선택된 파일이 없습니다.</label>) : null
          }
        </div>
      </div>

      {/*버튼영역*/}
      <div className="noticeviewbtnarea flexcenterrow">

        {props.editMode === 1 ? (
          <div className="noticeviewbtncenter flexcenterrow">
            <button className="noticeviewbtnprev fontnwb" onClick={() => props.handleWriteResult(false, null, null, null, null, null)}>
              취소
            </button>
            <button className="bgcolor3 noticeviewbtnnext fontnwb" onClick={() => props.handleWriteResult(true, uname, title, desc, afilenew, aimgnew)}>
              등록
            </button>
          </div>
          ):
          (
            <div className="noticeviewbtncenter flexcenterrow">
              <button className="noticeviewbtnprev fontnwb" onClick={() => props.handleEditResult(false, null, null, null, null, null)}>
                취소
              </button>
              <button className="bgcolor1 noticeviewbtnnext fontnwb" onClick={() => props.handleEditResult(true, props.editInfo.idx, uname, title, desc, afilenew, aimgnew, fileDeleted ? 'n' : props.editInfo.afile, 
                imgDeleted ? 'n' : props.editInfo.aimg, fileDeleted ? 'n' : props.editInfo.afileori, imgDeleted ? 'n' : props.editInfo.aimgori)}>
                수정완료
              </button>
            </div>
          )
        }
        
      </div>
    </div>
  );
}

export default NoticeEdit;