import React from 'react';
import { observer } from "mobx-react";
import {  LOG_ENABLE, mobileOn, GetImgUrl, GetAppImgUrl } from "../../URL/AppUrl";
import "../../App.css";
import "./Product.css";
import { ProductStoreInstance } from "../../Stores/ProductStore";
import { IsValidS, IsValidV, GetComProductTypeName, GetProductUnitName, GetProductUsesName, ConvertItemCategoryToUnit } from '../../Util/Util';
import Loading from '../Loading/Loading';
import ProductAddNewItem from './ProductAddNewItem';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { Fragment } from 'react';

class ProductAddNew extends React.Component {

  state = {
    loading : false,
    editMode : 0, //0 : 상품 선택모드, 1 : 정보 입력 단계
    selectedItem : null,
    icode: '', //품목코드
    iname: '', //상품명
    alias: '', //단축명
    iunit: 0, //취급단위
    iuses: 0, //용도
  }

  setIname = (v) =>
  {
    this.setState((prevState) => ({...prevState, iname : v}));
  }

  setIcode = (v) =>
  {
    this.setState((prevState) => ({...prevState, icode : v}));
  }

  setAlias = (v) =>
  {
    this.setState((prevState) => ({...prevState, alias : v}));
  }

  setUses = (v) =>
  {
    this.setState((prevState) => ({...prevState, iuses : v}));
  }

  setUnit = (v) =>
  {
    this.setState((prevState) => ({...prevState, iunit : v}));
  }

  componentDidMount() {

    if (LOG_ENABLE)
      console.log("ProductAddNew.componentDidMount");
  }

  componentWillUnmount()
  {
    if (LOG_ENABLE)
      console.log("ProductAddNew.componentWillUnmount");
    
    ProductStoreInstance.clearCompdList();
  }

  setLoading = (enable) =>
  {
    this.setState((prevState) => ({...prevState, loading : enable}));
  }
  
  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.handleSearch();
    }
  }

  handleSearch = () =>
  {
    if (this.state.loading)
    {
      return;
    }

    if (!ProductStoreInstance.isSearchWordValid())
    {
      alert('검색할 상품명을 2자이상 입력하세요.');
      return;
    }

    this.setLoading(true);
    ProductStoreInstance.searchCommonProduct(this.onSearchResult);
  }

  onSearchResult = (resultCode) =>
  {
    this.setLoading(false);
  }

  handleSelect = (item) =>
  {
    if (!IsValidV(item))
    {
      return;
    }

    if (LOG_ENABLE)
      console.log(`handleSelect : ${item.idx}`);

    this.setState((prevState) => ({...prevState, selectedItem : item, iname: item.iname, editMode: 1}));
  }

  getMaker = () =>
  {
    if (!IsValidV(this.state.selectedItem))
    {
      return '';
    }

    return this.state.selectedItem.mk;
  }

  getProductName = () =>
  {
    if (!IsValidV(this.state.selectedItem))
    {
      return '';
    }

    return this.state.selectedItem.iname;
  }

  getProductVolume = () =>
  {
    if (!IsValidV(this.state.selectedItem))
    {
      return '';
    }

    return this.state.selectedItem.ivol;
  }

  getProductCount = () =>
  {
    if (!IsValidV(this.state.selectedItem))
    {
      return '';
    }

    return this.state.selectedItem.cnt;
  }

  getProductFreq = () =>
  {
    if (!IsValidV(this.state.selectedItem))
    {
      return '';
    }

    return this.state.selectedItem.freq;
  }

  getProductImg = () =>
  {
    if (!IsValidV(this.state.selectedItem))
    {
      return GetImgUrl('itemimage.png');
    }

    return GetAppImgUrl(this.state.selectedItem.img);
  }

  getProductUnitName = () =>
  {
    if (!IsValidV(this.state.selectedItem))
    {
      return '';
    }

    return GetProductUnitName(this.state.selectedItem.iunit);
  }

  getProductUsesName = () =>
  {
    if (!IsValidV(this.state.selectedItem))
    {
      return '';
    }

    return GetProductUsesName(this.state.selectedItem.iuses);
  }

  GetProductCategoryName = () =>
  {
    if (!IsValidV(this.state.selectedItem))
    {
      return '';
    }

    if (LoginStoreInstance.compIdx === 37 || LoginStoreInstance.compIdx === 186)
    {
      const itemCategory = ConvertItemCategoryToUnit(LoginStoreInstance.compIdx, this.state.selectedItem.itc);
      return ProductStoreInstance.getCategoryNameByCode(itemCategory);
    }

    return GetComProductTypeName(this.state.selectedItem.itc);
  }

  handleAddProduct = () =>
  {
    if (LOG_ENABLE)
    {
      console.log(`handleAddProduct`);
    }

    if (!IsValidV(this.state.selectedItem))
    {
      alert('추가할 상품을 검색 후 선택해 주세요.');
      return;
    }

    if (!IsValidS(this.state.icode))
    {
      alert('상품의 품목 코드를 입력해 주세요.');
      return;
    }

    if (!IsValidS(this.state.iname))
    {
      alert('상품명을 입력하세요.');
      return;
    }

    let alias = '';

    if (!IsValidS(this.state.alias))
    {
      //alert('상품의 단축명을 입력해 주세요.\n단축명은 판매일보 출력시 사용됩니다.');
      //return;

      alias = 'n';

      if (!window.confirm(`${this.state.iname}\n품목코드 : ${this.state.icode}\n상품을 추가할까요?`))
      {
        return;
      }
    }
    else
    {
      alias = this.state.alias;

      if (!window.confirm(`${this.state.iname}\n품목코드 : ${this.state.icode}\n단축명 : ${this.state.alias}\n상품을 추가할까요?`))
      {
        return;
      }
    }

    this.props.handleAddItem(ConvertItemCategoryToUnit(LoginStoreInstance.compIdx, this.state.selectedItem.itc), 
      this.state.icode, this.state.iname, alias, this.state.selectedItem.ivol,
      this.state.iunit, this.state.iuses, 
      this.state.selectedItem.freq, 
      this.state.selectedItem.cnt, 
      this.state.selectedItem.price, 
      this.state.selectedItem.img, 
      this.state.selectedItem.idx,
      this.state.selectedItem.mk,
      this.state.selectedItem.dt);
  }
  
  isProductDetailValid = () =>
  {
    if (!IsValidV(this.state.selectedItem) || !IsValidV(this.state.selectedItem.dt) || this.state.selectedItem.dt === 'n')
      return false;

    return true;
  }

  getProductDetailUrl = () =>
  {
    if (!IsValidV(this.state.selectedItem) || !IsValidV(this.state.selectedItem.dt) || this.state.selectedItem.dt === 'n')
      return '없음';

    return this.state.selectedItem.dt;
  }

  gotoProductDetailPage = () =>
  {
    if (!this.isProductDetailValid())
    {
      return;
    }

    window.open(this.getProductDetailUrl(), '_blank');
  }

  render()
  {
    let productList = null;
    const comPdList = ProductStoreInstance.compdList;

    if (IsValidS(comPdList))
    {
      productList = comPdList.map(item => {
        return (<ProductAddNewItem key={item.idx} item = {item} handleSelect={this.handleSelect}/>);
      });
    }

    /*const categoryList = ProductStoreInstance.productCategory;

    let category = null;

    if (categoryList != null && categoryList.length > 0)
    {
      category = categoryList.map(item => {
        return (<option key={item.idx} value={item.iid}>{item.cname}</option>);
      });
    }*/

    if (mobileOn)
    {
      if (this.state.editMode === 0)
      {
        return(
          <div className="pdcomaddpopbg flexcenterrow">
            {
              this.state.loading ? <Loading />:null
            }
            
            <div className="pdcomaddpopmb flexcentercol">
              {/*타이틀*/}
              <div className='cntfullh flexcenterrow marginbottom30'>
                <div className='dcell1'></div>
                <div className='dcell8 flexcenterrow'>
                  <label className='mfontnb'>상품선택</label>
                </div>
                <div className='dcell1 flexcenterrow'>
                  <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur' onClick={() => {
                    if (this.state.loading)
                      return;
                    
                    this.props.handleCloseCompd();
                  }}/>
                </div>
              </div>
  
              {/*검색영역*/}
              <div className='cntfullh flexcenterrowstart'>
                <label className='marginleft20 mfontnb'>상품 DB 검색&nbsp;&nbsp;&nbsp;</label>
                <input type="text" onChange={(e) => ProductStoreInstance.setSearchWord(e.target.value)} value = {ProductStoreInstance.searchWord} 
                  className="pdsearchinpmb mfontn"
                  onKeyPress={this.handleKeyPress}
                  placeholder='상품명을 2글자 이상입력해 주세요.'
                />
  
                <button className='compdserbtn mfontnwb btncur' onClick={this.handleSearch}>검색</button>
              </div>
  
              <div className='pdcomhline margintop10 marginbottom10'></div>
              
              {/*하단 정보영역*/}
              <div className='pdcomleftmb flexcentercolstart'>
                {
                  !IsValidS(productList) ? (
                    <div className='flexcentercol'>
                      <label className='mfontn'>상품을 검색 후 선택하세요.</label>
                      <label className='mfontn'>상품 DB에 상품이 없어 검색되지 않는 경우</label>
                      <label className='mfontn'>개발사에 상품 추가를 요청해 주세요.</label>
                    </div>
                  ) : null
                }
                
                {productList}
              </div>

            </div>    
          </div>
        );
      }
      else
      {
        return(
          <div className="pdcomaddpopbg flexcenterrow">
            {
              this.state.loading ? <Loading />:null
            }
            
            <div className="pdcomaddpopmb flexcentercol">
              {/*타이틀*/}
              <div className='cntfullh flexcenterrow marginbottom30'>
                <div className='dcell1'></div>
                <div className='dcell8 flexcenterrow'>
                  <label className='mfontnb'>상품 정보 입력</label>
                </div>
                <div className='dcell1 flexcenterrow'>
                  <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur' onClick={() => {
                    if (this.state.loading)
                      return;
                    
                    this.props.handleCloseCompd();
                  }}/>
                </div>
              </div>
  
              <div className='pdcomhline margintop10 marginbottom10'></div>
              
              {/*하단 정보영역*/}
              <div className='pdcomleftmb flexcentercolstart'>
  
                <div className='flexcenterrow pdcominforow3'>
                  <label className='mfontnb'>선택된 상품</label>
                  <img src={this.getProductImg()} alt="상품이미지" className='pdcominpimg'/>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>제조사</label>
                  <label className='mfontn'>{this.getMaker()}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>판매분류</label>
                  <label className='mfontn'>{this.GetProductCategoryName()}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>용량</label>
                  <label className='mfontn'>{`${this.getProductVolume()}ml`}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>도수</label>
                  <label className='mfontn'>{this.getProductFreq()}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>입수</label>
                  <label className='mfontn'>{this.getProductCount()}</label>
                </div>
                <div className='pdcominfoline'></div>

                {/*
                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>취급단위</label>
                  <label className='mfontn'>{this.getProductUnitName()}</label>
                </div>
                <div className='pdcominfoline'></div>
                
                
                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>용도</label>
                  <label className='mfontn'>{this.getProductUsesName()}</label>
                </div>
                <div className='pdcominfoline'></div>
                */}

                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>상세페이지</label>
                  <label className={this.isProductDetailValid()? 'mfontn btncur' : 'mfontn2'}
                    onClick={() =>{
                      this.gotoProductDetailPage();
                    }}
                  >{this.getProductDetailUrl()}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow2'>
                  <label className='fontsb2'>아래 항목은 직접 입력해 주세요.</label>
                </div>
                <div className='pdcominfoline2'></div>

                {/*취급단위*/}
                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>취급단위</label>
                  
                  <select className="compditcsel mfontn btncur" 
                    onChange={(e) => {
                        console.log(e.target.value);
                        this.setUnit(parseInt(e.target.value));
                    }}

                    value={this.state.iunit}
                  >
                    <option value="0">박스</option>
                    <option value="1">병</option>
                    <option value="2">박스/병</option>
                    <option value="3">박스/개</option>
                    <option value="4">개</option>
                  </select>
                </div>
                <div className='pdcominfoline'></div>

                {/*용도*/}
                <div className="flexcenterrow pdcominforowmb">
                  <label className="mfontnb">용도</label>
                  
                  <select className="compditcsel mfontn btncur" 
                    onChange={(e) => {
                        console.log(e.target.value);
                        this.setUses(parseInt(e.target.value));
                    }}

                    value={this.state.iuses}
                  >
                    <option value="0">유흥</option>
                    <option value="1">일반</option>
                    <option value="2">공통</option>
                  </select>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>품목코드</label>
                  <input type="text" onChange={(e) => this.setIcode(e.target.value)} value = {this.state.icode} className="pdcominputeditinp mfontn"
                    placeholder="품목코드를 입력하세요." maxLength="15"/>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>상품명</label>
                  <input type="text" onChange={(e) => this.setIname(e.target.value)} value = {this.state.iname} className="pdcominputeditinp mfontn"
                    placeholder="상품명을 입력하세요."  maxLength="40"/>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforowmb'>
                  <label className='mfontnb'>단축명</label>
                  <input type="text" onChange={(e) => this.setAlias(e.target.value)} value = {this.state.alias} className="pdcominputeditinp mfontn"
                    placeholder="단축명은 일일판매일보에 사용됩니다."  maxLength="40"/>
                </div>
                <div className='pdcominfoline'></div>

                <button className='pdcominfoaddbtnmb mfontnwb btncur bgcolor3' onClick={this.handleAddProduct}>상품추가</button>
              </div>
  
            </div>
          </div>
        );
      }
      
    }
    else
    {
      return(
        <div className="pdcomaddpopbg flexcenterrow">
          {
            this.state.loading ? <Loading />:null
          }
          
          <div className="pdcomaddpop flexcentercolstart">
            {/*타이틀*/}
            <div className='pdcomtt flexcenterrow marginbottom30'>
              <div className='dcell1'></div>
              <div className='dcell8 flexcenterrow'>
                <label className='fontlargeb'>상품추가</label>
              </div>
              <div className='dcell1 flexcenterrow'>
                <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur' onClick={() => {
                  if (this.state.loading)
                    return;
                  
                  this.props.handleCloseCompd();
                }}/>
              </div>
            </div>

            {/*검색영역*/}
            <div className='pdcomsera flexcenterrowstart'>
              <label className='marginleft20 fontnb'>상품 DB 검색&nbsp;&nbsp;&nbsp;</label>
              <input type="text" onChange={(e) => ProductStoreInstance.setSearchWord(e.target.value)} value = {ProductStoreInstance.searchWord} 
                className="pdsearchinpmb fontn"
                onKeyPress={this.handleKeyPress}
                placeholder='상품명을 2글자 이상입력해 주세요.'
              />

              <button className='compdserbtn fontnwb btncur' onClick={this.handleSearch}>검색</button>

              {
                LoginStoreInstance.getUserLevel <= 1 ? (
                  <button className='compdaddcompd fontnwb btncur' onClick={this.props.handleAddComPD}>공통상품DB에 상품추가</button>
                ) : null
              }
              
            </div>

            <div className='pdcomhline margintop10 marginbottom10'></div>
            
            {/*하단 정보영역*/}
            <div className='pdcomcont flexcenterrow'>
              <div className='pdcomleft flexcentercolstart'>
                {
                  !IsValidS(productList) ? (
                    <div className='flexcentercol'>
                      <label className='fonts2'>상품을 검색 후 선택하세요.</label>
                      <label className='fonts2'>상품 검색 시 전체 상품명을 입력하기 보다는 상품의 핵심 단어 위주로 검색해 주세요.</label>
                      <label className='fonts2'>ex) 상품명이 <b>"샤또네프 뒤 빠쁘 루즈, 보카스텔"</b>일때</label>
                      <label className='fonts2'><b>"샤또네프"</b> 또는 <b>"보카스텔"</b>만 입력해서 검색</label>

                      <label className='fonts2'><br/>상품의 <b>취급단위,입수</b> 등에 따라 같은 이름을 가진 상품이 </label>
                      <label className='fonts2'>여러개 있을 수 있으니, 해당 부분을 자세히 확인해 주세요. </label>
                      <label className='fonts2'><br/>제조사의 경우 때에 따라 수입사 정보로 기록되어 있을 수 있습니다.</label>
                      <label className='fonts2'>해당 부분은 단순 참고 용도입니다.</label>
                      <label className='mfontnr'><br/><b>상품명이 한개 글자인경우 뒤에 다른 부가 정보를 붙이면 한개 글자 검색이되지 않습니다.</b></label>
                      <label className='mfontnr'><b>예) 상품명이 "센" 일경우 "센 130ml" 등과 같이 뒤에 다른 부가 정보를 붙이면</b></label>
                      <label className='mfontnr'><b>"센"으로 검색 불가</b></label>
                      <label className='mfontnr'><b>이런 경우 "센130ml" 등과 같이 공백을 업애고</b></label>
                      <label className='mfontnr'><b>검색시 "센1" 또는 "센130" 등의 형태로 검색해야 합니다.</b></label>
                      {
                        LoginStoreInstance.getUserLevel <= 1 ? (
                          <Fragment>
                            <label className='fonts2'><br/>개발사에서는 지속적으로 상품DB를 구축하겠지만</label>
                            <label className='fonts2'>아직 상품DB에 없는 상품을 급하게 추가할 필요가 있는경우</label>
                            <label className='fonts2'><b>"공통상품DB에 상품추가"</b></label>
                            <label className='fonts2'>기능을 통해 직접 공통DB에 상품을 추가 할 수 있습니다.</label>
                            <label className='fonts2'>해당 기능은 급하게 상품을 추가해야 하는경우에만 이용해 주시고</label>
                            <label className='fonts2'>일반적인 경우에는 개발사에 상품 추가를 요청해 주세요.</label>
                          </Fragment>
                        ) : null
                      }
                      
                    </div>
                  ) : null
                }
                
                {productList}
              </div>

              <div className='pdcomright flexcentercolstart'>
                
                <div className='flexcenterrow pdcominforow3'>
                  <label className='fontnb'>선택된 상품</label>
                  <img src={this.getProductImg()} alt="상품이미지" className='pdcominpimg'/>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>제조사</label>
                  <label className='fontn'>{this.getMaker()}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>판매분류</label>
                  <label className='fontn'>{this.GetProductCategoryName()}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>용량</label>
                  <label className='fontn'>{`${this.getProductVolume()}ml`}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>도수</label>
                  <label className='fontn'>{this.getProductFreq()}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>입수</label>
                  <label className='fontn'>{this.getProductCount()}</label>
                </div>
                <div className='pdcominfoline'></div>

                {/* 
                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>취급단위</label>
                  <label className='fontn'>{this.getProductUnitName()}</label>
                </div>
                <div className='pdcominfoline'></div>

                
                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>용도</label>
                  <label className='fontn'>{this.getProductUsesName()}</label>
                </div>
                <div className='pdcominfoline'></div>
                */}

                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>상세페이지</label>
                  <label className={this.isProductDetailValid() ? 'fontn btncur' : 'fontn2'}
                    onClick={() => {
                      if (this.isProductDetailValid())
                        this.gotoProductDetailPage();
                    }}
                  >{this.getProductDetailUrl()}</label>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow2'>
                  <label className='fontsb2'>아래 항목은 직접 입력해 주세요.</label>
                </div>
                <div className='pdcominfoline2'></div>

                {/*취급단위*/}
                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>취급단위</label>
                  
                  <select className="compditcsel fontn btncur" 
                    onChange={(e) => {
                        console.log(e.target.value);
                        this.setUnit(parseInt(e.target.value));
                    }}

                    value={this.state.iunit}
                  >
                    <option value="0">박스</option>
                    <option value="1">병</option>
                    <option value="2">박스/병</option>
                    <option value="3">박스/개</option>
                    <option value="4">개</option>
                  </select>
                </div>
                <div className='pdcominfoline'></div>

                {/*용도*/}
                <div className="flexcenterrow pdcominforow">
                  <label className="fontnb">용도</label>
                  
                  <select className="compditcsel fontn btncur" 
                    onChange={(e) => {
                        console.log(e.target.value);
                        this.setUses(parseInt(e.target.value));
                    }}

                    value={this.state.iuses}
                  >
                    <option value="0">유흥</option>
                    <option value="1">일반</option>
                    <option value="2">공통</option>
                  </select>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>품목코드</label>
                  <input type="text" onChange={(e) => this.setIcode(e.target.value)} value = {this.state.icode} className="pdcominputeditinp fontn"
                    placeholder="품목코드를 입력하세요." maxLength="15"/>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>상품명</label>
                  <input type="text" onChange={(e) => this.setIname(e.target.value)} value = {this.state.iname} className="pdcominputeditinp fontn"
                    placeholder="상품명을 입력하세요."  maxLength="40"/>
                </div>
                <div className='pdcominfoline'></div>

                <div className='flexcenterrow pdcominforow'>
                  <label className='fontnb'>단축명</label>
                  <input type="text" onChange={(e) => this.setAlias(e.target.value)} value = {this.state.alias} className="pdcominputeditinp fontn"
                    placeholder="단축명은 일일판매일보에 사용됩니다."  maxLength="40"/>
                </div>
                <div className='pdcominfoline'></div>

                <button className='pdcominfoaddbtn fontnwb btncur bgcolor3' onClick={this.handleAddProduct}>상품추가</button>
              </div>
            </div>

          </div>
        </div>
      );
    }    
  }
}

export default observer(ProductAddNew);