import { GetFormattedPhone2 } from "../../Util/Util";

const MnuOrderDetmb = (props) =>
{
  return (
    <div className="mnuodeditpopbg flexcenterrow">
      <div className="mnuodeditpop flexcentercolstart">
        <label className="mfontnb">수기 주문 등록 상세정보 확인</label>

        <div className="flexcenterrow mnuodeditinfomb">
          <label className="mfontn">업체명</label>
          <label className="mfontn">{props.order.cname}</label>
        </div>
        <div className="mnuodline2"></div>

        <div className="flexcenterrow mnuodeditinfomb">
          <label className="mfontn">대표자명</label>
          <label className="mfontn">{props.order.oname}</label>
        </div>

        <div className="mnuodline2"></div>

        <div className="flexcenterrow mnuodeditinfomb">
          <label className="mfontn">휴대폰</label>
          <label className="mfontn">{GetFormattedPhone2(props.order.phone)}</label>
        </div>

        <div className="mnuodline2"></div>

        <div className="flexcenterrow mnuodeditinfomb">
          <label className="mfontn">주소</label>
          <label className="mfontn">{props.order.addr}</label>
        </div>

        <div className="mnuodline2"></div>

        <div className="flexcenterrow mnuodeditinfomb">
          <label className="mfontn">영업담당자</label>
          <label className="mfontn">{props.order.sname}</label>
        </div>

        <div className="mnuodline2 marginbottom20"></div>

        {
          props.order.st !== 11 ? (
            <button className="bgcolor1 mnuoddetbtnmb btncur mfontnwb marginbottom20" onClick={() => props.handleAddItem(props.order.idx)}>상품 추가하기</button>
          ) : null
        }
        
        {/*
          props.order.st === 0  ? (
            <button className="bgcolor3 mnuoddetbtnmb btncur mfontnwb marginbottom20" onClick={() => props.handleOrderDone(props.order.idx)}>주문 처리 완료</button>
          ) : null
        */}

        {
          props.order.st !== 11  ? (
            <button className="bgcolor2 mnuoddetbtnmb btncur mfontnwb marginbottom20" onClick={() => props.handleOrderDelete(props.order.idx)}>주문 취소</button>
          ) : null
        }

        <button className="bgcolor4 mnuoddetbtnmb btncur mfontnwb" onClick={props.handleClose}>확인</button>
      </div>  
    </div>
  );
}

export default MnuOrderDetmb;