import React from 'react';

import {GetImgUrl, GetSoundUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
import { IsValidS } from '../../Util/Util';
import MainMenu from '../Menu/MainMenu';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { OrderStoreInstance } from '../../Stores/OrderStore';
import { StaffStoreInstance } from '../../Stores/StaffStore';
import PageList from '../PageList';
import { observer } from 'mobx-react';
import OrderApp from './OrderApp';
import OrderVoice from './OrderVoice';
import OrderAdd from './OrderAdd';

import '../../App.css';
import '../Emp/Staff.css';
import './Order.css';
import { NumberFormatString } from '../../Util/Util';
import DatePick from '../DatePick/DatePick';
import { ProcessLogout } from '../../Stores/StoreManager';
import Loading from '../Loading/Loading';
import MenuMb from '../Menu/MenuMb';
import OrderAppmb from './OrderAppmb';
import OrderVoicemb from './OrderVoicemb';
import OrderAppDet from './OrderAppDet';
import OrderVoiceDet from './OrderVoiceDet';
import OrderVoiceItemEdit from './OrderVoiceItemEdit';
import OrderTransfer from './OrderTransfer';

class Order extends React.Component {

  state = 
  {
    loading : false,
    dateSelect : false, //날짜 선택중인가?
    selectedDate : '',
    
    orderIdx : -1,//편집중인 주문의 고유번호
    editOrder : false, //주문 편집중인가?
    listMode : 2, //app(0), 음성(1), 전체(2)
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 1, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 1, //전체 페이지 개수
    showDetail : 0, //1 : 앱주문 상세, 2 : 음성주문상세, 3 : 음성주문 수정
    voicePlaying : false,
    voiceTime : '',
    appOn : false,

    salesIdx: -1, //관리담당인경우 현재 선택된, 영업담당자의 고유번호
    showOrderTransfer : false, //주문일자 이동 편집창 보임여부
    currentTransferOrder : -1, //이동할 주문의 고유번호
  };

  editOrderInfo = null;
  
  //선택된 날짜
  selectedYear = 0;
  selectedMonth = 0;
  selectedDay = 0;

  //오늘 처리해야 하는 주문의 날짜
  todayYear = 0;
  todayMonth = 0;
  todayDay = 0;

  intervalId = -1;

  maxPageNum = 10;
  detailInfo = null;
  detailOrderIdx = -1;
  audioData = null;
  audioPlaying = false;
  audioLength = 0; //재생중인 오디오의 길이
  audioTime = null;

  currentSalesIdx = -1;

  getDayInfo = () =>
  {
    return this.selectedYear.toString() + NumberFormatString(this.selectedMonth) + NumberFormatString(this.selectedDay);
  }

  getDayInfoInt = () =>
  {
    return parseInt(this.getDayInfo());
  }

  componentDidMount() {

    if (mobileOn)
      this.maxPageNum = 3;

    let yesterDay = this.getYesterday();

    this.setState((prevState) => ({...prevState, selectedDate : yesterDay}));

    if (LOG_ENABLE)
      console.log("Staff.componentDidMount");

    this.intervalId = setInterval(this.update, 1000);

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 4}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    //프로필없으면 읽기
    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.OnLoadProfile);
      return;
    }

    //관리 담당자인경우
    if (LoginStoreInstance.getUserLevel === 3)
    {
      //영업담당자 목록이 수신되지 않았다면
      if (this.loadSalesManagerList())
      {
        return;
      }

      if (this.loadManagedSalesList())
      {
        return;
      }

      this.initSales();
    }

    this.loadCount(this.state.listMode);
  }
  
  componentWillUnmount = () =>
  {
    this.stopAudio();

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }
  }

  loadSalesManagerList = () =>
  {
    if (!IsValidS(StaffStoreInstance.salesManagerList))
    {
      this.setLoading(true);
      StaffStoreInstance.loadSalesManager(this.onLoadSalesManager);
      return true;
    }

    return false;
  }

  loadManagedSalesList = () =>
  {
    if (!IsValidS(StaffStoreInstance.managedSalesList))
    {
      this.setLoading(true);
      StaffStoreInstance.loadManagedSalesList(LoginStoreInstance.userIdx, this.onManagedSalesLoaded);

      return true;
    }

    return false;
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : "backhandler"}));

    if (!this.state.dateSelect && !this.state.editOrder && this.state.showDetail === 0)
      this.props.history.push("/main");
    else if (this.state.dateSelect)
    {
      this.handleDate(false, '');
    }
    else if (this.state.showDetail !== 0)
    {
      this.handleCloseDetail();
    }
  }

  stopAudio = () =>
  {
    if (this.audioData != null)
    {
      this.audioData.pause();
      this.audioData = null;
      this.audioPlaying = false;
    }
  }

  updateAudioTime = () =>
  {
    if (this.audioTime === null)
      return;

    const currentTime = ((new Date()) - this.audioTime) / 1000;

    let passedTime = this.audioLength - currentTime;
    
    if (passedTime < 0)
    {
      this.setState((prevState) => ({...prevState, voiceTime : ''}));
      return;
    }

    let min = Math.floor(passedTime / 60);

    if (min > 0)
      passedTime -= min * 60;

    passedTime = Math.floor(passedTime);

    this.setState((prevState) => ({...prevState, voiceTime : `${NumberFormatString(min)}:${NumberFormatString(passedTime)}`}));
  }

  playAudio = (soundFile) =>
  {
    if (this.audioData !== null)
    {
      this.stopAudio();

      return;
    }

    this.audioData = new Audio(GetSoundUrl(soundFile));
    this.audioData.load();
    
    this.audioData.addEventListener('loadeddata', () => {
      //this.setState((prevState) => ({...prevState, time : this.getAudioTime()}));

      this.audioData.currentTime = 0;
      this.audioData.play();

      this.audioLength = this.audioData.duration; //재생중인 오디오의 길이
      this.audioTime = new Date();

      this.updateAudioTime();
    });

    this.audioData.addEventListener('playing', () => {

      if (LOG_ENABLE)
        console.log("audio playing");

      this.audioPlaying = true;
      this.setState((prevState) => ({...prevState, voicePlaying : true}));
    });

    this.audioData.addEventListener('pause', () => {

      if (LOG_ENABLE)
        console.log("audio paused");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, voicePlaying : false, voiceTime: ''}));
      this.stopAudio();
    });

    this.audioData.addEventListener('ended', () => {

      if (LOG_ENABLE)
        console.log("audio ended");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, voicePlaying : false, voiceTime: ''}));

      this.stopAudio();
    });

    this.audioData.addEventListener('error', () => {
      if (LOG_ENABLE)
        console.log("audio error");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, voicePlaying : false, voiceTime: ''}));

      this.stopAudio();
    });
  }

  setLoading = (enabled) =>
  {
    this.setState((prevState) => ({...prevState, loading : enabled}));
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }

    //음성재생중인경우
    if (this.audioPlaying)
    {
      this.updateAudioTime();
    }
  }

  calcPageInfo = (listMode) =>
  {
    let currentCount = 0;
    
    let curListMode = listMode;

    if (listMode === -1)
      curListMode = this.state.listMode;

    switch(curListMode)
    {
      case 0: //앱주문
        currentCount = OrderStoreInstance.aorder;
        break;
      case 1: //음성주문
        currentCount = OrderStoreInstance.vorder;
        break;
      case 2: //전체 주문
        currentCount = OrderStoreInstance.totalOrder;
        break;
      case 3: //미배송 주문
        break;
      default:
        break;
    }

    let pageCount = Math.floor(currentCount / 30);

    if ((currentCount % 30) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.maxPageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    if (listMode !== -1)
      this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount, listMode : listMode}));
    else
      this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  OnLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    //관리 담당자인경우
    if (LoginStoreInstance.getUserLevel === 3)
    {
      //영업담당자 목록이 수신되지 않았다면
      if (this.loadSalesManagerList())
      {
        return;
      }

      if (this.loadManagedSalesList())
      {
        return;
      }

      this.initSales();
    }
    
    this.loadCount(this.state.listMode);
  }

  onLoadSalesManager = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      if (!window.confirm(`영업담당자 목록을 읽는중 오류가 발생했습니다.\n다시 시도할까요?`))
      {
        this.setLoading(false);
        return;
      }

      this.loadSalesManagerList();
      return;
    }

    if (this.loadManagedSalesList())
    {
      return;
    }

    this.setLoading(false);

    this.initSales();
    this.loadCount(this.state.listMode);
  }

  onManagedSalesLoaded = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      if (!window.confirm(`관리하는 영업담당자 목록을 읽는중 오류가 발생했습니다.\n다시 시도할까요?`))
      {
        this.setLoading(false);
        return;
      }

      this.loadManagedSalesList();
      return;
    }

    this.initSales();
    this.loadCount(this.state.listMode);
  }

  //관리 담당자인경우, 영업담당 목록을 읽고나서 , 첫번째 영업담당자를 설정해줌
  initSales = () =>
  {
    if (!IsValidS(StaffStoreInstance.managedSalesList))
    {
      if (LOG_ENABLE)
        console.log('managed sales not found.');

      this.currentSalesIdx = -1;
      return;
    }

    const firstSales = StaffStoreInstance.getFirstManagedSalesInfo();

    if (firstSales === null)
    {
      if (LOG_ENABLE)
        console.log('managed sales not found.2');

      this.currentSalesIdx = -1;
      return;
    }

    this.setState((prevState) => ({...prevState, salesIdx: firstSales.sidx}));

    this.currentSalesIdx = firstSales.sidx;
  }

  handleSalesSelected = (salesIdx) =>
  {
    if (LOG_ENABLE)
      console.log(`handleSalesSelected : ${salesIdx}`);

    const salesInfo = StaffStoreInstance.findManagedSalesByIdx(salesIdx);

    if (salesInfo === null)
    {
      if (LOG_ENABLE)
        console.log(`sales info not found.`);

      return;
    }

    this.setState((prevState) => ({...prevState, salesIdx: salesIdx}));

    this.currentSalesIdx = salesIdx;

    this.loadCount(this.state.listMode);
  }

  //전체 주문의 개수 정보를 읽는다.
  loadCount = (loadType) =>
  {
    if (LoginStoreInstance.getUserLevel === 3)
    {
      if (this.currentSalesIdx < 1)
      {
        if(LOG_ENABLE)
        {
          console.log(`invalid sales1`);
        }

        this.setLoading(false);

        return;
      }
    }

    if (!this.state.loading)
      this.setLoading(true);

    if (LOG_ENABLE)
      console.log("day info : " + this.getDayInfo());

    OrderStoreInstance.loadOrderCount(this.getDayInfo(), this.currentSalesIdx, loadType !== 3 ? 0 : 2,  this.onLoadCountResult);
  }

  onLoadCountResult = (resultCode) =>
  {
    switch (resultCode)
    {
      case 0:
        if (LOG_ENABLE)
          console.log("onLoadCountResult");

        this.calcPageInfo(-1);
        this.loadOrderList(this.state.currentPage);
        break;
      default:
        this.setLoading(false);
        alert("주문 정보 조회중 오류가 발생했습니다.");
        break;
    }
  }

  loadOrderList = (pageNum) =>
  {
    OrderStoreInstance.clearOrderList();

    let orderType = -1;

    switch(this.state.listMode)
    {
      case 0: //앱주문인경우
        if (OrderStoreInstance.aorder < 1)
        {
          if (LOG_ENABLE)
            console.log("no app order");

          if (this.state.loading)
          {
            this.setLoading(false);
          }
          return;
        }

        orderType = 0;
        break;
      case 1: //음성 주문인경우
        if (OrderStoreInstance.vorder < 1)
        {
          if (LOG_ENABLE)
            console.log("no voice order");

          if (this.state.loading)
          {
            this.setLoading(false);
          }
          return;
        }

        orderType = 1;
        break;
      case 2: //전체 주문인경우
        if (OrderStoreInstance.totalOrder < 1)
        {
          if (LOG_ENABLE)
            console.log("no order");

          if (this.state.loading)
          {
            this.setLoading(false);
          }
          return;
        }

        orderType = -1;
        break;
      case 3: //미배송 주문인경우
        if (OrderStoreInstance.totalOrder < 1)
        {
          if (LOG_ENABLE)
            console.log("no order");

          if (this.state.loading)
          {
            this.setLoading(false);
          }
          return;
        }

        orderType = 3;
        break;
      default:
        break;
    }

    if (!this.state.loading)
      this.setLoading(true);

    OrderStoreInstance.loadOrder(this.currentSalesIdx, orderType, (pageNum - 1) * 30, this.getDayInfo(), this.onLoadOrderResult);
  }

  onLoadOrderResult = (resultCode) =>
  {
    if (this.state.loading)
      this.setLoading(false);

    if(resultCode !== 0)
    {
      alert("주문 정보 조회중 오류가 발생했습니다.");
      return;
    }
  }

  //오늘 배송할 데이터인가?
  isToDay = () =>
  {
    if (this.selectedYear !== this.todayYear)
      return false;

    if (this.selectedMonth !== this.todayMonth)
      return false;

    if (this.selectedDay !== this.todayDay)
      return false;

    return true;
  }

  //이전날짜인가?
  isPrevDay = () =>
  {
    if (this.selectedYear < this.todayYear)
      return true;

    if (this.selectedYear > this.todayYear)
      return false;

    if (this.selectedMonth < this.todayMonth)
      return true;

    if (this.selectedMonth > this.todayMonth)
      return false;

    if (this.selectedDay < this.todayDay)
      return true;

    if (this.selectedDay > this.todayDay)
      return false;

    return false;
  }

  //이후 날짜인가?
  isNextDay = () =>
  {
    if (this.selectedYear > this.todayYear)
      return true;

    if (this.selectedYear < this.todayYear)
      return false;

    if (this.selectedMonth > this.todayMonth)
      return true;

    if (this.selectedMonth < this.todayMonth)
      return false;

    if (this.selectedDay > this.todayDay)
      return true;

    if (this.selectedDay < this.todayDay)
      return false;

    return false;
  }

  //배송 마감 시간이 지났는지 확인하기
  checkOrderDoneTime = () =>
  {
    let date = new Date();

    return !LoginStoreInstance.checkOrderTimeLimit(date.getHours(), date.getMinutes());
  }

  getYesterday = () =>
  {
    let date = new Date();

    date.setDate(date.getDate() - 1);

    this.selectedYear = date.getFullYear();
    this.selectedMonth = date.getMonth() + 1;
    this.selectedDay = date.getDate();

    //처리 가능한 주문의 날짜를 기록해둠
    this.todayYear = this.selectedYear;
    this.todayMonth = this.selectedMonth;
    this.todayDay = this.selectedDay;

    return (date.getFullYear() + "-" + NumberFormatString(date.getMonth() + 1) + "-" + NumberFormatString(date.getDate()));
  }

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");  
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        break;
      case 4: //판매일보
        this.props.history.push("/report"); 
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }
  
  //앱주문, 음성주문, 전체주문
  handleCategory = (index) =>
  {
    if (LOG_ENABLE)
      console.log("handleCategory : " + index);

    if (this.state.listMode === index)
      return;

    this.calcPageInfo(index);
    this.loadCount(index);
  }

  handleAppOrder = () =>
  {
    this.handleCategory(0);
  }

  handleVoiceOrder = () =>
  {
    this.handleCategory(1);
  }

  handleAllOrder = () =>
  {
    this.handleCategory(2);
  }

  handleUndeliOrderList = () =>
  {
    this.handleCategory(3);
  }

  //하단 페이지 변경 버튼 눌릴경우
  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4
  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = this.maxPageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadOrderList(1);
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(startPage);
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(startPage);
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(endPage);
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadOrderList(pageNum);
    }
  }

  //날짜 영역을 선택하는경우
  handleDateSelect = () =>
  {
    if (LOG_ENABLE)
      console.log("handleDateSelect");

    this.setState((prevState) => ({...prevState, dateSelect : true}));
  }

  //새로운 날짜가 선택됨
  handleDate = (valid, selectedDate) =>
  {
    if (LOG_ENABLE)
      console.log("handleDate : " + selectedDate);

    //날짜 선택없이 닫은경우
    if (!valid)
    {
      this.setState((prevState) => ({...prevState, dateSelect : false}));
      return;
    }

    let newYear = selectedDate.getFullYear();
    let newMonth = selectedDate.getMonth() + 1;
    let newDay = selectedDate.getDate();

    let today = new Date(this.todayYear, this.todayMonth - 1, this.todayDay, 0, 0, 0);
    let newDate = new Date(newYear, newMonth - 1, newDay, 0, 0, 0);

    let dateDiff = (newDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);

    if (dateDiff > 1)
    {
      this.setState((prevState) => ({...prevState, dateSelect : false}));

      alert("해당 일자는 조회할 수 없습니다.");
      return;
    }

    this.selectedYear = newYear;
    this.selectedMonth = newMonth;
    this.selectedDay = newDay;

    let newDateInfo = selectedDate.getFullYear() + "-" + NumberFormatString(selectedDate.getMonth() + 1) + "-" + NumberFormatString(selectedDate.getDate());

    let nextDay = false;

    this.setState((prevState) => ({...prevState, dateSelect : false,selectedDate : newDateInfo}));

    if (nextDay)
      alert("이날짜의 주문은 아직 접수중 이므로, 추가 주문이 있을 수 있습니다.");

    OrderStoreInstance.clearOrderList();
    this.loadCount(this.state.listMode);
  }

  //판매일보조회 버튼
  handleOrderReport = () =>
  {
    if (LOG_ENABLE)
      console.log("handleOrderReport");

    /*if (OrderStoreInstance.orderInCompletedCount > 0 && LoginStoreInstance.getUserLevel === 2)
    {
      if (!window.confirm("아직 처리되지 않은 음성 주문이 있습니다. 그래도 판매 일보 메뉴로 이동할까요?"))
        return;
    }*/

    this.props.history.push("/report"); 
  }

  //주문 편집창 닫기
  handleCloseOrder = () => 
  {
    this.setState((prevState) => ({...prevState, editOrder : false}));
  }

  //상품 추가하기 버튼눌림, 영업담당자 본인이 아니면 사용못하게 하기
  handleAddItem = (orderIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleAddItem : " + orderIdx);

    this.editOrderInfo = OrderStoreInstance.findOrderByIdx(orderIdx);

    if (this.editOrderInfo == null)
    {
      alert("주문을 찾을 수 없습니다.");
      return;
    }

    /*if (LoginStoreInstance.getUserLevel !== 2 && this.editOrderInfo.sidx !== LoginStoreInstance.userIdx)
    {
      alert("이 기능은 관련 영업 담당자 본인만 이용 할 수 있습니다.");
      return;
    }*/

    //어제 부터 오늘 오전6시까지의 주문만 수정 할 수 있다.
    /*if (this.isPrevDay() || this.isNextDay())
    {
      const msg = this.isPrevDay() ? "이주문은 이미 지난 주문입니다. 그래도 진행할까요?" : "이 주문은 내일 처리할 주문입니다. 그래도 진행할까요?";

      if (!window.confirm(msg))
      {
        return;
      }
    }*/

    this.setState((prevState) => ({...prevState, editOrder : true, orderIdx : orderIdx, showDetail : 0}));
  }

  handleReorder = (orderIdx) =>
  {
    if (LOG_ENABLE)
      console.log(`handleReorder : ${orderIdx}`);

    this.editOrderInfo = OrderStoreInstance.findOrderByIdx(orderIdx);

    if (this.editOrderInfo == null)
    {
      alert("주문을 찾을 수 없습니다.");
      return;
    }

    if (!window.confirm('재주문 요청할까요? 주문자에게 알림이 가고 더이상 이주문을 편집할 수 없습니다.'))
    {
      return;
    }

    this.setState((prevState) => ({...prevState, loading : true, showDetail : 0}));

    OrderStoreInstance.requestVOrderDone(orderIdx, 6, undefined, this.onReorderResult);
  }

  onReorderResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("재주문 요청 권한이 없습니다.");
        break;
      case 3:
        alert("해당 주문을 찾을 수 없습니다.");
        break;
      case 5:
        alert("해당 주문은 이용자에 의해 취소되었습니다.");
        break;
      default:
        alert("재주문 요청에 실패 했습니다.");
        break;
    }
  }

  //상품추가 완료버튼 눌릴경우, 영업담당자 본인이 아니면 사용못하게 하기
  handleAddItemCompleted = (orderIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleAddItemCompleted : " + orderIdx);

    this.editOrderInfo = OrderStoreInstance.findOrderByIdx(orderIdx);

    if (this.editOrderInfo == null)
    {
      alert("주문을 찾을 수 없습니다.");
      return;
    }

    /*if (LoginStoreInstance.getUserLevel !== 2 && this.editOrderInfo.sidx !== LoginStoreInstance.userIdx)
    {
      alert("이 기능은 관련 영업 담당자 본인만 이용 할 수 있습니다.");
      return;
    }*/

    //어제 부터 오늘 오전6시까지의 주문만 수정 할 수 있다.
    /*if (this.isPrevDay() || this.isNextDay())
    {
      const msg = this.isPrevDay() ? "이주문은 이미 지난 주문입니다. 그래도 진행할까요?" : "이 주문은 내일 처리할 주문입니다. 그래도 진행할까요?";

      if (!window.confirm(msg))
      {
        return;
      }
    }*/

    if (!window.confirm('이주문에 상품 추가를 완료 할까요? 완료시 해당 음성 주문은 "상품 등록 완료" 상태가 됩니다.'))
    {
      return;
    }

    this.setState((prevState) => ({...prevState, loading : true, showDetail : 0}));

    OrderStoreInstance.requestVOrderDone(orderIdx, 0, undefined, this.onVOrderDoneResult);
  }
  
  onVOrderDoneResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("주문 완료 권한이 없습니다.");
        break;
      case 3:
        alert("해당 주문을 찾을 수 없습니다.");
        break;
      case 5:
        alert("해당 주문은 이용자에 의해 취소되었습니다.");
        break;
      default:
        alert("주문 완료 처리에 실패 했습니다.");
        break;
    }
  }

  //아이템 추가 창에서 담기 버튼을 누른경우
  handleAddProduct = (orderIdx, itemIdx, itemName, boxCount, bottleCount) =>
  {
    if (LOG_ENABLE)
      console.log(`handleAddProduct : ${orderIdx} , itemidx = ${itemIdx} , bcnt = ${boxCount} , ecnt = ${bottleCount}`);

    if (boxCount < 1 && bottleCount < 1)
    {
      alert("추가할 개수를 올바르게 입력해 주세요.");
      return;
    }

    let orderInfo = OrderStoreInstance.findOrderByIdx(orderIdx);

    if (orderInfo === null)
    {
      alert("해당 주문을 찾을 수 없습니다.");
      return;
    }

    /*
    if (LoginStoreInstance.getUserLevel !== 2 && orderInfo.sidx !== LoginStoreInstance.userIdx)
    {
      alert("상품 추가 권한이 없습니다.");
      return;
    }*/

    if (boxCount > 0 && bottleCount > 0)
    {
      if (!window.confirm(`${itemName} - ${boxCount}박스, ${bottleCount}병을 주문에 추가할까요?`))
        return;
    }
    else if (boxCount > 0)
    {
      if (!window.confirm(`${itemName} - ${boxCount}박스를 주문에 추가할까요?`))
        return;
    }
    else if (!window.confirm(`${itemName} - ${bottleCount}병을 주문에 추가할까요?`))
      return;

    this.setLoading(true);
    OrderStoreInstance.addProduct(orderIdx, itemIdx, boxCount, bottleCount, this.onAddItemResult);
  }

  onAddItemResult = (resultCode) =>
  {
    this.setLoading(false);

    switch (resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("상품 추가 권한이 없습니다.");
        break;
      case 3:
        alert("주문이 존재하지 않습니다.");
        break;
      case 4:
        alert("상품이 존재하지 않습니다.");
        break;
      default:
        alert("상품 추가에 실패 했습니다.");
        break;
    }
  }

  //음성 주문의 수정 버튼을 눌러서 개수 수정 완료한경우, bc : 박스개수, ec : 병개수
  handleEditItem = (orderIdx, orderItemIdx, bc, ec) =>
  {
    if (LOG_ENABLE)
      console.log(`handleEditItem : ${orderIdx}, itemidx = ${orderItemIdx}, bcnt = ${bc}, ecnt = ${ec}`);

    let boxCount = bc;
    let bottleCount = ec;

    if (isNaN(boxCount))
      boxCount = 0;

    if (isNaN(bottleCount))
      bottleCount = 0;

    if (boxCount < 1 && bottleCount < 1)
    {
      alert("상품 개수가 모두 0개일 수 없습니다. 상품을 제거하려면 삭제해주세요.");
      return;
    }

    let orderInfo = OrderStoreInstance.findOrderByIdx(orderIdx);

    if (orderInfo === null)
    {
      alert("해당 주문을 찾을 수 없습니다.");
      return;
    }

    /*if (LoginStoreInstance.getUserLevel !== 2 && orderInfo.sidx !== LoginStoreInstance.userIdx)
    {
      alert("상품 수정 권한이 없습니다.");
      return;
    }*/

    if (!window.confirm("상품 개수를 수정할까요?"))
      return;

    this.setState((prevState) => ({...prevState, loading : true, showDetail : 0}));
    OrderStoreInstance.editProduct(orderIdx, orderItemIdx, boxCount, bottleCount, this.onEditItemResult);
  }

  onEditItemResult = (resultCode) =>
  {
    this.setLoading(false);

    switch (resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("상품 수정 권한이 없습니다.");
        break;
      case 3:
        alert("주문이 존재하지 않습니다.");
        break;
      case 4:
        alert("상품이 존재하지 않습니다.");
        break;
      default:
        alert("상품 수정에 실패 했습니다.");
        break;
    }
  }
  //음성 주문 아이템의 삭제 버튼 누른경우
  handleDeleteItem = (orderIdx, orderItemIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleDeleteItem : " + orderIdx + ", itemidx = " + orderItemIdx);

    let orderInfo = OrderStoreInstance.findOrderByIdx(orderIdx);

    if (orderInfo === null)
    {
      alert("해당 주문을 찾을 수 없습니다.");
      return;
    }

    /*if (LoginStoreInstance.getUserLevel !== 2 && orderInfo.sidx !== LoginStoreInstance.userIdx)
    {
      alert("상품 삭제 권한이 없습니다.");
      return;
    }*/

    if (!window.confirm("이 상품을 삭제 할까요?"))
      return;

    this.setState((prevState) => ({...prevState, loading : true, showDetail : 0}));
    OrderStoreInstance.delProduct(orderIdx, orderItemIdx, this.onDeleteItemResult);
  }

  onDeleteItemResult = (resultCode) =>
  {
    this.setLoading(false);

    switch (resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("상품 삭제 권한이 없습니다.");
        break;
      case 3:
        alert("주문이 존재하지 않습니다.");
        break;
      case 4:
        alert("상품이 존재하지 않습니다.");
        break;
      default:
        alert("상품 삭제에 실패 했습니다.");
        break;
    }
  }

  //undeliType : 0 => 당일 주문처리, 1 => 익일주문처리
  handleUndeliOrder = (orderIdx, undeliType) =>
  {
    if (LOG_ENABLE)
      console.log(`handleUndeliOrder : ${orderIdx}, ${undeliType}`);

    let orderInfo = OrderStoreInstance.findOrderByIdx(orderIdx);

    if (orderInfo === null)
    {
      alert("해당 주문을 찾을 수 없습니다.");
      return;
    }

    this.setLoading(true);

    OrderStoreInstance.requestVOrderDone(orderIdx, undeliType === 0 ? 3 : 4, undefined, this.onUndeliOrderProcessResult);
  }

  onUndeliOrderProcessResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 6:
        alert('해당 주문은 미배송 주문이 아닙니다.');
        break;
      default:
        alert('주문 처리중 오류가 발생했습니다.');
        break;
    }
  }

  //주문 조기 마감처리
  handleOrderDone = () =>
  {
    if (LOG_ENABLE)
      console.log(`handleOrderDone`);

    if (this.checkOrderDoneTime())
    {
      alert('이미 주문 마감 시간이 지났습니다.');
      return;
    }

    if (OrderStoreInstance.orderDone)
    {
      alert('이미 주문이 조기마감되었습니다.');
      return;
    }

    if (!window.confirm('주문을 조기 마감할까요?'))
      return;

    this.setLoading(true);

    OrderStoreInstance.requestVOrderDone(undefined, 5, this.getDayInfo(), this.orderDoneResult);
  }

  //주문 조기 마감 처리 요청결과
  orderDoneResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        alert('주문이 조기 마감되었습니다.');
        break;
      default:
        alert('주문 마감 처리중 오류가 발생했습니다.');
        break;
    }
  }

  //주문 마감 버튼 출력 조건이 되는지 확인
  checkOrderBtn = () =>
  {
    //영업담담자고, 오늘처리할 주문이고, 주문이 조기마감되지 않았고, 주문 마감시간이 지나지 않았다면
    return  LoginStoreInstance.getUserLevel === 2 && this.isToDay() && !this.checkOrderDoneTime() && !OrderStoreInstance.orderDone;
  }

  handleLogout = () => {
    ProcessLogout();
    this.props.history.push("/");
  }

  //모바일 앱주문 상세
  handleDetailApp = (orderInfo) => {
    if (LOG_ENABLE)
      console.log("handleDetailApp");

    this.detailInfo = orderInfo;

    this.setState((prevState) => ({...prevState, showDetail : 1, voicePlaying : false}));
  }

  //모바일 음성주문상세
  handleDetailVoice = (orderInfo) => {
    if (LOG_ENABLE)
      console.log("handleDetailVoice");

    this.detailInfo = orderInfo;

    this.setState((prevState) => ({...prevState, showDetail : 2, voicePlaying : false}));
  }

  //모바일 음성주문 개별 아이템 수정
  handleEditVoiceItem = (orderIdx, orderInfo) => {
    
    if (LOG_ENABLE)
      console.log("handleEditVoiceItem");

    this.detailOrderIdx = orderIdx;
    this.detailInfo = orderInfo;

    this.setState((prevState) => ({...prevState, showDetail : 3, voicePlaying : false}));
  }

  handleCloseDetail = () => {
    if (LOG_ENABLE)
      console.log(`handleCloseDetail`);

    this.setState((prevState) => ({...prevState, showDetail : 0, voicePlaying : false}));

    //재생중인 사운드는 멈추기
    this.stopAudio();
  }

  handlePlayVoice = (orderInfo) => {

    if (LOG_ENABLE)
      console.log(`handlePlayVoice`);

    this.setState((prevState) => ({...prevState, voiceTime : ``}));
    this.audioTime = null;

    if (this.audioPlaying)
    {
      this.stopAudio();
      return;
    }

    this.playAudio(orderInfo.sfile);
  }

  handleMoveOrder = (orderIdx) => {
    if (LOG_ENABLE)
      console.log(`handleMoveOrder : ${orderIdx}`);

    if (this.audioPlaying)
    {
      this.stopAudio();
      return;
    }

    this.setState((prevState) => ({...prevState, showOrderTransfer : true, currentTransferOrder : orderIdx, showDetail : 0}));
  }
  
  //실제 주문을 특정일자로 이동하는 요청
  //moved : false : 그냥 창닫기, true : 이동하기
  //moveType : 1 : 기본배송, 2 : 추가배송
  handleOrderTransfer = (moved, orderIdx, transDate, moveType) =>
  {
    if (!moved)
    {
      this.setState((prevState) => ({...prevState, showOrderTransfer : false}));
      return;
    }

    this.setLoading(true);

    OrderStoreInstance.requestVOrderDone(orderIdx, moveType === 1 ? 7 : 8, transDate.toString(), this.orderTransferResult);
  }

  orderTransferResult = (resultCode) =>
  {
    switch(resultCode)
    {
      case 0:
        this.setState((prevState) => ({...prevState, showOrderTransfer : false, loading : false}));
        alert(`주문일자가 변경되었습니다.`);
        break;
      case 1:
        this.setState((prevState) => ({...prevState, showOrderTransfer : false, loading : false}));
        alert(`잘못된 요청입니다.`);
        break;
      default:
        this.setState((prevState) => ({...prevState, loading : false}));
        alert(`주문일지 변경에 실패 했습니다.`);
        break;
    }
  }

  render() {
    const orderList = OrderStoreInstance.getOrderList;

    let orders = null;

    if (orderList !== null && orderList.length > 0)
    {
      if (mobileOn)
      {
        orders = orderList.map(item => {

          if (item.otype === 0)
            return (<OrderAppmb key={item.idx} order = {item} handleUndeliOrder = {this.handleUndeliOrder} handleDetail = {this.handleDetailApp} 
              addItem = {this.handleAddItem}
              handleEditItem = {this.handleEditItem} handleDeleteItem = {this.handleDeleteItem}
              handleEditVoItem = {this.handleEditVoiceItem}
              handleMoveOrder = {this.handleMoveOrder}
              />);
          else if (item.otype === 1)
            return (<OrderVoicemb key={item.idx} order = {item} addItem = {this.handleAddItem} addItemCompleted = {this.handleAddItemCompleted} 
              handleEditItem = {this.handleEditItem} handleDeleteItem = {this.handleDeleteItem}  handleUndeliOrder = {this.handleUndeliOrder}
              handleDetail = {this.handleDetailVoice}
              handleEditVoItem = {this.handleEditVoiceItem}
              handleMoveOrder = {this.handleMoveOrder}
              />);
          else
              return null;
        });
      }
      else
      {
        orders = orderList.map(item => {

          if (item.otype === 0)
            return (<OrderApp key={item.idx} order = {item} handleUndeliOrder = {this.handleUndeliOrder} addItem = {this.handleAddItem}
              handleEditItem = {this.handleEditItem} handleDeleteItem = {this.handleDeleteItem} handleMoveOrder = {this.handleMoveOrder}
            />);
          else if (item.otype === 1)
            return (<OrderVoice key={item.idx} order = {item} addItem = {this.handleAddItem} addItemCompleted = {this.handleAddItemCompleted} 
              handleEditItem = {this.handleEditItem} handleDeleteItem = {this.handleDeleteItem}  handleUndeliOrder = {this.handleUndeliOrder}
              handleReorder = {this.handleReorder} handleMoveOrder = {this.handleMoveOrder}
              />);
          else
              return null;
        });
      }
    }

    let salesList = null;

    if (LoginStoreInstance.getUserLevel === 3)
    {
      if (IsValidS(StaffStoreInstance.managedSalesList))
      {
        salesList = StaffStoreInstance.managedSalesList.map(item => {return (<option key = {item.sidx} value={item.sidx}>{item.uname}</option>)});
      }
      else
      {
        salesList = [];
        salesList.push((<option key = {-1} value={"-1"}>관리하는 영업담당자가 없습니다.</option>));
      }
    }

    let subMsg = "";

    if (this.isPrevDay())
      subMsg = "지난 일자의 주문입니다.";
    else if (this.isNextDay())
      subMsg = "내일 배송할 주문입니다.";
    else
    {
      if (this.checkOrderDoneTime() || OrderStoreInstance.orderDone)
      {
        subMsg = " - (오늘 배송 할 주문입니다. 주문 마감됨)";
      }
      else
        subMsg = ` - (오늘 배송 할 주문입니다. 주문 마감전)`;
    }

    if (mobileOn)
    {
      return (
        <div className="appcontainer">
          {this.state.dateSelect ? <DatePick handleDate = {this.handleDate} cyear={this.selectedYear} cmonth={this.selectedMonth} cday={this.selectedDay}/> : null}
          
          {this.state.editOrder ? <OrderAdd orderIdx = {this.state.orderIdx} order = {this.editOrderInfo} handleClose={this.handleCloseOrder} handleAddProduct={this.handleAddProduct} showLoading={this.setLoading}/> : null}
  
          {
            this.state.loading ? <Loading />:null
          }
  
          {
            this.state.showDetail === 1 ? (
              <OrderAppDet order = {this.detailInfo} handleCloseDetail = {this.handleCloseDetail} handleAddItem = {this.handleAddItem} handleMoveOrder = {this.handleMoveOrder}/>
            ) : null  
          }

          {
            this.state.showDetail === 2 ? (
              <OrderVoiceDet 
                order = {this.detailInfo}
                playing = {this.state.voicePlaying}
                playTime = {this.state.voiceTime}
                handleCloseDetail = {this.handleCloseDetail}
                playVoice = {this.handlePlayVoice}
                handleAddItem = {this.handleAddItem}
                handleAddItemCompleted = {this.handleAddItemCompleted}
                handleReorder = {this.handleReorder}
                handleMoveOrder = {this.handleMoveOrder}
              />
            ) : null  
          }

          {
            this.state.showDetail === 3 ? (
              <OrderVoiceItemEdit item = {this.detailInfo}
                orderIdx = {this.detailOrderIdx}
                handleCloseDetail = {this.handleCloseDetail} 
                handleEditItem = {this.handleEditItem}
                handleDeleteItem = {this.handleDeleteItem}
              />
            ) : null  
          }

          {
            this.state.showOrderTransfer ? (
              <OrderTransfer orderIdx ={this.state.currentTransferOrder} rtime={this.getDayInfoInt()} handleOrderTransfer = {this.handleOrderTransfer} />
            ) : null
          }

          <div className="maincontainermb">
            <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout = {this.handleLogout}/>            
            
            
            <div className="empserconmb">
              <div className="ordersertitle flexcenterrowstart">
                
                <div className="orderserl flexcenterrowstart">
                  <img src={GetImgUrl("mbodorderchk.png")} alt="주문관리" />
                  <label className="mfontnb">&nbsp;{`주문관리(마감 ${LoginStoreInstance.getOrderDoneTime()})`}</label>
                </div>
  
                <div className="orderserr flexcenterrowend">
                  {
                    this.checkOrderBtn() ?
                      (
                        <button className="bgcolor3 orderrepbtnmb mfontnwb" onClick={this.handleOrderDone}>
                          주문 마감
                        </button>      
                      ) : null
                  }
                </div>
                
              </div>
  
              <div className="empsercontentmb flexcenterrowstart">
                <label className="mfontnwb">&nbsp;날짜별 주문 내역 조회</label>
  
                <div className="orderdatemb flexcenterrow" onClick={this.handleDateSelect}>
                  <label className="btncur mfontnb">{this.state.selectedDate}</label>
                </div>
  
                <button className="orderdateselmb mfontnb" onClick={this.handleDateSelect}>
                  조회
                </button>
              </div>
            </div>
  
            <div className="odcatselbgmb">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("mbodpapers.png")} alt="주문현황" />
                <label className="mfontnb">&nbsp;주문현황</label>
              </div>
  
              <div className="odcatselcontmb flexcentercol">
                
                <div className="oinfolmb flexcenterrow odcatbtnareamb">

                  <div className="cnthalfh flexcenterrowstart">
                    <img src={GetImgUrl("mbodcatph.png")} alt="App주문" className="empimgmb"/>
                    <label className="mfontnwb">App 주문&nbsp;&nbsp;</label>
                    <div className="odcntmb flexcenterrow">
                      <label className="mfontnb">{OrderStoreInstance.aorder + "건"}</label>
                    </div>
                  </div>

                  <div className="cnthalfh flexcenterrowend">
                    <img src={GetImgUrl("mbodcatmic.png")} alt="음성주문" className="empimgmb"/>
                    <label className="mfontnwb">음성 주문&nbsp;&nbsp;</label>
                    <div className="odcntmb flexcenterrow">
                      <label className="mfontnb">{OrderStoreInstance.vorder + "건"}</label>
                    </div>  
                  </div>
                </div>
                
                <div className="oinfolmb2 flexcenterrow odcatbtnareamb">
                  <button className="ordercatbtnmb odundelibtnfontmb" onClick={this.handleUndeliOrderList}>
                    미배송 관리
                  </button>
  
                  <button className="ordercatbtnmb mfontnb" onClick={this.handleAppOrder}>
                    APP 주문 조회
                  </button>
                </div>

                <div className="oinfolmb2 flexcenterrow odcatbtnareamb">
                  <button className="ordercatbtnmb mfontnb" onClick={this.handleVoiceOrder}>
                    음성 주문 조회
                  </button>
  
                  <button className="ordercatbtnmb mfontnb" onClick={this.handleAllOrder}>
                    전체 조회
                  </button>
                </div>
              </div>
            </div>
  
            <div className="odserconmb">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("mbodpapers.png")} alt="주문정보 조회" />
                <label className= {this.state.listMode === 3 ? "odundelititlefontmb" : "mfontnb"}>&nbsp;{this.state.listMode === 3 ? "미배송 주문 조회" : "주문정보 조회"}</label>
                
              </div>
              
              <div className="empline"/>
  
              <div className="odserconmb2 flexcenterrowstart">
                <img src={GetImgUrl("mbodday.png")} alt="조회 일자" />
                <label className="mfontnb">&nbsp;{this.state.selectedDate}</label>
                <label className="odsubmsgmb">&nbsp;{subMsg}</label>
              </div>
  
              <div className="empline"/>
  
              
              {
                LoginStoreInstance.getUserLevel === 3 ? (
                  <div className="odserconmb2 flexcentercol">
                    <select className="odsalesselmb mfontn"
                      value={this.state.salesIdx.toString()}
                      onChange={(e) => {
                        if (LOG_ENABLE)
                          console.log(e.target.value);
      
                        this.handleSalesSelected(parseInt(e.target.value));
                      }}
                    >
                      {salesList}
                    </select>
                    <div className="empline"/>    
                  </div>
                ) : null
              }

            </div>
  
            {orders != null && orders.length > 0 ? orders : (this.state.listMode === 3 ? (<label className="mfontnb">해당 일자의 미배송 주문이 없습니다.</label>) : (<label className="mfontnb">해당 일자의 주문이 없습니다.</label>))}
  
            {
              this.state.pageCount > 1 ? 
                <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
    else
    {
      return (
        <div className="appcontainer">
          {this.state.dateSelect ? <DatePick handleDate = {this.handleDate} cyear={this.selectedYear} cmonth={this.selectedMonth} cday={this.selectedDay}/> : null}
          
          {this.state.editOrder ? <OrderAdd orderIdx = {this.state.orderIdx} order = {this.editOrderInfo} handleClose={this.handleCloseOrder} handleAddProduct={this.handleAddProduct} showLoading={this.setLoading}/> : null}
  
          {
            this.state.loading ? <Loading />:null
          }
  
          {
            this.state.showOrderTransfer ? (
              <OrderTransfer orderIdx ={this.state.currentTransferOrder} rtime={this.getDayInfoInt()} handleOrderTransfer = {this.handleOrderTransfer} />
            ) : null
          }

          <div className="maincontainer">
            <div className="empmargin"/>
            <MainMenu index={3} handleMenuClick = {this.handleMenuClick}/>
            
            <div className="empsercon">
              <div className="ordersertitle flexcenterrowstart">
                
                <div className="orderserl flexcenterrowstart">
                  <img src={GetImgUrl("papers.png")} alt="주문관리" />
                  <label className="fontlargeb">&nbsp;{`주문관리(마감시간 ${LoginStoreInstance.getOrderDoneTime()})`}</label>
                </div>
  
                <div className="orderserr flexcenterrowend">
                  {
                    this.checkOrderBtn() ?
                      (
                        <button className="bgcolor3 orderrepbtn fontnwb" onClick={this.handleOrderDone}>
                          주문 마감
                        </button>      
                      ) : null
                  }
  
                  <button className="bgcolor1 orderrepbtn fontnwb" onClick={this.handleOrderReport}>
                    판매일보조회
                  </button>  
                </div>
                
              </div>
  
              <div className="empsercontent flexcenterrowstart">
                <img src={GetImgUrl("search.png")} alt="날짜별주문관리" className="empimg"/>
                <label className="fontnwb">&nbsp;날짜별 주문 내역 조회</label>
  
                <div className="orderdate flexcenterrow" onClick={this.handleDateSelect}>
                  <label className="btncur fontnb">{this.state.selectedDate}</label>
                </div>
  
                <button className="orderdatesel fontnb" onClick={this.handleDateSelect}>
                  조회
                </button>
              </div>
            </div>
  
            <div className="empsercon">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("papersl.png")} alt="주문현황" />
                <label className="fontlargeb">&nbsp;주문현황</label>
              </div>
  
              <div className="empsercontent flexcenterrowstart">
                
                <div className="oinfol flexcenterrowstart">
                  <img src={GetImgUrl("phones.png")} alt="App주문" className="empimg"/>
                  <label className="fontnwb">App 주문</label>
                  <div className="empcnt flexcenterrow">
                    <label className="fontnb">{OrderStoreInstance.aorder + "건"}</label>
                  </div>
  
                  <img src={GetImgUrl("voices.png")} alt="음성주문" className="empimg"/>
                  <label className="fontnwb">음성 주문</label>
                  <div className="empcnt flexcenterrow">
                    <label className="fontnb">{OrderStoreInstance.vorder + "건"}</label>
                  </div>
                </div>
                
                <div className="oinfor flexcenterrowend">
                  <button className="ordercatbtn odundelibtnfont" onClick={this.handleUndeliOrderList}>
                    미배송 관리
                  </button>
  
                  <button className="ordercatbtn fontnb" onClick={this.handleAppOrder}>
                    APP 주문 조회
                  </button>
  
                  <button className="ordercatbtn fontnb" onClick={this.handleVoiceOrder}>
                    음성 주문 조회
                  </button>
  
                  <button className="ordercatbtn fontnb" onClick={this.handleAllOrder}>
                    전체 조회
                  </button>
                </div>
  
                
              </div>
            </div>
  
            <div className="empsercon">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("papersl.png")} alt="주문정보 조회" />
                <label className= {this.state.listMode === 3 ? "odundelititlefont" : "fontlargeb"}>&nbsp;{this.state.listMode === 3 ? "미배송 주문 조회" : "주문정보 조회"}</label>
                
              </div>
              
              <div className="empline"/>
  
              <div className="flexcenterrowstart oddatecont">
                <div className='oddatearea flexcenterrow'>
                  <img src={GetImgUrl("day.png")} alt="조회 일자" />
                  <label className="fontlargeb">&nbsp;{this.state.selectedDate}</label>
                  <label className="rptsubmsg">&nbsp;{subMsg}</label>
                </div>
                
                {
                  LoginStoreInstance.getUserLevel === 3 ? (
                    <div className='odsalesselarea flexcenterrow'>
                      <select className="odsalessel fontn"
                        value={this.state.salesIdx.toString()}
                        onChange={(e) => {
                          if (LOG_ENABLE)
                            console.log(e.target.value);
        
                          this.handleSalesSelected(parseInt(e.target.value));
                        }}
                      >
                        {salesList}
                      </select>
                    </div>
                  ) : null
                }
                
              </div>
  
              <div className="empline"/>
  
            </div>
  
            {orders != null && orders.length > 0 ? orders : (this.state.listMode === 3 ? "해당 일자의 미배송 주문이 없습니다." : "해당 일자의 주문이 없습니다.")}
  
            {
              this.state.pageCount > 1 ? 
                <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
  }
}

export default observer(Order);