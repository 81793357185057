import React from 'react';
import { IsValidS } from '../../Util/Util';

function GetShortName(name)
{
  if (!IsValidS(name))
    return "";

  if (name.length < 12)
    return name;
  
  return name.substring(0, 12) + "...";
}

//모바일용
const RetInfomb = (props) => {
  return (
    <tr className="emptabledata">
      <td className="emptablecell">
        <input type="radio" onChange={(e) => props.onCheckChanged(props.index)} className="chk" checked={props.selectIdx === props.index}/>
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellinmb flexcenterrow">
          <label className={props.appinst ? "mfontn" : "mfontnr"}>{GetShortName(props.cname)}</label>
        </div>
        
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellinmb flexcenterrow">
          <button className="retdetbtnmb mfontnwb btncur" onClick={()=>{
            if (props.handleDetail)
              props.handleDetail(props.index, props.detType);
          }}>
            상세정보
          </button>  
        </div>
      </td>
    </tr>
  );
}

export default RetInfomb;