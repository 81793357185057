import { IsValidS } from "../../Util/Util";
import MnuOrderItemInfoedb from "./MnuOrderItemInfoedb";

//편집 버튼이 있는
const MnuOrderItemsedb = (props) =>
{
  let items = null;

  if (IsValidS(props.order.items))
  {
    items = props.order.items.map(item => {
      return (
        <MnuOrderItemInfoedb key={item.idx} item = {item} handleEdit = {props.handleEdit} handleDelete={props.handleDelete} orderIdx={props.orderIdx}/>
      );
    });
  }

  return(
    <table className="mnuoditemtable">
      <thead>
        <tr>
          <th className="emptableheader">
            <label className="fontnw">상품번호</label>
          </th>

          <th className="emptableheader">
            <label className="fontnw">상품명</label>
          </th>

          <th className="emptableheader">
            <label className="fontnw">용량</label>
          </th>

          <th className="emptableheader">
            <label className="fontnw">주문갯수</label>
          </th>

          <th className="emptableheader">
            <label className="fontnw">수정</label>
          </th>

          <th className="emptableheader">
            <label className="fontnw">삭제</label>
          </th>
        </tr>
      </thead>
      <tbody>
        {items}
      </tbody>
    </table>
  );
}

export default MnuOrderItemsedb;