import React from 'react';

import '../../App.css';
import './Stats.css';

import MainMenu from '../Menu/MainMenu';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { StatsStoreInstance } from '../../Stores/StatsStore';
import {GetImgUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
import { observer } from 'mobx-react';
import Loading from '../Loading/Loading';
import { ProcessLogout } from '../../Stores/StoreManager';
import { IsValidS } from '../../Util/Util';
import StatsItemmb from './StatsItemmb';
import StatsItem from './StatsItem';
import MenuMb from '../Menu/MenuMb';
import PageList from '../PageList';
import NoAppUserList from './NoAppUserList';
import { RetailerStoreInstance } from '../../Stores/RetailerStore';

class AppStats extends React.Component {
  state = 
  {
    loading : false,
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 0, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 0, //전체 페이지 개수
    appOn : false,
    noapplist : false,
  };

  maxPageNum = 10;
  intervalId = -1;

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("AppStats.componentDidMount");

    if (mobileOn)
      this.maxPageNum = 3;

    this.intervalId = setInterval(this.update, 1000);

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 11}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);
      return;
    }

    this.loadStats(1);
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }

    RetailerStoreInstance.clearNoAppListTotal();
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : JSON.stringify(this.state)}));

    this.props.history.push("/main");
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }

  setLoading = (enabled) =>
  {
    this.setState((prevState) => ({...prevState, loading : enabled}));
  }

  calcPageInfo = () =>
  {
    let currentCount = StatsStoreInstance.totalStatsCount;

    let pageCount = Math.floor(currentCount / 20);

    if ((currentCount % 20) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.maxPageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  onLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    this.loadStats(1);
  }

  loadStats = (pageNumber) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    StatsStoreInstance.loadStats((pageNumber - 1) * 20, this.onLoadStats);
  }

  onLoadStats = (resultCode, totalCount) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('통계 읽기중 오류가 발생햇습니다.');
      return;
    }

    if (totalCount !== -1)
      this.calcPageInfo();
  }

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case -1:
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");
        break;
      case 2: //거래처관리
        this.props.history.push("/reta"); 
        break;
      case 3: //주문관리
        this.props.history.push("/order"); 
        break;
      case 4: //판매일보
        this.props.history.push("/report");
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }

  handleLogout = () => {
    ProcessLogout();
    this.props.history.push("/");
  }

  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = this.maxPageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadStats(1);
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadStats(startPage);
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadStats(startPage);
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadStats(endPage);
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadStats(pageNum);
    }
  }

  handleNoAppListClose = () =>
  {
    this.setState((prevState) => ({...prevState, noapplist : false}));
  }

  handleNoAppListOpen = () =>
  {
    this.setState((prevState) => ({...prevState, noapplist : true}));
  }
  
  render() {
    const list = StatsStoreInstance.statsList;
    let stats = null;

    if (mobileOn)
    {
      if (IsValidS(list))
      {
        stats = list.map(item => {
          return (<StatsItemmb key={item.idx} item = {item}  userType={LoginStoreInstance.userType}/>);
        });
      }

      return(
        <div className="appcontainer">
          {
            this.state.loading ? <Loading />:null
          }
          
          {
            this.state.noapplist ? <NoAppUserList handleClose={this.handleNoAppListClose} setLoading = {this.setLoading} history = {this.props.history} appOn={this.state.appOn}/> : null
          }
          <div className="maincontainermb">
            <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout = {this.handleLogout}/>

            <div className="statstitlemb flexcenterrowstart">
              <img src={GetImgUrl("graphmu.png")} alt="통계"/>
              <label className="mfontlargeb">&nbsp;앱 이용자 통계</label>
            </div>

            <div className="statsline">
            </div>

            <div className="statsinfo">
              <div className="cntfullh flexcenterrowstart">
                <img src={GetImgUrl("statspp.png")} alt="앱 설치정보"/>
                <label className="mfontlarge">&nbsp;앱 설치 정보</label>
              </div>
              
              <div className="flexcentercol statsinfo2mb">

                <div className="flexcenterrow cntfullh statsinfoxmb">
                  <div className="flexcenterrow marginleft10">
                    <img src={GetImgUrl("statsshop.png")} alt="shop"/>
                    <label className="mfontnw">&nbsp;거래처수</label>

                    <div className="statsinfoboxmb flexcenterrow">
                      <label className="mfontnb">{StatsStoreInstance.retCount}</label>
                    </div>
                  </div>

                  <div className="flexcenterrow">
                    <img src={GetImgUrl("statsphone.png")} alt="shop"/>
                    <label className="mfontnw">&nbsp;앱설치수</label>

                    <div className="statsinfoboxmb flexcenterrow marginright10">
                      <label className="mfontnb">{StatsStoreInstance.retAppCount}</label>
                    </div>
                  </div>
                </div>
                
                <div className="flexcenterrow cntfullh statsinfoxmb margintop10">
                  <div className="flexcenterrow marginleft10">
                    <img src={GetImgUrl("ssgraph.png")} alt="shop"/>
                    <label className="mfontnw">&nbsp;앱 설치 비율</label>

                    <div className="statsinfoboxmb flexcenterrow">
                      <label className="mfontnb">{StatsStoreInstance.getAppInstallPer}</label>
                    </div>
                  </div>

                  <button className="statsbtnmb mfontnb btncur marginright10" onClick = {() => this.handleNoAppListOpen()}>미설치 업체</button>
                </div>
                
              </div>
            </div>

            <div className="statsinfo3mb flexcenterrowstart">
              <img src={GetImgUrl("statspp.png")} alt="통계"/>
              <label className="mfontlarge">&nbsp;일별 통계</label>
            </div>

            {
              LoginStoreInstance.userType === 0 ? (
                <div className = "tablecont">
                  <table className="statstable">
                    <thead>
                      <tr>
                        <th className="emptableheader">
                          <label className="fontnw">일자</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">신규(등록)</label>
                        </th>

                        <th className="emptableheader">
                          <label className="fontnw">신규(일반)</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">앱 주문</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">음성 주문</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">기타 주문</label>
                        </th>

                        <th className="emptableheader">
                          <label className="fontnw">수기 주문</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">주문자 수</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">총 주문 건 수</label>
                        </th>

                        <th className="emptableheader">
                          <label className="fontnw">앱 이용자</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {stats}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className = "tablecont">
                  <table className="statstable">
                    <thead>
                      <tr>
                        <th className="emptableheader">
                          <label className="mfontnw">일자</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="mfontnw">신규</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="mfontnw">주문(앱,음성,기타,수기)</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="mfontnw">주문자수</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="mfontnw">총주문수</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {stats}
                    </tbody>
                  </table>
                </div>
              )
            }
            
            {
              this.state.pageCount > 1 ? <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
            
          </div>
        </div>
      );
    }
    else
    {
      if (IsValidS(list))
      {
        stats = list.map(item => {
          return (<StatsItem key={item.idx} item = {item} userType={LoginStoreInstance.userType}/>);
        });
      }

      return(
        <div className="appcontainer">
          {
            this.state.loading ? <Loading />:null
          }
          
          {
            this.state.noapplist ? <NoAppUserList handleClose={this.handleNoAppListClose} setLoading = {this.setLoading} history = {this.props.history} appOn={this.state.appOn}/> : null
          }
          <div className="maincontainer">
            <div className="empmargin"/>
            <MainMenu index={11} handleMenuClick = {this.handleMenuClick}/>

            <div className="statstitle flexcenterrowstart">
              <img src={GetImgUrl("graphmu.png")} alt="통계"/>
              <label className="fontlargeb">&nbsp;&nbsp;앱 이용자 통계</label>
            </div>

            <div className="statsline">
            </div>

            <div className="statsinfo">
              <div className="cntfullh flexcenterrowstart statsml20">
                <img src={GetImgUrl("statspp.png")} alt="앱 설치정보"/>
                <label className="fontlarge">&nbsp;&nbsp;앱 설치 정보</label>
              </div>
              
              <div className="flexcenterrow statsinfo2">
                <div className="flexcenterrow">
                  <img src={GetImgUrl("statsshop.png")} alt="shop"/>
                  <label className="fontnw">&nbsp;&nbsp;전체 거래처 수</label>

                  <div className="statsinfobox flexcenterrow">
                    <label className="fontnb">{StatsStoreInstance.retCount}</label>
                  </div>
                </div>

                <div className="flexcenterrow">
                  <img src={GetImgUrl("statsphone.png")} alt="shop"/>
                  <label className="fontnw">&nbsp;&nbsp;앱 설치 수</label>

                  <div className="statsinfobox flexcenterrow">
                    <label className="fontnb">{StatsStoreInstance.retAppCount}</label>
                  </div>
                </div>

                <div className="flexcenterrow">
                  <img src={GetImgUrl("ssgraph.png")} alt="shop"/>
                  <label className="fontnw">&nbsp;&nbsp;앱 설치 비율</label>

                  <div className="statsinfobox flexcenterrow">
                    <label className="fontnb">{StatsStoreInstance.getAppInstallPer}</label>
                  </div>
                </div>

                <button className="statsbtn fontnb btncur" onClick = {() => this.handleNoAppListOpen()}>미설치 업체 리스트</button>
              </div>
            </div>

            <div className="statsinfo3 flexcenterrowstart">
              <img src={GetImgUrl("statspp.png")} alt="통계"/>
              <label className="fontlarge">&nbsp;&nbsp;일별 통계</label>
            </div>

            {
              LoginStoreInstance.userType === 0 ? (
                <div className = "tablecont">
                  <table className="statstable">
                    <thead>
                      <tr>
                        <th className="emptableheader">
                          <label className="fontnw">일자</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">신규(등록)</label>
                        </th>

                        <th className="emptableheader">
                          <label className="fontnw">신규(일반)</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">앱 주문</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">음성 주문</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">기타 주문</label>
                        </th>

                        <th className="emptableheader">
                          <label className="fontnw">수기 주문</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">주문자 수</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">총 주문 건 수</label>
                        </th>

                        <th className="emptableheader">
                          <label className="fontnw">앱 이용자</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {stats}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className = "tablecont">
                  <table className="statstable">
                    <thead>
                      <tr>
                        <th className="emptableheader">
                          <label className="fontnw">일자</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">신규 가입</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">앱 주문</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">음성 주문</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">기타 주문</label>
                        </th>

                        <th className="emptableheader">
                          <label className="fontnw">수기 주문</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">주문자 수</label>
                        </th>
      
                        <th className="emptableheader">
                          <label className="fontnw">총 주문 건 수</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {stats}
                    </tbody>
                  </table>
                </div>
              )
            }
            
            {
              this.state.pageCount > 1 ? <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
            
          </div>
        </div>
      );
    }
  }
}

export default observer(AppStats);