import { observer } from "mobx-react";
import { LOG_ENABLE } from "../../URL/AppUrl";
import { IsValidS } from "../../Util/Util";
import "../Emp/Staff.css";
import "./Product.css";
import ProductItem from "./ProductItem";

const ProductList = observer((props) =>
{
  let itemList = null;
  let itemCategory = null;

  if (props.itemList !== null && props.itemList.length > 0)
  {
    itemList = props.itemList.map(item => {
      return (<ProductItem key={item.idx} item = {item} handleSelect={props.handleSelect} handleSelectAndEdit={props.handleSelectAndEdit} selectedIdx={props.selectedIdx} />);
    });
  }

  if (IsValidS(props.itemCategory))
  {
    itemCategory = props.itemCategory.map(item => {
      return <option value={item.iid.toString()}>{item.cname}</option>
    });

    itemCategory.unshift((<option value="-1">전체</option>));
  }

  return (
    
    <div className = "pdlistarea flexcentercolstart">
      
      {/*리스트영역 편집버튼*/}
      <div className = "flexcenterrowstart pdedit">
        <div className="flexcenterrow">
          <input type="radio" checked = {false} className="pdcheck" onChange={() => console.log("checked")}/>
          <label className="fontnb">&nbsp;&nbsp;선택한 정보를&nbsp;&nbsp;</label>

          <button className="bgcolor1 pdeditbtn fontnwb" onClick={() => props.handleEdit()}>
            수정
          </button>

          <button className="pdexcelbtn fontnwb" onClick={() => props.handleWriteExcel()}>
            상품 목록 엑셀출력
          </button>

          <button className="pdaddnewbtn fontnwb bgcolor3" onClick={() => props.handleAddComPd()}>
            상품 추가
          </button>
          
        </div>
        
        <div className="flexcenterrow">
          <select className="pditemcat fontnb"
            onChange={(e) => {
              if (LOG_ENABLE)
                console.log(e.target.value);

                props.handleProductCategory(parseInt(e.target.value));
            }}
          >
            {itemCategory}
          </select>
        </div>
      </div>

      {/*리스트영역 아이템 목록*/}
      <div className="pdlist flexcentercolstart">
        <table className="pdtable">
          <thead>
            <tr className="pdtablehead">
              <th className="pdtablea">
                <input type="radio" checked = {false} className="pdcheck"  onChange={() => console.log("checked")}/>
              </th>

              <th className="pdtableb">
                <label className="fontnw">판매분류</label>
              </th>

              <th className="pdtablec">
                <label className="fontnw">품목코드</label>
              </th>

              <th className="pdtabled">
                <label className="fontnw">품목명</label>
              </th>

              <th className="pdtablek">
                <label className="fontnw">이미지</label>
              </th>
              
              <th className="pdtablee">
                <label className="fontnw">{"규격(용량)"}</label>
              </th>

              <th className="pdtablef">
                <label className="fontnw">단위</label>
              </th>

              <th className="pdtableg">
                <label className="fontnw">용도</label>
              </th>

              <th className="pdtableh">
                <label className="fontnw">도수</label>
              </th>

              <th className="pdtablei">
                <label className="fontnw">입수</label>
              </th>

              <th className="pdtablej">
                <label className="fontnw">판매가</label>
              </th>
            </tr>
          </thead>
          <tbody>
            {itemList}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default ProductList;