
import React from 'react';
import './Main.css';
import '../../App.css';

import {GetImgUrl, LOG_ENABLE} from '../../URL/AppUrl';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { observer } from 'mobx-react';
import Loading from '../Loading/Loading';

//도매상 등록
class AddWholeSales extends React.Component {
  
  state = {
    loading : false,
    cname : '',
    oname : '',
    phone: '',
    cnum: '',
    cid: '',
    cpw: '',
  };

  setLoading = (loading) =>
  {
    this.setState((prevState) => ({...prevState, loading : loading}));
  }

  setCompanyName = (v) =>{
    this.setState((prevState) => ({...prevState, cname : v}));
  }

  setUserName = (v) =>{
    this.setState((prevState) => ({...prevState, oname : v}));
  }

  setPhone = (v) =>{
    this.setState((prevState) => ({...prevState, phone : v}));
  }

  setCNum = (v) =>{
    this.setState((prevState) => ({...prevState, cnum : v}));
  }

  setId = (v) =>{
    this.setState((prevState) => ({...prevState, cid : v}));
  }

  setPw = (v) =>{
    this.setState((prevState) => ({...prevState, cpw : v}));
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("AddWholeSales.componentDidMount");


    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);
    }
  }

  componentWillUnmount = () =>
  {
    if (LOG_ENABLE)
      console.log("AddWholeSales.componentWillUnmount");
  }

  onLoadProfile = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      this.props.ProcessLogout();
      return;
    }
  }

  render()
  {
    return (
      <div className="flexcentercol mainaddwscont">
        {
          this.state.loading ? (
            <Loading />
          ) : null
        }

        <div className="flexcentercol mainaddwspop">
          <div className="cntfullh marginbottom15 flexcenterrow">
            <div className="dcell1"></div>

            <div className="dcell8 flexcenterrow">
              <label className="fontnb">도매상 메인계정 등록</label>    
            </div>

            <div className="dcell1">
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className="btncur" onClick={() => this.props.handleCloseAWS()}/>
            </div>
          </div>


          <div className="staffaddinpcont flexcenterrow">
            <div className="staffaddinpleft flexcenterrowstart">
              <label className="fontlargeb">상호</label>
            </div>
            
            <div className="staffaddinpright flexcenterrowend">
              <input type={"text"} onChange={(e) => this.setCompanyName(e.target.value)} value = {this.state.cname} className="mainadwsinp fontn"/>
            </div>
          </div>

          <div className="staffaddinpcont flexcenterrow">
            <div className="staffaddinpleft flexcenterrowstart">
              <label className="fontlargeb">대표자</label>
            </div>
            
            <div className="staffaddinpright flexcenterrowend">
              <input type={"text"} onChange={(e) => this.setUserName(e.target.value)} value = {this.state.oname} className="mainadwsinp fontn"/>
            </div>
          </div>

          <div className="staffaddinpcont flexcenterrow">
            <div className="staffaddinpleft flexcenterrowstart">
              <label className="fontlargeb">휴대폰</label>
            </div>
            
            <div className="staffaddinpright flexcenterrowend">
              <input type={"text"} onChange={(e) => this.setPhone(e.target.value)} value = {this.state.phone} className="mainadwsinp fontn"/>
            </div>
          </div>

          <div className="staffaddinpcont flexcenterrow">
            <div className="staffaddinpleft flexcenterrowstart">
              <label className="fontlargeb">사업자번호</label>
            </div>
            
            <div className="staffaddinpright flexcenterrowend">
              <input type={"text"} onChange={(e) => this.setCNum(e.target.value)} value = {this.state.cnum} className="mainadwsinp fontn"/>
            </div>
          </div>

          <div className="staffaddinpcont flexcenterrow">
            <div className="staffaddinpleft flexcenterrowstart">
              <label className="fontlargeb">ID</label>
            </div>
            
            <div className="staffaddinpright flexcenterrowend">
              <input type={"text"} onChange={(e) => this.setId(e.target.value)} value = {this.state.cid} className="mainadwsinp fontn"/>
            </div>
          </div>

          <div className="staffaddinpcont flexcenterrow">
            <div className="staffaddinpleft flexcenterrowstart">
              <label className="fontlargeb">비밀번호</label>
            </div>
            
            <div className="staffaddinpright flexcenterrowend">
              <input type={"text"} onChange={(e) => this.setPw(e.target.value)} value = {this.state.cpw} className="mainadwsinp fontn"/>
            </div>
          </div>

          <button className="btncur mainadddwsbtn fontnwb" onClick={() => this.props.handleAddWS(this.state.cname, this.state.oname, this.state.phone, this.state.cnum, this.state.cid, this.state.cpw)}>도매상 등록</button>
        </div>
        
      </div>
    );
  }
}

export default observer(AddWholeSales);