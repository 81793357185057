import React, { useState } from 'react';
import './Menu.css';

import {GetImgUrl} from '../../URL/AppUrl';
import MenuDrawer from './MenuDrawer';
//import { Link } from "react-router-dom";

//모바일용 상단 메뉴 영역
const MenuMb = (props) => {
  const [popup, setPopup] = useState(false);

  return (
    <div className="menucmb flexcentercol">

      {
        popup ? <MenuDrawer handleClosePop = {() => setPopup(false)} handleMenuClick = {props.handleMenuClick} handleLogout={props.handleLogout}/> : null
      }

      <div className="menucmbin flexcenterrow">
        <div className="dcell1 flexcenterrow">
          <img src={GetImgUrl("mmmenu.png")} alt="메뉴" className="btncur" 
            onClick={() => { 

              //if (props.handleMenuClick)
                //props.handleMenuClick(-1);

              setPopup(true);
            }}
          />
        </div>
        
        <div className="dcell8 flexcenterrow">
          <img src={GetImgUrl("bimb2.png")} alt="주도" className="btncur" />
        </div>

        <div className="dcell1">
        </div>
      </div>

      <div className="menumbline"/>
    </div>
  );
}

export default MenuMb;