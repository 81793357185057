import React from 'react';
import './Main.css';
import '../../App.css';
import {APP_VER, GetImgUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
//import { Link } from "react-router-dom";
import MainMenu from '../Menu/MainMenu';
import MenuIcon from './MenuIcon';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { ProcessLogout } from '../../Stores/StoreManager';
import MenuIconmb from './MenuIconmb';
import MenuMb from '../Menu/MenuMb';
import { observer } from 'mobx-react';
import { ChatStoreInstance } from '../../Stores/ChatStore';
import { IsValidS } from '../../Util/Util';
import { AlarmStoreInstance } from '../../Stores/AlarmStore';
import MainSet from './MainSet';
import AddWholeSales from './AddWholeSales';
import { StaffStoreInstance } from '../../Stores/StaffStore';
import OPChat from '../OPChat/OPChat';
import { OPChatStoreInstance } from '../../Stores/OPChatStore';

class Main extends React.Component {
  
  intervalId = -1;

  state = {
    loading: false,
    appOn : false,
    setting : false, //셋팅 팝업 출력여부
    addws: false,
    opChat : false,
  };

  savedToken = null;

  setLoading = (loading) =>
  {
    this.setState((prevState) => ({...prevState, loading : loading}));
  }

  onLoadProfile = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      //this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    ChatStoreInstance.loadNewChatCount(null);
    OPChatStoreInstance.loadNewChatCount(null);
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("Main.componentDidMount");

    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);
    }
    else
    {
      ChatStoreInstance.loadNewChatCount(null);
      OPChatStoreInstance.loadNewChatCount(null);
    }

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 1}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    if (mobileOn && !AlarmStoreInstance.loading && !AlarmStoreInstance.defaultDone)
    {
      AlarmStoreInstance.loadDefaultAlarm(LoginStoreInstance.compIdx, LoginStoreInstance.userIdx);
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "pushtoken")
        {
          if (IsValidS(msg.token))
          {
            if (!LoginStoreInstance.isProfileReady)
            {
              this.savedToken = msg.token;
            }
            else if (LoginStoreInstance.pushTokenIdx !== -1 && LoginStoreInstance.needPushToken)
            {
              AlarmStoreInstance.saveToken(LoginStoreInstance.pushTokenIdx, LoginStoreInstance.userIdx, msg.token);
            }
          }
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }
  
  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case 0: //메인
        
        break;
      case 1: //직원관리
        this.props.history.push("/emp");
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        this.props.history.push("/order");  
        break;
      case 4: //판매일보
        this.props.history.push("/report"); 
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 10: //마감시간 설정
        this.handleSetting();
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }

  handleLogout = () => {
    ProcessLogout();
    this.props.history.push("/");
  }

  handleGotoBravo6 = () => {
    window.open("https://www.bravo6.kr/", "_blank");
  }

  handleSetting = () => {
    this.setState((prevState) => ({...prevState, setting : !prevState.setting}));
  }

  handleTimeError = () => {
    this.setState((prevState) => ({...prevState, setting : false}));
  }
  
  //도매상 등록창 토글
  handleAddWholeSales = () => {
    this.setState((prevState) => ({...prevState, addws : !prevState.addws}));
  }

  //도매상 등록창 닫기
  handleCloseAWS = () =>{
    this.setState((prevState) => ({...prevState, addws : false}));
  }

  //도매상 등록시도
  handleAddWS = (cname, oname, phone, cnum, cid, cpw) => {
    if (this.state.loading)
      return;

    if (!IsValidS(cname))
    {
      alert('도매상 상호를 입력하세요.');
      return;
    }

    if (!IsValidS(oname))
    {
      alert('대표자를 입력하세요.');
      return;
    }

    if (!IsValidS(phone))
    {
      alert('핸드폰 번호를 입력하세요.');
      return;
    }

    if (!IsValidS(cnum))
    {
      alert('사업자 번호를 입력하세요.');
      return;
    }

    if (!IsValidS(cid))
    {
      alert('ID를 입력하세요.');
      return;
    }

    if (!IsValidS(cpw))
    {
      alert('비밀번호를 입력하세요.');
      return;
    }

    if (!window.confirm('도매상 메인 계정을 생성할까요?'))
      return;
      
    this.setLoading(true);
    StaffStoreInstance.addWholeSales(cname, oname, phone, cnum, cid, cpw, this.onAddWholeSalesResult);
  }

  onAddWholeSalesResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        alert('도매상이 추가되었습니다.');
        this.handleCloseAWS();
        break;
      case 1:
        alert('잘못된 요청입니다.');
        break;
      case 2:
        alert('도매상 추가 권한이 없습니다.');
        break;
      case 4:
        alert('계정추가중 오류가 발생했습니다.');
        break;
      case 5:
        alert('해당 계정은 이미 사용중입니다.');
        break;
      default:
        alert('계정추가중 오류가 발생했습니다.');
        break;
    }
  }

  handleCloseChat = () =>
  {
    this.setState((prevState) => ({...prevState, opChat : false}));
  }

  handleOpChat = () =>
  {
    this.setState((prevState) => ({...prevState, opChat : true}));
  }

  render() {

    if (mobileOn)
    {
      return (
        <div className="appcontmb flexcentercolstart maintopmb">
          {//운영자, 도매상간 채팅버튼 주석처리
            !this.state.opChat ? (
              <div className="mainopchatbtnmb btncursor flexcentercol">
                <img src={GetImgUrl("chat_click.png")} alt="운영자채팅" className="mainopchatbtnimg btncur" onClick={this.handleOpChat}/>
                <label className="mfontnb">업무지원</label>
                {
                  OPChatStoreInstance.newChatCount > 0 ? <div className="mainponewchat"></div> : null
                }
              </div>
            ) : null
          }
          {
            this.state.opChat ? (
              <OPChat handleCloseChat = {this.handleCloseChat} appOn = {this.state.appOn}/>
            ) : null
          }
          <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout = {this.handleLogout}/>
          {
            this.state.setting ? (
              <MainSet ProcessLogout = {this.handleLogout} handleTimeError = {this.handleTimeError} handleCloseSetting = {this.handleTimeError}/>
            ) : null
          }
          <div className="maincontmb flexcentercol">
            <div className="flexcenterrow mainmnurowmb">
              <MenuIconmb img={GetImgUrl("mmpp.png")} title="직원관리" desc="직원을 추가하거나 정보를 수정합니다." index = {1} handleClick={this.handleMenuClick}/>
              <MenuIconmb img={GetImgUrl("mmshop.png")} title="거래처관리" desc="거래처 정보를 추가하거나 수정합니다." index = {2} handleClick={this.handleMenuClick}/>
            </div>
            
            <div className="flexcenterrow mainmnurowmb">
              <MenuIconmb img={GetImgUrl("mmorder.png")} title="주문관리" desc="주문 정보를 관리하거나 조회합니다." index = {3} handleClick={this.handleMenuClick}/>
              <MenuIconmb img={GetImgUrl("mmpapers.png")} title="판매일보" desc="판매일보를 조회하거나 출력합니다." index = {4} handleClick={this.handleMenuClick}/>
            </div>
            
            <div className="flexcenterrow mainmnurowmb">
              <MenuIconmb img={GetImgUrl("mmmisc.png")} title="기타 주문" desc="판촉물 등 기타 주문 사항을 조회합니다." index = {6} handleClick={this.handleMenuClick}/>
              <MenuIconmb img={GetImgUrl("mnuodmbm.png")} title="수기 주문 등록" desc="주문 정보를 수기로 직접 등록합니다." index = {12} handleClick={this.handleMenuClick}/>  
            </div>
            
            <div className="flexcenterrow mainmnurowmb">
              <MenuIconmb img={GetImgUrl("mmnew.png")} title="신규 상품 등록" desc="신규 상품을 등록하거나 등록 여부를 조회 합니다." index = {5} handleClick={this.handleMenuClick}/>
              <MenuIconmb img={GetImgUrl("mmspeak.png")} title="공지 사항" desc="공지 사항을 조회하거나 작성합니다." index = {7} handleClick={this.handleMenuClick}/>  
            </div>
            
            <div className="flexcenterrow mainmnurowmb">
              <MenuIconmb img={GetImgUrl("mmchat.png")} title="채팅" desc="실시간으로 채팅을 주고받습니다." index = {8} handleClick={this.handleMenuClick} newItem={ChatStoreInstance.newChatCount > 0}/>
              <MenuIconmb img={GetImgUrl("alarm.png")} title="알림" desc="각종 알림을 확인합니다." index = {9} handleClick={this.handleMenuClick} newItem={AlarmStoreInstance.getNewCount > 0}/>              
            </div>
            
            <div className="flexcenterrow mainmnurowmb">
              {
                LoginStoreInstance.getUserLevel <= 1 ? (
                  <MenuIconmb img={GetImgUrl("mbgraph.png")} title="통계" desc="앱 이용 통계를 확인합니다." index = {11} handleClick={this.handleMenuClick}/>
                ) : null
              }  
            

              {
                LoginStoreInstance.getUserLevel < 2 ? (
                  <MenuIconmb img={GetImgUrl("jset.png")} 
                    title="설정" desc="주문 마감 시간등을 설정합니다." 
                    index = {10} handleClick={this.handleMenuClick}
                  />
                ) : null
              }
            </div>
            
            <label className="mainfooterfontmb">{`접속자 : ${LoginStoreInstance.getCompanyName}(${LoginStoreInstance.getUserName}), ver${APP_VER}`}</label>
            {/* 
            <div className="appdownload flexcenterrow">
              <a href="https://cdn.bravo6.kr/judo1.0.0.apk" target="_blank" rel="noreferrer" download>주도 모바일앱 다운로드(1.0.0)</a>
            </div>
            */}
          </div>
        </div>
      );
    }
    else
    {
      return (
        <div className="appcontainer">
          <div className= "maincontainer">
            {//영자, 도매상간 채팅버튼 주석처리
              !this.state.opChat ? (
                <div className="mainopchatbtn btncursor flexcentercol">
                  <img src={GetImgUrl("chat_click.png")} alt="운영자채팅" className="btncur" onClick={this.handleOpChat}/>
                  <label className="mfontnb">업무지원</label>
                  {
                    OPChatStoreInstance.newChatCount > 0 ? <div className="mainponewchat"></div> : null
                  }
                </div>
              ) : null
            }

            {
              this.state.opChat ? (
                <OPChat handleCloseChat = {this.handleCloseChat} appOn = {false}/>
              ) : null
            }
            {
              this.state.setting ? (
                <MainSet ProcessLogout = {this.handleLogout} handleTimeError = {this.handleTimeError} handleCloseSetting = {this.handleTimeError}/>
              ) : null
            }
            {
              this.state.addws ? (
                <AddWholeSales ProcessLogout = {this.handleLogout} handleCloseAWS = {this.handleCloseAWS} handleAddWS = {this.handleAddWS}/>
              ): null
            }
            <div className="maintop flexcenterrowend">
              <div className="menuitem flexcenterrow marginright20" onClick={this.handleGotoBravo6}>
                <img src={GetImgUrl("brhome.png")} alt="로그아웃"/>
                <label className="menulinktext">엔돌핀 서비스 홈페이지 바로가기</label>
              </div>

              {
                LoginStoreInstance.userType !== 0 && LoginStoreInstance.getUserLevel < 2 ? (
                  <div className="menuitem flexcenterrow marginright20" onClick={this.handleSetting}>
                    <img src={GetImgUrl("jset.png")} alt="설정"/>
                    <label className="menulinktext">설정</label>
                  </div>
                ) : null
              }

              <div className="menuitem flexcenterrow" onClick={this.handleLogout}>
                <img src={GetImgUrl("logout.png")} alt="로그아웃"/>
                <label className="menulinktext">로그아웃</label>
              </div>
            </div>
  
            <div className="mainhead flexcenterrow">
              <img src={GetImgUrl("titlem.png")}  alt="N-돌핀" className="titleimg"/>
            </div>
  
            <MainMenu index={0} handleMenuClick = {this.handleMenuClick}/>
            
            <div className="maincontent flexcenterrow">
              <MenuIcon img={GetImgUrl("bpeople.png")} title="직원관리" desc="직원을 추가하거나 정보를 수정합니다." index = {1} handleClick={this.handleMenuClick}/>
              <MenuIcon img={GetImgUrl("bshop.png")} title="거래처관리" desc="거래처 정보를 추가하거나 수정합니다." index = {2} handleClick={this.handleMenuClick}/>
              <MenuIcon img={GetImgUrl("border.png")} title="주문관리" desc="주문 정보를 관리하거나 조회합니다." index = {3} handleClick={this.handleMenuClick}/>
              <MenuIcon img={GetImgUrl("bpapers.png")} title="판매일보" desc="판매일보를 조회하거나 출력합니다." index = {4} handleClick={this.handleMenuClick}/>
              <MenuIcon img={GetImgUrl("etc.png")} title="기타 주문" desc="판촉물 등 기타 주문 사항을 조회합니다." index = {6} handleClick={this.handleMenuClick}/>

              <MenuIcon img={GetImgUrl("mnuodb.png")} title="수기 주문 등록" desc="주문 정보를 수기로 직접 등록합니다." index = {12} handleClick={this.handleMenuClick}/>
              {
                LoginStoreInstance.getUserLevel <= 1 ? (
                  <MenuIcon img={GetImgUrl("bgraph.png")} title="앱 이용자 통계" desc="N-돌핀 앱 이용자 통계를 조회합니다." index = {11} handleClick={this.handleMenuClick}/>
                ) : null
              }
              
              <MenuIcon img={GetImgUrl("bnew.png")} title="신규 상품 등록" desc="신규 상품을 등록하거나 등록 여부를 조회 합니다." index = {5} handleClick={this.handleMenuClick}/>
              <MenuIcon img={GetImgUrl("bnotice.png")} title="공지 사항" desc="공지 사항을 조회하거나 작성합니다." index = {7} handleClick={this.handleMenuClick}/>
              <MenuIcon img={GetImgUrl("bchat.png")} title="채팅" desc="실시간으로 채팅을 주고받습니다." index = {8} handleClick={this.handleMenuClick}/>
            </div>

            <div className='footerline'></div>
            <label className="mainfooterfont">{`접속자 : ${LoginStoreInstance.getCompanyName}(${LoginStoreInstance.getUserName}), ver${APP_VER}`}</label>
          </div>
        </div>
      );
    }
  }
}

export default observer(Main);