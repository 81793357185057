import React from 'react';
import { GetImgUrl, GetFileUrl } from '../URL/AppUrl';
import { GetChatTime, IsValidS, isImageFile } from '../Util/Util';

function GetCurrentTime(item)
{
  if (IsValidS(item.time))
    return item.time;

  return GetChatTime(item.rdate);
}

const ChatAppImg = (props) =>
{
  if (props.item.ctype === 3) //운영자 첨부 이미지면
  {
    return (
      <div className="achatitem">
  
        <div className="achatbubblef flexcentercol" onClick={() => props.handleDownload(true, props.item.chat, props.item.fname)}>
          {isImageFile(props.item.chat) ? (<img src={GetFileUrl(props.item.chat)} alt="첨부파일" className="achatafimgsz"/>) : null}
          <label className="achatfilefont">{props.item.fname}</label>
        </div>
  
        <label className="achattime">{GetCurrentTime(props.item)}</label>
        <img src={GetImgUrl("chatimg.png")} alt="첨부이미지"/>
        <label className="achattime">이미지 첨부</label>
      </div>
    );
  }
  else //유저 첨부 이미지면
  {
    return (
      <div className="achatitemuser">
        <img src={GetImgUrl("chatimg.png")} alt="첨부이미지"/>
        <label className="achattime">이미지 첨부</label>
        <label className="achattime">{GetCurrentTime(props.item)}</label>

        <div className="achatbubbleuserf flexcentercol" onClick={() => props.handleDownload(true, props.item.chat, props.item.fname)}>
          {isImageFile(props.item.chat) ? (<img src={GetFileUrl(props.item.chat)} alt="첨부파일" className="achatafimgsz"/>):null}
          <label className="achatfilefontuser">{props.item.fname}</label>
        </div>
      </div>
    );
  }  
}

export default ChatAppImg;