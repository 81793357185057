import {
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';
import React from 'react';

import {GetApiUrl, LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { IsValidS, IsValidV } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

class StatsStore {
  //observable
  statsList = null; //알림목록
  retCount = 0; //전체 거래처수
  retAppCount = 0; //거래처중 앱설치 거래처수
  //----------------------------------------------------------------------------------------------------------------------------------------
  //비observable
  totalStatsCount = 0;

  //모든 데이터를 초기화 시킴
  clearAll = () => {
    this.statsList = null; //알림목록
    this.retCount = 0; //전체 거래처수
    this.retAppCount = 0; //거래처중 앱설치 거래처수
    this.totalStatsCount = 0;
    this.loadStatsCallback = null;
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      statsList : observable,
      retCount : observable,
      retAppCount : observable,
      parseStats : action,
      clearAll : action,
      getAppInstallPer: computed,
    });
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  loadStatsCallback = null;
  makeLoadParam = (pos) => {
    const params = new URLSearchParams();
    params.append("pos", pos.toString());
    params.append("rt", "0");

    return params;
  }

  //기본적인 전체/개별 공지 목록을 서버로 부터 수신하기
  loadStats = (pos, callback) => {

    this.loadStatsCallback = callback;

    axios({
      method:"POST",
      url: GetApiUrl("stats/list.do"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadParam(pos)
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("default alarm loading done.");

      this.parseStats(res.data);

    }).catch(error=>{

      if (LOG_ENABLE)
        console.log(error);
      
      this.parseStats(null);
    });
  }

  parseStats = (result) => {

    if (!IsValidV(result))
    {
      if (this.loadStatsCallback)
        this.loadStatsCallback(-2, -1);

      return;
    }

    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (result.ret === 0)
    {
      //통계가 있으면
      if (IsValidS(result.list))
      {
        this.statsList = result.list;
      }
      else
        this.statsList = null;

      if (result.ft === 1) //첫번째 페이지 요청인경우
      {
        if (result.tc && result.tc !== -1)
          this.totalStatsCount = result.tc;

        if (result.am === 1) //운영자인경우
        {
          if (IsValidS(this.statsList))
          {
            this.retCount = this.statsList[0].tr;
            this.retAppCount = this.statsList[0].ru;
          }
        }
        else //운영자가 아니면
        {
          if (result.trc && result.trc !== -1)
          this.retCount = result.trc;
      
          if (result.tac && result.tac !== -1)
            this.retAppCount = result.tac;
        }
      }
    }
    else
    {
      if (result.ret === 100)
      {
        //세션 오류인경우
        LoginStoreInstance.sessionError = true;
      }
    }

    if (this.loadStatsCallback)
      this.loadStatsCallback(result.ret, result.tc);
  }

  get getAppInstallPer()
  {
    if (this.retCount < 1 || this.retAppCount < 1)
      return "0%";

    return ((this.retAppCount / this.retCount) * 100.0).toFixed(1) + '%';
  }
}

const StatsStoreInstance = new StatsStore();
const StatsStoreContext = React.createContext(StatsStoreInstance);

const UseStatsStore = () => React.useContext(StatsStoreContext);

export {UseStatsStore, StatsStoreInstance};
