import React from 'react';
import './Menu.css';
import { animated, Spring } from 'react-spring'

import {GetImgUrl, LOG_ENABLE} from '../../URL/AppUrl';
import { observer } from 'mobx-react';
import { ChatStoreInstance } from '../../Stores/ChatStore';
import { AlarmStoreInstance } from '../../Stores/AlarmStore';
import { LoginStoreInstance } from '../../Stores/LoginStore';

class MenuDrawer extends React.Component
{
  state = {
    width : 0,
    left: 0,
    aniOn : false,
    menuHeight: 0,
  };

  handleSizeChanging = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleSizeChanging");

    this.setState((prevState) => ({...prevState, width : this.calcPopupWidth(window.innerWidth), menuHeight: this.calcMenuHeight(window.innerHeight)}));
  }

  calcPopupWidth = (windowWidth) =>
  {
    return Math.floor(windowWidth * 0.89);
  }

  calcMenuHeight = (windowHeight) =>
  {
    return Math.floor((windowHeight - 40) * 0.1);
  }

  componentDidMount() {

    window.addEventListener("resize", this.handleSizeChanging);

    this.setState((prevState) => ({...prevState, width : this.calcPopupWidth(window.innerWidth), menuHeight: this.calcMenuHeight(window.innerHeight)}));
  }

  render()
  {
    return (
      <div className="mnudrawercntbg flexcentercol">
        <Spring to={{left : 0}} from={{left : -this.state.width}}>
            {
              styles => (
                <animated.div className="mnudrawercnt flexcentercolstart" style={{width: this.state.width, ...styles}}>
                  <div className="flexcenterrow mnudrawertop">
                    <div className="cnthalfh mnudrawertopleft flexcenterrowstart">
                      <img src={GetImgUrl("mbpop1.png")} alt="menu" />
                      <label className="mfontlargeb">&nbsp;MENU</label>
                    </div>

                    <div className="cnthalfh mnudrawertopright flexcenterrowstart">
                      <div className="mnudrawertoprightl flexcenterrowstart btncur" onClick={() => {
                        if (this.props.handleLogout)
                          this.props.handleLogout();
                      }}>
                        <img src={GetImgUrl("mbpop11.png")} alt="menu" />
                        <label className="mfontlargeb btncur">&nbsp;로그아웃</label>
                      </div>
                      <div className="mnudrawertoprightr flexcenterrowend">
                        <img src={GetImgUrl("mbpop12.png")} alt="close" className="btncur" onClick={() => {
                          if (this.props.handleClosePop)
                            this.props.handleClosePop();
                        }}/>
                      </div>
                    </div>

                  </div>

                  <div className="mnudrawerline"></div>

                  <div className="flexcentercolstart mnudrawercontsc">
                    <div className="mnudrawerrow flexcenterrow">
                      {/*메인 */}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(0)}>
                        <img src={GetImgUrl("mbpop2.png")} alt="메인" />
                        <label className="mfontlargeb btncur">&nbsp;메인 화면</label>
                      </div>

                      
                      {/*직원관리*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(1)}>
                        <img src={GetImgUrl("mbpop3.png")} alt="직원" />
                        <label className="mfontlargeb btncur">&nbsp;직원 관리</label>
                      </div>
                    </div>

                    <div className="mnudrawerrow flexcenterrow">
                      {/*거래처*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(2)}>
                        <img src={GetImgUrl("mbpop4.png")} alt="거래처" />
                        <label className="mfontlargeb btncur">&nbsp;거래처 관리</label>
                      </div>

                      
                      {/*주문*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(3)}>
                        <img src={GetImgUrl("mbpop5.png")} alt="주문" />
                        <label className="mfontlargeb btncur">&nbsp;주문 관리</label>
                      </div>
                    </div>

                    <div className="mnudrawerrow flexcenterrow">
                      {/*판매일보*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(4)}>
                        <img src={GetImgUrl("mbpop6.png")} alt="일보" />
                        <label className="mfontlargeb btncur">&nbsp;판매 일보</label>
                      </div>

                      
                      {/*기타주문*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(6)}>
                        <img src={GetImgUrl("mbpop7.png")} alt="기타" />
                        <label className="mfontlargeb btncur">&nbsp;기타 주문</label>
                      </div>
                    </div>

                    <div className="mnudrawerrow flexcenterrow">
                      {/*신규상품*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(12)}>
                        <img src={GetImgUrl("mnuodmbm.png")} alt="수기" />
                        <label className="mfontlargeb btncur">&nbsp;수기 주문 등록</label>
                      </div>

                      {/*신규상품*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(5)}>
                        <img src={GetImgUrl("mbpop8.png")} alt="상품" />
                        <label className="mfontlargeb btncur">&nbsp;신규 상품 등록</label>
                      </div>
                    </div>

                    <div className="mnudrawerrow flexcenterrow">
                      {/*공지*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(7)}>
                        <img src={GetImgUrl("mbpop9.png")} alt="공지" />
                        <label className="mfontlargeb btncur">&nbsp;공지 사항</label>
                      </div>

                      {/*채팅*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(8)}>
                        <img src={GetImgUrl("mbpop10.png")} alt="채팅" />
                        <label className="mfontlargeb btncur">&nbsp;채팅</label>
                        {
                          ChatStoreInstance.newChatCount > 0 ? (<div className="menunewitemmb"/>): null
                        }                      
                      </div>
                    </div>

                    <div className="mnudrawerrow flexcenterrowstart">
                      {/*앱이용자통계*/}
                      {
                        LoginStoreInstance.getUserLevel <= 1 ? (
                          <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(11)}>
                            <img src={GetImgUrl("mbgraph.png")} alt="통계" />
                            <label className="mfontlargeb btncur">&nbsp;앱 이용자 통계</label>
                            {
                              AlarmStoreInstance.getNewCount > 0 ? (<div className="menunewitemmb"/>): null
                            }
                          </div>
                        ): null
                      }

                      {/*알림*/}
                      <div className="flexcenterrowstart mnudraweritem btncur" onClick={() => this.props.handleMenuClick(9)}>
                        <img src={GetImgUrl("alarm.png")} alt="알림" />
                        <label className="mfontlargeb btncur">&nbsp;알림</label>
                        {
                          AlarmStoreInstance.getNewCount > 0 ? (<div className="menunewitemmb"/>): null
                        }
                      </div>
                    </div>

                    <div className="mnudrawerrow flexcenterrow">
                    </div>
                  </div>

                  
                  
                </animated.div>
              )
            }
            
        </Spring>
      </div>
    );
  }
}

export default observer(MenuDrawer);