import React from 'react';
import { observer } from 'mobx-react';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { ProcessLogout } from '../../Stores/StoreManager';
import { StaffStoreInstance } from '../../Stores/StaffStore';

import './MnuOrder.css';
import '../../App.css';
import { MOrderStoreInstance } from '../../Stores/MOrderStore';

import {GetImgUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
import DatePick from '../DatePick/DatePick';
import MenuMb from '../Menu/MenuMb';
import Loading from '../Loading/Loading';
import MainMenu from '../Menu/MainMenu';
import PageList from '../PageList';
import { NumberFormatString, IsValidS } from '../../Util/Util';
import MnuOneOrder from './MnuOneOrder';
import MnuOrderAdd from './MnuOrderAdd';
import MnuOneOrdermb from './MnuOneOrdermb';
import MnuOrderEditmb from './MnuOrderEditmb';
import MnuOrderDetmb from './MnuOrderDetmb';
import MnuOrderInfo from './MnuOrderInfo';

class MnuOrder extends React.Component {
  state = {
    loading : false,
    salesList: null,
    salesIdx: -1,
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 0, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 0, //전체 페이지 개수

    requestMode : 0, //0 : 전체, 1 : 영업담당
    dateString : '', //선택된 날짜의 문자열 표현
    dateSelect: false, //날짜 선택중인가?

    addOrder : 0, //0 : 주문추가하기, 1 : 주문에 상품추가하기
    showEditMB : false,
    showDetail: false,
    addInfo : false,
  };

  maxPageNum = 10;
  todayYear = 0;
  todayMonth = 0;
  todayDate = 0;

  selectedYear = 0;
  selectedMonth = 0;
  selectedDate = 0;

  currentDate = 0; //현재 조회중인일자
  editOrderIdx = -1; //상품추가하기의 주문번호

  editOrderInfo = null;
  editItemInfo = null;

  currentSalesIdx = -1;
  addInfoCount = 0;
  addInfoText1 = "";
  addInfoText2 = "";

  showAddInfo = (visible, text1, text2) =>
  {
    this.addInfoCount = 0;
    this.addInfoText1 = text1;
    this.addInfoText2 = text2;
    this.setState((prevState) => ({...prevState, addInfo : visible}));
  }

  setLoading = (loading) =>
  {
    this.setState((prevState) => ({...prevState, loading : loading}));
  }

  //이전날짜인가?
  isPrevDay = () =>
  {
    if (this.selectedYear < this.todayYear)
      return true;

    if (this.selectedYear > this.todayYear)
      return false;

    if (this.selectedMonth < this.todayMonth)
      return true;

    if (this.selectedMonth > this.todayMonth)
      return false;

    if (this.selectedDate < this.todayDate)
      return true;

    if (this.selectedDate > this.todayDate)
      return false;

    return false;
  }

  //이후 날짜인가?
  isNextDay = () =>
  {
    if (this.selectedYear > this.todayYear)
      return true;

    if (this.selectedYear < this.todayYear)
      return false;

    if (this.selectedMonth > this.todayMonth)
      return true;

    if (this.selectedMonth < this.todayMonth)
      return false;

    if (this.selectedDate > this.todayDate)
      return true;

    if (this.selectedDate < this.todayDate)
      return false;

    return false;
  }

  //오늘 조회할 주문일자를 계산한다.
  calcToday = () =>
  {
    let date = new Date();

    date.setDate(date.getDate() - 1);

    this.todayYear = date.getFullYear();
    this.todayMonth = date.getMonth() + 1;
    this.todayDate = date.getDate();

    this.selectedYear = this.todayYear;
    this.selectedMonth = this.todayMonth;
    this.selectedDate = this.todayDate;

    let dateInfo = (date.getFullYear() + "-" + NumberFormatString(date.getMonth() + 1) + "-" + NumberFormatString(date.getDate()));
    this.currentDate = parseInt(`${date.getFullYear()}${NumberFormatString(date.getMonth() + 1)}${NumberFormatString(date.getDate())}`);

    if (LOG_ENABLE)
      console.log(`querydate : ${this.currentDate}`);

    this.setState((prevState) => ({...prevState, dateString: dateInfo}));
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log(`MnuOrder.componentDidMount`);

    this.intervalId = setInterval(this.update, 300);

    if (mobileOn)
      this.maxPageNum = 3;

    this.calcToday();

    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);
    }
    else
    {
      if (LoginStoreInstance.getUserLevel < 2)
      {
        this.setState((prevState) => ({...prevState, requestMode: 0}));
      }
      else
      {
        this.setState((prevState) => ({...prevState, requestMode: 1}));
      }

      this.loadSales();
    }
  }

  componentWillUnmount()
  {
    if (LOG_ENABLE)
      console.log(`MnuOrder.componentWillUnmount`);

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    if (LoginStoreInstance.getUserLevel < 2)
    {
      this.setState((prevState) => ({...prevState, requestMode: 0}));
    }
    else
    {
      this.setState((prevState) => ({...prevState, requestMode: 1}));
    }

    this.loadSales();
  }

  loadSales = () =>
  {
    StaffStoreInstance.loadSalesManager(this.onLoadSalesMangerList);  
  }

  onLoadSalesMangerList = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      if (!window.confirm("영업담당자 목록 조회중 오류가 발생했습니다.\n다시 시도할까요?"))
      {
        this.setLoading(false);
        return;
      }

      StaffStoreInstance.loadSalesManager(this.onLoadSalesMangerList);
    }
    else
    {
      //관리 담당자인경우
      if (LoginStoreInstance.getUserLevel === 3)
      {
        if (!this.state.loading)
          this.setLoading(true);

        StaffStoreInstance.loadManagedSalesList(LoginStoreInstance.userIdx, this.onManagedSalesLoaded);
        return;
      }

      if (this.state.requestMode === 0)
      {
        if (IsValidS(StaffStoreInstance.salesManagerList))
        {
          this.setState((prevState) => ({...prevState, salesList: StaffStoreInstance.salesManagerList, salesIdx: StaffStoreInstance.salesManagerList[0].idx}));
          this.currentSalesIdx = StaffStoreInstance.salesManagerList[0].idx;
        }
      }
      else
      {
        this.setState((prevState) => ({...prevState, salesIdx: LoginStoreInstance.userIdx}));
        this.currentSalesIdx = LoginStoreInstance.userIdx;
      }

      this.loadOrderList(1);
    }
  }

  onManagedSalesLoaded = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      if (!window.confirm("관리하는 영업담당자 목록 조회중 오류가 발생했습니다.\n다시 시도할까요?"))
      {
        this.setLoading(false);
        return;
      }

      StaffStoreInstance.loadManagedSalesList(LoginStoreInstance.userIdx, this.onManagedSalesLoaded);
      return;
    }

    this.initSales();
    this.loadOrderList(1);
  }

  initSales = () =>
  {
    const salesInfo = StaffStoreInstance.getFirstManagedSalesInfo();

    if (salesInfo === null)
    {
      this.setState((prevState) => ({...prevState, salesIdx: -1}));
      this.currentSalesIdx = -1;
      return;
    }

    this.setState((prevState) => ({...prevState, salesIdx: salesInfo.sidx}));
    this.currentSalesIdx = salesInfo.sidx;
  }

  handleSalesSelected = (salesIdx) =>
  {
    const salesInfo = StaffStoreInstance.findManagedSalesByIdx(salesIdx);

    if (salesInfo === null)
    {
      if (LOG_ENABLE)
        console.log('sales not found');

      return;
    }

    this.setState((prevState) => ({...prevState, salesIdx: salesInfo.sidx}));
    this.currentSalesIdx = salesInfo.sidx;

    this.loadOrderList(1);
  }

  loadOrderList = (pageNum) =>
  {
    if (LoginStoreInstance.getUserLevel === 3)
    {
      if (this.currentSalesIdx < 1)
      {
        if (LOG_ENABLE)
          console.log('invalid sales idx');

        this.setLoading(false);
        return;
      }
    }

    if (!this.state.loading)
      this.setLoading(true);

    MOrderStoreInstance.loadOrder(this.state.requestMode, this.currentSalesIdx, (pageNum - 1) * 20, this.currentDate, this.onOrderLoaded);
  }

  onOrderLoaded = (resultCode, firstRead) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('주문 목록 조회중 오류가 발생했습니다. 네트워크 연결 상태를 확인해 주세요.');
      return;
    }

    if (firstRead)
    {
      this.calcPageInfo();
    }
  }

  calcPageInfo = () =>
  {
    let currentCount = MOrderStoreInstance.totalOrderCount;

    let pageCount = Math.floor(currentCount / 20);

    if ((currentCount % 20) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.maxPageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  //하단 페이지 변경 버튼 눌릴경우
  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4
  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = this.maxPageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadOrderList(1);
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(startPage);
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(startPage);
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadOrderList(endPage);
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadOrderList(pageNum);
    }
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }

    if (this.state.addInfo)
    {
      if (++this.addInfoCount > 5)
      {
        this.showAddInfo(false, "");
      }
    }
  }

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case -1:
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        this.props.history.push("/order");  
        break;
      case 4: //판매일보
        this.props.history.push("/report"); 
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 10: //마감시간 설정
        this.handleSetting();
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }

  //주문 마감시간이 지났는지 확인한다.
  checkOrderDoneTime = () =>
  {
    let date = new Date();

    if (LOG_ENABLE)
      console.log(`order done time : ${LoginStoreInstance.getOrderDoneTime()}`);

    return !LoginStoreInstance.checkOrderTimeLimit(date.getHours(), date.getMinutes());
  }

  handleAddOrder = () =>
  {
    if (LOG_ENABLE)
      console.log(`handleAddOrder`);

    if (LoginStoreInstance.getUserLevel === 3)
    {
      if (this.currentSalesIdx < 1)
      {
        alert(`주문을 추가할 영업담당자가 필요하지만\n관리하는 영업담당자가 없습니다.`);
        return;  
      }
    }

    if (this.isPrevDay())
    {
      alert(`해당 일자에는 주문을 추가 할 수 없습니다.`);
      return;
    }

    //오늘자인경우
    /*
    if (!this.isNextDay())
    {
      if (this.checkOrderDoneTime())
      {
        alert(`주문이 마감되어 더 이상 추가 할 수 없습니다.\n내일 배송 할 주문이라면 날짜를 변경해 주세요.`);
        return;
      }

      //영업담당의 개별주문 마감여부

      if (this.state.requestMode === 1)
      {
        //자신의 개별적인 주문 마감여부를 확인하기
        if (MOrderStoreInstance.salesOrderDone)
        {
          alert(`주문이 마감되어 더 이상 추가 할 수 없습니다.\n내일 배송 할 주문이라면 날짜를 변경해 주세요.`);
          return;
        }
      }
    }*/

    /*
    if (LoginStoreInstance.getUserLevel > 2)
    {
      alert('수기 주문을 등록할 권한이 없습니다.');
      return;
    }*/

    this.setState((prevState) => ({...prevState, addOrder: 1}));
  }

  handleCloseAddOrder = () =>
  {
    this.setState((prevState) => ({...prevState, addOrder: 0}));
  }

  handleSelectDate = () =>
  {
    this.setState((prevState) => ({...prevState, dateSelect : true}));
  }

  handleDate = (valid, selectedDate) =>
  {
    if (LOG_ENABLE)
    console.log("handleDate : " + selectedDate);

    //날짜 선택없이 닫은경우
    if (!valid)
    {
      this.setState((prevState) => ({...prevState, dateSelect : false}));
      return;
    }

    let newYear = selectedDate.getFullYear();
    let newMonth = selectedDate.getMonth() + 1;
    let newDay = selectedDate.getDate();

    this.selectedYear = newYear;
    this.selectedMonth = newMonth;
    this.selectedDate = newDay;

    let newDateInfo = selectedDate.getFullYear() + "-" + NumberFormatString(selectedDate.getMonth() + 1) + "-" + NumberFormatString(selectedDate.getDate());
    this.currentDate = `${newYear}${NumberFormatString(newMonth)}${NumberFormatString(newDay)}`;

    this.setState((prevState) => ({...prevState, dateString: newDateInfo, dateSelect : false}));

    this.loadOrderList(1);
  }

  //상품 삭제 요청
  handleDelete = (orderIdx, item) =>
  {
    if (LOG_ENABLE)
      console.log(`handleDelete`);

    if (this.state.loading)
    {
      return;
    }

    let order = MOrderStoreInstance.findOrderByIdx(orderIdx);

    if (order === null)
    {
      alert('주문을 찾을 수 없습니다.');
      return;
    }

    if (!window.confirm('상품을 삭제할까요?'))
    {
      return;
    }

    this.setLoading(true);
    MOrderStoreInstance.delProduct(orderIdx, item.idx, this.onDeleteResult);
  }

  onDeleteResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        alert('상품이 삭제되었습니다.');
        if (mobileOn)
        {
          this.setState((prevState) => ({...prevState, showEditMB : false}));
        }
        break;
      case 1:
        alert('잘못된 요청입니다.');
        break;
      case 2:
        alert('주문을 찾을 수 없습니다.');
        break;
      default:
        alert('상품 삭제 중 오류가 발생했습니다.');
        break;
    }
  }
  //상품 수정 끝남
  handleEditDone = (orderIdx, item, boxCount, bottleCount) =>
  {
    if (LOG_ENABLE)
      console.log(`handleEditDone : ${orderIdx}, ${item.iidx}, ${boxCount}, ${bottleCount}`);

    if (this.state.loading)
    {
      return;
    }

    if (boxCount < 1 && bottleCount < 1)
    {
      alert('상품 개수를 모두 0으로 설정할 수 없습니다. 삭제 메뉴를 이용해 주세요.');
      return;
    }

    this.setLoading(true);
    MOrderStoreInstance.editOrder(0, orderIdx, `${item.iidx},${boxCount},${bottleCount}`, this.onItemEditResult);
  }

  onItemEditResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        if (mobileOn)
        {
          this.setState((prevState) => ({...prevState, showEditMB : false, showDetail: false}));
          alert('수정 되었습니다.');
        }
        break;
      case 1:
        alert('잘못된 요청입니다.');
        break;
      case 2:
        alert('주문이 없습니다.');
        break;
      default:
        alert('주문 수정에 실패 했습니다.');
        break;
    }
  }

  //주문에 상품추가하기
  handlePdAdd = (orderIdx) =>
  {
    let order = MOrderStoreInstance.findOrderByIdx(orderIdx);

    if (order === null)
    {
      alert('주문을 찾을 수 없습니다.');
      return;
    }

    if (order.st === 11)
    {
      alert('해당 주문은 이미 배송 완료되어 수정이 불가능합니다.');
      return;
    }

    this.editOrderIdx = orderIdx;
    this.setState((prevState) => ({...prevState, addOrder: 2, showDetail : false}));
  }

  //해당 주문을 편집 완료 상태로 만듬
  handleOrderDone = (orderIdx) =>
  {
    if (LOG_ENABLE)
      console.log(`handleOrderDone`);

    let order = MOrderStoreInstance.findOrderByIdx(orderIdx);

    if (order === null)
    {
      alert('주문을 찾을 수 없습니다.');
      return;
    }

    if (order.st !== 0)
    {
      return;
    }

    //if (!window.confirm('이 주문을 처리 완료 할까요? 처리 완료된 주문은 더이상 수정 할 수 없습니다.'))
    if (!window.confirm('이 주문을 처리 완료 할까요?'))
      return;

    this.setLoading(true);
    MOrderStoreInstance.editOrder(1, orderIdx, null, this.onItemEditResult);
  }

  handleOrderDelete = (orderIdx) =>
  {
    if (LOG_ENABLE)
      console.log(`handleOrderDelete : ${orderIdx}`);

    let order = MOrderStoreInstance.findOrderByIdx(orderIdx);

    if (order === null)
    {
      alert('주문을 찾을 수 없습니다.');
      return;
    }

    if (order.st === 11)
    {
      alert('이 주문은 이미 배송 완료되어 삭제가 불가능합니다.');

      return;
    }

    if (!window.confirm('이 주문을 취소할까요?'))
      return;

    this.setLoading(true);
    MOrderStoreInstance.editOrder(2, orderIdx, null, this.onCancelOrderResult);
  }

  //주문 취소 요청의 결과
  onCancelOrderResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        alert('주문이 취소 되었습니다.');
        break;
      case 1:
        alert('잘못된 요청입니다.');
        break;
      case 2:
        alert('주문이 없습니다.');
        break;
      default:
        alert('주문 취소에 실패 했습니다.');
        break;
    }
  }

  getDateInfo = () =>
  {
    if (this.isPrevDay())
    {
      return `${this.state.dateString} 지난일자`;
    }
    else if (this.isNextDay())
    {
      return `${this.state.dateString} 차후 배송`;
    }

    return `${this.state.dateString} 오늘자 기본배송`;
  }

  //해당 영업담당자 앞으로, 주문을 등록함
  //salesIdx: 대상 영업담당자
  //retailer: 주문을 요청한 소매상
  //items: 아이템고유번호,박스개수,병개수 형태로 변환된 주문상품목록
  handleRequestAddOrder = (salesIdx, retailer, items) =>
  {
    if (LOG_ENABLE)
      console.log(`handleRequestAddOrder : ${salesIdx}, ${retailer.cname}, items=${items}, date=${this.currentDate}`);

    this.setLoading(true);
    MOrderStoreInstance.addOrder(this.currentDate, salesIdx, retailer.idx, items, this.onAddOrderResult);
  }

  onAddOrderResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        //alert('주문이 등록되었습니다.');
        this.setState((prevState) => ({...prevState, addOrder: 0}));
        break;
      case 1:
        alert('잘못된 요청입니다.');
        break;
      case 2:
        alert('오늘자 주문이 마감되어 더이상 등록 할 수 없습니다.');
        break;
      case 3:
        alert('대상 거래처를 찾을 수 없습니다.');
        break;
      case 4:
        alert('주문 등록중 오류가 발생했습니다.');
        break;
      case 5:
        alert('주문이 등록되었지만 다시 조회 중 오류가 발생했습니다.');
        break;
      default:
        alert('서버 오류로 인해 주문 등록에 실패 했습니다.');
        break;
    }
  }

  handleEditOrderItem = (orderIdx, editItems) =>
  {
    if (LOG_ENABLE)
      console.log(`handleEditOrderItem : ${orderIdx}, ${editItems}`);

    if (!IsValidS(editItems))
    {
      this.setState((prevState) => ({...prevState, addOrder: 0}));
      return;
    }

    this.setLoading(true);
    MOrderStoreInstance.editOrder(0, orderIdx, editItems, this.onItemEditResult2);
  }

  onItemEditResult2 = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.setState((prevState) => ({...prevState, addOrder: 0}));
        alert('상품이 추가되었습니다.');
        break;
      case 1:
        alert('잘못된 요청입니다.');
        break;
      case 2:
        alert('주문이 없습니다.');
        break;
      default:
        alert('주문 수정에 실패 했습니다.');
        break;
    }
  }

  handleDetail = (order) =>
  {
    if (LOG_ENABLE)
      console.log(`handleDetail : ${JSON.stringify(order)}`);

    this.editOrderInfo = order;
    this.setState((prevState) => ({...prevState, showDetail: true}));
  }

  handleCloseDetail = () =>
  {
    this.setState((prevState) => ({...prevState, showDetail: false}));
  }

  handleEditItemMobile = (order, item) =>
  {
    if (LOG_ENABLE)
      console.log(`handleEditItemMobile`);

    this.editOrderInfo = order;
    this.editItemInfo = item;

    this.setState((prevState) => ({...prevState, showEditMB : true}));
  }

  handleCloseEditmb = () =>
  {
    this.setState((prevState) => ({...prevState, showEditMB : false}));
  }

  handleLogout = () =>
  {
    ProcessLogout();
    this.props.history.push("/");
  }

  render()
  {
    let orderList = null;

    let salesList = null;

    if (LoginStoreInstance.getUserLevel === 3)
    {
      if (IsValidS(StaffStoreInstance.managedSalesList))
      {
        salesList = StaffStoreInstance.managedSalesList.map(item => {return (<option key = {item.sidx} value={item.sidx}>{item.uname}</option>)});
      }
      else
      {
        salesList = [];
        salesList.push((<option key = {-1} value={"-1"}>관리하는 영업담당자가 없습니다.</option>));
      }
    }

    if (mobileOn)
    {
      const manualOrders = MOrderStoreInstance.orderList;

      if (IsValidS(manualOrders))
      {
        orderList = manualOrders.map(item => {return (<MnuOneOrdermb key={item.idx} idx = {item.idx} order = {item}
          handleDetail = {this.handleDetail}
          handleEditItemMobile = {this.handleEditItemMobile}
          />)});
      }

      return (
        <div className="appcontmb flexcentercolstart">
          <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout = {this.handleLogout}/>
          {this.state.dateSelect ? <DatePick handleDate = {this.handleDate} cyear={this.selectedYear} cmonth={this.selectedMonth} cday={this.selectedDate}/> : null}
          
          {
            this.state.addOrder !== 0 ? (
              <MnuOrderAdd 
                mode = {this.state.requestMode}
                resultMode = {this.state.addOrder === 1  ? 0 : 1}
                orderIdx = {this.editOrderIdx}
                salesIdx = {this.state.salesIdx}
                handleClose = {this.handleCloseAddOrder}
                setLoading={this.setLoading}
                salesList={this.state.salesList}
                handleAddOrder = {this.handleRequestAddOrder}
                handleEditOrderItem = {this.handleEditOrderItem}
                showInfoText = {this.showAddInfo}
              />
            ) : null
          }
          {
            this.state.showEditMB ? (
              <MnuOrderEditmb
                order={this.editOrderInfo}
                item={this.editItemInfo}
                handleDelete={this.handleDelete} 
                handleEditDone={this.handleEditDone} 
                handleClose={this.handleCloseEditmb}
              />
            ) : null
          }
          {
            this.state.showDetail ? (
              <MnuOrderDetmb
                order={this.editOrderInfo}
                handleAddItem={this.handlePdAdd} 
                handleOrderDone={this.handleOrderDone} 
                handleOrderDelete = {this.handleOrderDelete}
                handleClose={this.handleCloseDetail}
              />
            ) : null
          }
          {
            this.state.loading ? <Loading />:null
          }
          {
            this.state.addInfo ? <MnuOrderInfo desc1={this.addInfoText1} desc2={this.addInfoText2} /> :null
          }

          <div className="maincontainermb">
            <div className="mnuoddatearea flexcentercol">
              <div className="mnuoddateareaup flexcenterrowstart marginbottom15 margintop15">
                <img src={GetImgUrl("mnuod1mb.png")} alt="수기주문" className="marginleft10 marginright10"/>
                <label className="mfontnb">수기 주문 등록</label>
              </div>
              <div className="mnuoddateareadown flexcenterrow marginbottom15">
                <label className="mfontnwb marginright10">날짜별 수기 주문 조회</label>

                <div className="mnuoddate flexcenterrow marginright10 btncur" onClick={this.handleSelectDate}>
                  <label className="fontnb btncur">{this.state.dateString}</label>
                </div>

                <button className="mnuoddatesel fontnb btncur" onClick={this.handleSelectDate}>조회</button>
              </div>
            </div>

            <div className="mnuodlist1 flexcenterrowstart marginbottom15">
              <div className="cnthalfh flexcenterrowstart">
                <img src={GetImgUrl("statsppmb.png")} alt="종이" className="marginleft10 marginright10"/>
                <label className="mfontnb">수기 주문 내역</label>
              </div>

              <div className="cnthalfh flexcenterrowend">
                {
                  !this.isPrevDay() ? (
                    <button className="bgcolor3 mnuodaddbtnmb mfontnwb btncur" onClick={this.handleAddOrder}>주문 추가하기</button>  
                  ) : null
                }
              </div>
            </div>

            <div className="mnuodline"></div>

            <div className="mnuodlist1 flexcenterrowstart marginbottom15 margintop15">
              <div>
                <img src={GetImgUrl("pdaymb.png")} alt="달력" className="marginleft10 marginright10"/>
                <label className="mfontnb">{this.getDateInfo()}</label>
              </div>
            </div>
            <div className="mnuodline"></div>

            {
              LoginStoreInstance.getUserLevel === 3 ? (
                <div className="mnuodlist1 flexcentercol marginbottom15 margintop15">
                  <select className="odsalesselmb mfontn"
                    value={this.state.salesIdx.toString()}
                    onChange={(e) => {
                      if (LOG_ENABLE)
                        console.log(e.target.value);

                      this.handleSalesSelected(parseInt(e.target.value));
                    }}
                  >
                    {salesList}
                  </select>

                  <div className="mnuodline"></div>
                </div>
              ) : null
            }

            {orderList != null && orderList.length > 0 ? orderList : ("해당 일자의 주문이 없습니다.")}
            
            {
              this.state.pageCount > 1 ? 
                <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
    else
    {
      const manualOrders = MOrderStoreInstance.orderList;

      if (IsValidS(manualOrders))
      {
        orderList = manualOrders.map(item => {return (<MnuOneOrder key={item.idx} idx = {item.idx} order = {item}
          handleDelete={this.handleDelete} 
          handleEditDone={this.handleEditDone} 
          handleOrderDone = {this.handleOrderDone}
          handleOrderDelete = {this.handleOrderDelete}
          handlePdAdd = {this.handlePdAdd}
          />)});
      }

      return (
        <div className="appcontainer">
          {this.state.dateSelect ? <DatePick handleDate = {this.handleDate} cyear={this.selectedYear} cmonth={this.selectedMonth} cday={this.selectedDate}/> : null}
          
          {
            this.state.addOrder !== 0 ? (
              <MnuOrderAdd 
                mode = {this.state.requestMode}
                resultMode = {this.state.addOrder === 1  ? 0 : 1}
                orderIdx = {this.editOrderIdx}
                salesIdx = {this.state.salesIdx}
                handleClose = {this.handleCloseAddOrder}
                setLoading={this.setLoading}
                salesList={this.state.salesList}
                handleAddOrder = {this.handleRequestAddOrder}
                handleEditOrderItem = {this.handleEditOrderItem}
                showInfoText = {this.showAddInfo}
              />
            ) : null
          }

          {
            this.state.loading ? <Loading />:null
          }

          {
            this.state.addInfo ? <MnuOrderInfo desc1={this.addInfoText1} desc2={this.addInfoText2} /> :null
          }

          <div className="maincontainer">
            <div className="empmargin"/>
            <MainMenu index={12} handleMenuClick = {this.handleMenuClick}/>

            <div className="mnuoddatearea flexcentercol">
              <div className="mnuoddateareaup flexcenterrowstart marginbottom15 margintop15">
                <img src={GetImgUrl("mnuod1.png")} alt="수기주문" className="marginleft10 marginright10"/>
                <label className="fontlargeb">수기 주문 등록</label>
              </div>
              <div className="mnuoddateareadown flexcenterrowstart marginbottom15">
                <img src={GetImgUrl("search.png")} alt="돋보기" className="marginleft10 marginright10"/>
                <label className="fontnwb marginright10">날짜별 수기 주문 조회</label>

                <div className="mnuoddate flexcenterrow marginright10 btncur" onClick={this.handleSelectDate}>
                  <label className="fontnb btncur">{this.state.dateString}</label>
                </div>

                <button className="mnuoddatesel fontnb btncur" onClick={this.handleSelectDate}>조회</button>
              </div>
            </div>

            <div className="mnuodlist1 flexcenterrowstart marginbottom15">
              <div className="cnthalfh flexcenterrowstart">
                <img src={GetImgUrl("statspp.png")} alt="종이" className="marginleft10 marginright10"/>
                <label className="fontlargeb">수기 주문 내역</label>
              </div>

              <div className="cnthalfh flexcenterrowend">
                {
                  !this.isPrevDay() ? (
                    <button className="bgcolor3 mnuodaddbtn fontnwb btncur" onClick={this.handleAddOrder}>주문 추가하기</button>  
                  ) : null
                }
              </div>
            </div>

            <div className="mnuodline"></div>

            <div className="flexcenterrow marginbottom15 margintop15 mnuodlist2">
              <div className='flexcenterrow'>
                <img src={GetImgUrl("printday.png")} alt="달력" className="marginleft10 marginright10"/>
                <label className="fontlargeb">{this.getDateInfo()}</label>
              </div>
              {
                LoginStoreInstance.getUserLevel === 3 ? (
                  <div className='odsalesselarea flexcenterrow'>
                    <select className="odsalessel fontn"
                      value={this.state.salesIdx.toString()}
                      onChange={(e) => {
                        if (LOG_ENABLE)
                          console.log(e.target.value);
      
                        this.handleSalesSelected(parseInt(e.target.value));
                      }}
                    >
                      {salesList}
                    </select>
                  </div>
                ) : null
              }
            </div>

            <div className="mnuodline"></div>

            {orderList != null && orderList.length > 0 ? orderList : ("해당 일자의 주문이 없습니다.")}
            
            {
              this.state.pageCount > 1 ? 
                <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
  }
}

export default observer(MnuOrder);