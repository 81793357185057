import { forwardRef } from "react";
import { LOG_ENABLE, mobileOn } from "../../URL/AppUrl";
import ChatTalkItem from "./ChatTalkItem";
import ChatTalkItemmb from "./ChatTalkItemmb";

//채팅 내용리스트
const ChatTalkList = forwardRef((props, ref) =>
{
  let chatList = props.talkList;

  if (mobileOn)
  {
    if (props.list !== undefined && props.list !== null && props.list.length > 0)
    {
      chatList = props.list.map(item => {
        return (<ChatTalkItemmb key={item.idx} item={item} searchedIdx={props.searchedIdx} handleDownload = {props.handleDownload}/>);
      });
    }

    if (LOG_ENABLE)
      console.log(`talk height : ${props.height}`);
      
    return (
      <div className="chattalklistmb flexcentercolstart" onScroll={props.handleScroll} ref={ref} style={{height : props.height}}>
        {chatList}
      </div>
    );
  }
  else
  {
    if (props.list !== undefined && props.list !== null && props.list.length > 0)
    {
      chatList = props.list.map(item => {
        return (<ChatTalkItem key={item.idx} item={item} searchedIdx={props.searchedIdx} handleDownload = {props.handleDownload}/>);
      });
    }

    return (
      <div className="chattalklist flexcentercolstart" onScroll={props.handleScroll} ref={ref}>
        {chatList}
      </div>
    );
  }
  
});

export default ChatTalkList;