import React from "react";
import { ODS_ACCEPT, ODS_DELIE, ODS_REORDER, ODS_UNDELI } from "../../Stores/OrderStore";
import { GetImgUrl, GetSoundUrl, LOG_ENABLE } from "../../URL/AppUrl"
import { GetDataTime } from "../../Util/Util";
import OrderItemListvo from "./OrderItemListvo";

class OrderVoice extends React.Component
{
  state = {
    playing : false,
  };

  audioData = null;

  componentDidMount()
  {
    
  }

  componentWillUnmount()
  {
    if (this.audioData != null)
    {
      this.audioData.pause();
      this.audioData = null;
    }
  }

  initSound = () =>
  {
    this.audioData = new Audio(GetSoundUrl(this.props.order.sfile));
    this.audioData.load();
    
    this.audioData.addEventListener('loadeddata', () => {
      //this.setState((prevState) => ({...prevState, time : this.getAudioTime()}));
    });

    this.audioData.addEventListener('playing', () => {

      if (LOG_ENABLE)
        console.log("audio playing");

      this.audioPlaying = true;
      this.setState((prevState) => ({...prevState, playing : true}));
    });

    this.audioData.addEventListener('pause', () => {

      if (LOG_ENABLE)
        console.log("audio paused");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, playing : false}));
    });

    this.audioData.addEventListener('ended', () => {

      if (LOG_ENABLE)
        console.log("audio ended");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, playing : false}));
    });

    this.audioData.addEventListener('error', () => {
      if (LOG_ENABLE)
        console.log("audio error");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, playing : false}));
    });
  }

  playVoice = () =>
  {
    if (this.audioData == null || !this.state.playing)
    {
      if (this.audioData == null)
        this.initSound();
        
      this.audioData.currentTime = 0;
      this.audioData.play();
    }
    else
      this.audioData.pause();
  }

  getOrderTitle = (order) =>
  {
    if (order.st === ODS_UNDELI)
      return `음성주문(${GetDataTime(order.rdate)})`;
    
    return '음성주문';
  }

  getCompanyName = (order) =>
  {
    if (order.ct === 0)
      return `${order.cname}(유흥)`;
    return `${order.cname}(일반)`;
  }

  getOrderStatusMsg = (order) =>
  {
    switch(order.st)
    {
      case ODS_ACCEPT:
        return "이주문은 아직 처리되지 않았습니다. 음성 내용을 청취 후 상품을 등록해주세요.";

      case ODS_REORDER:
        return "재주문 요청됨";

      default:
        return "상품등록완료";
    }
  }

  render()
  {
    return (
      <div className = "ordertablecont">
  
        <div className="empsertitle flexcenterrowstart">
  
          <div className="orderadditemcontl flexcenterrowstart">
            <img src={GetImgUrl("vplayx.png")} alt="음성주문" />
            <label className={(this.props.order.st === ODS_ACCEPT || this.props.order.st === ODS_UNDELI || this.props.order.st === ODS_REORDER) ? "orderverrfont" : "fontlargeb"}>&nbsp;{this.getOrderTitle(this.props.order)}</label>
            <label className={(this.props.order.st === ODS_UNDELI || this.props.order.st === ODS_REORDER) ? "rptsubmsg" : "rptsubmsgn"}>&nbsp;{`주문번호 ${this.props.order.idx}`}</label>
            {
              this.props.order.st !== ODS_UNDELI ?
                (
                  <label className={(this.props.order.st === ODS_ACCEPT || this.props.order.st === ODS_REORDER) ? "orderverrfonts" : "ordervnfonts"}>&nbsp;{this.getOrderStatusMsg(this.props.order)}</label>
                ):null
            }
          </div>
        
          {
            this.props.order.st === ODS_UNDELI ? (
              <div className="orderadditemcontr flexcenterrowend">
                <button className="bgcolor3 orderundelibtn fontnwb" onClick={() => this.props.handleUndeliOrder(this.props.order.idx, 0)}>
                  당일 주문 처리
                </button>

                <button className="bgcolor4 orderadditembtn fontnwb" onClick={() => this.props.handleUndeliOrder(this.props.order.idx, 1)}>
                  익일 주문처리
                </button>
              </div>
            ):
            (
              <div className="orderadditemcontr flexcenterrowend">
                {
                  this.props.order.st !== ODS_DELIE && this.props.order.st !== ODS_REORDER ? (
                    <button className="bgcolor3 orderadditembtn fontnwb" onClick={() => this.props.handleMoveOrder(this.props.order.idx)}>
                      주문일자변경
                    </button>
                  ) : null
                }

                {
                  this.props.order.st === ODS_ACCEPT ? (
                    <button className="bgcolor2 orderadditembtn fontnwb" onClick={() => this.props.handleReorder(this.props.order.idx)}>
                      재주문 요청
                    </button>
                  ):
                  null
                }

                {
                  this.props.order.st === ODS_ACCEPT ? (
                    <button className="bgcolor1 orderadditembtn fontnwb" onClick={() => this.props.addItemCompleted(this.props.order.idx)}>
                      상품 추가완료
                    </button>
                  ):
                  null
                }

                {
                  this.props.order.st !== ODS_REORDER ? (
                    <button className="bgcolor3 orderadditembtn fontnwb" onClick={() => this.props.addItem(this.props.order.idx)}>
                      상품 추가하기
                    </button>
                  ) : null
                }
              </div>
            )
          }
        </div>

        <table className="emptable">
          <thead>
            <tr>
              <th className="emptableheader">
                <label className="fontnw">업체명</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">대표자명</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">휴대폰</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">주소</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">영업 담당자</label>
              </th>
  
              <th className="emptableheader">
                <label className="fontnw">녹음 재생</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="emptabledata">
              <td className="ordercellcnamevo">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.getCompanyName(this.props.order)}</label>
                </div>
              </td>
              
              <td className="ordercellunamevo">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.props.order.uname}</label>
                </div>
              </td>
  
              <td className="ordercellphonevo">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.props.order.phone}</label>
                </div>
              </td>
  
              <td className="emptablecell">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.props.order.addr}</label>
                </div>
              </td>
  
              <td className="ordercellsnamevo">
                <div className="emptablecellin flexcenterrow">
                  <label className="fontn">{this.props.order.sname}</label>
                </div>
              </td>
  
              <td className="ordercellvoice">
                <div className="emptablecellin flexcenterrow">
                  <img src={GetImgUrl(this.state.playing ? "stop.png" : "play.png")} className="btncur" onClick={this.playVoice} alt="재생"/>
                </div>
              </td>
  
            </tr>
          </tbody>
        </table>
  
        {
          this.props.order.items !== undefined && this.props.order.items !== null && this.props.order.items.length > 0 ? 
            <OrderItemListvo itemList = {this.props.order.items} order = {this.props.order} handleEditItem={this.props.handleEditItem} 
              handleDeleteItem={this.props.handleDeleteItem}/> : null
        }
  
        {/*<div className="orderline"/>*/}
      </div>
    );
  }
}

export default OrderVoice;