import { Fragment } from "react";
import { LOG_ENABLE } from "../../URL/AppUrl";
import { GetItemListmb } from "./ReportUtil";
import { ODS_DELIE } from "../../Stores/OrderStore";

function GetRetailerName (order)
{
  if (order.orderType === 0)
    return order.cname;

  return `${order.cname}(수기주문)`;
}

function GetDeliMode(order)
{
  switch(order.printType)
  {
    case 1:
      return "배송1";
    case 2:
      return "배송2";
    case 3:
      return "배송3";
    default:
      return "";
  }
}

function GetDeliFontColor(order)
{
  switch(order.printType)
  {
    case 1:
      return "mfontnl";
    case 2:
      return "mfontng";
    case 3:
      return "mfontno";
    default:
      return "fontn";
  }
}

const DeliveryBtn = (props) =>
{
  return (
    <div className="rpttablecont2mb flexcenterrow">
      {
        props.order.st !== ODS_DELIE ? (
          <button className="bgcolor3 repdelidonebtnmb mfontnwb" onClick={() => props.handleDelivery(props.order.idx)}>
            배송완료
          </button>
        ) 
        : (
          <label className="rptdelidonefontmb">상품 배송 완료</label>
        )
      }
    </div>
  );
}

//아직 배송전일경우의 아이템 출력
const ReportItemmb = (props) =>
{
  if (LOG_ENABLE)
    console.log("ReportItem : " + JSON.stringify(props.order));

  const items = GetItemListmb(props.order, props.popt);
  let displayItem = null;

  if (items !== null && items.length > 0)
  {
    let keyIndex = 1;

    displayItem = items.map(item => {
      return (
        <Fragment key={++keyIndex}>
          {item}<br/>
        </Fragment>
      );
    });
  }

  return (
    <div className="cntfullh marginbottom15" key={props.order.idx}>
      <div className="rpttablemb flexcenterrow">
        <label className="mfontnw">거래처명</label>
      </div>

      <div className="rpttablecontmb flexcenterrow">
        <label className={props.order.orderType === 0 ? "mfontn" : "mfontn2"}>{GetRetailerName(props.order)}</label>
      </div>

      <div className="rpttablemb flexcenterrow">
        <label className="mfontnw">상품명</label>
      </div>

      <div className="rpttablecont1mb flexcentercol mfontn">
        {displayItem}
      </div>

      <div className="flexcenterrow mfontn rpttablecont1mb2">
        {
          props.order.printType === 0 ? (
            <Fragment>
              <button className="repdeliselbtnmb mfontnwb bgcolor8 btncur" onClick={() => props.handleDeliMode(1, props.order)}>배송1</button>
              <button className="repdeliselbtnmb mfontnwb bgcolor6 btncur" onClick={() => props.handleDeliMode(2, props.order)}>배송2</button>
              <button className="repdeliselbtnmb mfontnwb bgcolor7 btncur" onClick={() => props.handleDeliMode(3, props.order)}>배송3</button>
            </Fragment>
          ): null
        }

        {
          props.order.printType !== 0 ? (
            <div className="flexcenterrow cntfullh">
              <div className="rptdelisellf"></div>
              
              <div className="flexcenterrow rptdeliselcn">
                <label className={GetDeliFontColor(props.order)}>{GetDeliMode(props.order)}</label>
              </div>
              
              <div className="flexcenterrow rptdeliselrt">
                <button className="repdeliselbtn2mb mfontnwb bgcolor1 btncur" onClick={() => props.handleDeliMode(0, props.order)}>배송 수정</button>
              </div>
            </div>
          ): null
        }
      </div>

      {
        props.delis ? (
          <div className="rpttablecont1mb3 flexcentercol mfontn">
            {
              props.order.orderType === 0 ? (
                <DeliveryBtn order = {props.order} handleDelivery = {props.handleDelivery}/>
              ): null
            }

            {
              props.order.orderType === 1 ? (
                <DeliveryBtn order = {props.order} handleDelivery = {props.handleDeliveryMnuOrder}/>
              ): null
            }
          </div>
        ) : null
      }
      
    </div>
  );
}

export default ReportItemmb;