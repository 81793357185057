import { observer } from 'mobx-react';
import React from 'react';
import { AlarmStoreInstance } from '../../Stores/AlarmStore';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { ProcessLogout } from '../../Stores/StoreManager';
import { LOG_ENABLE } from '../../URL/AppUrl';
import { IsValidS } from '../../Util/Util';
import Loading from '../Loading/Loading';
import MenuMb from '../Menu/MenuMb';
import "./Alarm.css";
import AlarmItem from './AlarmItem';

class Alarm extends React.Component
{
  state = {
    loading : false,
    appOn : false,
  };

  setLoading = (loading) =>
  {
    this.setState((prevState) => ({...prevState, loading : loading}));
  }

  componentDidMount = () =>
  {
    if (LOG_ENABLE)
      console.log("Alarm.componentDidMount");

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 10}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
      return;
    }
    else if (!AlarmStoreInstance.loading && !AlarmStoreInstance.defaultDone)
      AlarmStoreInstance.loadDefaultAlarm(LoginStoreInstance.compIdx, LoginStoreInstance.userIdx);
  }

  componentWillUnmount = () =>
  {
    AlarmStoreInstance.clearNewMark();

    if (LOG_ENABLE)
      console.log("Chat.componentWillUnmount");

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    if (LOG_ENABLE)
      console.log(`onProfileLoaded : ${resultCode}`);

    this.setLoading(false);

    if (resultCode !== 0)
    {
      
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    if (!AlarmStoreInstance.loading && !AlarmStoreInstance.defaultDone)
      AlarmStoreInstance.loadDefaultAlarm(LoginStoreInstance.compIdx, LoginStoreInstance.userIdx);
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : "backhandler"}));

    //채팅중이면
    if (this.state.loading || AlarmStoreInstance.loading)
    {
      return;
    }

    this.props.history.push("/main");
  }

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        this.props.history.push("/order");  
        break;
      case 4: //판매일보
        this.props.history.push("/report"); 
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }

  handleDelete = (alarmIdx) =>
  {

  }

  handleNext = () =>
  {
    if (AlarmStoreInstance.loading)
    {
      return;
    }

    AlarmStoreInstance.loadNextAlarm();
  }

  handleLogout = () =>
  {
    ProcessLogout();
    this.props.history.push("/"); 
  }

  render()
  {
    const alarmList = AlarmStoreInstance.getAlarms;

    let alarmInfo = null;

    if (IsValidS(alarmList))
    {
      alarmInfo = alarmList.map(item => {
        return (<AlarmItem key={item.idx} item = {item} handleDelete = {this.handleDelete}/>);
      });

      if (!AlarmStoreInstance.done)
        alarmInfo.push((<AlarmItem key={-1} item = {null} handleNext = {this.handleNext}/>));
    }

    return (
      <div className="appcontmb flexcentercol">
        {
          (this.state.loading || AlarmStoreInstance.loading) ? <Loading />:null
        }

        <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout={this.handleLogout}/>
        <label className="mfontlargeb alarmtitle">알림</label>
        <div className="alarmcont flexcentercolstart">
          {alarmInfo}

          {
            alarmInfo === null ? (
              <label className="mfontnb noalarm">현재 수신된 알림이 없습니다.</label>
            ):null
          }
        </div>
      </div>
    );
  }
}

export default observer(Alarm);