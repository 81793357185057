import { ProductStoreInstance } from "../../Stores/ProductStore";
import { GetProductUnitName, GetProductUsesName } from "../../Util/Util";
import "./Product.css";

const ProductDetmb = (props) =>
{
  return(
    <div className="pddetcontmb flexcenterrow">
      <div className="pddetpopmb flexcentercolstart">
        <div className="pddettitle flexcenterrow">
          <label className="mfontnb">상품 상세정보 확인</label>
        </div>

        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">판매분류</label>
          <label className="mfontn">{ProductStoreInstance.getCategoryNameByCode(props.item.iid)}</label>
        </div>

        <div className='pdcominfoline'></div>

        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">품목코드</label>
          <label className="mfontn">{props.item.icode}</label>
        </div>
        
        <div className='pdcominfoline'></div>
        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">품목명</label>
          <label className="mfontn">{props.item.iname}</label>
        </div>

        <div className='pdcominfoline'></div>
        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">단축명</label>
          <label className="mfontn">{props.item.alias === "n" ? "없음" : props.item.alias}</label>
        </div>

        <div className='pdcominfoline'></div>
        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">규격(용량)</label>
          <label className="mfontn">{props.item.ivol}</label>
        </div>

        <div className='pdcominfoline'></div>
        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">취급단위</label>
          <label className="mfontn">{GetProductUnitName(props.item.unit)}</label>
        </div>

        <div className='pdcominfoline'></div>
        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">용도</label>
          <label className="mfontn">{GetProductUsesName(props.item.uses)}</label>
        </div>

        <div className='pdcominfoline'></div>
        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">도수</label>
          <label className="mfontn">{props.item.freq}</label>
        </div>

        <div className='pdcominfoline'></div>
        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">입수</label>
          <label className="mfontn">{props.item.cnt}</label>
        </div>

        <div className='pdcominfoline'></div>
        <div className="flexcenterrow pddetinfomb">
          <label className="mfontn">판매가</label>
          <label className="mfontn">{`${props.item.price}원`}</label>
        </div>

        <button className="pddetbtnmb mfontnwb btncur bgcolor4" onClick={() => props.handleCloseDetail()}>확인</button>
      </div>
    </div>
  );
}

export default ProductDetmb;