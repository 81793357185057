import { Fragment } from "react";
import { ODS_UNDELI } from "../../Stores/OrderStore";
import { GetImgUrl } from "../../URL/AppUrl";
import { GetDataTime, IsValidS } from "../../Util/Util";
import OrderItemListvomb from "./OrderItemListvomb";

function GetOrderTitle(order)
{
  if (order.st === ODS_UNDELI)
    return `APP주문(${GetDataTime(order.rdate)})`;
  
  return `APP주문`;
}

function GetCompanyName(order)
{
  if (order.ct === 0)
    return `${order.cname}(유흥)`;
  return `${order.cname}(일반)`;
}

const OrderAppmb = (props) =>
{
  return (
    <div className = "ordertablecontmb">
      <div className="empsertitle flexcenterrowstart">
        
        <div className="orderadditemcontlmb flexcenterrowstart">
          <img src={GetImgUrl("mbodphone2.png")} alt="앱주문" />
          <label className={props.order.st === ODS_UNDELI ? "odundelititlefontmb" : "mfontnb"}>&nbsp;{GetOrderTitle(props.order)}</label>
        </div>

        
        <div className="orderadditemcontrmb flexcenterrowend">
          {
            props.order.st === ODS_UNDELI ?
            (
              <Fragment>
                <button className="bgcolor3 orderundelibtnmb mfontnwb" onClick={() => props.handleUndeliOrder(props.order.idx, 0)}>
                  당일주문처리
                </button>

                <button className="bgcolor4 orderadditembtnmb mfontnwb" onClick={() => props.handleUndeliOrder(props.order.idx, 1)}>
                  익일주문처리
                </button>
              </Fragment>
            ):null
          }
        </div>
      </div>

      <table className="emptablemb">
        <thead>
          <tr>
            <th className="emptableheadermb">
              <label className="mfontnw">업체명</label>
            </th>

            <th className="emptableheadermb">
              <label className="mfontnw">상세정보</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="emptabledata">
            <td className="ordercellcnamemb">
              <div className="emptablecellinmb flexcenterrow">
                <label className="fontn">{GetCompanyName(props.order)}</label>
              </div>
            </td>
            
            <td className="ordercellunamemb">
              <div className="emptablecellinmb flexcenterrow">
                <button className="oddetappbtnmb mfontnwb btncur" onClick = {() => props.handleDetail(props.order)}>상세정보</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      { IsValidS(props.order.items) ? <OrderItemListvomb itemList = {props.order.items}  order = {props.order} handleEditVoItem={props.handleEditVoItem}/> : null}

      {/*<div className="orderline"/>*/}
    </div>
  );
};

export default OrderAppmb;