
//검색된 업체
const ChatSearchItem = (props) =>
{
  return (
    <div className="chatcompitem flexcentercol">
      <div className="chatseritemtop flexcenterrowstart">
        <label className="fontlargeb">{props.item.cname}</label>
      </div>

      <div className="chatseritemcenter flexcenterrowstart">
        <label className="chatseritemaddrfont fontn">{props.item.addr}</label>
      </div>

      <div className="chatseritembottom flexcenterrowend">
        <button className="bgcolor1 chatseritembtn fontnwb" onClick={() => props.handleStartTalk(props.item.aidx)}>
          대화 시작
        </button>
      </div>

    </div>
  );
}

export default ChatSearchItem;