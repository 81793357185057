import React from 'react';
import './Staff.css';
import '../../App.css';
import { IsValidS } from '../../Util/Util';
import { LOG_ENABLE, mobileOn } from '../../URL/AppUrl';
import { observer } from 'mobx-react';
import { RecmStoreInstance } from '../../Stores/RecmStore';
import Loading from '../Loading/Loading';

//특정 추천인의 가입자를 다른 추천인으로 일괄이동
class RecmTransAll extends React.Component {
  state = {
    loading: false,
    selectedRecmIdx: -1, //선택된 추천인 고유번호
  };

  visiblePageNum = 10;

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setSelectedRecmIdx = (v) =>
  {
    this.setState((prevState) => ({...prevState, selectedRecmIdx : v}));
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("RecmTransAll.componentDidMount");
    
    if (mobileOn)
      this.visiblePageNum = 3;
  }

  componentWillUnmount = () =>
  {
  }

  //추천인 변경실행
  handleDoChange = () =>
  {
    if (this.state.loading)
      return;

    if (this.props.item.idx === this.state.selectedRecmIdx)
    {
      alert(`선택한 추천인과 이전할 추천인이 같습니다.`);
      return;
    }

    const list = RecmStoreInstance.userList;

    const recmInfo = RecmStoreInstance.findRecmByIdxFromAllList(this.state.selectedRecmIdx);

    if (recmInfo === null)
    {
      alert(`가입자를 이전할 추천인을 선택해 주세요.`);
      return;
    }

    if (!window.confirm(`선택된 가입자를 ${recmInfo.nm}님에게 이전할까요?`))
      return;

    this.setLoading(true);

    RecmStoreInstance.transUser(0, this.props.item.idx, this.state.selectedRecmIdx, null, this.onTransDone);
  }

  onTransDone = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`가입자 이전 처리중 오류가 발생했습니다.`);

      this.loadList(1);
      return;
    }

    alert(`선택하신 가입자의 이전이 완료되었습니다.`);
    this.props.handleClose();
  }

  getAllRecmListOption = () =>
  {
    const list = RecmStoreInstance.recmAllList;

    if (!IsValidS(list))
      return null;

    const optList = list.map(item => {
      return (<option key={item.idx} value={item.idx.toString()}>{`${item.cc}(${item.nm})`}</option>);
    });

    optList.unshift((<option key={-1} value="-1">추천인을 선택하세요.</option>));

    return optList;
  }

  render()
  {
    return (
      <div className='recmtranspopbg flexcenterrow'>
        {
          this.state.loading ? (<Loading />) : null
        }

        <div className='recmtranspop2 flexcentercolstart'>
        
          <div className='cntfullh flexcenterrow marginbottom10 margintop20'>
            <label className='fontlargeb'>{`"${this.props.item.nm}"님 가입자 일괄 이전`}</label>
          </div>

          <div className='cntfullh flexcenterrow marginbottom10 margintop20'>
            <label className='fontn'>가입자 전체</label>
            <label className='fontn3'>&nbsp;{`${this.props.transCount}`}</label>
            <label className='fontn'>{`개를`}&nbsp;</label>

            <select className="recmselect fontn" 
              onChange={(e) => {
                  console.log(e.target.value);
                  this.setSelectedRecmIdx(parseInt(e.target.value));
              }}

              value={this.state.selectedRecmIdx.toString()}
            >
              {this.getAllRecmListOption()}
            </select>

            <label className='fontn'>&nbsp;추천인으로 이전합니다.&nbsp;</label>

          </div>

          <div className='flexcenterrow recmtransallbtn spacebetween margintop20'>
            <button className='recmchgbtn2 fontnwb btncur bgcolor4' onClick={() => this.props.handleClose()}>취소</button>
            <button className='recmchgbtn2 fontnwb btncur bgcolor3' onClick={this.handleDoChange}>변경완료</button>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(RecmTransAll);