import './MnuOrder.css';

function GetBtnColor(props, item)
{
  if (props.selectedIdx === -1)
    return "mnuodselretonmb mfontnwb btncur bgcolor1";
  else if (props.selectedIdx === item.idx)
    return "mnuodselretonmb mfontnwb btncur bgcolor3"

  return "mnuodselretonmb mfontnwb btncur bgcolor4"
}

const MnuOrderCompItemmb = (props) =>
{
  return (
    <div className="flexcentercol mnuodcompitemmb">
      <label className="mfontnb">{props.item.cname}</label>
      <label className="mfontn">{props.item.addr}</label>

      <button className= { GetBtnColor(props, props.item)} onClick={() => props.handleSelect(props.item)}>선택</button>
    </div>
  );
}

export default MnuOrderCompItemmb;