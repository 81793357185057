
const MnuOrderInfo = (props) =>
{
  return (
    <div className="flexcentercol mnuorderinfobg">
      <label className="fontnwb">{props.desc1}</label>
      <label className="fontnwb">{props.desc2}</label>
    </div>
  );
};

export default MnuOrderInfo;