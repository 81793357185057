import { observer } from "mobx-react";
import React from "react";
import { LoginStoreInstance } from "../../Stores/LoginStore";
import { ProductStoreInstance } from "../../Stores/ProductStore";
import { ProcessLogout } from "../../Stores/StoreManager";
import { GetImgUrl, LOG_ENABLE, mobileOn } from "../../URL/AppUrl";
import { IsValidS } from "../../Util/Util";
import "../Emp/Staff.css";
import PageList from "../PageList";
import "./Product.css";
import ProductInput from "./ProductInput";
import ProductInputmb from "./ProductInputmb";
import ProductList from "./ProductList";
import ProductListmb from "./ProductListmb";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

class ProductListMode extends React.Component
{

  state = {
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 1, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 0, //전체 페이지 개수
    searchMode : false,
    iid : -1,  //판매 카테고리
  };

  iid = -1; 
  searchType = 0;
  maxPageNum = 10;

  //상품 조회 카테고리를 변경함
  setProductCategory = (cat) =>
  {
    this.iid = cat;
    this.setState((prevState) => ({...prevState, iid : cat, currentPage: 1, searchMode : false}));
  }

  handleProductCategory = (cat) =>
  {
    this.setProductCategory(cat);
    this.loadProduct(1);
  }

  componentDidMount = () =>
  {
    if (mobileOn)
      this.maxPageNum = 3;

    //프로필 정보가 없으면 읽기
    if (!LoginStoreInstance.isProfileReady)
    {
      this.props.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);

      return;
    }
    
    this.iid = -1; 

    this.loadProductCategory();
  }

  componentWillUnmount = () =>
  {

  }

  onLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.props.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    this.loadProductCategory();
  }

  loadProductCategory = () =>
  {
    if (!this.state.loading)
      this.props.setLoading(true);

    ProductStoreInstance.loadCategory(this.onCategoryLoaded);
  }

  onCategoryLoaded = (resultCode) =>
  {
    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        this.props.setLoading(false);
        return;
      default:
        alert("상품 분류 정보를 읽는중 오류가 발생했습니다.");
        this.props.setLoading(false);
        return;
    }

    this.loadProduct(1);
  }

  loadProduct = (pageNum) =>
  {
    if (!this.state.loading)
    this.props.setLoading(true);

    ProductStoreInstance.loadProduct("0", this.iid, (pageNum - 1) * 20, this.onloadProduct);
  }

  onloadProduct = (resultCode, isFirst) =>
  {
    this.props.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        return;
      default:
        alert("제폼 목록 조회에 실패 했습니다.");
        return;
    }

    if (isFirst)
    {
      this.calcPageInfo();
    }
  }

  calcPageInfo = () =>
  {
    let currentCount = ProductStoreInstance.totalCount;

    let pageCount = Math.floor(currentCount / 20);

    if ((currentCount % 20) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.maxPageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4

  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = this.maxPageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage, searchMode : false}));

      this.loadProduct(1);
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage, searchMode : false}));

      this.loadProduct(startPage);
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage, searchMode : false}));

      this.loadProduct(startPage);
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage, searchMode : false}));

      this.loadProduct(endPage);
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum, searchMode : false}));

      this.loadProduct(pageNum);
    }
  }

  //검색 버튼 눌림
  handleSearch = () =>
  {
    if (LOG_ENABLE)
      console.log("handleSearch");

    if (!IsValidS(ProductStoreInstance.searchWord))
    {
      alert("검색어를 입력하세요.");
      return;
    }

    this.setState((prevState) => ({...prevState, searchMode : true}));

    this.props.setLoading(true);

    switch(this.searchType)
    {
      case 0: //품목 코드로 검색
        ProductStoreInstance.searchProductByItemCode(this.onSearchResult);
        break;
      case 1: //품목명으로 검색
        ProductStoreInstance.searchProduct(-1, this.onSearchResult);
        break;
      default:
        break;
    }
  }

  onSearchResult = (resultCode) =>
  {
    this.props.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      default:
        alert("검색중 오류가 발생했습니다");
        break;
    }
  }

  //검색창에서 목록 보기창으로 바꾸는 경우
  handleList = () =>
  {
    if (LOG_ENABLE)
      console.log("handleList");

    this.iid = -1;

    this.setState((prevState) => ({...prevState, searchMode : false}));

    this.loadProduct(1);

    if (this.props.handleListMode)
      this.props.handleListMode();
  }

  //검색 카테고리 변경됨
  handleCategory = (category) =>
  {
    if (LOG_ENABLE)
      console.log("handleCategory : " + category);

    this.searchType = category;
  }

  handleEdit = () =>
  {
    if (this.props.handleEdit)
      this.props.handleEdit(this.state.searchMode);
  }

  handleSelectAndEdit = (itemIdx) =>
  {
    if (this.props.handleSelectAndEdit)
      this.props.handleSelectAndEdit(itemIdx, this.state.searchMode);
  }

  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.handleSearch();
    }
  }

  handleWriteExcel = () =>
  {
    if (LOG_ENABLE)
      console.log("handleWriteExcel");

    if (this.props.appOn)
    {
      alert('앱에서는 상품 출력이 제공되지 않습니다. PC또는 모바일브라우저를 이용해 주세요.');
      return;
    }

    if (ProductStoreInstance.totalLoading)
      return;

    if (ProductStoreInstance.totalListDone)
      this.exportProductExcel();
    else
    {
      this.props.setLoading(true);
      ProductStoreInstance.clearTotalList();
      ProductStoreInstance.loadProduct(1, 0, ProductStoreInstance.totalListIdx, this.onTotalProductLoadDone);
    }
  }

  onTotalProductLoadDone = (resultCode, moreProduct) =>
  {
    if (resultCode !== 0)
    {
      this.props.setLoading(false);
      alert("상품 정보 조회중 오류가 발생했습니다.");
      return;
    }

    if (!moreProduct)
    {
      this.props.setLoading(false);
      this.exportProductExcel();
      return;
    }

    ProductStoreInstance.loadProduct(1, 0, ProductStoreInstance.totalListIdx, this.onTotalProductLoadDone);
  }

  exportProductExcel = () =>
  {
    if (LOG_ENABLE)
      console.log("exportProductExcel");

    try
    {
      let temp = [];

      const workBook = new ExcelJS.Workbook();
      workBook.creator = 'Bravo6';
      workBook.created = new Date();
      workBook.modified = new Date();
  
      ProductStoreInstance.totalListToExcelValue(temp);
  
      let sheet = workBook.addWorksheet('상품목록', {properties:{tabColor:{argb:'FFFC0000'}, outlineLevelCol:1}});
      this.makeSheet(sheet, temp);
  
      workBook.xlsx.writeBuffer().then(function (buffer){
        saveAs(new Blob([buffer], {type: "application/octet-stream"}), `${LoginStoreInstance.getCompanyName}_상품목록.xlsx`);
      });
    }
    catch(e)
    {
      console.log(e.toString());

      alert("상품 정보 출력중 오류가 발생했습니다.");
    }
  }

  makeSheet = (workSheet, valueList) =>
  {
    workSheet.columns = [
      { header: '회사고유번호', key: 'cidx'},
      { header: '판매분류', key: 'iid'},
      { header: '품목코드', key: 'icode'},
      { header: '품목명', key: 'iname'},
      { header: '단축명', key: 'alias'},
      { header: '용량', key: 'ivol'},
      { header: '취급단위', key: 'unit'},
      { header: '용도', key: 'uses'},
      { header: '도수', key: 'freq'},
      { header: '입수', key: 'cnt'},
      { header: '가격', key: 'price'},
      { header: '이미지', key: 'img'},
      { header: '상품고유번호', key: 'idx'},
    ];

    let border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };

    let row = workSheet.getRow(1);
    let fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'ff797979'},
    };

    row.font = {bold : true, color : {argb: 'ffffffff'}};
    row.alignment = {vertical: 'center', horizontal: 'center'};
    //row.fill = fill;

    row.getCell('cidx').border = border;
    row.getCell('iid').border = border;
    row.getCell('icode').border = border;
    row.getCell('iname').border = border;
    row.getCell('alias').border = border;
    row.getCell('ivol').border = border;
    row.getCell('unit').border = border;
    row.getCell('uses').border = border;
    row.getCell('freq').border = border;
    row.getCell('cnt').border = border;
    row.getCell('price').border = border;
    row.getCell('img').border = border;
    row.getCell('idx').border = border;

    row.getCell('cidx').fill = fill;
    row.getCell('iid').fill = fill;
    row.getCell('icode').fill = fill;
    row.getCell('iname').fill = fill;
    row.getCell('alias').fill = fill;
    row.getCell('ivol').fill = fill;
    row.getCell('unit').fill = fill;
    row.getCell('uses').fill = fill;
    row.getCell('freq').fill = fill;
    row.getCell('cnt').fill = fill;
    row.getCell('price').fill = fill;
    row.getCell('img').fill = fill;
    row.getCell('idx').fill = fill;
    
    if (valueList.length > 0)
    {
      fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'fff9f9f9'},
      };

      for (let i = 0;i < valueList.length; ++i)
      {
        row = workSheet.getRow(i + 2);
        row.values = valueList[i];
        row.alignment = {vertical: 'center', horizontal: 'center'};
        row.getCell('cidx').border = border;
        row.getCell('iid').border = border;
        row.getCell('icode').border = border;
        row.getCell('iname').border = border;
        row.getCell('alias').border = border;
        row.getCell('ivol').border = border;
        row.getCell('unit').border = border;
        row.getCell('uses').border = border;
        row.getCell('freq').border = border;
        row.getCell('cnt').border = border;
        row.getCell('price').border = border;
        row.getCell('img').border = border;
        row.getCell('idx').border = border;


        if ((i % 2) !== 0)
        {
          row.getCell('cidx').fill = fill;
          row.getCell('iid').fill = fill;
          row.getCell('icode').fill = fill;
          row.getCell('iname').fill = fill;
          row.getCell('alias').fill = fill;
          row.getCell('ivol').fill = fill;
          row.getCell('unit').fill = fill;
          row.getCell('uses').fill = fill;
          row.getCell('freq').fill = fill;
          row.getCell('cnt').fill = fill;
          row.getCell('price').fill = fill;
          row.getCell('img').fill = fill;
          row.getCell('idx').fill = fill;
        }
      }
    }

    this.autoWidthSheet(workSheet, 15);
  }
 
  autoWidthSheet = (worksheet, minimalWidth = 10) => {
    worksheet.columns.forEach((column) => {
        let maxColumnLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            maxColumnLength = Math.max(
                maxColumnLength,
                minimalWidth,
                cell.value ? cell.value.toString().length : 0
            );
        });
        column.width = maxColumnLength + 2;
    });
  }

  render()
  {
    if (mobileOn) //모바일웹
    {
      return (
        <div className="pdcontentmb flexcentercolstart">
          {/**타이틀 영역 */}
          <div className="pdtitlemb flexcenterrow">
            <label className="mfontlargeb">신규상품등록</label>
          </div>
    
          {/**검색 영역 */}
          {/* 
          <div className="pdsearchmb flexcenterrow">
          
            <div className="pdsearchtabmb flexcentercolend" onClick={() => this.props.handleTab(0)}>
              <label className= {(this.props.editMode === 0 || this.props.editMode === 2) ? "btncur mfontmb" : "btncur pdsearchfonttabmb"}>등록</label>
              <div className={(this.props.editMode === 0 || this.props.editMode === 2) ? "pdsearchtablinemb" : "pdsearchtablineselmb"}></div>
            </div>

            <div className="flexcentercolend pdvlinecontmb">
              <div className="ntctabvlinemb"></div>
            </div>
            

            <div className="pdsearchtabmb flexcentercolend"  onClick={() => this.props.handleTab(1)}>
              <label className={(this.props.editMode === 0 || this.props.editMode === 2) ? "btncur pdsearchfonttabmb" : "btncur mfontmb"}>조회</label>  
              <div className={(this.props.editMode === 0 || this.props.editMode === 2) ? "pdsearchtablineselmb" : "pdsearchtablinemb"}></div>
            </div>
          </div> */}
    
          <div className="pdhlinemb"></div>

          {/**검색 영역 */}
          {
            this.props.editMode === 0 ? 
            (
              <div className="flexcenterrow pdsearchmb2">
                
                <select className="pdsearchcatmb mfontnb"
                  onChange={(e) => {
                    if (LOG_ENABLE)
                      console.log(e.target.value);
      
                      this.handleCategory(parseInt(e.target.value));
                  }}
                >
                  <option value="0">품목코드</option>
                  <option value="1">품목명</option>
                </select>
      
                <input type="text" onChange={(e) => ProductStoreInstance.setSearchWord(e.target.value)} value = {ProductStoreInstance.searchWord} 
                  className="pdsearchinpmb mfontn"
                  onKeyPress={this.handleKeyPress}
                />
      
                <button className="pdsearchbtnmb mfontnb" onClick={this.handleSearch}>
                  검색
                </button>
              
                {
                  this.state.searchMode ? (
                  <button className="pdsearchbtnmb mfontnb" onClick={this.handleList}>
                    목록
                  </button>
                  ):null
                }
              </div>
            ):null
          }
          

          {/*리스트 모드인지, 편집모드인지에 따라서 컨트롤 선택 */}
          {
            this.props.editMode === 0 ? 
              <ProductListmb itemList={this.state.searchMode ? ProductStoreInstance.searchList : ProductStoreInstance.getProductList} 
                appOn = {this.props.appOn}
                itemCategory = {ProductStoreInstance.productCategory}
                selectedCategory={this.state.iid}
                handleProductCategory = {this.handleProductCategory}
                handleAddComPd = {this.props.handleAddComPd}
                handleWriteExcel = {this.handleWriteExcel}
                handleEdit={this.handleEdit} 
                handleDetail={this.props.handleDetail}
                handleSelect = {this.props.handleSelect} selectedIdx = {this.props.selectedIdx}/> : 
              <ProductInputmb itemIdx = {this.props.editItem.idx} iid={this.props.editItem.iid} icode={this.props.editItem.icode} iname={this.props.editItem.iname} 
                alias={this.props.editItem.alias} ivol={this.props.editItem.ivol} iunit={this.props.editItem.unit} iuses={this.props.editItem.uses} 
                ifreq={this.props.editItem.freq} icnt={this.props.editItem.cnt} img={this.props.editItem.img} price={this.props.editItem.price}  editMode = {true} handleEdit = {this.props.handleEditResult}/>
          }
  
          <div className="pdpageareamb">
            {
              ((this.props.editMode === 0) && (this.state.pageCount > 1) && !this.state.searchMode) ? 
                <PageList handlePage={this.handlePage} 
                  first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                  next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                  currentPage={this.state.currentPage} 
                  startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
          
        </div>
      );
    }
    else //pc웹
    {
      return (
        <div className="pdcontent flexcentercolstart">
          {/**타이틀 영역 */}
          <div className="pdtitle flexcenterrow">
            <label className="pdtitlefont">신규상품등록</label>
          </div>
    
          {/**검색 영역 */}
          <div className="pdsearch flexcenterrow">
          
            {/**검색 영역 왼쪽*/}
            <div className="pdsearchleft flexcenterrowstart">
    
              {/*
              <div className="pdsearchtab flexcentercolend" onClick={() => this.props.handleTab(0)}>
                <label className= {(this.props.editMode === 0 || this.props.editMode === 2) ? "pdsearchfonttab" : "btncur fontlargeb"}>등록</label>
                <div className={(this.props.editMode === 0 || this.props.editMode === 2) ? "pdsearchtabline" : "pdsearchtablinesel"}></div>
              </div>
    
              <div className="pdsearchtab flexcentercolend"  onClick={() => this.props.handleTab(1)}>
                <label className={(this.props.editMode === 0 || this.props.editMode === 2) ? "btncur fontlargeb" : "pdsearchfonttab"}>조회</label>  
                <div className={(this.props.editMode === 0 || this.props.editMode === 2) ? "pdsearchtablinesel" : "pdsearchtabline"}></div>
              </div>
              */}
            </div>
      
            {/**검색 영역 오른쪽*/}
            <div className="pdsearchright flexcenterrowend">
              <img src={GetImgUrl("searchpd.png")} alt="검색"/>
              <label className="fontnb">&nbsp;&nbsp;검색하기&nbsp;&nbsp;</label>
    
              <select className="pdsearchcat fontnb"
                onChange={(e) => {
                  if (LOG_ENABLE)
                    console.log(e.target.value);
    
                    this.handleCategory(parseInt(e.target.value));
                }}
              >
                <option value="0">품목코드</option>
                <option value="1">품목명</option>
              </select>
    
              <input type="text" onChange={(e) => ProductStoreInstance.setSearchWord(e.target.value)} 
                value = {ProductStoreInstance.searchWord} className="pdsearchinp fontn"
                onKeyPress={this.handleKeyPress}
              />
    
              <button className="pdsearchbtn fontnwb" onClick={this.handleSearch}>
                검색
              </button>
  
              {
                this.state.searchMode ? (
                <button className="pdsearchbtn fontnwb" onClick={this.handleList}>
                  목록
                </button>
                ):null
              }
  
            </div>
          </div>
    
          {/*리스트 모드인지, 편집모드인지에 따라서 컨트롤 선택 */}
          {
            this.props.editMode === 0 ? 
              <ProductList itemList={this.state.searchMode ? ProductStoreInstance.searchList : ProductStoreInstance.getProductList} 
                itemCategory = {ProductStoreInstance.productCategory}
                selectedCategory={this.state.iid}
                handleProductCategory = {this.handleProductCategory}
                handleEdit={this.handleEdit} 
                handleWriteExcel = {this.handleWriteExcel}
                handleSelectAndEdit = {this.handleSelectAndEdit}
                handleAddComPd = {this.props.handleAddComPd}
                handleSelect = {this.props.handleSelect} selectedIdx = {this.props.selectedIdx}/> : 

              <ProductInput itemIdx = {this.props.editItem.idx} iid={this.props.editItem.iid} icode={this.props.editItem.icode} iname={this.props.editItem.iname} 
                alias={this.props.editItem.alias} ivol={this.props.editItem.ivol} iunit={this.props.editItem.unit} iuses={this.props.editItem.uses} 
                ifreq={this.props.editItem.freq} icnt={this.props.editItem.cnt} img={this.props.editItem.img} price={this.props.editItem.price}  editMode = {true} handleEdit = {this.props.handleEditResult}/>
          }
  
          <div className="pdpagearea">
            {
              ((this.props.editMode === 0) && (this.state.pageCount > 1) && !this.state.searchMode) ? 
                <PageList handlePage={this.handlePage} 
                  first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                  next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                  currentPage={this.state.currentPage} 
                  startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
          
        </div>
      );
    }
  }
}

export default observer(ProductListMode);