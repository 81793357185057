import { Fragment } from "react";

const ItemCountInfo = (props) =>
{
  return (
    <Fragment>
      {
        props.item.bcnt > 0 ? 
        (
          <label className="mfontn">{`${props.item.bcnt}박스`}</label>
        ) : null
      }
      
      {
        props.item.ecnt > 0 ? 
        (
          <label className="mfontn">{`${props.item.ecnt}병`}</label>
        ) : null
      }
    </Fragment>
  );
}

const MnuOrderItemInfoedb = (props) =>
{
  return(
    <tr className="emptabledata">
      <td className="mnuordercell">
        <div className="mnuordercellitem flexcenterrow">
          <label className="mfontn">{props.item.icode}</label>
        </div>
      </td>
      
      <td className="mnuordercell">
        <div className="mnuordercellitem flexcenterrow">
          <label className="mfontn">{props.item.iname}</label>
        </div>
      </td>

      <td className="mnuordercell">
        <div className="mnuordercellitem flexcenterrow">
          <label className="mfontn">{`${props.item.ivol}ml`}</label>
        </div>
      </td>

      <td className="mnuordercell">
        <div className="mnuordercellitem flexcentercol">
          <ItemCountInfo item={props.item}/>
        </div>
      </td>

      <td className="mnuordercell">
        <div className="mnuordercellitem flexcentercol">
          <button className="bgcolor1 mnuodeditbtn2mb flexcenterrow btncur  mfontnwb" 
            onClick={() => { 
              props.handleEditItemMobile(props.order, props.item);
            }}
          >
            수정
          </button>
        </div>
      </td>
    </tr>
  );
}

export default MnuOrderItemInfoedb;