import { ODS_ACCEPT, ODS_DELIE, ODS_REORDER } from "../../Stores/OrderStore";
import { GetImgUrl } from "../../URL/AppUrl";
import "../Product/Product.css";

//음성주문 상세
const OrderVoiceDet = (props) =>
{
  return(
    <div className="pddetcontmb flexcenterrow">
      <div className="odvcpopdetmb flexcentercolstart">
        <div className="pddettitle flexcenterrow">
          <label className="mfontnb">음성 주문 상세정보 확인</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">업체명</label>
          <label className="mfontn">{props.order.cname}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">대표자명</label>
          <label className="mfontn">{props.order.uname}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">휴대폰</label>
          <label className="mfontn">{props.order.phone}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">주소</label>
          <label className="mfontn">{props.order.addr}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">영업담당자</label>
          <label className="mfontn">{props.order.sname}</label>
        </div>

        <div className="flexcenterrow ompdetinfomb">
          <label className="mfontn">녹음재생</label>

          <div className="flexcenterrow">
            <img src={GetImgUrl(props.playing ? "mbstop1.png" : "mbplay1.png")} className="btncur" onClick={() => props.playVoice(props.order)} alt="재생"/>
            <label className="mfontn">{props.playTime}</label>
          </div>
        </div>

        {
          props.order.st === ODS_ACCEPT ? (
            <button className="bgcolor1 odvcbtnokmb2 mfontnwb btncur" onClick={() => props.handleAddItem(props.order.idx)}>상품 추가하기</button>
          ):null
        }
        
        {
          props.order.st === ODS_ACCEPT ? (
            <button className="bgcolor2 odvcbtnokmb2 mfontnwb btncur" onClick={() => props.handleReorder(props.order.idx)}>재주문 요청</button>
          ):null
        }

        {
          props.order.st !== ODS_DELIE && props.order.st !== ODS_REORDER ? (
            <button className="bgcolor7 odvcbtnokmb mfontnwb btncur" onClick={() => props.handleMoveOrder(props.order.idx)}>주문 일자 변경</button>    
          ):null
        }

        {
          props.order.st === ODS_ACCEPT ? (
            <button className="bgcolor3 odvcbtnokmb mfontnwb btncur" onClick={() => props.handleAddItemCompleted(props.order.idx)}>주문 처리 완료</button>    
          ):null
        }

        <button className="bgcolor4 odvcbtnokmb mfontnwb btncur" onClick={() => props.handleCloseDetail()}>확인</button>
        
      </div>
    </div>
  );
}

export default OrderVoiceDet;