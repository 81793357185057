import {  mobileOn, GetImgUrl, GetAppImgUrl } from "../../URL/AppUrl";
import { ProductStoreInstance } from "../../Stores/ProductStore";
import "../../App.css";
import "./Product.css";
import { useState } from "react";
import {  GetProductStatus, IsValidS, IsValidV } from "../../Util/Util";

function isProductDetailValid(item)
{
  if (!IsValidV(item) || !IsValidV(item.dt) || item.dt === 'n')
    return false;

  return true;
}

function getProductDetailUrl(item)
{
  if (!IsValidV(item) || !IsValidV(item.dt) || item.dt === 'n')
    return '없음';

  return item.dt;
}

function gotoProductDetailPage(item)
{
  if (!isProductDetailValid(item))
  {
    return;
  }

  window.open(getProductDetailUrl(item), '_blank');
}

const ProductEditNew = (props) =>
{
  const [iuses, setUses] = useState(props.item.uses);
  const [iunit, setUnit] = useState(props.item.unit);
  const [icode, setIcode] = useState(props.item.icode);
  const [iname, setIname] = useState(props.item.iname);
  const [alias, setAlias] = useState(IsValidS(props.item.alias) && props.item.alias !== "n" ? props.item.alias : '');

  /*
  const categoryList = ProductStoreInstance.productCategory;

  let category = null;

  if (categoryList !== null && categoryList.length > 0)
  {
    category = categoryList.map(item => {
      return (<option key={item.idx} value={item.iid}>{item.cname}</option>);
    });
  }*/

  if (mobileOn)
  {
    return (
      <div className="pdcomaddpopbg flexcenterrow">
        <div className="flexcentercol pdcomeditpopmb">
          {/*타이틀*/}
          <div className='cntfullh flexcenterrow marginbottom30'>
            <div className='dcell1'></div>
            <div className='dcell8 flexcenterrow'>
              <label className='mfontnb'>상품수정</label>
            </div>
            <div className='dcell1 flexcenterrow'>
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur' onClick={() => {
                
                props.handleClose();
              }}/>
            </div>
          </div>
  
          <div className='flexcenterrow pdcominforow3'>
            <label className='mfontnb'>상품 이미지</label>
            <img src={GetAppImgUrl(props.item.img)} alt="상품이미지" className='pdcominpimg'/>
          </div>
          <div className='pdcominfoline'></div>
  
          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>판매분류</label>
            <label className='mfontn'>{ProductStoreInstance.getCategoryNameByCode(props.item.iid)}</label>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>용량</label>
            <label className='mfontn'>{`${props.item.ivol}ml`}</label>
          </div>
          <div className='pdcominfoline'></div>
  
          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>도수</label>
            <label className='mfontn'>{props.item.freq}</label>
          </div>
          <div className='pdcominfoline'></div>
  
          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>입수</label>
            <label className='mfontn'>{props.item.cnt}</label>
          </div>
          <div className='pdcominfoline'></div>
  
          {/* 
          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>취급단위</label>
            <label className='mfontn'>{GetProductUnitName(props.item.unit)}</label>
          </div>
          <div className='pdcominfoline'></div>

          
          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>용도</label>
            <label className='mfontn'>{GetProductUsesName(props.item.uses)}</label>
          </div>
          <div className='pdcominfoline'></div>
          */}

          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>판매상태</label>
            <label className='mfontn'>{GetProductStatus(props.item.pt)}</label>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow2'>
            <label className='fonts'>아래 항목만 수정 가능합니다.</label>
          </div>
          <div className='pdcominfoline2'></div>
  
          {/*취급단위*/}
          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>취급단위</label>
            
            <select className="compditcsel mfontn btncur" 
              onChange={(e) => {
                  console.log(e.target.value);
                  setUnit(parseInt(e.target.value));
              }}

              value={iunit}
            >
              <option value="0">박스</option>
              <option value="1">병</option>
              <option value="2">박스/병</option>
              <option value="3">박스/개</option>
              <option value="4">개</option>
            </select>
          </div>
          <div className='pdcominfoline'></div>

          {/*용도*/}
          <div className="flexcenterrow pdcominforowmb">
            <label className="mfontnb">용도</label>
            
            <select className="compditcsel mfontn btncur" 
              onChange={(e) => {
                  console.log(e.target.value);
                  setUses(parseInt(e.target.value));
              }}

              value={iuses}
            >
              <option value="0">유흥</option>
              <option value="1">일반</option>
              <option value="2">공통</option>
            </select>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>품목코드</label>
            <input type="text" onChange={(e) => setIcode(e.target.value)} value = {icode} className="pdcominputeditinp mfontn"
              placeholder="품목코드를 입력하세요." maxLength="15"/>
          </div>
          <div className='pdcominfoline'></div>
  
          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>상품명</label>
            <input type="text" onChange={(e) => setIname(e.target.value)} value = {iname} className="pdcominputeditinp mfontn"
              placeholder="상품명을 입력하세요."  maxLength="40"/>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow'>
            <label className='mfontnb'>단축명</label>
            <input type="text" onChange={(e) => setAlias(e.target.value)} value = {alias} className="pdcominputeditinp mfontn"
              placeholder="단축명은 일일판매일보에 사용됩니다."  maxLength="40"/>
          </div>
          <div className='pdcominfoline'></div>
  
          <button className='pdcominfoaddbtnmb mfontnwb btncur bgcolor3' onClick={() => {
            props.handleEditItem(props.item.idx, props.item.iid, icode, iname, alias, props.item.ivol, iunit, iuses, props.item.freq, props.item.cnt,props.item.img,props.item.price)}}
          >상품수정</button>

          <button className='pdcominfoaddbtnmb mfontnwb btncur bgcolor2' onClick={() => {
            props.handleEditItemStatus(props.item.idx, 1)}}
          >판매중지</button>

          <button className='pdcominfoaddbtnmb mfontnwb btncur bgcolor1' onClick={() => {
            props.handleEditItemStatus(props.item.idx, 0)}}
          >판매중</button>

        </div>
      </div>
    );
  }
  else
  {
    return (
      <div className="pdcomaddpopbg flexcenterrow">
        <div className="flexcentercolstart pdcomeditpop">
          {/*타이틀*/}
          <div className='cntfullh flexcenterrow marginbottom30'>
            <div className='dcell1'></div>
            <div className='dcell8 flexcenterrow'>
              <label className='fontlargeb'>상품수정</label>
            </div>
            <div className='dcell1 flexcenterrow'>
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" className='btncur' onClick={() => {
                
                props.handleClose();
              }}/>
            </div>
          </div>
  
          <div className='cntfullh flexcentercol'>
            <label className='mfontn'>품목코드와 단축명 수정 후 상품 수정 버튼을 누르면 저장됩니다.</label>
            <label className='mfontn'>상품을 판매 중지 또는 재판매 하려는 경우 <b>"판매중지"</b> 또는 <b>"판매중"</b> 버튼을 눌러주세요.</label>
            <label className='mfontn'>상품을 판매 중지 또는 판매중 상태로 변경 후 <b>"상품 수정" 버튼을 누를 필요 없습니다.</b></label>

            <label className='fonts2'>상품명이 한개 글자인경우 뒤에 다른 부가 정보를 붙이면 한개 글자 검색이되지 않습니다.</label>
            <label className='fonts2'>예) 상품명이 "센" 일경우 "센 130ml" 등과 같이 뒤에 다른 부가 정보를 붙이면 "센"으로 검색 불가</label>
            <label className='fonts2'>이런 경우 "센130ml" 등과 같이 공백을 업애고 검색시 "센1" 또는 "센130" 등의 형태로 검색해야 합니다.</label>
          </div>

          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow3'>
            <label className='fontnb'>상품 이미지</label>
            <img src={GetAppImgUrl(props.item.img)} alt="상품이미지" className='pdcominpimg'/>
          </div>
          <div className='pdcominfoline'></div>
  
          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>판매분류</label>
            <label className='fontn'>{ProductStoreInstance.getCategoryNameByCode(props.item.iid)}</label>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>용량</label>
            <label className='fontn'>{`${props.item.ivol}ml`}</label>
          </div>
          <div className='pdcominfoline'></div>
  
          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>도수</label>
            <label className='fontn'>{props.item.freq}</label>
          </div>
          <div className='pdcominfoline'></div>
  
          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>입수</label>
            <label className='fontn'>{props.item.cnt}</label>
          </div>
          <div className='pdcominfoline'></div>
  
          {/* 
          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>취급단위</label>
            <label className='fontn'>{GetProductUnitName(props.item.unit)}</label>
          </div>
          <div className='pdcominfoline'></div>

          
          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>용도</label>
            <label className='fontn'>{GetProductUsesName(props.item.uses)}</label>
          </div>
          <div className='pdcominfoline'></div>
          */}

          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>판매상태</label>
            <label className={props.item.pt === 0 ? 'fontn' : 'fontn2'}>{GetProductStatus(props.item.pt)}</label>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>상세페이지</label>
            <label className={isProductDetailValid(props.item) ? 'fontn btncur' : 'fontn2'}
              onClick={() =>{
                gotoProductDetailPage(props.item);
              }}
            >{getProductDetailUrl(props.item)}</label>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow2'>
            <label className='fonts2'>아래 항목만 수정 가능합니다.</label>
          </div>
          <div className='pdcominfoline2'></div>
  
          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>취급단위</label>
            <select className="compditcsel fontn btncur" 
              onChange={(e) => {
                  console.log(e.target.value);
                  setUnit(parseInt(e.target.value));
              }}

              value={iunit}
            >
              <option value="0">박스</option>
              <option value="1">병</option>
              <option value="2">박스/병</option>
              <option value="3">박스/개</option>
              <option value="4">개</option>
            </select>
          </div>
          <div className='pdcominfoline'></div>

          {/*취급단위*/}
          <div className="flexcenterrow pdcominforowmb">
            <label className="fontnb">용도</label>
            
            <select className="compditcsel fontn btncur" 
              onChange={(e) => {
                  console.log(e.target.value);
                  setUses(parseInt(e.target.value));
              }}

              value={iuses}
            >
              <option value="0">유흥</option>
              <option value="1">일반</option>
              <option value="2">공통</option>
            </select>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>품목코드</label>
            <input type="text" onChange={(e) => setIcode(e.target.value)} value = {icode} className="pdcominputeditinp fontn"
              placeholder="품목코드를 입력하세요." maxLength="15"/>
          </div>
          <div className='pdcominfoline'></div>
  
          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>상품명</label>
            <input type="text" onChange={(e) => setIname(e.target.value)} value = {iname} className="pdcominputeditinp fontn"
              placeholder="상품명을 입력하세요."  maxLength="40"/>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow'>
            <label className='fontnb'>단축명</label>
            <input type="text" onChange={(e) => setAlias(e.target.value)} value = {alias} className="pdcominputeditinp fontn"
              placeholder="단축명은 일일판매일보에 사용됩니다."  maxLength="40"/>
          </div>
          <div className='pdcominfoline'></div>

          <div className='flexcenterrow pdcominforow marginbottom15'>
            <button className='pdcominfoaddbtn fontnwb btncur bgcolor3' onClick={() => {
              props.handleEditItem(props.item.idx, props.item.iid, icode, iname, alias, props.item.ivol, iunit, iuses, props.item.freq, props.item.cnt,props.item.img,props.item.price)}}
            >상품수정</button>

            <button className='pdcominfoaddbtn fontnwb btncur bgcolor2' onClick={() => {
              props.handleEditItemStatus(props.item.idx, 1)}}
            >판매중지</button>

            <button className='pdcominfoaddbtn fontnwb btncur bgcolor1' onClick={() => {
              props.handleEditItemStatus(props.item.idx, 0)}}
            >판매중</button>
          </div>
          

        </div>
      </div>
    );
  }
}

export default ProductEditNew;