import { useState } from "react";
import { GetImgUrl } from "../../URL/AppUrl";

function CheckBoxCount(item)
{
  return (item.unit === 0 || item.unit === 2 || item.unit === 3);
}

function CheckBottleCount(item)
{
  return (item.unit === 1 || item.unit === 2 || item.unit === 4);
}

const MnuOrderEditmb = (props) =>
{
  const [boxCount, setBoxCount] = useState(props.item.bcnt);
  const [bottleCount, setBottleCount] = useState(props.item.ecnt);

  return (
    <div className="mnuodeditpopbg flexcenterrow">
      <div className="mnuodeditpop flexcentercolstart">
        <div className="cntfullh flexcenterrow">
          <div className="dcell1"></div>
          <div className="dcell8 flexcenterrow">
            <label className="mfontnb">주문 정보 수정</label>
          </div>
          <div className="dcell1 flexcenterrow">
            <img src={GetImgUrl("mnuodxmb.png")} alt="닫기" onClick={props.handleClose} className="btncur"/>
          </div>
        </div>
        
        <div className="flexcenterrow mnuodeditinfomb">
          <label className="mfontn">상품번호</label>
          <label className="mfontn">{props.item.icode}</label>
        </div>

        <div className="flexcenterrow mnuodeditinfomb">
          <label className="mfontn">상품명</label>
          <label className="mfontn">{props.item.iname}</label>
        </div>

        <div className="flexcenterrow mnuodeditinfomb">
          <label className="mfontn">용량</label>
          <label className="mfontn">{`${props.item.ivol}ml`}</label>
        </div>

        <div className="flexcenterrow mnuodeditinfomb">
          <label className="mfontn">주문갯수</label>

          <div className="flexcentercol mnuodeditinfoinpareamb">
            {
              CheckBoxCount(props.item) ? (
                <div className="marginbottom10">
                  <label className="mfontn">박스</label>
                  <input type="number" onChange={(e) => setBoxCount(parseInt(e.target.value))} value = {boxCount < 1 ? '' : boxCount} 
                    className="mnuodeditinfoinpmb mfontn" placeholder="갯수입력"
                  />
                </div>
              ) : null
            }
            {
              CheckBottleCount(props.item) ? (
                <div>
                  <label className="mfontn">병</label>
                  <input type="number" onChange={(e) => setBottleCount(parseInt(e.target.value))} value = {bottleCount < 1 ? '' : bottleCount} 
                    className="mnuodeditinfoinpmb mfontn" placeholder="갯수입력"
                  />
                </div>
              ) : null
            }
          </div>
        </div>

        <div className="flexcenterrow mnuodeditinfomb">
          <button className="bgcolor2 mnuodeditbtnmb mfontnwb btncur" onClick={() => props.handleDelete(props.order.idx, props.item)}>해당 상품 삭제</button>
          <button className="bgcolor1 mnuodeditbtnmb mfontnwb btncur" onClick={() => props.handleEditDone(props.order.idx, props.item, boxCount, bottleCount)}>주문 정보 수정</button>
        </div>
      </div>
    </div>
  );
}

export default MnuOrderEditmb;