import React from 'react';
import { GetImgUrl } from '../URL/AppUrl';
import { GetChatTime, IsValidS } from '../Util/Util';

function GetCurrentTime(item)
{
  if (IsValidS(item.time))
    return item.time;

  return GetChatTime(item.rdate);
}

const ChatAppFile = (props) =>
{
  if (props.item.ctype === 4) //운영자 첨부 파일이면
  {
    return (
      <div className="achatitem">
  
        <div className="achatbubblef flexcenterrow" onClick={() => props.handleDownload(false, props.item.chat, props.item.fname)}>
          <label className="achatfilefont">{props.item.fname}</label>
        </div>
  
        <label className="achattime">{GetCurrentTime(props.item)}</label>
        <img src={GetImgUrl("chatclip.png")} alt="첨부파일"/>
        <label className="achattime">파일 첨부</label>
        
      </div>
    );
  }
  else //유저의 첨부 파일이면
  {
    return (
      <div className="achatitemuser">
  
        <img src={GetImgUrl("chatclip.png")} alt="첨부파일"/>
        <label className="achattime">파일 첨부</label>
        <label className="achattime">{GetCurrentTime(props.item)}</label>

        <div className="achatbubbleuserf flexcenterrow" onClick={() => props.handleDownload(false, props.item.chat, props.item.fname)}>
          <label className="achatfilefontuser">{props.item.fname}</label>
        </div>
      </div>
    );
  }
  
}

export default ChatAppFile;