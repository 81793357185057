import React from 'react';
import { observer } from "mobx-react";
import {  LOG_ENABLE, mobileOn } from "../../URL/AppUrl";
import "../../App.css";
import "./Product.css";
import ProductListMode from "./ProductListMode";
//import ProductInput from "./ProductInput";
import { ProductStoreInstance } from "../../Stores/ProductStore";
import MainMenu from '../Menu/MainMenu';
import ProductAdd from './ProductAdd';
import { ProcessLogout } from '../../Stores/StoreManager';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { IsValidS, IsValidV, UploadFile } from '../../Util/Util';
import Loading from '../Loading/Loading';
import MenuMb from '../Menu/MenuMb';
import ProductDetmb from './ProductDetmb';
import ProductAddNew from './ProductAddNew';
import ProductEditNew from './ProductEditNew';
import ProductAddCom from './ProductAddCom';

class Product extends React.Component {

  state = {
    loading : false,
    editMode : 0, //0 : 리스트 출력모드, 1 : 아이템 등록모드, 2 : 아이템 편집모드
    selectedIdx : -1, //현재 편집 중인 아이템 고유번호
    showDetail : false,
    appOn : false,
    showComPd: false, //상품 추가창
    showComEditPd: false, //상품 수정창
    showAddComPd: false, //공통상품 직접 추가
  }

  searchModeEdit = false;
  editItemInfo = null;
  intervalId = -1;
  addInfo = null;
  detailInfo = null;
  selectedPage = 0; //현재의 페이지 번호
  compdAddInfo = null;

  componentDidMount() {

    if (LOG_ENABLE)
      console.log("componentDidMount");

    this.intervalId = setInterval(this.update, 1000);

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 7}));
    }
    else
      LoginStoreInstance.appEnabled = false;
  }

  componentWillUnmount()
  {
    if (LOG_ENABLE)
      console.log("componentWillUnmount");

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }

    ProductStoreInstance.clearTotalList();
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : "backhandler"}));

    if (!this.state.editMode)
      this.props.history.push("/main");
  }

  setLoading = (enable) =>
  {
    this.setState((prevState) => ({...prevState, loading : enable}));
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        this.props.history.push("/order"); 
        break;
      case 4: //판매일보
        this.props.history.push("/report"); 
        break;
      case 5: //신규상품등록
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }

  handleListMode = () =>
  {
    this.setState((prevState) => ({...prevState, editMode : 0}));
  }

  handleTab = (tabMode) =>
  {
    if (tabMode === 0)
      this.setState((prevState) => ({...prevState, editMode : 1}));
    else
      this.setState((prevState) => ({...prevState, editMode : 0}));
  }

  //아이템 변경 체크박스
  handleSelect = (itemIdx) => {
    if (LOG_ENABLE)
      console.log("handleSelect : " + itemIdx);

    if (this.state.selectedIdx === itemIdx)
      this.setState((prevState) => ({...prevState, selectedIdx : -1}));
    else
      this.setState((prevState) => ({...prevState, selectedIdx : itemIdx}));
  }

  handleSelectAndEdit = (itemIdx, searchMode) => {
    if (LOG_ENABLE)
    console.log("handleSelectAndEdit : " + itemIdx);

    this.searchModeEdit = searchMode;

    if (searchMode)
      this.editItemInfo = ProductStoreInstance.findProductByIdxFromSearchList(itemIdx);
    else
      this.editItemInfo = ProductStoreInstance.findProductByIdx(itemIdx);

    if (this.editItemInfo === null)
    {
      alert("오류!! 해당 상품을 찾을 수 없습니다");
      return;
    }

    /* 구버전의 편집팝업
    if (this.state.editMode !== 2)
      this.setState((prevState) => ({...prevState, editMode : 2, selectedIdx : itemIdx}));*/

    this.setState((prevState) => ({...prevState, showComEditPd : true}));
  }

  //아이템 변경 버튼 눌림
  handleEdit = (searchMode) =>
  {
    if (LOG_ENABLE)
      console.log("handleEdit");

    if (this.state.selectedIdx === -1)
    {
      alert("수정 할 아이템을 선택하세요.");
      return;
    }

    this.searchModeEdit = searchMode;
    
    if (searchMode)
      this.editItemInfo = ProductStoreInstance.findProductByIdxFromSearchList(this.state.selectedIdx);
    else
      this.editItemInfo = ProductStoreInstance.findProductByIdx(this.state.selectedIdx);

    if (this.editItemInfo === null)
    {
      alert("오류!! 해당 상품을 찾을 수 없습니다");
      return;
    }

    /* 구번전의 수정 팝업
    if (this.state.editMode !== 2)
      this.setState((prevState) => ({...prevState, editMode : 2}));*/

    this.setState((prevState) => ({...prevState, showComEditPd : true}));
  }

  //아이템 편집 결과
  handleEditResult = (editted, itemIdx, iid, icode, iname, alias, ivol, iunit, iuses, ifreq, icnt, oldImage, price, imgFile) =>
  {
    if (LOG_ENABLE)
      console.log("handleEditResult");

    if (!editted)
    {
      if (this.state.editMode !== 0)
        this.setState((prevState) => ({...prevState, editMode : 0}));

      return;
    }

    let itemInfo = null;

    if (this.searchModeEdit)
      itemInfo = ProductStoreInstance.findProductByIdxFromSearchList(itemIdx);
    else
      itemInfo = ProductStoreInstance.findProductByIdx(itemIdx);

    if (!IsValidV(itemInfo))
    {
      alert("상품 수정중 오류가 발생했습니다.");

      if (this.state.editMode !== 0)
        this.setState((prevState) => ({...prevState, editMode : 0}));

      return;
    }

    if (!IsValidV(iid))
    {
      alert("판매분류를 선택하세요.");
      return;
    }

    if (!IsValidS(icode))
    {
      alert("품목 코드를 입력하세요.");
      return;
    }
    
    if (!IsValidS(iname))
    {
      alert("품목명을 입력하세요.");
      return;
    }

    if (!IsValidS(alias))
    {
      alert("상품 단축명을 입력하세요.");
      return;
    }

    if (!IsValidS(ivol))
    {
      alert("용량을 입력하세요.");
      return;
    }

    if (!IsValidV(iunit))
    {
      alert("취급 단위를 입력하세요.");
      return;
    }

    if (!IsValidV(iuses))
    {
      alert("용도를 선택하세요.");
      return;
    }

    if (!IsValidS(ifreq))
    {
      alert("도수를 입력하세요.");
      return;
    }

    if (!IsValidV(icnt))
    {
      alert("입수를 입력하세요.");
      return;
    }

    if (!IsValidS(price))
    {
      alert("상품 가격을 입력하세요.");
      return;
    }

    this.setLoading(true);

    if (IsValidV(imgFile))
    {
      this.addInfo = {
        idx : itemIdx,
        iid : iid,
        icode : icode,
        iname : iname,
        alias : alias,
        ivol : ivol,
        unit : iunit,
        uses: iuses,
        freq : ifreq,
        cnt : icnt,
        price : price,
      };

      if (LOG_ENABLE)
        console.log("upload image first : " + JSON.stringify(this.addInfo));

      //업로드할 이미지가 있다면
      UploadFile(LoginStoreInstance.userIdx, 0, imgFile, this.onUploadForEdit);
    }
    else
    {
      ProductStoreInstance.editProduct(this.searchModeEdit, itemIdx, iid, icode,iname,alias,ivol,iunit,iuses,ifreq,icnt,oldImage, price, this.onEditResult);
    }
  }

  onUploadForEdit = (success, oldName, newName) =>
  {
    if (!success)
    {
      this.setLoading(false);
      alert("상품 이미지 업로드에 실패 했습니다.");
      return;
    }

    if (LOG_ENABLE)
      console.log(`upload success : ${oldName}, ${newName}`);

    ProductStoreInstance.editProduct(this.searchModeEdit, this.addInfo.idx, this.addInfo.iid, this.addInfo.icode,this.addInfo.iname,
      this.addInfo.alias,this.addInfo.ivol,this.addInfo.unit,this.addInfo.uses,this.addInfo.freq,this.addInfo.cnt, newName,this.addInfo.price,
      this.onEditResult);
  }

  //아이템 편집 결과
  handleEditResult2 = (itemIdx, iid, icode, iname, alias, ivol, iunit, iuses, ifreq, icnt, oldImage, price) =>
  {
    if (LOG_ENABLE)
      console.log("handleEditResult2");

    let itemInfo = null;

    if (this.searchModeEdit)
      itemInfo = ProductStoreInstance.findProductByIdxFromSearchList(itemIdx);
    else
      itemInfo = ProductStoreInstance.findProductByIdx(itemIdx);

    if (!IsValidV(itemInfo))
    {
      alert("상품 수정중 오류가 발생했습니다.");

      if (this.state.editMode !== 0)
        this.setState((prevState) => ({...prevState, showComEditPd: false}));

      return;
    }

    if (!IsValidV(iid))
    {
      alert("판매분류를 선택하세요.");
      return;
    }

    if (!IsValidS(icode))
    {
      alert("품목 코드를 입력하세요.");
      return;
    }
    
    if (!IsValidS(iname))
    {
      alert("품목명을 입력하세요.");
      return;
    }

    if (!IsValidS(alias))
    {
      alias = "n"
    }

    if (!IsValidS(ivol))
    {
      alert("용량을 입력하세요.");
      return;
    }

    if (!IsValidV(iunit))
    {
      alert("취급 단위를 입력하세요.");
      return;
    }

    if (!IsValidV(iuses))
    {
      alert("용도를 선택하세요.");
      return;
    }

    if (!IsValidS(ifreq))
    {
      alert("도수를 입력하세요.");
      return;
    }

    if (!IsValidV(icnt))
    {
      alert("입수를 입력하세요.");
      return;
    }

    if (!IsValidS(price))
    {
      alert("상품 가격을 입력하세요.");
      return;
    }

    this.setLoading(true);
    ProductStoreInstance.editProduct(this.searchModeEdit, itemIdx, iid, icode,iname,alias,ivol,iunit,iuses,ifreq,icnt,oldImage, price, this.onEditResult);
  }

  onEditResult = (resultCode) =>
  {
    switch(resultCode)
    {
      case 0:
        alert("상품이 수정되었습니다.");
        this.setState((prevState) => ({...prevState, editMode : 0, loading : false, showComEditPd: false}));
        return;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("상품 수정 권한이 없습니다.");
        break;
      case 3:
        alert("동일한 품목 코드가 존재합니다.");
        break;
      default:
        alert("상품 수정에 실패 했습니다.");
        break;
    }

    this.setLoading(false);
  }

  //아이템의 판매 상태를 변경함
  //itemStatus : 0(판매중), 1(판매중지)
  handleEditItemStatus = (itemIdx, itemStatus) =>
  {
    let itemInfo = null;

    if (this.searchModeEdit)
      itemInfo = ProductStoreInstance.findProductByIdxFromSearchList(itemIdx);
    else
      itemInfo = ProductStoreInstance.findProductByIdx(itemIdx);

    if (!IsValidV(itemInfo))
    {
      alert("수정할 상품을 찾을 수 없습니다.");

      if (this.state.editMode !== 0)
        this.setState((prevState) => ({...prevState, showComEditPd: false}));

      return;
    }

    if (itemStatus === 0)
    {
      if (itemInfo.pt === 0)
      {
        alert('해당 상품은 이미 판매 중입니다.');
        return;
      }

      if (!window.confirm('해당 상품을 다시 판매 중 상태로 변경할까요?'))
      {
        return;
      }
    }
    else if (itemStatus === 1)
    {
      if (itemInfo.pt === 1)
      {
        alert('해당 상품은 이미 판매중지 되었습니다.');
        return;
      }

      if (!window.confirm('해당 상품을 판매 중지 할까요?'))
      {
        return;
      }
    }

    this.setLoading(true);
    ProductStoreInstance.editProductStatus(this.searchModeEdit, itemIdx, itemStatus, this.onEditStatusResult);
  }

  onEditStatusResult = (resultCode, itemStatus) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        alert(itemStatus === 0 ? '상품을 판매중 상태로 변경했습니다.' : '상품을 판매 중지 했습니다.');
        this.setState((prevState) => ({...prevState, editMode : 0, loading : false, showComEditPd: false}));
        break;
      case 1:
        alert('잘못된 요청입니다.');
        break;
      default:
        alert('아이템 판매 상태 갱신중 오류가 발생했습니다.');
        break;
    }
  }

  //아이템 등록 탭 눌림
  handleAdd = () =>
  {
    if (LOG_ENABLE)
      console.log("handleAdd");

    if (this.state.editMode !== 1)
      this.setState((prevState) => ({...prevState, editMode : 1}));
  }

  //아이템 등록 요청
  handleAddResult = (iid, icode, iname, alias, ivol, iunit, iuses, ifreq, icnt, price, imgFile) =>
  {
    this.addInfo = {
      iid : iid,
      icode : icode,
      iname : iname,
      alias : alias,
      ivol : ivol,
      unit : iunit,
      uses: iuses,
      freq : ifreq,
      cnt : icnt,
      price: price,
    };

    if (LOG_ENABLE)
      console.log("handleAddResult : " + JSON.stringify(this.addInfo));

    if (!IsValidV(iid === null))
    {
      alert("판매분류를 선택하세요.");
      return;
    }

    if (!IsValidS(icode))
    {
      alert("품목 코드를 입력하세요.");
      return;
    }

    if (!IsValidS(iname))
    {
      alert("품목명을 입력하세요.");
      return;
    }

    if (!IsValidS(alias))
    {
      alert("상품 단축명을 입력하세요.");
      return;
    }

    if (!IsValidS(ivol))
    {
      alert("용량을 입력하세요.");
      return;
    }

    if (!IsValidV(iunit))
    {
      alert("취급 단위를 입력하세요.");
      return;
    }

    if (!IsValidV(iuses))
    {
      alert("용도를 선택하세요.");
      return;
    }

    if (!IsValidS(ifreq))
    {
      alert("도수를 입력하세요.");
      return;
    }

    if (!IsValidV(icnt))
    {
      alert("입수를 입력하세요.");
      return;
    }

    if (!IsValidV(imgFile))
    {
      alert("상품 이미지를 선택하세요.");
      return;
    }

    if (!window.confirm("상품을 추가할까요?"))
    {
      return;
    }

    this.setLoading(true);
    
    //먼저 이미지를 업로드 해야한다.
    UploadFile(LoginStoreInstance.userIdx, 0, imgFile, this.onUploadImgResult);
  }

  onUploadImgResult = (success, oldName, newName) =>
  {
    if (!success)
    {
      this.setLoading(false);
      alert("상품 이미지 업로드중 오류가 발생했습니다.");
      return;
    }

    if (LOG_ENABLE)
      console.log(`upload success : old = ${oldName}, new = ${newName}`);

    ProductStoreInstance.addProduct(this.addInfo.iid, this.addInfo.icode,this.addInfo.iname,this.addInfo.alias,
      this.addInfo.ivol,this.addInfo.unit,this.addInfo.uses,this.addInfo.freq,this.addInfo.cnt, newName, this.addInfo.price, 0, "n", this.onAddResult);
  }

  //신규버전의 아이템 등록 요청
  handleAddResult2 = (iid, icode, iname, alias, ivol, iunit, iuses, ifreq, icnt, price, imgFile, comIdx, mk, dt) =>
  {
    if (LOG_ENABLE)
      console.log("handleAddResult2 : ");

    this.setLoading(true);
    
    ProductStoreInstance.addProduct(iid, icode,iname,alias,ivol,iunit,iuses,ifreq,icnt, imgFile, price, comIdx, mk, dt, this.onAddResult);
  }

  onAddResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.setState((prevState) => ({...prevState, showComPd: false}));
        alert("상품이 추가되었습니다.");
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("상품 추가 권한이 없습니다.");
        break;
      case 3:
        alert("동일한 품목 코드가 존재합니다.");
        break;
      default:
        alert("상품 추가에 실패 했습니다.");
        break;
    }
  }

  handleDetail = (itemInfo) =>
  {
    this.detailInfo = itemInfo;
    this.setState((prevState) => ({...prevState, showDetail : true}));
  }

  handleCloseDetail = () =>
  {
    this.setState((prevState) => ({...prevState, showDetail : false}));
  }

  handleLogout = () => {
    ProcessLogout();
    this.props.history.push("/");
  }
  
  handleCloseCompd = () =>
  {
    this.setState((prevState) => ({...prevState, showComPd : false}));
  }

  handleCloseComEditpd = () =>
  {
    this.setState((prevState) => ({...prevState, showComEditPd : false}));
  }

  handleAddComPd = () =>
  {
    this.setState((prevState) => ({...prevState, showComPd : true}));
  }

  handleAddComPD = () =>
  {
    if (LOG_ENABLE)
      console.log(`handleAddComPD`);

    this.setState((prevState) => ({...prevState, showAddComPd : true}));
  }

  handleCloseAddCompd = () =>
  {
    if (LOG_ENABLE)
      console.log(`handleCloseAddCompd`);

    this.setState((prevState) => ({...prevState, showAddComPd : false}));
  }

  render()
  {
    if (mobileOn)
    {
      return(
        <div className="appcontmb">
          {
            this.state.showComPd ? (<ProductAddNew handleCloseCompd={this.handleCloseCompd} handleAddItem={this.handleAddResult2} handleAddComPD={this.handleAddComPD}/>) : null
          }

          {
            this.state.showAddComPd ? (<ProductAddCom handleCloseAddCompd={this.handleCloseAddCompd}/>) : null
          }

          {
            this.state.showComEditPd ? (<ProductEditNew item={this.editItemInfo} handleClose={this.handleCloseComEditpd} handleEditItem={this.handleEditResult2} handleEditItemStatus={this.handleEditItemStatus}/>) : null
          }

          {
            this.state.loading ? <Loading />:null
          }

          {
            this.state.showDetail ? <ProductDetmb item={this.detailInfo} handleCloseDetail = {this.handleCloseDetail}/> : null
          }

          <div className="flexcentercolstart pdconainermb">
            <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout = {this.handleLogout}/>

            {
              (this.state.editMode === 0 || this.state.editMode === 2) ? 
              <ProductListMode editMode = {this.state.editMode} editItem={this.editItemInfo}
                appOn = {this.state.appOn}
                handleAddComPd = {this.handleAddComPd}
                handleDetail={this.handleDetail}
                handleSelectAndEdit = {this.handleSelectAndEdit}
                handleSelect = {this.handleSelect} handleEdit = {this.handleEdit} handleEditResult={this.handleEditResult}
                selectedIdx={this.state.selectedIdx} handleTab = {this.handleTab} setLoading = {this.setLoading}
                handleListMode = {this.handleListMode}
                history = {this.props.history}/> : 
              <ProductAdd handleAdd = {this.handleAddResult} handleTab = {this.handleTab}/>
            }
          </div>
        </div>
      );
    }
    else
    {
      return(
        <div className="appcontainer">
          {
            this.state.showComPd ? (<ProductAddNew handleCloseCompd={this.handleCloseCompd} handleAddItem={this.handleAddResult2} handleAddComPD={this.handleAddComPD}/>) : null
          }

          {
            this.state.showAddComPd ? (<ProductAddCom handleCloseAddCompd={this.handleCloseAddCompd}/>) : null
          }

          {
            this.state.showComEditPd ? (<ProductEditNew item={this.editItemInfo} handleClose={this.handleCloseComEditpd} handleEditItem={this.handleEditResult2} handleEditItemStatus={this.handleEditItemStatus}/>) : null
          }

          {
            this.state.loading ? <Loading />:null
          }
  
          <div className="flexcentercolstart pdconainer">
            <div className="empmargin"/>
            <MainMenu index={5} handleMenuClick = {this.handleMenuClick}/>
            {
              (this.state.editMode === 0 || this.state.editMode === 2) ? 
              <ProductListMode editMode = {this.state.editMode} editItem={this.editItemInfo}
                appOn = {this.state.appOn}
                handleAddComPd = {this.handleAddComPd}
                handleSelectAndEdit = {this.handleSelectAndEdit}
                handleSelect = {this.handleSelect} handleEdit = {this.handleEdit} handleEditResult={this.handleEditResult}
                selectedIdx={this.state.selectedIdx} handleTab = {this.handleTab} setLoading = {this.setLoading}
                handleListMode = {this.handleListMode}
                history = {this.props.history}/> : 
              <ProductAdd handleAdd = {this.handleAddResult} handleTab = {this.handleTab}/>
            }
          </div>
        </div>
      );
    }    
  }
}

export default observer(Product);