import React from 'react';
import { observer } from 'mobx-react';
import { GetImgUrl, LOG_ENABLE, mobileOn } from '../../URL/AppUrl';
import { IsValidS } from '../../Util/Util';
import MnuOrderAddComp from './MnuOrderAddComp';
import { RetailerStoreInstance } from '../../Stores/RetailerStore';
import MnuOrderAddSalesSel from './MnuOrderAddSalesSel';
import MnuOrderAddProduct from './MnuOrderAddProduct';
import { MOrderStoreInstance } from '../../Stores/MOrderStore';
import { ProductStoreInstance } from '../../Stores/ProductStore';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { StaffStoreInstance } from '../../Stores/StaffStore';

class MnuOrderAdd extends React.Component {
  state = {
    mode: 0, //0 : 영업담당자 선택, 1 : 업체 선택, 2 : 최근 주문상품, 3 : 검색상품
    title: '영업담당자 선택',
    searchTitle: '',
    searchTitle2: '',
    searchWord: '',
    salesIdx: -1,
    searchProduct: '',
    showDoneBtn: false,
  };

  salesName = null; //선택된 담당자의 이름
  selectedRetailerInfo = null; //선택된 거래처의 정보
  itemList = null; //추가된 상품의 목록
  
  setLoading = (loading) =>
  {
    if (this.props.setLoading)
      this.props.setLoading(loading);
  }

  setSalesSelectMode = () =>
  {
    this.setState((prevState) => ({...prevState, mode: 0, title:'영업 담당자 선택', salesIdx : this.props.salesIdx, showDoneBtn: false}));
  }

  setRetailerSelectMode = (salesIdx) =>
  {
    this.selectedRetailerInfo = null;
    RetailerStoreInstance.clearRetList();

    if (mobileOn)
    {
      if (salesIdx !== -1)
        this.setState((prevState) => ({...prevState, mode: 1, title:'거래처 선택', searchTitle: '거래처 검색', searchTitle2: 'ex. 명륜진사갈비', selectedRetIdx: -1, salesIdx : salesIdx, showDoneBtn: false}));
      else
        this.setState((prevState) => ({...prevState, mode: 1, title:'거래처 선택', searchTitle: '거래처 검색', searchTitle2: 'ex. 명륜진사갈비', selectedRetIdx: -1, showDoneBtn: false}));
    }
    else
    {
      if (salesIdx !== -1)
      this.setState((prevState) => ({...prevState, mode: 1, title:'거래처 선택', searchTitle: '거래처 검색', searchTitle2: '검색어를 입력해 주세요.(ex. 명륜진사갈비)', selectedRetIdx: -1, salesIdx : salesIdx, showDoneBtn: false}));
    else
      this.setState((prevState) => ({...prevState, mode: 1, title:'거래처 선택', searchTitle: '거래처 검색', searchTitle2: '검색어를 입력해 주세요.(ex. 명륜진사갈비)', selectedRetIdx: -1, showDoneBtn: false}));
    }
  }

  setProductSelectMode = () =>
  {
    if (mobileOn)
    {
      this.setState((prevState) => ({...prevState, mode: 2, title:'상품 추가하기', searchWord: '', searchProduct: '', searchTitle: '상품 검색', searchTitle2: 'ex. 참이슬', showDoneBtn: true}));    
    }
    else
    {
      this.setState((prevState) => ({...prevState, mode: 2, title:'상품 추가하기', searchWord: '', searchProduct: '', searchTitle: '상품 검색', searchTitle2: '검색어를 입력해 주세요.(ex. 참이슬)', showDoneBtn: true}));    
    }
  }

  setSearchWord = (v) =>
  {
    this.setState((prevState) => ({...prevState, searchWord: v}));
  }

  componentDidMount() {

    if (this.props.resultMode === 0) //주문 추가모드인경우
    {
      if (this.props.mode === 0)
      {
        if (LOG_ENABLE)
          console.log('sales select mode');
  
        this.setSalesSelectMode();
      }
      else
      {
        if (LOG_ENABLE)
          console.log('retailer select mode');
  
        this.setRetailerSelectMode(this.props.salesIdx);
      }
    }
    else //상품추가 모드인경우
    {
      const order = MOrderStoreInstance.findOrderByIdx(this.props.orderIdx);

      if (order === null)
      {
        alert('주문을 찾을 수 없습니다.');
        return;
      }

      this.itemList = [];

      if (IsValidS(order.items))
      {
        for (let i = 0;i < order.items.length; ++i)
        {
          this.itemList.push({idx: order.items[i].iidx, boxCount: order.items[i].bcnt, bottleCount: order.items[i].ecnt});
        }
      }

      this.setProductSelectMode();

      this.setLoading(true);
      MOrderStoreInstance.clearRecentList();
      MOrderStoreInstance.loadRecentItems(order.ridx, this.onLoadRecentDone);
    }
  }

  componentWillUnmount() {
  }

  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log(`handleKeyPress`);

    if (e.key !== "Enter")
    {
      return;
    }

    this.doSearchRetailer();
  }

  doSearchRetailer = () =>
  {
    if (!IsValidS(this.state.searchWord))
    {
      alert('검색어를 입력하세요.');
      return;
    }

    if (this.props.resultMode === 0)
    {
      if (this.state.mode === 1)
      {
        RetailerStoreInstance.setSearchWord(this.state.searchWord);
  
        this.setLoading(true);

        if (LoginStoreInstance.getUserLevel === 3)
        {
          const salesInfo = StaffStoreInstance.findManagedSalesByIdx(this.props.salesIdx);

          if (salesInfo === null)
          {
            alert('영업담당자를 찾을 수 없습니다.');
            return;
          }
          //관리담당일경우 여기에 처리를 해줘야한다. 현재 선택된 영업담당자
          RetailerStoreInstance.searchRetailer(0, 0, salesInfo.cid, this.onSearchRetResult);
        }
        else
        {
          RetailerStoreInstance.searchRetailer(0, 0, LoginStoreInstance.getUserId, this.onSearchRetResult);
        }
      }
      else if (this.state.mode === 2 || this.state.mode === 3)
      {
        this.setState((prevState) => ({...prevState, searchProduct: this.state.searchWord}));
        this.setLoading(true);
        ProductStoreInstance.setSearchWord(this.state.searchWord);
        ProductStoreInstance.searchProduct(this.selectedRetailerInfo.ct, this.onSearcProductResult);
      }
    }
    else
    {
      let order = MOrderStoreInstance.findOrderByIdx(this.props.orderIdx);

      if (order !== null)
      {
        this.setState((prevState) => ({...prevState, searchProduct: this.state.searchWord}));
        this.setLoading(true);
        ProductStoreInstance.setSearchWord(this.state.searchWord);
        ProductStoreInstance.searchProduct(order.ct, this.onSearcProductResult);
      }
    }
  }

  //거래처 검색결과
  onSearchRetResult = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('거래처 검색중 오류가 발생했습니다. 인터넷 연결 상태를 확인해 주세요.');
      return;
    }
  }

  //상품 검색결과
  onSearcProductResult = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('상품 검색중 오류가 발생했습니다. 인터넷 연결 상태를 확인해 주세요.');
      return;
    }

    this.setState((prevState) => ({...prevState, mode : 3}));
  }

  findSalesName = (salesIdx) =>
  {
    if (IsValidS(this.props.salesList))
    {
      for (let i = 0;i < this.props.salesList.length; ++i)
      {
        if (this.props.salesList[i].idx === salesIdx)
        {
          return this.props.salesList[i].uname;
        }
      }
    }

    return null;
  }

  handleSalesSelect = (salesIdx) =>
  {
    if (LOG_ENABLE)
      console.log(`handleSalesSelect : ${salesIdx}`);

    this.setState((prevState) => ({...prevState, salesIdx: salesIdx}));

    this.salesName = this.findSalesName(salesIdx);
  }

  handleSelectRetailer = (retailer) =>
  {
    if (LOG_ENABLE)
      console.log(`handleSelectRetailer : ${JSON.stringify(retailer)}`);

    this.selectedRetailerInfo = retailer;

    if (retailer)
    {
      this.setState((prevState) => ({...prevState, selectedRetIdx: retailer.idx}));
    }
  }

  handlePrevStep = () =>
  {
    if (LOG_ENABLE)
      console.log('handleNextStep');

    switch(this.state.mode)
    {
      case 1: //거래처 선택
        this.setSalesSelectMode();
        break;
      case 2: //상품등록창최근거래
      case 3: //상품등록창검색결과
        this.setRetailerSelectMode(-1);
        break;
      default:
        break;
    }
  }

  handleNextStep = () =>
  {
    if (LOG_ENABLE)
      console.log('handleNextStep');

    switch(this.state.mode)
    {
      case 0: //영업담당 선택모드
        if (this.state.salesIdx !== -1)
        {
          if (LOG_ENABLE)
            console.log(`current sales : ${this.state.salesIdx}`);

          this.salesName = this.findSalesName(this.state.salesIdx);
          this.setRetailerSelectMode(-1);
        }
        else
        {
          alert('영업 담당자를 선택하세요.');
        }
        break;
      case 1: //거래처 선택
        if (this.state.selectedRetIdx !== -1 && this.selectedRetailerInfo !== null)
        {
          this.itemList = null;
          this.setProductSelectMode();

          this.setLoading(true);
          MOrderStoreInstance.clearRecentList();
          MOrderStoreInstance.loadRecentItems(this.selectedRetailerInfo.idx, this.onLoadRecentDone);
        }
        else
        {
          alert(`거래처를 검색 후 선택해 주세요.`);
        }
        break;
      default:
        break;
    }
  }

  onLoadRecentDone = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('최근 상품 목록 조회중 오류가 발생했습니다.');
      return;
    }
  }

  findItemFromTempList = (itemIdx) =>
  {
    if (!IsValidS(this.itemList))
      return null;

    for (let i = 0;i < this.itemList.length; ++i)
    {
      if (this.itemList[i].idx === itemIdx)
        return this.itemList[i];
    }

    return null;
  }

  handleAddItem = (itemName, itemIdx, boxCount, bottleCount) =>
  {
    if (LOG_ENABLE)
      console.log(`handleAddItem : ${itemIdx}, ${boxCount}, ${bottleCount}`);

    if (boxCount < 1 && bottleCount < 1)
    {
      return;
    }

    let item = this.findItemFromTempList(itemIdx);

    if (item === null)
    {
      item = {
        idx: itemIdx,
        boxCount : boxCount,
        bottleCount: bottleCount
      };

      if (this.itemList === null)
      {
        this.itemList = [];
      }

      this.itemList.push(item);

      if (LOG_ENABLE)
        console.log(`item not found add new`);
    }
    else
    {
      item.boxCount += boxCount;
      item.bottleCount += bottleCount;
  
      if (LOG_ENABLE)
        console.log(`item found add count : box=${item.boxCount}, bottle=${item.bottleCount}`);
    }
    
    let addInfo = '';

    if (boxCount > 0 && bottleCount > 0)
    {
      addInfo = `${boxCount}박스, ${bottleCount}병을`;
    }
    else if (boxCount > 0)
    {
      addInfo = `${boxCount}박스를`;
    }
    else if (bottleCount > 0)
    {
      addInfo = `${bottleCount}병을`;
    }

    if (item.boxCount > 0 && item.bottleCount > 0)
    {
      this.props.showInfoText(true, `'${itemName}' ${addInfo} 장바구니에 담았습니다.`,`총합 ${item.boxCount}박스, ${item.bottleCount}병`);
      //alert(`'${itemName}' ${addInfo} 장바구니에 담았습니다.\n총합 ${item.boxCount}박스, ${item.bottleCount}병`);
    }
    else if (item.boxCount > 0)
    {
      this.props.showInfoText(true, `'${itemName}' ${addInfo} 장바구니에 담았습니다.`,`총합 ${item.boxCount}박스`);
      //alert(`'${itemName}' ${addInfo} 장바구니에 담았습니다.\n총합 ${item.boxCount}박스`);
    }
    else if (item.bottleCount > 0)
    {
      this.props.showInfoText(true, `'${itemName}' ${addInfo} 장바구니에 담았습니다.`,`총합 ${item.bottleCount}병`);
      //alert(`'${itemName}' ${addInfo} 장바구니에 담았습니다.\n총합 ${item.bottleCount}병`);
    }
  }

  onClickSearchArea = () =>
  {
    if (this.state.mode === 3)
    {
      this.setState((prevState) => ({...prevState, mode : 2}));
    }
  }

  checkPrevBtn = () =>
  {
    if (this.state.mode === 0 || this.props.resultMode !== 0)
      return false;

    if (this.state.mode === 1)
    {
      if (this.props.mode === 0)
        return true;

      return false;
    }

    if (this.state.mode === 2 || this.state.mode === 3)
      return true;

    return false;
  }

  handleAddDone = () =>
  {
    if (LOG_ENABLE)
      console.log('handleAddDone');

    if (!IsValidS(this.itemList))
    {
      alert('추가된 상품이 없습니다. 상품을 추가해 주세요.');
      return;
    }

    let valid = false;

    for (let i = 0;i < this.itemList.length; ++i)
    {
      if (this.itemList[i].boxCount > 0 || this.itemList[i].bottleCount > 0)
      {
        valid = true;
        break;
      }
    }

    if (!valid)
    {
      alert('추가된 상품이 없습니다. 상품을 추가해 주세요.');
      return;
    }

    if (this.props.resultMode === 0)
    {
      if (this.props.mode === 0)
      {
        if (!window.confirm(`담당자 '${this.salesName}'님 앞으로 수기 주문을 추가할까요?`))
          return;
      }
      else
      {
        if (!window.confirm(`수기 주문을 추가할까요?`))
          return;
      }
  
      if (LOG_ENABLE)
        console.log(`${this.state.salesIdx}, ${JSON.stringify(this.selectedRetailerInfo)}, ${this.getFormattedItemList()}`);
  
      this.props.handleAddOrder(this.state.salesIdx, this.selectedRetailerInfo, this.getFormattedItemList());
    }
    else //주문에 상품을 추가하는 모드인경우
    {
      let order = MOrderStoreInstance.findOrderByIdx(this.props.orderIdx);

      if (order === null)
      {
        alert('주문을 찾을 수 없습니다.');
        return;
      }

      let resultItem = "";
      let first = true;

      for (let i = 0;i < this.itemList.length; ++i)
      {
        if (this.itemList[i].boxCount < 1 && this.itemList[i].bottleCount < 1)
          continue;

        let prevItem = this.findItemInOrderItems(order, this.itemList[i].idx);

        if (prevItem === null || prevItem.bcnt !== this.itemList[i].boxCount || prevItem.ecnt !== this.itemList[i].bottleCount)
        {
          if (first)
          {
            first = false;
            resultItem += `${this.itemList[i].idx},${this.itemList[i].boxCount},${this.itemList[i].bottleCount}`;
          }
          else
          {
            resultItem += `,${this.itemList[i].idx},${this.itemList[i].boxCount},${this.itemList[i].bottleCount}`;
          }
        }
      }

      if (resultItem.length < 1)
      {
        alert('추가 또는 수정된 상품이 없습니다.');
        return;
      }

      if (!window.confirm('상품을 추가할까요?'))
      {
        return;
      }

      
      this.props.handleEditOrderItem(this.props.orderIdx, resultItem);
    }
  }

  findItemInOrderItems = (order, itemIdx) =>
  {
    if (!order || !IsValidS(order.items))
      return null;

    for (let i = 0;i < order.items.length; ++i)
    {
      if (order.items[i].iidx === itemIdx)
        return order.items[i];
    }

    return null;
  }

  getFormattedItemList = () =>
  {
    if (!IsValidS(this.itemList))
      return null;

    let result = "";
    let first = true;

    for (let i = 0;i < this.itemList.length; ++i)
    {
      if (this.itemList[i].boxCount > 0 || this.itemList[i].bottleCount > 0)
      {
        if (first)
        {
          first = false;
          result += `${this.itemList[i].idx},${this.itemList[i].boxCount},${this.itemList[i].bottleCount}`;
        }
        else
        {
          result += `,${this.itemList[i].idx},${this.itemList[i].boxCount},${this.itemList[i].bottleCount}`;
        }
      }
    }

    return result;
  }

  handleCloseSearch = () =>
  {
    if (this.state.mode === 3)
    {
      this.setState((prevState) => ({...prevState, mode : 2}));
    }
  }

  render()
  {
    if (mobileOn)
    {
      return (
        <div className="mnuodaddpopbgmb flexcenterrow">
          <div className="mnuodaddpopmb flexcentercolstart">
            <div className="cntfullh flexcenterrow">
              <div className="dcell1 flexcenterrow"></div>
              <div className="dcell8 flexcenterrow">
                <label className="mfontnb">{this.state.title}</label>
              </div>
              <div className="dcell1 flexcenterrowend">
                <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={this.props.handleClose} className="btncur mnuodaddpopclbtn"/>
              </div>
            </div>
  
            {
              this.state.mode !== 0 ? (
                <div className="mnuodsearchareamb flexcenterrow">
                  <label className="mfontnb">{this.state.searchTitle}</label>
                  <input type="text" onChange={(e) => this.setSearchWord(e.target.value)} value = {this.state.searchWord} 
                    className="mnuodserinpmb mfontn" placeholder={this.state.searchTitle2}
                    onKeyPress={this.handleKeyPress}
                    onClick={this.onClickSearchArea}
                  />
                  <button className="mnuodserbtnmb mfontnwb btncur" onClick = {() => this.doSearchRetailer()}>검색</button>
                </div>
              ) : null
            }
            
            {
              this.state.mode === 0 ? (
                <MnuOrderAddSalesSel selectedSalesIdx={this.state.salesIdx} handleSalesSelect={this.handleSalesSelect} salesList={this.props.salesList}/>
              ) : null
            }
            {
              this.state.mode === 1 ? (
                <MnuOrderAddComp selectedIdx={this.state.selectedRetIdx} handleSelect={this.handleSelectRetailer}/>
              ) : null
            }
            {
              this.state.mode === 2 || this.state.mode === 3 ? (
                <MnuOrderAddProduct itemList={this.state.mode === 2 ? MOrderStoreInstance.recentItems : ProductStoreInstance.searchList} 
                  mode = {this.state.mode === 2 ? 0 : 1}
                  title= {this.state.mode === 2 ? '최근 주문 리스트' : `'${this.state.searchProduct}' 검색 결과`}
                  handleAddItem = {this.handleAddItem}
                  handleCloseSearch = {this.handleCloseSearch}
                />
              ) : null
            }
            <div className="cntfullh flexcenterrow mnuodaddbtmbtnarea">
              {
                this.checkPrevBtn() ? (
                  <button className="bgcolor4 mnuaddnextbtnmb mfontnwb btncur" onClick={this.handlePrevStep}>돌아가기</button>
                ) : null
              }
  
              {
                this.state.mode < 2 ? (
                  <button className="bgcolor3 mnuaddnextbtnmb mfontnwb btncur" onClick={this.handleNextStep}>다음</button>
                ) : null
              }
  
              {
                this.state.showDoneBtn ? (
                  <button className="bgcolor3 mnuadddonebtnmb mfontnwb btncur" onClick={this.handleAddDone}>추가 완료</button>
                ) : null
              }
            </div>
            
          </div>  
        </div>
      );
    }
    else
    {
      return (
        <div className="mnuodaddpopbg flexcenterrow">
          <div className="mnuodaddpop flexcentercolstart">
            <div className="cntfullh flexcenterrow">
              <div className="dcell1 flexcenterrow"></div>
              <div className="dcell8 flexcenterrow">
                <label className="fontlargeb">{this.state.title}</label>
              </div>
              <div className="dcell1 flexcenterrowend">
                <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={this.props.handleClose} className="btncur mnuodaddpopclbtn"/>
              </div>
            </div>
  
            {
              this.state.mode !== 0 ? (
                <div className="mnuodsearcharea flexcenterrowend">
                  <img src={GetImgUrl("searchs.png")} alt="검색" />
                  <label className="fontlargeb">{this.state.searchTitle}</label>
                  <input type="text" onChange={(e) => this.setSearchWord(e.target.value)} value = {this.state.searchWord} 
                    className="mnuodserinp fontn" placeholder={this.state.searchTitle2}
                    onKeyPress={this.handleKeyPress}
                    onClick={this.onClickSearchArea}
                  />
                  <button className="mnuodserbtn fontnwb btncur" onClick = {() => this.doSearchRetailer()}>검색하기</button>
                </div>
              ) : null
            }
            
            {
              this.state.mode === 0 ? (
                <MnuOrderAddSalesSel selectedSalesIdx={this.state.salesIdx} handleSalesSelect={this.handleSalesSelect} salesList={this.props.salesList}/>
              ) : null
            }
            {
              this.state.mode === 1 ? (
                <MnuOrderAddComp selectedIdx={this.state.selectedRetIdx} handleSelect={this.handleSelectRetailer}/>
              ) : null
            }
            {
              this.state.mode === 2 || this.state.mode === 3 ? (
                <MnuOrderAddProduct itemList={this.state.mode === 2 ? MOrderStoreInstance.recentItems : ProductStoreInstance.searchList} 
                  mode = {this.state.mode === 2 ? 0 : 1}
                  title= {this.state.mode === 2 ? '최근 주문 리스트' : `'${this.state.searchProduct}' 검색 결과`}
                  handleAddItem = {this.handleAddItem}
                />
              ) : null
            }
            <div className="cntfullh flexcenterrow mnuodaddbtmbtnarea">
              {
                this.checkPrevBtn() ? (
                  <button className="bgcolor4 mnuaddnextbtn fontnwb btncur" onClick={this.handlePrevStep}>돌아가기</button>
                ) : null
              }
  
              {
                this.state.mode < 2 ? (
                  <button className="bgcolor3 mnuaddnextbtn fontnwb btncur" onClick={this.handleNextStep}>다음</button>
                ) : null
              }
  
              {
                this.state.showDoneBtn ? (
                  <button className="bgcolor3 mnuadddonebtn fontnwb btncur" onClick={this.handleAddDone}>추가 완료</button>
                ) : null
              }
            </div>
            
          </div>  
        </div>
      );
    }
  }
}

export default observer(MnuOrderAdd);