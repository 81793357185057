import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  
} from 'mobx';
import React from 'react';
import {LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV, ToBase64 } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

//공지 관련
class NoticeStore {
  //observable변수
  gnotices = null; //조회된 전역공지 목록
  pnotices = null; //조회된 개별 공지 목록
  //비 observable
  searchedList = false; //공지의 목록이 검색에 의한 결과이면true
  totalGCount = 0; //전체 공지의 개수
  totalPCount = 0; //개별 공지의 개수

  gStartIdx = 0; //전체공지중 첫번째 공지의 고유번호
  gEndIdx = 0; //전체 공지중 마지막 공지의 고유번호
  pStartIdx = 0; //개별 공지중 첫번째 공지의 고유번호
  pEndIdx = 0; //개별 공지중 마지막 공지의 고유번호

  clearAll = () =>
  {
    //observable변수
    this.gnotices = null; //조회된 전역공지 목록
    this.pnotices = null; //조회된 개별 공지 목록
    //비 observable
    this.totalGCount = 0; //전체 공지의 개수
    this.totalPCount = 0; //개별 공지의 개수

    this.searchedList = false; //공지의 목록이 검색에 의한 결과이면true

    this.searchCallback = null;
    this.searchType = 0;
    this.loadCallback = null;
    this.addCallback = null;
    this.currentPage = 0; //공지를 추가하는 시점의 페이지 번호
    this.editCallback = null;
    this.editInfo = null;
    this.lastEditIdx = -1;

    this.gStartIdx = 0; //전체공지중 첫번째 공지의 고유번호
    this.gEndIdx = 0; //전체 공지중 마지막 공지의 고유번호
    this.pStartIdx = 0; //개별 공지중 첫번째 공지의 고유번호
    this.pEndIdx = 0; //개별 공지중 마지막 공지의 고유번호

    this.loadOneCallback = null;
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      gnotices : observable,
      pnotices : observable,

      clearAll : action,

      searchNotice : action,
      parseSearchResult : action,

      loadNotice : action,
      parseLoadResult : action,

      addNotice : action,
      parseAddResult : action,

      editNotice : action,
      parseEditResult : action,
    });
  }
  
  findPrevNotice = (noticeIdx, isGlobal) =>
  {
    //현재 공지 목록이 검색된 목록이면
    if (this.searchedList)
      return null;

    const notices = isGlobal ? this.gnotices : this.pnotices;

    if (!IsValidS(notices))
      return null;

    let maxIdx = -1;
    let notice = null;

    for (let i = 0;i < notices.length; ++i)
    {
      if (notices[i].idx < noticeIdx){
        if (maxIdx === -1 || maxIdx < notices[i].idx) {
          maxIdx = notices[i].idx;
          notice = notices[i];
        }
      }
    }

    return notice;
  }

  findNextNotice = (noticeIdx, isGlobal) =>
  {
    //현재 공지 목록이 검색된 목록이면
    if (this.searchedList)
      return null;

    const notices = isGlobal ? this.gnotices : this.pnotices;

    if (!IsValidS(notices))
      return null;

    let minIdx = -1;
    let notice = null;

    for (let i = 0;i < notices.length; ++i)
    {
      if (notices[i].idx > noticeIdx){
        if (minIdx === -1 || minIdx > notices[i].idx) {
          minIdx = notices[i].idx;
          notice = notices[i];
        }
      }
    }

    return notice;
  }

  //고유번호로 전역 공지 찾기
  findGNoticeByIdx = (noticeIdx) =>
  {
    if (this.gnotices === null)
      return null;

    for (let i = 0;i < this.gnotices.length; ++i)
    {
      if (this.gnotices[i].idx === noticeIdx)
        return this.gnotices[i];
    }

    return null;
  }

  //고유번호로 개별 공지 찾기
  findPNoticeByIdx = (noticeIdx) =>
  {
    if (this.pnotices === null)
      return null;

    for (let i = 0;i < this.pnotices.length; ++i)
    {
      if (this.pnotices[i].idx === noticeIdx)
        return this.pnotices[i];
    }

    return null;
  }

  //검색하기-------------------------------------------------------------------------------------------------------------------------------------
  searchCallback = null;
  searchType = 0;

  makeSearchParam (nt, sw) {
    const params = new URLSearchParams();

    params.append("nt", nt.toString());
    params.append("sw", (ToBase64(sw)));
    return params;
  }

  searchNotice = (nt, sw, callback) =>
  {
    this.searchCallback = callback;
    this.searchType = nt;

    if (LOG_ENABLE)
      console.log(`searchNotice : ${nt}/${sw}`);

    axios({
      method:"POST",
      url: ("notice/ser.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSearchParam (nt, sw)
    }).then((res)=>{
        this.parseSearchResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseSearchResult(null);
    });
  }

  parseSearchResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseSearchResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.searchCallback != null)
        this.searchCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (this.searchType === 0)
      {
        this.searchedList = true;
        this.gnotices = result.list;

        this.validateNotice(this.gnotices);
      }
      else
      {
        this.searchedList = true;
        this.pnotices = result.list;

        this.validateNotice(this.pnotices);
      }
    }

    if (this.searchCallback != null)
      this.searchCallback(result.ret);
  }

  validateNotice = (list) =>
  {
    if (list === null || list === undefined || list.length < 1)
      return;

    for (let i = 0;i < list.length; ++i)
    {
      this.validateNoticeItem(list[i]);
    }
  }

  validateNoticeItem = (item) =>
  {
    if (IsValidS(item.title))
      item.title = FromBase64(item.title);

    if (IsValidS(item.uname))
      item.uname = FromBase64(item.uname);

    if (IsValidS(item.notice))
      item.notice = FromBase64(item.notice);

    if (IsValidS(item.afileori) && item.afileori !== "n")
      item.afileori = FromBase64(item.afileori);
    
    if (IsValidS(item.aimgori) && item.aimgori !== "n")
      item.aimgori = FromBase64(item.aimgori);
  }
  //목록 읽기-------------------------------------------------------------------------------------------------------------------------------------
  loadCallback = null;

  makeLoadParam (nt, pos) {
    const params = new URLSearchParams();

    params.append("nt", nt.toString());
    params.append("pos", pos.toString());
    return params;
  }

  loadNotice = (noticeType, offset, callback) =>
  {
    this.loadCallback = callback;
    this.searchType = noticeType;

    axios({
      method:"POST",
      url: ("notice/list.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadParam (noticeType, offset)
    }).then((res)=>{
        this.parseLoadResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseLoadResult(null);
    });
  }

  parseLoadResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseLoadResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadCallback != null)
        this.loadCallback(-2, false);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (this.searchType === 0)
      {
        this.searchedList = false;
        this.gnotices = result.list;

        this.validateNotice(this.gnotices);

        //첫페이지 조회인경우, 전체 공지 개수 및 시작공지와 끝 공지 고유번호가 포함되어 있다.
        if (result.ft === 1)
        {
          this.totalGCount = result.tc;
          this.gStartIdx = result.sidx;
          this.gEndIdx = result.eidx;
        }
      }
      else
      {
        this.searchedList = false;
        this.pnotices = result.list;

        this.validateNotice(this.pnotices);

        //첫페이지 조회인경우, 전체 공지 개수 및 시작공지와 끝 공지 고유번호가 포함되어 있다.
        if (result.ft === 1)
        {
          this.totalPCount = result.tc;
          this.pStartIdx = result.sidx;
          this.pEndIdx = result.eidx;
        }
      }
    }

    if (this.loadCallback != null)
      this.loadCallback(result.ret, result.ft === 1);
  }
  //추가-------------------------------------------------------------------------------------------------------------------------------------
  addCallback = null;
  currentPage = 0; //공지를 추가하는 시점의 페이지 번호

  makeAddParam (nt, title, uname, notice, afile, afileori, aimg, aimgori) {
    const params = new URLSearchParams();

    params.append("nt", nt.toString());
    params.append("title", (ToBase64(title)));
    params.append("uname", (ToBase64(uname)));
    params.append("notice", (ToBase64(notice)));

    if (IsValidS(afile))
      params.append("afile", afile);

    if (IsValidS(afileori))
      params.append("afileori", (ToBase64(afileori)));

    if (IsValidS(aimg))
      params.append("aimg", aimg);

    if (IsValidS(aimgori))
      params.append("aimgori", (ToBase64(aimgori)));

    return params;
  }

  addNotice = (currentPage, nt, title, uname, notice, afile, afileori, aimg, aimgori, callback) =>
  {
    this.addCallback = callback;
    this.searchType = nt;
    this.currentPage = currentPage;

    axios({
      method:"POST",
      url: ("notice/add.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeAddParam (nt, title, uname, notice, afile, afileori, aimg, aimgori)
    }).then((res)=>{
        this.parseAddResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseAddResult(null);
    });
  }

  parseAddResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseAddResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.addCallback != null)
        this.addCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (IsValidV(result.item) && this.currentPage === 1)
      {
        this.validateNoticeItem(result.item);

        if (this.searchType === 0) //이때 searchType에는 공지 타입이 기록되어 있다.
        {
          let newList = null;

          if (IsValidS(this.gnotices))
            newList = [result.item, ...this.gnotices];
          else
            newList = [result.item];

          this.gnotices = newList;
        }
        else
        {
          let newList = null;

          if (IsValidS(this.pnotices))
            newList = [result.item, ...this.pnotices];
          else
            newList = [result.item];

          this.pnotices = newList;
        }
      }
    }

    if (this.addCallback != null)
      this.addCallback(result.ret);
  }
  
  //수정-------------------------------------------------------------------------------------------------------------------------------------
  editCallback = null;
  editInfo = null;
  lastEditIdx = -1;

  makeEditParam (idx, title, uname, notice, afile, afileori, aimg, aimgori) {
    const params = new URLSearchParams();

    params.append("idx", idx.toString());
    params.append("title", (ToBase64(title)));
    params.append("uname", (ToBase64(uname)));
    params.append("notice", (ToBase64(notice)));
    
    if (IsValidS(afile))
      params.append("afile", afile);

    if (IsValidS(afileori))
      params.append("afileori", (ToBase64(afileori)));

    if (IsValidS(aimg))
      params.append("aimg", aimg);

    if (IsValidS(aimgori))
      params.append("aimgori", (ToBase64(aimgori)));

    return params;
  }

  editNotice = (idx, nt, title, uname, notice, afile, afileori, aimg, aimgori, callback) =>
  {
    if (LOG_ENABLE)
    {
      console.log(`idx =${idx}, nt =${nt}, title =${title}, uname =${uname}, notice =${notice}, afile =${afile}, afileori =${afileori}
        , aimg =${aimg}, aimgori =${aimgori}`);
    }
    this.editCallback = callback;
    this.searchType = nt;
    this.lastEditIdx = idx;

    let ntc = nt === 0 ? this.findGNoticeByIdx(idx) : this.findPNoticeByIdx(idx);

    if (ntc !== null)
    {
      this.editInfo = {
        ...ntc,
        title : title,
        uname : uname,
        notice : notice,
        afile : afile,
        afileori : afileori,
        aimg : aimg,
        aimgori : aimgori,
      };
    }
    else
      this.editInfo = null;
    

    axios({
      method:"POST",
      url: ("notice/edit.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeEditParam (idx, title, uname, notice, afile, afileori, aimg, aimgori)
    }).then((res)=>{
        this.parseEditResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseEditResult(null);
    });
  }

  parseEditResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseEditResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.editCallback != null)
        this.editCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0 && this.editInfo !== null)
    {
      if (this.searchType === 0)
      {
        if (LOG_ENABLE)
          console.log("edit list global");

        const notice = this.findGNoticeByIdx(this.lastEditIdx);

        if (notice !== null)
        {
          if (LOG_ENABLE)
            console.log("edit list global : found");

          let newList = [];

          for (let i = 0;i < this.gnotices.length; ++i)
          {
            if (this.gnotices[i].idx === this.lastEditIdx)
              newList.push(this.editInfo);
            else
              newList.push(this.gnotices[i]);
          }

          this.gnotices = newList;
        }
      }
      else
      {
        const notice = this.findPNoticeByIdx(this.lastEditIdx);

        if (notice !== null)
        {
          if (LOG_ENABLE)
            console.log("edit list private : found");

          let newList = [];

          for (let i = 0;i < this.pnotices.length; ++i)
          {
            if (this.pnotices[i].idx === this.lastEditIdx)
              newList.push(this.editInfo);
            else
              newList.push(this.pnotices[i]);
          }

          this.pnotices = newList;
        }
      }
    }

    if (this.editCallback != null)
      this.editCallback(result.ret);
  }
  //공지한개조회-------------------------------------------------------------------------------------------------------------------------------------
  loadOneCallback = null;

  makeLoadOneParam (idx, prev, nt) {
    const params = new URLSearchParams();

    params.append("idx", idx.toString());
    params.append("pv", prev.toString());
    params.append("nt", nt.toString());

    return params;
  }

  loadNoticeOne = (idx, nt, prev, callback) =>
  {
    if (LOG_ENABLE)
      console.log(`idx = ${idx}, nt = ${nt}, prev = ${prev}`);
      
    this.loadOneCallback = callback;

    axios({
      method:"POST",
      url: ("notice/loadone.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadOneParam (idx, prev, nt)
    }).then((res)=>{
        this.parseLoadOneResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseLoadOneResult(null);
    });
  }

  parseLoadOneResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseLoadOneResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadOneCallback != null)
        this.loadOneCallback(-2, null);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (IsValidV(result.item))
        this.validateNoticeItem(result.item);
    }

    if (this.loadOneCallback != null)
      this.loadOneCallback(result.ret, result.item);
  }
}
//-------------------------------------------------------------------------------------------------------------------------------------------------
const NoticeStoreInstance = new NoticeStore();
const NoticeStoreContext = React.createContext(NoticeStoreInstance);

const UseNoticeStore = () => React.useContext(NoticeStoreContext);

export {UseNoticeStore, NoticeStoreInstance};
