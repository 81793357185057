import React from "react";
import { ODS_ACCEPT, ODS_REORDER } from "../../Stores/OrderStore";

function GetOrderStatus(order)
{
  switch(order.st)
  {
    case ODS_ACCEPT:
      return "아직 처리되지 않은 주문입니다. 주문을 청취 후 주문 처리 완료를 눌러주세요.";
    case ODS_REORDER:
      return "재주문 요청되었습니다.";
    default:
      return "주문 처리 완료";
  }
}

const OrderMiscVoicemb = (props) =>
{
  return (
    <div className = "ordertablecontmb">

      <div className="empsertitle flexcenterrowstart">
        <label className={(props.order.st === ODS_ACCEPT || props.order.st === ODS_REORDER) ? "orderverrfontsmmb" : "ordervnfontsmmb"}>&nbsp;{GetOrderStatus(props.order)}</label>
      </div>

      <table className="emptablemb">
        <thead>
          <tr>
            <th className="emptableheader">
              <label className="mfontnw">업체명</label>
            </th>

            <th className="emptableheader">
              <label className="mfontnw">상세정보 확인</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="emptabledata">
            <td className="ordercellcnamevo">
              <div className="emptablecellin flexcenterrow">
                <label className="mfontn">{props.order.cname}</label>
              </div>
            </td>
            
            <td className="ordercellunamevo">
              <div className="emptablecellin flexcenterrow">
                <button className= {props.order.st === ODS_ACCEPT ? "bgcolor3 orderdetbtnmb mfontnwb btncur" : "bgcolor4 orderdetbtnmb mfontnwb btncur"} onClick={() => props.handleDetail(props.order)}>상세정보</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default OrderMiscVoicemb;