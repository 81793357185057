import { observer } from 'mobx-react';
import React, { createRef, Fragment } from 'react';
import { ChatStoreInstance } from '../../Stores/ChatStore';
import { GetFileUrl, LOG_ENABLE, mobileOn } from '../../URL/AppUrl';
import "./Chat.css";
import MainMenu from '../Menu/MainMenu';
import ChatCompany from './ChatCompany';
import ChatInput from './ChatInput';
import ChatSerComp from './ChatSerComp';
import ChatSerTalk from './ChatSerTalk';
import ChatTalkList from './ChatTalkList';
import ChatEmptyTop from './ChatEmptyTop';
import ChatEmpty from './ChatEmpty';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { ProcessLogout } from '../../Stores/StoreManager';
import { DownloadFile, IsValidS, IsValidV, UploadFile } from '../../Util/Util';
import Loading from '../Loading/Loading';
import ChatAlarm from './ChatAlarm';
import TimeUtil from '../../Util/TimeUtil';
import MenuMb from '../Menu/MenuMb';
import ChatSerCompmb from './ChatSerCompmb';
import ChatInputmb from './ChatInputmb';

//채팅창 메인
class Chat extends React.Component
{
  state = {
    loading : false,
    selectedIdx : -1,
    searchComp : 0, //검색된 업체 목록 모드인경우
    talkUserIdx : -1, //대화중인 상대유저의 고유번호
    newChatOn : false, //사용자의 신규 채팅이 수신되었고, 현재 해당 사용자가 대화중이지 않을때
    newChat : null,
    searchTalkMode : false, //대화 검색중인가?
    searchTalkCount : 0, //검색된 토크의 개수
    searchTalkIndex : 0, //현재 보고 있는 검색 토크 인덱스
    searchTalkIdx : undefined,

    currentPage: 0, //0 : 업체 목록, 1 : 채팅,

    companyName: '',

    talkAreaHeight : 300,
    appOn : false,
  };

  intervalId = -1;
  talkRef = createRef();

  needTalkInit = false; //특정회사와의 대화 정보가 수신되는 경우 true로 설정됨
  talkScrollPos = 0;
  moreTalkList = false; //채팅창 위로 스크롤해서 대화내용 더 받은경우에 true
  moreOn = false;
  uploadInfo = null;
  newChatTime = null;
  searchedTalkList = null; //대화 내용검색된목록
  lastScrollHeight = 0;

  componentDidMount = () =>
  {
    if (LOG_ENABLE)
      console.log("Chat.componentDidMount");

    if (mobileOn)
    {
      //this.chatPopHeight = window.innerHeight - (120 + 68);
      let chatPopHeight = window.innerHeight - 360;

      this.setState((prevState) => ({...prevState, talkAreaHeight : chatPopHeight}));

      if (LOG_ENABLE)
        console.log(`chat window height : ${chatPopHeight}`);
    }

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 9}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    this.newChatTime = new TimeUtil();
    this.newChatTime.catch();

    this.intervalId = setInterval(this.update, 500);

    ChatStoreInstance.newChatListCallback = this.onNewChatListUpdated;
    ChatStoreInstance.chatListCallback = this.onChatLoaded;
    ChatStoreInstance.newChatCallback = this.onNewChat;

    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
      return;
    }
    else
      ChatStoreInstance.clearNewChatCount();

    ChatStoreInstance.connect();
  }

  componentWillUnmount = () =>
  {
    ChatStoreInstance.clearAll();

    if (LOG_ENABLE)
      console.log("Chat.componentWillUnmount");

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : "backhandler"}));

    //채팅중이면
    if (this.state.currentPage === 1)
    {
      this.handleToCompanyList();
    }
    else
    {
      this.props.history.push("/main");
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    if (LOG_ENABLE)
      console.log(`onProfileLoaded : ${resultCode}`);

    this.setLoading(false);

    if (resultCode !== 0)
    {
      
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    ChatStoreInstance.connect();
    ChatStoreInstance.clearNewChatCount();
  }

  setLoading = (enabled) =>
  {
    this.setState((prevState) => ({...prevState, loading : enabled}));
  }

  //대화업체 목록이 갱신될때마다 호출됨
  onNewChatListUpdated = (count) =>
  {
    if (LOG_ENABLE)
      console.log(`onNewChatListUpdated : ${count}`);

    if (count > 0)
    {
      if (ChatStoreInstance.getTotalCompanycount < 100 && !ChatStoreInstance.chatListLoading && count >= 50)
        ChatStoreInstance.loadLastNewChatList();
    }
  }

  //대화 목록이 갱신될때마다 호출됨
  onChatLoaded = (count) =>
  {
    if (LOG_ENABLE)
      console.log(`onChatLoaded : ${count}`);

    if (count > 0)
    {
      if (ChatStoreInstance.getTotalTalkCount < 100 && !ChatStoreInstance.chatLoading && count >= 30)
      {
        ChatStoreInstance.loadLastChatList(this.state.talkUserIdx);
      }

      if (count < 30 || ChatStoreInstance.getTotalTalkCount >= 100)
        this.needTalkInit = true;
    }
    else
      this.needTalkInit = true;
  }

  //신규 채팅 한개가 수신될떄
  onNewChat = (current, chat) =>
  {
    if (current && this.state.talkUserIdx !== -1)
    {
      if (LOG_ENABLE)
        console.log("curret user new chat");

      this.needTalkInit = true;
      this.moreOn = false;
    }
    else
    {
      if (LOG_ENABLE)
        console.log("another user new chat");

      if (!this.state.newChatOn)
      {
        this.setState((prevState) => ({...prevState, newChatOn : true, newChat : chat}));
      }
      else
        this.setState((prevState) => ({...prevState, newChat : chat}));

      this.newChatTime.catch();
    }
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }


    if (this.needTalkInit || (!this.moreOn && this.getTalkScrollHeight() !== this.lastScrollHeight))
      this.updateTalkScroll(true);

    if (this.state.newChatOn)
    {
      if (this.newChatTime.getPassedTime(new Date()) >= 2.0)
      {
        this.setState((prevState) => ({...prevState, newChatOn : false}));
      }
    }
  }

  moveTalkScrollPos = (pos)=>
  {
    if (IsValidV(this.talkRef.current) && IsValidV(this.talkRef.current.scrollHeight))
    {
      this.talkRef.current.scrollTo(0, pos);
    }
  }

  getTalkScrollHeight = () =>
  {
    if (IsValidV(this.talkRef) && IsValidV(this.talkRef.current) && IsValidV(this.talkRef.current.scrollHeight))
      return this.talkRef.current.scrollHeight;

    return 0;
  }

  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");
        break;
      case 2: //거래처관리
        this.props.history.push("/reta");  
        break;
      case 3: //주문관리
        this.props.history.push("/order");  
        break;
      case 4: //판매일보
        this.props.history.push("/report"); 
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }

  handleCompanyClick = (idx, userIdx) =>
  {
    if (LOG_ENABLE)
      console.log(`handleCompanyClick : ${idx}, ${userIdx}`);

    ChatStoreInstance.clearNewChatMark(idx);

    this.moreOn = false;

    if (mobileOn || this.state.selectedIdx !== idx)
    {
      let companyInfo = ChatStoreInstance.findNewChatByUserIdx(userIdx);

      if (companyInfo !== null)
      {
        //선택된 업체의 고유번호를 설정함
        this.setState((prevState) => ({...prevState, selectedIdx : idx, talkUserIdx : userIdx, searchTalkMode : false, 
          searchTalkIdx : undefined, currentPage : 1, companyName : companyInfo.cname}));

        ChatStoreInstance.clearChatList();
        ChatStoreInstance.loadLastChatList(userIdx);
      }
    }
  }

  //idx : 업체 메인 유저의 고유번호
  handleStartTalk = (userIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleStartTalk : " + userIdx);

    let itemIdx = 100000000;
    let targetUser = ChatStoreInstance.findNewChatByUserIdx(userIdx);

    if (targetUser !== null)
      itemIdx = targetUser.idx;
    
    this.moreOn = false;

    //일단 임시로 고유번호를 임의로 설정
    this.setState((prevState) => ({...prevState, selectedIdx : itemIdx, talkUserIdx : userIdx, searchComp : 0, searchTalkMode : false, 
      searchTalkIdx : undefined, currentPage : 1, companyName: (targetUser !== null ? targetUser.cname : '')}));

    ChatStoreInstance.clearChatList();
    ChatStoreInstance.loadLastChatList(userIdx);
  }

  handleCloseCompSearch = () =>
  {
    this.setState((prevState) => ({...prevState, searchComp : 0}));
  }
  /*
  일단 업체 검색은 먼저 계정의 목록에서 검색해서 결과를 보여주도록한다.
  */
  handleSearchCompany = (searchWord) =>
  {
    if (LOG_ENABLE)
      console.log("handleSearchCompany : " + searchWord);

    if (!IsValidS(searchWord) || searchWord.length < 2)
    {
      alert("검색할 업체명을 입력하세요(2자이상).");
      return;
    }

    this.setState((prevState) => ({...prevState, searchComp : 1, loading: true}));

    ChatStoreInstance.searchCompany(searchWord, this.onSearchResult);
  }

  onSearchResult = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setState((prevState) => ({...prevState, searchComp : 0, loading: false}));

      alert("업체 검색중 오류가 발생했습니다.");

      return;
    }

    this.setLoading(false);
  }

  //대화내영에서 검색하기
  handleSearchTalk = (searchWord) =>
  {
    if (LOG_ENABLE)
      console.log("handleSearchTalk : " + searchWord);

    if (!IsValidS(searchWord))
    {
      alert("검색어를 입력하세요.");
      return;
    }

    //
    this.searchedTalkList = ChatStoreInstance.searchTalk(searchWord);

    let count = IsValidS(this.searchedTalkList) ? this.searchedTalkList.length : 0;

    this.setState((prevState) => ({...prevState, searchTalkMode : true, searchTalkCount : count, searchTalkIndex : 0,
      searchTalkIdx : count > 0 ? this.searchedTalkList[0].idx : undefined
    }));

    //검색된 내용이 있으면, 첫번째 검색어로 이동한다.
    if (count > 0)
    {
      let item = document.getElementById("ti" + this.searchedTalkList[0].idx);

      if (IsValidV(item))
      {
        //this.moveTalkScrollPos(item.offsetTop);
        item.scrollIntoView();
      }
    }
  }

  //검색된 대화내용 탐색
  handleSearchMove = (moveUp) =>
  {
    if (LOG_ENABLE)
      console.log("handleSearchMove : " + moveUp);

    if (moveUp)
    {
      if (this.state.searchTalkIndex > 0)
      {
        if (LOG_ENABLE)
          console.log("move up");

        let newIndex = this.state.searchTalkIndex - 1;

        this.setState((prevState) => ({...prevState, searchTalkIndex : newIndex, searchTalkIdx : this.searchedTalkList[newIndex].idx}));

        let item = document.getElementById("ti" + this.searchedTalkList[newIndex].idx);

        if (IsValidV(item))
        {
          //this.moveTalkScrollPos(item.offsetTop);
          item.scrollIntoView();
        }
      }
    }
    else
    {
      if (this.state.searchTalkIndex < (this.state.searchTalkCount - 1))
      {
        if (LOG_ENABLE)
          console.log("move down");

        let newIndex = this.state.searchTalkIndex + 1;

        this.setState((prevState) => ({...prevState, searchTalkIndex : newIndex, searchTalkIdx : this.searchedTalkList[newIndex].idx}));

        let item = document.getElementById("ti" + this.searchedTalkList[newIndex].idx);

        if (IsValidV(item))
        {
          //this.moveTalkScrollPos(item.offsetTop);
          item.scrollIntoView();
        }
      }
    }
  }

  //검색된 대화목록에서 현재 선택된 대화 찾음
  getSearchdTalkIdx = () =>
  {
    if (!this.state.searchTalkMode)
      return undefined;

    if (!IsValidS(this.searchList) || this.state.searchTalkIndex < 0 || this.state.searchTalkIndex >= this.searchList.length)
      return undefined;

    return this.searchList[this.state.searchTalkIndex];
  }

  handleTalkSearchClose = () =>
  {
    this.setState((prevState) => ({...prevState, searchTalkMode : false, searchTalkIdx: undefined}));
  }

  handleSendTalk = (talk) =>
  {
    if (LOG_ENABLE)
      console.log("handleSendTalk : " + talk);

    if (!ChatStoreInstance.connected)
    {
      alert("현재 채팅 서버에 접속되어 있지 않습니다. 잠시 후 이용해 주세요.");
      return;
    }

    if (!IsValidS(talk))
      return;

    ChatStoreInstance.sendChat(0, talk, "n", this.state.talkUserIdx);
  }

  handleStopTalk = () =>
  {
    if (LOG_ENABLE)
      console.log(`handleStopTalk : ${this.state.talkUserIdx}`);

    if (this.state.talkUserIdx !== -1)
    {
      ChatStoreInstance.sendTalkStop(this.state.talkUserIdx);

      this.setState((prevState) => ({...prevState, selectedIdx : -1, talkUserIdx : -1, searchTalkMode : false, currentPage : 0}));
    }
  }

  sendFile = (fileName, file) =>
  {
    if (LOG_ENABLE)
      console.log("sendFile : " + fileName);

    if (this.state.talkUserIdx === -1){
      return;
    }

    if (!ChatStoreInstance.connected)
    {
      alert("현재 채팅서버와 접속되어 있지 않습니다. 잠시 후 이용해 주세요.");
      return;
    }

    if (IsValidV(file) && IsValidS(fileName))
    {
      let upperName = file.name.toUpperCase();

      if (IsValidS(upperName))
      {
        if (upperName.indexOf('.JSP') >= 0 || upperName.indexOf('.PH') >= 0 || upperName.indexOf('.PHP') >= 0 || upperName.indexOf('.HTM') >= 0 || 
          upperName.indexOf('.ASP') >= 0 || upperName.indexOf('.VB') >= 0 || upperName.indexOf('.CSS') >= 0 || upperName.indexOf('.JS') >= 0 ||
          upperName.indexOf('.JAVA') >= 0 || upperName.indexOf('.EXE') >= 0 || upperName.indexOf('.COM') >= 0 || upperName.indexOf('.ADE') >= 0 || 
          upperName.indexOf('.APP') >= 0 || upperName.indexOf('.BAT') >= 0 || upperName.indexOf('.CAB') >= 0 || 
          upperName.indexOf('.CMD') >= 0 || upperName.indexOf('.SH') >= 0 || upperName.indexOf('.CHM') >= 0 || upperName.indexOf('.CPL') >= 0 || 
          upperName.indexOf('.DLL') >= 0 || upperName.indexOf('.DMG') >= 0 || upperName.indexOf('.EX') >= 0 || upperName.indexOf('.HTA') >= 0 || 
          upperName.indexOf('.JAR') >= 0 || upperName.indexOf('.ISP') >= 0 || upperName.indexOf('.INS') >= 0 || upperName.indexOf('.LIB') >= 0 || 
          upperName.indexOf('.LNK') >= 0 || upperName.indexOf('.MDE') >= 0 || upperName.indexOf('.MSC') >= 0 || upperName.indexOf('.MSI') >= 0 || 
          upperName.indexOf('.MSP') >= 0 || upperName.indexOf('.MST') >= 0 || upperName.indexOf('.NSH') >= 0 || upperName.indexOf('.PIF') >= 0 || 
          upperName.indexOf('.SCR') >= 0 || upperName.indexOf('.SCT') >= 0 || upperName.indexOf('.SHB') >= 0 || upperName.indexOf('.SYS') >= 0 || 
          upperName.indexOf('.VXD') >= 0 || upperName.indexOf('.WSC') >= 0 || upperName.indexOf('.WSF') >= 0 || upperName.indexOf('.WSH') >= 0 ||
          upperName.indexOf('.XML') >= 0 || upperName.indexOf('.CLA') >= 0 || upperName.indexOf('.WAR') >= 0 || upperName.indexOf('.HTACCESS') >= 0 || 
          upperName.indexOf('.JSON') >= 0 || upperName.indexOf('.TS') >= 0 || upperName.indexOf('.VXD') >= 0 || upperName.indexOf('.PY') >= 0)
        {
          if (LOG_ENABLE)
            console.log(upperName);
            
          alert("해당 형식의 파일은 업로드 할 수 없습니다.");
          return;
        }
      }

      this.uploadInfo = {fn : fileName, fl : file, isFile : true};

      UploadFile(LoginStoreInstance.userIdx, 1, file, this.onUploadDone)
    }
  }

  sendImage = (fileName, file) =>
  {
    if (LOG_ENABLE)
      console.log("sendImage : " + fileName);
      
    if (this.state.talkUserIdx === -1){
      return;
    }

    if (!ChatStoreInstance.connected)
    {
      alert("현재 채팅서버와 접속되어 있지 않습니다. 잠시 후 이용해 주세요.");
      return;
    }

    if (IsValidV(file) && IsValidS(fileName))
    {
      let upperName = file.name.toUpperCase();

      if (IsValidS(upperName))
      {
        if (upperName.indexOf('.JSP') >= 0 || upperName.indexOf('.PH') >= 0 || upperName.indexOf('.PHP') >= 0 || upperName.indexOf('.HTM') >= 0 || 
          upperName.indexOf('.ASP') >= 0 || upperName.indexOf('.VB') >= 0 || upperName.indexOf('.CSS') >= 0 || upperName.indexOf('.JS') >= 0 ||
          upperName.indexOf('.JAVA') >= 0 || upperName.indexOf('.EXE') >= 0 || upperName.indexOf('.COM') >= 0 || upperName.indexOf('.ADE') >= 0 || 
          upperName.indexOf('.ASP') >= 0 || upperName.indexOf('.APP') >= 0 || upperName.indexOf('.BAT') >= 0 || upperName.indexOf('.CAB') >= 0 || 
          upperName.indexOf('.CMD') >= 0 || upperName.indexOf('.SH') >= 0 || upperName.indexOf('.CHM') >= 0 || upperName.indexOf('.CPL') >= 0 || 
          upperName.indexOf('.DLL') >= 0 || upperName.indexOf('.DMG') >= 0 || upperName.indexOf('.EX') >= 0 || upperName.indexOf('.HTA') >= 0 || 
          upperName.indexOf('.JAR') >= 0 || upperName.indexOf('.ISP') >= 0 || upperName.indexOf('.INS') >= 0 || upperName.indexOf('.LIB') >= 0 || 
          upperName.indexOf('.LNK') >= 0 || upperName.indexOf('.MDE') >= 0 || upperName.indexOf('.MSC') >= 0 || upperName.indexOf('.MSI') >= 0 || 
          upperName.indexOf('.MSP') >= 0 || upperName.indexOf('.MST') >= 0 || upperName.indexOf('.NSH') >= 0 || upperName.indexOf('.PIF') >= 0 || 
          upperName.indexOf('.SCR') >= 0 || upperName.indexOf('.SCT') >= 0 || upperName.indexOf('.SHB') >= 0 || upperName.indexOf('.SYS') >= 0 || 
          upperName.indexOf('.VXD') >= 0 || upperName.indexOf('.WSC') >= 0 || upperName.indexOf('.WSF') >= 0 || upperName.indexOf('.WSH') >= 0 ||
          upperName.indexOf('.XML') >= 0 || upperName.indexOf('.CLA') >= 0 || upperName.indexOf('.WAR') >= 0 || upperName.indexOf('.HTACCESS') >= 0 || 
          upperName.indexOf('.JSON') >= 0 || upperName.indexOf('.TS') >= 0 || upperName.indexOf('.VXD') >= 0 || upperName.indexOf('.PY') >= 0)
        {
          if (LOG_ENABLE)
            console.log(upperName);

          alert("해당 형식의 파일은 업로드 할 수 없습니다.");
          return;
        }
      }
      
      this.uploadInfo = {fn : fileName, fl : file, isFile : false};
      
      UploadFile(LoginStoreInstance.userIdx, 1, file, this.onUploadDone)
    }
  }

  onUploadDone = (success, oldName, newName) =>
  {
    if (!success || this.uploadInfo === null)
    {
      alert("파일 전송에 실패 했습니다.");
      return;
    }

    if (!ChatStoreInstance.connected)
    {
      alert("현재 채팅서버와 접속되어 있지 않습니다. 잠시 후 이용해 주세요.");
      return;
    }

    if (this.uploadInfo.isFile)
      ChatStoreInstance.sendFile(newName, oldName, this.state.talkUserIdx);
    else
      ChatStoreInstance.sendImage(newName, oldName, this.state.talkUserIdx);
  }

  handleDownload = (isFile, fileName, oriName) =>
  {
    if (LOG_ENABLE)
      console.log(`file = ${isFile}, name = ${fileName}, oriname = ${oriName}`);

    if (this.state.appOn)
      this.postMessage(JSON.stringify({action: "download", fn : fileName, on : oriName}));
    else
      DownloadFile(GetFileUrl(fileName), oriName);
  }

  handleTalkScroll = (e) =>{
    
    if (e.target.scrollTop === 0)
    {
      if (LOG_ENABLE)
        console.log("handleTalkScroll : top");

      if (!ChatStoreInstance.chatLoading)
      {
        //대화 스크롤바가 상단에 도착했다, 더읽을 채팅이 있으면 더 읽어온다.
        
        //데이터를 더 읽어 오기전에 일단 스크롤 위치를 기록해둔다. 나중에 채팅 목록을 더읽어왔을때 
        //새롭게 갱신된 스크롤 폭에서 이 값만큼 뺀 위치로 스크롤을 이동시켜줘야한다.
        if (IsValidV(this.talkRef.current))
        {
          this.talkScrollPos = this.talkRef.current.scrollHeight;
        }

        //아직 더 읽을 수 있는 채팅이 있으면
        if (ChatStoreInstance.totalTalkCount !== 0 && ChatStoreInstance.totalTalkCount > ChatStoreInstance.currentTalkCount)
        {
          if (LOG_ENABLE)
            console.log("handleTalkScroll : request more chat");

          this.moreTalkList = true;
          this.moreOn = true;
          ChatStoreInstance.loadLastChatList(this.state.talkUserIdx);
        }
      }
    }
  }

  handleScrollCompany = (e) =>{

    //console.log(`top = ${e.target.scrollTop}, height= ${e.target.scrollHeight}`);
    if (e.target.scrollTop === 0)
    {
      if (LOG_ENABLE)
        console.log("handleScrollCompany : top");
    }
    else if (e.target.scrollTop >= (e.target.scrollHeight - 730))
    {
      //스크롤바가 하단에 도착한경우, 업체 목록을 더읽어온다.
      if (LOG_ENABLE)
        console.log("handleScrollCompany : bottom");

      if (!ChatStoreInstance.chatListLoading && ChatStoreInstance.totalCompanyCount !== 0 && 
        ChatStoreInstance.totalCompanyCount > ChatStoreInstance.getTotalCompanycount)
      {
        ChatStoreInstance.loadLastNewChatList();
      }
    }
  }

  componentDidUpdate(){

    if (LOG_ENABLE)
      console.log("Chat.componentDidUpdate");

    if (this.needTalkInit)
    {
      if (LOG_ENABLE)
        console.log("componentDidUpdate : scroll");

      this.updateTalkScroll(false);
    }
  }

  updateTalkScroll = (forceUpdate) =>
  {
    if ((this.needTalkInit || forceUpdate) && IsValidV(this.talkRef.current))
    {
      if (IsValidV(this.talkRef.current.scrollTop) && IsValidV(this.talkRef.current.scrollHeight))
      {
        if (this.moreTalkList)
        {
          this.moreTalkList = false;
    
          this.moveTalkScrollPos(this.getTalkScrollHeight() - this.talkScrollPos);

          if (LOG_ENABLE)
            console.log("scroll to bottom : more");
        }
        else
        {
          this.talkRef.current.scrollTo(0, this.talkRef.current.scrollHeight - this.state.talkAreaHeight);

          if (LOG_ENABLE)
            console.log(`scroll to bottom : bottom : ${this.talkRef.current.scrollHeight}`);
        }

        this.needTalkInit = false;

        this.lastScrollHeight = this.getTalkScrollHeight();
      }
    }    
  }

  handleLogout = () =>
  {
    ProcessLogout();
    this.props.history.push("/"); 
  }

  handleToCompanyList = () =>
  {
    this.setState((prevState) => ({...prevState, currentPage : 0, selectedIdx : -1}));
  }

  handleToChat = () =>
  {
    ChatStoreInstance.clearChatList();

    if (this.state.talkUserIdx !== -1)
      ChatStoreInstance.loadLastChatList(this.state.talkUserIdx);

    this.setState((prevState) => ({...prevState, currentPage : 1}));
  }

  render()
  {
    if (mobileOn)
    {
      if (this.state.currentPage === 0) //업체 목록에 있는경우
      {
        return (
          <div className="chatcontmb flexcentercolstart">
    
            {
              this.state.loading ? <Loading />:null
            }
    
            {
              this.state.newChatOn ? <ChatAlarm item={this.state.newChat}/> : null
            }
            
            <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout={this.handleLogout}/>
    
            <div className="chatcontentcontmb flexcentercolstart">
              <ChatSerCompmb handleSearchCompany = {this.handleSearchCompany} listMode = {this.state.searchComp} 
                handleCloseSearch={this.handleCloseCompSearch}
                handleToChat={this.handleToChat}
              />
      
              <ChatCompany list={this.state.searchComp === 0 ? ChatStoreInstance.newChat : ChatStoreInstance.searchList} 
                selectedIdx = {this.state.selectedIdx} handleCompanyClick = {this.handleCompanyClick} handleStartTalk={this.handleStartTalk} 
                listMode={this.state.searchComp}
                handleScroll = {this.handleScrollCompany}
              />
            </div>
            
          </div>
        );
      }
      else //채팅 페이지에 있는경우
      {
        return (
          <div className="chatcontmb flexcentercolstart">
    
            {
              this.state.loading ? <Loading />:null
            }
    
            {
              this.state.newChatOn ? <ChatAlarm item={this.state.newChat}/> : null
            }
            
            <MenuMb handleMenuClick = {this.handleMenuClick} handleLogout={this.handleLogout}/>
    
            <div className="chatcontentcontmb flexcentercolstart">
              <div className="chatcontentmb flexcentercolstart">

                <div className="chatpoptopmb flexcenterrow">
                  <div className="dcell4 flexcenterrowstart">
                    <label className="mfontlargeb btncur" onClick={() => this.handleToCompanyList()}>{"< 거래처 대화 목록"}</label>
                  </div>

                  <div className="dcell2 flexcenterrow">
                    <label className="mfontlargeb">채팅</label>
                  </div>

                  <div className="dcell4"></div>
                </div>

                <div className="chathlinemb"></div>
                {
                  this.state.selectedIdx === -1 ? (
                    <Fragment>
                      <ChatEmptyTop/>
                      <ChatEmpty/>
                    </Fragment>
                  ):
                  (
                    <Fragment>
                      <ChatSerTalk handleStopTalk = {this.handleStopTalk} handleSearchTalk = {this.handleSearchTalk}
                        searched={this.state.searchTalkMode} 
                        handleSearchMove = {this.handleSearchMove}
                        handleSearchClose = {this.handleTalkSearchClose}
                        searchCount = {this.state.searchTalkCount}
                        searchIndex = {this.state.searchTalkIndex}
                        companyName = {this.state.companyName}
                      />
                      <ChatTalkList list = {ChatStoreInstance.talkList} handleScroll={this.handleTalkScroll} ref = {this.talkRef} 
                        talkList={ChatStoreInstance.talkList} searchedIdx={this.state.searchTalkIdx}
                        handleDownload = {this.handleDownload}
                        height = {this.state.talkAreaHeight}
                      />
                    </Fragment>
                  )
                }
                
                <ChatInputmb handleSendTalk = {this.handleSendTalk} sendFile = {this.sendFile} sendImage = {this.sendImage} selectedIdx = {this.state.selectedIdx} />
    
              </div>
            </div>
          </div>
        );
      }
    }
    else
    {
      return (
        <div className="chatcont flexcentercolstart">
  
          {
            this.state.loading ? <Loading />:null
          }
  
          {
            this.state.newChatOn ? <ChatAlarm item={this.state.newChat}/> : null
          }
          
  
          <div className="chatmenucont flexcenterrow">
            <MainMenu index={8} handleMenuClick = {this.handleMenuClick}/>
          </div>
          
          <div className="chatcontentcont flexcentercolstart">
            <div className="chatcontent">
  
              <div className="chatleft flexcentercolstart">
                <ChatSerComp handleSearchCompany = {this.handleSearchCompany} listMode = {this.state.searchComp} handleCloseSearch={this.handleCloseCompSearch}/>
  
                <ChatCompany list={this.state.searchComp === 0 ? ChatStoreInstance.newChat : ChatStoreInstance.searchList} 
                  selectedIdx = {this.state.selectedIdx} handleCompanyClick = {this.handleCompanyClick} handleStartTalk={this.handleStartTalk} 
                  listMode={this.state.searchComp}
                  handleScroll = {this.handleScrollCompany}
                />
              </div>
  
              <div className="chatright flexcentercolstart">
  
                {
                  this.state.selectedIdx === -1 ? (
                    <Fragment>
                      <ChatEmptyTop/>
                      <ChatEmpty/>
                    </Fragment>
                  ):
                  (
                    <Fragment>
                      <ChatSerTalk handleStopTalk = {this.handleStopTalk} handleSearchTalk = {this.handleSearchTalk}
                        searched={this.state.searchTalkMode} 
                        handleSearchMove = {this.handleSearchMove}
                        handleSearchClose = {this.handleTalkSearchClose}
                        searchCount = {this.state.searchTalkCount}
                        searchIndex = {this.state.searchTalkIndex}
                        companyName = {this.state.companyName}
                      />
                      <ChatTalkList list = {ChatStoreInstance.talkList} handleScroll={this.handleTalkScroll} ref = {this.talkRef} 
                        talkList={ChatStoreInstance.talkList} searchedIdx={this.state.searchTalkIdx}
                        handleDownload = {this.handleDownload}
                      />
                    </Fragment>
                  )
                }
                
                <ChatInput handleSendTalk = {this.handleSendTalk} sendFile = {this.sendFile} sendImage = {this.sendImage} selectedIdx = {this.state.selectedIdx} />
              </div>
  
            </div>
          </div>
          
        </div>
      );
    }
  }
}

export default observer(Chat);