import React from 'react';
import { GetFormattedPhone } from '../../Util/Util';

const UserLevelToName = (level, utype) =>
{
  if (utype === 1)
  {
    return "메인(Admin)";
  }

  switch(level)
  {
    case 0:
      return "Admin";
    case 1:
      return "일반관리자";
    case 2:
      return "영업 담당자";
    case 3:
      return "관리 담당자";
    default:
      return "알수 없음";
  }
}

const EmpInfo = (props) => {
  return (
    <tr className="emptabledata">
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <input type="radio" onChange={(e) => props.onCheckChanged(props.index)} className="chk" checked={props.selectIdx === props.index}/>
        </div>
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn btncur" onClick={() => props.handleEditDirect(props.index)}>{props.num}</label>
        </div>
      </td>
      
      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn btncur" onClick={() => props.handleEditDirect(props.index)}>{`${props.name}(${props.cid})`}</label>
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn btncur" onClick={() => props.handleEditDirect(props.index)}>{GetFormattedPhone(props.phone)}</label>
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{props.email}</label>
        </div>
      </td>

      <td className="emptablecell">
        <div className="emptablecellin flexcenterrow">
          <label className="fontn">{UserLevelToName(props.level, props.utype)}</label>
        </div>
      </td>
    </tr>
  );
}

export default EmpInfo;