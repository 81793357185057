//import { forwardRef } from "react";
import OrderAddItemmb from "./OrderAddItemmb";

//const OrderAddItemList = forwardRef((props, ref) =>
const OrderAddItemListmb = (props) =>
{
  const itemList = props.items;

  let items = null;

  if (itemList != null && itemList.length > 0)
  {
    items = itemList.map(item => {
      return (<OrderAddItemmb key={item.idx} item = {item} itemMode = {props.itemMode} addItem = {props.addItem}/>);
    });
  }

  return (
    <div className="oditemlistmb flexcentercolstart">
      {items}
    </div>
  );
}
//});

export default OrderAddItemListmb;