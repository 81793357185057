import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
//import AsyncStorage from '@react-native-community/async-storage';
import {GetApiUrl, LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

class AlarmStore {
  //observable
  loading = false; //수신중인가?
  removing = false; //삭제 중인가?
  alarmList = null; //알림목록
  //----------------------------------------------------------------------------------------------------------------------------------------
  //비observable
  alarmIdx = -1; //이유저가 최종적으로 읽은 공지의 고유번호

  done = false; //더이상 읽을알림이 없는경우 true
  defaultDone = false; //기본읽기가 완료된경우 true
  companyIdx = -1; //회사고유번호
  userIdx = -1; //유저고유번호

  minAlarmIdx = -1; //수신한 알림중 고유번호가 가장 작은것의 번호
 
  //모든 데이터를 초기화 시킴
  clearAll = () => {
    this.loading = false; //수신중인가?
    this.removing = false; //삭제 중인가?
    this.alarmList = null; //알림목록
     //----------------------------------------------------------------------------------------------------------------------------------------
    //비observable
     this.alarmIdx = -1; //이유저가 최종적으로 읽은 공지의 고유번호
  
    this.done = false; //더이상 읽을알림이 없는경우 true
    this.defaultDone = false; //기본읽기가 완료된경우 true
    this.companyIdx = -1; //회사고유번호
    this.userIdx = -1; //유저고유번호
  
    this.minAlarmIdx = -1; //수신한 알림중 고유번호가 가장 작은것의 번호
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      loading : observable,
      removing : observable,
      alarmList : observable,

      addNewAlarm : action,
      
      loadDefaultAlarm : action,
      parseAlarm : action,

      loadNextAlarm : action,
      parseNextAlarmResult : action,
      clearNewMark : action,
      
      removeAlarm : action,
      clearAlarm : action,
      clearDone : action,

      clearAll : action,

      parseSaveTokenResult : action,

      getAlarms: computed,
      getAlarmCount : computed,
      getNewCount : computed,
    });
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  makeDefaultParam = () => {
    const params = new URLSearchParams();
    params.append("cidx", this.companyIdx.toString());
    params.append("cnt", "20");
    params.append("idx", this.userIdx.toString());
    params.append("md", "1");

    return params;
  }

  //기본적인 전체/개별 공지 목록을 서버로 부터 수신하기
  loadDefaultAlarm = (companyIdx, userIdx) => {

    this.companyIdx = companyIdx;
    this.userIdx = userIdx;

    //이미 로딩중이면
    if (this.loading)
    {
      if (LOG_ENABLE)
        console.log("already read");
      return;
    }

    //로딩중 설정하고
    this.loading = true;

    axios({
      method:"POST",
      url: GetApiUrl("notice/allarm.do"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeDefaultParam()
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("default alarm loading done.");

      this.parseAlarm(res.data);

    }).catch(error=>{

      if (LOG_ENABLE)
        console.log(error);
      
      this.parseAlarm(null);
    });
  }

  parseAlarm = (result) => {

    if (!IsValidV(result))
    {
      this.defaultDone = true;
      this.loading = false;
      return;
    }

    //console.log(JSON.stringify(result));

    this.defaultDone = true;
    this.loading = false;

    if (result.ret === 0)
    {
      //알림이 있으면
      if (IsValidS(result.list))
      {
        this.alarmList = result.list;
      }
      else
        this.done = true; //더이상 알림이 없다고 설정

      if (IsValidS(this.alarmList))
      {
        this.alarmList.forEach(element => {

          if (IsValidS(element.title))
            element.title = FromBase64(element.title);

          if (IsValidS(element.notice))
            element.notice = FromBase64(element.notice);

            //알림중 고유번호가 가장 작은것의 번호를 구해둠
            if (this.minAlarmIdx === -1 || this.minAlarmIdx > element.idx)
              this.minAlarmIdx = element.idx;
        });
      }
      else
        this.alarmList = null;
    }
    else
    {
      if (result.ret === 100)
      {
        //세션 오류인경우
        LoginStoreInstance.sessionError = true;
      }

      if (LOG_ENABLE)
        console.log("error occurred : " + result.msg);

      this.done = true;
    }
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  makeNextParam = () => {
    const params = new URLSearchParams();
    params.append("midx", this.minAlarmIdx.toString());
    params.append("cnt", "20");
    params.append("cidx", this.companyIdx.toString());
    params.append("idx", this.userIdx.toString());
    params.append("md", "1");

    return params;
  }
  //다음 알림 목록 읽기 요청
  loadNextAlarm = () => {

    //더이상 읽을 알림이 없으면
    if (this.done)
    {
      if (LOG_ENABLE)
        console.log("no alarm");
      return;
    }

    if (this.loading || !this.defaultDone)
    {
      if (LOG_ENABLE)
        console.log("already read");
      return;
    }

    this.loading = true;

    axios({
      method:"POST",
      url: GetApiUrl("notice/narm.do"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeNextParam()
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("next alarm loading done.");

      this.parseNextAlarmResult(res.data);
    }).catch(error=>{

      if (LOG_ENABLE)
        console.log("load alarm error : " + error);

      this.parseNextAlarmResult(null);
    });
  }

  parseNextAlarmResult = (result) =>
  {
    this.loading = false;

    if (!IsValidV(result))
    {
      return;
    }

    if (IsValidV(result))
    {
      if (result.ret === 0)
      {
        //알림이 있으면
        if (IsValidS(result.list))
        {
          this.addNewAlarm(result.list);
        }
        else
          this.done = true; //더이상 전역 공지 없다고 설정
      }
      else if (result.ret === 100)
      {
        //세션 오류인경우
        LoginStoreInstance.sessionError = true;
      }
    }
    else
      this.done = true; //더이상 전역 공지 없다고 설정
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  makeClearParam = (clearIdx) => {
    const params = new URLSearchParams();
    params.append("aidx", clearIdx.toString());
    params.append("idx", this.userIdx.toString());
    params.append("md", "1");

    return params;
  }

  //특정 알림을 배열에서 삭제만 한다.
  removeAlarm = (clearIdx) => {
    if (!IsValidS(this.alarmList))
      return false;

    let found = false;

    this.alarmList = this.alarmList.filter(element => {

      if (element.idx === clearIdx)
      {
        found = true;
        return false;
      }


      return true;
    });

    return found;
  }

  clearDone = () => {
    this.loading = false;
    this.removing = false;
  }

  //특정 알림을 삭제 처리한다.
  clearAlarm = (clearIdx) => {

    //현재 공지를 읽고 있거나, 기본 공지가 아직 읽어지지 않았다면
    if (this.loading || !this.defaultDone || this.removing)
    {
      if (LOG_ENABLE)
        console.log("already read");

      return;
    }

    if (!this.removeAlarm(clearIdx))
    {
      if (LOG_ENABLE)
        console.log("alarm not found");

      return;
    }

    this.loading = true;
    this.removing = true;

    axios({
      method:"POST",
      url: GetApiUrl("notice/clrarm.do"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeClearParam(clearIdx)
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("next alarm loading done : " + JSON.stringify(res.data));

      this.clearDone();

      if (IsValidV(res.data))
      {
        if (res.data.ret === 0)
        {

        }
        else if (res.data.ret === 100)
        {
          //세션 오류인경우
          LoginStoreInstance.sessionError = true;
        }
        
        if (LOG_ENABLE)
          console.log("delete alarm succeeded");
      }
      else
        alert("알림을 삭제할 수 없습니다. 인터넷 연결 상태를 확인해 주세요.");

    }).catch(error=>{

      if (LOG_ENABLE)
        console.log("clear alarm error : " + error);
      
      this.clearDone();

      alert("알림을 삭제할 수 없습니다. 인터넷 연결 상태를 확인해 주세요.");
    });
  }
  //푸시알림 토큰 저장----------------------------------------------------------------------------------------------------------------------------------------
  makeSaveTokenParam = (pushIdx, userIdx, pushToken) => {
    const params = new URLSearchParams();
    params.append("pidx", pushIdx.toString()); //푸시알림설정 고유번호
    params.append("idx", userIdx.toString()); //요청자 고유번호
    params.append("tk", pushToken); //푸시알림 토큰
    params.append("md", "1");

    return params;
  }

  //푸시 알림 수신여부를 변경함
  saveToken = (pushIdx, userIdx, pushToken) => {

    if (pushIdx === -1)
    {
      console.log("invalid push config");
      return;
    }

    axios({
      method:"POST",
      url: GetApiUrl("notice/token.do"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSaveTokenParam(pushIdx, userIdx, pushToken)
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("push token set done : " + JSON.stringify(res.data));

      this.parseSaveTokenResult(res.data);
    }).catch(error=>{

      if (LOG_ENABLE)
        console.log("change push error : " + error);
      
      this.parseSaveTokenResult(null);
    });
  }

  parseSaveTokenResult = (result) =>
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("save token failed.");

      return;
    }

    if (result.ret !== 0)
    {
      if (LOG_ENABLE)
        console.log("save token failed2.");

      return;
    }
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  //고유번호가 가장 작은것의 번호를 구하기
  getMinAlarmIdx = () => {
    if (this.alarmList === null || this.alarmList.length < 1)
      return -1;

    let min = -1;

    this.alarmList.forEach(element => {
      if (element.idx < min || min === -1)
        min = element.idx;
    });

    return min;
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  //서버로 부터 수신된 알림을 목록에 추가하기
  addNewAlarm = (alarmList) => {

    if (!IsValidS(alarmList))
    {
      return;
    }

    alarmList.forEach(element => {

      if (IsValidS(element.title))
        element.title = FromBase64(element.title);

      if (IsValidS(element.notice))
        element.notice = FromBase64(element.notice);

      //알림중 고유번호가 가장 작은것의 번호를 구해둠
      if (this.minAlarmIdx === -1 || this.minAlarmIdx > element.idx)
        this.minAlarmIdx = element.idx;
    });

    if (this.alarmList === null)
      this.alarmList = alarmList;
    else
      this.alarmList = this.alarmList.concat(alarmList);
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  //새로 수신된 알림들의 신규 표시 마크를 클리어하기
  clearNewMark = () => {
    if (this.alarmList === null)
      return;

      this.alarmList.forEach(element => {

        if (element.rd === 0)
          element.rd = 1;
      });
  }
  
  //알림 개수 얻기
  get getAlarmCount() {
    if (!IsValidS(this.alarmList))
      return 0;

    return this.alarmList.length;
  }

  //알림 목록 얻기
  get getAlarms() {
    return this.alarmList;
  }

  //수신된 알림중 신규 알림의 개수를 구함
  get getNewCount() {
    let cnt = 0;

    if (this.alarmList != null)
    {
      this.alarmList.forEach(element => {
        if (element.rd === 0)
          ++cnt;
      });
    }

    return cnt;
  }
}

const AlarmStoreInstance = new AlarmStore();
const AlarmStoreContext = React.createContext(AlarmStoreInstance);

const UseAlarmStore = () => React.useContext(AlarmStoreContext);

export {UseAlarmStore, AlarmStoreInstance};
