//import logo from './logo.svg';
import './App.css';
import { /*BrowserRouter, */HashRouter, Route, Switch } from "react-router-dom";
import Login from "./components/Login/Login";
import Main from './components/Main/Main';
import Staff from './components/Emp/Staff';
import Ret from './components/Ret/Ret';
import Order from './components/Order/Order';
import Report from './components/Report/Report';
import Product from './components/Product/Product';
import Notice from './components/Notice/Notice';
import Chat from './components/Chat/Chat';
import OrderMisc from './components/OrderMisc/OrderMisc';
import ChatApp from './ChatApp/ChatApp';
import TermApp from './Term/TermApp';
import PrivacyApp from './Term/PrivacyApp';
import Alarm from './components/Alarm/Alarm';
import AppStats from './components/Stats/AppStats';
import MnuOrder from './components/MnuOrder/MnuOrder';
//import ChatPrepare from './components/Chat/ChatPrepare';

//import Navigation from "./components/Navigation";

function App() {
  return (
    <HashRouter>
        
        {/* Switch : 일치하는 첫번째 라우트를 렌더링 한다.*/}

        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/main" component={Main} />
          <Route exact path="/emp" component={Staff} />
          <Route exact path="/reta" component={Ret} />
          <Route exact path="/order" component={Order} />
          <Route exact path="/orderm" component={MnuOrder} />
          <Route exact path="/report" component={Report} />
          <Route exact path="/goods" component={Product} />
          <Route exact path="/notice" component={Notice} />
          <Route exact path="/chat" component={Chat} />
          <Route exact path="/morder" component={OrderMisc} />
          <Route exact path="/achat" component={ChatApp} />
          <Route exact path="/termapp" component={TermApp} />
          <Route exact path="/privacyapp" component={PrivacyApp} />
          <Route exact path="/alarm" component={Alarm} />
          <Route exact path="/stats" component={AppStats} />
        </Switch>
        
      </HashRouter>
  );

  //<Route exact path="/chat" component={Chat} />
  //<Route exact path="/achat/:ses" component={ChatApp} />
  //
}

export default App;
