/*eslint arrow-body-style: ["error", "as-needed"]*/
/*eslint-env es6*/

import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import {LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV,  } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import { getCookie, setCookie } from '../Util/CookieManager';

const ODS_ACCEPT = 0; //주문 접수됨
const ODS_PROCESSED = 1; //음성주문 처리됨
const ODS_UNDELI = 5; //미배송주문
const ODS_DELIS = 10; //배송시작됨
const ODS_DELIE = 11; //배송완료
const ODS_REORDER = 12; //재주문 요청됨

//주문 관련
class OrderStore {
  //observable변수
  orderList = null;
  dailyOrder = null; //판매 일보용 주문 목록, 주문한 업체들의 목록이다.
  aorder = 0; //앱주문개수
  vorder = 0; //음성주문개수
  morder = 0; //기타주문개수

  //비 observable
  totalOrder = 0; //전체 주문
  orderInCompletedCount = 0; //아직 처리되지 않은 음성 주문의 개수
  selectedSalesIdx = -1;
  deliveryStarted = false; //배송이 시작되었나?
  orderDone = false; //주문이 선마감되었나?
  printFontSize = 9;
  totalInfocheck = false;

  clearAll = () =>
  {
    this.orderList = null;
    this.dailyOrder = null; //판매 일보용 주문 목록
    this.aorder = 0; //앱주문개수
    this.vorder = 0; //음성주문개수
    this.morder = 0; //기타주문개수
  
    //비 observable
    this.totalOrder = 0; //전체 주문
    this.orderInCompletedCount = 0; //아직 처리되지 않은 음성 주문의 개수
    
    this.deliveryStarted = false; //배송이 시작되었나?
    this.orderDone = false; //주문이 선마감되었나?
    this.dailyReport = null;//일일판매일보 출력을 위한 데이터

    this.countCallback = null;
    this.loadCallback = null;
    this.addCallback = null;
    this.delCallback = null;
    this.editCallback = null;
    this.doneCallback = null;
    this.reportCallback = null;
    this.lastQueryIdx = -1;
    this.lastSalesIdx = -1;

    this.startDeliCallback = null;
    this.deliDoneCallback = null;

    this.manualReportCallback = null;
    this.manualOrderCount = 0;
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      orderList : observable,
      dailyOrder: observable,
      aorder : observable,
      vorder : observable,
      morder : observable,

      clearAll : action,

      loadOrderCount : action,
      parseCountResult : action,

      loadOrder : action,
      parseLoadResult : action,

      addProduct : action,
      parseAddResult : action,

      delProduct : action,
      parseDelResult : action,

      editProduct : action,
      parseEditResult : action,

      requestVOrderDone : action,
      parseVDResult : action,

      clearOrderList : action,
      getOrderList : computed,

      clearDailyOrder : action,
      getDailyOrder : computed,

      loadDailyReport : action,
      parseDailyReport : action,

      parseManualReportResult : action,

      requestDeliveryDone : action,
      parseDDResult : action,

      startDelivery : action,
      parseSDResult : action,

      setDailyOrderDeliNumber : action,
      generateDeliTotalInfo : action,
    });
  }
  
  //주문 목록 클리어
  clearOrderList = () =>
  {
    this.orderList = null; //목록
  }

  //주문목록
  get getOrderList()
  {
    return this.orderList;
  }

  clearDailyOrder = () =>
  {
    this.dailyOrder = null;
  }
  //판매일보 주문 목록
  get getDailyOrder()
  {
    return this.dailyOrder;
  }

  findOrderByIdx = (idx) =>
  {
    if (this.orderList == null || this.orderList.length < 1)
      return null;

    for (let i = 0; i < this.orderList.length; ++i)
    {
      if (this.orderList[i].idx === idx)
        return this.orderList[i];
    }

    return null;
  }
  //목록 개수 읽기-------------------------------------------------------------------------------------------------------------------------------------
  countCallback = null;

  makeCountParam (dayInfo, salesIdx, requestType) {
    const params = new URLSearchParams();

    params.append("sd", dayInfo);
    params.append("rt", requestType.toString());
    params.append("sidx", salesIdx.toString());

    return params;
  }

  //dayInfo : 조회일자
  //requestType : 요청 타입, 0 : 전체 주문, 1 : 기타 주문만, 2 : 미배송 주문만
  loadOrderCount = (dayInfo, salesIdx, requestType, callback) =>
  {
    this.countCallback = callback;

    axios({
      method:"POST",
      url: ("order/cnt.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeCountParam(dayInfo, salesIdx, requestType)
    }).then((res)=>{
        this.parseCountResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseCountResult(null);
    });
  }

  parseCountResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseCountResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.countCallback != null)
        this.countCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      if (LOG_ENABLE)
        console.log("session error");

      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (result.rt === 0 || result.rt === 2)//요청타입이 전체 주문 개수이면
      {
        this.aorder = result.ac; //앱주문개수
        this.vorder = result.vc; //음성주문개수
  
        this.totalOrder = this.aorder + this.vorder; //전체 주문
        this.orderInCompletedCount = result.vnc; //아직 처리되지 않은 음성 주문의 개수

        this.orderDone = result.od !== 0; //주문 마감여부
      }
      else //기타 주문 개수만 요청했으면
      {
        this.morder = result.mc; //기타주문개수
      }
      
      this.orderList = null;
    }

    if (this.countCallback != null)
        this.countCallback(result.ret);
  }
  //목록 읽기-------------------------------------------------------------------------------------------------------------------------------------
  loadCallback = null;

  makeLoadParam (salesIdx, lt, pos, dayInfo) {
    const params = new URLSearchParams();

    params.append("lt", lt.toString());
    params.append("pos", pos.toString());
    params.append("sd", dayInfo);
    params.append("sidx", salesIdx.toString());
    
    return params;
  }

  //lt : -1, 전체(앱 + 음성), 0 : 앱, 1 : 음성, 2 : 기타, 3 : 미배송
  loadOrder = (salesIdx, lt, pos, dayInfo, callback) =>
  {
    this.loadCallback = callback;

    if (LOG_ENABLE)
    {
      console.log(`lt = ${lt}, pos = ${pos}, day = ${dayInfo}`);
    }

    axios({
      method:"POST",
      url: ("order/list.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeLoadParam(salesIdx, lt, pos, dayInfo)
    }).then((res)=>{
        this.parseLoadResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseLoadResult(null);
    });
  }

  parseLoadResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseLoadResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.loadCallback != null)
        this.loadCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      this.orderList = result.list;

      if (IsValidS(this.orderList))
      {
        this.orderList.forEach(element => {
          this.decodeOrder(element);
        });
      }
      else
        this.orderList = null;
    }
    else
      this.orderList = null;

    if (this.loadCallback != null)
        this.loadCallback(result.ret);
  }

  decodeOrder = (element) =>
  {
    if (IsValidS(element.cname))
      element.cname = FromBase64(element.cname);

    if (IsValidS(element.uname))
      element.uname = FromBase64(element.uname);

    if (IsValidS(element.addr))
      element.addr = FromBase64(element.addr);

    if (IsValidS(element.sname))
      element.sname = FromBase64(element.sname);

    if (IsValidS(element.items))
    {
      element.items.forEach(item => {
        this.decodeOrderItem(item);
      });
    }
  }

  decodeOrderItem = (element) =>
  {
    if (IsValidS(element.iname))
      element.iname = FromBase64(element.iname);

    if (IsValidS(element.alias))
      element.alias = FromBase64(element.alias);

    if (IsValidS(element.dt))
      element.dt = FromBase64(element.dt);
  }
  //추가-------------------------------------------------------------------------------------------------------------------------------------
  addCallback = null;

  makeAddParam (orderIdx, itemIdx, boxCount, bottleCount) {
    const params = new URLSearchParams();

    params.append("idx", orderIdx.toString());
    params.append("bcnt", boxCount.toString());
    params.append("ecnt", bottleCount.toString());
    params.append("iidx", itemIdx.toString());
    return params;
  }

  //orderIdx : 주문고유번호, itemIdx : 주문상품 고유번호, count : 주문개수
  addProduct = (orderIdx, itemIdx, boxCount, bottleCount, callback) =>
  {
    this.addCallback = callback;

    axios({
      method:"POST",
      url: ("order/additem.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeAddParam(orderIdx, itemIdx, boxCount, bottleCount)
    }).then((res)=>{
        this.parseAddResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseAddResult(null);
    });
  }

  parseAddResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseAddResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.addCallback != null)
        this.addCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      const order = this.findOrderByIdx(parseInt(result.idx));

      if (order !== null && result.item !== undefined && result.item !== null)
      {
        this.decodeOrderItem(result.item);

        let newList = [];

        if (order.items === undefined || order.items.length < 1)
        {
          newList.push(result.item);

          order.items = newList;
        }
        else
        {
          let found = false;

          for (let i = 0;i < order.items.length; ++i)
          {
            if (order.items[i].idx === result.item.idx)
            {
              found = true;
              newList.push(result.item);
            }
            else
            {
              newList.push(order.items[i]);
            }
          }

          if (!found)
          {
            newList.push(result.item);
          }

          order.items = newList;
        }
        
        this.orderList = this.orderList.slice();
      }
    }

    if (this.addCallback != null)
        this.addCallback(result.ret);
  }
  //삭제-------------------------------------------------------------------------------------------------------------------------------------
  delCallback = null;

  makeDelParam (orderIdx, itemIdx) {
    const params = new URLSearchParams();

    params.append("oidx", orderIdx.toString());
    params.append("idx", itemIdx.toString());
    return params;
  }

  //orderIdx : 주문번호, itemIdx : 주문 아이템 번호
  delProduct = (orderIdx, itemIdx, callback) =>
  {
    this.delCallback = callback;

    axios({
      method:"POST",
      url: ("order/del.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeDelParam(orderIdx, itemIdx)
    }).then((res)=>{
        this.parseDelResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseDelResult(null);
    });
  }

  parseDelResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseDelResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.delCallback != null)
        this.delCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      const order = this.findOrderByIdx(parseInt(result.oidx));

      if (order !== null)
      {
        if (order.items === undefined || order.items.length < 1)
        {
        }
        else
        {
          let newList = [];

          const iidx = parseInt(result.idx);

          for (let i = 0;i < order.items.length; ++i)
          {
            if (order.items[i].idx !== iidx)
            {
              newList.push(order.items[i]);
            }
          }

          order.items = newList;
        }

        this.orderList = this.orderList.slice();
      }
    }

    if (this.delCallback != null)
        this.delCallback(result.ret);
  }
  //수정-------------------------------------------------------------------------------------------------------------------------------------
  editCallback = null;

  makeEditParam (orderIdx, itemIdx, boxCount, bottleCount) {
    const params = new URLSearchParams();

    params.append("oidx", orderIdx.toString());
    params.append("idx", itemIdx.toString());
    params.append("bcnt", boxCount.toString());
    params.append("ecnt", bottleCount.toString());
    return params;
  }

  editProduct = (orderIdx, itemIdx, boxCount, bottleCount, callback) =>
  {
    this.editCallback = callback;

    axios({
      method:"POST",
      url: ("order/edititem.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeEditParam(orderIdx, itemIdx, boxCount, bottleCount)
    }).then((res)=>{
        this.parseEditResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseEditResult(null);
    });
  }

  parseEditResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseEditResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.editCallback != null)
        this.editCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      const order = this.findOrderByIdx(parseInt(result.oidx));

      if (order !== null)
      {
        if (IsValidS(order.items))
        {
          let idx = parseInt(result.idx);

          for (let i = 0;i < order.items.length; ++i)
          {
            if (order.items[i].idx === idx)
            {
              order.items[i].bcnt = parseInt(result.bcnt);
              order.items[i].ecnt = parseInt(result.ecnt);
              break;
            }
          }

          this.orderList = this.orderList.slice();
        }
      }
    }

    if (this.editCallback != null)
      this.editCallback(result.ret);
  }
  //주문 완료처리-------------------------------------------------------------------------------------------------------------------------------------
  doneCallback = null;

  makeVDParam (orderIdx, orderType, orderDate) {
    const params = new URLSearchParams();

    if (IsValidV(orderIdx))
      params.append("idx", orderIdx.toString());

    params.append("ot", orderType.toString());

    if (IsValidV(orderDate))
      params.append("tm", orderDate);

    return params;
  }

  //orderType : 0 : 음성주문, 1 : 기타주문, 2 : 앱주문, 3 : 당일배송으로, 4 : 익일배송으로, 5 : 오늘 주문 마감하기, 6 : 재주문 요청
  //7 : 주문을 특정일자로 이동(기본), 8 ; 주문을 특정 일자로 이동(추가)
  requestVOrderDone = (orderIdx, orderType, orderDate, callback) =>
  {
    this.doneCallback = callback;

    axios({
      method:"POST",
      url: ("order/vdone.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeVDParam(orderIdx, orderType, orderDate)
    }).then((res)=>{
        this.parseVDResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseVDResult(null);
    });
  }

  parseVDResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseVDResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.doneCallback != null)
        this.doneCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      const requestType = parseInt(result.ot);

      if (requestType === 5) //주문 마감처리결과
      {
        this.orderDone = true;
      }
      else if (requestType === 3) //당일 배송으로 처리한 결과
      {
        const order = this.findOrderByIdx(parseInt(result.idx));

        //해당 주문을 처리완료 상태로 바꾸기
        if (order !== null)
        {
          order.st = ODS_ACCEPT;
  
          this.orderList = this.orderList.slice();
        }
      }
      else if (requestType === 4) //익일 배송으로 처리함, 해당 주문을 목록에서 제외한 목록을 만들기
      {
        const order = this.findOrderByIdx(parseInt(result.idx));

        if (order !== null)
        {
          let newList = [];

          for (let i = 0;i < this.orderList.length; ++i)
          {
            if (this.orderList[i].idx !== order.idx)
            {
              newList.push(this.orderList[i]);
            }
          }

          this.orderList = newList;
        }
      }
      else if (requestType === 6)//재주문 처리
      {
        const order = this.findOrderByIdx(parseInt(result.idx));

        //해당 주문을 처리완료 상태로 바꾸기
        if (order !== null)
        {
          order.st = ODS_REORDER;
  
          this.orderList = this.orderList.slice();
        }
      }
      else if (requestType === 7 || requestType === 8) //주문을 특정일자로 이동시킴
      {
        const order = this.findOrderByIdx(parseInt(result.idx));

        if (order !== null)
        {
          let newList = [];

          for (let i = 0;i < this.orderList.length; ++i)
          {
            if (this.orderList[i].idx !== order.idx)
            {
              newList.push(this.orderList[i]);
            }
          }

          this.orderList = newList;
        }
      }
      else //기타 처리완료
      {
        const order = this.findOrderByIdx(parseInt(result.idx));

        //해당 주문을 처리완료 상태로 바꾸기
        if (order !== null)
        {
          order.st = ODS_PROCESSED;
  
          this.orderList = this.orderList.slice();
        }
      }
    }

    if (this.doneCallback != null)
      this.doneCallback(result.ret);
  }
  //배송시작-------------------------------------------------------------------------------------------------------------------------------------
  startDeliCallback = null;

  makeSDParam (salesIdx, startDate, endDate, startOrderIdx, lastOrderIdx, totalOrderCount, deliveryType, totalManualOrderCount) {
    const params = new URLSearchParams();

    params.append("sidx", salesIdx.toString());
    params.append("sds", startDate);
    params.append("sde", endDate);
    params.append("oc", totalOrderCount.toString());
    params.append("fidx", startOrderIdx.toString());
    params.append("lidx", lastOrderIdx.toString());
    params.append("rt", deliveryType.toString());
    params.append("ver", "1");
    params.append("tmoc", totalManualOrderCount.toString());
    return params;
  }

  //salesIdx : 영업담당자 고유번호
  //dayInfo : 주문일자
  //startOrderIdx : 조회된 주문중 첫번째 주문의 번호
  //lastOrderIdx : 조회된 주문중 마지막 주문의 번호
  //deliveryType : 0 : 일반배송, 1 : 추가배송
  //totalManualOrderCount : 수기주문의 개수
  startDelivery = (salesIdx, startDate, endDate, startOrderIdx, lastOrderIdx, totalOrderCount, deliveryType, totalManualOrderCount, callback) =>
  {
    if (LOG_ENABLE)
      console.log(`startDelivery : ${startDate}~${endDate}`);
    this.startDeliCallback = callback;

    axios({
      method:"POST",
      url: ("order/stdev.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeSDParam(salesIdx, startDate, endDate, startOrderIdx, lastOrderIdx, totalOrderCount, deliveryType, totalManualOrderCount)
    }).then((res)=>{
        this.parseSDResult(res.data);
    }).catch(error=>{

      if (LOG_ENABLE)
          console.log(error);

      this.parseSDResult(null);
    });
  }

  parseSDResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseSDResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.startDeliCallback != null)
        this.startDeliCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      this.deliveryStarted = true;

      //주문을 배송 시작 상태로 만들기
      if (IsValidS(this.dailyOrder))
      {
        this.dailyOrder.forEach(element => {
          element.st = ODS_DELIS;
        });
      }
    }

    if (this.startDeliCallback != null)
      this.startDeliCallback(result.ret);
  }

  //배송완료처리-------------------------------------------------------------------------------------------------------------------------------------
  deliDoneCallback = null;

  makeDDParam (retailerIdx, startDate, endDate, deliveryType) {
    const params = new URLSearchParams();

    params.append("ridx", retailerIdx.toString());
    params.append("sds", startDate);
    params.append("sde", endDate);
    params.append("rt", deliveryType);
    return params;
  }

  //특정 업체의 주문을 배송완료 상태로 만든다.
  //retailerIdx : 소매업체 고유번호
  //startDate, endDate : 주문 일자의 범위
  //deliveryType : 0 : 기본배송, 1 : 추가배송, 2 : 수기주문
  requestDeliveryDone = (retailerIdx, startDate, endDate, deliveryType, callback) =>
  {
    this.deliDoneCallback = callback;

    if (LOG_ENABLE)
      console.log(`retidx : ${retailerIdx}, stime : ${startDate}, etime : ${endDate}, deliveryType : ${deliveryType}`);

    axios({
      method:"POST",
      url: ("order/enddev.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeDDParam(retailerIdx, startDate, endDate, deliveryType)
    }).then((res)=>{
        this.parseDDResult(res.data);
    }).catch(error=>{

      if (LOG_ENABLE)
          console.log(error);

      this.parseDDResult(null);
    });
  }

  parseDDResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseDDResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.deliDoneCallback != null)
        this.deliDoneCallback(-2);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (IsValidS(this.dailyOrder))
      {
        if (LOG_ENABLE)
          console.log("change delivery status");

        let found = false;

        if (result.rt === 2) //수기 주문에 대한 배송완료인경우
        {
          for (let i = 0;i < this.dailyOrder.length; ++i)
          {
            if (this.dailyOrder[i].idx === result.ridx && this.dailyOrder[i].orderType === 1)
            {
              if (LOG_ENABLE)
                console.log("found manual order");
  
              this.dailyOrder[i].st = ODS_DELIE; //주문을 배송완료 상태로 만들기
  
              found = true;
              break;
            }
          }
        }
        else //일반 주문의 배송완료
        {
          for (let i = 0;i < this.dailyOrder.length; ++i)
          {
            if (this.dailyOrder[i].idx === result.ridx && this.dailyOrder[i].orderType === 0)
            {
              if (LOG_ENABLE)
                console.log("found order");
  
              this.dailyOrder[i].st = ODS_DELIE; //주문을 배송완료 상태로 만들기
  
              found = true;
              break;
            }
          }
        }
        

        if (found){
          this.dailyOrder = [...this.dailyOrder];
        }
      }
      else if (LOG_ENABLE)
        console.log("invalid daily order");
    }

    if (this.deliDoneCallback != null)
      this.deliDoneCallback(result.ret);
  }
  //일일판매일보 데이터-------------------------------------------------------------------------------------------------------------------------------------
  reportCallback = null;
  dailyReport = null;//일일판매일보 출력을 위한 임시 데이터
  firstQueryIdx = -1;
  lastQueryIdx = -1;
  lastSalesIdx = -1;
  totalReportOrderCount = 0; //판매일보조회로 수신된 총 주문개수
  totalBoxOrderCount = 0; //전체 주문의 총 박스 개수
  totalBottleOrderCount = 0; //전체 주문의 총 병 개수

  totalBoxOrderCountDeli = 0; //전체 주문의 총 박스 개수
  totalBottleOrderCountDeli = 0; //전체 주문의 총 병 개수

  clearReportInfo = () =>
  {
    this.firstQueryIdx = -1;
    this.lastQueryIdx = -1;
    this.lastSalesIdx = -1;
    this.dailyReport = null;//일일판매일보 출력을 위한 임시 데이터
    this.deliveryStarted = true; //일단은 배송시작되었다고 표시해두기
    this.orderDone = false; //주문 선마감 플래그
    this.totalReportOrderCount = 0; //판매일보조회로 수신된 총 주문개수
    this.orderInCompletedCount = 0; //처리되지 않은 주문의 개수
    this.manualOrderCount = 0;
    this.totalBoxOrderCount = 0; //전체 주문의 총 박스 개수
    this.totalBottleOrderCount = 0; //전체 주문의 총 병 개수
    this.totalBoxOrderCountDeli = 0; //전체 주문의 총 박스 개수
    this.totalBottleOrderCountDeli = 0; //전체 주문의 총 병 개수
  }

  //주문의 배송 순번을 설정함
  setDailyOrderDeliNumber = (order, deliNumber) =>
  {
    if (!IsValidS(this.dailyOrder))
      return null;

    let found = false;

    for (let i = 0;i < this.dailyOrder.length; ++i)
    {
      if (this.dailyOrder[i].idx === order.idx && this.dailyOrder[i].orderType === order.orderType)
      {
        found = true;
        break;
      }
    }

    if (!found)
    {
      if (LOG_ENABLE)
        console.log('setDailyOrderDeliNumber failed : order not found.');

      return;
    }

    let temp = [];

    for (let i = 0;i < this.dailyOrder.length; ++i)
    {
      if (this.dailyOrder[i].idx === order.idx && this.dailyOrder[i].orderType === order.orderType)
      {
        temp.push({...this.dailyOrder[i], printType : deliNumber});
      }
      else
      {
        temp.push(this.dailyOrder[i]);
      }
    }

    this.dailyOrder = temp;

    if (IsValidV(this.dailyReport))
    {
      if (order.orderType === 0)
      {
        if (IsValidS(this.dailyReport.list))
        {
          for (let i = 0;i < this.dailyReport.list.length; ++i)
          {
            if (this.dailyReport.list[i].idx === order.idx)
            {
              this.dailyReport.list[i].printType = deliNumber;

              if (LOG_ENABLE)
                console.log('setDailyOrderDeliNumber : order found in list.');
              break;
            }
          }
        }
      }
      else
      {
        if (IsValidS(this.dailyReport.manualList))
        {
          for (let i = 0;i < this.dailyReport.manualList.length; ++i)
          {
            if (this.dailyReport.manualList[i].idx === order.idx)
            {
              this.dailyReport.manualList[i].printType = deliNumber;

              if (LOG_ENABLE)
                console.log('setDailyOrderDeliNumber : order found in manual list.');
              break;
            }
          }
        }
      }
    }
  }
  //-------------------------------------------------------------------------------------------
  makeReportParam (idx, sidx, startDate, endDate, requestType) {
    const params = new URLSearchParams();

    params.append("idx", idx.toString());
    params.append("sidx", sidx.toString());
    params.append("sds", startDate);
    params.append("sde", endDate);
    params.append("rt", requestType.toString());

    return params;
  }

  //idx : 이전 요청으로 읽었던 고유번호중 가장 큰것
  //sidx : 영업담당자 고유번호
  //dayInfo : 조회일자
  //requestType : 0 : 일반배송, 1 : 추가배송
  loadDailyReport = (idx, sidx, startDate, endDate, requestType, callback) =>
  {
    this.reportCallback = callback;
    this.lastQueryIdx = idx;
    this.lastSalesIdx = sidx;

    axios({
      method:"POST",
      url: ("order/rep.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeReportParam(idx, sidx, startDate, endDate, requestType)
    }).then((res)=>{
        this.parseDailyReport(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseDailyReport(null);
    });
  }

  parseDailyReport = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseDailyReport : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.reportCallback != null)
        this.reportCallback(-2, 0, -1, this.lastQueryIdx, 0);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    //마지막으로 읽은 주문의 개수
    let lastCount = 0;

    if (result.ret === 0)
    {
      //첫주문 정보를 읽은경우 배송 시작 여부에 관한 정보가 있음
      if (result.ft === 1)
      {
        this.deliveryStarted = result.ds !== 0; //배송시작여부
        this.orderDone = result.od !== 0; //주문 선마감 여부
      }

      if (IsValidS(result.list))
      {
        lastCount = result.list.length;

        if (LOG_ENABLE)
          console.log("order list loaded : " + lastCount);

        result.list.forEach(element => {
          //이주문에 대해서 배송완료 또는 시작중이 아니면

          //처리되지 않은 음성주문이면
          if (element.otype === 1 && element.st === ODS_ACCEPT)
          {
            ++this.orderInCompletedCount;
          }
          else if (element.st === ODS_UNDELI) //미배송 주문이면
          {
            ++this.orderInCompletedCount;
          }

          if (IsValidS(element.cname))
          {
            element.cname = FromBase64(element.cname);
          }
        });

        if (this.dailyReport === null) //기존에 리포트가 없으면
        {
          this.dailyReport = {
            idx : this.lastSalesIdx, //영업담당 고유번호
            list : [], //일반 앱주문 목록
            manualList : [], //수기주문 목록
            total: null,
            deliTotal: null,
          };
        }
        
        this.processReportOrder(this.dailyReport.list, result.list);
      }

      //더이상 읽을 데이터가 없고, 추가배송에 대한 조회인경우
      if (lastCount === 0 && result.rt !== 0)
      {
        if (this.dailyReport !== null)
        {
          this.generateTotalInfo();
          this.dailyOrder = this.dailyReport.list;
        }
      }
    }

    if (this.reportCallback != null)
      this.reportCallback(result.ret, lastCount, this.firstQueryIdx, this.lastQueryIdx, this.totalReportOrderCount);
  }

  //newList의 주문정보를 이전 목록으로 병합한다.
  processReportOrder = (prevList, newList) =>
  {
    if (!IsValidS(newList))
    {
      if (LOG_ENABLE)
        console.log("processReportOrder : order item not found");

      return prevList;
    }

    let totalList = prevList;

    //총 주문 개수를 누적한다.
    this.totalReportOrderCount += newList.length;

    for (let i = 0;i < newList.length; ++i)
    {
      //고유번호중 가장 작은것
      if (this.firstQueryIdx === -1 || this.firstQueryIdx > newList[i].idx)
        this.firstQueryIdx = newList[i].idx;

      //주문 고유번호중 가장큰것
      if (this.lastQueryIdx < newList[i].idx)
        this.lastQueryIdx = newList[i].idx;

      //재주문 요청된 주문이면 통과
      if (newList[i].st === ODS_REORDER)
        continue;

        //목록에 회사가 있는지 확인
      let company = this.findCompanyFromOrderList(totalList, newList[i].ridx);

      let needPush = false;

      if (company === null)
      {
        if (LOG_ENABLE)
          console.log("company not found : addnew => " + newList[i].ridx);

        company = {
          idx : newList[i].ridx, //소매업체 고유번호
          cname : newList[i].cname, //소매업체 이름
          st : ODS_DELIE, //주문상태는 일단 배송완료상태로
          items : [], //소메 업체의 주문 목록
          orderType: 0, //0 : 일반앱주문, 1 : 수기주문
          printType: 0, //1 : 배송1, 2 : 배송2, 3 : 배송3, 0 : 설정안됨
        };

        needPush = true;
      }
      else if (LOG_ENABLE)
        console.log("company found : " + newList[i].ridx);

      //아직 배송 완료처리되지 않은 주문이 있으면 해당 업체의 주문상태를 접수중 상태로 바꿈
      if (newList[i].st !== ODS_DELIE)
      {
        company.st = ODS_ACCEPT;
      }

      //주문에 포함된 모든 아이템들을 통합하기
      if (IsValidS(newList[i].items))
      {
        if (LOG_ENABLE)
          console.log("order item found");

        for (let k = 0;k < newList[i].items.length; ++k)
        {
          let item = this.findOrderItemFromCompanyOrder(company.items, newList[i].items[k].iidx);

          if (item === null)
          {
            if (LOG_ENABLE)
              console.log("item not found add new");

            //기존에 목록에 없다면
            item = {...newList[i].items[k]};

            if (IsValidS(item.iname))
              item.iname = FromBase64(item.iname);

            if (IsValidS(item.alias) && item.alias !== "n")
              item.alias = FromBase64(item.alias);

            if (company.items === null)
            {
              if (LOG_ENABLE)
                console.log("item list empty: add new");
              company.items = [];
            }

            company.items.push(item);
          }
          else
          {//기존에 동일 아이템이 있으면 개수를 더한다.
            item.bcnt += newList[i].items[k].bcnt;
            item.ecnt += newList[i].items[k].ecnt;
          }
        }
      }
      else if (LOG_ENABLE)
        console.log("order item not found");

      if (needPush)
      {
        if (LOG_ENABLE)
          console.log("push company");

        totalList.push(company);
      }
    }

    return totalList;
  }
  //-------------------------------------------------------------------------------------------
  manualReportCallback = null;
  manualOrderCount = 0;

  makeManualReportParam = (refIdx, salesIdx, startTime, endTime) =>
  {
    const params = new URLSearchParams();

    params.append("idx", refIdx.toString());
    params.append("sidx", salesIdx.toString());
    params.append("stime", startTime);
    params.append("etime", endTime);

    return params;
  }

  loadManualOrders = (refIdx, salesIdx, startTime, endTime,callback) =>
  {
    this.manualReportCallback = callback;
    this.lastSalesIdx = salesIdx;

    axios({
      method:"POST",
      url: ("mo/rpt.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeManualReportParam(refIdx, salesIdx, startTime, endTime)
    }).then((res)=>{
        this.parseManualReportResult(res.data);
    }).catch(error=>{
        console.log(error);
        this.parseManualReportResult(null);
    });
  }

  parseManualReportResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseManualReportResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.manualReportCallback != null)
        this.manualReportCallback(-2, 0, -1, -1);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        this.manualOrderCount += result.list.length;

        let maxIdx = -1;
        let minIdx = -1;

        for (let i = 0;i < result.list.length; ++i)
        {
          result.list[i].cname = FromBase64(result.list[i].cname);

          if (maxIdx === -1 || maxIdx < result.list[i].idx)
            maxIdx = result.list[i].idx;

          if (minIdx === -1 || minIdx > result.list[i].idx)
            minIdx = result.list[i].idx;
        }

        if (this.dailyReport === null) //기존에 리포트가 없으면
        {
          this.dailyReport = {
            idx : this.lastSalesIdx, //영업담당 고유번호
            list : [], //일반 앱주문 목록
            manualList : [], //수기주문 목록
            total: null,
            deliTotal: null,
          };
        }

        //주문을 기존 주문에 병합하기
        this.processManualReportOrder(this.dailyReport.manualList, result.list);

        if (this.manualReportCallback != null)
          this.manualReportCallback(result.ret, result.list.length, minIdx, maxIdx);
      }
      else
      { //더이상 읽을 주문이 없는경우, 토탈 정보 산출
        if (this.dailyReport !== null)
        {
          this.generateTotalInfo();

          this.dailyOrder = [];

          if (IsValidS(this.dailyReport.list))
          {
            this.dailyOrder = this.dailyOrder.concat(this.dailyReport.list);
          }

          if (IsValidS(this.dailyReport.manualList))
          {
            this.dailyOrder = this.dailyOrder.concat(this.dailyReport.manualList);
          }
        }

        if (this.manualReportCallback != null)
          this.manualReportCallback(result.ret, 0, -1, -1);
      }
    }
    else
    {
      if (this.manualReportCallback != null)
        this.manualReportCallback(result.ret, 0, -1, -1);
    }
  }

  processManualReportOrder = (prevList, newList) =>
  {
    if (!IsValidS(newList))
    {
      if (LOG_ENABLE)
        console.log("processManualReportOrder : order item not found");

      return prevList;
    }

    let resultList = prevList;

    for (let i = 0;i < newList.length; ++i)
    {
      //재주문 요청된 주문이면 통과
      if (newList[i].st === ODS_REORDER)
        continue;

        //목록에 회사가 있는지 확인
      let company = this.findCompanyFromOrderList(resultList, newList[i].ridx);

      let needPush = false;

      if (company === null)
      {
        if (LOG_ENABLE)
          console.log("company not found : addnew => " + newList[i].ridx);

        company = {
          idx : newList[i].ridx, //소매업체 고유번호
          cname : newList[i].cname, //소매업체 이름
          st : ODS_DELIE, //주문상태는 일단 배송완료상태로
          items : [], //소메 업체의 주문 상품 목록
          orderType: 1, //0 : 일반앱주문, 1 : 수기주문
          printType: 0, //0 : 배송1, 1 : 배송2, 2 : 배송3
        };

        needPush = true;
      }
      else if (LOG_ENABLE)
        console.log("company found : " + newList[i].ridx);

      //아직 배송 완료처리되지 않은 주문이 있으면 해당 업체의 주문상태를 접수중 상태로 바꿈
      if (newList[i].st !== ODS_DELIE)
      {
        company.st = ODS_ACCEPT;
      }

      //주문에 포함된 모든 아이템들을 통합하기
      if (IsValidS(newList[i].items))
      {
        if (LOG_ENABLE)
          console.log("order item found");

        for (let k = 0;k < newList[i].items.length; ++k)
        {
          let item = this.findOrderItemFromCompanyOrder(company.items, newList[i].items[k].iidx);

          if (item === null)
          {
            if (LOG_ENABLE)
              console.log("item not found add new");

            //기존에 목록에 없다면
            item = {...newList[i].items[k]};

            if (IsValidS(item.iname))
              item.iname = FromBase64(item.iname);

            if (IsValidS(item.alias) && item.alias !== "n")
              item.alias = FromBase64(item.alias);

            if (company.items === null)
            {
              if (LOG_ENABLE)
                console.log("item list empty: add new");
              company.items = [];
            }

            company.items.push(item);
          }
          else
          {//기존에 동일 아이템이 있으면 개수를 더한다.
            item.bcnt += newList[i].items[k].bcnt;
            item.ecnt += newList[i].items[k].ecnt;
          }
        }
      }
      else if (LOG_ENABLE)
        console.log("order item not found");

      if (needPush)
      {
        if (LOG_ENABLE)
          console.log("push company");

          resultList.push(company);
      }
    }

    return resultList;
  }
  //-------------------------------------------------------------------------------------------
  //cidx에 해당 하는 업체의 주문을 찾음
  findCompanyFromOrderList = (compList, cidx) =>
  {
    if (compList === null || compList.length < 1)
      return null;

    for (let i = 0;i < compList.length; ++i)
    {
      if (compList[i].idx === cidx)
        return compList[i];
    }

    return null;
  }

  //주문 아이템 목록에 일치하는 아이템이 있는지 확인한다.
  findOrderItemFromCompanyOrder = (orderItems, iidx) =>
  {
    if (orderItems === null || orderItems.length < 1)
      return null;

    for (let i = 0;i < orderItems.length; ++i)
    {
      if (orderItems[i].iidx === iidx)
        return orderItems[i];
    }

    return null;
  }

  //주문의 종합 정보를 만들기
  generateTotalInfo = () =>
  {
    let report = this.dailyReport;

    if (report === null)
    {
      if (LOG_ENABLE)
        console.log("sales report not found");

      return;
    }

    report.total = [];

    if (LOG_ENABLE)
      console.log("generate total info");

    //list는 각업체별 앱 주문 목록이다.
    if (IsValidS(report.list))
    {
      for (let i = 0;i < report.list.length; ++i)
      {
        if (!IsValidS(report.list[i].items))
          continue;
  
        for (let k = 0;k < report.list[i].items.length; ++k)
        {
          let item = this.findOrderItemFromCompanyOrder(report.total, report.list[i].items[k].iidx);
  
          if (item === null)
          {
            //기존에 목록에 없다면
            item = {...report.list[i].items[k]};
    
            this.totalBoxOrderCount += item.bcnt;
            this.totalBottleOrderCount += item.ecnt;

            report.total.push(item);
          }
          else
          {//기존에 동일 아이템이 있으면 개수를 더한다.
            item.bcnt += report.list[i].items[k].bcnt;
            item.ecnt += report.list[i].items[k].ecnt;

            this.totalBoxOrderCount += report.list[i].items[k].bcnt;
            this.totalBottleOrderCount += report.list[i].items[k].ecnt;
          }
        }
      }  
    }

    //수기주문 목록병합
    if (IsValidS(report.manualList))
    {
      for (let i = 0;i < report.manualList.length; ++i)
      {
        if (!IsValidS(report.manualList[i].items))
          continue;
  
        for (let k = 0;k < report.manualList[i].items.length; ++k)
        {
          let item = this.findOrderItemFromCompanyOrder(report.total, report.manualList[i].items[k].iidx);
  
          if (item === null)
          {
            //기존에 목록에 없다면
            item = {...report.manualList[i].items[k]};
    
            this.totalBoxOrderCount += item.bcnt;
            this.totalBottleOrderCount += item.ecnt;

            report.total.push(item);
          }
          else
          {//기존에 동일 아이템이 있으면 개수를 더한다.
            item.bcnt += report.manualList[i].items[k].bcnt;
            item.ecnt += report.manualList[i].items[k].ecnt;

            this.totalBoxOrderCount += report.manualList[i].items[k].bcnt;
            this.totalBottleOrderCount += report.manualList[i].items[k].ecnt;
          }
        }
      }  
    }

    if (LOG_ENABLE)
    {
      console.log(`total box : ${this.totalBoxOrderCount}, total bottle : ${this.totalBottleOrderCount}`);
    }
  }

  //특정회차의 주문통합하기
  generateDeliTotalInfo = (deliMode) =>
  {
    this.totalBoxOrderCountDeli = 0;
    this.totalBottleOrderCountDeli = 0;
    this.dailyReport.deliTotal = null;

    let report = this.dailyReport;

    if (report === null)
    {
      if (LOG_ENABLE)
        console.log("sales report not found");

      return;
    }

    report.deliTotal = [];

    if (LOG_ENABLE)
      console.log(`generate deli total info : ${deliMode}`);

    //list는 각업체별 앱 주문 목록이다.
    if (IsValidS(report.list))
    {
      for (let i = 0;i < report.list.length; ++i)
      {
        //일치하는 회차가 아닌경우
        if (!IsValidS(report.list[i].items) || report.list[i].printType !== deliMode)
          continue;
  
        for (let k = 0;k < report.list[i].items.length; ++k)
        {
          let item = this.findOrderItemFromCompanyOrder(report.deliTotal, report.list[i].items[k].iidx);
  
          if (item === null)
          {
            //기존에 목록에 없다면
            item = {...report.list[i].items[k]};
    
            this.totalBoxOrderCountDeli += item.bcnt;
            this.totalBottleOrderCountDeli += item.ecnt;

            report.deliTotal.push(item);
          }
          else
          {//기존에 동일 아이템이 있으면 개수를 더한다.
            item.bcnt += report.list[i].items[k].bcnt;
            item.ecnt += report.list[i].items[k].ecnt;

            this.totalBoxOrderCountDeli += report.list[i].items[k].bcnt;
            this.totalBottleOrderCountDeli += report.list[i].items[k].ecnt;
          }
        }
      }  
    }

    //수기주문 목록병합
    if (IsValidS(report.manualList))
    {
      for (let i = 0;i < report.manualList.length; ++i)
      {
        if (!IsValidS(report.manualList[i].items) || report.manualList[i].printType !== deliMode)
          continue;
  
        for (let k = 0;k < report.manualList[i].items.length; ++k)
        {
          let item = this.findOrderItemFromCompanyOrder(report.deliTotal, report.manualList[i].items[k].iidx);
  
          if (item === null)
          {
            //기존에 목록에 없다면
            item = {...report.manualList[i].items[k]};
    
            this.totalBoxOrderCountDeli += item.bcnt;
            this.totalBottleOrderCountDeli += item.ecnt;

            report.deliTotal.push(item);
          }
          else
          {//기존에 동일 아이템이 있으면 개수를 더한다.
            item.bcnt += report.manualList[i].items[k].bcnt;
            item.ecnt += report.manualList[i].items[k].ecnt;

            this.totalBoxOrderCountDeli += report.manualList[i].items[k].bcnt;
            this.totalBottleOrderCountDeli += report.manualList[i].items[k].ecnt;
          }
        }
      }  
    }

    if (LOG_ENABLE)
    {
      console.log(`total box deli : ${this.totalBoxOrderCountDeli}, total bottle deli : ${this.totalBottleOrderCountDeli}`);
    }
  }
  //주문개수 조회-------------------------------------------------------------------------------------------------------------------------------------
  orderCountCallback = null;

  makeOCParam (salesIdx, startDate, endDate, deliveryStep) {
    const params = new URLSearchParams();

    params.append("sidx", salesIdx.toString());
    params.append("sds", startDate);
    params.append("sde", endDate);
    params.append("ds", deliveryStep);
    return params;
  }

  requestDailyOrderCount = (salesIdx, startDate, endDate, deliveryStep, callback) =>
  {
    this.orderCountCallback = callback;

    if (LOG_ENABLE)
      console.log(`sidx : ${salesIdx}, stime : ${startDate}, etime : ${endDate}`);

    axios({
      method:"POST",
      url: ("order/odcnt.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeOCParam(salesIdx, startDate, endDate, deliveryStep)
    }).then((res)=>{
        this.parseOCResult(res.data);
    }).catch(error=>{

      if (LOG_ENABLE)
          console.log(error);

      this.parseOCResult(null);
    });
  }

  parseOCResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseOCResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.orderCountCallback != null)
        this.orderCountCallback(-2, 0, 0);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    let orderCount = -1; //주문개수
    let modifedCount = -1; //수정주문개수

    if (result.ret === 0)
    {
      orderCount = result.cnt;
      modifedCount = result.mcnt;
    }

    if (this.orderCountCallback != null)
      this.orderCountCallback(result.ret, orderCount, modifedCount);
  }
  //주문마감 조회-------------------------------------------------------------------------------------------------------------------------------------
  checkOrderDoneCallback = null;

  makeCheckODParam (sidx, rtime) {
    const params = new URLSearchParams();

    params.append("sidx", sidx.toString());
    params.append("rtime", rtime.toString());
    
    return params;
  }

  requestDailyOrderDone = (sidx, rtime, callback) =>
  {
    this.checkOrderDoneCallback = callback;

    if (LOG_ENABLE)
      console.log(`sidx : ${sidx}, rtime : ${rtime}`);

    axios({
      method:"POST",
      url: ("order/odinfo.do"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'text', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: this.makeCheckODParam(sidx, rtime)
    }).then((res)=>{
        this.parseCheckODResult(res.data);
    }).catch(error=>{

      if (LOG_ENABLE)
          console.log(error);

      this.parseCheckODResult(null);
    });
  }

  parseCheckODResult = (result) =>
  {
    if (LOG_ENABLE)
      console.log("parseCheckODResult : " + JSON.stringify(result));

    if (result === undefined || result === null)
    {
      if (this.checkOrderDoneCallback != null)
        this.checkOrderDoneCallback(false, false);

      return;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (result.ret === 0)
    {
      if (this.checkOrderDoneCallback != null)
        this.checkOrderDoneCallback(false, result.di === 1);
    }
    else if (this.checkOrderDoneCallback != null)
      this.checkOrderDoneCallback(false, false);
  }
  //-------------------------------------------------------------------
  loadFontSize = () =>
  {
    try
    {
      const value = getCookie("pfsz");
      const value2 = getCookie("pfszchk");

      if (IsValidV(value))
      {
        if (LOG_ENABLE)
            console.log("font size setting found");
  
        if (!isNaN(value))
        {
          this.printFontSize = parseInt(value);
  
          if (LOG_ENABLE)
            console.log("valid font size : " + this.printFontSize);
        }
        else
        {
          this.printFontSize = 9;
  
          if (LOG_ENABLE)
            console.log("invalid font size : select default");
        }
      }
      else
      {
          this.printFontSize = 9;
  
          if (LOG_ENABLE)
            console.log("font size setting not found");
      }

      if (IsValidV(value2))
      {
        this.totalInfocheck = value2 === "1";
      }
      else
      {
        this.totalInfocheck = false;
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }
  }

  saveFontSize = () =>
  {
    if (LOG_ENABLE)
      console.log("saveFontSize : " + this.printFontSize);

    setCookie("pfsz", this.printFontSize.toString());
    setCookie("pfszchk", this.totalInfocheck ? "1" : "0");
  }
}


//-------------------------------------------------------------------------------------------------------------------------------------------------
const OrderStoreInstance = new OrderStore();
const OrderStoreContext = React.createContext(OrderStoreInstance);

const UseOrderStore = () => React.useContext(OrderStoreContext);

export {UseOrderStore, OrderStoreInstance, ODS_UNDELI, ODS_ACCEPT, ODS_DELIE, ODS_PROCESSED, ODS_REORDER};
