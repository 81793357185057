import { observer } from "mobx-react";
import { LOG_ENABLE } from "../../URL/AppUrl";
import { IsValidS } from "../../Util/Util";
import "../Emp/Staff.css";
import "./Product.css";
import ProductItemmb from "./ProductItemmb";

const ProductListmb = observer((props) =>
{
  let itemList = null;
  let itemCategory = null;

  if (props.itemList !== null && props.itemList.length > 0)
  {
    itemList = props.itemList.map(item => {
      return (<ProductItemmb key={item.idx} item = {item} handleSelect={props.handleSelect} selectedIdx={props.selectedIdx} 
        handleDetail={props.handleDetail}/>);
    });
  }

  if (IsValidS(props.itemCategory))
  {
    itemCategory = props.itemCategory.map(item => {
      return <option value={item.iid.toString()}>{item.cname}</option>
    });

    itemCategory.unshift((<option value="-1">전체상품</option>));
  }

  return (
    
    <div className = "pdlistareamb flexcentercolstart">
      
      {/*리스트영역 편집버튼*/}
      <div className = "pdeditmb flexcenterrowstart">
        <input type="radio" checked = {false} className="pdcheck" onChange={() => console.log("checked")}/>
        <label className="mfontnb">&nbsp;&nbsp;선택한 정보를&nbsp;&nbsp;</label>

        <button className="bgcolor1 pdeditbtnmb mfontnwb" onClick={() => props.handleEdit()}>
          수정
        </button>

        {
          !props.appOn ? (
            <button className="pdexcelbtnmb mfontnwb" onClick={() => props.handleWriteExcel()}>
              상품 목록 엑셀출력
            </button>
          ) : null
        }
      </div>

      <button className="bgcolor3 pdaddcompdmb mfontnwb" onClick={() => props.handleAddComPd()}>
        신규 상품 등록
      </button>

      <select className="pditemcatmb mfontnb"
        onChange={(e) => {
          if (LOG_ENABLE)
            console.log(e.target.value);

            props.handleProductCategory(parseInt(e.target.value));
        }}
      >
        {itemCategory}
      </select>

      {/*리스트영역 아이템 목록*/}
      <div className="pdlistmb flexcentercolstart">
        <table className="pdtable">
          <thead>
            <tr className="pdtablehead">
              <th className="pdtableitemmb">
                <input type="radio" checked = {false} className="pdcheck"  onChange={() => console.log("checked")}/>
              </th>

              <th className="pdtableitemmb">
                <label className="mfontnw">품목명</label>
              </th>

              <th className="pdtableitemmb">
                <label className="mfontnw">상세정보확인</label>
              </th>
            </tr>
          </thead>
          <tbody>
            {itemList}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default ProductListmb;