import { GetBankName, GetFormattedCNum2, IsValidS, GetFormattedPhone2 } from "../../Util/Util";
import './Staff.css';
import '../../App.css';

const RecmTransItem = (props) =>
{
  return (
    <tr className="recomrow">

      {/*선택*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <input type="checkbox" checked={props.item.selected} onChange={() => props.handleCheck(props.item)} className="recmchkbox"/>
        </div>
      </td>

      {/*업체명*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{props.item.cname}</label>  
        </div>
      </td>

      {/*주소*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{props.item.addr !== "n" ? props.item.addr : "-"}</label>  
        </div>
      </td>

      {/*대표자명*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{props.item.uname}</label>  
        </div>
      </td>

      {/*연락처*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{GetFormattedPhone2(props.item.phone)}</label>  
        </div>
      </td>

      {/*사업자번호*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className= "fontn">{GetFormattedCNum2(props.item.cnum)}</label>
        </div>
      </td>

      {/*업체구분*/}
      <td className="recomdcell">
        <div className="recomlitem flexcenterrow">
          <label className="fontn">{props.item.ct === 0 ? "유흥" : "일반"}</label>  
        </div>
      </td>
    </tr>
  );
}

export default RecmTransItem;