
function GetChatMsg(chat)
{
  return `${chat.cname} : ${chat.chat}`;
}

const ChatAlarm = (props) =>
{
  return (
    <div className="chatalarmcont flexcenterrow">
      <div className="chatalarm">
        <label className="fontnw">{GetChatMsg(props.item)}</label>
      </div>
    </div>
  );
}

export default ChatAlarm;