import { useState } from "react";
import { GetImgUrl } from "../../URL/AppUrl"

//채팅창 좌측 업체 검색창
const ChatSerCompmb = (props) =>
{
  const[search, setSearch] = useState('');

  return (
    <div className="chatsercompmb flexcentercolstart">

      <div className="chatsercomptopmb flexcenterrow">
        <div className="dcell2 flexcenterrowstart">
          <label className="mfontlargeb btncur" onClick={() => props.handleToChat()}>{"< 채팅"}</label>
        </div>

        <div className="dcell6 flexcenterrow">
          <label className="mfontlargeb">거래처 대화 목록</label>
        </div>

        <div className="dcell2"></div>
      </div>

      <div className="chathlinemb"></div>

      <div className="chatsercomptopmb flexcenterrow">
        <div className="chatcompserareamb flexcenterrow">
    
          <div className="chatcompserarealeftmb flexcenterrow">
            <img src={GetImgUrl("searchpd.png")} alt="검색"/>
          </div>

          <div className="chatcompserarearightmb flexcenterrowstart">
            <input type="text" onChange={(e) => setSearch(e.target.value)} value = {search} className="chatserinpmb mfontnb" placeholder="거래처 검색" 
              onKeyPress = {(e) => {
                if (e.key === "Enter")
                {
                  props.handleSearchCompany(search);
                }
            }}/>
          </div>

        </div>        
      </div>

      <div className="chathlinemb"></div>

      {
        props.listMode === 0 ? (
        <div className="chatsercompbottommb flexcenterrow">
          <div className="chatlefttoplinemb flexcenterrow"/>
          <label className="mfontnb">&nbsp;&nbsp;대화 목록&nbsp;&nbsp;</label>
          <div className="chatlefttoplinemb flexcenterrow"/>
        </div>
        ):
        (
        <div className="chatsercompbottommb flexcenterrow">
          <div className="charserttleft">

          </div>

          <div className="charserttcenter flexcenterrow">
            <label className="mfontnb">검색 목록</label>  
          </div>

          <div className="charserttright flexcenterrowend">
            <img src={GetImgUrl("chatsx.png")} alt="닫기" className="charserttrightimg" onClick={() => props.handleCloseSearch()}/>
          </div>
        </div>
        )
      }
      
      <div className="chathlinemb"></div>

    </div>
  );
}

export default ChatSerCompmb;