import { observer } from "mobx-react";
import React from "react";
import { ProductStoreInstance } from "../../Stores/ProductStore";
import { GetImgUrl, GetSoundUrl, LOG_ENABLE, mobileOn } from "../../URL/AppUrl";
import { NumberFormatString } from "../../Util/Util";
import "./OrderAdd.css";
import OrderAddItemListmb from "./OrderAddItemListmb";
import OrderAddItemList from "./OrderAddItemList";
import OrderSearchList from "./OrderSearchList";
import OrderSearchListmb from "./OrderSearchListmb";

//상품 추가창
class OrderAdd extends React.Component
{
  state = {
    itemMode : 0, //0 : 최근 주문 리스트, 1 : 검색
    playing : false,
    time : '00:00',
    searchList : false, //검색된 아이템 목록창 보이는 중인가?
    searchTitle: '',
  };

  //listRef = React.createRef();
  audioData = null;
  intervalId = -1;
  voiceTime = 0; //음성파일길이
  voicePlayTime = null; //음성 재생 시작하는 시점의 시간을 기록함

  componentDidMount = () =>
  {
    document.body.style.overflow = 'hidden';
    document.querySelector('html').scrollTop = window.scrollY;

    //console.log(JSON.stringify(this.props));

    this.audioData = new Audio(GetSoundUrl(this.props.order.sfile));
    this.audioData.load();
    
    this.audioData.addEventListener('loadeddata', () => {
      this.setState((prevState) => ({...prevState, time : this.getAudioTime()}));
    });

    this.audioData.addEventListener('playing', () => {

      if (LOG_ENABLE)
        console.log("audio playing");

      this.audioPlaying = true;
      this.voicePlayTime = new Date();
      this.setState((prevState) => ({...prevState, playing : true}));
    });

    this.audioData.addEventListener('pause', () => {

      if (LOG_ENABLE)
        console.log("audio paused");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, playing : false}));
    });

    this.audioData.addEventListener('ended', () => {

      if (LOG_ENABLE)
        console.log("audio ended");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, playing : false}));
    });

    this.audioData.addEventListener('error', () => {
      if (LOG_ENABLE)
        console.log("audio error");

      this.audioPlaying = false;
      this.setState((prevState) => ({...prevState, playing : false}));
    });

    this.intervalId = setInterval(this.updateVoiceTime, 1000);

    this.props.showLoading(true);
    ProductStoreInstance.loadRecentProduct(this.props.order.ridx, this.onRecentLoaded);
  }

  onRecentLoaded = (resultCode) =>
  {
    this.props.showLoading(false);

    if (resultCode !== 0)
    {
      alert("소매상 최근 주문 상품 목록 조회에 실패 했습니다.");
    }
  }

  getAudioTime = () =>
  {
    this.voiceTime = 0;

    if (this.audioData === null)
      return "00:00";

    this.voiceTime = this.audioData.duration;
    let sec = this.voiceTime;

    const minute = Math.floor(sec / 60);

    if (minute > 0)
      sec -= minute * 60;

    return NumberFormatString(minute) + ":" + NumberFormatString(Math.round(sec));
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    document.body.style.overflow = 'visible';

    if (this.audioData !== null)
    {
      this.audioData.pause();
      this.audioData = null;
    }
  }

  //음성 재생시 음성 시간 갱신
  updateVoiceTime = () =>
  {
    if (!this.state.playing)
      return;

    const curDate = new Date();

    let remainedTime = this.voiceTime - ((curDate - this.voicePlayTime) / 1000);

    if (remainedTime < 0)
    {
      this.setState((prevState) => ({...prevState, time : ""}));
      return;
    }

    const minute = Math.floor(remainedTime / 60);

    if (minute > 0)
      remainedTime -= minute * 60;

    this.setState((prevState) => ({...prevState, time : (NumberFormatString(minute) + ":" + NumberFormatString(Math.round(remainedTime)))}));
  }

  handleScroll = (e) =>
  {
    console.log("handle scroll");

    e.preventDefault();
  }

  addItem = (itemMode, itemInfo, boxCount, bottleCount) =>
  {
    if (LOG_ENABLE)
      console.log(`addItem : itemmode= ${itemMode} , bcnt = ${boxCount} , ecnt = ${bottleCount}`);

    this.props.handleAddProduct(this.props.orderIdx, itemMode === 0 ? itemInfo.iidx : itemInfo.idx, itemInfo.iname, boxCount, bottleCount);
  }

  searchItem = () =>
  {
    if (LOG_ENABLE)
      console.log("searchItem");

    if (!ProductStoreInstance.isSearchWordValid())
    {
      alert("검색할 상품명을 입력하세요.");
      return;
    }
  
    this.setState((prevState) => ({...prevState, searchTitle: `'${ProductStoreInstance.searchWord}' 검색 결과`}));
    this.props.showLoading(true);
    ProductStoreInstance.searchProduct(this.props.order.ct, this.onSearchResult);
    //this.setState((prevState) => ({...prevState, searchList : true}));
  }

  onSearchResult = (resultCode) =>
  {
    this.props.showLoading(false);

    if (resultCode !== 0)
      alert("상품 검색중 오류가 발생했습니다.");
    else if (ProductStoreInstance.isSearchProductValid())
      this.setState((prevState) => ({...prevState, searchList : true}));
    else
      alert("일치하는 상품이 없습니다.");
  }

  //주문 내용 재생하기
  playOrder = () =>
  {
    if (this.audioData != null && !this.state.playing)
    {
      this.audioData.currentTime = 0;
      this.audioData.play();
    }
    else
      this.audioData.pause();
  }

  handleCloseSearch = () =>
  {
    this.setState((prevState) => ({...prevState, searchList : false}));
  }

  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.searchItem();
    }
  }

  render()
  {
    let playArea = null;

    if (mobileOn)
    {
      if (this.props.order.otype !== 0)
      {
        if (!this.state.playing)
        {
          playArea = (
            <div className="flexcenterrow voadplayareamb">
              <label className="mfontnb">녹음재생</label>
              <img src={GetImgUrl("voadplaymb.png")} alt="플레이" className="btncur" onClick={this.playOrder}/>
            </div>
          );
        }
        else
        {
          playArea = (
            <div className="flexcenterrow voadplayareamb">
              <label className="mfontnb">녹음재생</label>
  
              <div className="flexcenterrow">
                <img src={GetImgUrl("voadstopmb.png")} alt="중지" className="btncur" onClick={this.playOrder}/>
                <label className="odaplayfntmb">&nbsp;{this.state.time}</label>
              </div>
            </div>
          );
        }
      }
      

      return (
        <div className="orderaddcontpop flexcentercol">
          <div className="orderaddpopmb flexcentercolstart" onClick = {() => {
            if (this.state.searchList)
              this.setState((prevState) => ({...prevState, searchList : false}));
          }}>
  
            {/*타이틀 */}
            <div className="orderaddpoptitle">
              <div className="orderaddpoptl"/>
              
              <div className="orderaddpoptc flexcenterrow">
                <label className="mfontlargeb">상품 추가하기</label>
              </div>
              
              <div className="orderaddpoptr flexcenterrowend">
                <div className="odaclosebtn" onClick={this.props.handleClose}>
                  <img src={GetImgUrl("x_btn2.png")} alt="닫기"/>
                </div>
              </div>
            </div>

            {playArea}

            {/*검색영역*/}
            <div className="flexcenterrow odasearchmb">
              <label className="mfontnb">상품 검색&nbsp;&nbsp;&nbsp;</label>
    
              <input type="text" onChange={(e) => ProductStoreInstance.setSearchWord(e.target.value)} value = {ProductStoreInstance.searchWord} 
                className="odainpmb" placeholder="ex. 참이슬"
                onKeyPress={this.handleKeyPress}
              />
    
              <button className="odasearchbtnmb fontnw" onClick={this.searchItem}>
                검색
              </button>
            </div>
    
            {/*아이템 영역창*/}
            <div className="oditemcontmb flexcentercol">
              <div className="voaditmttmb flexcenterrow">
                <div className="dcell1"></div>
                <div className="dcell8 flexcenterrow">
                  <label className="mfontnb">{!this.state.searchList ? "최근 주문 리스트" : this.state.searchTitle}</label>
                </div>
                <div className="dcell1 flexcenterend">
                  {
                    this.state.searchList ? (<img src={GetImgUrl("voadxcl2.png")} alt="닫기" className="btncur" onClick={() => this.handleCloseSearch()}/>)
                      : null
                  }
                  
                </div>
              </div>
              
              {
                this.state.searchList ? <OrderSearchListmb addItem = {this.addItem} itemList = {ProductStoreInstance.searchList}/> : 
                (<OrderAddItemListmb items = {ProductStoreInstance.recentList} itemMode = {this.state.itemMode} addItem = {this.addItem} />)
              }
              
            </div>
            
          </div>
        </div>
      );
    }
    else
    {
      if (this.props.order.otype !== 0)
      {
        if (!this.state.playing)
        {
          playArea = (
            <div className="flexcenterrow">
              <img src={GetImgUrl("play2.png")} alt="플레이" className="btncur" onClick={this.playOrder}/>
              <label className="odaptfnt">&nbsp;다시 듣기&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            </div>
          );
        }
        else
        {
          playArea = (
            <div className="flexcenterrow">
              <img src={GetImgUrl("stop.png")} alt="중지" className="btncur" onClick={this.playOrder}/>
              <label className="odaplayfnt">&nbsp;{this.state.time}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            </div>
          );
        }
      }

      return (
        <div className="staffaddcont flexcentercol">
          <div className="orderaddpop flexcentercolstart" onClick = {() => {
            if (this.state.searchList)
              this.setState((prevState) => ({...prevState, searchList : false}));
          }}>
  
            {/*타이틀 */}
            <div className="orderaddpoptitle">
              <div className="orderaddpoptl"/>
              
              <div className="orderaddpoptc flexcenterrow">
                <img src={GetImgUrl("vplayx.png")} alt="상품추가"/>
                <label className="odaptfnt">상품 추가하기</label>
              </div>
              
              <div className="orderaddpoptr flexcenterrowend">
                <div className="odaclosebtn" onClick={this.props.handleClose}>
                  <img src={GetImgUrl("x_btn2.png")} alt="닫기"/>
                </div>
              </div>
            </div>
    
            {/*검색영역*/}
            <div className="odasearch flexcenterrow">
  
              {playArea}
              
    
              <img src={GetImgUrl("searchs.png")} alt="검색"/>
    
              <label className="odaptfnt">&nbsp;상품 검색&nbsp;&nbsp;&nbsp;</label>
    
              <input type="text" onChange={(e) => ProductStoreInstance.setSearchWord(e.target.value)}
                value = {ProductStoreInstance.searchWord} className="odainp" placeholder="검색어를 입력하세요.(ex. 참이슬)"
                onKeyPress={this.handleKeyPress}
              />
    
              <button className="odasearchbtn fontnw" onClick={this.searchItem}>
                검색하기
              </button>
            </div>
    
            {/*아이템 영역창*/}
            <div className="oditemcont flexcentercol">
              <label className="odaptfnt">{!this.state.searchList ? "최근 주문 리스트" : "검색된 아이템"}</label>
              <OrderAddItemList items = {ProductStoreInstance.recentList} itemMode = {this.state.itemMode} addItem = {this.addItem} />
            </div>
    
            {/*검색 목록 */}
            {
              this.state.searchList ? <OrderSearchList addItem = {this.addItem} itemList = {ProductStoreInstance.searchList}/> : null
            }
            
          </div>
        </div>
      );
    }
  }
}

export default observer(OrderAdd);