import React from 'react';
import { GetImgUrl, mobileOn } from '../URL/AppUrl';
import "./PageList.css";

const ImagePage = (props) =>
{
  return (<img src={props.img} onClick={() => props.handlePage(props.pageNum)} className="pageimg" alt=""/>);
}

const PageItem = (props) =>
{
  return (<label onClick={() => props.handlePage(props.pageNum)} className={props.currentPage===props.pageNum ? "pagesel" : "pagenormal fontnb" }>{props.pageNum}</label>);
}

const PageList = (props) => {
  let pageItem = [];

  //첫페이지
  if (props.first)
  {
    pageItem.push((<ImagePage key = {-1} img={GetImgUrl("first.png")} pageNum={-1} handlePage={props.handlePage}/>));
  }

  //이전페이지
  if (props.prev)
  {
    pageItem.push((<ImagePage key = {-2} img={GetImgUrl("prev.png")} pageNum={-2} handlePage={props.handlePage}/>));
  }

  for (let i = props.startPage; i <= props.endPage; ++i)
  {
    pageItem.push((<PageItem key = {i} pageNum={i} handlePage={props.handlePage} currentPage = {props.currentPage}/>));
  }

  //다음페이지
  if (props.next)
  {
    pageItem.push((<ImagePage key = {-3} img={GetImgUrl("next.png")} pageNum={-3} handlePage={props.handlePage}/>));
  }

  //마지막 페이지
  if (props.last)
  {
    pageItem.push((<ImagePage key = {-4} img={GetImgUrl("last.png")} pageNum={-4} handlePage={props.handlePage}/>));
  }

  if (mobileOn)
  {
    return (
      <div className="pagecontainermb flexcenterrow">
        {pageItem}
      </div>
    );
  }
  else
  {
    return (
      <div className="pagecontainer flexcenterrow">
        {pageItem}
      </div>
    );
  }  
}

export default PageList;