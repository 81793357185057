import { IsValidS } from "../../Util/Util";
import MnuOrderAddItem from "./MnuOrderAddItem";
import MnuOrderAddItemmb from "./MnuOrderAddItemmb";
import { GetImgUrl, mobileOn } from "../../URL/AppUrl";
import './MnuOrder.css';

//상품목록
const MnuOrderAddProduct = (props) =>
{
  let item = null;

  if (mobileOn)
  {
    if (IsValidS(props.itemList))
    {
      item = props.itemList.map(item => {
        return (<MnuOrderAddItemmb key={item.idx} item={item} handleSelect={props.handleSelect} mode={props.mode} handleAddItem={props.handleAddItem}/>);
      });
    }
  
    return (
      <div className="mnuodcomplistmb flexcentercolstart">
        <div className="cntfullh flexcenterrow marginbottom15 margintop10">
          <div className="dcell1"></div>
          <div className="dcell8 flexcenterrow">
            <label className="mfontlargeb">{props.title}</label>
          </div>
          <div className="dcell1 flexcenterrow">
            {
              props.mode === 1 ? (
                <img src={GetImgUrl("mnuodxmb.png")} alt="닫기" onClick={props.handleCloseSearch} className="btncur"/>
              ) : null
            }
            
          </div>
          
        </div>
        
        <div className="flexcentercolstart mnuodcomplistitemareamb">
          {item}
        </div>
      </div>
    );
  }
  else
  {
    if (IsValidS(props.itemList))
    {
      item = props.itemList.map(item => {
        return (<MnuOrderAddItem key={item.idx} item={item} handleSelect={props.handleSelect} mode={props.mode} handleAddItem={props.handleAddItem}/>);
      });
    }
  
    return (
      <div className="mnuodcomplist flexcentercolstart">
        <label className="fontlargeb marginbottom15">{props.title}</label>
        <div className="flexcentercolstart mnuodcomplistitemarea">
          {item}
        </div>
      </div>
    );
  }
}

export default MnuOrderAddProduct;