import React from 'react';
import '../../App.css';
import './Staff.css';
import {GetImgUrl, mobileOn} from '../../URL/AppUrl';
import { StaffStoreInstance } from '../../Stores/StaffStore';
import { IsValidS } from '../../Util/Util';
import Loading from '../Loading/Loading';
import EditManagedSalesItem from './EditManagedSalesItem';
import EditManagedSalesAddItem from './EditManagedSalesAddItem';

class EditManagedSales extends React.Component {
  state = {
    loading : false,
    salesList : null,
    managedList : null,
    selectedSales : -1,
    selectedManagedSales : -1,
  };

  error = false;
  

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading: v}));
  }

  componentDidMount()
  {
    this.loadSalesList();
  }

  componentWillUnmount()
  {

  }

  loadSalesList = () =>
  {
    this.setLoading(true);
    StaffStoreInstance.loadSalesManager(this.onSalesListLoaded);
  }

  onSalesListLoaded = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert('영업담당자 목록을 읽는 중 오류가 발생했습니다.');
      this.error = true;
      return;
    }

    this.loadManagedSales();
  }

  loadManagedSales = () =>
  {
    if (!this.state.loading)
    {
      this.setLoading(true);
    }

    StaffStoreInstance.loadManagedSalesList(this.props.managerIdx, this.onManagedSalesLoaded);
  }

  onManagedSalesLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('관리중인 영업담당자 목록을 읽는 중 오류가 발생했습니다.');
      this.error = true;
      return;
    }

    this.selectedSales = -1;
    this.selectedManagedSales = -1;
    this.generateSalesList();
  }

  generateSalesList = () =>
  {
    const sales = StaffStoreInstance.salesManagerList;
    const managedSales = StaffStoreInstance.managedSalesList;

    let resultSales = [];
    let resultManaged = [];

    if (IsValidS(sales))
    {
      for (let i = 0;i < sales.length; ++i)
      {
        //해당 영업담당자가 관리되는 목록에 없다면 추가하기
        if (StaffStoreInstance.findManagedSalesByIdx(sales[i].idx) === null)
        {
          resultSales.push(sales[i]);
        }
      }
    }

    if (IsValidS(managedSales))
    {
      for (let i = 0;i < managedSales.length; ++i)
      {
        resultManaged.push(managedSales[i]);
      }
    }

    this.setState((prevState) => ({...prevState, salesList : resultSales, managedList: resultManaged}));
  }

  handleSelectSales = (salesIdx) =>
  {
    if (this.state.selectedSales === salesIdx)
    {
      this.setState((prevState) => ({...prevState, selectedSales: -1}));
    }
    else
    {
      this.setState((prevState) => ({...prevState, selectedSales: salesIdx}));
    }
  }

  handleSelectManaged = (managedSalesIdx) =>
  {
    if (this.state.selectedManagedSales === managedSalesIdx)
    {
      this.setState((prevState) => ({...prevState, selectedManagedSales: -1}));
    }
    else
    {
      this.setState((prevState) => ({...prevState, selectedManagedSales: managedSalesIdx}));
    }
  }

  //해당 영업담당자가 관리되는 목록에 있는지 확인하기
  checkCurrentManagedSales = (managedList, salesIdx) =>
  {
    if (!IsValidS(managedList))
    {
      return false;
    }

    for (let i = 0;i < managedList.length; ++i)
    {
      if (managedList[i].sidx === salesIdx)
        return true;
    }

    return false;
  }

  //특정 영업담당자를 관리하는 영업담당자 목록에 추가하기
  handleAddSales = () =>
  {
    if (this.state.selectedSales < 1)
    {
      alert(mobileOn ? '관리하는 목록에 추가할 영업담당자를 위쪽의 목록에서 선택해 주세요.' : '관리하는 목록에 추가할 영업담당자를 왼쪽의 목록에서 선택해 주세요.');
      return;
    }

    let salesInfo = StaffStoreInstance.findSalesFromSalesManagerByIdx(this.state.selectedSales);

    if (salesInfo === null)
    {
      console.log('selected sales not found');
      return;
    }

    let newManagedList = null;

    if (IsValidS(this.state.managedList))
    {
      let found = false;

      for (let i = 0;i < this.state.managedList.length; ++i)
      {
        if (this.state.managedList[i].sidx === this.state.selectedSales)
        {
          found = true;
          break;
        }
      }

      if (found)
      {
        console.log('already exist');

        return;
      }

      newManagedList = [...this.state.managedList];

      newManagedList.push({sidx: salesInfo.idx, uname: salesInfo.uname});
    }
    else
    {
      newManagedList = [{sidx: salesInfo.idx, uname: salesInfo.uname}];
    }

    const sales = StaffStoreInstance.salesManagerList;
    
    let resultSales = [];
  
    if (IsValidS(sales))
    {
      for (let i = 0;i < sales.length; ++i)
      {
        //해당 영업담당자가 관리되는 목록에 없다면 추가하기
        if (!this.checkCurrentManagedSales(newManagedList, sales[i].idx))
        {
          resultSales.push(sales[i]);
        }
      }
    }

    this.setState((prevState) => ({...prevState, salesList: resultSales, managedList: newManagedList, selectedSales : -1}));
  }

  //선택된 영업담당자를 관리하는 영업담당자 목록에서 제거하기
  handleDelSales = () =>
  {
    if (this.state.selectedManagedSales < 1)
    {
      alert(mobileOn ? '제거 할 영업담당자를 아래쪽의 목록에서 선택해 주세요.' : '제거 할 영업담당자를 오른쪽의 목록에서 선택해 주세요.');
      return;
    }

    let newManagedList = [];

    if (!IsValidS(this.state.managedList))
    {
      return;
    }

    for (let i = 0;i < this.state.managedList.length; ++i)
    {
      if (this.state.managedList[i].sidx !== this.state.selectedManagedSales)
      {
        newManagedList.push(this.state.managedList[i]);
      }
    }

    //기존과 동일하다면
    if (newManagedList.length === this.state.managedList.length)
    {
      return;
    }

    const sales = StaffStoreInstance.salesManagerList;
    
    let resultSales = [];
  
    if (IsValidS(sales))
    {
      for (let i = 0;i < sales.length; ++i)
      {
        //해당 영업담당자가 관리되는 목록에 없다면 추가하기
        if (!this.checkCurrentManagedSales(newManagedList, sales[i].idx))
        {
          resultSales.push(sales[i]);
        }
      }
    }

    this.setState((prevState) => ({...prevState, salesList: resultSales, managedList: newManagedList, selectedManagedSales : -1}));
  }

  handleReset = () =>
  {
    if (!window.confirm('관리 하는 영업담당자 목록을 원래되로 복구 할까요?'))
    {
      return;
    }

    this.generateSalesList();
  }

  handleSaveResult = () =>
  {
    if (!IsValidS(StaffStoreInstance.managedSalesList) && !IsValidS(this.state.managedList))
    {
      alert('저장할 변경 사항이 없습니다.');
      return;
    }

    //해당 관리 담당자의 모든 관리하는 영업담장자를 모두 제거함
    if (!IsValidS(this.state.managedList))
    {
    }
    else
    {
      //목록의 길이가 같은경우, 변경사항이 없는지 확인하기
      if (IsValidS(StaffStoreInstance.managedSalesList) && (StaffStoreInstance.managedSalesList.length === this.state.managedList.length))
      {
        let changed = false;

        for (let i = 0;i < StaffStoreInstance.managedSalesList.length; ++i)
        {
          if (!this.checkCurrentManagedSales(this.state.managedList, StaffStoreInstance.managedSalesList[i].sidx))
          {
            changed = true;
            break;
          }
        }

        if (!changed)
        {
          alert('저장할 변경 사항이 없습니다.');
          return;
        }
      }
    }
    
    if (!window.confirm(`관리하는 영업담당자 목록 변경사항을 저장할까요?`))
    {
      return;
    }

    let editInfo = [];

    if (!IsValidS(this.state.managedList)) //기존의 목록이 있던 영업 담당자를 모두 삭제하는 경우다
    {
      if (IsValidS(StaffStoreInstance.managedSalesList))
      {
        for (let i = 0;i < StaffStoreInstance.managedSalesList.length; ++i)
        {
          editInfo.push({sidx: StaffStoreInstance.managedSalesList[i].sidx, edit: 0});
        }
      }
    }
    else if (!IsValidS(StaffStoreInstance.managedSalesList)) //새로 추가된 항목만 있는경우
    {
      for (let i = 0;i < this.state.managedList.length; ++i)
      {
        editInfo.push({sidx: this.state.managedList[i].sidx, edit: 1});
      }
    }
    else //추가된 것도 있고, 제거된 것도 있는경우
    {
      //먼저 추가된 항목을 추출하기
      for (let i = 0;i < this.state.managedList.length; ++i)
      {
        if (StaffStoreInstance.findManagedSalesByIdx(this.state.managedList[i].sidx) === null) //기존 목록에 없다면
        {
          editInfo.push({sidx: this.state.managedList[i].sidx, edit: 1});
        }
      }

      //기존 목록에는 있었지만 지금은 제거된것 추출
      for (let i = 0;i < StaffStoreInstance.managedSalesList.length; ++i)
      {
        if (!this.checkCurrentManagedSales(this.state.managedList, StaffStoreInstance.managedSalesList[i].sidx))
        {
          editInfo.push({sidx: StaffStoreInstance.managedSalesList[i].sidx, edit: 0});
        }
      }
    }

    if (!IsValidS(editInfo))
    {
      alert('저장할 변경 사항이 없습니다.');
      return;
    }

    this.setLoading(true);
    StaffStoreInstance.editManagedSalesList(this.props.managerIdx, editInfo, this.onSaveResult);
  }

  onSaveResult = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert('변경사항 저장중 오류가 발생했습니다.');

      return;
    }

    this.generateSalesList();

    alert('변경 사항이 저장되었습니다.');
  }

  render()
  {
    let salesList = null;
    let managedSalesList = null;

    if (IsValidS(this.state.salesList))
    {
      salesList = this.state.salesList.map(item => {return (<EditManagedSalesItem key={item.idx} item={item}  handleSelected={this.handleSelectSales} selected={this.state.selectedSales === item.idx}/>)});
    }

    if (IsValidS(this.state.managedList))
    {
      managedSalesList = this.state.managedList.map(item => {return (<EditManagedSalesAddItem key={item.sidx} item={item} handleSelected={this.handleSelectManaged}  selected={this.state.selectedManagedSales === item.sidx}/>)});
    }

    if (mobileOn)
    {
      return (
        <div className='editmspopbg flexcenterrow'>
          {
            this.state.loading ? (<Loading />) : null
          }
  
          <div className='editmspopmb flexcentercol'>
            <div className='cntfullh flexcenterrowend'>
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={this.props.handleClose} className='margintop10 marginright10 btncur'/>
            </div>
  
            <div className='cntfullh flexcenterrow'>
              <label className='mfontnb'>관리하는 영업담당자 편집</label>
            </div>
  
            <div className='editmshline margintop10 marginbottom10'></div>
  
            <div className='editmslfmb flexcentercolstart'>
              <label className='mfontnb'>영업 담당자 목록</label>
              <div className='editmshline margintop5 marginbottom5'></div>
              {salesList}
            </div>

            <div className='flexcenterrow editmscnmb'>
              <button className='fontlargewb editmsbtnmb btncur bgcolor1 ' onClick={this.handleAddSales}>▼</button>
              <button className='fontlargewb editmsbtnmb btncur bgcolor1' onClick={this.handleDelSales}>▲</button>

              <button className='mfontnwb editmsbtnmb btncur bgcolor1 ' onClick={this.handleReset}>원래되로</button>
            </div>

            <div className='editmsrtmb flexcentercolstart'>
              <label className='mfontnb'>관리하는 영업 담당자 목록</label>
              <div className='editmshline margintop5 marginbottom5'></div>
              {managedSalesList}
            </div>
            
            <button className='btncur mfontnwb bgcolor3 editmssavebtn margintop30' onClick={this.handleSaveResult}>변경사항 저장</button>
            
          </div>
        </div>
      );
    }
    else
    {
      return (
        <div className='editmspopbg flexcenterrow'>
          {
            this.state.loading ? (<Loading />) : null
          }
  
          <div className='editmspop flexcentercol'>
            <div className='cntfullh flexcenterrowend'>
              <img src={GetImgUrl("x_btn2.png")} alt="닫기" onClick={this.props.handleClose} className='margintop10 marginright10 btncur'/>
            </div>
  
            <div className='cntfullh flexcenterrow'>
              <label className='fontlargeb'>관리하는 영업담당자 편집</label>
            </div>
  
            <div className='editmshline margintop30 marginbottom10'></div>
  
            <div className='cntfullh flexcenterrow margintop10'>
              <div className='editmslf flexcentercolstart'>
                <label className='fontnb'>영업 담당자 목록</label>
                <div className='editmshline margintop5 marginbottom5'></div>
                {salesList}
              </div>
  
              <div className='editmscn flexcentercol'>
                <button className='fontlargewb editmsbtn btncur bgcolor1 ' onClick={this.handleAddSales}>{">>"}</button>
                <button className='fontlargewb editmsbtn btncur bgcolor1' onClick={this.handleDelSales}>{"<<"}</button>
  
                <button className='fontnwb editmsbtn btncur bgcolor1 ' onClick={this.handleReset}>원래되로</button>
              </div>
  
              <div className='editmsrt flexcentercolstart'>
                <label className='fontnb'>관리하는 영업 담당자 목록</label>
                <div className='editmshline margintop5 marginbottom5'></div>
                {managedSalesList}
              </div>
            </div>
            
            <button className='btncur fontnwb bgcolor3 editmssavebtn margintop30' onClick={this.handleSaveResult}>변경사항 저장</button>
            
          </div>
        </div>
      );
    }
  }
}

export default EditManagedSales;