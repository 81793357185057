import React from 'react';
import "./Alarm.css";
import { IsValidV } from '../../Util/Util';

const AlarmItem = (props) =>
{
  if (IsValidV(props.item))
  {
    if (props.item.rd === 0)
    {
      return (
        <div className="flexcentercol alarmitem">
          <div className="alarmnewcont flexcenterrowstart">
            <div className="alarmnew"></div>
            <label className="mfontnb alarminfo">{props.item.title}</label>
          </div>
          
          <label className="mfontn alarminfo">{props.item.rdate}</label>
          <label className="mfontn alarminfo">{props.item.notice}</label>
        </div>
      );
    }
    else
    {
      return (
        <div className="flexcentercol alarmitem">
          <label className="mfontnb alarminfo">{props.item.title}</label>
          <label className="mfontn alarminfo">{props.item.rdate}</label>
          <label className="mfontn alarminfo">{props.item.notice}</label>
        </div>
      );
    }
    
  }
  else
  {
    return (
      <div className="alarmitemmore flexcenterrow btncur" onClick = {() => props.handleNext()}>
        <label className="mfontnb">알림 더보기</label>
      </div>
    );
  }
}

export default AlarmItem;