import { AlarmStoreInstance } from "./AlarmStore";
import { ChatStoreInstance } from "./ChatStore";
import { LoginStoreInstance } from "./LoginStore";
import { MOrderStoreInstance } from "./MOrderStore";
import { NoticeStoreInstance } from "./NoticeStore";
import { OrderStoreInstance } from "./OrderStore";
import { ProductStoreInstance } from "./ProductStore";
import { RecommStoreInstance } from "./RecommStore";
import { RetailerStoreInstance } from "./RetailerStore";
import { StaffStoreInstance } from "./StaffStore";
import { StatsStoreInstance } from "./StatsStore";

function ProcessLogout()
{
  RecommStoreInstance.clearAll();
  StatsStoreInstance.clearAll();
  AlarmStoreInstance.clearAll();
  NoticeStoreInstance.clearAll();
  StaffStoreInstance.clearAll();
  RetailerStoreInstance.clearAll();
  OrderStoreInstance.clearAll();
  MOrderStoreInstance.clearAll();
  ProductStoreInstance.clearAll();
  ChatStoreInstance.clearAll();
  LoginStoreInstance.logOut();
  LoginStoreInstance.enableAutoLogin = false;
}

export {ProcessLogout};