import React from 'react';
import { GetChatTime, IsValidS } from '../Util/Util';

function GetCurrentTime(item)
{
  if (IsValidS(item.time))
    return item.time;

  return GetChatTime(item.rdate);
}

const getChattingLabels = (chatMsg, clsName) =>
{
  if (!IsValidS(chatMsg))
    return null;

  if (chatMsg.indexOf("<br>") >= 0)
  {
    const splits = chatMsg.split("<br>");

    if (IsValidS(splits))
    {
      let itemKey = 1;
      return splits.map(item => {
        return (<label key={itemKey++} className={clsName}>{item}</label>);
      });
    }
  }

  return (<label className={clsName}>{chatMsg}</label>);;
}

const ChatAppItem = (props) =>
{
  return (
    <div className="achatitem">

      <div className="achatbubble flexcentercol">
        {/*<label className="achatfont">{props.item.chat}</label>*/}
        {getChattingLabels(props.item.chat, "achatfont")}
      </div>

      <label className="achattime">{GetCurrentTime(props.item)}</label>
      
    </div>
  );
}

export default ChatAppItem;