import { LoginStoreInstance } from "../../Stores/LoginStore";
import { LOG_ENABLE } from "../../URL/AppUrl";
import { IsValidS } from "../../Util/Util";

const ReportSalesSelmb = (props) =>
{
  let sel = null;

  if (IsValidS(props.sales))
  {
    if (LoginStoreInstance.getUserLevel === 3)
    {
      sel = props.sales.map(item => {
        return (<option key={item.sidx} value={item.sidx}>{item.uname}</option>);
      });
    }
    else
    {
      sel = props.sales.map(item => {
        return (<option key={item.idx} value={item.idx}>{item.uname}</option>);
      });
    }
    
  }
  else
  {
    sel = [];
    sel.push((<option key={-1} value={"-1"}>{LoginStoreInstance.getUserLevel === 3 ?  "관리하는 영업담당자가 없습니다." : "영업담당자가 없습니다."}</option>));
  }

  return (
    <div className="flexcenterrow rptsalesselmb">
      <select className="rptsalesselinpmb mfontn"
        onChange={(e) => {
          if (LOG_ENABLE)
            console.log(e.target.value);

            props.handleSalesSelection(parseInt(e.target.value));
        }}
      >
        {sel}
      </select>
    </div>
  );
}

export default ReportSalesSelmb;