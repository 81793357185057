import React from 'react';
import '../Emp/Staff.css';
import './Ret.css';
import '../../App.css';
import {GetImgUrl, LOG_ENABLE, mobileOn} from '../../URL/AppUrl';
//import { Link } from "react-router-dom";
import MainMenu from '../Menu/MainMenu';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import { RetailerStoreInstance } from '../../Stores/RetailerStore';
import RetInfo from './RetInfo';
import PageList from '../PageList';
import RetAdd from './RetAdd';
import { observer } from 'mobx-react';
import Loading from '../Loading/Loading';
import { ProcessLogout } from '../../Stores/StoreManager';
import RetBanList from './RetBanList';
import { IsValidS, IsValidV } from '../../Util/Util';
import RetInfomb from './RetInfomb';
import MenuMb from '../Menu/MenuMb';
import RetAddmb from './RetAddmb';
import RetDetmb from './RetDetmb';
import RetBanListmb from './RetBanListmb';
import { StaffStoreInstance } from '../../Stores/StaffStore';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import RetAccountInfo from './RetAccountInfo';
import RetAccountInfomb from './RetAccountInfomb';

//거래처 관리
class Ret extends React.Component {

  state = 
  {
    searchList : false,
    loading : false,
    retIdx : -1,//편집중인 업체의 고유번호, 만일 신규생성이면 -1
    editRet : false, //편집중인가?
    newRet : false, //신규 추가시true
    startPage : 1, //현재 페이지의 시작 페이지 번호
    endPage : 0, //페이지의 끝 페이지 번호
    currentPage : 1, //현재 보이는 페이지 번호
    pageCount : 0, //전체 페이지 개수
    banList : false, //차단 거래처
    showDetail : false, //상세정보창보이기
    detType : 0, //상세정보창타입, 0 : 일반거래처, 1 : 차단거래처
    appOn : false,
    salesIdx : 0, //0 : 전체 거래처, 기타의 경우 영업담당자별
    salesList : null,
    waitRetAcc : false, //업체의 계정정보를 기다림
    showRetAcc : false, //업체의 계정정보 보기
  };

  searchMode = 0; //검색 모드
  editInfo = null;
  intervalId = -1;
  maxPageNum = 10;
  retAccountIdx = -1;
  retAccountId = null;

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("Ret.componentDidMount");

    if (mobileOn)
      this.maxPageNum = 3;

    this.intervalId = setInterval(this.update, 1000);

    RetailerStoreInstance.clearRetList();

    if (window.ReactNativeWebView)
    {
      document.addEventListener("message", this.handleAppMessage);
      window.addEventListener("message", this.handleAppMessage);

      this.setState((prevState) => ({...prevState, appOn : true}));

      LoginStoreInstance.appEnabled = true;

      this.postMessage(JSON.stringify({action : "webpos", pos : 3}));
    }
    else
      LoginStoreInstance.appEnabled = false;

    if (!LoginStoreInstance.isProfileReady)
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onLoadProfile);
      return;
    }

    StaffStoreInstance.loadSalesManager(this.onLoadSalesMaangerList);    
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (window.ReactNativeWebView)
    {
      document.removeEventListener("message", this.handleAppMessage);
      window.removeEventListener("message", this.handleAppMessage);
    }

    RetailerStoreInstance.clearRetListTotal();
  }

  //리액트로 부터 수신된 메시지
  handleAppMessage = (event) =>
  {
    if (LOG_ENABLE)
      console.log("handleAppMessage : " + event.data);

    //this.postMessage(, event.data);

    try
    {
      let msg = JSON.parse(event.data);

      if (msg && msg.action)
      {
        if (msg.action === "back") //백버튼 눌린경우
        {
          this.backHandler();
        }
      }
    }
    catch(e)
    {
    }
  }

  //리액트로 메시지 보내기
  postMessage = (message) =>
  {
    try
    {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }    
  }

  //앱에서 뒤로가기 버튼누른경우
  backHandler = () =>
  {
    if (LOG_ENABLE)
      this.postMessage(JSON.stringify({action : "log", msg : JSON.stringify(this.state)}));

    if (!this.state.editRet && !this.state.newRet && !this.state.showDetail && !this.state.banList)
      this.props.history.push("/main");
    else if (this.state.showDetail)
    {
      this.handleCloseDetail();
    }
    else if (this.state.banList)
    {
      this.handleCloseBanList();
    }
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError)
    {
      ProcessLogout();
      this.props.history.push("/");
    }
  }

  setLoading = (enabled) =>
  {
    this.setState((prevState) => ({...prevState, loading : enabled}));
  }

  calcPageInfo = () =>
  {
    let currentCount = RetailerStoreInstance.totalCount;

    let pageCount = Math.floor(currentCount / 30);

    if ((currentCount % 30) !== 0)
      ++pageCount;

    let startPage = 1;
    let endPage = this.maxPageNum;

    if (endPage > pageCount)
      endPage = pageCount;

    if (LOG_ENABLE)
    {
      console.log("page count = " + pageCount);
    }

    this.setState((prevState) => ({...prevState, startPage : startPage, endPage : endPage, currentPage : 1, pageCount : pageCount}));
  }

  onLoadProfile = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert("프로필 정보 읽기에 실패 했습니다.");
      ProcessLogout();
      this.props.history.push("/");
      return;
    }

    StaffStoreInstance.loadSalesManager(this.onLoadSalesMaangerList);
  }

  onLoadSalesMaangerList = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      if (!window.confirm("영업담당자 및 관리 담당자 목록 조회중 오류가 발생했습니다.\n다시 시도할까요?"))
      {
        this.setLoading(false);
        return;
      }

      StaffStoreInstance.loadSalesManager(this.onLoadSalesMaangerList);
    }
    else
    {
      if (LoginStoreInstance.getUserLevel === 3)
      {
        StaffStoreInstance.loadManagedSalesList(LoginStoreInstance.userIdx, this.onManagedSalesLoaded);
      }
      else
      {
        this.makeSalesList();      
      }
    }
  }

  onManagedSalesLoaded = (resultCode) =>
  {
    if (resultCode !== 0)
    {
      if (!window.confirm('관리하는 영업담당자 목록을 읽지 못했습니다.\n다시 시도할까요?'))
      {
        this.setLoading(false);
        return;
      }

      StaffStoreInstance.loadManagedSalesList(LoginStoreInstance.userIdx, this.onManagedSalesLoaded);
      return;
    }
    else
    {
      this.makeManagedSalesList();
    }
  }

  //일반관리자 이상권한의 경우 영업담당자 목록을 만듬
  makeSalesList = () =>
  {
    let temp = [];

    temp.push({idx: 0, cid: '0', uname: '전체'});

    if (IsValidS(StaffStoreInstance.salesManagerList))
    {
      temp = temp.concat(StaffStoreInstance.salesManagerList);
    }

    this.setState((prevState) => ({...prevState, salesList: temp, salesIdx: 0}));

    this.loadRetList(1);
  }

  //관리담당자의 경우 자신이 관리하는 영업담당자들의 목록을 만듬
  makeManagedSalesList = () =>
  {
    let temp = [];

    if (!IsValidS(StaffStoreInstance.managedSalesList))
    {
      temp.push({idx: -1, cid: '0', uname: '관리하는 영업담당자 없음'});

      this.setState((prevState) => ({...prevState, salesList: temp, salesIdx: -1}));

      return;
    }

    for (let i = 0;i < StaffStoreInstance.managedSalesList.length; ++i)
    {
      temp.push({idx: StaffStoreInstance.managedSalesList[i].sidx, uname: StaffStoreInstance.managedSalesList[i].uname, 
        cid: StaffStoreInstance.managedSalesList[i].cid});
    }

    this.setState((prevState) => ({...prevState, salesList: temp, salesIdx: StaffStoreInstance.managedSalesList[0].sidx}));

    this.loadSalesRetList(1, StaffStoreInstance.managedSalesList[0].sidx);
  }

  changeSales = (salesIdx) =>
  {
    if (LOG_ENABLE)
      console.log(`changeSales : ${salesIdx}`);

    let temp = parseInt(salesIdx);

    if (LoginStoreInstance.getUserLevel === 3) //관리담당이면
    {
      if (temp < 1)
      {
        return;
      }

      this.setState((prevState) => ({...prevState, salesIdx: temp}));

      if (this.loadSalesRetList(1, temp))
      {
        if (!this.state.loading)
          this.setLoading(true);
      }
    }
    else //어드민, 일반 관리자
    {
      let salesInfo = this.findSalesByIdx(temp);

      if (salesInfo)
      {
        this.setState((prevState) => ({...prevState, salesIdx: temp}));
  
        if (temp === 0)
        {
          this.loadRetListAll(1);
  
          if (!this.state.loading)
            this.setLoading(true);
        }
        else if (this.loadSalesRetList(1, temp))
        {
          if (!this.state.loading)
            this.setLoading(true);
        }
      }
    }
  }

  findSalesByIdx = (salesIdx) =>
  {
    if (!IsValidS(this.state.salesList))
      return null;

    for (let i = 0;i < this.state.salesList.length; ++i)
    {
      if (this.state.salesList[i].idx === salesIdx)
        return this.state.salesList[i];
    }

    return null;
  }

  loadRetList = (page) =>
  {
    if (LoginStoreInstance.getUserLevel === 3)
    {
      if (!this.loadSalesRetList(page, this.state.salesIdx))
        return;
    }
    else if (LoginStoreInstance.getUserLevel <= 1)
    {
      if (this.state.salesIdx === 0)
        this.loadRetListAll(page);
      else
      {
        if (!this.loadSalesRetList(page, this.state.salesIdx))
          return;
      }
    }
    else
    {
      RetailerStoreInstance.loadRetailer((page - 1) * 30, 5, LoginStoreInstance.userId, this.onLoadListResult);
    }

    if (!this.state.loading)
      this.setLoading(true);
  }

  loadRetListAll = (page) =>
  {
    RetailerStoreInstance.loadRetailer((page - 1) * 30, 0, null, this.onLoadListResult);
  }

  //특정 영업담당자의 거래처 목록 읽기
  loadSalesRetList = (page, salesIdx) =>
  {
    let salesInfo = this.findSalesByIdx(salesIdx);

    if (!salesInfo)
    {
      if (LOG_ENABLE)
        console.log('sales not found!!');

      return false;
    }

    if (LOG_ENABLE)
      console.log(`sales found : ${salesInfo.idx}, ${salesInfo.cid}`);

    RetailerStoreInstance.loadRetailer((page - 1) * 30, 5, salesInfo.cid, this.onLoadListResult);
  }

  onLoadListResult = (resultCode, totalCount, isFirst) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert("목록 읽기 실패 입니다.");
      return;
    }

    if (isFirst)
    {
      this.calcPageInfo();
    }
  }
  
  //상단의 메뉴항목 또는 메인 페이지의 메뉴 항목들 클릭시
  handleMenuClick = (menuIndex) => {
    if (LOG_ENABLE)
      console.log("handleMenuClick : " + menuIndex);

    switch(menuIndex)
    {
      case -1:
      case 0: //메인
        this.props.history.push("/main");
        break;
      case 1: //직원관리
        this.props.history.push("/emp");
        break;
      case 2: //거래처관리
        break;
      case 3: //주문관리
        this.props.history.push("/order"); 
        break;
      case 4: //판매일보
        this.props.history.push("/report");
        break;
      case 5: //신규상품등록
        this.props.history.push("/goods");  
        break;
      case 6: //기타주문
        this.props.history.push("/morder"); 
        break;
      case 7: //공지사항
        this.props.history.push("/notice");  
        break;
      case 8: //채팅
        this.props.history.push("/chat");  
        break;
      case 9: //알림
        this.props.history.push("/alarm");  
        break;
      case 11: //통계
        this.props.history.push("/stats");  
        break;
      case 12: //수기주문등록
        this.props.history.push("/orderm");  
        break;
      default:
        break;
    }
  }
  
  //검색어 바뀜
  onSearchChanged = (e) => {
    
    RetailerStoreInstance.setSearchWord(e.target.value);
  }

  handleListMode = () =>
  {
    this.setState((prevState) => ({...prevState, searchList : false}));

    this.loadRetList(this.state.currentPage);
  }

  //업체 검색하기
  handleSearch = () =>
  {
    if (LOG_ENABLE)
      console.log("handleSearch");

    if (!RetailerStoreInstance.isSearchWordValid())
    {
      if (this.searchMode === 0)
      {
        alert("검색 할 업체 이름을 입력하세요.");
        return;
      }
      else if (this.searchMode === 1)
      {
        alert("검색 할 업체 대표자명을 입력하세요.");
        return;
      }
      else if (this.searchMode === 2)
      {
        alert("검색 할 업체 사업자번호를 입력하세요.");
        return;
      }
      else
      {
        alert("검색 할 업체 전화번호를 입력하세요.");
        return;
      }
    }

    if (LoginStoreInstance.getUserLevel === 3)
    {
      let salesInfo = this.findSalesByIdx(this.state.salesIdx);

      if (this.state.salesIdx < 1 || !salesInfo)
      {
        alert('관리중인 영업담당자가 없습니다.');
        return;
      }

      this.setState((prevState) => ({...prevState, loading : true, searchList : true}));
      //현재 선택된 영업담당자
      RetailerStoreInstance.searchRetailer(this.searchMode, 0, salesInfo.cid, this.onSearchResult);
    }
    else
    {
      this.setState((prevState) => ({...prevState, loading : true, searchList : true}));
      
      RetailerStoreInstance.searchRetailer(this.searchMode, 0, LoginStoreInstance.getUserId, this.onSearchResult);
    }
    
  }

  onSearchResult = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert("거래처 검색에 실패 했습니다.");
    }
  }
  //업체 정보 수정
  handleChangeRet = () =>
  {
    if (LOG_ENABLE)
      console.log("handleChangeUser");

    if (this.state.retIdx === -1)
    {
      alert("수정 할 업체를 선택하세요.");
      return;
    }

    this.editInfo = RetailerStoreInstance.findRetailerByIdx(this.state.retIdx);

    if (this.editInfo === null)
    {
      alert("수정 할 업체를 선택하세요.");
      return;
    }

    //영업담당이 한명밖에 없다면
    if (StaffStoreInstance.getSalesManagerCount() === 1)
    {
      this.editInfo.sid = StaffStoreInstance.getFirstSalesManagerId();
    }

    this.setState((prevState) => ({...prevState, editRet : true, newRet : false}));
  }

  handleEditDirect = (retailerIdx) =>
  {
    if (LOG_ENABLE)
      console.log("handleEditDirect");

    if (LoginStoreInstance.getUserLevel > 1)
    {
      return;
    }

    this.editInfo = RetailerStoreInstance.findRetailerByIdx(retailerIdx);

    if (this.editInfo === null)
    {
      alert("수정 할 업체를 선택하세요.");
      return;
    }

    //영업담당이 한명밖에 없다면
    if (StaffStoreInstance.getSalesManagerCount() === 1)
    {
      this.editInfo.sid = StaffStoreInstance.getFirstSalesManagerId();
    }

    this.setState((prevState) => ({...prevState, retIdx : retailerIdx, editRet : true, newRet : false}));
  }

  //업체 정보 삭제
  handleDeleteRet = () =>
  {
    if (LOG_ENABLE)
      console.log("handleDeleteUser");

    if (this.state.retIdx === -1)
    {
      alert("삭제할 업체를 선택하세요.");
      return;
    }

    this.editInfo = RetailerStoreInstance.findRetailerByIdx(this.state.retIdx);

    if (this.editInfo === null)
    {
      alert("삭제할 업체를 선택하세요.");
      return;
    }

    if (!window.confirm(this.editInfo.cname + "(" + this.editInfo.cnum + ") 업체를 거래처 목록에서 삭제할까요?"))
    {
      return;
    }

    this.setLoading(true);
    RetailerStoreInstance.delRetailer(this.state.retIdx, this.onDeleteResult);
  }

  onDeleteResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.calcPageInfo();
        alert("해당 거래처가 삭제되었습니다.");
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("거래처 삭제 권한이 없습니다.");
        break;
      default:
        alert("거래처 삭제에 실패 했습니다.");
        break;
    }
  }

  //거래처 차단 버튼 눌림
  handleBanRet = () =>
  {
    if (LOG_ENABLE)
      console.log("handleBanRet");

    if (this.state.retIdx === -1)
    {
      alert("차단할 업체를 선택하세요.");
      return;
    }

    this.editInfo = RetailerStoreInstance.findRetailerByIdx(this.state.retIdx);

    if (this.editInfo === null)
    {
      alert("차단할 업체를 선택하세요.");
      return;
    }

    if (LoginStoreInstance.getUserLevel > 1)
    {
      alert("거래처를 차단할 권한이 없습니다.");
      return;
    }

    if (!window.confirm(this.editInfo.cname + "(" + this.editInfo.cnum + ") 업체를 차단할까요?"))
    {
      return;
    }

    this.setLoading(true);
    RetailerStoreInstance.banRetailer(this.state.retIdx, 0, this.onBanResult);
  }

  //업체 차단 결과
  onBanResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0: //정상
        alert('거래처의 주문이 차단되었습니다.');
        break;
      case 1: //잘못된 요청
        alert('잘못된 요청입니다.');
        break;
      case 2: //권한 없음
        alert('업체를 차단할 권한이 없습니다.');
        break;
      case 4:
        alert('해당 업체를 찾을 수 없습니다.');
        break;
      case 5:
        alert('해당 업체는 현재 N-돌핀 앱에 가입되어 있지 않습니다.');
        break;
      case 6:
        alert('해당 업체는 현재 거래 상대가 아닙니다.');
        break;
      case 3: //차단 실패
      default:
        alert('업체 차단에 실패 했습니다.');
        break;
    }
  }
  //업체 등록 버튼
  handleAdd = () =>
  {
    if (LOG_ENABLE)
      console.log("handleAdd");

    this.editInfo = {idx : -1, ctype: '', btype: '', cname: '', oname:'', cphone:'', cnum:'', 
      sid: StaffStoreInstance.getFirstSalesManagerId(), 
      pid: StaffStoreInstance.getFirstSalesManagerId(), 
      addr:'', email:'', rdate:'', ct : 0
    };

    this.setState((prevState) => ({...prevState, editRet : true, newRet : true, retIdx : -1}));
  }

  handleBanList = () =>
  {
    if (LOG_ENABLE)
      console.log("handleBanList");

    this.setState((prevState) => ({...prevState, banList: true}));
  }

  handleCloseBanList = () =>
  {
    if (LOG_ENABLE)
      console.log("handleCloseBanList");

    this.setState((prevState) => ({...prevState, banList: false}));
  }

  //업체 편집창 닫기
  handleCloseRet = () => 
  {
    this.setState((prevState) => ({...prevState, editRet : false, newRet : false}));
  }

  /*업체 정보 등록/편집창에서 업체 정보 등록/수정 요청이 옴
  idx :업체 고유번호, 신규면 -1
  ctype :업종
  btype: 업태
  cname: 상호
  oname: 대표자명
  cphone: 전화번호
  cnum : 사업자 번호
  sid : 영업담당자 id
  pid : 관리 담당자 id
  addr : 주소
  email : 이메일주소
  companyType : 업체 타입, 0 : 유흥, 1 : 일반
  */
  addRetailer = (idx, ctype,btype,cname,oname,cphone,cnum,sid,pid,addr,email,companyType) =>
  {
    if (!IsValidS(cname))
    {
      alert("거래처 상호를 올바르게 입력해 주세요.");
      return;
    }

    if (!IsValidS(oname))
    {
      alert("거래처 대표자명을 올바르게 입력해 주세요.");
      return;
    }

    if (!IsValidS(cphone))
    {
      alert("거래처 대표 전화번호를 입력해 주세요.");
      return;
    }

    if (!IsValidS(cnum))
    {
      alert("거래처 사업자번호를 입력해 주세요.");
      return;
    }

    if (!IsValidS(sid))
    {
      alert("영업 담당자 계정 정보를 입력해주세요.");
      return;
    }

    /*if (!IsValidS(pid))
    {
      alert("관리 담당자 계정 정보를 입력해주세요.");
      return;
    }*/

    if (!IsValidS(ctype))
    {
      alert("거래처 업종 정보를 올바르게 입력해 주세요.");
      return;
    }

    if (!IsValidS(btype))
    {
      alert("거래처 업태 정보를 올바르게 입력해 주세요.");
      return;
    }

    if (!IsValidS(addr))
    {
      alert("거래처 주소를 입력해 주세요.");
      return;
    }


    if (!IsValidS(email))
    {
      //alert("거래처 이메일 주소를 입력해 주세요.");
      //return;
      email = 'n';
    }

    if (!IsValidV(companyType) || (companyType !== 0 && companyType !== 1))
    {
      alert("업체 구분 정보를 올바르게 선택해 주세요.");
      return;
    }

    if (idx === -1)
    {
      if (!window.confirm("신규 업체를 등록 할까요?"))
        return;

      this.setLoading(true);
      RetailerStoreInstance.addRetailer(cname,oname,cphone,cnum,sid,sid/*pid*/,ctype,btype,addr,email,companyType, this.onAddRetResult);
    }
    else
    {
      const retailerInfo = RetailerStoreInstance.findRetailerByIdx(idx);

      if (retailerInfo === null)
      {
        alert('수정할 업체 정보를 찾지 못했습니다.');
        return;
      }

      let tempCnum = cnum.replace(/-/gi, "");
      let tempCnum2 = retailerInfo.cnum.replace(/-/gi, "");

      //사업자 번호가 변경된경우
      if (tempCnum !== tempCnum2)
      {
        if (LoginStoreInstance.userType !== 1)
        {
          alert('사업자 등록번호 수정권한이 없습니다.');
          return;
        }
      }

      if (!window.confirm("업체 정보를 수정 할까요?"))
        return;

      this.setLoading(true);
      RetailerStoreInstance.editRetailer(idx, cname,oname,cphone,cnum,sid,sid/*pid*/,ctype,btype,addr,email,companyType, this.onEditResult);
    }
  }

  onEditResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.setState((prevState) => ({...prevState, editRet : false}));
        alert("거래처 정보가 수정 되었습니다.");
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("거래처 등록 권한이 없습니다.");
        break;
      case 3:
        alert("영업담당자 계정이 존재하지 않습니다. 계정을 확인해 주세요.");
        break;
      case 4:
        alert("관리 담당자 계정이 존재하지 않습니다. 계정을 확인해 주세요.");
        break;
      case 5:
        alert("해당 사업자 번호는 이미 존재합니다.");
        break;
      default:
        alert("거래처 수정이 실패 했습니다.");
        break;
    }
  }

  onAddRetResult = (resultCode)=>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.setState((prevState) => ({...prevState, editRet : false}));

        if (this.state.currentPage === 1)
          this.loadRetList(this.state.currentPage);

        alert("거래처가 등록 되었습니다.");
        break;
      case 1:
        alert("잘못된 요청입니다.");
        break;
      case 2:
        alert("거래처 등록 권한이 없습니다.");
        break;
      case 3:
        alert("영업담당자 계정이 존재하지 않습니다. 계정을 확인해 주세요.");
        break;
      case 4:
        alert("관리 담당자 계정이 존재하지 않습니다. 계정을 확인해 주세요.");
        break;
      case 5:
        alert("해당 사업자 번호는 이미 존재합니다.");
        break;
      default:
        alert("거래처 등록에 실패 했습니다.");
        break;
    }
  }
  //전체 선택 체크 박스
  onCheckChanged = () =>
  {
    if (LOG_ENABLE)
      console.log("onCheckChanged");
  }

  //업체 목록 리스트의 체크 박스
  onChildCheckChanged = (index) =>
  {
    if (LOG_ENABLE)
      console.log("onChildCheckChanged : " + index);

    //체크된 직원 정보외의 정보는 체크 해제하고
    //체크된 직원의 정보를 저장함

    if (index === this.state.retIdx)
      this.setState((prevState) => ({...prevState, retIdx : -1}));
    else
      this.setState((prevState) => ({...prevState, retIdx : index}));
  }

  //하단 페이지 변경 버튼 눌릴경우
  //처음페이지 -1
  //이전페이지 -2
  //다음페이지 -3 
  //마지막 페이지 -4
  handlePage = (pageNum) =>
  {
    if (LOG_ENABLE)
      console.log("handlePage : " + pageNum);

    if (pageNum === -1)
    {
      let endPage = this.maxPageNum;

      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : 1, startPage : 1, endPage : endPage}));

      this.loadRetList(1);
    }
    else if (pageNum === -2)
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadRetList(startPage);
    }
    else if (pageNum === -3)
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadRetList(startPage);
    }
    else if (pageNum === -4)
    {
      let endPage = this.state.pageCount;
      let startPage = endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;

      this.setState((prevState) => ({...prevState, currentPage : endPage, startPage : startPage, endPage : endPage}));

      this.loadRetList(endPage);
    }
    else
    {
      this.setState((prevState) => ({...prevState, currentPage : pageNum}));

      this.loadRetList(pageNum);
    }
  }

  handleDetail = (retIdx, detType) =>
  {
    if (LOG_ENABLE)
      console.log(`handleDetail => retidx = ${retIdx}, detType : ${detType}`);

    this.editInfo = detType === 0 ? RetailerStoreInstance.findRetailerByIdx(retIdx) : RetailerStoreInstance.findRetailerBanByIdx(retIdx);

    if (!IsValidV(this.editInfo))
    {
      return;
    }

    this.setState((prevState) => ({...prevState, showDetail : true, detType : detType}));
  }

  handleCloseDetail = () =>
  {
    this.setState((prevState) => ({...prevState, showDetail : false}));
  }

  handleLogout = () => {
    ProcessLogout();
    this.props.history.push("/");
  }
  
  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.handleSearch();
    }
  }

  handleExportExcel = () =>
  {
    if (LOG_ENABLE)
      console.log("handleExportExcel");

    if (this.state.loading)
    {
      return;
    }

    if (RetailerStoreInstance.retListTotalDone)
    {
      this.exportExcel();
    }
    else
    {
      this.setLoading(true);
      RetailerStoreInstance.clearRetListTotal();
      RetailerStoreInstance.loadRetailer(RetailerStoreInstance.retListtotalIdx, 4, null, this.onTotalListLoadDone);
    }
  }

  onTotalListLoadDone = (resultCode, readCount, unUsed) =>
  {
    if (resultCode !== 0)
    {
      this.setLoading(false);
      alert('거래처 목록 조회중 오류가 발생했습니다.');
      return;
    }

    if (readCount < 1)
    {
      this.setLoading(false);
      this.exportExcel();
      return;
    }

    RetailerStoreInstance.loadRetailer(RetailerStoreInstance.retListtotalIdx, 4, null, this.onTotalListLoadDone);
  }

  exportExcel = () =>
  {
    try
    {
      let temp = [];

      const workBook = new ExcelJS.Workbook();
      workBook.creator = 'Bravo6';
      workBook.created = new Date();
      workBook.modified = new Date();

      let salesList = [];

      RetailerStoreInstance.totalListToExcelValue(temp, salesList, null);

      let sheet = workBook.addWorksheet('거래처목록', {properties:{tabColor:{argb:'FFFC0000'}, outlineLevelCol:1}});
      this.makeSheet(sheet, temp);

      if (IsValidS(salesList))
      {
        for (let i = 0;i < salesList.length; ++i)
        {
          temp = [];
          RetailerStoreInstance.totalListToExcelValue(temp, salesList, salesList[i].id);

          sheet = workBook.addWorksheet(salesList[i].name, {properties:{outlineLevelCol:1}});
          this.makeSheet(sheet, temp);
        }
      }
  
      workBook.xlsx.writeBuffer().then(function (buffer){
        saveAs(new Blob([buffer], {type: "application/octet-stream"}), `${LoginStoreInstance.getCompanyName}_거래처.xlsx`);
      });
    }
    catch(e)
    {
      console.log(e.toString());
      alert('거래처 목록 출력중 오류가 발생했습니다.');
    }
  }

  makeSheet = (workSheet, valueList) =>
  {
    workSheet.columns = [
      { header: '상호', key: 'cname'},
      { header: '대표자', key: 'oname'},
      { header: '사업자번호', key: 'cnum'},
      { header: '업종', key: 'ctype'},
      { header: '업태', key: 'btype'},
      { header: '업체구분', key: 'ct'},
      { header: '전화번호', key: 'cphone'},
      { header: '주소', key: 'addr'},
      { header: '이메일', key: 'email'},
      { header: '영업담당자ID', key: 'sid'},
      { header: '영업담당자이름', key: 'sname'},
      { header: '업체상태', key: 'st'},
    ];

    let border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };

    let row = workSheet.getRow(1);
    let fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'ff797979'},
    };

    row.font = {bold : true, color : {argb: 'ffffffff'}};
    row.alignment = {vertical: 'center', horizontal: 'center'};
    //row.fill = fill;

    row.getCell('cname').border = border;
    row.getCell('oname').border = border;
    row.getCell('cnum').border = border;
    row.getCell('ctype').border = border;
    row.getCell('btype').border = border;
    row.getCell('ct').border = border;
    row.getCell('cphone').border = border;
    row.getCell('addr').border = border;
    row.getCell('email').border = border;
    row.getCell('sid').border = border;
    row.getCell('sname').border = border;
    row.getCell('st').border = border;

    row.getCell('cname').fill = fill;
    row.getCell('oname').fill = fill;
    row.getCell('cnum').fill = fill;
    row.getCell('ctype').fill = fill;
    row.getCell('btype').fill = fill;
    row.getCell('ct').fill = fill;
    row.getCell('cphone').fill = fill;
    row.getCell('addr').fill = fill;
    row.getCell('email').fill = fill;
    row.getCell('sid').fill = fill;
    row.getCell('sname').fill = fill;
    row.getCell('st').fill = fill;
    
    if (valueList.length > 0)
    {
      fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'fff9f9f9'},
      };

      for (let i = 0;i < valueList.length; ++i)
      {
        row = workSheet.getRow(i + 2);
        row.values = valueList[i];
        row.alignment = {vertical: 'center', horizontal: 'center'};
        row.getCell('cname').border = border;
        row.getCell('oname').border = border;
        row.getCell('cnum').border = border;
        row.getCell('ctype').border = border;
        row.getCell('btype').border = border;
        row.getCell('ct').border = border;
        row.getCell('cphone').border = border;
        row.getCell('addr').border = border;
        row.getCell('email').border = border;
        row.getCell('sid').border = border;
        row.getCell('sname').border = border;
        row.getCell('st').border = border;


        if ((i % 2) !== 0)
        {
          row.getCell('cname').fill = fill;
          row.getCell('oname').fill = fill;
          row.getCell('cnum').fill = fill;
          row.getCell('ctype').fill = fill;
          row.getCell('btype').fill = fill;
          row.getCell('ct').fill = fill;
          row.getCell('cphone').fill = fill;
          row.getCell('addr').fill = fill;
          row.getCell('email').fill = fill;
          row.getCell('sid').fill = fill;
          row.getCell('sname').fill = fill;
          row.getCell('st').fill = fill;
        }
      }
    }

    this.autoWidthSheet(workSheet, 15);
  }
 
  autoWidthSheet = (worksheet, minimalWidth = 10) => {
    worksheet.columns.forEach((column) => {
        let maxColumnLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            maxColumnLength = Math.max(
                maxColumnLength,
                minimalWidth,
                cell.value ? cell.value.toString().length : 0
            );
        });
        column.width = maxColumnLength + 2;
    });
  }

  handleShowAccount = (idx) => {
    if (LOG_ENABLE)
      console.log(`handleShowAccount : ${idx}`);

    if (this.state.loading || this.state.waitRetAcc)
    {
      return;
    }
    
    this.retAccountIdx = idx;

    this.setState((prevState) => ({...prevState, loading : true, waitRetAcc: true}));
    RetailerStoreInstance.editRetailerAccount(idx, 0, null, null, this.onLoadRetAccount);
  }

  onLoadRetAccount = (resultCode, retId) => {

    this.setState((prevState) => ({...prevState, loading : false, waitRetAcc: false}));

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        alert('잘못된 요청입니다.');
        return;
      case 2:
        alert('권한이 없습니다.');
        return;
      case 3:
        alert('계정이 존재하지 않습니다.');
        return;
      default:
        alert('계정 조회중 오류가 발생했습니다.');
        return;
    }

    this.retAccountId = retId;
    this.setState((prevState) => ({...prevState, showRetAcc : true}));

    console.log(`ret account : ${retId}`);
  }

  //업체 N-돌핀 계정 비밀번호 초기화
  handleResetRetPassword = () =>{
    if (!window.confirm('업체의 N-돌핀 앱 비밀번호를 초기화 할까요?'))
    {
      return;
    }

    if (this.state.loading || this.state.waitRetAcc || this.retAccountIdx < 1 || !IsValidS(this.retAccountId))
    {
      return;
    }

    this.setState((prevState) => ({...prevState, loading : true, waitRetAcc: true}));
    RetailerStoreInstance.editRetailerAccount(this.retAccountIdx, 1, null, null, this.onResetRetAccount);
  }

  onResetRetAccount = (resultCode, unused) =>{
    
    this.setState((prevState) => ({...prevState, loading : false, waitRetAcc: false, showRetAcc: false}));

    switch(resultCode){
      case 0:
        alert('비밀번호가 1111로 초기화 되었습니다.');
        break;
      case 1:
        alert('잘못된 요청입니다.');
        break;
      case 2:
        alert('권한이 없습니다.');
        break;
      case 3:
        alert('계정이 존재하지 않습니다.');
        break;
      case 4:
      default:
        alert('비밀번호 초기화 중 오류가 발생했습니다.');
        break;
    }
  }

  handleCloseRetAccount = () =>
  {
    this.setState((prevState) => ({...prevState, showRetAcc: false}));
  }

  render() {
    const retList = RetailerStoreInstance.getRetList;

    let ret = null;

    let salesList = null;

    if (IsValidS(this.state.salesList))
    {
      salesList = this.state.salesList.map(item => {
        return (
          <option key = {item.idx} value={item.idx.toString()}>{item.uname}</option>
        );
      });
    }

    if (mobileOn) //모바일웹
    {
      if (retList !== null && retList.length > 0)
      {
        ret = retList.map(item => {
          return (<RetInfomb key={item.idx} index={item.idx} onCheckChanged = {this.onChildCheckChanged} cname={item.cname} cphone={item.cphone} 
            oname={item.oname} cnum={item.cnum} sid={item.sid} pid={item.pid} sname={item.sname} pname={item.pname} addr={item.addr} 
            selectIdx={this.state.retIdx} ct={item.ct} handleDetail = {this.handleDetail} detType={0} appinst = {item.aidx > 0}/>);
        });
      }
  
      return (
        <div className="appcontmb flexcentercolstart">
          {
            this.state.loading ? <Loading />:null
          }
  
          {
            this.state.banList ? <RetBanListmb handleClose={this.handleCloseBanList} setLoading = {this.setLoading} 
              handleDetail={this.handleDetail}/> : null
          }
  
          {
            this.state.editRet ? 
              (<RetAddmb 
                retIdx = {this.state.retIdx}
                newRet = {this.state.newRet}
                handleClose={this.handleCloseRet} 
                btntitle={this.state.newRet?"거래처 등록" : "거래처 정보 저장"}
                title={this.state.newRet?"거래처 등록" : "거래처 정보"}
                addRet= {this.addRetailer}
                ctype={this.editInfo.ctype}
                btype={this.editInfo.btype}
                cname={this.editInfo.cname}
                oname={this.editInfo.oname}
                cphone={this.editInfo.cphone}
                cnum={this.editInfo.cnum}
                sid={this.editInfo.sid}
                pid={this.editInfo.pid}
                addr={this.editInfo.addr}
                email={this.editInfo.email}
                ct={this.editInfo.ct}
                sales={StaffStoreInstance.salesManagerList}
              />) : null
          }
          
          {
            this.state.showDetail ? 
              (<RetDetmb
                handleCloseDetail={this.handleCloseDetail} 
                title={this.state.detType === 0 ?"거래처 상세정보 확인" : "차단 거래처 상세정보 확인"}
                ctype={this.editInfo.ctype}
                btype={this.editInfo.btype}
                cname={this.editInfo.cname}
                oname={this.editInfo.oname}
                cphone={this.editInfo.cphone}
                cnum={this.editInfo.cnum}
                sid={`${this.editInfo.sname}(${this.editInfo.sid})`}
                pid={`${this.editInfo.pname}(${this.editInfo.pid})`}
                addr={this.editInfo.addr}
                email={this.editInfo.email}
                ct={this.editInfo.ct}
                appinst = {this.editInfo.aidx > 0}
                retIdx={this.editInfo.aidx}
                handleShowAccount = {this.handleShowAccount}
                ulevel = {LoginStoreInstance.getUserLevel}
              />) : null
          }
          
          {
            this.state.showRetAcc ? <RetAccountInfomb accIdx={this.retAccountIdx} accId={this.retAccountId} handleResetRetPassword={this.handleResetRetPassword} 
            handleCloseRetAccount={this.handleCloseRetAccount}
              /> : null
          }
          <MenuMb handleMenuClick={this.handleMenuClick} handleLogout = {this.handleLogout}/>

          <div className="maincontainermb">
            <div className="retserconmb flexcentercol">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("mbrshop.png")} alt="거래처관리"/>
                <label className="mfontnb">&nbsp;거래처관리</label>
              </div>
  
              <div className="flexcenterrow retserconareamb">
                <select className="retserselmb mfontn"
                  onChange={(e) => {
                    if (LOG_ENABLE)
                      console.log(e.target.value);

                    this.searchMode = parseInt(e.target.value);
                  }}
                >
                  <option value="0">상호</option>
                  <option value="1">대표자명</option>
                  <option value="2">사업자번호</option>
                  <option value="3">전화번호</option>
                </select>

                <input type="text" onChange={this.onSearchChanged} value = {RetailerStoreInstance.searchWord} className="retserinpmb mfontn" 
                  onKeyPress={this.handleKeyPress}
                />

                <button className="retserbtnmb mfontnb" onClick={this.handleSearch}>
                  검색
                </button>
              </div>
            </div>

            {
              LoginStoreInstance.getUserLevel <= 1 ? (
                <div className="flexcenterrow retbtnareamb">
                  <button className="bgcolor2 retbanlistbtnmb mfontnwb" onClick={this.handleBanList}>
                    거래처 차단 관리
                  </button>

                  <button className="bgcolor1 retaddbtnmb mfontnwb" onClick={this.handleAdd}>
                    거래처등록
                  </button>  
                </div>
              ) : null
            }
            
  
            <div className="reteditarea2mb">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("mbpapers.png")} alt="거래처정보" />
                <label className="mfontnb">&nbsp;거래처정보</label>
              </div>
              
              <div className="empline"/>

              {
                LoginStoreInstance.getUserLevel <= 1 ? (
                  <div className="flexcenterrow reteditinfomb">
                    <div className="flexcenterrow">
                      <input type="radio" onChange={this.onCheckChanged} checked = {false} className="chk"/>
                      <label className="btncur mfontnb">&nbsp;선택한 정보를</label>
                    </div>
                    
                    <button className="bgcolor1 retsmallbtnmb mfontnwb" onClick = {this.handleChangeRet}>
                      수정
                    </button>


                    <button className="bgcolor2 retsmallbtnmb mfontnwb" onClick = {this.handleDeleteRet}>
                      삭제
                    </button>

                    <button className="bgcolor2 retbanbtnmb mfontnwb" onClick = {this.handleBanRet}>
                      거래처 차단
                    </button>
                  </div>
                ) : null
              }
              

              {
                !this.state.appOn && LoginStoreInstance.getUserLevel <= 1 ? (
                  <button className="retexcelbtnmb mfontnwb" onClick = {this.handleExportExcel}>
                    전체 거래처 엑셀 출력
                  </button>
                ) : null
              }
              
              {
                this.state.searchList ? (
                  <button className="mfontnwb retlmbtnmb" onClick = {this.handleListMode}>
                    목록보기
                  </button>
                ):
                null
              }

              {
                LoginStoreInstance.getUserLevel <= 1 || LoginStoreInstance.getUserLevel === 3 ? (
                  <select className="retsalesselmb mfontn  btncur"
                    value={this.state.salesIdx.toString()}
                    onChange={(e) => {
                      if (LOG_ENABLE)
                        console.log(e.target.value);

                      this.changeSales(e.target.value);
                    }}
                  >
                    {salesList}
                  </select> 
                ):null
              }
              
            </div>
  
            

            <div className = "tablecontmb">
              <table className="emptablemb">
                <thead>
                  <tr>
                    <th className="emptableheadermb">
                      {/*<input type="checkbox" onChange={this.onCheckChanged} checked = {false} className="chk"/>*/}
                    </th>
  
                    <th className="emptableheadermb">
                      <label className="mfontnw">업체명</label>
                    </th>
  
                    <th className="emptableheadermb">
                      <label className="mfontnw">상세정보 확인</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {ret}
                </tbody>
              </table>
            </div>
            {
              this.state.pageCount > 1 ? <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
          </div>
        </div>
      );
    }
    else //PC웹
    {
      if (retList !== null && retList.length > 0)
      {
        ret = retList.map(item => {
          return (<RetInfo key={item.idx} index={item.idx} 
            onCheckChanged = {this.onChildCheckChanged} 
            handleEditDirect = {this.handleEditDirect}
            cname={item.cname} cphone={item.cphone} 
            oname={item.oname} cnum={item.cnum} sid={item.sid} pid={item.pid} sname={item.sname} pname={item.pname} addr={item.addr} 
            selectIdx={this.state.retIdx} ct={item.ct}
            ulevel={LoginStoreInstance.getUserLevel}
            handleShowAccount = {this.handleShowAccount}
            retIdx = {item.aidx}
            appinst = {item.aidx > 0}/>);
        });
      }
  
      return (
        <div className="appcontainer">
          {
            this.state.loading ? <Loading />:null
          }
  
          {
            this.state.banList ? <RetBanList handleClose={this.handleCloseBanList} setLoading = {this.setLoading} history = {this.props.history}/> : null
          }
  
          {
            this.state.showRetAcc ? <RetAccountInfo accIdx={this.retAccountIdx} accId={this.retAccountId} handleResetRetPassword={this.handleResetRetPassword} 
            handleCloseRetAccount={this.handleCloseRetAccount}
              /> : null
          }

          {
            this.state.editRet ? 
              (<RetAdd 
                retIdx = {this.state.retIdx}
                newRet = {this.state.newRet}
                handleClose={this.handleCloseRet} 
                btntitle={this.state.newRet?"거래처 등록" : "거래처 정보 저장"}
                title={this.state.newRet?"거래처 등록" : "거래처 정보"}
                addRet= {this.addRetailer}
                ctype={this.editInfo.ctype}
                btype={this.editInfo.btype}
                cname={this.editInfo.cname}
                oname={this.editInfo.oname}
                cphone={this.editInfo.cphone}
                cnum={this.editInfo.cnum}
                sid={this.editInfo.sid}
                pid={this.editInfo.pid}
                addr={this.editInfo.addr}
                email={this.editInfo.email}
                ct={this.editInfo.ct}
                sales={StaffStoreInstance.salesManagerList}
              />) : null
          }
          
          <div className="maincontainer">
            <div className="empmargin"/>
            <MainMenu index={2} handleMenuClick = {this.handleMenuClick}/>
            
            <div className="empsercon">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("shop.png")} alt="거래처관리"/>
                <label className="fontlargeb">&nbsp;거래처관리</label>
              </div>
  
              <div className="empsercontent flexcenterrowstart">
  
                <div className="retserleft flexcenterrowstart">
                  <img src={GetImgUrl("search.png")} alt="거래처검색" className="retserimg"/>
                  <label className="fontnwb">거래처검색</label>
  
                  <select className="empselect fontn"
                    onChange={(e) => {
                      if (LOG_ENABLE)
                        console.log(e.target.value);
  
                      this.searchMode = parseInt(e.target.value);
                    }}
                  >
                    <option value="0">상호</option>
                    <option value="1">대표자명</option>
                    <option value="2">사업자번호</option>
                    <option value="3">전화번호</option>
                  </select>
  
                  <input type="text" onChange={this.onSearchChanged} value = {RetailerStoreInstance.searchWord} className="retserinp fontn"
                    onKeyPress={this.handleKeyPress}
                  />
  
                  <button className="empserbtn fontnb" onClick={this.handleSearch}>
                    검색
                  </button>

                  {
                    this.state.searchList && LoginStoreInstance.getUserLevel > 1 ? (
                      <button className="fontnb retlmbtn2" onClick = {this.handleListMode}>
                        목록보기
                      </button>
                    ):
                    null
                  }
                </div>
  
                <div className="retserright flexcenterrowend">

                  {
                    LoginStoreInstance.getUserLevel <= 1 ? (
                      <button className="retbanlistbtn fontnb" onClick={this.handleBanList}>
                        거래처 차단 관리
                      </button>
                    ) : null
                  }
                  
                  {
                    LoginStoreInstance.getUserLevel <= 1 ? (
                      <button className="retaddbtn fontnb" onClick={this.handleAdd}>
                        거래처등록
                      </button>
                    ) : null
                  }
                </div>
              </div>
            </div>
  
            <div className="empsercon">
              <div className="empsertitle flexcenterrowstart">
                <img src={GetImgUrl("papers.png")} alt="거래처정보" />
                <label className="fontlargeb">&nbsp;거래처정보</label>
              </div>
              
              <div className="empline"/>
  
              {
                LoginStoreInstance.getUserLevel <= 1 ? (
                  <div className="retinfo flexcenterrowstart">
                    <div className="retbtnareapc flexcenterrowstart">
                      <input type="radio" onChange={this.onCheckChanged} checked = {false} className="chk"/>
                      <label className="btncur fontnb">&nbsp;&nbsp;선택한 정보를&nbsp;&nbsp;</label>
      
                      
                      <button className="bgcolor1 empsmallbtn fontnwb" onClick = {this.handleChangeRet}>
                        수정
                      </button>
      
                      <label className="btncur fontnb">&nbsp;&nbsp;&nbsp;&nbsp;</label>
      
                      <button className="bgcolor2 empsmallbtn fontnwb" onClick = {this.handleDeleteRet}>
                        삭제
                      </button>
      
                      <label className="btncur fontnb">&nbsp;&nbsp;&nbsp;&nbsp;</label>
      
                      <button className="bgcolor2 retbanbtn fontnwb" onClick = {this.handleBanRet}>
                        거래처 차단
                      </button>
      
                      <button className="retexcelbtn fontnwb" onClick = {this.handleExportExcel}>
                        전체 거래처 엑셀 출력
                      </button>

                      {
                        this.state.searchList ? (
                          <button className="fontnwb retlmbtn" onClick = {this.handleListMode}>
                            목록보기
                          </button>
                        ):
                        null
                      }
                      
                      <select className="retsalessel fontn  btncur"
                        value={this.state.salesIdx.toString()}
                        onChange={(e) => {
                          if (LOG_ENABLE)
                            console.log(e.target.value);

                          this.changeSales(e.target.value);
                        }}
                      >
                        {salesList}
                      </select> 

                    </div>
                  </div>
                ) : null
              }

              {
                LoginStoreInstance.getUserLevel === 3 ? (
                  <div className="retinfo flexcenterrowstart">
                    <div className="flexcenterrowstart retbtnareapc2">
                      {
                        this.state.searchList ? (
                          <button className="fontnwb retlmbtn" onClick = {this.handleListMode}>
                            목록보기
                          </button>
                        ):
                        null
                      }
                      
                      <select className="retsalessel2 fontn  btncur"
                        value={this.state.salesIdx.toString()}
                        onChange={(e) => {
                          if (LOG_ENABLE)
                            console.log(e.target.value);

                          this.changeSales(e.target.value);
                        }}
                      >
                        {salesList}
                      </select> 

                    </div>
                  </div>
                ) : null
              }
              
            </div>
  
            <div className = "tablecont">
              <table className="emptable">
                <thead>
                  <tr>
                    <th className="emptableheader">
                      {/*<input type="checkbox" onChange={this.onCheckChanged} checked = {false} className="chk"/>*/}
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">업체명</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">주소</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">대표자명</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">연락처</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">사업자번호</label>
                    </th>
  
                    <th className="emptableheader">
                      <label className="fontnw">영업담당자</label>
                    </th>
  
                    {/*
                    <th className="emptableheader">
                      <label className="fontnw">관리담당자</label>
                    </th> */}
  
                    <th className="emptableheader">
                      <label className="fontnw">업체구분</label>
                    </th>

                    {
                      LoginStoreInstance.getUserLevel < 2 ? (
                        <th className="emptableheader">
                          <label className="fontnw">계정</label>
                        </th>
                      ) : null
                    }
                    
                  </tr>
                </thead>
                <tbody>
                {ret}
                </tbody>
              </table>
            </div>
            {
              (this.state.pageCount > 1 && !this.state.searchList) ? <PageList handlePage={this.handlePage} 
                first={this.state.startPage !== 1} prev={this.state.startPage !== 1} 
                next={this.state.endPage < this.state.pageCount} last={this.state.endPage < this.state.pageCount} 
                currentPage={this.state.currentPage} 
                startPage={this.state.startPage} endPage={this.state.endPage}/> : 
              null
            }
            
          </div>
        </div>
        
      );
    }
  }
}

export default observer(Ret);