import { IsValidS } from "../../Util/Util";

function GetGoodsName(item)
{
  if (IsValidS(item.alias) && item.alias !== "n" && item.iname !== item.alias)
    return `${item.iname} : ${item.alias}`;

  return item.iname;
}

const ProductItemmb = (props) =>
{
  return (
    <tr>
      <td className="pdtableitemmb">
        <div className="pdtableitemmb2 flexcenterrow">
          <input type="radio" onChange= {(e) => props.handleSelect(props.item.idx)} checked = {props.selectedIdx === props.item.idx} className="pdcheck"/>
        </div>
      </td>

      <td className="pdtableitemmb">
        <div className="pdtableitemmb2 flexcenterrow">
          <label className={props.item.pt === 0 ? "mfontn" : "mfontn2"}>{GetGoodsName(props.item)}</label>
        </div>
      </td>

      <td className="pdtableitemmb">
        <div className="pdtableitemmb2 flexcenterrow">
          <button className="pddetbtn btncur mfontnwb" onClick={() => props.handleDetail(props.item)}>상세정보</button>
        </div>
      </td>
    </tr>
  );
}

export default ProductItemmb;