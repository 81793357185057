import { ProductStoreInstance } from "../../Stores/ProductStore";
import { GetAppImgUrl } from "../../URL/AppUrl";
import { IsValidS } from "../../Util/Util";

function UsesCodeToString(uses)
{
  if (uses === 0)
    return "유흥";
  else if (uses === 1)
    return "일반";
  else if (uses === 2)
    return "공용";

  return uses;
}

function UnitToString(unit)
{
  if (unit === 0)
    return "박스";
  else if (unit === 1)
    return "병";
  else if (unit === 2)
    return "박스/병";
  else if (unit === 3)
    return "박스/개";
  else if (unit === 4)
    return "개";

  return "오류";
}

function GetGoodsVolume(item)
{
  if (IsValidS(item.ivol) && item.ivol.indexOf("g") >= 0)
    return `${item.ivol}`;

  return `${item.ivol}ml`;
}

function GetGoodsName(item)
{
  if (item.pt === 0)
  {
    if (IsValidS(item.alias) && item.alias !== "n" && item.iname !== item.alias)
      return `${item.iname} : ${item.alias}`;

    return item.iname;
  }
  else
  {
    if (IsValidS(item.alias) && item.alias !== "n" && item.iname !== item.alias)
      return `${item.iname} : ${item.alias}(판매중지됨)`;

    return `${item.iname}(판매중지됨)`;
  }
}

const ProductItem = (props) =>
{
  return (
    <tr>
      <td className="pdtablea">
        <div className="pdtableitem flexcenterrow">
          <input type="radio" onChange= {(e) => props.handleSelect(props.item.idx)} checked = {props.selectedIdx === props.item.idx} className="pdcheck"/>
        </div>
      </td>

      <td className="pdtableb">
        <div className="pdtableitem flexcenterrow">
          <label className="fontn">{ProductStoreInstance.getCategoryNameByCode(props.item.iid)}</label>
        </div>
      </td>

      <td className="pdtablec">
        <div className="pdtableitem flexcenterrow">
          <label className="fontmid">{props.item.icode}</label>
        </div>
      </td>

      <td className="pdtabled">
        <div className="pdtableitem flexcenterrow">
          <label className={props.item.pt === 0 ? "fontmid btncur" : "fontmidr btncur"} onClick={() => props.handleSelectAndEdit(props.item.idx)}>{GetGoodsName(props.item)}</label>
        </div>
      </td>

      <td className="pdtablek">
        <div className="pdtableitem flexcenterrow">
          <img src={GetAppImgUrl(props.item.img)} alt="상품이미지" className="pdtbimgsm"/>
        </div>
      </td>
      
      <td className="pdtablee">
        <div className="pdtableitem flexcenterrow">
          <label className="fontmid">{GetGoodsVolume(props.item)}</label>
        </div>
      </td>

      <td className="pdtablef">
        <div className="pdtableitem flexcenterrow">
          <label className="fontn">{UnitToString(props.item.unit)}</label>
        </div>
      </td>

      <td className="pdtableg">
        <div className="pdtableitem flexcenterrow">
          <label className="fontn">{UsesCodeToString(props.item.uses)}</label>
        </div>
      </td>

      <td className="pdtableh">
        <div className="pdtableitem flexcenterrow">
          <label className="fontn">{props.item.freq}</label>
        </div>
      </td>

      <td className="pdtablei">
        <div className="pdtableitem flexcenterrow">
          <label className="fontn">{props.item.cnt}</label>
        </div>
      </td>

      <td className="pdtablej">
        <div className="pdtableitem flexcenterrow">
          <label className="fontn">{props.item.price}</label>
        </div>
      </td>
    </tr>
  );
}

export default ProductItem;